import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from './recipes.constant';

export interface RecipeAttributes {
  config_ha: string;
  config_nonha: string;
  createdAt: string;
  structure: string;
  name: string;
  published_at: string;
  updatedAt: string;
  guide: Guide;
  tags: { data: StrapiData<RecipeTagAttributes>[]; }
  price_ha: string;
  price_nonha: string;
  resources_ha: string;
  resources_nonha: string;
}

export interface RecipeTagAttributes {
  tag: string;
  icon: string;
  logo: string;
  slug: string;
  type: 'application' | 'runtime' | 'other';
}

interface Guide {
  slug: string;
}

interface Recipes {
  isLoading: boolean;
  entities: HashMap<StrapiData<RecipeAttributes>>;
  list: number[];
  recipeTags: StrapiData<RecipeTagAttributes>[];
}

export type HashMap<T> = Record<number, T>;

interface StrapiResponse<T> {
  data: Array<StrapiData<T>>;
  meta: {
    pagination: {
      page: number;
      pageCount: number;
      pageSize: number;
      total: number;
    }
  }
}

export class StrapiData<T> {
  id: number;
  attributes: T;
}

const initialState: Recipes = {
  isLoading: false,
  entities: {},
  list: [],
  recipeTags: []
};

@Injectable({ providedIn: 'root' })
export class RecipesService {

  state$ = new BehaviorSubject<Recipes>(initialState);
  private _apiPrefix = 'api';

  protected get state() {
    return this.state$.getValue();
  }

  constructor(
    private _http: HttpClient,
    @Inject(API_URL)
    private _apiUrl: string
  ) {
    // preload for default empty state
    /**
     * Actually the recipe onboarding feature is under construction.
     * It doesn't have a sence to call for data. It leads to XHR errors.
     */
    // this.getList();
    // this.getRecipeTags();
  }

  getList() {
    this._setLoading(true);
    this._http
      .get<StrapiResponse<RecipeAttributes>>(
        `${this._apiUrl}/${this._apiPrefix}/recipes?populate=*`,
        { headers: { zefSkipAuthHeader: 'true' } }
      )
      .subscribe((res) => {
        this._setLoading(false);
        this.state$.next(this._reduce(this.state, res.data));
      });
  }

  getRecipeTags() {
    this._http
      .get<StrapiResponse<RecipeTagAttributes>>(
        `${this._apiUrl}/${this._apiPrefix}/recipe-tags?sort=priority&populate=*`,
        { headers: { zefSkipAuthHeader: 'true' } }
      )
      .subscribe((res) => this.state$.next({ ...this.state, recipeTags: res.data }));
  }

  selectById$(id: string) {
    return this.state$.pipe(map((state) => state.entities[id]));
  }

  selectList$() {
    return this.state$.pipe(map((s) => s.list.reduce((arr, itm, index) => {
      arr[index] = s.entities[itm];
      return arr;
    }, [] as Array<StrapiData<RecipeAttributes>>)))
  }

  selectLoading$() {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  selectRecipeTags$() {
    return this.state$.pipe(map((state) => state.recipeTags));
  }

  selectRecipeTagsAttributesMap$() {
    return this.selectRecipeTags$().pipe(map((d) => d.reduce((obj, itm) => {
      obj[itm.attributes.slug] = itm.attributes;
      return obj;
    }, {})));
  }

  private _setLoading(isLoading: boolean) {
    this.state$.next({
      ...this.state,
      isLoading
    });
  }

  private _reduce(state: Recipes, data: Array<StrapiData<RecipeAttributes>>) {
    return {
      ...state,
      ...data.reduce((obj, itm) => {
        obj.entities[itm.id] = itm;
        obj.list.push(itm.id);
        return obj;
      }, {
        entities: { ...state.entities },
        list: [ ...state.list ]
      })
    };
  }

}
