<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  verticalAlign="start"
  horizontalAlign="end"
  (contentMouseLeave)="contentMouseLeave.emit()"
  (contentMouseEnter)="contentMouseEnter.emit()"
  (closed)="popClosed.emit()">
  <mat-card class="__pop  z-manual-elevation  c-soft-elevation">

    <h3 class="__domain">
      <zef-copy-to-clipboard
        class="__copy"
        [copyData]="state.domain?.domainName"
        [tooltip]="state.generalTranslations.copyToClipboard">
        {{ state.domain?.domainName !== '*' ? state.domain?.domainName : 'Fallback rule (*)' }}
      </zef-copy-to-clipboard>
    </h3>

    <zui-dns-check-status-icon
      class="__icon"
      [size]="12"
      [status]="state.domain?.dnsCheckStatus"
      [statusText]="state.translations.dnsChestStatuses[state.domain?.dnsCheckStatus]">
    </zui-dns-check-status-icon>

    <div class="__ip" *ngIf="project?.publicIpV6">
      <zef-copy-to-clipboard
        [copyData]="project.publicIpV6"
        [tooltip]="state.generalTranslations.copyToClipboard">
        {{ project.publicIpV6 }}
      </zef-copy-to-clipboard>
    </div>

    <div class="__ip" *ngIf="project?.publicIpV4">
      <zef-copy-to-clipboard
        [copyData]="project.publicIpV4"
        [tooltip]="state.generalTranslations.copyToClipboard">
        {{ project.publicIpV4 }}
      </zef-copy-to-clipboard>
    </div>

  </mat-card>
</sat-popover>
