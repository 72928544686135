import {
  Component,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { SatPopover } from '@zerops/zef/popover';
import { AppState, GeneralTranslations } from '@zerops/zerops/app';
import { HttpRoutingEntity } from '@zerops/zerops/core/http-routing-base';
import { HttpRouting } from '@zerops/models/http-routing';
import { selectZefProgressMapByType, toProgressMapKey } from '@zerops/zef/progress';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  map,
  tap,
  withLatestFrom,
  switchMap,
  filter,
  share
} from 'rxjs/operators';
import { ObservableInput } from 'observable-input';

@Component({
  selector: 'z-http-routing-actions-pop',
  templateUrl: './http-routing-actions-pop.container.html',
  styleUrls: [ './http-routing-actions-pop.container.scss' ]
})
export class HttpRoutingActionsPopContainer extends ZefReactiveComponent implements OnDestroy {
  // # Event Streams
  onHttpRoutingDelete$ = new Subject<void>();
  onHttpRoutingRestore$ = new Subject<void>();

  // # Data
  // -- sync
  httpRoutingDeleteType = this._httpRoutingEntity.deleteOne.type;
  httpRoutingRestoreType = this._httpRoutingEntity.restoreOne.type;

  // -- angular
  @ObservableInput()
  @Input('id')
  id$!: Observable<string>;

  @Input()
  activeElRef: ElementRef<any>;

  @Output()
  popClosed = new EventEmitter<void>();

  @Output()
  editClicked = new EventEmitter<HttpRouting>();

  @ViewChild('popoverRef', { static: true })
  popoverRef: SatPopover;

  // -- sync

  // -- async
  httpRouting$ = this.id$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._httpRoutingEntity.entityById$(id)),
    filter((d) => !!d)
  );
  deleteOnSync$ = this.httpRouting$.pipe(
    map((d) => d.deleteOnSync)
  );
  loadingsMap$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(
      select(selectZefProgressMapByType([
        this.httpRoutingDeleteType,
        this.httpRoutingRestoreType
      ])),
      map((m) => m && m[id] ? m[id] : {}),
    )),
    share()
  );
  isDeleteLoading$ = this.loadingsMap$.pipe(toProgressMapKey(this.httpRoutingDeleteType));
  isRestoreLoading$ = this.loadingsMap$.pipe(toProgressMapKey(this.httpRoutingRestoreType));
  generalTranslations$ = this.translate$<GeneralTranslations>('general');

  // # State resolver
  state = this.$connect({
    httpRouting: this.httpRouting$,
    deleteOnSync: this.deleteOnSync$,
    isDeleteLoading: this.isDeleteLoading$,
    isRestoreLoading: this.isRestoreLoading$,
    generalTranslations: this.generalTranslations$
  });

  // # Action Streams
  private _httpRoutingDeleteAction$ = this.onHttpRoutingDelete$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => this._httpRoutingEntity.deleteOne(
      id,
      undefined,
      { type: 'pop' }
    )),
    tap(() => this.popoverRef.isOpen() ? this.popoverRef.close() : undefined)
  );
  private _httpRoutingRestoreAction$ = this.onHttpRoutingRestore$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => this._httpRoutingEntity.restoreOne(
      id,
      undefined,
      { type: 'pop' }
    )),
    tap(() => this.popoverRef.isOpen() ? this.popoverRef.close() : undefined)
  );

  constructor(
    private _store: Store<AppState>,
    private _httpRoutingEntity: HttpRoutingEntity
  ) {
    super();

    this.$dispatchActions(
      [
        this._httpRoutingDeleteAction$,
        this._httpRoutingRestoreAction$
      ]
    );
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
