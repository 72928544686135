import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  ServiceStackTileComponent,
  ServiceStackTileCategoryDirective,
  ServiceStackTileNameDirective
} from './service-stack-tile.component';

@NgModule({
  declarations: [
    ServiceStackTileComponent,
    ServiceStackTileCategoryDirective,
    ServiceStackTileNameDirective
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    ServiceStackTileComponent,
    ServiceStackTileCategoryDirective,
    ServiceStackTileNameDirective
  ]
})
export class ServiceStackTileModule {

}
