import { Injectable } from '@angular/core';

const userStorageKey = '@zerops/zerops/user-data';

interface UserDataPayload {
  userId: string;
  clientUserId: string;
}

@Injectable({ providedIn: 'root' })
export class UserDataStorageService {
  private _data: UserDataPayload;

  setData(data: UserDataPayload) {
    this._data = data;
    this._updateStorage();
  }

  removeData() {
    this._data = undefined;
    this._updateStorage();
  }

  getData() {
    return this._data;
  }

  constructor() {
    this._data = this._getStorage();
  }

  private _updateStorage() {
    const d = this._data
      ? JSON.stringify(this._data)
      : '';

    localStorage.setItem(userStorageKey, d);
  }

  private _getStorage() {
    const s = localStorage.getItem(userStorageKey);

    return s ? JSON.parse(s) : undefined;
  }
}
