<zui-routing-location-item
  *ngFor="let item of (showAllRecords ? httpRoutingLocations : httpRoutingLocationsSliced); trackBy: trackByIndex;"
  class="__location-item"
  [path]="item.path"
  [port]="item.port"
  [internalProtocol]="item.protocol"
  [dnsCheckStatus]="item._dnsCheckStatus"
  [serviceStackStatus]="serviceStackStatus"
  [serviceStackName]="serviceStackName"
  [serviceStackId]="serviceStackId"
  [isSynced]="item._isSynced"
  [sslEnabled]="item._sslEnabled"
  [activeSsl]="item._activeSsl"
  [routingType]="'http'"
  [copyToClipboardTooltipTranslation]="copyButtonTooltip"
  [type]="type">
</zui-routing-location-item>

<zui-routing-location-item
  *ngFor="let item of (showAllRecords ? portRoutingsRaw : portRoutingsSliced); trackBy: trackByIndex;"
  class="__location-item"
  [serviceStackName]="serviceStackName"
  [serviceStackId]="serviceStackId"
  [serviceStackStatus]="serviceStackStatus"
  [isSynced]="item.isSynced"
  [port]="item?.internalPort"
  [path]="item.path"
  [routingType]="'port'"
  [internalProtocol]="item.internalProtocol"
  [copyToClipboardTooltipTranslation]="copyButtonTooltip"
  [type]="type">
</zui-routing-location-item>

<ng-container *ngIf="!showAllRecords">

  <!-- redirect mode button -->
  <button
    *ngIf="showMoreButtonMode === showMoreButtonModes.Redirect"
    [routerLink]="[ '/', 'service-stack', serviceStackId, 'routing' ]"
    mat-stroked-button
    type="button"
    class="__button c-button--smaller c-button--routing">

    <ng-container *ngIf="httpRoutingLocations?.length + portRoutings?.length > (itemsNumber * 2); else elseRef">
      <ng-template [ngTemplateOutlet]="buttonTextTplRef"></ng-template>
    </ng-container>

    <ng-template #elseRef>
      <!-- TODO: translation -->
      Open routing settings
    </ng-template>

    <mat-icon>navigate_next</mat-icon>

  </button>

  <!-- context mode button -->
  <button
    *ngIf="showMoreButtonMode === showMoreButtonModes.Context"
    mat-stroked-button
    type="button"
    class="__button c-button--smaller c-button--routing"
    (click)="openRoutingSettingsClicked.emit()">

    <ng-container *ngIf="httpRoutingLocations?.length + portRoutings?.length > (itemsNumber * 2); else elseRef">
      <ng-template [ngTemplateOutlet]="buttonTextTplRef"></ng-template>
    </ng-container>

    <ng-template #elseRef>
      <!-- TODO: translation -->
      Open routing settings
    </ng-template>

    <mat-icon>navigate_next</mat-icon>

  </button>

  <!-- pop mode button -->
  <button
    *ngIf="showMoreButtonMode === showMoreButtonModes.Pop && (((httpRoutingLocations?.length + portRoutings?.length) - (httpRoutingLocationsSliced?.length + portRoutingsSliced ?.length)) > 0)"
    mat-flat-button
    type="button"
    class="__button c-button--smaller"
    (click)="publicRoutingDynamicAnchorRef.open()"
    #publicRoutingDynamicAnchorRef="dynamicPopAnchor"
    [zuiDynamicPopAnchor]="publicRoutingPopRef">
    <ng-template [ngTemplateOutlet]="buttonTextTplRef"></ng-template>
  </button>

  <ng-template #buttonTextTplRef>
    <!-- TODO: translation -->
    {{ 'Show ' + ((httpRoutingLocations?.length + portRoutings?.length) - (httpRoutingLocationsSliced?.length + portRoutingsSliced ?.length)) + ' more ' + (((httpRoutingLocations?.length + portRoutings?.length) - (httpRoutingLocationsSliced?.length + portRoutingsSliced ?.length)) > 1 ? 'routing records' : 'routing record') }}
  </ng-template>

</ng-container>
