import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { InitPrepareStepComponent } from './init-prepare-step.component';

@NgModule({
  declarations: [ InitPrepareStepComponent ],
  imports: [
    CommonModule,
    ZuiProcessStepStateModule,
    ZuiDocsTextModule
  ],
  exports: [ InitPrepareStepComponent ]
})
export class ZuiInitPrepareStepModule {

}
