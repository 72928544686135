import { Injectable } from '@angular/core';
import { zefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { successOf } from '@zerops/zef/entities';
import {
  PortRouting,
  FirewallPolicies,
  PublicIpTypes,
  IPV6_BASE_RANGE,
  IPV4_BASE_RANGE
} from '@zerops/models/port-routing';
import { PortRoutingEntity } from '@zerops/zerops/core/port-routing-base';
import { ZefSnackService } from '@zerops/zef/snack';
import { PortRoutingForm } from '@zerops/zui/port-routing-form';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { box } from 'ngrx-forms';
import { map, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './port-routing-edit-dialog.constant';
import { portRoutingEditDialogOpen } from './port-routing-edit-dialog.action';

@Injectable()
export class PortRoutingEditDialogEffect {

  private _onDialogOpen$ = this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME)
  );

  // open dialog
  private _onEditDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(portRoutingEditDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  // on dialog opened set form value
  private _onDialogOpenSetFormValue$ = createEffect(() => this._onDialogOpen$.pipe(
    switchMap(({ meta: { id } }) => this._portRoutingEntity.entityById$(id)),
    filter((d) => !!d),
    withLatestFrom(this._portRoutingForm.value$),
    map(([
      {
        publicIpType,
        internalPort,
        internalProtocol,
        serviceStackId,
        publicPort,
        firewallPolicy,
        firewallAllowMyIp,
        firewallIpRanges,
        id
      },
      _
  ]) => this._portRoutingForm.setValue({
      id,
      publicIpType,
      internalPortData: box({
        port: internalPort,
        protocol: internalProtocol,
        serviceStackId: serviceStackId
      }),
      publicPort,
      firewall: !!firewallIpRanges?.length,
      firewallPolicy,
      firewallAllowMyIp: firewallPolicy === FirewallPolicies.Whitelist
        ? firewallAllowMyIp
        : false,
      // TODO: comment
      firewallIpRanges: firewallIpRanges?.length
        ? firewallIpRanges.map((item) => {
          const splittedIp = item.split('/');
          const range = publicIpType === PublicIpTypes.IpV6 && splittedIp[1] === IPV6_BASE_RANGE
            || publicIpType === PublicIpTypes.IpV4 && splittedIp[1] === IPV4_BASE_RANGE ? undefined : splittedIp[1];
          return {
            ip: splittedIp[0],
            range
          };
        })
        : [{
          ip: '',
          range: undefined
        }]
    }))
  ));

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf<PortRouting>(this._portRoutingEntity.updateOne)
  );

  private _onUpdateSuccessDialogClose$ = createEffect(() => this._onUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._portRoutingForm.reset(),
      this._portRoutingForm.setDefaultValues(),
      this._portRoutingForm.enable('publicPort')
    ])
  ));

  private _onUpdateSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.updateSuccess` }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _portRoutingEntity: PortRoutingEntity,
    private _snack: ZefSnackService,
    private _portRoutingForm: PortRoutingForm
  ) { }
}
