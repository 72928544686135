<div
  class="__content  c-progress-spinner-content"
  [class.has-hide-content]="isLoading && hideContent">
  <ng-content></ng-content>
</div>

<mat-spinner
  *ngIf="isLoading"
  class="__spinner  c-progress-spinner-spinner"
  [ngStyle]="{
    'marginLeft.px': -(diameter / 2),
    'marginTop.px': -(diameter / 2)
  }"
  [diameter]="diameter">
</mat-spinner>

