import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { IpInfoComponent } from './ip-info.component';

@NgModule({
  declarations: [ IpInfoComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    ZefCopyToClipboardModule,
    ZefProgressErrorModule,
    MatIconModule
  ],
  exports: [ IpInfoComponent ]
})
export class IpInfoModule { }
