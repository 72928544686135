<button
  mat-button
  class="c-button--smaller  __button"
  (click)="popRef.open()"
  [satPopoverAnchor]="popRef">
  <mat-icon fontSet="material-icons-outlined">{{ lightMode ? 'light_mode' : 'dark_mode' }}</mat-icon>&nbsp;

  @switch (activeMode) {
    @case ('light') {
      <span>Light theme</span>
    }
    @case ('dark') {
      <span>Dark theme</span>
    }
    @case ('system') {
      <span>System theme ({{ lightMode ? 'light' : 'dark' }})</span>
    }
  }
</button>

<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="popRef.close()"
  [verticalAlign]="'center'"
  [horizontalAlign]="'after'">
  <mat-card class="__menu-card  z-manual-elevation  c-soft-elevation">

    <mat-nav-list class="__list">

      <a
        mat-list-item
        class="__item"
        (click)="setTheme.emit('light'); popRef.close();"
        [class.is-active]="activeMode === 'light'">
        <zui-action-list-item
          [icon]="'light_mode'"
          [label]="'Light theme'">
        </zui-action-list-item>
      </a>

      <a
        mat-list-item
        class="__item"
        (click)="setTheme.emit('dark'); popRef.close();"
        [class.is-active]="activeMode === 'dark'">
        <zui-action-list-item
          [icon]="'dark_mode'"
          [label]="'Dark theme'">
        </zui-action-list-item>
      </a>

      <a
        class="__item"
        mat-list-item
        (click)="setTheme.emit('system'); popRef.close();"
        [class.is-active]="activeMode === 'system'">
        <zui-action-list-item
          [icon]="'settings'"
          [label]="'System theme'">
        </zui-action-list-item>
      </a>

    </mat-nav-list>

    <div
      class="__experimental-wrap"
      [class.is-dark-theme]="isDarkTheme">
      <span class="__experimental">Highly expertimental</span>
    </div>

  </mat-card>
</sat-popover>
