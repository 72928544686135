import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingBaseEffect } from './billing-base.effect';
import { billingBaseReducer } from './billing-base.reducer';
import { FEATURE_NAME } from './billing-base.constant';

@NgModule({
  imports: [
    EffectsModule.forFeature([ BillingBaseEffect ]),
    StoreModule.forFeature(FEATURE_NAME, billingBaseReducer)
  ]
})
export class BillingBaseModule {

}
