import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './addon-activation-dialog.constant';

const en = {
  close: 'Close',
  activateAddon: 'Activate add-on',
  deactivateAutorenew: 'Deactivate auto-renew',
  activateAutorenew: 'Activate auto-renew ($0)',
  creditWarning: 'You do not have enough credit to purchase the add-on'
};

export type AddonActivationDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class AddonActivationDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
