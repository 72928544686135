import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './deploy-shutdown-dialog.constant';

const en = {
  title: 'Temporary Shutdown',
  desc: `You are about to deploy a <strong>new version of your code</strong>.
  Before you proceed, choose if you like to <strong>temporary shutdown</strong> your service.`,
  deploy: 'Deploy code',
  deployDesc: 'New version of your code will be deployed.',
  close: 'Close',
  closeDesc: 'Dialog will be closed without any changes.'
};

export type DeployShutdownDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class DeployShutdownDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
