import { Injectable } from '@angular/core';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { onWebsocketMessageDispatchAddRemoveEntities } from '@zerops/zef/entities';
import { HttpRoutingEntity } from '@zerops/zerops/core/http-routing-base';
import { zefDialogClose } from '@zerops/zef/dialog';
import { HttpRoutingForm } from '@zerops/zui/http-routing-form';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './http-routing-list.constant';
import { initializeHttpRoutingList } from './http-routing-list.action';

@Injectable()
export class HttpRoutingListEffect {

  private _initializedProjectId$ = this._actions$.pipe(
    ofType(initializeHttpRoutingList),
    map(({ projectId }) => projectId)
  );

  // http routing list / update
  private _setupHttpRoutingListStream$ = createEffect(() => this._initializedProjectId$.pipe(
    withLatestFrom(this._userEntity.activeClientId$),
    map(([ projectId, clientId ]) => this._httpRoutingEntity.listSubscribe(
      clientId,
      {
        name: FEATURE_NAME,
        id: projectId
      },
      {
        search: [
          {
            name: 'projectId',
            operator: 'eq',
            value: projectId
          }
        ]
      }
    ))
  ));

  private _onHttpRoutingListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._httpRoutingEntity,
      FEATURE_NAME,
      undefined,
      this._initializedProjectId$
    )
  ));

  // TODO: move to http edit dialog
  private _onEditDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === this._httpRoutingEntity.updateOne.type),
    switchMap(() => [
      this._httpRoutingForm.reset(),
      this._httpRoutingForm.setDefaultValues()
    ])
  ));

  constructor(
    private _actions$: Actions,
    private _userEntity: UserEntity,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _httpRoutingForm: HttpRoutingForm
  ) { }

}
