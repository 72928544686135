import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ProcessItemModule } from '@zerops/zui/process-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ProcessesCardComponent } from './processes-card.component';

@NgModule({
  declarations: [ ProcessesCardComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    ZuiMaterialStackModule,
    ProcessItemModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent
  ],
  exports: [ ProcessesCardComponent ]
})
export class ZuiProcessesCardModule {

}
