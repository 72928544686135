import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { UserDataBaseState } from './user-data-base.model';
import { UserDataBaseActionUnion, setActiveId } from './user-data-base.action';

const initialState = new UserDataBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    setActiveId,
    (s, { id }): UserDataBaseState => ({
      ...s,
      activeId: id
    })
  ),
  on(zefResetState, () => initialState)
);

export function userDataBaseReducer(
  state: UserDataBaseState,
  action: UserDataBaseActionUnion
) {
  return actionReducer(state, action);
}
