import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRoutingFieldsModule } from '@zerops/zui/http-routing-fields';
import { ZefFormModule } from '@zerops/zef/forms/modules';
import { HttpRoutingFormComponent } from './http-routing-form.component';

@NgModule({
  declarations: [ HttpRoutingFormComponent ],
  imports: [
    CommonModule,
    HttpRoutingFieldsModule,
    ZefFormModule
  ],
  exports: [ HttpRoutingFormComponent ]
})
export class HttpRoutingFormModule {

}
