import { Component } from '@angular/core';

@Component({
  selector: 'zui-tag',
  templateUrl: './tag.component.html',
  styleUrls: [ './tag.component.scss' ]
})
export class TagComponent {

}
