import { Component, OnDestroy } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { UserDataEntity, selectUserDataBaseActiveId } from '@zerops/zerops/core/user-data-base';
import { UserDataEditForm } from '@zerops/zui/user-data-edit-form';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { formValueOnValid } from '@zerops/zef/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './user-data-edit-dialog.constant';

@Component({
  selector: 'z-user-data-edit-dialog',
  templateUrl: './user-data-edit-dialog.container.html',
  styleUrls: [ './user-data-edit-dialog.container.scss' ]
})
export class UserDataEditDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onEdit$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Forms
  formState$ = this._userDataEditForm.state$;

  // # Data
  // -- sync
  editKey = this._userDataEntity.updateOne.type;
  dialogKey = FEATURE_NAME;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  activeUserData$ = this._userDataEntity.entityById$(selectUserDataBaseActiveId);

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$
  });

  // # Action Streams
  private _editAction$ = this.onEdit$.pipe(
    formValueOnValid(this._userDataEditForm),
    withLatestFrom(this.activeUserData$),
    map(([ d, { id } ]) => this._userDataEntity.updateOne(id, d))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _userDataEditForm: UserDataEditForm,
    private _userDataEntity: UserDataEntity
  ) {
    super();

    this.$dispatchActions([
      this._editAction$,
      this._closeAction$
    ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
