import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ClientListItemComponent } from './client-list-item.component';

@NgModule({
  declarations: [ ClientListItemComponent ],
  imports: [
     ZefAvatarModule,
     ZefCopyToClipboardModule,
     MatIconModule,
     CommonModule,
     ZefPipesModule
  ],
  exports: [ ClientListItemComponent ]
})
export class ClientListItemModule {

}
