import { ServiceStackBackup } from '@zerops/models/service-stack';
import { HashMap } from '@zerops/zef/core';

export class ServiceStackBaseState {
  containerFiles: HashMap<HashMap<string>>;
  backups: HashMap<ServiceStackBackup[]>;

  constructor() {
    this.containerFiles = undefined;
    this.backups = {};
  }
}

export class ContainerFilesAccess {
  accessToken: string;
  expiration: string;
  listUrl: string;
  readFileContentUrl: string;
  readFileUrl: string;
}

export class ContainerFile {
  size: number;
  content: string;
  mimeType: string;
}
