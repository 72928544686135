import { Injectable } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { BehaviorSubject } from 'rxjs';
import { HttpRoutingWithLocations } from '@zerops/models/http-routing';
import { PortRouting } from '@zerops/models/port-routing';
import { ServiceStackPort } from '@zerops/models/service-stack';

@Injectable({ providedIn: 'root' })
export class WebPublicRoutingPopService {

  private _ref: SatPopover;
  private _data$ = new BehaviorSubject<{
    serviceStackId: string;
    ports: ServiceStackPort[];
    httpRoutings: HttpRoutingWithLocations[];
    portRoutings: PortRouting[];
    publicIpV4: string;
    publicIpV6: string;
  }>(undefined);

  saveRef(ref: SatPopover) {
    this._ref = ref;
  }

  getRef() {
    return this._ref;
  }

  setData(
    serviceStackId: string,
    ports: ServiceStackPort[],
    httpRoutings: HttpRoutingWithLocations[],
    portRoutings: PortRouting[],
    publicIpV4: string,
    publicIpV6: string
  ) {
    if (!!serviceStackId && (!!httpRoutings?.length || !!portRoutings?.length)) {
      this._data$.next({
        serviceStackId,
        ports,
        httpRoutings,
        portRoutings,
        publicIpV4,
        publicIpV6
      });
    }
  }



  getData$() {
    return this._data$.asObservable();
  }
}
