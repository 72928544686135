import { Injectable } from '@angular/core';
import { zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { BillingInfoForm } from '@zerops/zerops/common/billing-info-form';
import { selectBillingInfo, updateBillingInfoSuccess } from '@zerops/zerops/core/client-base';
import { AppState } from '@zerops/zerops/app';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './billing-info-edit-dialog.constant';
import { ZefSnackService } from '@zerops/zef/snack';

@Injectable()
export class BillingInfoEditDialogEffect {

  private _onDialogOpenSetFormValue$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME),
    withLatestFrom(
      this._store.pipe(
        select(selectBillingInfo),
        filter((d) => !!d)
    )),
    map(([ _, d ]) => this._billingInfoForm.setValue({
      vatPayer: d.future ? !!d.future?.vatNumber : !!d.current?.vatNumber,
      vatNumber: d.future ? d.future?.vatNumber : d.current?.vatNumber,
      companyNumber: d.future ? d.future?.companyNumber : d.current?.companyNumber,
      companyName: d.future ? d.future?.companyName : d.current?.companyName,
      invoiceAddressStreet: d.future ? d.future?.invoiceAddressStreet : d.current?.invoiceAddressStreet,
      invoiceAddressCity: d.future ? d.future?.invoiceAddressCity : d.current?.invoiceAddressCity,
      invoiceAddressPostcode: d.future ? d.future?.invoiceAddressPostcode : d.current?.invoiceAddressPostcode,
      invoiceAddressCountryId: d.future ? d.future?.invoiceAddressCountryId : d.current?.invoiceAddressCountryId
    }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._billingInfoForm.reset(),
      this._billingInfoForm.setDefaultValues()
    ])
  ));

  private onUpdateBillingInfoSuccess$ = this._actions$.pipe(
    ofType(updateBillingInfoSuccess),
  );

  private _onUpdateBillingInfoSuccessCloseDialog$ = createEffect(() => this.onUpdateBillingInfoSuccess$.pipe(
    filter((action) => action.meta === FEATURE_NAME),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onUpdateBillingInfoSuccessNotification$ = createEffect(() => this.onUpdateBillingInfoSuccess$.pipe(
    switchMap(() => this._snack.success$({ text: 'Billing info was updated successfully' }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _billingInfoForm: BillingInfoForm,
    private _snack: ZefSnackService
  ) { }
}
