import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import {
  paymentRequest,
  BillingBaseActionUnion,
  paymentRequestSuccess,
  confirmPaymentSuccess,
  paymentSources,
  paymentSourcesSuccess,
  availableAddonsSuccess,
  removePaymentSource
} from './billing-base.action';
import { BillingBaseState } from './billing-base.model';

const initialState = new BillingBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    paymentRequest,
    (s, { data }): BillingBaseState => ({
      ...s,
      activePaymentRequest: {
        ...s.activePaymentRequest,
        amount: data.amount
      }
    })
  ),
  on(
    paymentRequestSuccess,
    (s, { data }): BillingBaseState => ({
      ...s,
      activePaymentRequest: {
        ...s.activePaymentRequest,
        ...data
      }
    })
  ),
  on(
    paymentSources,
    (s): BillingBaseState => ({
      ...s,
      paymentSources: undefined
    })
  ),
  on(
    paymentSourcesSuccess,
    (s, { data }): BillingBaseState => ({
      ...s,
      paymentSources: data.sources
    })
  ),
  on(
    removePaymentSource,
    (s): BillingBaseState => ({
      ...s,
      paymentSources: []
    })
  ),
  on(
    availableAddonsSuccess,
    (s, { data }): BillingBaseState => ({
      ...s,
      availableProjectAddons: data.projectAddons,
      availableClientAddons: data.clientAddons
    })
  ),
  on(
    confirmPaymentSuccess,
    (s): BillingBaseState => ({
      ...s,
      activePaymentRequest: undefined
    })
  ),
  on(zefResetState, () => initialState)
);

export function billingBaseReducer(
  state: BillingBaseState,
  action: BillingBaseActionUnion
) {
  return actionReducer(state, action);
}
