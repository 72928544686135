import { createReducer, on } from '@ngrx/store';
import { ProcessesPopState } from './processes-pop.model';
import {
  ProcessesPopActionUnion,
  processesPopSetSettings,
  processesPopSettingsReset
} from './processes-pop.action';

const initialState = new ProcessesPopState();

export const actionReducer = createReducer(
  initialState,
  on(
    processesPopSetSettings,
    (s, { level, id }): ProcessesPopState => ({
      ...s,
      level,
      id
    })
  ),
  on(
    processesPopSettingsReset,
    () => initialState
  )
);

export function processesPopReducer(
  state = initialState,
  action: ProcessesPopActionUnion
) {
  return actionReducer(state, action);
}
