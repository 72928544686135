<div
  class="__step  c-build-state-steps-step"
  [class.is-waiting]="_state === buildProcessStates.Waiting">

  <span class="__step_status  c-build-state-steps-step-status">
    <zui-process-step-state [state]="_state"></zui-process-step-state>
  </span>

  <div *ngIf="!hideText" class="__step_text  c-build-state-steps-step-text" [ngSwitch]="_state">
    <ng-template [ngSwitchCase]="buildProcessStates.Waiting">
      Run runtime prepare commands from&nbsp;zerops.yml
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Running">
      Running runtime prepare commands from&nbsp;zerops.yml
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Finished">
      Runtime prepare commands from zerops.yml ran successfully
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Failed">

      Runtime prepare commands from zerops.yml failed

      <!-- Start: New solution for displaying BE errors. -->
      <div
        *ngIf="!!pipelineErrors?.[0]?.error"
        class="__error-card">
        <zef-scrollbar maxHeight="200px">
          <zui-errors-printer
            [pipelineErrors]="pipelineErrors">
          </zui-errors-printer>
        </zef-scrollbar>
      </div>
      <!-- End: New solution for displaying BE errors. -->

    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Cancelled">
      Couldn't start runtime prepare container, prepare step cancelled
    </ng-template>
  </div>

</div>
