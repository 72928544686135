import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ClientStatus } from '@zerops/models/client-status';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientStatusEntity extends EntityService<ClientStatus> {

  activeClientStatus$ = this._userEntity.activeClientId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this.entityById$(id, (a, b) => a === b))
  );

  credit$ = this.activeClientStatus$.pipe(
    map((d) => {
      const credit: number = d?.credit || 0;
      const promoCredit: number = d?.promoCredit || 0;

      return {
        credit,
        promoCredit,
        total: credit + promoCredit
      };
    })
  );

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService,
    private _userEntity: UserEntity
  ) {
    super(ApiEntityKeys.ClientStatus, store, collectionManager, websocketService);
  }

}
