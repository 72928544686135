import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ProcessNotificationDescComponent } from './process-notification-desc.component';
import { ZuiInternalLinkTextModule } from '@zerops/zui/links-text';

@NgModule({
  declarations: [ ProcessNotificationDescComponent ],
  imports: [
    CommonModule,
    ZuiDocsTextModule,
    ZuiInternalLinkTextModule
  ],
  exports: [ ProcessNotificationDescComponent ]
})
export class ZuiProcessNotificationDescModule {

}
