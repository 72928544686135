import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './service-stack-base.constant';

const en = {
  addedToQueue: 'Added to queue, working on it!',
  operationAddedToQueue: 'Service stack operation was added to queue successfully.'
};

export type ServiceStackBaseTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ServiceStackBaseTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
