import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ObjectStoragesUsageState } from './object-storages-usage.model';
import { FEATURE_NAME } from './object-storages-usage.constant';

export const selectObjectStoragesUsageState = createFeatureSelector<ObjectStoragesUsageState>(FEATURE_NAME);

export const selectObjectStoragesInfoMap = createSelector(
  selectObjectStoragesUsageState,
  (state) => state && state.data
);
