import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { SharedStorageConnectionFieldModule } from '@zerops/zui/shared-storage-connection-field';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import {
  SharedStorageServiceStackFormComponent
} from './shared-storage-service-stack-form.component';
import {
  SharedStorageServiceStackFormTranslationsModule
} from './shared-storage-service-stack-form.translations';
import {
  HostnameFieldModule,
  DiskCapacityFieldModule
} from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';

@NgModule({
  declarations: [ SharedStorageServiceStackFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MatCardModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    SharedStorageServiceStackFormTranslationsModule,
    DiskCapacityFieldModule,
    SharedStorageConnectionFieldModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiAutoscalingFormFieldModule,
    ZuiSeparatorComponent
  ],
  exports: [ SharedStorageServiceStackFormComponent ]
})
export class SharedStorageServiceStackFormModule {

}
