import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState, Boxed, box } from 'ngrx-forms';
import { FEATURE_NAME } from './http-routing-form.constant';

export interface HttpRoutingFormValue {
  domains: Boxed<Array<string>>;
  sslEnabled: boolean;
  locations: Array<{
    path: string;
    portData: Boxed<{ port: number; serviceStackId: string; }>;
  }>;
  id: string;
}

export type HttpRoutingFormState = FormState<HttpRoutingFormValue>;

@Injectable({ providedIn: 'root' })
export class HttpRoutingForm extends ZefForm<HttpRoutingFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        id: undefined,
        domains: box([]),
        sslEnabled: true,
        locations: [
          {
            path: '',
            portData: box({ port: undefined, serviceStackId: undefined })
          }
        ]
      },
      {}
    );
  }
}
