<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar maxHeight="60vh">
      <zui-form-section separator="none">
        <zui-user-data-edit-form
          #formRef
          class="__form"
          (submit)="onEdit$.next()"
          [state]="state.formState">
        </zui-user-data-edit-form>
      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="editKey">
            <button
              class="c-button--full"
              color="accent"
              mat-raised-button
              (click)="formRef.triggerSubmit()"
              type="button">
              Update secret variable
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>
