import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { ContainerStatusIconModule } from '../container-status-icon';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { ZuiSeparatorComponent } from '../separator';
import { DatePipe } from '@angular/common';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';

@Component({
  selector: 'zui-container-base-info',
  templateUrl: './container-base-info.component.html',
  styleUrls: ['./container-base-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContainerStatusIconModule,
    ZuiSeparatorComponent,
    DatePipe,
    ZefPipesModule,
    ZefCopyToClipboardModule
  ]
})
export class ZuiContainerBaseInfoComponent {
  hostname = input<string>();
  type = input<'container' | 'balancer' | 'build'>();
  versionName = input<string>();
  created = input<string>();
  containerId = input<string>();
  hideBadge = input<boolean>(false);
  status = input<ServiceStackStatuses>();
  statusText = input<string>();
  middleEllipsisLength = input<number>(30);
}
