import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppVersion } from '@zerops/models/app-version';
import { BuildProcessStates } from '../../build-state-steps.model';
import { getPipelineState } from '../../build-state-steps.utils';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-run-prepare-step',
  templateUrl: './run-prepare-step.component.html',
  styleUrls: [ './run-prepare-step.component.scss' ],
 changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunPrepareStepComponent {

  buildProcessStates = BuildProcessStates;

  @Input()
  set appVersion(v: AppVersion) {
    this._appVersion = v;
    this._state = getPipelineState(this._appVersion)?.RUN_PREPARE_COMMANDS;
  }
  get appVersion(): AppVersion {
    return this._appVersion;
  }

  @Input()
  hideText = false;

  @Input()
  pipelineErrors: PipelineError[];

  _state: BuildProcessStates;
  private _appVersion: AppVersion;
}
