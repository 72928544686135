import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { UserDataItemModule } from '@zerops/zerops/common/user-data-item';
import { UserDataMorePopModule } from '@zerops/zui/user-data-more-pop';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { SatPopoverModule } from '@zerops/zef/popover';
import { NgArrayPipesModule } from 'ngx-pipes';
import { UserDataFormFieldComponent } from './user-data-form-field.component';
import {
  UserDataAddPopModule,
  UserDataEditPopModule,
  UserDataEnvFormatDialogModule
} from './modules';

@NgModule({
  declarations: [ UserDataFormFieldComponent ],
  imports: [
    CommonModule,
    MaterialStackModule,
    SatPopoverModule,
    ZefDialogModule,
    UserDataItemModule,
    UserDataMorePopModule,
    UserDataAddPopModule,
    UserDataEditPopModule,
    UserDataEnvFormatDialogModule,
    NgArrayPipesModule
  ],
  exports: [ UserDataFormFieldComponent ]
})
export class UserDataFormFieldModule { }
