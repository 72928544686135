import { Component, OnDestroy } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { ZefSnackService } from '@zerops/zef/snack';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FEATURE_NAME } from './copy-code-dialog.constant';
import { ThemeService } from '@zerops/zef/theme';

@Component({
  selector: 'z-copy-code-dialog',
  templateUrl: './copy-code-dialog.container.html',
  styleUrls: [ './copy-code-dialog.container.scss' ]
})
export class CopyCodeDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  data$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.data)
  );
  title$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    filter((d) => !!d.meta?.title),
    map(({ meta }) => meta?.title)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    data: this.data$,
    title: this.title$,
    isDarkTheme: this._theme.isDarkMode$
  });

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _clipboardService: Clipboard,
    private _snack: ZefSnackService,
    private _theme: ThemeService

  ) {
    super();

    this.$dispatchActions([ this._closeAction$ ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  copy(d: string) {
    this._clipboardService.copy(d);
    this._snack.info$({ translation: 'general.copySuccess' });
  }

}
