<div *ngIf="labelTranslation" class="__label-wrap">
  <span class="__label">{{ labelTranslation }}</span>
</div>

<div class="__value-wrap">
  <span class="__value">{{ value }} {{ unit }}</span>
</div>

<mat-slider
  class="__slider"
  [value]="value"
  thumbLabel
  [step]="step"
  [min]="minValue"
  [max]="maxValue"
  (input)="value = $event.value">
</mat-slider>
