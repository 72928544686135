import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { PriceModule } from '@zerops/zui/price';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { AutoscalingFormFieldComponent } from './autoscaling-form-field.component';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { NgMathPipesModule } from 'ngx-pipes';
import { ConfirmPopModule } from '../confirm-pop';
import { DynamicPopAnchorModule } from '../dynamic-pop-anchor';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [ AutoscalingFormFieldComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ZuiMaterialFormStackModule,
    MatButtonToggleModule,
    ZefFormsModule,
    MatSlideToggleModule,
    ZefDialogModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ZuiSeparatorComponent,
    PriceModule,
    NgMathPipesModule,
    ZefScrollbarComponent,
    ConfirmPopModule,
    DynamicPopAnchorModule,
    NouisliderModule
  ],
  exports: [ AutoscalingFormFieldComponent ]
})
export class ZuiAutoscalingFormFieldModule {

}
