import { HashMap } from '@zerops/zef/core';
import {
  subHours,
  subDays,
  startOfHour,
  subMonths,
  startOfDay,
  startOfMonth,
  addDays,
  addHours,
  addMonths
} from 'date-fns/esm';
import {
  TransactionGroupRange,
  TransactionTimeGroupBy
} from './transaction-debit.model';

const now = startOfHour(new Date());

export const TRANSACTION_GROUP_RANGE: HashMap<TransactionGroupRange> = {
  last24h: {
    key: 'last24hours',
    label: 'Last 24 Hours',
    timeGroupBy: TransactionTimeGroupBy.Hours,
    hasCurrent: true,
    averageScale: 1,
    // limit: 24,
    range: {
      from: subHours(now, 23),
      to: startOfHour(addHours(now, 1))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: subHours(now, 24),
        to: startOfHour(addHours(now, 1))
      };
    }
  },
  last7d: {
    key: 'last7days',
    timeGroupBy: TransactionTimeGroupBy.Days,
    label: 'Last 7 Days',
    hasCurrent: true,
    averageScale: 24,
    // limit: 7,
    range: {
      from: startOfDay(subDays(now, 6)),
      to: startOfDay(addDays(now, 1))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfDay(subDays(now, 6)),
        to: startOfDay(addDays(now, 1))
      };
    }
  },
  last30d: {
    key: 'last30days',
    timeGroupBy: TransactionTimeGroupBy.Days,
    label: 'Last 30 Days',
    hasCurrent: true,
    averageScale: 24,
    // limit: 30,
    range: {
      from: startOfDay(subDays(now, 29)),
      to: startOfDay(addDays(now, 1))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfDay(subDays(now, 30)),
        to: startOfDay(addDays(now, 1))
      };
    }
  },
  last12m: {
    key: 'last12month',
    timeGroupBy: TransactionTimeGroupBy.Months,
    label: 'Last 12 months',
    averageScale: 24 * 30,
    hasCurrent: true,
    // limit: 12,
    range: {
      from: startOfMonth(startOfDay(subMonths(now, 11))),
      to: startOfMonth(startOfDay(addMonths(now, 1)))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfMonth(startOfDay(subMonths(now, 11))),
        to: startOfMonth(startOfDay(addMonths(now, 1)))
      };
    }
  },
  thisMonth: {
    key: 'thisMonth',
    timeGroupBy: TransactionTimeGroupBy.Days,
    label: 'This month',
    averageScale: 24,
    hasCurrent: true,
    range: {
      from: startOfMonth(startOfDay(now)),
      to: startOfMonth(addMonths(startOfDay(now), 1))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfMonth(startOfDay(now)),
        to: startOfMonth(addMonths(startOfDay(now), 1))
      };
    }
  },
  lastMonth: {
    key: 'lastMonth',
    timeGroupBy: TransactionTimeGroupBy.Days,
    label: 'Last month',
    hasCurrent: false,
    averageScale: 24,
    range: {
      from: startOfMonth(startOfDay(subMonths(now, 1))),
      to: startOfDay(startOfMonth(now))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfMonth(startOfDay(subMonths(now, 1))),
        to: startOfDay(startOfMonth(now))
      };
    }
  },
  today: {
    key: 'today',
    timeGroupBy: TransactionTimeGroupBy.Hours,
    label: 'Today',
    hasCurrent: true,
    averageScale: 1,
    range: {
      from: startOfDay(now),
      to: startOfDay(addDays(now, 1))
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfDay(now),
        to: startOfDay(addDays(now, 1))
      };
    }
  },
  yesterday: {
    key: 'yesterday',
    timeGroupBy: TransactionTimeGroupBy.Hours,
    label: 'Yesterday',
    hasCurrent: false,
    averageScale: 1,
    range: {
      from: startOfDay(subDays(now, 1)),
      to: startOfDay(now)
    },
    getRange: () => {
      const now = startOfHour(new Date());

      return {
        from: startOfDay(subDays(now, 1)),
        to: startOfDay(now)
      };
    }
  },
  // lastYear: {},
  // thisYear: {},
  // yesterday: {}
};
