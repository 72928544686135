import {
  AfterViewInit,
  Component,
  ViewChild
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { SatPopover } from '@zerops/zef/popover';
import { AppVersionItemTranslations, APP_VERSION_ITEM_FEATURE_NAME } from '@zerops/zui/app-version-item';
import { NotificationItemTranslations, NOTIFICATION_ITEM_FEATURE_NAME } from '@zerops/zui/notification-item';
import { Notification } from '@zerops/models/notification';
import { Contexts, CONTEXTUAL_DIALOG_FEATURE_NAME } from '@zerops/zui/contextual-dialog';
import { zefDialogOpen } from '@zerops/zef/dialog';
import {
  format,
  parseISO,
  startOfDay,
  isBefore,
  subMinutes
} from 'date-fns/esm';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WebNotificationsPopService } from './web-notifications.service';

// TODO: unify
interface GroupedNotification {
  day: string;
  items: Notification[];
}

@Component({
  selector: 'zui-web-notifications-pop',
  templateUrl: './web-notifications-pop.container.html',
  styleUrls: [ './web-notifications-pop.container.scss' ]
})
export class WebNotificationsPopContainer extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onOpenContextualDialog$ = new Subject<Contexts>();

  // # Data
  // -- sync
  contextualPopContexts = Contexts;

  // -- angular
  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  // -- async
  appVersionItemTranslations$ = this.translate$<AppVersionItemTranslations>(APP_VERSION_ITEM_FEATURE_NAME);
  notificationItemTranslations$ = this.translate$<NotificationItemTranslations>(NOTIFICATION_ITEM_FEATURE_NAME);
  groupedNotifications$ = this._webNotificationsPopService.getNotifications$().pipe(
    map((d) => this._groupNotifications(d))
  );

  // # State resolver
  state = this.$connect({
    appVersionItemTranslations: this.appVersionItemTranslations$,
    notificationItemTranslations: this.notificationItemTranslations$,
    groupedNotifications: this.groupedNotifications$
  });

  // # Action Streams
  private _openContextualDialogAction$ = this.onOpenContextualDialog$.pipe(
    map((meta) => zefDialogOpen({
      key: CONTEXTUAL_DIALOG_FEATURE_NAME,
      meta
    }))
  );

  constructor(
    private _webNotificationsPopService: WebNotificationsPopService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._openContextualDialogAction$ ]);
  }

  ngAfterViewInit(): void {
    this._webNotificationsPopService.saveRef(this.popRef);
  }

  trackById(_: number, item: any) {
    return item?.id;
  }

  trackByDay(_: number, item: any) {
    return item?.day;
  }

  // TODO: unify
  private _groupNotifications(notifications: Notification[]) {
    return notifications.reduce((obj, itm) => {
      const parsedDay = parseISO(itm.created);
      const isRecent = !isBefore(parsedDay, subMinutes(new Date(), 30));
      const day = !isRecent
        ? format(parsedDay, 'y-M-d')
        : 'recent';

      if (obj.dayMap[day] === undefined) {
        obj.dayMap[day] = obj.data.length;

        obj.data.push({
          day: isRecent
            ? 'recent'
            : startOfDay(parsedDay).toISOString(),
          items: [],
        });
      }

      obj.data[obj.dayMap[day]].items.push(itm);

      return obj;
    }, {
      dayMap: {},
      data: [] as GroupedNotification[]
    }).data;
  }
}
