import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Sort } from '@zerops/zef/sort';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppVersionEntity extends EntityService<AppVersion> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'asc'
  };

  defaultSort$ = of(this.defaultSort);

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.AppVersion, store, collectionManager, websocketService);
  }

  list$(
    tag?: string | { name: string; id: string; },
    // TODO: interface for order selector / dir
    orderSelector: Array<string | ((i: AppVersion) => any)> = [
      (d) => d.status === AppVersionStatuses.Deploying,
      (d) => d.status === AppVersionStatuses.Building,
      'created'
    ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ 'desc', 'desc', 'desc' ]
  ) {
    return super.list$(
      tag,
      orderSelector?.length && orderSelector[0] !== undefined
        ? orderSelector
        : [ this.defaultSort.key ],
      orderDir?.length && orderDir[0] !== undefined
        ? orderDir
        : [ this.defaultSort.direction ]
    );
  }
}
