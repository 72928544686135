export const REMIX_NODEJS_IMPORT_YAML = `project:
  name: recipe-remix
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: nodejs@20
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-remix-nodejs`;
