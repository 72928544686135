import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'zui-zcli-examples',
  templateUrl: './zcli-examples.component.html',
  styleUrls: [ './zcli-examples.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZcliExamplesComponent {

  @Input()
  mode: 'push' | 'vpn' = 'push';

  @Input()
  project: string;

  @Input()
  service: string;

  @Input()
  region: string;

  accessTokenSettingsUrl = `${window.location.protocol}//${location.host}/settings/token-management`;

}
