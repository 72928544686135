import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { PopErrorContainer } from './pop-error.container';

@NgModule({
  declarations: [ PopErrorContainer ],
  imports: [
    CommonModule,
    MaterialStackModule
  ],
  exports: [ PopErrorContainer ]
})
export class PopErrorModule {

}
