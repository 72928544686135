import {
  trigger,
  transition,
  style,
  animate,
  state
} from '@angular/animations';

export function wsReconnectAnimation() {
  return trigger('ws-reconnect', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translate3d(0, 4px, 0)'
      }),
      animate(200, style({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
      }))
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
      }),
      animate(200, style({
        opacity: 0,
        transform: 'translate3d(0, 4px, 0)'
      }))
    ])
  ]);
}

export function reconnectingAnimation() {
  return trigger('reconnecting', [
    transition(':leave', [
      style({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)'
      }),
      animate(120, style({
        opacity: 0,
        transform: 'translate3d(0, 10px, 0)'
      }))
    ])
  ]);
}

export function reconnectedAnimation() {
  return trigger('reconnected', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translate3d(0, -10px, 0)'
      }),
      animate(170, style({
        opacity: 1,
        transform: 'translate3d(0, 0px, 0)'
      }))
    ])
  ]);
}

export function wrapAnimation() {
  return trigger('wrap', [
    state('0', style({
      backgroundColor: '#4cb75f'
    })),
    state('1', style({
      backgroundColor: '#ea6c6c'
    })),
    transition('1 => 0', [
      animate('150ms 100ms')
    ])
  ]);
}
