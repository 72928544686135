import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatPopoverAnchoringService } from '@zerops/zef/popover';
import { ZuiInternalLinkTextComponent } from './internal-link-text.component';

@NgModule({
  declarations: [ ZuiInternalLinkTextComponent ],
  imports: [
    CommonModule
  ],
  exports: [ ZuiInternalLinkTextComponent ],
  providers: [ SatPopoverAnchoringService ]
})
export class ZuiInternalLinkTextModule {

}
