import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ResourceStatisticsBaseEffect } from './resource-statistics-base.effect';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './resource-statistics-base.constant';
import { resourceStatisticsBaseReducer } from './resource-statistics-base.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ResourceStatisticsBaseEffect ]),
    StoreModule.forFeature(FEATURE_NAME, resourceStatisticsBaseReducer),
]
})
export class ResourceStatisticsBaseModule {

}
