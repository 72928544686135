import { Injectable } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { Notification } from '@zerops/models/notification';
import { BehaviorSubject } from 'rxjs';
import orderBy from 'lodash-es/orderBy';

@Injectable({ providedIn: 'root' })
export class WebNotificationsPopService {

  private _ref: SatPopover;
  private _notifications$ = new BehaviorSubject<Notification[]>([]);

  saveRef(ref: SatPopover) {
    this._ref = ref;
  }

  getRef() {
    return this._ref;
  }

  setNotifications(notifications: Notification[], level: 'project' | 'service', id: string) {

    if (notifications?.length) {
      if (level === 'service' && id) {
        const serviceNotifications = notifications.filter((d) => d.serviceStacks?.length && d.serviceStacks[0]?.id === id);
        this._notifications$.next(this._sliceNotifications(serviceNotifications));
      } else {
        this._notifications$.next(this._sliceNotifications(notifications));
      }

    }

  }

  private _sliceNotifications(notifications: Notification[]) {
    return orderBy(notifications.slice(0, 100), 'created', 'desc');
  }


  getNotifications$() {
    return this._notifications$.asObservable();
  }
}
