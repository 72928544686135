import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { format, subHours, addHours } from 'date-fns/esm';
import flatten from 'lodash-es/flatten';
import orderBy from 'lodash-es/orderBy';
import { getRandomInt, chooseWeighted } from '@zerops/zef/core';
import { ProjectWithServiceStacks } from '@zerops/models/project';

export interface SerieOption {
  name?: string;
  lowerThresholdLimit: number;
  upperThresholdLimit: number;
  /** [ from, to ] */
  serieStepRange: [ number, number ];
  /** [ from, to ] */
  serieSpendRange: [ number, number ];
  /** [ noop, increase, decrease ] */
  probabilities: [ number, number, number ];
}

export interface Step {
  date: Date;
  series: Array<{
    value: number;
    spend: number;
    name: string;
  }>;
  serviceStackId?: string;
  projectId?: string;
  id?: string;
}

export interface StepFlat {
  date: Date;
  name?: string;
  serviceStackId?: string;
  projectId?: string;
  id?: string;
  value: number;
  spend: number;
}


@Injectable({ providedIn: 'root' })
export class ResourceStatisticsService {

  private _data$ = new BehaviorSubject<StepFlat[]>([]);
  private _data: StepFlat[];

  serviceStackMap$ = this._data$.pipe(
    map((d: StepFlat[]) => {

      const x = d.reduce((obj, itm) => {
        if (obj[itm.serviceStackId] === undefined) {
          obj[itm.serviceStackId] = {
            spend: [{
              x: itm.date
            }],
            spendTotal: [{
              x: itm.date,
              y: [ ]
            }]
          };
        }

        if (obj[itm.serviceStackId][itm.name] === undefined) {
          obj[itm.serviceStackId][itm.name] = [{
            x: itm.date,
            y: [ itm.value ]
          }];
        } else {
          obj[itm.serviceStackId][itm.name].push({
            x: itm.date,
            y: [ itm.value ]
          });
        }

        const lastDate = this._formatDate(obj[itm.serviceStackId]['spendTotal'][obj[itm.serviceStackId]['spendTotal'].length - 1].x);

        if (lastDate !== this._formatDate(itm.date)) {
          obj[itm.serviceStackId]['spendTotal'].push({
            x: itm.date,
            y: [ 0 ]
          });

          obj[itm.serviceStackId]['spend'].push({
            x: itm.date
          });
        }

        obj[itm.serviceStackId]['spend'][obj[itm.serviceStackId]['spend'].length - 1][itm.name] = itm.spend;

        // eslint-disable-next-line max-len
        obj[itm.serviceStackId]['spendTotal'][obj[itm.serviceStackId]['spendTotal'].length - 1].y[0] = (obj[itm.serviceStackId]['spendTotal'][obj[itm.serviceStackId]['spendTotal'].length - 1].y[0] || 0) + itm.spend;

        return obj;

      }, {});

      return x;
    }),
    distinctUntilChanged()
  );

  projectMap$ = this._data$.pipe(
    map((d: StepFlat[]) => {

      const _dateHashMap = {};

      const x = orderBy(d, 'date').reduce((obj, itm) => {
        if (obj[itm.projectId] === undefined) {
          obj[itm.projectId] = {
            data: [],
            total: 0,
            currentSerieTotal: 0
          };
        }

        const date = this._formatDate(itm.date);

        if (_dateHashMap[itm.projectId] === undefined) {
          _dateHashMap[itm.projectId] = {};
        }

        if (_dateHashMap[itm.projectId][date] === undefined) {
          obj[itm.projectId].data.push({
            x: itm.date,
            [itm.serviceStackId]: itm.spend,
            _keys: [ itm.serviceStackId ]
          });

          _dateHashMap[itm.projectId][date] = obj[itm.projectId].data.length - 1;
        } else {
          obj[itm.projectId].data[_dateHashMap[itm.projectId][date]][itm.serviceStackId] = itm.spend;

          if (!obj[itm.projectId].data[_dateHashMap[itm.projectId][date]]._keys.includes(itm.serviceStackId)) {
            obj[itm.projectId].data[_dateHashMap[itm.projectId][date]]._keys.push(itm.serviceStackId);
          }
        }

        obj[itm.projectId].total = obj[itm.projectId].total + itm.spend;

        obj[itm.projectId].currentSerieTotal = 0;
        obj[itm.projectId].data[obj[itm.projectId].data.length - 1]._keys.forEach((k: string) => {
          // eslint-disable-next-line max-len
          obj[itm.projectId].currentSerieTotal = obj[itm.projectId].currentSerieTotal + obj[itm.projectId].data[obj[itm.projectId].data.length - 1][k];
        });

        return obj;

      }, {});

      return x;
    }),
    distinctUntilChanged()
  );

  company$ = this._data$.pipe(
    map((d: StepFlat[]) => {
      const _dateMap = {};

      const x = orderBy(d, 'date').reduce((obj, itm) => {
        const date = this._formatDate(itm.date);

        if (_dateMap[date] === undefined) {
          obj.data.push({
            x: itm.date,
            _keys: []
          });
          _dateMap[date] = obj.data.length - 1;
        }

        if (!obj.data[_dateMap[date]][itm.projectId]) {
          obj.data[_dateMap[date]][itm.projectId] = 0;
          obj.data[_dateMap[date]]._keys.push(itm.projectId);
        }

        obj.data[_dateMap[date]][itm.projectId] = obj.data[_dateMap[date]][itm.projectId] + itm.spend;

        obj.total = obj.total + itm.spend;


        obj.currentSerieTotal = 0;
        obj.data[obj.data.length - 1]._keys.forEach((k: string) => {
          obj.currentSerieTotal = obj.currentSerieTotal + obj.data[obj.data.length - 1][k];
        });

        return obj;
      }, {
        data: [],
        total: 0,
        currentSerieTotal: 0
      });

      return x;
    })
  );

  companyTotals$ = of({ total: 0, dayTotal: 0 });

  // companyTotals$ = this.company$.pipe(map((d: any) => {

  //   let dayTotal = 0;
  //   const total = d.series.reduce((num: number, s: number[]) => {
  //     s.forEach((n) => dayTotal = dayTotal + n);

  //     num = num + s[s.length - 1];
  //     return num;
  //   }, 0);

  //   return {
  //     total,
  //     dayTotal
  //   };

  // }));

  init(p: ProjectWithServiceStacks[]) {
    this._data = flatten(flatten(flatten(p.map((d) => d.serviceStacks)).map(({ id, projectId }) => {
      return this._generate(
        [
          {
            name: 'cpu',
            lowerThresholdLimit: 20,
            upperThresholdLimit: 90,
            serieSpendRange: [ 1, 2 ],
            serieStepRange: [ 5, 15 ],
            probabilities: [ 0.8, 0.15, 0.05 ]
          },
          {
            name: 'ramGBytes',
            lowerThresholdLimit: 20,
            upperThresholdLimit: 90,
            serieSpendRange: [ 1, 2 ],
            serieStepRange: [ 5, 15 ],
            probabilities: [ 0.9, 0.08, 0.02 ]
          },
          {
            name: 'ramDiskGBytes',
            lowerThresholdLimit: 20,
            upperThresholdLimit: 90,
            serieSpendRange: [ 1, 2 ],
            serieStepRange: [ 5, 15 ],
            probabilities: [ 0.9, 0.08, 0.02 ]
          },
          {
            name: 'diskGBytes',
            lowerThresholdLimit: 20,
            upperThresholdLimit: 90,
            serieSpendRange: [ 1, 2 ],
            serieStepRange: [ 5, 15 ],
            probabilities: [ 0.9, 0.08, 0.02 ]
          }
        ],
        [ 1, 3 ],
        24
      ).map((itm: Step) => {
        return itm.series.map((value) => ({
          value: value.value,
          spend: value.spend / 10,
          date: itm.date,
          name: value.name,
          serviceStackId: id,
          id: `${id}_${value.name}`,
          projectId,
        }));
      });
    })));

    this._data$.next(this._data);

  }

  private _generate(
    seriesOptions: SerieOption[],
    /** [ from, to ] */
    initialSpendRange: [ number, number ],
    steps: number,
    stepHours = 1
  ) {

    const initialStep: Step = {
      date: subHours(new Date(), steps * stepHours),
      series: seriesOptions.map(({
        lowerThresholdLimit,
        upperThresholdLimit
      }, i) => {
        return {
          value: getRandomInt(lowerThresholdLimit, upperThresholdLimit),
          spend: getRandomInt(initialSpendRange[0], initialSpendRange[1]),
          name: seriesOptions[i].name
        };
      })
    };

    const result: Step[] = [ initialStep ];

    for (let i = 0; i < (steps - 1); i++) {
      result.push(this._getNextStep(result[i], seriesOptions, stepHours));
    }

    return result;
  }

  private _formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd_HH');
  }

  private _getNextStep(
    {
      series,
      date
    }: Step,
    seriesOptions: SerieOption[],
    hourStep: number
  ): Step {

    const nextSeries = seriesOptions.map((
      {
        probabilities,
        serieStepRange,
        serieSpendRange,
        upperThresholdLimit,
        lowerThresholdLimit
      },
      i
    ) => {

      const op = chooseWeighted([ 'noop', 'increase', 'decrease' ], probabilities);
      const prevVal = series[i];

      let nextSpend = prevVal.spend;
      let nextValue = prevVal.value;

      if (op === 'noop') { return prevVal; }

      const valueModifier = getRandomInt(serieStepRange[0], serieStepRange[1]);

      if (op === 'increase') {
        nextValue = prevVal.value + valueModifier;

        if (nextValue >= upperThresholdLimit) {
          nextSpend = nextSpend + getRandomInt(serieSpendRange[0], serieSpendRange[1]);
          nextValue = Math.round(prevVal.value / 2);
        }

      }

      if (op === 'decrease') {
        nextValue = prevVal.value + valueModifier;

        if (nextValue <= 0) { nextValue = prevVal.value; }

        if (nextValue <= lowerThresholdLimit) {
          nextSpend = nextSpend - getRandomInt(serieSpendRange[0], serieSpendRange[1]);
          nextValue = Math.round(prevVal.value * 2);
        }
      }

      return {
        value: nextValue,
        spend: nextSpend,
        name: prevVal.name
      };

    });

    return {
      date: addHours(date, hourStep),
      series: nextSeries
    };

  }

}
