import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { ProjectStatus } from '@zerops/models/project-status';

@Injectable({ providedIn: 'root' })
export class ProjectStatusEntity extends EntityService<ProjectStatus> {

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.ProjectStatus, store, collectionManager, websocketService);
  }

}
