import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './user-data-edit-dialog.constant';

const en = {
  updateSuccess: 'Service env variable was updated successfully.'
};

export type UserDataEditDialogTranslations = typeof en;

@NgModule()
export class UserDataEditDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
