import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {
  AppVersion,
  AppVersionParsedPublicGitSource,
  AppVersionStatuses,
  ZeropsYamlErrors,
  parsePublicGitUrl
} from '@zerops/models/app-version';
import { ServiceStackTypes } from '@zerops/models/service-stack';
import { BuildProcessStates, getPipelineState } from '@zerops/zui/build-state-steps';

@Component({
  selector: 'zui-pipeline-desc',
  templateUrl: './pipeline-desc.component.html',
  styleUrls: [ './pipeline-desc.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PipelineDescComponent implements OnChanges {

  mode = 'new';

  buildProcessStates = BuildProcessStates;

  serviceDisplayValue: string;

  @Input()
  noPipelineLinkDetail = false;

  @Input()
  set appVersion(v) {

    if (v && !!v.publicGitSource) {
      this.parsedPublicGitSource = parsePublicGitUrl(v.publicGitSource.gitUrl);
    }

    if (v) {
      this.pipelineState = getPipelineState(v);

      this.statusName = 'Finished';

      if (this.pipelineState.INIT_BUILD_CONTAINER === BuildProcessStates.Waiting || (!v.build && this.pipelineState.DEPLOY === BuildProcessStates.Waiting)) {
        this.statusName = 'Waiting to run';
      }

      if (this.pipelineState.INIT_BUILD_CONTAINER === BuildProcessStates.Running || this.pipelineState.INIT_PREPARE_CONTAINER === BuildProcessStates.Running || this.pipelineState.RUN_BUILD_COMMANDS === BuildProcessStates.Running || this.pipelineState.DEPLOY === BuildProcessStates.Running) {
        this.statusName = 'Running';
      }

      if (
        v?.status === this.appVersionStatuses.WaitingToBuild
        && (
          this.errorData?.code === ZeropsYamlErrors.ZeropsBuildFileNotFound
          || this.errorData?.code === ZeropsYamlErrors.YamlValidationInvalidYaml
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlInvalidYaml
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlSetupNotFound
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlServiceNotFound
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlTooManyExtends
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlInvalidParameter
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlMissingParameter
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlDocumentRootRequired
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlStartCommandRequired
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlNoProbeSpecified
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlTooManyProbes
          || this.errorData?.code === ZeropsYamlErrors.ZeropsBuildFileTooLarge
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlRunOsRequired
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlRunBaseRequired
          || this.errorData?.code === ZeropsYamlErrors.ZeropsYamlRunOsBaseInvalid
        )
      ) {
        this.statusName = 'Failed';
      }

      if ([
        this.appVersionStatuses.BuildValidationFailed,
        this.appVersionStatuses.BuildFailed,
        this.appVersionStatuses.DeployFailed,
        this.appVersionStatuses.PreparingRuntimeFailed
      ].includes(v.status)) {
        this.statusName = 'Failed';
      }

      if (v?.status === this.appVersionStatuses.Cancelled) {
        this.statusName = 'Cancelled';
      }

    }

    this._appVersion = v;
  }
  get appVersion() {
    return this._appVersion;
  }

  @Input()
  actionName: string;

  @Input()
  serviceName: string;

  @Input()
  serviceType: string;

  @Input()
  serviceTypeId: ServiceStackTypes;

  @Input()
  serviceId: string;

  @Input()
  projectName: string;

  @Input()
  projectId: string;

  @Input()
  status: 'RUNNING' | 'PENDING' | 'SUCCESS' | 'FAILED' = 'RUNNING';

  @Input()
  disableLink = false;

  @Input()
  hasHardBreakAfterService = false;

  @Input()
  errorData: {
    code: string;
    message: string;
  };

  @Output()
  linkClicked = new EventEmitter<void>();

  pipelineState = getPipelineState();
  statusName: string;
  appVersionStatuses = AppVersionStatuses;
  parsedPublicGitSource: AppVersionParsedPublicGitSource;
  serviceTypes = ServiceStackTypes;
  private _appVersion: AppVersion;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['serviceTypeId'] || changes['serviceType']) {
      this.serviceDisplayValue = this.serviceType;
    }
  }
}
