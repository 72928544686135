export const GO_IMPORT_YAML = `project:
  name: recipe-go
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: go@1.22
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-go

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
