import { Component, Input } from '@angular/core';

export enum BuildProcessStates {
  Queued = 'queued',
  Timer = 'timer',
  Waiting = 'waiting',
  Running = 'running',
  Finished = 'finished',
  Failed = 'failed',
  Cancelled = 'cancelled'
}

@Component({
  selector: 'zui-process-step-state',
  templateUrl: './process-step-state.component.html',
  styleUrls: [ './process-step-state.component.scss' ]
})
export class ProcessStepStateComponent {
  buildProcessStates = BuildProcessStates;

  @Input()
  running = false;

  @Input()
  state: BuildProcessStates;
}
