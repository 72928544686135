import { createAction } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { HashMap } from '@zerops/zef/core';

export const getStorageInfo = createAction(
  '[@zerops/zerops/feature/object-storage-usage] get-usage',
  (id: string) => ({ id })
);

export const getStorageInfoFail = createAction(
  '[@zerops/zerops/feature/object-storage-usage] get-usage/fail',
  (err: HttpErrorResponse) => ({ err })
);

export const getStorageInfoSuccess = createAction(
  '[@zerops/zerops/feature/object-storage-usage] get-usage/success',
  (usageMap: HashMap<{
    used: number;
    rawPolicy: string;
  }>) => ({ usageMap })
);
