import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './client-base.constant';

const en = {
  setAvatarSuccess: 'User avatar was added successfully.',
  removeAvatarSuccess: 'User avatar was removed successfully.'
};

export type ClientBaseTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ClientBaseTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
