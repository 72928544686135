import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocsPopService {

  private _ref: any;
  private _entity$ = new BehaviorSubject<string>(null);

  getRef() {
    return this._ref;
  }

  saveRef(ref: any) {
    this._ref = ref;
  }

  removeRef() {
    this._ref = undefined;
  }

  setEntity(ent: string) {
    this._entity$.next(ent);
  }

  getEntity$() {
    return this._entity$.asObservable();
  }

}
