import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusIconBaseModule } from '@zerops/zui/status-icon-base';
import { UploadingIconModule } from '@zerops/zui/uploading-icon';
import { ServiceStackStatusIconComponent } from './service-stack-status-icon.component';

@NgModule({
  declarations: [ ServiceStackStatusIconComponent ],
  imports: [
    CommonModule,
    StatusIconBaseModule,
    UploadingIconModule
  ],
  exports: [ ServiceStackStatusIconComponent ]
})
export class ServiceStackStatusIconModule { }
