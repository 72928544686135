export type Levels = 'client' | 'project' | 'service';

export class NotificationsPopState {
  level: Levels;
  id?: string;
}

export interface NotificationsPopOpenPayload {
  level: Levels;
  id?: string;
}
