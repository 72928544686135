import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeSelectorComponent } from './theme-selector.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '../action-list-item';

@NgModule({
  declarations: [ ThemeSelectorComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ActionListItemModule
  ],
  exports: [ ThemeSelectorComponent ]
})
export class ZuiThemeSelectorModule {

}
