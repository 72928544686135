import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './deletion-warning-dialog.constant';

const en = {
  entityDeleted: 'Entity deleted',
  desc: 'The <strong>{entity}</strong> has been deleted.',
  goToDashboard: 'Go to Dashboard',
  entities: {
    'service-stack': 'service',
    project: 'project'
  }
};

export type DeletionWarningDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class DeletionWarningDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
