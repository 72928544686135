import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { map } from 'rxjs/operators';
import { pipelineDetailDialogOpen } from './pipeline-detail-dialog.action';
import { FEATURE_NAME } from './pipeline-detail-dialog.constant';

@Injectable()
export class PipelineDetailDialogEffect {

  private _pipelineDetailDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(pipelineDetailDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  constructor(private _actions$: Actions) { }
}
