<zef-dialog
  [open]="state.open"
  [options]="{ restoreFocus: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar maxHeight="80vh">
      <zui-form-section
        class="__form-section"
        separator="none">

        <span *zuiFormSectionTitle>
          Open a public port on <br/> <strong>{{ state.ip }}</strong>
        </span>

        <div *zuiFormSectionDesc>
          Select which public port on the IP address assigned <br/>to the project will route to which service and its port.
        </div>

        <zui-port-routing-form
          class="__form"
          #formRef
          (submit)="onAdd$.next()"
          (removeFirewallIpRange)="onRemoveFirewallIpRange$.next($event)"
          [state]="state.formState"
          [portsWithServiceStackInfo]="state.projectPorts">
          @if (state.formState?.controls.firewall.value) {
            <button
              type="button"
              (click)="onAddEmptyFirewallIpRange$.next()"
              mat-button
              class="__new-button  c-button--full">
              Add another IP address
            </button>
          }
        </zui-port-routing-form>

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="addKey">
            <button
              class="__button c-button--full"
              color="accent"
              [disabled]="(!state.formState?.isValid || state.formState?.controls.publicIpType?.value === publicIpTypes.IpV4) && (!state.project?.publicIpV4 && state.formState?.controls.publicPort?.value === 80 || state.formState?.controls.publicPort?.value === 443)"
              (click)="formRef.triggerSubmit()"
              mat-raised-button
              type="button">
              Open a new public port
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>
