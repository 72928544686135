import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Process } from '@zerops/models/process';

@Injectable({ providedIn: 'root' })
export class HttpRoutingBaseApi {
  constructor(private _http: HttpClient) {}

  sync$(id: string) {
    return this._http.put<Process>(
      `/api/project/${id}/sync-public-http-routing`,
      {}
    );
  }

}
