import {
  getHistoryStatisticsGroupRangeKey,
  HistoryStatistic,
  StatisticsGroupBy,
  transformResourceHistoryContainerGroup,
  transformResourceHistoryServiceGroup
} from '@zerops/models/resource-statistics';
import { HashMap } from '@zerops/zef/core';
import mergeWith from 'lodash-es/mergeWith';
import { FEATURE_NAME } from './resource-statistics-base.constant';
import {
  HistoryStatisticsGroupRequestPayload,
  HistoryStatisticsGroupUpdateSuccessPayload
} from './resource-statistics-base.model';

export function getCurrentResourceStatisticsSubscriptionName(
  name: string,
  id?: string
) {
  const base = `${FEATURE_NAME}_current-resource-stats_##${name}##`;
  if (id) { return `${base}-${id}`; }

  return base;
}

export const historyDataExists = (
  payload: HistoryStatisticsGroupRequestPayload,
  historyData: HashMap<any>
) => {

  const {
    groupRange,
    groupBy,
    serviceId,
    projectId,
    containerId
  } = payload;

  const rangeKey = getHistoryStatisticsGroupRangeKey(groupRange.key);

  // if history group doesn't exist at all
  if (historyData[groupBy] === undefined) {
    return false;
  }

  // if there's projectId and there is no serviceId
  // in payload we need to check
  // trackedProjects, because projectId is being used
  // to load multiple services
  if (
    groupBy === StatisticsGroupBy.Service
    && (!!projectId && !serviceId)
    && historyData?.trackedProjects?.[projectId]
  ) {
    return true;
  }

  // if we are checking single service existance
  // of timegroup inside a group
  if (
    !!serviceId
    && groupBy === StatisticsGroupBy.Service
    && historyData[groupBy]?.[serviceId]?.[rangeKey] !== undefined
  ) {
    return true;
  }

  // if we are checking single container existance
  // of timegroup inside a group
  if (
    !!containerId
    && groupBy === StatisticsGroupBy.Container
    && historyData[groupBy]?.[containerId]?.[rangeKey] !== undefined
  ) {
    return true;
  }

  // if there's serviceId in payload we need to check
  // trackedServices, because serviceId is being used
  // to load multiple containers
  if (
    groupBy === StatisticsGroupBy.Container
    && (!!serviceId && !containerId)
    && historyData?.trackedServices?.[serviceId]
  ) {
    return true;
  }

  return false;
};

export function getHistoryResourceStatisticsSubscriptionName(
  groupBy: string,
  limit: number,
  timeGroupBy: string,
  projectId?: string,
  serviceId?: string,
  containerId?: string,
  key?: string
) {
  let base = `${FEATURE_NAME}_history-statistics-group_${groupBy}_${limit}_${timeGroupBy}_##${key}##`;

  if (projectId) { base = `${base}-${projectId}`; }
  if (serviceId) { base = `${base}-${serviceId}`; }
  if (containerId) { base = `${base}-${containerId}`; }

  return base;
}

export function historyResourceStatisticsMerge(
  data: HistoryStatisticsGroupUpdateSuccessPayload,
  state: HashMap<HistoryStatistic>
) {

  const {
    items,
    groupBy,
    key,
    projectId,
    serviceId
  } = data;

  const groupKey = key;

  if (groupBy === StatisticsGroupBy.Service) {
    const d = transformResourceHistoryServiceGroup(items, groupKey);

    const s = {
      ...state,
      [groupBy]: mergeWith(
        { ...state[groupBy] },
        d,
        (srcValue, objValue) => {

          const r = mergeWith(
            { ...srcValue },
            { ...objValue },
            (srcValueInner, objValueInner) => {
              const innerR = [
                ...(srcValueInner || []),
                ...(objValueInner || [])
              ];

              // update vs initial set
              return srcValueInner !== undefined && objValueInner?.length
                ? innerR.slice(objValueInner.length, innerR.length)
                : innerR;
            }
          );

          return r;
        }
      )
    };

    if (!!projectId && !serviceId) {
      s['trackedProjects'] = {
        ...(state.trackedProjects || {}),
        [projectId]: true
      };
    }

    return s;
  }

  if (groupBy === StatisticsGroupBy.Container) {
    const d = transformResourceHistoryContainerGroup(items, groupKey);

    const s = {
      ...state,
      [groupBy]: mergeWith(
        { ...state[groupBy] },
        d,
        (objValue, srcValue) => ({
          ...srcValue,
          ...objValue
        })
      )
    };

    if (!!serviceId) {
      s['trackedServices'] = {
        ...(state.trackedServices || {}),
        [serviceId]: true
      };
    }

    return s;
  }

}
