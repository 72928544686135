<table class="mat-datetimepicker-calendar-table">
  <thead class="mat-datetimepicker-calendar-table-header"></thead>
  <tbody
    (@slideCalendar.done)="_calendarStateDone()"
    (selectedValueChange)="_yearSelected($event)"
    [@slideCalendar]="_calendarState"
    [activeCell]="_getActiveCell()"
    [numCols]="4"
    [rows]="_years"
    [selectedValue]="_selectedYear"
    [todayValue]="_todayYear"
    allowDisabledSelection="true"
    matDatetimepickerCalendarBody
    role="grid"
  ></tbody>
</table>
