import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { PortRoutingItemModule } from '@zerops/zui/port-routing-item';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { EffectsModule } from '@ngrx/effects';
import { PortRoutingActionsPopModule } from './modules';
import { PortRoutingListContainer } from './port-routing-list.container';
import { PortRoutingListEffect } from './port-routing-list.effect';

@NgModule({
  declarations: [ PortRoutingListContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    PortRoutingItemModule,
    EffectsModule.forFeature([ PortRoutingListEffect ]),
    PortRoutingActionsPopModule,
    ZuiMoreButtonModule
  ],
  exports: [ PortRoutingListContainer ]
})
export class PortRoutingListModule {

}
