import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { PortRoutingActionsPopContainer } from './port-routing-actions-pop.container';

@NgModule({
  declarations: [ PortRoutingActionsPopContainer ],
  imports: [
    CommonModule,
    MatListModule,
    MaterialStackModule,
    SatPopoverModule,
    ActionListItemModule,
    TranslocoModule,
    ZefDialogModule
  ],
  exports: [ PortRoutingActionsPopContainer ]
})
export class PortRoutingActionsPopModule {

}
