import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AppVersionBaseEffect } from './app-version-base.effect';
import { AppVersionBaseTranslationsModule } from './app-version.translations';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      AppVersionBaseEffect,
      AppVersionBaseTranslationsModule
    ])
  ]
})
export class AppVersionBaseModule {

}
