import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Directive,
  TemplateRef,
  ContentChild
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[zuiFormSectionTitle]'
})
export class FormSectionTitleDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[zuiFormSectionDesc]'
})
export class FormSectionDescDirective {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'zui-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: [ './form-section.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionComponent {

  @Input()
  set full(v: string | boolean) {
    this._full = coerceBooleanProperty(v);
  }
  get full(): boolean {
    return this._full;
  }

  @Input()
  separator: 'top' | 'bottom' | 'none' | 'both' = 'bottom';

  @Input()
  separatorSpacing: 'small' | 'medium' | 'large' | 'huge' = 'medium';

  @Input()
  separatorSize: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  docButtonText: string;

  @Input()
  docButtonLink: string;

  @Input()
  isZen: boolean;

  @ContentChild(FormSectionTitleDirective, { static: false })
  titleRef: FormSectionTitleDirective;

  @ContentChild(FormSectionDescDirective, { static: false })
  descRef: FormSectionDescDirective;

  @HostBinding('class.is-full')
  get classIsFull() {
    return this.full;
  }

  private _full: boolean;
}
