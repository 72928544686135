import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { ZefPermissionService } from './permission.service';

export const zefPermissionGuardActivation = (route: ActivatedRouteSnapshot) => {
  const _permission = inject(ZefPermissionService);
  const _router = inject(Router);

  return _permission
    .authorize(route.data['zefPermissions'].rule)
    .pipe(
      take(1),
      map((res) => {
        if (res) {
          return true;
        } else {
          if (route.data['zefPermissions'].redirectTo) {
            _router.navigate(route.data['zefPermissions'].redirectTo);
          }
          return false;
        }
      })
    );
}
