export const BUN_IMPORT_YAML = `project:
  name: recipe-bun
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: bun@1.1
    buildFromGit: https://github.com/zeropsio/recipe-bun
    enableSubdomainAccess: true

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
