import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { SatPopoverModule } from '@zerops/zef/popover';
import { RoutingLocationItemModule } from '@zerops/zui/routing-location-item';
import { DnsCheckStatusIconModule } from '@zerops/zui/dns-check-status-icon';
import { HttpRoutingItemComponent } from './http-routing-item.component';
import { HttpRoutingItemTranslationsModule } from './http-routing-item.translations';

@NgModule({
  declarations: [ HttpRoutingItemComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    DynamicPopAnchorModule,
    MatTooltipModule,
    ZuiSeparatorComponent,
    HttpRoutingItemTranslationsModule,
    RoutingLocationItemModule,
    SatPopoverModule,
    DnsCheckStatusIconModule
  ],
  exports: [ HttpRoutingItemComponent ]
})
export class HttpRoutingItemModule {

}
