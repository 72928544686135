import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { TransactionDebit } from '@zerops/models/transaction-debit';
import { Sort } from '@zerops/zef/sort';

@Injectable({ providedIn: 'root' })
export class TransactionDebitEntity extends EntityService<TransactionDebit> {

  defaultSort: Sort = {
    key: 'createdDate',
    direction: 'desc'
  };

  list$(
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: TransactionDebit) => any)> = [ this.defaultSort.key ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ this.defaultSort.direction ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketSubscription: ZefWebsocketService
  ) {
    super(ApiEntityKeys.TransactionDebit, store, collectionManager, websocketSubscription);
  }
}
