<mat-datetimepicker-calendar
  (_userSelection)="datetimepicker.close()"
  (selectedChange)="onSelectionChange($event)"
  (viewChanged)="datetimepicker._viewChanged($event)"
  [ariaNextMonthLabel]="datetimepicker.ariaNextMonthLabel"
  [ariaNextYearLabel]="datetimepicker.ariaNextYearLabel"
  [ariaPrevMonthLabel]="datetimepicker.ariaPrevMonthLabel"
  [ariaPrevYearLabel]="datetimepicker.ariaPrevYearLabel"
  [preventSameDateTimeSelection]="datetimepicker.preventSameDateTimeSelection"
  [attr.mode]="datetimepicker.mode"
  [dateFilter]="datetimepicker._dateFilter"
  [id]="datetimepicker.id"
  [maxDate]="datetimepicker._maxDate"
  [minDate]="datetimepicker._minDate"
  [multiYearSelector]="datetimepicker.multiYearSelector"
  [selected]="datetimepicker._selected"
  [startAt]="datetimepicker.startAt"
  [startView]="datetimepicker.startView"
  [timeInterval]="datetimepicker.timeInterval"
  [twelvehour]="datetimepicker.twelvehour"
  [type]="datetimepicker.type"
  cdkTrapFocus
  class="mat-typography"
>
</mat-datetimepicker-calendar>
