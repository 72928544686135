import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ProcessesPopTriggerComponent } from './processes-pop-trigger.component';

@NgModule({
  declarations: [ ProcessesPopTriggerComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    SatPopoverModule,
    DynamicPopAnchorModule
  ],
  exports: [ ProcessesPopTriggerComponent ]
})
export class ZuiProcessesPopTriggerModule {

}
