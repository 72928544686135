<!-- TODO: translate -->
<zef-form
  [state]="state"
  (submit)="submit.emit(state)">

  <span
    *ngIf="state.controls.publicPort?.value === 80 || state.controls.publicPort?.value === 443"
    class="__port-warning">
    <zui-docs-text>
      Ports 80 and 443 are reserved for Zerops. <a data-zui-docs="routing/access-through-ip-and-firewall.html">documentation</a>.
    </zui-docs-text>
  </span>

  <!-- ports -->
  <div class="__port-control">
    <div class="__port-control-grid">

      <div class="__port-control-grid-item">
        <zef-form-field>
          <mat-form-field
            color="accent"
            class="__public-form-field">

            <mat-label>Public Port on the IP</mat-label>

            <input
              matInput
              [ngrxFormControlState]="state.controls.publicPort"
              [ngrxEnableFocusTracking]="true"
              [placeholder]="'Enter port...'"
              type="number"
            />

          </mat-form-field>
        </zef-form-field>
      </div>

      <div class="u-text--center">
        <mat-icon class="__arrow-icon">arrow_right_alt</mat-icon>
      </div>

      <div class="__port-control-grid-item">
        <div class="__internal-wrap">
          <zef-form-field>
            <mat-form-field
              color="accent"
              class="__internal-form-field">

              <mat-label>Service + Port</mat-label>

              <mat-select
                [compareWith]="compareFn"
                [ngrxFormControlState]="state.controls.internalPortData"
                [ngrxEnableFocusTracking]="true">

                <mat-optgroup
                  [label]="item[0]?.serviceStackName"
                  *ngFor="let item of portsWithServiceStackInfo; trackBy: trackBy;">
                  <mat-option
                    *ngFor="let portItem of item; trackBy: trackBy;"
                    [value]="portItem">
                    {{ portItem.protocol }}://:{{ portItem.serviceStackName }}:{{ portItem.port }}
                  </mat-option>
                </mat-optgroup>

              </mat-select>

            </mat-form-field>
          </zef-form-field>
        </div>
      </div>

    </div>
  </div>

  <!-- firewall -->
  <div class="__firewall-wrap">

    <zef-form-field last appearance="slide-toggle">
      <div class="__firewall">
        <mat-slide-toggle [ngrxFormControlState]="state.controls.firewall">
          Enable firewall on the public port
        </mat-slide-toggle>
      </div>
    </zef-form-field>

    <div class="__firewall-fields" *ngIf="state.controls.firewall.value">

      <!-- firewall policy -->
      <zef-form-field class="__firewall-policty-form-field" last [appearance]="'radio'">

        <zef-form-label>Firewall Policy type</zef-form-label>

        <mat-radio-group
          class="__group"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state.controls.firewallPolicy">

          <mat-radio-button
            class="__radio"
            [value]="firewallPolicies.Blacklist">
            <span class="__radio-text">
              <span class="__radio-type">Blacklist</span>
            </span>
          </mat-radio-button>

          <mat-radio-button
            class="__radio"
            [value]="firewallPolicies.Whitelist">
            <span class="__radio-text">
              <span class="__radio-type">Whitelist</span>
            </span>
          </mat-radio-button>

        </mat-radio-group>

      </zef-form-field>

      <span class="__ip-control-label">IP adddresses to {{ state.value.firewallPolicy }}</span>

      <div
        class="__ip-control"
        *ngFor="let control of state.controls.firewallIpRanges.controls;
          trackBy: trackBy;
          let index = index;">
        <div class="__ip-control-grid">

          <div class="__ip-control-grid-item">
            <zef-form-field>
              <mat-form-field
                color="accent"
                class="__ip-form-field">
                <mat-label>IP address</mat-label>
                <input
                  matInput
                  [ngrxFormControlState]="control.controls.ip"
                  [ngrxEnableFocusTracking]="true"
                  [placeholder]="state.controls.publicIpType.value === publicIpTypes.IpV6 ? 'Enter IPv6 address...' : 'Enter IPv4 address...'"
                  type="text"
                />
              </mat-form-field>
            </zef-form-field>
          </div>

          <div class="u-text--center">
            <span>/</span>
          </div>

          <div class="__ip-control-grid-item is-right">
            <div class="__range-wrap">

              <zef-form-field>
                <mat-form-field
                  color="accent"
                  class="__ip-form-field">
                  <mat-label>Range (optional)</mat-label>
                  <input
                    matInput
                    [ngrxFormControlState]="control.controls.range"
                    [ngrxEnableFocusTracking]="true"
                    [placeholder]="state.controls.publicIpType.value === publicIpTypes.IpV6 ? ipV6BaseRange : ipV4BaseRange"
                    type="text"
                  />
                </mat-form-field>
              </zef-form-field>

              <button
                *ngIf="state.controls.firewallIpRanges.controls?.length > 1"
                class="__remove-ip-button"
                type="button"
                (click)="removeFirewallIpRange.emit(index)"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>

            </div>
          </div>

        </div>
      </div>

    </div>

    <ng-content></ng-content>

  </div>

</zef-form>
