import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Sort } from '@zerops/zef/sort';
import { Process, ProcessStatuses } from '@zerops/models/process';
import { HashMap } from '@zerops/zef/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';

@Injectable({ providedIn: 'root' })
export class ProcessEntity extends EntityService<Process> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'asc'
  };

  defaultSort$ = of(this.defaultSort);

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.Process, store, collectionManager, websocketService);
  }

  list$(
    tag?: string | { name: string; id: string; },
    // TODO: interface for order selector / dir
    orderSelector: Array<string | ((i: Process) => any)> = [ this.defaultSort.key, 'sequence' ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ this.defaultSort.direction, 'asc' ]
  ) {
    return super.list$(tag, orderSelector, orderDir)
      // TEMP
      .pipe(
        map((d) => d
          ? d.filter((itm) => itm.actionName !== 'instanceGroup.create')
          : d
        )
      );
  }

  processMap$() {
    return super.list$().pipe(
      map((d) => {

        const all = {
          running: [],
          pending: []
        };

        const maps = d?.reduce((obj, itm) => {

          if (!obj.projectMap[itm.project.id]) {
            obj.projectMap[itm.project.id] = {
              running: [],
              pending: []
            };
          }

          if (itm.status === ProcessStatuses.RUNNING) {
            obj.projectMap[itm.project.id].running.push(itm);
            all.running.push(itm);
          }

          if (itm.status === ProcessStatuses.PENDING) {
            obj.projectMap[itm.project.id].pending.push(itm);
            all.pending.push(itm);
          }

          if (itm.serviceStacks?.length) {
            const ssId = itm.serviceStacks[0].id;

            if (!obj.serviceMap[ssId]) {
              obj.serviceMap[ssId] = {
                running: [],
                pending: []
              };
            }


            if (itm.status === ProcessStatuses.RUNNING) {
              obj.serviceMap[ssId].running.push(itm);
            }

            if (itm.status === ProcessStatuses.PENDING) {
              obj.serviceMap[ssId].pending.push(itm);
            }

          }

          return obj;

        }, {
          projectMap: {},
          serviceMap: {}
        } as {
          projectMap: HashMap<{
            running: any[];
            pending: any[];
          }>;
          serviceMap: HashMap<{
            running: any[];
            pending: any[];
          }>;
        });

        return {
          all: {
            running: orderBy(all.running, [ 'created', 'sequence' ], [ 'desc', 'asc' ]),
            pending: orderBy(all.pending, [ 'created', 'sequence' ] , [ 'asc', 'asc' ])
          },
          ...(maps || {})
        };
      })
    );
  }

}
