import { Component, OnDestroy, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { formValueOnValid } from '@zerops/zef/forms';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { updateProjectCustomEnvs } from '@zerops/zerops/core/project-base/project-base.action';
import { FEATURE_NAME } from './project-envs-edit-dialog.constant';
import { ProjectEnvsEditDialogForm } from './project-envs-edit-dialog.form';
import { ProjectEnvsEditDialogTranslations } from './project-envs-edit-dialog.translations';

@Component({
  selector: 'z-project-envs-edit-dialog',
  templateUrl: './project-envs-edit-dialog.feature.html',
  styleUrls: [ './project-envs-edit-dialog.feature.scss' ]
})
export class ProjectEnvsEditDialogFeature extends ZefReactiveComponent implements OnDestroy {

  /**
   * Dependency injections.
   */
  #store = inject(Store);
  #projectEnvsEditForm = inject(ProjectEnvsEditDialogForm);

  /**
   * Event streams properties.
   */
  onUpdate$ = new Subject<void>();
  onClose$ = new Subject<void>();

  /**
   * Forms properties.
   */
  formState$ = this.#projectEnvsEditForm.state$;

  /**
   * Sync properties.
   */
  updateKey = updateProjectCustomEnvs.type;
  dialogKey = FEATURE_NAME;

  /**
   * Async properties.
   */
  dialogState$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME))
  );
  projectId$ = this.dialogState$.pipe(
    map(({ meta }) => meta?.projectId),
    filter((d) => !!d)
  );
  projectEnvsEditDialogItemTranslations$ = this.translate$<ProjectEnvsEditDialogTranslations>(FEATURE_NAME);

  /**
   * State resolver property.
   */
  state = this.$connect({
    open: this.dialogState$.pipe(map((d) => !!d.state)),
    formState: this.formState$,
    projectEnvsEditDialogItemTranslations: this.projectEnvsEditDialogItemTranslations$
  });

  /**
   * Action streams methods.
   */
  private _updateAction$ = this.onUpdate$.pipe(
    formValueOnValid(this.#projectEnvsEditForm),
    withLatestFrom(this.projectId$),
    map(([ value, projectId ]) => updateProjectCustomEnvs({
      projectId,
      content: value.content
    }))
  )

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  )

  constructor() {
    super();

    this.$dispatchActions([
      this._updateAction$,
      this._closeAction$
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
