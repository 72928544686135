import { HashMap } from '@zerops/zef/core';
import { ProjectStatus } from './project-status.model';

export const getProjectsStatusesMap = (d: ProjectStatus[]): HashMap<ProjectStatus> => {

  const projectStatusesMap = d?.reduce((obj, itm) => {
    if (!obj[itm.projectId]) { obj[itm.projectId] = {}; }
    obj[itm.projectId] = itm;

    return obj;
  }, {});

  return projectStatusesMap;
};

export const getProjectStatus = (d: ProjectStatus[], projectId: string) => {
  const projectStatusesMap = getProjectsStatusesMap(d);
  return projectStatusesMap && projectStatusesMap[projectId] ? projectStatusesMap[projectId] : undefined;
};
