import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZefStateSwitcherState } from './state-switcher.model';
import { FEATURE_NAME } from './state-switcher.constant';

export const selectZefStateSwitcherRootState = createFeatureSelector<ZefStateSwitcherState>(FEATURE_NAME);

export const selectZefStateSwitcherStates = createSelector(
  selectZefStateSwitcherRootState,
  (state) => state.states
);

export const selectZefStateSwitcherState = (key: string) => createSelector(
  selectZefStateSwitcherStates,
  (state) => state[key]
);
