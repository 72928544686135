import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './sync-dialog.constant';

const en = {
  httpRoutingTitle: 'Out of sync public access through your <strong>Domains</strong>.',
  portRoutingTitle: 'Out of sync public access through <strong>IP Addresses</strong>.',
  userDataTitle: 'Out of sync <strong>Service environment Variables</strong>.',
  publishHttpChanges: '{num, plural, =1 {Publish # domain access change} other {Publish # domain access changes}}',
  publishPortChanges: '{num, plural, =1 {Publish # IP access change} other {Publish # IP access changes}}',
  publishOnServices: '{num, plural, =1 {on # service} other {on # services}}',
  applyChangesCountless: 'Publish changes',
  synchronizeUserData: `{num, plural, =0 {Commit service changes}
  =1 {Commit # change}
  other {Commit # changes}}`,
  viewDetails: 'View details',
  currentService: 'Current Service',
  otherServices: 'Other Services'
};

export type SyncDialogTranslations = typeof en;

@NgModule()
export class SyncDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
