import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogClose, onZefDialogOpen } from '@zerops/zef/dialog';
import { ProjectEnvEditForm } from '@zerops/zui/project-env-edit-form';
// import { ZefSnackService } from '@zerops/zef/snack';
import { map, filter, switchMap, delay } from 'rxjs/operators';
import { FEATURE_NAME } from './project-env-edit-dialog.constant';
import { updateProjectCustomEnvSuccess } from '@zerops/zerops/core/project-base/project-base.action';

@Injectable()
export class ProjectEnvEditDialogEffect {

  /*
  onProjectEnvUpdateDialogOpen$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(this._userDataEntity.updateOne.type),
    map(({ meta: { key, content, type } }) => this._projectEnvEditForm.setValue({ key, content, type }))
  ));
  */

  onDialogOpenFocusInput$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    delay(100),
    map(() => this._projectEnvEditForm.focus('key'))
  ));

  #onProjectEnvUpdateSuccess$ = this._actions$.pipe(
    ofType(updateProjectCustomEnvSuccess)
  );

  onUpdateSuccessDialogClose$ = createEffect(() => this.#onProjectEnvUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    delay(500),
    switchMap(() => [
      this._projectEnvEditForm.reset(),
      this._projectEnvEditForm.setDefaultValues()
    ])
  ));

  // onUpdateSuccessNotification$ = createEffect(() => this._onProjectEnvUpdateSuccess$.pipe(
  //   switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.updateSuccess` }))
  // ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _projectEnvEditForm: ProjectEnvEditForm
    // private _snack: ZefSnackService,
  ) { }
}
