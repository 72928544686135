import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Process } from '@zerops/models/process';
import { ProcessItemModes, ProcessItemTranslations } from '@zerops/zui/process-item';
import { AppVersionItemTranslations, APP_VERSION_ITEM_FEATURE_NAME } from '@zerops/zui/app-version-item';
import { ZefReactiveComponent, HashMap } from '@zerops/zef/core';
import { AppVersion } from '@zerops/models/app-version';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-processes-card',
  templateUrl: './processes-card.component.html',
  styleUrls: [ './processes-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessesCardComponent extends ZefReactiveComponent {

  showAllPendingProcesses$ = new Subject<boolean>();

  @ObservableInput()
  @Input('runningProcesses')
  runningProcesses$!: Observable<Process[]>;

  @ObservableInput()
  @Input('pendingProcesses')
  pendingProcesses$!: Observable<Process[]>;

  @ObservableInput()
  @Input('pipelineErrors')
  pipelineErrors$!: Observable<PipelineError[]>;

  @Input()
  maxProcessesNumber = 5;

  @Input()
  processItemTranslations: ProcessItemTranslations;

  @Input()
  processCancelProgressesMap: HashMap<boolean> = {};

  @Input()
  mode: ProcessItemModes;

  @Input()
  hideCancel = false;

  @Input()
  highlightId?: string;

  @Input()
  maxHeight = 'calc(100vh - 150px)';

  @Output()
  cancelClicked = new EventEmitter<string>();

  @Output()
  internalLinkClicked = new EventEmitter<void>();

  @Output()
  pipelineDetailClicked = new EventEmitter<{ appVersion: AppVersion; pipelineErrors: PipelineError[]; }>();

  @Output()
  buildCancelClicked = new EventEmitter<string>();

  // -- async
  displayedPendingProcesses$ = combineLatest([
    this.pendingProcesses$,
    this.showAllPendingProcesses$.pipe(startWith(false))
  ]).pipe(
    map(([ processes, showAll ]) => showAll
      ? processes
      : processes?.slice(0, this.maxProcessesNumber)
    )
  );
  appVersionItemTranslations$ = this.translate$<AppVersionItemTranslations>(APP_VERSION_ITEM_FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    runningProcesses: this.runningProcesses$,
    pendingProcesses: this.pendingProcesses$,
    pipelineErrors: this.pipelineErrors$,
    displayedPendingProcesses: this.displayedPendingProcesses$,
    appVersionItemTranslations: this.appVersionItemTranslations$
  });

  trackById(_: number, item: Process) {
    return item.id;
  }

}
