import { Component } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { UserDataAddFormState } from './user-data-add-form.form';

@Component({
  selector: 'zui-user-data-add-form',
  templateUrl: './user-data-add-form.component.html',
  styleUrls: [ './user-data-add-form.component.scss' ],
})
export class UserDataAddFormComponent extends ZefFormBase<UserDataAddFormState> {
}
