<mat-card class="__card">
  <div class="__grid">

    <div class="__grid-item">

      <div>
        {{ data.message || ('Unknown error from ' + data.key + ' contact us for more.') }}
      </div>

      <ng-container
        *ngIf="data.originalError?.error?.error?.meta?.length">
        <div
          *ngFor="let meta of data.originalError?.error?.error?.meta"
          class="__meta">

          <span *ngIf="meta.parameter">{{ meta.parameter }}</span>

          <ng-container *ngIf="meta.parameter"> - </ng-container>

          <span *ngIf="meta.message">{{ meta.message }}</span>

        </div>
      </ng-container>

    </div>

    <div class="u-text--right  __grid-item">
      <button
        class="__button"
        mat-button
        type="button"
        (click)="instance.closeWithAction()">
        <!-- TODO: translation -->
        Close
      </button>
    </div>

  </div>
</mat-card>
