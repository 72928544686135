import { Injectable } from '@angular/core';
import { Store, MemoizedSelector } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { combineLatest, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Sort } from '@zerops/zef/sort';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';
import { ServiceStackTypeCategories, ServiceStack } from '@zerops/models/service-stack';
import { Project, ProjectWithServiceStacks } from '@zerops/models/project';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';

@Injectable({ providedIn: 'root' })
export class ProjectEntity extends EntityService<Project> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'desc'
  };

  defaultSort$ = of(this.defaultSort);

  private _defaultServiceStackSortDirection: Array<boolean | 'asc' | 'desc'> = [ 'asc', 'asc', 'asc' ];
  private _defaultServiceStackSortOrder: Array<string | ((i: ServiceStack) => number)> = [
    ({ isSystem }) => isSystem ? 1 : -1,
    ({ serviceStackTypeInfo }) => serviceStackTypeInfo?.serviceStackTypeCategory === ServiceStackTypeCategories.SharedStorage
      || serviceStackTypeInfo?.serviceStackTypeCategory === ServiceStackTypeCategories.ObjectStorage
      ? 1
      : -1,
    'created'
  ];

  listWithServiceStacks$ = (
    tag?: string,
    orderSelector: Array<string | ((i: Project) => number)> = [ this.defaultSort.key ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ this.defaultSort.direction ],
    serviceStackOrderSelector: Array<string | ((i: ServiceStack) => number)> = this._defaultServiceStackSortOrder,
    serviceStackOrderDir: Array<boolean | 'asc' | 'desc'> = this._defaultServiceStackSortDirection,
    emptyAsUndefined = false
  ) => combineLatest([
    this.list$(
      tag,
      orderSelector?.length && orderSelector[0] !== undefined
        ? orderSelector
        : [ this.defaultSort.key ],
      orderDir?.length && orderDir[0] !== undefined
        ? orderDir
        : [ this.defaultSort.direction ]
    ).pipe(map((v) => emptyAsUndefined ? v : (v || []))),
    this._serviceStackEntity
      .list$()
      .pipe(map((stacks) => groupBy(
        // TODO: move to a better place
        stacks?.filter((s) => ![
          ServiceStackTypeCategories.Build,
          ServiceStackTypeCategories.Prepare
        ].includes(s.serviceStackTypeInfo?.serviceStackTypeCategory)),
        'projectId'
      )))
  ]).pipe(map(([ projects, stackMap ]) => projects?.map((project) => this._appendOrderedServiceStacks(
    project,
    stackMap[project.id],
    serviceStackOrderSelector,
    serviceStackOrderDir
  ))));

  allWithServiceStacks$ = (
    serviceStackOrderSelector: Array<string | ((i: ServiceStack) => number)> = this._defaultServiceStackSortOrder,
    serviceStackOrderDir: Array<boolean | 'asc' | 'desc'> = this._defaultServiceStackSortDirection
  ) => combineLatest([
    this.all$(),
    this._serviceStackEntity
      .list$()
      .pipe(map((stacks) => groupBy(
        stacks?.filter((s) => s.serviceStackTypeInfo?.serviceStackTypeCategory !== ServiceStackTypeCategories.Build),
        'projectId'
      )))
  ]).pipe(map(([ projects, stackMap ]) => projects.map((project) => this._appendOrderedServiceStacks(
    project,
    stackMap[project.id],
    serviceStackOrderSelector,
    serviceStackOrderDir
  ))));

  entityByIdWithServiceStacks$ = (
    selectorOrId: MemoizedSelector<any, string> | string,
    serviceStackOrderSelector?: Array<string | ((i: ServiceStack) => number)>,
    serviceStackOrderDir?: Array<boolean | 'asc' | 'desc'>
  ) => combineLatest([
    this.entityById$(selectorOrId).pipe((filter((d) => !!d))),
    this._serviceStackEntity.list$().pipe(map((v) => v || []))
  ]).pipe(
    map(([ project, serviceStacks ]) => this._appendOrderedServiceStacks(
      project,
      serviceStacks?.filter((s) => s.project.id === project.id
        // TODO: move to a better place
        && ![
          ServiceStackTypeCategories.Build,
          ServiceStackTypeCategories.Prepare
        ].includes(s.serviceStackTypeInfo?.serviceStackTypeCategory)
      ),
      serviceStackOrderSelector?.length && serviceStackOrderSelector[0] !== undefined
        ? serviceStackOrderSelector
        : this._defaultServiceStackSortOrder,
      serviceStackOrderDir?.length && serviceStackOrderDir[0] !== undefined
        ? serviceStackOrderDir
        : this._defaultServiceStackSortDirection
    ))
  );

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService,
    private _serviceStackEntity: ServiceStackEntity,
  ) {
    super(ApiEntityKeys.Project, store, collectionManager, websocketService);
  }

  private _appendOrderedServiceStacks(
    project: Project,
    serviceStacks: ServiceStack[],
    serviceStackOrderSelector: Array<string | ((i: ServiceStack) => number)>,
    serviceStackOrderDir: Array<boolean | 'asc' | 'desc'>
  ) {
    let stacks: ServiceStack[];
    if (serviceStackOrderSelector) {
      stacks = orderBy(serviceStacks, serviceStackOrderSelector, serviceStackOrderDir);
    }  else {
      stacks = serviceStacks;
    }

    return {
      ...project,
      serviceStacks: stacks
    } as ProjectWithServiceStacks;
  }
}
