import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZuiThreeDotsModule } from '@zerops/zui/three-dots';
import { EffectsModule } from '@ngrx/effects';
import { WebsocketDebuggerEffect } from './websocket-debugger.effect';
import { WebsocketDebuggerService } from './websocket-debugger.service';
import { WebsocketDebuggerComponent } from './websocket-debugger.component';


@NgModule({
  declarations: [ WebsocketDebuggerComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ZuiThreeDotsModule,
    EffectsModule.forFeature([ WebsocketDebuggerEffect ])
  ],
  providers: [ WebsocketDebuggerService ],
  exports: [ WebsocketDebuggerComponent ]
})
export class WebsocketDebuggerModule {

}
