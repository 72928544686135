import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AddonExtendedByProjectInfo, MetricIds, SubscriptionExtendedByProjectInfo } from '@zerops/models/billing';
import { Currency } from '@zerops/models/settings';

enum Modes {
  Info = 'info',
  Project = 'project'
}

@Component({
  selector: 'zui-addon-card',
  templateUrl: './addon-card.component.html',
  styleUrls: [ './addon-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonCardComponent {

  infoHeaderSize = 'small';
  metricIds = MetricIds;
  modes = Modes;

  @Input()
  mode = Modes.Info;

  @Input()
  metricId: MetricIds;

  @Input()
  addonPrice: number;

  @Input()
  subscriptions: SubscriptionExtendedByProjectInfo[];

  @Input()
  availableAddons: AddonExtendedByProjectInfo[];

  @Input()
  subscriptionData: SubscriptionExtendedByProjectInfo;

  @Input()
  addonData: AddonExtendedByProjectInfo;

  @Input()
  generalTranslations: any;

  @Input()
  activeCurrency: Currency;

  @Output()
  buttonClick = new EventEmitter<{
    addon: AddonExtendedByProjectInfo;
    subscription: SubscriptionExtendedByProjectInfo;
  }>();

  @Output()
  linkClicked = new EventEmitter<void>();

  trackByIndex(index: number) {
    return index;
  }

}
