<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxHeight: '80vh' }"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zui-form-section
      class="__form-section"
      separator="none">

      <span *zuiFormSectionTitle>
        {{ state.projectEnvsEditDialogItemTranslations.titleDialog }}
      </span>

      <z-project-envs-edit-form
        #formRef
        class="__form"
        (submit)="onUpdate$.next()"
        [state]="state.formState"
      />
    </zui-form-section>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="updateKey">
            <button
              class="c-button--full"
              color="accent"
              mat-raised-button
              (click)="formRef.triggerSubmit()"
              type="button">
              {{ state.projectEnvsEditDialogItemTranslations.titleActionButton }}
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>