import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiDocsTextComponent } from './docs-text.component';

@NgModule({
  declarations: [ ZuiDocsTextComponent ],
  imports: [
    CommonModule
  ],
  exports: [ ZuiDocsTextComponent ]
})
export class ZuiDocsTextModule {

}
