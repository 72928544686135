import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { UserDataAddFormModule } from '@zerops/zui/user-data-add-form';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PopStateModule } from '@zerops/zui/pop-state';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { TranslocoModule } from '@ngneat/transloco';
import { UserDataAddPopComponent } from './user-data-add-pop.component';

@NgModule({
  declarations: [ UserDataAddPopComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    SatPopoverModule,
    UserDataAddFormModule,
    FormSectionModule,
    ReactiveFormsModule,
    ZefProgressErrorModule,
    PopStateModule,
    TranslocoModule,
    UserDataFieldsModule
  ],
  exports: [ UserDataAddPopComponent ]
})
export class UserDataAddPopModule {

}
