<div
  *ngIf="!hideBackButton && (creditLimit || remainingCredit)"
  class="__back-button-wrap">
  <button
    class="__back-button c-button c-button--smaller"
    mat-button
    type="button"
    (click)="backClicked.emit()">
    <mat-icon class="__button-icon">chevron_left</mat-icon>
    Back
  </button>
</div>

<zef-form
  #form
  [state]="formState"
  (submit)="updateLimitClicked.emit()"
  class="__form">

  <zef-form-field class="__field">

    <mat-slider
      class="__slider"
      thumbLabel
      min="1"
      max="100"
      step="1"
      [displayWith]="formatSliderLabel"
      [ngrxFormControlState]="formState.controls.limit">
    </mat-slider>

    <button
      *ngIf="projectStatus !== projectStatuses.Stopped"
      class="__average-limit-button"
      [style.left.px]="((recommendedLimit / 100) * 250) + 3"
      [matTooltip]="'Set to twice the average od daily project cost ($'+ (recommendedLimit < 1 ? 1 : recommendedLimit) + ')'"
      mat-button
      type="button"
      (click)="setTwiceAverageClicked.emit()">
    </button>

    <div class="__slider-labels">
      <span>$1</span>
      <span>$100</span>
    </div>

  </zef-form-field>

  <div class="__controls-grid">
    <div>
      <zef-progress-error
        full
        [loading]="updateLoading">
        <button
          class="c-button--full c-button--smaller"
          mat-raised-button
          color="accent"
          type="button"
          (click)="form?.triggerSubmit()">
          Update daily cost limit
        </button>
      </zef-progress-error>
    </div>
  </div>

</zef-form>