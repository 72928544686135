<mat-card
  *ngFor="let item of paymentSources; trackBy: trackByIndex"
  class="__card c-payment-sources-card">

  <span class="__badge c-payment-sources-badge">
    <span class="__badge-content">

      Payment source secured by

      <mat-icon
        class="__stripe-icon"
        svgIcon="stripe">
      </mat-icon>

    </span>
  </span>

  <div
    class="__grid"
    [class.has-three-items]="hideRemoveButton">

    <div class="__grid-item">

      <mat-icon
        *ngIf="item.brand === 'visa'"
        class="__icon"
        svgIcon="visa">
      </mat-icon>

      <mat-icon
        *ngIf="item.brand === 'mastercard'"
        class="__icon"
        svgIcon="mastercard">
      </mat-icon>

      <mat-icon
        *ngIf="item.brand === 'discover'"
        class="__icon"
        svgIcon="discover">
      </mat-icon>

      <mat-icon
        *ngIf="item.brand === 'american-express'"
        class="__icon"
        svgIcon="american-express">
      </mat-icon>

    </div>

    <div class="__grid-item  fx:text-right">
      <div class="__last-four">*{{ item.last4 }}</div>
    </div>

    <div class="__grid-item  fx:text-right">
      <span class="__expiration">exp. {{ item.expMonth }}/{{ item.expYear }}</span>
    </div>

    <div
      *ngIf="!hideRemoveButton"
      class="__grid-item">
      <button
        class="__remove-button"
        type="button"
        mat-icon-button
        [zuiDynamicPopAnchor]="confirmPopRef.popRef"
        #deleteButtonRef="dynamicPopAnchor"
        (click)="removingId = item.id; deleteButtonRef.open();">
        <mat-icon class="__remove-icon">clear</mat-icon>
      </button>
    </div>

  </div>

</mat-card>

<zui-confirm-pop
  #confirmPopRef
  [heading]="'Are you sure you want to remove your card from Zerops?'"
  [confirmButtonText]="'general.confirm' | transloco"
  [verticalAlign]="'below'"
  [closeButtonText]="'general.close' | transloco"
  (confirm)="removePaymentSource.emit(removingId)">
</zui-confirm-pop>
