import { NgModule, Inject } from '@angular/core';
import * as Rollbar from 'rollbar';
import { filter } from 'rxjs/operators';
import { rollbarErrorProvider, RollbarService } from './rollbar-lib.service';
import { UserEntity } from '../user-base';

@NgModule({
  providers: [ ...rollbarErrorProvider ]
})
export class RollbarLibModule {
  constructor(
    private _userEntity: UserEntity,
    @Inject(RollbarService)
    private _rollbar: Rollbar
  ) {
    this._userEntity
      .activeClientUser$
      .pipe(filter((d) => !!d))
      .subscribe(({ user }) => {
        this._rollbar.configure({
          payload: {
            person: {
              id: user.id,
              username: user.fullName,
              email: user.email
            }
          }
        });
      });
  }
}
