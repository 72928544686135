import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { PriceComponent } from './price.component';

@NgModule({
  declarations: [ PriceComponent ],
  imports: [ CommonModule, ZefPipesModule ],
  exports: [ PriceComponent ]
})
export class PriceModule {
}
