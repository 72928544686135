import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AppVersionStatusIconComponent } from './app-version-status-icon.component';
import { StatusIconBaseModule } from '../status-icon-base';

@NgModule({
  declarations: [ AppVersionStatusIconComponent ],
  imports: [ CommonModule, MatTooltipModule, StatusIconBaseModule ],
  exports: [ AppVersionStatusIconComponent ]
})
export class AppversionStatusIconModule { }
