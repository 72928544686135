import { Injectable } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { AppVersion } from '@zerops/models/app-version';
import { ServiceStack } from '@zerops/models/service-stack';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WebServiceStackActiveAppVersionPopService {

  private _ref: SatPopover;
  private _data$ = new BehaviorSubject<{ appVersion: AppVersion; serviceStack: ServiceStack; }>(undefined);

  saveRef(ref: SatPopover) {
    this._ref = ref;
  }

  getRef() {
    return this._ref;
  }

  setData(appVersion: AppVersion, serviceStack: ServiceStack) {
    if (!!appVersion && !!serviceStack) {
      this._data$.next({
        appVersion,
        serviceStack
      });
    }
  }



  getData$() {
    return this._data$.asObservable();
  }
}
