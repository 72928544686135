<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  horizontalAlign="center"
  verticalAlign="below"
  [hasArrow]="true">
  <mat-card class="__pop  z-manual-elevation  c-info-card">

    <zui-app-version-item
      class="__app-version"
      [class.is-unnamed]="(state.activeAppVersion?.source === 'CLI' || state.activeAppVersion?.source === 'GUI') && !state.activeAppVersion?.name"
      *ngIf="state.activeAppVersion"
      [data]="state.activeAppVersion"
      [id]="state.activeAppVersion?.id"
      [lastUpdate]="state.activeAppVersion?.lastupdate"
      [githubIntegration]="state.activeAppVersion?.githubIntegration"
      [gitlabIntegration]="state.activeAppVersion?.gitlabIntegration"
      [publicGitSource]="state.activeAppVersion?.publicGitSource"
      [projectId]="state.activeAppVersion?.projectId"
      [serviceStackName]="state.serviceStack?.name"
      [status]="state.activeAppVersion?.status"
      [created]="state.activeAppVersion?.created"
      [source]="state.activeAppVersion?.source"
      [name]="state.activeAppVersion?.name"
      [translations]="state.appVersionItemTranslations"
      [mode]="'shrinked'">
    </zui-app-version-item>

    <button
      (click)="onOpenContextualDialog$.next(contexts.ServiceStackDeployClick)"
      mat-stroked-button
      type="button"
      class="__button c-button--smaller c-button--routing">

      Open Builds & Deploys

      <mat-icon>navigate_next</mat-icon>

    </button>

  </mat-card>
</sat-popover>
