import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './user-data-add-dialog.constant';

const en = {
  addSuccess: 'Service env variable was added successfully.'
};

export type UserDataAddDialogTranslations = typeof en;

@NgModule()
export class UserDataAddDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
