import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AppVersionStatusComponent } from './app-version-status.component';

@NgModule({
  declarations: [ AppVersionStatusComponent ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [ AppVersionStatusComponent ]
})
export class ZuiAppVersionStatusModule { }
