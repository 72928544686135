import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState, disable, enable } from 'ngrx-forms';
import { FEATURE_NAME } from './user-data-edit-form.constant';
import { UserDataTypes } from '@zerops/models/user-data';

export interface UserDataEditFormValue {
  key: string;
  content: string;
  type: UserDataTypes;
}

export type UserDataEditFormState = FormState<UserDataEditFormValue>;

@Injectable({ providedIn: 'root' })
export class UserDataEditForm extends ZefForm<UserDataEditFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        key: '',
        content: '',
        type: null
      },
      {
        key: (s, ps) => {
          if (ps
              && ps.controls.type
              && ps.controls.type.value
              && ps.controls.type.value !== UserDataTypes.Secret) {
            return disable(s);
          } else {
            return enable(s);
          }
        }
      }
    );
  }
}
