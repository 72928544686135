import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

const CREATED_BY_TYPE_PREFIX_MAP = {
  GITHUB: 'GitHub:',
  GITLAB: 'GitLab:',
  CLI: 'zcli:',
  USER: 'Zerops:'
};

@Component({
  selector: 'zui-process-notification-shell',
  templateUrl: './process-notification-shell.component.html',
  styleUrls: [ './process-notification-shell.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessNotificationShellComponent {

  @Input()
  fullName: string;

  @Input()
  firstName: string;

  @Input()
  created: string;

  @Input()
  prefixText: string;

  @Input()
  isSystem: boolean;

  @Input()
  email: string;

  @Input()
  image: string;

  @Input()
  externalImage: string;

  @Input()
  set createdByType(v) {
    this._createdByType = v;
    this.createdByTypePrefix = CREATED_BY_TYPE_PREFIX_MAP[v];
  }
  get createdByType() {
    return this._createdByType;
  }

  @Input()
  color: 'orange' | 'red' | 'blue' | 'green' | 'grey' | 'base' = 'base';

  createdByTypePrefix: string;

  private _createdByType: 'GITHUB' | 'GITLAB' | 'CLI' | 'USER';
}
