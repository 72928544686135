<div class="__activation  c-app-version-name-activation" *ngIf="!noProcessStepState && (!!activationDate || !!lastUpdate)">

  <zui-process-step-state
    class="__activation_state-icon"
    [state]="!isFailed ? 'finished' : 'failed'">
  </zui-process-step-state>

  <strong>{{ (!isFailed ? activationDate : lastUpdate) | date: 'short' }}</strong>

</div>

<h5
  class="__name  c-app-version-name-name"
  [class.is-inline]="noProcessStepState">

  <!-- from public GIT -->
  <ng-container *ngIf="source === 'GIT'">

    <ng-container *ngIf="!noLink">
      <a class="__link" target="_blank" [href]="publicGitSource?.gitUrl">
        {{ publicGitSource?.branchName }}
        <mat-icon class="__link-icon" *ngIf="!noLinkIcon">open_in_new</mat-icon>
      </a>
    </ng-container>

    <ng-container *ngIf="noLink">
      <span class="__text">
        {{ publicGitSource?.branchName }}
      </span>
    </ng-container>

  </ng-container>

  <!-- from github -->
  <ng-container *ngIf="source === 'GITHUB'">

    <!-- BRANCH eventType -->
    <ng-container *ngIf="githubIntegration?.eventType === 'BRANCH'">

      <ng-container *ngIf="!noLink">
        <a class="__link" target="_blank" [href]="'https://github.com/' + githubIntegration?.repositoryFullName + '/tree/' + githubIntegration?.commit">{{ name }} <mat-icon class="__link-icon" *ngIf="!noLinkIcon">open_in_new</mat-icon></a>
      </ng-container>

      <ng-container *ngIf="noLink">
        <span class="__text">{{ name }}</span>
      </ng-container>

    </ng-container>

    <!-- TAG eventType -->
    <ng-container *ngIf="githubIntegration?.eventType === 'TAG'">

      <ng-container *ngIf="!noLink">
        <a class="__link" target="_blank" [href]="'https://github.com/' + githubIntegration?.repositoryFullName + '/tree/' + githubIntegration?.tagName">{{ name }} <mat-icon class="__link-icon  c-app-version-name-link-icon" *ngIf="!noLinkIcon">open_in_new</mat-icon></a>
      </ng-container>

      <ng-container *ngIf="noLink">
        <span class="__text">{{ name }}</span>
      </ng-container>

    </ng-container>

  </ng-container>

  <!-- from gitlab -->
  <ng-container *ngIf="source === 'GITLAB'">

    <!-- BRANCH eventType -->
    <ng-container *ngIf="gitlabIntegration?.eventType === 'BRANCH'">

      <ng-container *ngIf="!noLink">
        <a class="__link" target="_blank" [href]="'https://gitlab.com/' + gitlabIntegration?.repositoryFullName + '/tree/' + gitlabIntegration?.commit">{{ name }} <mat-icon class="__link-icon" *ngIf="!noLinkIcon">open_in_new</mat-icon></a>
      </ng-container>

      <ng-container *ngIf="noLink">
        <span class="__text">{{ name }}</span>
      </ng-container>

    </ng-container>

    <!-- TAG eventType -->
    <ng-container *ngIf="gitlabIntegration?.eventType === 'TAG'">

      <ng-container *ngIf="!noLink">
        <a class="__link" target="_blank" [href]="'https://gitlab.com/' + gitlabIntegration?.repositoryFullName + '/tree/' + gitlabIntegration?.tagName">{{ name }} <mat-icon class="__link-icon" *ngIf="!noLinkIcon">open_in_new</mat-icon></a>
      </ng-container>

      <ng-container *ngIf="noLink">
        <span class="__text">{{ name }}</span>
      </ng-container>

    </ng-container>

  </ng-container>

  <!-- from CLI / GUI -->
  <ng-container *ngIf="source === 'CLI' || source === 'GUI'">

    <!-- has name -->
    <ng-container *ngIf="!!name">
      {{ name }}
    </ng-container>

    <!-- unnamed -->
    <span
      class="__unnamed  c-app-version-name-unnamed"
      *ngIf="!name">
      unnamed version
    </span>

  </ng-container>

</h5>

<ng-container *ngIf="noProcessStepState">

  <div class="__date" *ngIf="isFailed && !!lastUpdate"> - {{ lastUpdate | date: 'short' }} </div>
  <div class="__date" *ngIf="!isFailed && !!activationDate"> - {{ activationDate | date: 'short' }}</div>

</ng-container>
