import { Component, ChangeDetectionStrategy } from '@angular/core';
import { StatusIconBase } from '@zerops/zui/status-icon-base';
import { ProcessStatuses } from '@zerops/models/process';

@Component({
  selector: 'zui-dns-check-status-icon',
  templateUrl: './dns-check-status-icon.component.html',
  styleUrls: [ './dns-check-status-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DnsCheckStatusIconComponent extends StatusIconBase<ProcessStatuses> {

}
