import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { FormState } from 'ngrx-forms';
import { FEATURE_GITHUB_FORM } from './remote-repository-connector.constant';

export interface RemoteRepositoryConnectorGithubFormValue {
  repositoryFullName: string;
  isActive: boolean;
  eventType: 'BRANCH' | 'TAG';
  branchName?: string;
  triggerBuild: boolean;
  tagRegex?: string;
}

export type RemoteRepositoryConnectorGithubFormState = FormState<RemoteRepositoryConnectorGithubFormValue>;

@Injectable({ providedIn: 'root' })
export class RemoteRepositoryConnectorGithubForm extends ZefForm<RemoteRepositoryConnectorGithubFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_GITHUB_FORM,
      {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false,
        tagRegex: undefined
      },
      {}
    );
  }
}
