<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar class="__scroll" maxHeight="80vh">
      <zui-form-section separator="none">

        <span *zuiFormSectionTitle>

          @if (!state.isFirstAddition) {
            <ng-container>
              Update billing info for <strong>{{ state.clientUser?.client?.accountName }}</strong>
            </ng-container>
          }

          @if (state.isFirstAddition) {
            <ng-container>
              Setup Billing info
            </ng-container>
          }

        </span>

        <div *zuiFormSectionDesc>
          @if (!state.isFirstAddition) {
            <ng-container>
              Updated billing details will automatically take effect on the first day of the following month. for manual change please contact our customer support.
            </ng-container>
          }
        </div>

        <z-billing-info-form
          #formRef
          (submit)="onEdit$.next()"
          [countryList]="state.countryList"
          [state]="state.formState"
        />

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons-grid">

      <div class="__buttons-grid-item">
        <zef-progress-error full [key]="billingInfoUpdateKey">
          <button
            class="c-button--full"
            color="accent"
            mat-raised-button
            type="button"
            [disabled]="!state.formState?.isValid"
            (click)="formRef.triggerSubmit()">

            @if (!state.isFirstAddition) {
              <ng-container>
                Update billing info
              </ng-container>
            }

            @if (state.isFirstAddition) {
              <ng-container>
                Setup billing info
              </ng-container>
            }

          </button>
        </zef-progress-error>
      </div>

      <div
        [class.is-dark-theme]="state.isDarkTheme"
        class="__buttons-grid-item">
        <button
          mat-button
          type="button"
          (click)="onClose$.next()"
          class="__close-button  c-button--full">
          {{ state.generalTranslations?.close }}
        </button>
      </div>

    </div>

  </div>
</zef-dialog>
