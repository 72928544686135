import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from '@zerops/zerops/app';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { ZefReactiveComponent } from '@zerops/zef/core';
// explicit import due to circular dependency
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base/app-version-base.entity';
import { Subject } from 'rxjs';
import { map, withLatestFrom, tap } from 'rxjs/operators';
import { FEATURE_NAME } from './deploy-shutdown-dialog.constant';
import { DeployShutdownDialogTranslations } from './deploy-shutdown-dialog.translations';

@Component({
  selector: 'z-deploy-shutdown-dialog',
  templateUrl: './deploy-shutdown-dialog.container.html',
  styleUrls: [ './deploy-shutdown-dialog.container.scss' ]
})
export class DeployShutdownDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onDeploy$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  shutdownControl = new UntypedFormControl(false);

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  serviceStackId$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.meta)
  );
  translations$ = this.translate$<DeployShutdownDialogTranslations>(FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    open: this.open$
  });

  // # Action Streams
  private _addOneAction$ = this.onDeploy$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ _, id ]) => this._appVersionEntity.addOne(
      { serviceStackId: id },
      { shutdownControl: this.shutdownControl.value }
    )),
    tap(() => {
      this.shutdownControl.reset(false);
      this.onClose$.next();
    })
  );
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _appVersionEntity: AppVersionEntity
  ) {
    super();

    this.$dispatchActions([
      this._addOneAction$,
      this._closeAction$
    ]);
  }

}
