import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ConfirmPopComponent } from './confirm-pop.component';

@NgModule({
  declarations: [ ConfirmPopComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    SatPopoverModule
  ],
  exports: [ ConfirmPopComponent ]
})
export class ConfirmPopModule { }
