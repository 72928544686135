import { ViewContainerRef, ElementRef, Input, Directive } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';

@Directive({
  selector: '[zuiDynamicPopAnchor]',
  exportAs: 'dynamicPopAnchor'
})
export class DynamicPopAnchorDirective {

  @Input()
  zuiDynamicPopAnchor: SatPopover;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef
  ) { }

  open(callOpen = true, anchor?: HTMLElement | ElementRef<HTMLElement>) {

    if (this.zuiDynamicPopAnchor) {
      this.zuiDynamicPopAnchor.setCustomAnchor(
        this._viewContainerRef,
        anchor ? anchor : this._elementRef
      );

      if (callOpen) {
        this.zuiDynamicPopAnchor.open();
      }
    }
  }

  anchor(anchor?: HTMLElement | ElementRef<HTMLElement>) {
    if (this.zuiDynamicPopAnchor) {
      this.zuiDynamicPopAnchor.setCustomAnchor(
        this._viewContainerRef,
        anchor ? anchor : this._elementRef
      );
    }
  }

  close() {
    if (this.zuiDynamicPopAnchor) {
      this.zuiDynamicPopAnchor.close();
    }
  }

  toggle() {
    this.zuiDynamicPopAnchor.isOpen() ? this.close() : this.open();
  }
}
