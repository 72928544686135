import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { DeletionWarningDialogContainer } from './deletion-warning-dialog.container';
import { DeletionWarningDialogTranslationsModule } from './deletion-warning-dialog.translations';
import { DeletionWarningDialogEffect } from './deletion-warning-dialog.effect';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ DeletionWarningDialogContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    EffectsModule.forFeature([ DeletionWarningDialogEffect ]),
    ZefDialogModule,
    DeletionWarningDialogTranslationsModule,
    ZefPipesModule
  ],
  exports: [ DeletionWarningDialogContainer ]
})
export class DeletionWarningDialogModule {

}
