import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ColorScheme } from '@zerops/zef/theme'

@Component({
  selector: 'zui-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: [ './theme-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSelectorComponent {
  @Input()
  activeMode: ColorScheme;

  @Input()
  lightMode: boolean;

  @Input()
  isDarkTheme: boolean;

  @Output()
  setTheme = new EventEmitter<ColorScheme>();
}
