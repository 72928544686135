import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserBaseEffect } from './user-base.effect';
import { FEATURE_NAME } from './user-base.constant';
import { userBaseReducer } from './user-base.reducer';
import { UserBaseTranslationsModule } from './user-base.translations';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, userBaseReducer),
    EffectsModule.forFeature([ UserBaseEffect ]),
    UserBaseTranslationsModule
  ]
})
export class UserBaseModule {

}
