import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeActionName',
  pure: true
})
export class NormalizeActionName implements PipeTransform {
  transform(input: string): any {
    return input?.replace(/\./g, '_');
  }
}
