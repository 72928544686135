import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatIconModule } from '@angular/material/icon';
import { ZeRecipeTagComponent } from './recipe-tag.component';

@NgModule({
  declarations: [ ZeRecipeTagComponent ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [ ZeRecipeTagComponent ]
})
export class ZuiRecipeTagModule {

  constructor(injector: Injector) {
    const customElement = createCustomElement(ZeRecipeTagComponent, { injector });
    customElements.define('ze-recipe-tag', customElement);
  }

}
