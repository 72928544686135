import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslocoModule } from '@ngneat/transloco';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiProcessNotificationShellModule } from '@zerops/zui/process-notification-shell';
import { ZuiPipelineDescModule } from '@zerops/zui/pipeline-desc';
import { ZuiAppVersionItemModule } from '@zerops/zui/app-version-item';
import { ZuiDurationModule } from '@zerops/zui/duration';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { ZuiBuildStateStepsModule } from '@zerops/zui/build-state-steps';
import { ZuiProcessNotificationDescModule } from '@zerops/zui/process-notification-desc';
import { ZuiProcessNotificationDurationModule } from '@zerops/zui/process-notification-duration';
import { ProcessItemComponent } from './process-item.component';
import { ProcessItemTranslationsModule } from './process-item.translations';
import { ZuiThreeDotsModule } from '../three-dots';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';

@NgModule({
  declarations: [ ProcessItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    ZuiSeparatorComponent,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    ZefPipesModule,
    ZuiDurationModule,
    ProcessItemTranslationsModule,
    ZuiAppVersionItemModule,
    ZuiPipelineDescModule,
    ZuiProcessNotificationShellModule,
    ZuiProcessStepStateModule,
    ZuiBuildStateStepsModule,
    ZuiProcessNotificationDurationModule,
    ZuiProcessNotificationDescModule,
    ZuiThreeDotsModule,
    ZefProgressErrorModule,
    ZefMessageFormatDirective,
    ZefPipesModule
  ],
  exports: [ ProcessItemComponent ]
})
export class ProcessItemModule { }
