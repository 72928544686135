import { Action, createAction, props, union } from '@ngrx/store';
import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import {
  TransactionDebitGroupRequestPayload,
  TransactionDebitGroupUpdateSuccessPayload,
  TransactionDebitPeriodCostSuccessPayload
} from './transaction-debit-base.model';

export const trackTransactionDebitGroupItem = createAction(
  '[@zerops/zerops/core/transaction-debit-base] track-transaction-debit-group-item',
  props<{ data: Action; }>()
);

export const transactionDebitGroupPrefillData = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group-prefill-data',
  props<TransactionDebitGroupUpdateSuccessPayload>()
);

export const transactionDebitGroup = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group',
  zefActionPayload<TransactionDebitGroupRequestPayload>()
);

export const transactionDebitGroupCancel = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group/cancel',
  zefActionPayload
);

export const transactionDebitGroupFail = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group/fail',
  zefActionFailPayload
);

export const transactionDebitGroupSuccess = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group/success',
  zefActionSuccessPayload<TransactionDebitGroupUpdateSuccessPayload>()
);

export const transactionDebitGroupUpdate = createAction(
  '[@zerops/zerops/core/transaction-debit-base] transaction-debit-group/update',
  props<TransactionDebitGroupUpdateSuccessPayload>()
);

export const periodCost = createAction(
  '[@zerops/zerops/core/transaction-debit-base] period-cost',
  zefActionPayload<string>()
);

export const periodCostFail = createAction(
  '[@zerops/zerops/core/transaction-debit-base] period-cost/fail',
  zefActionFailPayload
);

export const periodCostSuccess = createAction(
  '[@zerops/zerops/core/transaction-debit-base] period-cost/success',
  zefActionSuccessPayload<TransactionDebitPeriodCostSuccessPayload>()
);

const all = union({
  transactionDebitGroup,
  transactionDebitGroupFail,
  transactionDebitGroupSuccess,
  transactionDebitGroupUpdate,
  trackTransactionDebitGroupItem,
  periodCost,
  periodCostFail,
  periodCostSuccess
});

export type TransactionDebitBaseActionUnion = typeof all;
