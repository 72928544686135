import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './ws-reconnect.constant';

const en = {
  reconnecting: 'Reconnecting websocket …',
  outdated: 'The data may not be up to date.',
  connected: 'Connected!'
};

export type WsReconnectTranslations = typeof en;

@NgModule()
export class WsReconnectTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
