export enum BuildProcessStates {
  Waiting = 'waiting',
  Running = 'running',
  Finished = 'finished',
  Failed = 'failed',
  Noop = 'noop',
  Cancelled = 'cancelled',
  Activating = 'activating'
}

export interface BuildProcessStepState {
  INIT_BUILD_CONTAINER: BuildProcessStates;
  RUN_BUILD_COMMANDS: BuildProcessStates;
  INIT_PREPARE_CONTAINER: BuildProcessStates;
  RUN_PREPARE_COMMANDS: BuildProcessStates;
  DEPLOY: BuildProcessStates;
}
