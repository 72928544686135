import { createReducer, on } from '@ngrx/store';
import { ZefStateSwitcherState } from './state-switcher.model';
import {
  zefStateSwitcherSetState,
  ZefStateSwitcherActionUnion
} from './state-switcher.action';
import { zefResetState } from '../core';

const initialState = new ZefStateSwitcherState();

const actionReducer = createReducer(
  initialState,
  on(
    zefStateSwitcherSetState,
    (s, { key, state }): ZefStateSwitcherState => ({
      ...s,
      states: {
        ...s.states,
        [key]: state
      }
    })
  ),
  on(zefResetState, () => initialState)
);

export function stateSwitcherReducer(
  state: ZefStateSwitcherState,
  action: ZefStateSwitcherActionUnion
) {
  return actionReducer(state, action);
}

