import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  FormSectionComponent,
  FormSectionTitleDirective,
  FormSectionDescDirective
} from './form-section.component';

@NgModule({
  declarations: [
    FormSectionComponent,
    FormSectionTitleDirective,
    FormSectionDescDirective
  ],
  imports: [
    CommonModule,
    ZuiSeparatorComponent,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    FormSectionComponent,
    FormSectionTitleDirective,
    FormSectionDescDirective
  ]
})
export class FormSectionModule { }
