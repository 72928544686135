<zui-status-icon-base
  class="__icon-base"
  [size]="size"
  [status]="status"
  [statusText]="statusText"
  [tooltip]="tooltip"
  [pulsing]="pulsingStatusesMap[status]">
</zui-status-icon-base>

<span class="__icon-amount" *ngIf="amount !== undefined">{{ amount }}</span>
