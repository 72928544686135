<div class="__steps">

  <!-- FULL BUILD PIPELINE -->
  <ng-container *ngIf="appVersion?.source !== 'GUI' && (!!appVersion.build || !!appVersion.prepareCustomRuntime)">

    <!-- INIT BUILD -->
    <ng-container *ngIf="!!appVersion?.build">

      <zui-init-build-step
        class="__step-item  c-build-state-steps-step-item"
        [hideText]="hideText"
        [appVersion]="appVersion">
      </zui-init-build-step>

      <!-- RUN BUILD -->
      <zui-run-build-step
        class="__step-item c-build-state-steps-step-item"
        [hideText]="hideText"
        [appVersion]="appVersion"
        [pipelineErrors]="pipelineErrors">
      </zui-run-build-step>

    </ng-container>

    <!-- OPTIONAL PREPARE -->
    <ng-container *ngIf="!!appVersion?.prepareCustomRuntime">

      <!-- INIT PREPARE -->
      <zui-init-prepare-step
        class="__step-item  c-build-state-steps-step-item"
        [hideText]="hideText"
        [appVersion]="appVersion">
      </zui-init-prepare-step>

      <!-- RUN PREPARE -->
      <zui-run-prepare-step
        class="__step-item  c-build-state-steps-step-item"
        [hideText]="hideText"
        [appVersion]="appVersion"
        [pipelineErrors]="pipelineErrors">
      </zui-run-prepare-step>

    </ng-container>

    <!-- DEPLOY -->
    <zui-deploy-step
      class="__step-item  c-build-state-steps-step-item  is-last"
      [runtimeServiceName]="serviceName"
      [runtimeServiceType]="serviceType"
      [hideText]="hideText"
      [appVersion]="appVersion"
      [pipelineErrors]="pipelineErrors">
    </zui-deploy-step>

  </ng-container>

  <!-- DEPLOY WITHOUT BUILD -->
  <ng-container *ngIf="(appVersion?.source === 'GUI' || appVersion?.source === 'CLI') && !appVersion.build && !appVersion.prepareCustomRuntime">
    <div
      class="__step  is-last"
      [class.is-waiting]="buildProcessStepsState.DEPLOY === buildProcessStates.Waiting">

      <span class="__step_status">
        <zui-process-step-state [state]="buildProcessStepsState.DEPLOY"></zui-process-step-state>
      </span>

      <div class="__step_text" [ngSwitch]="buildProcessStepsState.DEPLOY">
        <ng-template [ngSwitchCase]="buildProcessStates.Waiting">
          <zui-docs-text>Create / Activate<a data-zui-docs="backup-restore/deploy-versioning.html">app version</a><strong>&nbsp;{{ appVersion.name }}</strong> and upgrade <strong>{{ serviceName }}</strong> ({{ serviceType }})</zui-docs-text>
        </ng-template>
        <ng-template [ngSwitchCase]="buildProcessStates.Running">
          <zui-docs-text>Creating / Activating <a data-zui-docs="backup-restore/deploy-versioning.html">app version</a><strong>&nbsp;{{ appVersion.name }}</strong> and upgrading <strong>{{ serviceName }}</strong> ({{ serviceType }})</zui-docs-text>
        </ng-template>
        <ng-template [ngSwitchCase]="buildProcessStates.Finished">
          <zui-docs-text>Created / Activated<a data-zui-docs="backup-restore/deploy-versioning.html">app version</a><strong>&nbsp;{{ appVersion.name }}</strong> and upgraded <strong>{{ serviceName }}</strong> ({{ serviceType }})</zui-docs-text>
        </ng-template>
        <ng-template [ngSwitchCase]="buildProcessStates.Failed">

          <zui-docs-text><strong>Failed</strong> while creating  / activating<a data-zui-docs="backup-restore/deploy-versioning.html">app version</a>&nbsp;<strong>{{ appVersion.name }}</strong> or upgrading <strong>{{ serviceName }}</strong> ({{ serviceType }}), please contact support if problem persists.</zui-docs-text>

          <!-- Start: New solution for displaying BE errors. -->
          <div
            *ngIf="!!pipelineErrors?.[0]?.error"
            class="__error-card">
            <zef-scrollbar maxHeight="200px">
              <zui-errors-printer
                [pipelineErrors]="pipelineErrors">
              </zui-errors-printer>
            </zef-scrollbar>
          </div>
          <!-- End: New solution for displaying BE errors. -->

        </ng-template>
        <ng-template [ngSwitchCase]="buildProcessStates.Cancelled">
          <zui-docs-text>Cancelled <a data-zui-docs="backup-restore/deploy-versioning.html">app version</a> {{ appVersion.name }} creation and deploy to <strong>{{ serviceName }}</strong> ({{ serviceType }})</zui-docs-text>
        </ng-template>
      </div>

    </div>
  </ng-container>

</div>
