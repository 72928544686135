import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './app-version-item.constant';

const en = {
  statuses: {
    ACTIVE: 'active',
    BACKUP: 'backup',
    UPLOADING: 'uploading',
    DEPLOYING: 'deploying',
    DEPLOY_FAILED: 'deploy failed',
    BUILD_FAILED: 'build failed',
    BUILDING: 'building',
    WAITING_TO_DEPLOY: 'preparing deploy',
    WAITING_TO_BUILD: 'preparing build'
  },
  confirmHeading: 'Are you sure you want to permanently delete the deployed package?',
  copyTooltip: 'Click to copy commit hash to clipboard',
  activate: 'Activate',
  delete: 'Delete'
};

export type AppVersionItemTranslations = typeof en;

@NgModule()
export class AppVersionItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
