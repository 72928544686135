import {
  AfterViewInit,
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
  input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, AsyncPipe } from '@angular/common';
import { BehaviorSubject, distinctUntilChanged, map, shareReplay, Subject, withLatestFrom } from 'rxjs';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OverlayScrollbars, EventListeners, OnUpdatedEventListenerArgs, PartialOptions } from "overlayscrollbars";
import { OverlayScrollbarsDirective, OverlayscrollbarsModule } from "overlayscrollbars-ngx";

import { ThemeService } from '../theme';
import { ZefScheduler } from '../core';
import { VirtualScrollRefs } from './scrollbar.api';
import { ScrollbarEdges } from './scrollbar.model';

/**
 * Initialization of the overlay scrollbar plugin that allows to scroll by clicking.
 * It's working but actually disabled. Mainly because of the transparent scrollbar
 * track, so a user is only guessing its precise position.
 */
/**
 * OverlayScrollbars.plugin([ClickScrollPlugin]);
 */

enum ScrollbarThemes {
  VIRTUAL_SCROLLING = 'os-theme-virtual-scrolling', // (used in combination with virtual scrolling components)
  ZEROPS_LIGHT = 'os-theme-zerops-light', // (used for all other Zerops scrolling with the light mode)
  ZEROPS_DARK = 'os-theme-zerops-dark', // (used for all other Zerops scrolling with the dark mode)
  SYSTEM_LIGHT = 'os-theme-light', // (defined in the overlay scrollbar library)
  SYSTEM_DARK = 'os-theme-dark' // (pre-defined in the overlay scrollbar library as default)
}

@Component({
  standalone: true,
  selector: 'zef-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: [ './scrollbar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgIf,
    MatIconModule,
    MatButtonModule,
    OverlayscrollbarsModule
  ]
})
export class ZefScrollbarComponent extends ZefScheduler implements OnInit, AfterViewInit, OnDestroy {
  /**
   * A signal variable that controls switching the GUI theme mode between dark
   * and light variants using template class binding. The synchronization is done through
   * the ThemeService service via subscription to the activeMode$ observable.
   */
  isDark = signal(false);
  /**
   * A signal variable controlling the highlighting of a scrollbar handle on the base
   * of the cursor mouse moving (entering and leaving events) related to the pane
   * controlled by that scrollbar and synced through template class binding.
   */
  isHover = signal(false);
  /**
   * Operator which completes the Observable when the calling context is destroyed.
   */
  #takeUntilDestroyed$ = takeUntilDestroyed();

  /**
   * This value allows the evaluation of edge positions of scrollbar handles
   * with a tolerance, both for entering or leaving them.
   */
  #edgeOffset = 10;
  /**
   * Specifies the low and high limits of the top edge handle position.
   */
  #topZoneV = [0, 0 + this.#edgeOffset];
  /**
   * A variable that stores the actual state of a scrollbar handle
   * about the defined top and bottom edge positions.
   */
  #currentScrollbarHandleStateV = ScrollbarEdges.INIT;
  /**
   * An overlay scrollbar instance is stored here during onInitialized event is called.
   */
  #overlayScrollbarsInstance!: OverlayScrollbars;
  /**
   * An overlay scrollbar viewport element is stored here during onInitialized event is called.
   */
  #overlayScrollbarsViewport!: HTMLElement;
  /**
   * A RxJS subject used to emit changes in the position of a scrollbar handle.
   */
  #scrollbarHandleStateV = new Subject<ScrollbarEdges>();
  /**
   * A subscription to this observable is used to synchronize reachedTopV and reachedBottomV
   * variables with the position of a scrollbar handle to correctly show or hide related
   * clickable icons for scrolling to the top or bottom positions.
   */
  #scrollbarHandleStateV$ = this.#scrollbarHandleStateV.asObservable();
  /**
   * A private variable that stores a value of the showScrollButtonsV component input setter.
   * The final displaying of the buttons is also controlled by <showTopSmartArrowIconV>
   * and <showBottomSmartArrowIconV> properties and applied logic rules for them.
   */
  #showScrollButtonsV = false;
  /**
   * This variable represents the actual position of a scrollbar handle. The value true
   * means that the handle is in the top position; the opposite means it is anywhere else.
   */
  reachedTopV = true;
  /**
   * This variable represents the actual position of a scrollbar handle. The value true
   * means that the handle is in the bottom position; the opposite means it is anywhere else.
   */
  reachedBottomV = false;
  /**
   * Initial conditions require not displaying a top smart arrow icon when a scrollbar handler
   * is in the top edge position and not during scrolling from top to bottom or from bottom
   * to top. It should be displayed only when a scrollbar handler is at the bottom edge position.
   */
  showTopSmartArrowIconV = false;
  /**
   * Initial conditions require the display of a bottom smart arrow icon at all times except
   * the moment when a scrollbar handler is in the bottom edge position.
   */
  showBottomSmartArrowIconV = true;
  /**
   * This variable identifies that the content is not shown as a whole,
   * and it is possible to scroll.
   */
  isScrollableV = false;

  /**
   * This is an async object used as a dynamic binding property of the overlayScrollbars
   * directive. It declares initial parameters for an overlay scrollbar component instantiation.
   */
  #defaultOptions: PartialOptions = {
    scrollbars: {
      /**
       * It's working but actually disabled. Mainly because of the transparent scrollbar
       * track, so a user is only guessing its precise position.
       */
      /**
       * clickScroll: true,
       */
      theme: ScrollbarThemes.ZEROPS_LIGHT
    }
  };
  #options = new BehaviorSubject<PartialOptions>(this.#defaultOptions);
  options$ = this.#options.asObservable().pipe(
    shareReplay(1)
  );

  /**
   * There is a long-time unresolved issue of https://github.com/angular/angular/issues/19826
   * that affects the behavior of how and when a component instance is destroyed concerning
   * its defined animation. It mainly means that a component instance is destroyed before
   * its animation is finished. This leads to some strange visual defects.
   * To eliminate such strange behavior, destroying a scrollbar instance in the ngOnDestroy
   * hook has to be postponed. The delayDestroy input allows to do that if it's used.
   */
  delayDestroy = input<number>();

  /**
   * The component input that allows to specify which theme should be used for styling.
   * Actually it is possible to choose among:
   * ScrollbarThemes.VIRTUAL_SCROLLING (used in combination with virtual scrolling components)
   * ScrollbarThemes.ZEROPS_LIGHT (used for all other Zerops scrolling with the light mode)
   * ScrollbarThemes.ZEROPS_DARK (used for all other Zerops scrolling with the dark mode)
   * ScrollbarThemes.SYSTEM_LIGHT (defined in the overlay scrollbar library)
   * ScrollbarThemes.SYSTEM_DARK (pre-defined in the overlay scrollbar library as default)
   */
  @Input()
  set theme(theme: ScrollbarThemes) {
    if (theme) {
      const scrollbars = {
        ...this.#defaultOptions.scrollbars,
        ...{ theme }
      }
      const options: PartialOptions = {
        ...this.#defaultOptions,
        ...{ scrollbars }
      };
      this.#options.next(options);
    }
  }

  /**
   * Input that controls the height of a scrollable container.
   */
  @Input()
  height = 'auto';

  /**
   * Input that controls the maximum height of a scrollable container.
   */
  @Input()
  maxHeight = 'none';

  /**
   * Input that allows the display or hiding of clickable icons to scroll
   * content to the top or bottom position.
   */
  @Input()
  set showScrollButtonsV(v: string | boolean) {
    this.#showScrollButtonsV = coerceBooleanProperty(v);
  }
  get showScrollButtonsV(): boolean {
    return this.#showScrollButtonsV;
  }

  /**
   * Used only in combination with a virtual scrolling component.
   * It helps to correctly pair a reference of such a virtual scrolling
   * component with an instance of an overlay scrollbar component.
   */
  @Input()
  virtualScrollKey!: string;

  @ViewChild('osRef') elRef?: ElementRef;
  @ViewChild('osRef', { read: OverlayScrollbarsDirective }) osRef?: OverlayScrollbarsDirective;

  constructor(
    private _ngZone: NgZone,
    private _themeService: ThemeService,
    private _virtualScrollRefs: VirtualScrollRefs
  ) {
    super();
  }

  /**
   * Evaluates the low and high limits of the bottom edge handle position.
   */
  #bottomZoneV = (edgeOffset: number, overflowAmountV: number): [number, number] => {
    return [overflowAmountV - edgeOffset, overflowAmountV];
  }

  /**
   * Evaluates both edge positions of a scrollbar handle
   * including transition states of entering and leaving them.
   */
  #emitScrollbarHandleStateV = (
    previousScrollbarHandleState: ScrollbarEdges,
    edgeOffset: number,
    overflowAmountV: number,
    scrollTop: number
  ): ScrollbarEdges => {
    const [topHigh, topLow] = this.#topZoneV;
    const [bottomHigh, bottomLow] = this.#bottomZoneV(edgeOffset, overflowAmountV);
    /**
     * A scrollbar handle is not inside either the top or bottom edge position.
     */
    if (scrollTop > topLow && scrollTop < bottomHigh) {
      if (previousScrollbarHandleState === ScrollbarEdges.ON_TOP) {
        const evaluatedHandleState = ScrollbarEdges.OFF_TOP;
        this.#scrollbarHandleStateV.next(evaluatedHandleState);
        return evaluatedHandleState;
      /**
      * A scrollbar handle is actually leaving the bottom edge position.
      */
      } else if (previousScrollbarHandleState === ScrollbarEdges.ON_BOTTOM) {
        const evaluatedHandleState = ScrollbarEdges.OFF_BOTTOM;
        this.#scrollbarHandleStateV.next(evaluatedHandleState);
        return evaluatedHandleState;
      /**
       * A scrollbar handle is actually somewhere between the top and bottom edge positions.
       */
      } else {
        const evaluatedHandleState = ScrollbarEdges.MIDDLE;
        if (previousScrollbarHandleState !== evaluatedHandleState) {
          this.#scrollbarHandleStateV.next(evaluatedHandleState);
        }
        return evaluatedHandleState;
      }
    /**
     * A scrollbar handle is inside the top edge position.
     */
    } else if (scrollTop >= topHigh && scrollTop <= topLow) {
      const evaluatedHandleState = ScrollbarEdges.ON_TOP;
      if (previousScrollbarHandleState !== evaluatedHandleState) {
        this.#scrollbarHandleStateV.next(evaluatedHandleState);
      }
      return evaluatedHandleState;
    /**
     * A scrollbar handle is inside the bottom edge position.
     */
    } else if (scrollTop >= bottomHigh && scrollTop <= bottomLow) {
      const evaluatedHandleState = ScrollbarEdges.ON_BOTTOM;
      if (previousScrollbarHandleState !== evaluatedHandleState) {
        this.#scrollbarHandleStateV.next(evaluatedHandleState);
      }
      return evaluatedHandleState;
    /**
     * This case should never happen.
     */
    }
    return previousScrollbarHandleState;
  };

  /**
   * This event is once time invoked when an overlay scrollbar
   * component is instantiated by calling osInitialize() method.
   */
  onInitialized = (instance: OverlayScrollbars): void => {
    this.#overlayScrollbarsInstance = instance;
    this.#overlayScrollbarsViewport = instance.elements().viewport;
  }

  /**
   * This event is invoked whenever an overlay scrollbar
   * component is affected by changes from a user actions in GUI
   * or by programmatic changes on an overlay scrollbar instance.
   */
  onUpdated = (instance: OverlayScrollbars, _onUpdatedArgs: OnUpdatedEventListenerArgs): void => {
    const { hasOverflow } = instance.state();
    let isAnyChange = false;
    if (!this.isScrollableV && hasOverflow.y) {
      this.reachedTopV = true;
      this.reachedBottomV = false;
      isAnyChange = true;
    }
    if (this.isScrollableV && !hasOverflow.y) {
      this.reachedTopV = false;
      this.reachedBottomV = false;
      isAnyChange = true;
    }
    if (this.isScrollableV !== hasOverflow.y) {
      this.isScrollableV = hasOverflow.y;
      if (!this.isScrollableV) {
        this.showTopSmartArrowIconV = false;
        this.showBottomSmartArrowIconV = false;
      } else {
        this.showTopSmartArrowIconV = false;
        this.showBottomSmartArrowIconV = true;
      }
      isAnyChange = true;
    }
    /**
     * Scrolling related events are run out of the Zone context because
     * of the performance effects, it's necessary to tick a CD round.
     */
    if (isAnyChange) {
      this._ngZone.run(() => {
        this.renderScheduler.schedule();
      });
    }
  }

  /**
   * This event is invoked only when an overlay scrollbar component is destroyed.
   */
  onDestroyed = (_instance: OverlayScrollbars, _canceled: boolean): void => {
    // Empty block
  }

  /**
   * This event is invoked every time when the content is scrolled.
   */
  onScroll = (instance: OverlayScrollbars, _event: Event): void => {
    /**
     * The value overflowAmount.y gives the number of pixels
     * by which the vertical scrolling is available.
     */
    const { overflowAmount } = instance.state();
    /**
     * The value scrollTop gives the number of pixels by which the top edge
     * of a scrollbar handle is away from the upper border of a scrollbar.
     */
    const { scrollTop } = instance.elements().scrollOffsetElement;
    /**
     * Calling the function emitScrollbarHandleStateV() returns the actual state
     * of a scrollbar handle in relation to the top and bottom possible positions.
     * The function also internally emits the dependent change of the scrollbar
     * handle position through #scrollbarHandleStateV$ subject.
     */
    this.#currentScrollbarHandleStateV = this.#emitScrollbarHandleStateV(
      this.#currentScrollbarHandleStateV,
      this.#edgeOffset,
      overflowAmount.y,
      Math.trunc(scrollTop)
    );
  }

  /**
   * This is an object used as a binding property of the overlayScrollbars directive.
   * It declares what methods have to be call when an overlay scrollbar events are emitted.
   */
  events: EventListeners = {
    initialized: this.onInitialized,
    updated: this.onUpdated,
    destroyed: this.onDestroyed,
    scroll: this.onScroll
  }

  ngOnInit() {

    /**
     * The subscription follows changes in setting of the theme from a user side
     * that affects the entire GUI rendering. But only two controlled themes are
     * 'light' and 'dark' ones that should be in sync with the scrollbar styling
     * classes of ScrollbarThemes.ZEROPS_LIGHT and ScrollbarThemes.ZEROPS_DARK.
     */
    this._themeService.activeMode$.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.options$),
      map(([ themeMode, options ]) => {
        if (options?.scrollbars?.theme === ScrollbarThemes.ZEROPS_LIGHT && themeMode === 'dark') {
          this.isDark.set(true);
          return ScrollbarThemes.ZEROPS_DARK;
        } else if (options?.scrollbars?.theme === ScrollbarThemes.ZEROPS_DARK && themeMode === 'light') {
          this.isDark.set(false);
          return ScrollbarThemes.ZEROPS_LIGHT;
        }
        return null;
      }),
      this.#takeUntilDestroyed$,
    ).subscribe((theme: ScrollbarThemes | null) => {
      if (theme) {
        const scrollbars = {
          ...this.#defaultOptions.scrollbars,
          ...{ theme }
        }
        const options: PartialOptions = {
          ...this.#defaultOptions,
          ...{ scrollbars }
        };
        this.#options.next(options);
      }
    });

    /**
     * The subscription follows changes in a scrollbar handle position
     * and sets related variables that affect the showing of clickable
     * buttons to scroll either to the top or bottom positions.
     */
    this.#scrollbarHandleStateV$.pipe(
      distinctUntilChanged(),
      this.#takeUntilDestroyed$
    ).subscribe({
      next: (scrollbarEdgeV) => {
        let isAnyChange = false;
        if (this.isScrollableV) {
          switch (scrollbarEdgeV) {
            /**
             * If the OFF_TOP value is emitted, it means that a scrollbar handle
             * is leaving its top position, and the next emitted value will be MIDDLE.
             */
            case ScrollbarEdges.OFF_TOP:
              if (this.reachedTopV) {
                this.reachedTopV = false;
                isAnyChange = true;
              }
              break;
            /**
             * If the ON_TOP value is emitted, it means that
             * a scrollbar handle reached its top position.
             */
            case ScrollbarEdges.ON_TOP:
              if (!this.reachedTopV) {
                this.reachedTopV = true;
                isAnyChange = true;
              }
              break;
            /**
             * If the OFF_BOTTOM value is emitted, it means that a scrollbar handle
             * is leaving its bottom position, and the next emitted value will be MIDDLE.
             */
            case ScrollbarEdges.OFF_BOTTOM:
              if (this.reachedBottomV) {
                this.reachedBottomV = false;
                this.showTopSmartArrowIconV = false;
                this.showBottomSmartArrowIconV = true;
                isAnyChange = true;
              }
              break;
            /**
             * If the ON_BOTTOM value is emitted, it means that
             * a scrollbar handle reached its bottom position.
             */
            case ScrollbarEdges.ON_BOTTOM:
              if (!this.reachedBottomV) {
                this.reachedBottomV = true;
                this.showTopSmartArrowIconV = true;
                this.showBottomSmartArrowIconV = false;
                isAnyChange = true;
              }
              break;
            /**
             * If the MIDDLE value is emitted, it means that a scrollbar handle
             * has been moving somewhere between the top and bottom positions.
             * The top smart navigation icon should not be displayed and the bottom
             * smart navigation icon should be displayed.
             */
            case ScrollbarEdges.MIDDLE:
              if (this.reachedTopV) {
                this.reachedTopV = false;
                isAnyChange = true;
              }
              break;
          }
        }
        /**
        * Scrolling related events are run out of the Zone context because
        * of the performance effects, it's necessary to tick a CD round.
        */
        if (isAnyChange) {
          this._ngZone.run(() => {
            this.renderScheduler.schedule();
          });
        }
      }
    });

    /**
     * This part is used to initialize a custom styled scrollbar only
     * in combination with the Virtual Scrolling component.
     */
    this._virtualScrollRefs.virtualScrollRefEmitted$.pipe(
      this.#takeUntilDestroyed$
    ).subscribe({
      next: (emittedKey) => {
        /**
         * The condition guarantees that both keys are identical and it means that the emitted
         * key represents the virtual scrolling component instance encapsulated by the overlay
         * scrollbar component with the same key passed as its input virtualScrollKey.
         */
        if (emittedKey === this.virtualScrollKey) {
          const virtualScrollRef = this._virtualScrollRefs.getVirtualScrollRef(this.virtualScrollKey);
          if (virtualScrollRef) {
            const scrollElement = virtualScrollRef.getScrollElement();
            if (this.osRef && this.elRef) {
              this.osRef.osInitialize({
                target: this.elRef.nativeElement,
                elements: {
                  viewport: scrollElement
                }
              });
            }
          }
        }
      }
    });
  }

  /**
   * This part is used to initialize a custom styled scrollbar with all other
   * scrolling cases except the Virtual Scrolling component.
   */
  ngAfterViewInit() {
    if (!this.virtualScrollKey) {
      if (this.osRef && this.elRef) {
        this.osRef.osInitialize({
          target: this.elRef.nativeElement
        });
      }
    }
  }

  /**
   * This method scrolls the content to the top position.
   */
  scrollToTop() {
    this.#overlayScrollbarsViewport.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  /**
   * This method scrolls the content to the bottom position.
   */
  scrollToBottom() {
    const { overflowAmount } = this.#overlayScrollbarsInstance.state();
    this.#overlayScrollbarsViewport.scrollTo({
      top: overflowAmount.y,
      behavior: 'smooth'
    });
  }

  ngOnDestroy() {
    if (!this.delayDestroy()) {
      this.#destroy();
      return;
    }

    setTimeout(() => {
      this.#destroy();
    }, this.delayDestroy())

  }

  /**
   * There is a long time unresolved issue https://github.com/angular/angular/issues/19826.
   * It's necessary to postpone destroying the overlay instance till the moment
   * when the component's animation will be finished.
   */
  #destroy() {
    if (!!this.#overlayScrollbarsInstance) {
      this.#overlayScrollbarsInstance.destroy();
    }
    if (this.virtualScrollKey && !!this._virtualScrollRefs) {
      this._virtualScrollRefs.deleteVirtualScrollRef(this.virtualScrollKey);
    }
  }

}
