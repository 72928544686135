import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './port-routing-add-dialog.constant';

const en = {
  addSuccess: 'Port routing was added successfully.'
};

export type PortRoutingAddDialogTranslations = typeof en;

@NgModule()
export class PortRoutingAddDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
