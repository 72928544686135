import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  forwardRef,
  inject,
  input,
  NgZone,
  OnDestroy,
  output,
  Signal,
  viewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { CODE_FIELD_DEFAULT_OPTIONS } from './code-field.constant';
import { CodeFieldEditorModel } from './code-field.model';
import { fromEvent, Subscription } from 'rxjs';
import { ZuiCodeFieldService } from './code-field.service';
import { configureMonacoYaml } from 'monaco-yaml';
import { registerNginx } from './nginx-support';

@Component({
  selector: 'zui-code-field',
  templateUrl: './code-field.component.html',
  styleUrls: [ './code-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeFieldComponent),
      multi: true
    }
  ]
})
export class CodeFieldComponent implements ControlValueAccessor, OnDestroy {

  // # Deps
  #zone = inject(NgZone);
  #codeFieldService = inject(ZuiCodeFieldService);

  // # Data
  value = input<string>();
  editorModel = input<CodeFieldEditorModel>();
  loading = input<boolean>(false);
  editorOptions = input<Partial<editor.IStandaloneEditorConstructionOptions>>({});
  editorContainerRef = viewChild<ElementRef<HTMLElement>>('editorContainerRef');
  editorInit = output<editor.IStandaloneCodeEditor>();

  #value = '';
  #editorInstance: editor.IStandaloneCodeEditor;
  #editorOptions: Signal<editor.IStandaloneEditorConstructionOptions | undefined> = computed(() => {
    return {
      ...CODE_FIELD_DEFAULT_OPTIONS,
      ...(this.editorOptions() || {})
    };
  });
  #windowResizeSubscription: Subscription;

  onChange = (_: any) => { return; };
  onTouched = (_: any) => { return; };

  constructor() {
    effect(() => {
      if (this.editorContainerRef()
        && (this.#editorOptions() || this.editorModel())
      ) {
        if (this.#codeFieldService.loadingState() === 'NOT_LOADED') {
          this.#codeFieldService.load({
            onMonacoLoad: () => {
              registerNginx((window as any).monaco);

              configureMonacoYaml((window as any).monaco, {
                enableSchemaRequest: true,
                hover: true,
                completion: true,
                validate: true,
                format: true,
                schemas: [
                  {
                    uri: "https://api.app-prg1.zerops.io/api/rest/public/settings/import-project-yml-json-schema.json",
                    fileMatch: [
                      "**/*zerops-import*",
                      "**/zerops-project-import.yml",
                      "**/zerops-service-import.yml"
                    ]
                  },
                  {
                    uri: "https://api.app-prg1.zerops.io/api/rest/public/settings/zerops-yml-json-schema.json",
                    fileMatch: [
                      "**/*zerops-yml*",
                      "**/zerops.yml"
                    ]
                  }
                ]
              });

            }
          });
        }

        if (this.#codeFieldService.loadingState() === 'LOADED') {
          this.#initMonaco();
        }
      }
    }, { allowSignalWrites: true });

    effect(() => {
      if (this.editorContainerRef()
        && this.value() !== undefined
      ) {
        this.writeValue(this.value() || '');
      }
    });

  }

  writeValue(val: string) {
    this.#value = val;
    // fix for value change while dispose in process
    setTimeout(() => {
      if (this.#editorInstance) {
        this.#editorInstance.setValue(this.#value || '');
      }
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.#unsubscribeResize();
    if (!this.#editorInstance) { return; }
    this.#editorInstance.dispose();
  }

  #initMonaco() {

    if (this.#editorInstance) {
      this.#editorInstance.dispose();
    }

    const editorModel = this.editorModel();
    const finalOptions = {
      ...this.#editorOptions()
    };

    if (!!editorModel) {
      const uri = editorModel.fileName
        ? (window as any).monaco.Uri.parse(editorModel.fileName)
        : undefined;
      const model = uri
        ? (window as any).monaco.editor.getModel(uri)
        : undefined;
      if (model) {
        finalOptions.model = model;
        finalOptions.model.setValue(this.#value || '');
      } else {
        finalOptions.model = (window as any).monaco.editor.createModel(
          editorModel.value || this.#value,
          editorModel.language,
          uri
        );
      }
    }

    this.#zone.runOutsideAngular(() => {
      this.#editorInstance = (window as any).monaco.editor.create(
        this.editorContainerRef().nativeElement,
        finalOptions
      );
    });

    if (!editorModel) {
      this.#editorInstance.setValue(this.#value || '');
    }

    this.#editorInstance.onDidChangeModelContent(() => {
      const value = this.#editorInstance.getValue();

      this.#zone.run(() => {
        this.onChange(value);
        this.#value = value;
      });

    });

    this.#editorInstance.onDidBlurEditorWidget(() => {
      this.onTouched(undefined);
    });

    // refresh layout on resize event.
    this.#unsubscribeResize();
    this.#windowResizeSubscription = fromEvent(window, 'resize').subscribe(
      () => this.#editorInstance.layout()
    );
    this.editorInit.emit(this.#editorInstance);
  }

  #unsubscribeResize() {
    if (this.#windowResizeSubscription) {
      this.#windowResizeSubscription.unsubscribe();
    }
  }

}
