import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './user-data-env-format-dialog.constant';

const en = {
  desc: 'Paste values in the <strong>.env</strong> format (KEY=VAL) and they will be automatically added to the list. Duplicate values will be ignored.',
  label: 'Variables to import',
  placeholder: 'e.g. ZEROPS_DATA_ENV_NAME="local"',
  add: 'Import variables',
  close: 'Close'
};

export type UserDataEnvFormatDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class UserDataEnvFormatDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
