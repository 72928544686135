<ng-template #buildDatesWrapRef>
  <!-- has started -->
  <ng-container *ngIf="!!buildData?.startDate">
    started on {{ buildData.startDate | date: 'medium' }}
  </ng-container>

  <!-- has not started -->
  <ng-container *ngIf="!buildData?.startDate">
    has not started yet.
  </ng-container>

  <!-- is finished -->
  <ng-container *ngIf="!!buildData?.endDate">
    and {{ status === appVersionStatuses.BuildFailed ? 'failed' : 'finished' }} at {{ buildData.endDate  | date: 'medium' }}.
  </ng-container>
</ng-template>

<div class="__wrap  c-app-version-item-wrap">

  <!-- version name -->
  <zui-app-version-name
    *ngIf="(mode !== modes.Condensed && !showOnlyBuildInfo) || (mode === modes.Condensed && status !== appVersionStatuses.BuildFailed && !buildData)"
    class="__name"
    [name]="name"
    [source]="source"
    [id]="data?.id"
    [status]="data?.status"
    [activationDate]="data?.activationDate"
    [lastUpdate]="data?.lastUpdate"
    [githubIntegration]="githubIntegration"
    [gitlabIntegration]="gitlabIntegration"
    [publicGitSource]="publicGitSource">
  </zui-app-version-name>

  <!-- release info -->
  <zui-app-version-release-info
    *ngIf="((mode !== modes.Condensed) && !showOnlyBuildInfo) || (mode === modes.Condensed && status !== appVersionStatuses.BuildFailed && !buildData)"
    class="__release  c-app-version-item-release-info"
    [appVersion]="data">
  </zui-app-version-release-info>

  <!-- no build -->
  <div *ngIf="!buildData?.serviceStackTypeVersionId">
    <!-- Files deployed directly from zCLI. -->
  </div>

  <!-- has build -->
  <div
    *ngIf="source !== 'GUI' && !!buildData?.serviceStackTypeVersionId"
    class="__build  c-app-version-item-build"
    [class.is-active]="status === appVersionStatuses.Active
      || status === appVersionStatuses.Backup
      || status === appVersionStatuses.Deploying
      || status === appVersionStatuses.WaitingToDeploy"
    [class.is-running]="status === appVersionStatuses.Building && !!buildData?.startDate"
    [class.is-failed]="status === appVersionStatuses.BuildFailed || status === appVersionStatuses.DeployFailed"
    [class.is-log]="mode === modes.Log"
    [class.is-condensed]="mode === modes.Condensed">
    <div class="__build_grid-item">
      <div class="__build-desc">

        <!-- from github -->
        <ng-container *ngIf="source === 'GITHUB'">

          Build Pipeline for <mat-icon class="__build_github-icon" svgIcon="github"></mat-icon> <a class="__build_repository-name" target="_blank" [href]="'https://github.com/' + githubIntegration?.repositoryFullName">{{ githubIntegration?.repositoryFullName }}</a>

          <!-- BRANCH eventType -->
          <ng-container *ngIf="githubIntegration?.eventType === 'BRANCH'">
            commit&nbsp;<a class="__build_commit" target="_blank" [href]="'https://github.com/' + githubIntegration?.repositoryFullName + '/tree/' + githubIntegration?.commit">{{ githubIntegration?.branchName }}&#64;{{ githubIntegration?.commit | shorten: 8 }}</a>
          </ng-container>

          <!-- TAG eventType -->
          <ng-container *ngIf="githubIntegration?.eventType === 'TAG'">
            release&nbsp;<a class="__build_release" target="_blank" [href]="'https://github.com/' + githubIntegration?.repositoryFullName + '/tree/' + githubIntegration?.tagName">{{ githubIntegration?.tagName }}</a>
          </ng-container>

          <!-- dates  -->
          <ng-template [ngTemplateOutlet]="buildDatesWrapRef"></ng-template>

        </ng-container>

        <!-- from gitlab -->
        <ng-container *ngIf="source === 'GITLAB'">

          Build Pipeline for <mat-icon class="__build_github-icon" svgIcon="gitlab"></mat-icon> <a class="__build_repository-name" target="_blank" [href]="'https://gitlab.com/' + gitlabIntegration?.repositoryFullName">{{ gitlabIntegration?.repositoryFullName }}</a>

          <!-- BRANCH eventType -->
          <ng-container *ngIf="gitlabIntegration?.eventType === 'BRANCH'">
            commit&nbsp;<a class="__build_commit" target="_blank" [href]="'https://gitlab.com/' + gitlabIntegration?.repositoryFullName + '/tree/' + gitlabIntegration?.commit">{{ gitlabIntegration?.branchName }}&#64;{{ gitlabIntegration?.commit | shorten: 8 }}</a>
          </ng-container>

          <!-- TAG eventType -->
          <ng-container *ngIf="gitlabIntegration?.eventType === 'TAG'">
            release&nbsp;<a class="__build_release" target="_blank" [href]="'https://gitlab.com/' + gitlabIntegration?.repositoryFullName + '/tree/' + gitlabIntegration?.tagName">{{ gitlabIntegration?.tagName }}</a>
          </ng-container>

          <!-- dates  -->
          <ng-template [ngTemplateOutlet]="buildDatesWrapRef"></ng-template>

        </ng-container>

        <!-- from CLI -->
        <ng-container *ngIf="source === 'CLI'">

          <!-- has build -->
          <ng-container *ngIf="!!buildData?.serviceStackTypeVersionId">

            <!-- no name -->
            <ng-container *ngIf="!name">
              Build Pipeline for unnamed version (use <span class="__desc-code">--versionName</span> to name your deploy)
            </ng-container>

            <!-- has name -->
            <ng-container *ngIf="name">
              Build pipeline for deploy <strong>{{ name }}</strong>
            </ng-container>

            <!-- dates  -->
            <ng-template [ngTemplateOutlet]="buildDatesWrapRef"></ng-template>

          </ng-container>

          <!-- only deploy -->
          <ng-container *ngIf="!buildData?.serviceStackTypeVersionId">

          </ng-container>

        </ng-container>

      </div>
    </div>
  </div>

</div>
