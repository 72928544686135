import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { SettingsBaseState } from './settings-base.model';
import {
  SettingsBaseActionUnion,
  loadSettingsSuccess,
  loadCountryListSuccess
} from './settings-base.action';
import { STACK_NAME_TRANSLATIONS_MAP } from './settings-base.constant';

const initialState = new SettingsBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    loadSettingsSuccess,
    (s, { data }): SettingsBaseState => ({
        ...s,
        currencyList: data.currencyList,
        languageList: data.languageList,
        serviceStackList: data.serviceStackList.map((stack) => ({
          ...stack,
          name: STACK_NAME_TRANSLATIONS_MAP[stack.id] ? STACK_NAME_TRANSLATIONS_MAP[stack.id] : stack.name
        }))
      })
  ),
  on(
    loadCountryListSuccess,
    (s, { data }): SettingsBaseState => ({
      ...s,
      countryList: data.list
    })
  ),
  on(zefResetState, () => initialState)
);

export function settingsBaseReducer(
  state: SettingsBaseState,
  action: SettingsBaseActionUnion
) {
  return actionReducer(state, action);
}
