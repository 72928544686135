import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './http-routing-edit-dialog.constant';

const en = {
  updateSuccess: 'Domain routing was updated successfully.'
};

export type HttpRoutingEditDialogTranslations = typeof en;

@NgModule()
export class HttpRoutingEditDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
