import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './notifications-card.constant';

const en = {
  title: 'Notifications',
  markAllAsRead: 'Mark all as read',
  showMore: '{count, plural, =1 {Show # more notification} other {Show # more notifications}}'
};

export type NotificationsCardTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class NotificationsCardTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
