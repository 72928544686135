import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ProjectCostLimitInfoComponent } from './project-cost-limit-info.component';
import { PriceDescModule } from '@zerops/zui/price-desc';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZuiDurationModule } from '@zerops/zui/duration';

@NgModule({
  declarations: [ ProjectCostLimitInfoComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    PriceDescModule,
    ZefProgressErrorModule,
    ZuiDurationModule
  ],
  exports: [ ProjectCostLimitInfoComponent ]
})
export class ProjectCostLimitInfoModule { }
