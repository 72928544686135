<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxWidth: '1300px', width: '100%' }"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    @if (!!state.appVersion) {
      <zef-scrollbar [maxHeight]="defaultMode === 'PIPELINE' ? '85vh' : '100vh'">
        <div
          class="__wrap"
          [class.is-pipeline]="defaultMode === 'PIPELINE'">
          @if (state.open) {
            <z-pipeline-detail
              [maxWidthDetail]="'750px'"
              [maxWidthLogs]="'1200px'"
              class="__pipeline-detail"
              [baseKey]="key"
              [mode]="defaultMode"
              (modeChanged)="defaultMode = $event"
              [appVersion]="state.appVersion"
              [pipelineErrors]="state.pipelineErrors"
              (linkClicked)="onClose$.next()">
            </z-pipeline-detail>
          }
        </div>
      </zef-scrollbar>
    }

    <button
      class="__close-button"
      type="button"
      (click)="onClose$.next()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>
