import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { ZefAuthDefaultResponse } from '@zerops/zef/auth';
import {
  ZefGitlabAuthUrlType,
  ZefGitlabAuthUrlResponse,
  ZefGitlabApiAuthorizePayload,
  ZefGitlabRepository,
  ZefGitlabBranch
} from './gitlab.model';

export const zefGitlabAuthUrlRequest = createAction(
  '[@zerops/zef/gitlab] auth-url-request',
  zefActionPayload<{ type: ZefGitlabAuthUrlType; recipes: { nonHaRecipeId: string; haRecipeId: string; }; }>()
);

export const zefGitlabAuthUrlFail = createAction(
  '[@zerops/zef/gitlab] auth-url-request/fail',
  zefActionFailPayload
);

export const zefGitlabAuthUrlSuccess = createAction(
  '[@zerops/zef/gitlab] auth-url-request/success',
  zefActionSuccessPayload<ZefGitlabAuthUrlResponse>()
);

export const zefGitlabSignupRequest = createAction(
  '[@zerops/zef/gitlab] sign-up',
  zefActionPayload<ZefGitlabApiAuthorizePayload>()
);

export const zefGitlabSignupFail = createAction(
  '[@zerops/zef/gitlab] sign-up/fail',
  zefActionFailPayload
);

export const zefGitlabSignupSuccess = createAction(
  '[@zerops/zef/gitlab] sign-up/success',
  zefActionSuccessPayload<ZefAuthDefaultResponse>()
);

export const zefGitlabLoginRequest = createAction(
  '[@zerops/zef/gitlab] login',
  zefActionPayload<ZefGitlabApiAuthorizePayload>()
);

export const zefGitlabLoginFail = createAction(
  '[@zerops/zef/gitlab] login/fail',
  zefActionFailPayload
);

export const zefGitlabLoginSuccess = createAction(
  '[@zerops/zef/gitlab] login/success',
  zefActionSuccessPayload<ZefAuthDefaultResponse>()
);

export const zefGitlabRepositoryAccessRequest = createAction(
  '[@zerops/zef/gitlab] repository-access',
  zefActionPayload<ZefGitlabApiAuthorizePayload>()
);

export const zefGitlabRepositoryAccessFail = createAction(
  '[@zerops/zef/gitlab] repository-access/fail',
  zefActionFailPayload
);

export const zefGitlabRepositoryAccessSuccess = createAction(
  '[@zerops/zef/gitlab] repository-access/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

export const zefGitlabRepositoriesRequest = createAction(
  '[@zerops/zef/gitlab] repositories',
  zefActionPayload()
);

export const zefGitlabRepositoriesFail = createAction(
  '[@zerops/zef/gitlab] repositories/fail',
  zefActionFailPayload
);

export const zefGitlabRepositoriesSuccess = createAction(
  '[@zerops/zef/gitlab] repositories/success',
  zefActionSuccessPayload<{ repositories: ZefGitlabRepository[]; }>()
);

export const zefGitlabBranchRequest = createAction(
  '[@zerops/zef/gitlab] branch',
  zefActionPayload<string>()
);

export const zefGitlabBranchFail = createAction(
  '[@zerops/zef/gitlab] branch/fail',
  zefActionFailPayload
);

export const zefGitlabBranchSuccess = createAction(
  '[@zerops/zef/gitlab] branch/success',
  zefActionSuccessPayload<{
    branches: ZefGitlabBranch[];
    repositoryName: string;
  }>()
);

const all = union({
  zefGitlabAuthUrlRequest,
  zefGitlabAuthUrlFail,
  zefGitlabAuthUrlSuccess,
  zefGitlabLoginRequest,
  zefGitlabLoginFail,
  zefGitlabLoginSuccess,
  zefGitlabSignupRequest,
  zefGitlabSignupFail,
  zefGitlabSignupSuccess,
  zefGitlabRepositoryAccessRequest,
  zefGitlabRepositoryAccessFail,
  zefGitlabRepositoryAccessSuccess
});
export type GitlabActionUnion = typeof all;
