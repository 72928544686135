import {
  trigger,
  transition,
  style,
  query,
  group,
  animate
} from '@angular/animations';

export function globalLoadingAnimation() {
  return trigger('globalLoadingAnimation', [

    transition('false => true', [
      group([

        query('.__spinner:enter', [
          style({ opacity: 0 }),
          animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 1
          }))
        ], { optional: true }),

        query('.__logo-wrap:leave', [
          group([
            animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
              opacity: 0
            })),
            animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
              transform: 'rotate(60deg) scale(0.8)'
            }))
          ])
        ], { optional: true })

      ])
    ]),

    transition('true => false', [
      group([

        query('.__spinner:leave', [
          animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 0
          }))
        ], { optional: true }),

        query('.__logo-wrap:enter', [
          group([

            style({
              transform: 'rotate(60deg) scale(0.8)',
              opacity: 0
            }),

            group([

              animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
                transform: 'rotate(0deg) scale(1)',
              })),

              animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
                opacity: 1
              }))

            ])

          ])
        ], { optional: true })

      ])
    ])
  ]);
}
