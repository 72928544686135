import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceStackBaseEffect } from './service-stack-base.effect';
import { serviceStackBaseReducer } from './service-stack-base.reducer';
import { FEATURE_NAME } from './service-stack-base.constant';
import { ServiceStackBaseTranslationsModule } from './service-stack-base.translations';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, serviceStackBaseReducer),
    EffectsModule.forFeature([ ServiceStackBaseEffect ]),
    ServiceStackBaseTranslationsModule
  ]
})
export class ServiceStackBaseModule {

}
