import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './menu-panel.constant';

const en = {
  projects: 'Projects',
  users: 'Team',
  settings: 'Settings',
  creditAndCost: 'Credit & Cost',
  help: 'Community Help',
  recipes: 'Recipes',
  documentation: 'Documentation'
};

export type MenuPanelTranslations = typeof en;

@NgModule()
export class MenuPanelTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
