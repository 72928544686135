<!-- initial -->
<div class="__initial" *ngIf="state.state === 'INITIAL'">

  <!-- trying to load repos -->
  <div class="c-gitlab-repo-form-gitlab-button-wrap" *ngIf="!state.permissionLoading">

    <button
      *ngIf="!state.repositoriesLoading"
      mat-flat-button
      class="__gitlab-button  c-button--larger  c-gitlab-repo-form-gitlab-button"
      (click)="onRepoConnectionRequest$.next(true)"
      type="button">
      <mat-icon class="__gitlab-button_icon" svgIcon="gitlab"></mat-icon> Connect with a GitLab repository
    </button>

    <div
      class="__initial_loading-wrap"
      *ngIf="state.repositoriesLoading">
      <zui-progress-spinner class="__initial_loading-wrap-icon" [isLoading]="true"></zui-progress-spinner> Loading repositories
    </div>

  </div>

  <!-- getting gitlab permissions -->
  <div
    class="__initial_loading-wrap"
    *ngIf="state.permissionLoading">
    <mat-icon class="__initial_loading-wrap-icon">lock_open</mat-icon> Requesting GitLab permissions
  </div>

</div>

<!-- authorized -->
<ng-container *ngIf="state.state === 'AUTHORIZED'">

  <!-- has repos -->
  <ng-container *ngIf="state.repositories?.length; else noRepositoriesRef">

    <div class="c-gitlab-repo-select-open-button-wrap">
      <button
        mat-flat-button
        type="button"
        class="__repo-select-open-button c-button--larger c-gitlab-repo-select-open-button"
        (click)="onRepoConnectionRequest$.next(true); repoSearchPopRef.open();"
        [satPopoverAnchor]="repoSearchPopRef">
        <mat-icon class="__repo-select-open-button_icon">keyboard_arrow_down</mat-icon> Click find GitLab repository to connect with...
      </button>
    </div>

    <sat-popover
      #repoSearchPopRef
      [hasBackdrop]="true"
      [restoreFocus]="false"
      [autoFocus]="true"
      [backdropClass]="'cdk-overlay-light-dark-backdrop'"
      [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
      [verticalAlign]="'start'"
      (afterOpen)="focusRepoSearchInput()"
      [horizontalAlign]="'center'">
      <mat-card class="__repo-search-card z-manual-elevation c-soft-elevation">

        <zef-form-field>
          <mat-form-field color="accent" class="__repo-search-field">

            <mat-icon class="__repo-search-field_icon-prefix" matPrefix>search</mat-icon>

            <input
              matInput
              #repoSearchInputRef
              type="text"
              autocomplete="off"
              [placeholder]="'Search by repository name …'"
              [formControl]="repoSearchForm"
            />

          </mat-form-field>
        </zef-form-field>

        <zef-scrollbar height="300px">
          <div
            *ngFor="let item of state.searchedRepositories; trackBy: trackByIndex;"
            [matTooltipHideDelay]="0"
            [matTooltipPosition]="'left'"
            [matTooltip]="'You need admin rights to the repository to be able to setup automatic build and deploy'"
            [matTooltipDisabled]="!!item.item.admin">

            <button
              mat-button
              [disabled]="!item.item.admin"
              class="__gitlab-repo-select-button"
              (click)="onSelectRepository$.next(item.item?.fullName)"
              type="button">

              <mat-icon
                *ngIf="!!item.item.admin"
                class="__gitlab-repo-select-button_icon"
                svgIcon="gitlab">
              </mat-icon>

              <mat-icon
                *ngIf="!item.item.admin"
                class="__gitlab-repo-select-button_lock-icon">
                lock
              </mat-icon>

              <zef-fuse-highlight
                class="__name-highlight  __github-repo-select-button_highlight"
                [data]="{ fullName: item.item?.fullName }"
                [key]="'fullName'"
                [matches]="item.matches">
              </zef-fuse-highlight>

            </button>
          </div>
        </zef-scrollbar>

        <button
          mat-button
          type="button"
          (click)="repoSearchPopRef.close()"
          class="[ c-button  c-button--full  c-button--smaller ]  __repo-search-close">
          Close search
        </button>

      </mat-card>
    </sat-popover>

  </ng-container>

  <!-- no repos -->
  <ng-template #noRepositoriesRef>
    <p>Looks like you don't have any repositories, sorry.</p>
  </ng-template>

</ng-container>

<!-- authorized -->
<ng-container *ngIf="state.state === 'SELECTED'">

  <mat-card class="__selected-card c-github-repo-form-selected-card">
    <div class="__selected-card_content c-github-repo-form-selected-card-content">

      <span class="__selected-repo-label">Connecting with</span>

      <h3 class="__selected-repo-name">

        <div>
          <mat-icon class="__selected-repo-name_icon" svgIcon="gitlab"></mat-icon>
          <a
            class="__link"
            target="_blank"
            [href]="'https://gitlab.com/' + state.formState.value.repositoryFullName">{{ state.formState.value.repositoryFullName | middleEllipsis: 20 }}
          </a>
        </div>

        <div>
          <button
            *ngIf="showDisconnectButton"
            type="button"
            class="__disconnect-button  c-gitlab-repo-form-disconnect-button"
            mat-icon-button
            (click)="onResetForm$.next()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </h3>

      <zef-form-field
        last
        [appearance]="'radio'">

        <zef-form-label class="__branch-select-label">Trigger pipeline on</zef-form-label>

        <mat-radio-group
          class="__trigger-radio-group"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state.formState?.controls.eventType"
          (change)="onSetTriggerBuildValue$.next()">

          <mat-radio-button
            class="__trigger-radio-button"
            [value]="'TAG'">
            New Tag
          </mat-radio-button>

          <mat-radio-button
            class="__trigger-radio-button"
            [value]="'BRANCH'">
            Push to Branch
          </mat-radio-button>

        </mat-radio-group>

      </zef-form-field>

      <ng-container *ngIf="state.formState?.value.eventType === 'TAG'">

        <div class="u-text--left">
          <div class="__tag-select-separator-wrap">
            <zui-separator
              [size]="'small'"
              [spacing]="'small'"
              class="__tag-select-separator">
            </zui-separator>
          </div>
        </div>

        <zef-form-field>

          <div class="__tag-select-wrap">

            <mat-form-field color="accent">

              <mat-label>Regular expression to match (optional)</mat-label>

              <input
                matInput
                type="text"
                [placeholder]="'Insert expression without slashes'"
                [ngrxEnableFocusTracking]="true"
                [ngrxFormControlState]="state.formState?.controls.tagRegex"
              />

            </mat-form-field>

          </div>

          <zef-form-hints class="__expression-hint">Pipeline will trigger only if tag name matches the expression</zef-form-hints>

        </zef-form-field>

      </ng-container>

      <ng-container *ngIf="state.formState?.value.eventType === 'BRANCH'">

        <div class="u-text--right">
          <div class="__branch-select-separator-wrap">
            <zui-separator
              [size]="'small'"
              [spacing]="'small'"
              class="__branch-select-separator">
            </zui-separator>
          </div>
        </div>

        <zef-form-field>

          <div class="__branch-select-wrap">

            <mat-form-field
              *ngIf="(state?.repositoriesBranchMap) else noBranchRef"
              class="__branch-select-form-field"
              color="accent">

              <mat-label>Select branch</mat-label>

              <mat-select
                #selectRef
                [ngrxEnableFocusTracking]="true"
                [ngrxFormControlState]="state.formState?.controls.branchName">

                <mat-select-trigger>
                  {{ state.formState?.value.branchName }} <span *ngIf="state.isSelectedBranchDefault" class="__branch-default-tag">default</span>
                </mat-select-trigger>

                <mat-option
                  [value]="item.name"
                  *ngFor="let item of state?.repositoriesBranchMap[state.formState.value.repositoryFullName]">
                  {{ item.name }} <span *ngIf="item.isDefault" class="__branch-default-tag">default</span>
                </mat-option>

              </mat-select>

            </mat-form-field>

            <ng-template #noBranchRef>
              <span class="__branch-empty">No branch available</span>
            </ng-template>

          </div>

        </zef-form-field>

        <ng-container *ngIf="state?.repositoriesBranchMap && state.formState.value.branchName">
          <zef-form-field
            last
            class="__trigger-build"
            appearance="checkbox">

            <mat-checkbox
              class="__trigger-build-checkbox"
              [ngrxFormControlState]="state.formState?.controls.triggerBuild"
              [ngrxEnableFocusTracking]="true">
              {{ triggerBuildLabel }}
            </mat-checkbox>

            <zef-form-hints>
              <ng-container *ngIf="!!state.formState.value.triggerBuild">Make sure you have zerops.yml already in the branch.</ng-container>
            </zef-form-hints>

          </zef-form-field>
        </ng-container>

      </ng-container>

      <div
        class="__ng-content"
        [class.is-branch]="state.formState?.value.eventType === 'BRANCH'">
        <ng-content></ng-content>
      </div>

    </div>
  </mat-card>

</ng-container>