import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { getStorageInfoSuccess } from './object-storages-usage.action';
import { ObjectStoragesUsageState } from './object-storages-usage.model';

const initialState = new ObjectStoragesUsageState();

export const actionReducer = createReducer(
  initialState,
  on(
    getStorageInfoSuccess,
    (s, { usageMap }): ObjectStoragesUsageState => ({
      ...s,
      data: usageMap
    })
  ),
  on(zefResetState, () => initialState)
);

export function objectStoragesUsageReducer(
  state = initialState,
  action: any
) {
  return actionReducer(state, action);
}
