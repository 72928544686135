import {
  getTransactionGroupRangeKey,
  TransactionGroupBy,
  transformMetricGroup,
  transformProjectGroup,
  transformServiceGroup
} from '@zerops/models/transaction-debit';
import { HashMap } from '@zerops/zef/core';
import mergeWith from 'lodash-es/mergeWith';
import { FEATURE_NAME } from './transaction-debit-base.constant';
import {
  TransactionDebitGroupRequestPayload,
  TransactionDebitGroupUpdateSuccessPayload
} from './transaction-debit-base.model';

export function getTransactionGroupRangeSubscriptionName(
  groupBy: string,
  timeGroupBy: string,
  projectId?: string,
  serviceId?: string,
  key?: string
) {
  const base = `${FEATURE_NAME}_transaction-group_${groupBy}_${timeGroupBy}_##${key}##`;

  if (projectId) { return `${base}-${projectId}`; }
  if (serviceId) { return `${base}-${serviceId}`; }

  return base;
}

export function trasactionGroupExists(
  payload: TransactionDebitGroupRequestPayload,
  transactionStateData: HashMap<any>
) {
  const { groupRange, groupBy, projectId, serviceId } = payload;

  const rangeKey = getTransactionGroupRangeKey(groupRange.key);

  // if transaction group doesn't exist at all
  if (transactionStateData[groupBy] === undefined) {
    return false;
  }

  // if transaction group is project we check
  // for existance of range key, since group
  // by project doesn't have layer with ids
  if (groupBy === TransactionGroupBy.Project
    && transactionStateData[groupBy][rangeKey] !== undefined
  ) {
    return true;
  }

  // if we are checking single project existance
  // of timegroup inside a group
  if (!!projectId
    && groupBy === TransactionGroupBy.Service
    && transactionStateData[groupBy][projectId][rangeKey] !== undefined
  ) {
    return true;
  }

  // if we are checking single service existance
  // of timegroup inside a group
  if (!!serviceId
    && groupBy === TransactionGroupBy.Metric
    && transactionStateData[groupBy][serviceId][rangeKey] !== undefined
  ) {
    return true;
  }

  return false;
}

export function transformDebitGroupTransformByMetric(
  data: TransactionDebitGroupUpdateSuccessPayload,
  state: HashMap<any>
) {

  const {
    items,
    groupBy,
    key
  } = data;

  if (groupBy === TransactionGroupBy.Metric) {
    const d = transformMetricGroup(items, getTransactionGroupRangeKey(key));

    const r = {
      ...state,
      [groupBy]: mergeWith(
        { ...state[groupBy] },
        { ...d },
        (oldVal, newVal) => {
          if (newVal) {
            return { ...oldVal, ...newVal };
          }

          return oldVal;
        }
      )
    };

    return r;
  }

  if (groupBy === TransactionGroupBy.Service) {
    const d = transformServiceGroup(items, getTransactionGroupRangeKey(key));

    const r = {
      ...state,
      [groupBy]: mergeWith(
        { ...state[groupBy] },
        { ...d },
        (oldVal, newVal) => {
          if (newVal) {
            return { ...oldVal, ...newVal };
          }

          return oldVal;
        }
      )
    };

    return r;

  }

  if (groupBy === TransactionGroupBy.Project) {
    const d = transformProjectGroup(items);
    const k = getTransactionGroupRangeKey(key);

    const r = {
      ...state,
      [groupBy]: {
        ...(state[groupBy] || {}),
        [k]: {
          data: d?.data?.length ? d.data : (state?.[groupBy]?.[k]?.data || [])
        }
      }
    };

    return r;
  }

  console.warn('Unknown groupBy — ', groupBy);
}
