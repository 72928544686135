import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { UserDataAddFormModule } from '@zerops/zui/user-data-add-form';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { UserDataAddDialogContainer } from './user-data-add-dialog.container';
import { UserDataAddDialogEffect } from './user-data-add-dialog.effect';
import { UserDataAddDialogTranslationsModule } from './user-data-add-dialog.translations';

@NgModule({
  declarations: [ UserDataAddDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    UserDataAddFormModule,
    EffectsModule.forFeature([ UserDataAddDialogEffect ]),
    FormSectionModule,
    ReactiveFormsModule,
    ZefProgressErrorModule,
    TranslocoModule,
    UserDataFieldsModule,
    ZefDialogModule,
    UserDataAddDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ UserDataAddDialogContainer ]
})
export class UserDataAddDialogModule {

}
