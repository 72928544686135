import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZuiRecipeModule } from '@zerops/zui/recipe';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ZuiPublicRoutingContentModule } from '@zerops/zui/public-routing-content';
import { ZuiFilterIconModule } from '@zerops/zui/filter-icon';
import { RecipesFeature } from './recipes.feature';
import { API_URL } from './recipes.constant';
import { RecipesService } from './recipes.service';

@NgModule({
  providers: [
    RecipesService
  ]
})
export class ZuiRecipesRootModule {
}


@NgModule({
  declarations: [ RecipesFeature ],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    ZuiRecipeModule,
    DynamicPopAnchorModule,
    SatPopoverModule,
    PopStateModule,
    ZefPipesModule,
    ZuiFilterIconModule,
    ZuiSeparatorComponent,
    ZuiPublicRoutingContentModule
  ],
  exports: [ RecipesFeature ],

})
export class ZuiRecipesModule {
  static forRoot(apiUrl: string): ModuleWithProviders<ZuiRecipesModule> {
    return {
      ngModule: ZuiRecipesRootModule,
      providers: [
        { provide: API_URL, useValue: apiUrl }
      ]
    }
  }
}
