export const QWIK_IMPORT_YAML = `project:
  name: recipe-qwik
  tags:
    - zerops-recipe

services:
  - hostname: ssrapp
    type: nodejs@20
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-qwik-nodejs

  - hostname: staticapp
    type: static
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-qwik-static`;
