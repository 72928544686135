import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './object-storage-service-stack-form.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  nameTitle: `Name`,
  nameDesc: `Give the service a unique name within your
  <a data-zui-docs="overview/projects-and-services-structure.html#project">project</a>.
  Bucket will be created with name based on the service name and a random prefix. This whole name will be available under helper env variable 'zeropsStorageBucket', along with 'accessKeyId' and 'accessSecret' variables.`,
  nameLabel: 'Name',
  policyTitle: 'Bucket access policy',
  policyDesc: 'You can select one of the pre-prepared basic policy templates, or select and define your own S3-compatible custom policy. Use `<strong>{{ .BucketName }}</strong>` placeholder if you need to utilize bucket name in your custom policy rules.',
  namePlaceholder: 'Name your Object storage …',
  discTitle: 'Bucket capacity quota (GB)',
  discDesc: `The maximum amount of data in GB the bucket is capable of holding.
  You need to set the capacity manually. Auto-scaling for Object storage buckets is not yet supported.
  Read full <a data-zui-docs="services/storage.html#s3-compatible-object-storage">Zerops Object storage documentation</a>.`,
  discLabel: 'Starting Disk Capacity',
  envVariablesTitle: 'Secret variables',
  envVariablesDesc: `Add environment variables with sensitive data, such as password, tokens, salts, certificates etc. These will be securely saved inside Zerops and added to your runtime service upon start. You can reference other secret variables, or other types of Zerops environment variables (generated, from zerops.yml file) even those that do not exist yet. You can also all other variables from other services inside the project.`,
  restrictedVariable: 'Restricted variable, can not be deleted or edited.',
  saveVariable: 'Save the variable',
  addVariable: 'Add new variable',
  copyKeyTooltip: 'Click to copy the key name to clipboard'
};

export type ObjectStorageServiceStackFormTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ObjectStorageServiceStackFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
