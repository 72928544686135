import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Process } from '@zerops/models/process';
import { processFinishNotify } from './process-base.action';

export const filterOnProcessFinish = <A extends Observable<Action>>(f: (p:  Partial<Process>[]) => boolean) => {
  return (source$: A) => source$.pipe(
    ofType(processFinishNotify),
    map(({ data }) => data),
    filter(f)
  );
};
