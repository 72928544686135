import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { FormState } from 'ngrx-forms';
import { FEATURE_NAME } from './top-up-promo-code-form.constant';

export interface TopUpPromoCodeFormValue {
  promoCode: string;
}

export type TopUpPromoCodeFormState = FormState<TopUpPromoCodeFormValue>;

@Injectable({ providedIn: 'root' })
export class TopUpPromoCodeForm extends ZefForm<TopUpPromoCodeFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService,
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        promoCode: null,
      },
      {},
    );
  }
}
