import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostnameFieldComponent } from './hostname-field.component';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';

@NgModule({
  declarations: [ HostnameFieldComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MaterialFormStackModule
  ],
  exports: [ HostnameFieldComponent ]
})
export class HostnameFieldModule {

}
