import { createAction, props, union } from '@ngrx/store';
import { ProcessesPopOpenPayload } from './processes-pop.model';

export const processesPopSetSettings = createAction(
  '[@zerops/zerops/feature/processes-pop] set-settings',
  props<ProcessesPopOpenPayload>()
);

export const processesPopSettingsReset = createAction(
  '[@zerops/zerops/feature/processes-pop] reset-settings'
);

const all = union({
  processesPopSetSettings,
  processesPopSettingsReset
});

export type ProcessesPopActionUnion = typeof all;
