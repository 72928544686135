import { ServiceStackType } from '@zerops/models/service-stack';
import { Country, Currency, Language, Enum } from '@zerops/models/settings';

export class SettingsBaseState {
  countryList: Country[];
  currencyList: Currency[];
  languageList: Language[];
  enumList: Enum[];
  serviceStackList: ServiceStackType[];
  activeCurrency: Currency;

  constructor() {
    this.countryList = [];
    this.currencyList = [];
    this.languageList = [];
    this.enumList = [];
    this.serviceStackList = [];
    this.activeCurrency = { id: 'USD', symbol: '$', displaySymbolBefore: true, roundDigits: 4 };
  }
}
