<!-- port actions -->
<z-port-routing-actions-pop
  #portRoutingActionsPopRef
  [id]="activeMoreId"
  [activeElRef]="activePortRoutingItemElRef"
  (popClosed)="activeMoreId = undefined"
  (editClicked)="editOpenClick.emit($event)">
</z-port-routing-actions-pop>

<ng-template #itemRef let-item="item" let-location="location">

  <mat-card
    class="__card"
    [class.is-inactive]="!!activeMoreId && activeMoreId !== item.id"
    [class.is-unsynced]="!item.isSynced && !!item.lastSync"
    [class.is-unsynced-new]="!item.isSynced && !item.lastSync"
    [class.is-unsynced-deletion]="item.deleteOnSync">

    <zui-more-button
      class="__more"
      [class.is-loading]="state.loadingsMap[item.id]"
      [popRef]="portRoutingActionsPopRef.popoverRef"
      [isLoading]="state.loadingsMap[item.id]"
      (moreClick)="activePortRoutingItemElRef = portRoutingItemRef.elementRef; activeMoreId = item.id;">
    </zui-more-button>

    <zui-port-routing-item
      #portRoutingItemRef
      [data]="item"
      [ip]="ip"
      [type]="type"
      [location]="location"
      (editOpen)="editOpenClick.emit(item)"
      [translations]="state.portRoutingItemTranslations">
    </zui-port-routing-item>

  </mat-card>
</ng-template>

<!-- local -->
<ng-container *ngFor="let item of state.categorizedItems?.local; trackBy: trackByIndex;">
  <ng-template [ngTemplateOutlet]="itemRef" [ngTemplateOutletContext]="{ item: item, location: 'stack' }"></ng-template>
</ng-container>

<!-- external -->
<div class="__other" *ngIf="state.categorizedItems?.external?.length">

  <h3 class="__http-routing-category_title">Other IP Accesses on the Project</h3>

  <ng-container *ngFor="let item of state.categorizedItems?.external; trackBy: trackByIndex;">
    <ng-template [ngTemplateOutlet]="itemRef" [ngTemplateOutletContext]="{ item: item, location: 'other' }"></ng-template>
  </ng-container>

</div>
