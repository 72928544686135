import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceDescComponent } from './price-desc.component';
import { PriceModule } from '@zerops/zui/price';

@NgModule({
  declarations: [ PriceDescComponent ],
  imports: [
    CommonModule,
    PriceModule
  ],
  exports: [ PriceDescComponent ]
})
export class PriceDescModule {
}
