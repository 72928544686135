import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { removeAtIndex } from '@zerops/zef/core';
import { dotEnvParser } from '@zerops/zef/utils';
import { Subscription } from 'rxjs';
import {
  UserDataAddPopComponent,
  UserDataEditPopComponent
} from './modules';
import uniq from 'lodash-es/uniq';
import { UserDataFormFieldValue } from './user-data-form-field.model';
import { FEATURE_NAME } from './user-data-form-field.constant';

@Component({
  selector: 'z-user-data-form-field',
  templateUrl: './user-data-form-field.component.html',
  styleUrls: ['./user-data-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserDataFormFieldComponent),
      multi: true
    }
  ]
})
export class UserDataFormFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {

  // # Forms
  _form = new UntypedFormGroup({
    key: new UntypedFormControl(undefined, [ Validators.required ]),
    content: new UntypedFormControl(undefined, [ Validators.required ])
  });

  @Input()
  buttonText: string;

  @Input()
  popButtonsText: string;

  @Input()
  copyKeyTooltipText: string;

  @Input()
  set value(v) {
    if (!v.length && !this._value) { return; }

    this._value = v;
    setTimeout(() => {
      this._propagateChange(this._value);
    });
  }

  get value() {
    return this._value;
  }

  @ViewChild(UserDataAddPopComponent)
  userDataAddPopRef: UserDataAddPopComponent;

  @ViewChild(UserDataEditPopComponent)
  userDataEditPopRef: UserDataEditPopComponent;

  activeElRef: ElementRef<any>;
  activeUserDataIndex: number = undefined;
  addUserDataKey = FEATURE_NAME + 'add';
  editUserDataKey = FEATURE_NAME + 'edit';
  envFormatDialogOpen = false;
  duplicateKeyWarning = false;
  private _value: any[];
  private _keyChangeSubscription: Subscription;

  writeValue(v: any[]) {
    if (v || (!v && this._value)) {
      this.value = v;
    }
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(_: any) {
    return;
  }

  predicate = (value: UserDataFormFieldValue): boolean => {
    const key = this._form.get('key').value;
    return value.key === key;
  };

  _appendEnvFormatItems(data: string) {
    if (!!data) {
      const parsedData = dotEnvParser(data, { createArray: true }) as UserDataFormFieldValue[];

      parsedData.forEach((d) => {
        this._addUserData(d);
      });
    }
  }

  _setForm() {
    this._form.setValue(this.value[this.activeUserDataIndex]);
  }

  _addUserData(value: UserDataFormFieldValue) {
    if (!!value.key && !!value.content && (!this.value || (this.value && !this.value.some((v) => v.key === value.key)))) {
      this.writeValue(uniq([
        ...(this.value || []),
        value
      ]));
    }
  }

  _deleteUserData() {
    this.value = removeAtIndex(this.value, this.activeUserDataIndex);
  }

  _updateUserData(value: UserDataFormFieldValue) {
    if (!!value.key && !!value.content) {
      this.value = this.value.map((itm, i) => {
        if (i === this.activeUserDataIndex) {
          return value;
        } else {
          return itm;
        }
      });
    }
  }

  _resetForm() {
    this.userDataAddPopRef.ngFormRef.resetForm();
    this.userDataEditPopRef.ngFormRef.resetForm();
    this._form.reset();
  }

  toggleEnvFormatDialogOpenState() {
    this.envFormatDialogOpen = !this.envFormatDialogOpen;
  }

  trackBy(index: number) {
    return index;
  }

  private _propagateChange = (_: any) => {
    return;
  };

  ngOnInit() {
    this._keyChangeSubscription = this._form.get('key').valueChanges
      .subscribe((key) => this.duplicateKeyWarning = this.value?.some((d) => d.key === key))
  }

  ngOnDestroy() {
    this._keyChangeSubscription.unsubscribe();
  }
}
