import { Component, ViewChild, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { SatPopover } from '@zerops/zef/popover';
import { AppState } from '@zerops/zerops/app';
import { ProcessItemModes, ProcessItemTranslations, PROCESS_ITEM_FEATURE_NAME } from '@zerops/zui/process-item';
import { AppVersion } from '@zerops/models/app-version';
import { pipelineDetailDialogOpen } from '@zerops/zerops/feature/pipeline-detail-dialog';
import { buildCancel, processCancel, ProcessEntity } from '@zerops/zerops/core/process-base';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProcessesPopService } from './processes-pop.service';
import { selectProcessesPopState } from './processes-pop.selector';
import { processesPopSettingsReset } from './processes-pop.action';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'z-processes-pop',
  templateUrl: './processes-pop.container.html',
  styleUrls: [ './processes-pop.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessesPopContainer extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onAfterPopClose$ = new Subject<void>();
  onProcessCancel$ = new Subject<string>();
  onOpenPipelineDetailDialog$ = new Subject<{ appVersion: AppVersion; pipelineErrors: PipelineError[] }>();
  onBuildCancel$ = new Subject<string>();

  // # Data
  // -- sync
  processItemModes = ProcessItemModes;

  // -- async
  processItemTranslations$ = this.translate$<ProcessItemTranslations>(PROCESS_ITEM_FEATURE_NAME);
  processesPopSettings$ = this._store.pipe(select(selectProcessesPopState));
  processes$ = this.processesPopSettings$.pipe(
    filter((d) => !!d?.level),
    switchMap(({ level, id }) => this._processEntity
      .processMap$()
      .pipe(
        filter((d) => !!d),
        map((processesMap) => {

          if (level === 'client') {
            return processesMap.all;
          }

          if (level === 'project') {
            return processesMap.projectMap[id];
          }

          if (level === 'service') {
            return processesMap.serviceMap[id];
          }

        })
      )
    ),
    tap((d) => (!d?.pending?.length && !d?.running.length) ? this.proccesesPopRef.close() : undefined)
  );
  scrollHeight$ = this.processesPopSettings$.pipe(
    map((d) => d?.level === 'client' ? 'calc(100vh - 70px)' : '65vh')
  );

  @ViewChild(SatPopover, { static: true })
  proccesesPopRef: SatPopover;

  // # State resolver
  state = this.$connect({
    processes: this.processes$,
    processItemTranslations: this.processItemTranslations$,
    scrollHeight: this.scrollHeight$
  });

  // # Action Streams
  private _afterPopCloseAction$ = this.onAfterPopClose$.pipe(
    map(() => processesPopSettingsReset())
  );
  private _processCancelAction$ = this.onProcessCancel$.pipe(
    map((id) => processCancel({ id }))
  );
  private _openPipelineDetailDialog$ = this.onOpenPipelineDetailDialog$.pipe(
    map((data) => pipelineDetailDialogOpen(data))
  );
  private _onBuildCancelAction$ = this.onBuildCancel$.pipe(
    map((id) => buildCancel({ id }))
  );

  constructor(
    private _processEntity: ProcessEntity,
    private _processesPopService: ProcessesPopService,
    private _store: Store<AppState>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._afterPopCloseAction$,
      this._processCancelAction$,
      this._openPipelineDetailDialog$,
      this._onBuildCancelAction$
    ]);

  }

  ngAfterViewInit() {
    this._processesPopService.saveRef(this.proccesesPopRef);
  }
}
