import { createAction, union } from '@ngrx/store';

export const httpRoutingAddDialogOpen = createAction(
  '[@zerops/zerops/feature/http-routing-add-dialog] open',
  (
    projectId: string,
    serviceStackId?: string,
    isFirst?: boolean
  ) => ({
    projectId,
    serviceStackId,
    isFirst
  })
);

const all = union({ httpRoutingAddDialogOpen });

export type HttpRoutingAddDialogActionUnion = typeof all;
