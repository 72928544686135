import {
  Component,
  OnDestroy,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { PortRoutingEntity, getCategorizedPortRoutings } from '@zerops/zerops/core/port-routing-base';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { selectZefProgressMapByType } from '@zerops/zef/progress';
import { PortRoutingItemTranslations, PORT_ROUTING_ITEM_FEATURE_NAME } from '@zerops/zui/port-routing-item';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { PortRouting, PublicIpTypes } from '@zerops/models/port-routing';

@Component({
  selector: 'z-port-routing-list',
  templateUrl: './port-routing-list.container.html',
  styleUrls: [ './port-routing-list.container.scss' ]
})
export class PortRoutingListContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  @Output()
  editOpenClick = new EventEmitter<PortRouting>();

  // # Data
  // -- angular
  @ObservableInput()
  @Input('serviceStackId')
  serviceStackId$!: Observable<string>;

  @ObservableInput()
  @Input('items')
  items$!: Observable<PortRouting[]>;

  @Input()
  ip: string;

  @Input()
  type: PublicIpTypes;

  // -- sync
  activeMoreId: string = undefined;
  activePortRoutingItemElRef: ElementRef<any>;

  // -- async
  portRoutingItemTranslations$ = this.translate$<PortRoutingItemTranslations>(PORT_ROUTING_ITEM_FEATURE_NAME);
  serviceStack$ = this.serviceStackId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  categorizedItems$ = this.items$.pipe(
    filter((d) => !!d),
    withLatestFrom(this.serviceStackId$),
    map(([ items, serviceStackId ]) => getCategorizedPortRoutings(items, serviceStackId))
  );
  loadingsMap$ = this._store.pipe(
    select(selectZefProgressMapByType([
      this._portRoutingEntity.deleteOne.type,
      this._portRoutingEntity.restoreOne.type
    ]))
  );

  // # State resolver
  state = this.$connect({
    portRoutingItemTranslations: this.portRoutingItemTranslations$,
    serviceStackId: this.serviceStackId$,
    loadingsMap: this.loadingsMap$,
    categorizedItems: this.categorizedItems$
  });

  constructor(
    private _store: Store<AppState>,
    private _serviceStackEntity: ServiceStackEntity,
    private _portRoutingEntity: PortRoutingEntity,
  ) {
    super();
  }

}
