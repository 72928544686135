import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TransactionDebitBaseEffect } from './transaction-debit-base.effect';
import { FEATURE_NAME } from './transaction-debit-base.constant';
import { transactionDebitBaseReducer } from './transaction-debit-base.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ TransactionDebitBaseEffect ]),
    StoreModule.forFeature(FEATURE_NAME, transactionDebitBaseReducer)
  ]
})
export class TransactionDebitBaseModule {

}
