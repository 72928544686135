import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './http-routing-add-dialog.constant';

const en = {
  hasAlreadyDefined: 'has Domain Access Already Defined',
  editDesc: `Domains have to be unique for the whole project, but don't worry,
  different locations paths can point to different combinations of services and their ports.`,
  editAndAppendData: 'Edit & append your data',
  addNonconflictRoutes: 'All non-conflicting routes and domains <br/>you added so far will be appended.',
  willRemove: 'Will Remove Domain <br /> from Added Data',
  addSuccess: 'Domain Access was added successfully.'
};

export type HttpRoutingAddDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class HttpRoutingAddDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
