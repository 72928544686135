export const NEXTJS_IMPORT_YAML = `project:
  name: recipe-nextjs
  tags:
    - zerops-recipe

services:
  - hostname: ssrapp
    type: nodejs@20
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-nextjs-nodejs

  - hostname: staticapp
    type: static
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-nextjs-static`;
