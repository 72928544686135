import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { NotificationItemModule } from '@zerops/zui/notification-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { WebNotificationsPopContainer } from './web-notifications-pop.container';

@NgModule({
  declarations: [ WebNotificationsPopContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    SatPopoverModule,
    NotificationItemModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent
  ],
  exports: [ WebNotificationsPopContainer ]
})
export class WebNotificationsPopModule {

}
