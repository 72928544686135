import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { MatIconModule } from '@angular/material/icon';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { ZuiProcessStepStateModule } from '@zerops/zui//process-step-state';
import { AppVersionReleaseInfoComponent } from './app-version-release-info.component';

@NgModule({
  declarations: [ AppVersionReleaseInfoComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    ZefAvatarModule,
    ZuiLogoModule,
    ZuiProcessStepStateModule
  ],
  exports: [ AppVersionReleaseInfoComponent ]
})
export class ZuiAppVersionReleaseInfoModule { }
