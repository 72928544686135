import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiTagModule } from '@zerops/zui/tag';
import { ZuiAppVersionItemModule } from '@zerops/zui/app-version-item';
import { ZuiPipelineDescModule } from '@zerops/zui/pipeline-desc';
import { ZuiBuildStateStepsModule } from '@zerops/zui/build-state-steps';
import { ZuiProcessNotificationDurationModule } from '@zerops/zui/process-notification-duration';
import { ZefDialogModule } from '@zerops/zef/dialog';
// import { ZlogModule } from '@zerops/zerops/feature/zlog';
import { LogDialogContainer } from './log-dialog.container';

@NgModule({
  declarations: [ LogDialogContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ZefDialogModule,
    // ZlogModule,
    ZuiTagModule,
    ZuiAppVersionItemModule,
    ZuiPipelineDescModule,
    ZuiBuildStateStepsModule,
    ZuiProcessNotificationDurationModule
  ],
  exports: [ LogDialogContainer ]
})
export class LogDialogModule { }
