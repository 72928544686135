import { Component } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ProjectEntity } from '@zerops/zerops/core/project-base';
import { ProjectStatusEntity } from '@zerops/zerops/core/project-status-base';
import { getProjectStatus } from '@zerops/models/project-status';
import { formValueOnValid } from '@zerops/zef/forms';
import { AppState, GeneralTranslations } from '@zerops/zerops/app';
import { selectActiveCurrency } from '@zerops/zerops/core/settings-base';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { selectCurrentProjectCostMap } from '@zerops/zerops/core/resource-statistics-base';
import { ClientStatusEntity } from '@zerops/zerops/core/client-status-base';
import { selectZefProgressByTypeAndTag } from '@zerops/zef/progress';
import { ProjectStatuses } from '@zerops/models/project';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { ProjectCostLimitForm } from './project-cost-limit.form';
import { FEATURE_NAME } from './project-cost-limit-dialog.constant';
import { ProjectCostLimitDialogModes } from './project-cost-limit-dialog.model';
import { selectMode } from './project-cost-limit-dialog.selector';
import { setMode } from './project-cost-limit-dialog.action';
import { ThemeService } from '@zerops/zef/theme';

@Component({
  selector: 'z-project-cost-limit-dialog',
  templateUrl: './project-cost-limit-dialog.container.html',
  styleUrls: [ './project-cost-limit-dialog.container.scss' ]
})
export class ProjectCostLimitDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onSetTwiceAverageLimit$ = new Subject<void>();
  onUpdateLimit$ = new Subject<void>();
  onRemoveLimit$ = new Subject<void>();
  onClose$ = new Subject<void>();
  onChangeDialogMode$ = new Subject<ProjectCostLimitDialogModes>();

  // # Forms
  formState$ = this._projectCostLimitForm.state$;
  formValue$ = this._projectCostLimitForm.value$;

  // # Data
  // -- angular

  // -- sync
  projectCostLimitDialogModes = ProjectCostLimitDialogModes;
  projectStatuses = ProjectStatuses;

  // -- async
  dialogState$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data.state));
  mode$ = this._store.pipe(select(selectMode));
  projectId$ = this.dialogState$.pipe(
    map((data) => data.meta?.projectId as string),
    filter((d) => !!d)
  );
  project$ = this.projectId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._projectEntity.entityById$(id)),
    filter((d) => !!d)
  );
  projectStatus$ = this.projectId$.pipe(
    filter((d) => !!d),
    switchMap((projectId) => this._projectStatusEntity.list$().pipe(
      map((d) => getProjectStatus(d, projectId)),
      distinctUntilChanged(),
      throttleTime(50)
    ))
  );
  activeCurrency$ = this._store.pipe(select(selectActiveCurrency));
  generalTranslations$ = this.translate$<GeneralTranslations>('general');

  recommendedLimit$ = combineLatest([
    this._store.pipe(select(selectCurrentProjectCostMap)),
    this.projectId$
  ]).pipe(
    filter(([ costMap, id ]) => !!id && !!costMap && !!costMap[id]),
    map(([ costMap, id ]) => Math.round((costMap[id] * 24) * 2))
  );
  totalCredit$ = this._clientStatusEntity.credit$.pipe(map((d) => d?.total));
  limitRemoveLoading$ = this._store.pipe(
    select(selectZefProgressByTypeAndTag(this._projectEntity.updateOne.type, 'limit_remove')),
    map((d) => !!d)
  );
  limitUpdateLoading$ = this._store.pipe(
    select(selectZefProgressByTypeAndTag(this._projectEntity.updateOne.type, 'limit')),
    map((d) => !!d)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    project: this.project$,
    formState: this.formState$,
    formValue: this.formValue$,
    projectStatus: this.projectStatus$,
    activeCurrency: this.activeCurrency$,
    generalTranslations: this.generalTranslations$,
    recommendedLimit: this.recommendedLimit$,
    mode: this.mode$,
    totalCredit: this.totalCredit$,
    limitRemoveLoading: this.limitRemoveLoading$,
    limitUpdateLoading: this.limitUpdateLoading$,
    isZen: this._theme.isZen$
  });

  // # Action Streams
  private _setFormValueAction$ = this.onSetTwiceAverageLimit$.pipe(
    withLatestFrom(this.recommendedLimit$),
    map(([ _, limit ]) => this._projectCostLimitForm.setValue({ limit }))
  );

  private _updateAction$ = this.onUpdateLimit$.pipe(
    formValueOnValid(this._projectCostLimitForm),
    withLatestFrom(this.project$),
    map(([ { limit }, d ]) => this._projectEntity.updateOne(
      d.id,
      {
        ...d,
        maxCreditLimit: limit
      },
      {
        tag: 'limit'
      }
    ))
  );

  private _updateRemoveLimitAction$ = this.onRemoveLimit$.pipe(
    withLatestFrom(this.project$),
    map(([ _, d ]) => this._projectEntity.updateOne(
      d.id,
      {
        ...d,
        maxCreditLimit: null
      },
      {

        tag: 'limit_remove'
      }
    ))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  private _setModeAction$ = this.onChangeDialogMode$.pipe(
    map((mode) => setMode({ mode }))
  );

  constructor(
    private _store: Store<AppState>,
    private _projectEntity: ProjectEntity,
    private _projectCostLimitForm: ProjectCostLimitForm,
    private _projectStatusEntity: ProjectStatusEntity,
    private _clientStatusEntity: ClientStatusEntity,
    private _theme: ThemeService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._setFormValueAction$,
      this._updateAction$,
      this._updateRemoveLimitAction$,
      this._closeAction$,
      this._setModeAction$
    ]);

  }

}
