import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZuiAppVersionItemModule } from '@zerops/zui/app-version-item';
import { WebServiceStackActiveAppversionPopContainer } from './web-service-stack-active-app-version-pop.container';

@NgModule({
  declarations: [ WebServiceStackActiveAppversionPopContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    SatPopoverModule,
    ZuiAppVersionItemModule
  ],
  exports: [ WebServiceStackActiveAppversionPopContainer ]
})
export class WebServiceStackActiveAppVersionPopModule { }
