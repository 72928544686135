import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProjectStatuses } from '@zerops/models/project';
import { FormGroupState } from 'ngrx-forms';
import { ProjectCostLimitFormValue } from '../../project-cost-limit.form';

@Component({
  selector: 'z-project-cost-limit-edit',
  templateUrl: './project-cost-limit-edit.component.html',
  styleUrls: ['./project-cost-limit-edit.component.scss']
})
export class ProjectCostLimitEditComponent {

  private _hideBackButton = false;
  projectStatuses = ProjectStatuses;

  @Input()
  formState: FormGroupState<ProjectCostLimitFormValue>;

  @Input()
  creditLimit: number;

  @Input()
  projectStatus: ProjectStatuses;

  @Input()
  remainingCredit: number;

  @Input()
  recommendedLimit: number;

  @Input()
  updateLoading = false;

  @Input()
  set hideBackButton(v) {
    this._hideBackButton = coerceBooleanProperty(v);
  }
  get hideBackButton(): boolean {
    return this._hideBackButton;
  }

  @Output()
  backClicked = new EventEmitter<void>();

  @Output()
  updateLimitClicked = new EventEmitter<void>();

  @Output()
  setTwiceAverageClicked = new EventEmitter<void>();

  formatSliderLabel = ((value: number) => `Limit $${value}`);

}
