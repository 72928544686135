import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
  exports: [
    MatButtonModule,
    MatIconModule,
    MatCardModule
  ]
})
export class MaterialStackModule {

}
