import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { PortsFormFieldModule } from '@zerops/zui/ports-form-field';
import { GithubRepoFormModule } from '@zerops/zerops/feature/github-repo-form';
import { GitlabRepoFormModule } from '@zerops/zerops/feature/gitlab-repo-form';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import {
  PythonServiceStackFormComponent
} from './python-service-stack-form.component';
import {
  PythonServiceStackFormTranslationsModule
} from './python-service-stack-form.translations';
import { HostnameFieldModule } from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';
import { CodeFieldModule } from '@zerops/zui/code-field';

@NgModule({
    declarations: [ PythonServiceStackFormComponent ],
    imports: [
      ZefFormsModule,
      MaterialFormStackModule,
      FormSectionModule,
      CommonModule,
      HostnameFieldModule,
      ZuiAutoscalingFormFieldModule,
      PythonServiceStackFormTranslationsModule,
      PortsFormFieldModule,
      UserDataFormFieldModule,
      GithubRepoFormModule,
      GitlabRepoFormModule,
      ZuiDocsTextModule,
      ZefPipesModule,
      ZefMessageFormatDirective,
      ZuiSeparatorComponent,
      CodeFieldModule
    ],
    exports: [ PythonServiceStackFormComponent ]
})
export class PythonServiceStackFormModule {

}
