import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { PortsFormFieldModule } from '@zerops/zui/ports-form-field';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import { GithubRepoFormModule } from '@zerops/zerops/feature/github-repo-form';
import { GitlabRepoFormModule } from '@zerops/zerops/feature/gitlab-repo-form';
import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZefPipesModule } from '@zerops/zef/pipes';
import {
  NodejsServiceStackFormComponent
} from './nodejs-service-stack-form.component';
import {
  NodejsServiceStackFormTranslationsModule
} from './nodejs-service-stack-form.translations';
import { HostnameFieldModule } from '../../fields';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { CodeFieldModule } from '@zerops/zui/code-field';

@NgModule({
  declarations: [ NodejsServiceStackFormComponent ],
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    CommonModule,
    NodejsServiceStackFormTranslationsModule,
    GithubRepoFormModule,
    GitlabRepoFormModule,
    PortsFormFieldModule,
    UserDataFormFieldModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiAutoscalingFormFieldModule,
    ZefMessageFormatDirective,
    ZuiSeparatorComponent,
    CodeFieldModule
  ],
  exports: [ NodejsServiceStackFormComponent ]
})
export class NodejsServiceStackFormModule {

}
