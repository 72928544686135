import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { FilterIconComponent } from './filter-icon.component';

@NgModule({
  declarations: [ FilterIconComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule
  ],
  exports: [ FilterIconComponent ]
})
export class ZuiFilterIconModule { }
