import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn
} from '@angular/common/http';
import { environment } from '@zerops/zerops/env';

export const apiBaseInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  if (request.url.startsWith('/api') && !request.url.includes(environment.apiUrl)) {
    request = request.clone({
      url: environment.apiUrl + (request.url.replace('/api', ''))
    });
  }
  return next(request);
}
