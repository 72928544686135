<div
  class="__grid"
  [class.__grid--start]="items?.length >= 3">

  <div *ngFor="let item of items; trackBy: trackBy;">
    <div
      class="__tile"
      [class.is-selected]="_idMap[item.id]">

      <a
        *ngIf="mode === modes.Add"
        class="__name"
        [href]="'/service-stack/' + item.id"
        target="_blank">

        {{ item.name }}

        <mat-icon class="__icon">open_in_new</mat-icon>

      </a>

      <a
        *ngIf="mode === modes.Stack"
        class="__name"
        [routerLink]="mode === modes.Stack ? [ '/service-stack', item.id ] : undefined">
        {{ item.name }}
      </a>

      <span class="__type">{{ item.serviceStackTypeInfo.serviceStackTypeName }}</span>

      <!-- TODO: translate -->
      <mat-slide-toggle
        class="__toggle"
        [matTooltip]="_idMap[item.id] ? 'Disconnect' : 'Connect'"
        [checked]="_idMap[item.id]"
        (change)="_toggleSelected(item.id)">
      </mat-slide-toggle>

    </div>
  </div>

  <div class="__empty-text" *ngIf="items?.length === 0">

    <ng-container *ngIf="mode === modes.Stack">
      There are no services that can be connected.
      <a
        class="c-button--smaller"
        color="accent"
        mat-stroked-button
        [routerLink]="[ '/project', projectId, 'add-service-stack' ]">
        Add new service
      </a>
    </ng-container>

    <ng-container *ngIf="mode === modes.Add">
      There are no services that can be connected
    </ng-container>

  </div>

</div>
