import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PortsFormFieldComponent } from './ports-form-field.component';
import { ActionListItemModule } from '../action-list-item';
import { DynamicPopAnchorModule } from '../dynamic-pop-anchor';
import { ZuiMoreButtonModule } from '../more-button';
import { ZuiTipCardComponent } from '../tip-card';

@NgModule({
  declarations: [ PortsFormFieldComponent ],
  imports: [
    CommonModule,
    SatPopoverModule,
    ZuiMaterialStackModule,
    ReactiveFormsModule,
    ZuiMaterialFormStackModule,
    ZefFormsModule,
    ZefProgressErrorModule,
    MatListModule,
    MatCheckboxModule,
    ActionListItemModule,
    ZuiMoreButtonModule,
    DynamicPopAnchorModule,
    ZuiTipCardComponent
  ],
  exports: [ PortsFormFieldComponent ]
})
export class PortsFormFieldModule { }
