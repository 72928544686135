import { Client } from '@zerops/models/client';
import { User } from '@zerops/models/user';

export class ClientUser {
  accessToClientZone: boolean;
  client: Client;
  clientId: string;
  cloudDnsEnabled: boolean;
  created: string;
  id: string;
  lastUpdate: string;
  roleCode: string;
  status: ClientUserStatuses;
  user: User;
  userId: string;

  // TODO: remove?
  languageId?: string;
  email?: string;
}

export enum ClientUserStatuses {
  active = 'ACTIVE',
  beingDeleted = 'BEING_DELETED',
  waitingAuthorization = 'WAITING_AUTHORIZATION'
}
