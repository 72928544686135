export const en = {
  ok: 'Ok',
  confirm: 'Confirm',
  cancel: 'Cancel',
  edit: 'Edit',
  delete: 'Delete',
  restore: 'Restore',
  close: 'Close',
  showMore: 'Show more',
  save: 'Save',
  takeBack: 'Take back',
  copySuccess: 'Copied to clipboard.',
  copyToClipboard: 'Click to copy to clipboard',
  created: 'Created',
  goTo: 'Go to',
  sort: {
    created: 'Created',
    name: 'Name',
    status: 'Status',
    lastUpdate: 'Last Update',
    key: 'Key',
    content: 'Value'
  },
  stackTypeCategoryName: {
    CORE: 'Project core service',
    CORELEGACY: 'Project core service',
    CORESERIOUS: 'Project core service',
    CORELIGHT: 'Project core + HTTP routing & balancers service',
    HTTP_L7_BALANCER: 'HTTP routing & balancer service'
  },
  stackTypeCategoryContainers: {
    CORE: 'Zerops core',
    HTTP_L7_BALANCER: 'HTTP balancer'
  },
  stackCategoryText: {
    CORE: `This service provides secure data communication between the Internet and your project (with built-in firewall). With dedicated containers for logs, log forwarding and statistics collection.
    Managed by Zerops.`,
    CORELEGACY: `This service provides secure data communication between the Internet and your project (with built-in firewall). With dedicated containers for logs, log forwarding and statistics collection.
    Managed by Zerops.`,
    CORESERIOUS: `This service provides secure data communication between the Internet and your project (with built-in firewall). With dedicated containers for logs, log forwarding and statistics collection.
    Managed by Zerops.`,
    CORELIGHT: `This service provides secure data communication between the Internet and your project (with built-in firewall), handles logs, log forwarding and statistics collection,
    also manages SSL termination and certification and routes HTTP/S traffic from core to your services,
    through your own domains or Zerops subdomain for previews. Managed by Zerops.`,
    HTTP_L7_BALANCER: `This service manages SSL termination and certification and routes HTTP/S traffic from core to your services,
  through your own domains or Zerops subdomain for previews. Managed by Zerops.`
  },
  stackStatus: {
    NEW: 'Waiting for core service',
    CREATING: 'installing & getting ready',
    STOPPED: 'stopped',
    ACTIVE: 'active',
    DELETING: 'deleting',
    FAILED: 'failed',
    STOPPING: 'stopping',
    STARTING: 'starting',
    RESTARTING: 'restarting',
    RELOADING: 'reloading',
    UPGRADING: 'upgrading',
    MOVING_CONTAINER: 'moving container',
    UPGRADE_FAILED: 'upgrade failed',
    ACTION_FAILED: 'failed',
    READY_TO_DEPLOY: 'ready to deploy',
    REPAIRING: 'repairing',
    SCALING: 'scaling',
    CONTAINER_FAILED: 'container failed',
    NEW_BUILD_PENDING: 'new with pipeline pending',
    ACTIVE_BUILD_PENDING: 'active with pending pipeline',
    NEW_BUILD_RUNNING: 'new with pipeline running',
    ACTIVE_BUILD_RUNNING: 'active with running pipeline',
    NEW_AND_INSTALLING: 'new and installing'
  },
  projectStatus: {
    NEW: 'pending',
    CREATING: 'creating',
    ACTIVE: 'active',
    DELETING: 'deleting',
    STOPPING: 'stopping',
    STARTING: 'starting',
    DELETED: 'deleted',
    FAILED: 'failed',
    STOPPED: 'stopped'
  },
  paymentStatus: {
    REQUESTED: 'requested',
    PROCESSING: 'processing',
    PAYMENT_SUCCESS: 'payment success',
    PAYMENT_DECLINED: 'payment declined',
    BALANCE_UPDATING: 'balance updating',
    BALANCE_UPDATED: 'balance updated',
    INVOICING: 'invoicing',
    INVOICED: 'invoiced',
    PAYMENT_FAILED: 'payment failed',
    BALANCE_UPDATE_FAILED: 'balance update failed',
    INVOICING_FAILED: 'invoicing failed',
    FINISHED: 'finished',
    PAYMENT_DECLINE_FAILED: 'payment decline failed',
    FAILED: 'failed'
  },
  containerStatus: {
    CREATING: 'creating',
    ACTIVE: 'active',
    DELETING: 'deleting',
    FAILED: 'failed',
    DELETED: 'Removed',
    STOPPING: 'stopping',
    STOPPED: 'stopped',
    STARTING: 'starting',
    RESTARTING: 'restarting',
    RELOADING: 'reloading',
    MOVING: 'moving',
  },
  containerCategory: {
    EMPTY: 'Empty',
    MASTER: 'Master',
    LOGGER: 'Logger controller',
    HTTP_L7_BALANCER: 'HTTP balancer',
    APPLICATION_BALANCER: 'Load balancer',
    DATABASE: 'Database container',
    MESSAGE_BROKER: 'Message broker container',
    USER_APPLICATION: 'Runtime container',
    SHARED_STORAGE: 'Shared storage',
    SYSTEM: 'System'
  },
  addonName: {
    PROJECT_CORE_LEGACY: 'Legacy package',
    PROJECT_CORE_SERIOUS: 'Serious package',
    PROJECT_CORE_LIGHT: 'Lightweight package',
    PROJECT_IPV4: 'Unique IPv4'
  },
  serviceName: {
    redis: 'KeyDB'
  }
};

export type GeneralTranslations = typeof en;

export enum RouteKeys {
  Login = 'login',
  Registration = 'registration',
  Invitation = 'invitation',
  Whitepaper = 'whitepaper',
  Recipe = 'recipe',
  Recipes = 'recipes',
  PasswordChange = 'password-change',
  ForgottenPassword = 'forgotten-password',
  PasswordRecovery = 'password-recovery',
  Dashboard = 'dashboard',
  ProjectDetail = 'project-detail',
  ProjectAdd = 'project-add',
  ProjectEdit = 'project-edit',
  ServiceStackDetail = 'service-stack-detail',
  ServiceStackAdd = 'service-stack-add',
  Account = 'account',
  Company = 'company',
  UserDetail = 'user-detail',
  UserList = 'user-list',
  UserAdd = 'user-add',
  UserEdit = 'user-edit',
  Settings = 'settings',
  GithubAuth = 'github-auth',
  LoginToZerops = 'login-to-zerops'
}

export enum ApiEntityKeys {
  User = 'user',
  ClientUser = 'client-user',
  ClientStatus = 'client-status',
  Client = 'client',
  Payment = 'card-payment',
  UserData = 'user-data',
  ServiceStack = 'service-stack',
  Notification = 'user-notification',
  Process = 'process',
  Project = 'project',
  ProjectStatus = 'project-status',
  HttpRouting = 'public-http-routing',
  AppVersion = 'app-version',
  PortRouting = 'public-port-routing',
  Container = 'container',
  Service = 'service',
  Subscription = 'subscription',
  TransactionDebit = 'transaction-debit',
  TransactionCredit = 'transaction-credit',
  UserToken = 'user-token',
  StackEvent = 'stack-event'
}

export enum OsPlatforms {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Generic = 'Generic'
}

export const DEFAULT_APP_ROUTE = [ '/', 'dashboard', 'projects', {} ];
