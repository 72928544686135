<mat-icon
  *ngIf="state === buildProcessStates.Timer"
  class="[ __icon  __icon--timer ]  c-process-step-state-icon">schedule</mat-icon>

<div
  class="[ __wrap __wrap--queued ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Queued">
  <mat-icon class="[ __icon  __icon--queued ]  c-process-step-state-icon">hourglass_empty</mat-icon>
</div>

<div
  class="[ __wrap __wrap--waiting ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Waiting && !running">
  <mat-icon class="[ __icon  __icon--waiting ]  c-process-step-state-icon">pause</mat-icon>
</div>

<div
  class="[ __wrap __wrap--running ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Running || running">
  <mat-icon class="[ __icon  __icon--running ]  c-process-step-state-icon">play_arrow</mat-icon>
</div>

<div
  class="[ __wrap __wrap--finished ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Finished">
  <mat-icon class="[ __icon  __icon--finished ]  c-process-step-state-icon">done</mat-icon>
</div>

<div
  class="[ __wrap __wrap--failed ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Failed">
  <mat-icon class="[ __icon  __icon--failed ]  c-process-step-state-icon">priority_high</mat-icon>
</div>

<div
  class="[ __wrap __wrap--cancelled ]  c-process-step-state-wrap"
  *ngIf="state === buildProcessStates.Cancelled">
  <mat-icon class="[ __icon  __icon--cancelled ]  c-process-step-state-icon">close</mat-icon>
</div>

<span class="__ripple" *ngIf="state === buildProcessStates.Running"></span>
