import { ChangeDetectorRef, Directive, inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { MessageFormatService } from './message-format.service';

@Directive({
  selector: '[zefMessageFormat]',
  standalone: true
})
export class ZefMessageFormatDirective implements OnInit {
  #mfService = inject(MessageFormatService);
  #cdr = inject(ChangeDetectorRef);
  #templateRef = inject(TemplateRef);
  #viewContainer = inject(ViewContainerRef);
  #translationMemo: Record<
    string,
    { value: string; params?: Record<string, any> }
  > = {};

  ngOnInit() {
    const formatFn = (pattern: string, params: Record<string, any>) => {
      const withParams = params
        ? `${pattern}${JSON.stringify(params)}`
        : pattern;
      if (!this.#translationMemo[withParams]) {
        this.#translationMemo[withParams] = {
          value: this.#mfService.format(pattern, params),
          params
        };
      }
      return this.#translationMemo[withParams].value;
    };
    this.#viewContainer.createEmbeddedView(this.#templateRef, {
      $implicit: formatFn
    });
    this.#cdr.markForCheck();
  }

}
