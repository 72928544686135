import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { UserData } from '@zerops/models/user-data';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Sort } from '@zerops/zef/sort';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserDataEntity extends EntityService<UserData> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'asc'
  };

  defaultSort$ = of(this.defaultSort);

  list$(
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: UserData) => any)> = [ (d) => d.type === 'READ_ONLY', this.defaultSort.key ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ 'desc', this.defaultSort.direction ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.UserData, store, collectionManager, websocketService);
  }
}
