import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefRemoveError } from '@zerops/zef/errors';
import { of, from, concat } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import {
  zefGitlabAuthUrlRequest,
  zefGitlabAuthUrlFail,
  zefGitlabAuthUrlSuccess,
  zefGitlabSignupRequest,
  zefGitlabSignupSuccess,
  zefGitlabSignupFail,
  zefGitlabLoginRequest,
  zefGitlabLoginSuccess,
  zefGitlabLoginFail,
  zefGitlabRepositoryAccessRequest,
  zefGitlabRepositoryAccessSuccess,
  zefGitlabRepositoryAccessFail,
  zefGitlabRepositoriesRequest,
  zefGitlabRepositoriesSuccess,
  zefGitlabRepositoriesFail,
  zefGitlabBranchRequest,
  zefGitlabBranchSuccess,
  zefGitlabBranchFail
} from './gitlab.action';
import { ZefGitlabService } from './gitlab.service';
import { ZefGitlabApiAuthorizePayload, ZefGitlabAuthUrlType } from './gitlab.model';
import { loginWithGitlab, parseGitlabAuthState } from './gitlab.utils';
import { zefAddProgress, zefRemoveProgress } from '../progress';
import { ZEF_GITLAB_AUTH_PROGRESS_KEY } from './gitlab.constant';

@Injectable({ providedIn: 'root' })
export class ZefGitlabEffect {

  private _onGetAuthUrl$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabAuthUrlRequest),
    switchMap((action) => this._api
      .getAuthUrl(action.data.type, action.data.recipes)
      .pipe(
        map((res) => zefGitlabAuthUrlSuccess(res, action)),
        catchError((err) => of(zefGitlabAuthUrlFail(err, action)))
      )
    )
  ));

  private _onGetAuthUrlSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabAuthUrlSuccess),
    tap(({ data }) => {
      window.location.href = data.gitlabUrl;
    })
  ), { dispatch: false });

  private _onSignupRequest$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabSignupRequest),
    switchMap((action) => this._api
      .signup(action.data)
      .pipe(
        map((res) => zefGitlabSignupSuccess(res, action)),
        catchError((err) => {

          // registration with existing user performs login
          if (err.code === 'emailExists') {
            const parsed = parseGitlabAuthState(action.data.state);
            return of(zefGitlabAuthUrlRequest({
              type: ZefGitlabAuthUrlType.Login,
              recipes: { nonHaRecipeId: parsed.nonHaRecipe, haRecipeId: parsed.haRecipe }
            }));
          }

          return of(zefGitlabSignupFail(err, action));
        })
      )
    )
  ));

  private _onLoginRequest$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabLoginRequest),
    switchMap((action) => this._api
      .login(action.data)
      .pipe(
        map((res) => zefGitlabLoginSuccess(res, action)),
        catchError((err) => {

          // login with non existing user performs registration
          if (err.code === 'userAccountNotFound') {
            return of(zefGitlabAuthUrlRequest({
              type: ZefGitlabAuthUrlType.Registration
            }));
          }

          return of(zefGitlabLoginFail(err, action));
        })
      )
    )
  ));

  private _onRepositoryAccessRequest$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabRepositoryAccessRequest),
    switchMap((action) => this._api
      .repositoryAccess(action.data)
      .pipe(
        map((res) => zefGitlabRepositoryAccessSuccess(res, action)),
        catchError((err) => of(zefGitlabRepositoryAccessFail(err, action)))
      )
    )
  ));

  private _onRepositoriesRequest$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabRepositoriesRequest),
    switchMap((action) => this._api
      .repositories()
      .pipe(
        map((res) => zefGitlabRepositoriesSuccess(res, action)),
        catchError((err) => of(zefGitlabRepositoriesFail(err, action)))
      )
    )
  ));

  private _onBranchRequest$ = createEffect(() => this._actions$.pipe(
    ofType(zefGitlabBranchRequest),
    switchMap((action) => this._api
      .repositoryBranch(action.data)
      .pipe(
        map(({ branches }) => zefGitlabBranchSuccess(
          { branches, repositoryName: action.data },
          action
        )),
        catchError((err) => of(zefGitlabBranchFail(err, action)))
      )
    )
  ));

  private _onRepositoriesFailTryAuth$ = createEffect(() => this._actions$.pipe(
    // when repositories request fails
    ofType(zefGitlabRepositoriesFail),
    switchMap(() => concat(
      // manually add auth progress, since it's a mix
      // of different api calls
      of(zefAddProgress(ZEF_GITLAB_AUTH_PROGRESS_KEY)),
      // get auth URL from zerops api
      this._api
        .getAuthUrl(ZefGitlabAuthUrlType.Repository)
        .pipe(
          // open new pop window with given url to try and get gitlab auth token
          switchMap(({ gitlabUrl }) => from(loginWithGitlab(gitlabUrl)).pipe(
            map((res) => res),
            catchError(() => of(undefined))
          )),
          catchError(() => of(undefined)),
          switchMap((data) => {

            // if response from gitlab auth pop window contains error
            // or we caught any error from our API, return undefined
            if (data?.['error'] || data === undefined) {
              return of(undefined);
            }

            // otherwise try to write the gitlab response to zerops
            return this._api
              .repositoryAccess(data as ZefGitlabApiAuthorizePayload)
              .pipe(catchError(() => of(undefined)));

          }),
          switchMap((data) => {
            // if we got back data, we should now have permissions
            // to read gitlab repositories, so repeat repositories request
            // and remove the auth progress
            if (data) {
              return [
                zefGitlabRepositoriesRequest(),
                zefRemoveProgress(ZEF_GITLAB_AUTH_PROGRESS_KEY)
              ];
            } else {
              // otherwise remove the original error and the progress
              // and give up
              return [
                zefRemoveError(zefGitlabRepositoriesRequest.type),
                zefRemoveProgress(ZEF_GITLAB_AUTH_PROGRESS_KEY)
              ];
            }
          })
        )
      )
    )
  ));

  constructor(
    private _actions$: Actions,
    private _api: ZefGitlabService
  ) { }

}
