import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { ClientUser } from '@zerops/models/client-user';

@Component({
  selector: 'z-client-user-select',
  templateUrl: './client-user-select.container.html',
  styleUrls: [ './client-user-select.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientUserSelectContainer {
  @Output()
  selected = new EventEmitter<ClientUser>();

  @Input()
  data: ClientUser[];
}
