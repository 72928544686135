import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DnsCheckStatuses } from '@zerops/models/http-routing';
import { ServiceStackStatuses } from '@zerops/models/service-stack';

@Component({
  selector: 'zui-routing-location-item',
  templateUrl: './routing-location-item.component.html',
  styleUrls: [ './routing-location-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutingLocationItemComponent implements OnChanges {

  dnsCheckStatuses = DnsCheckStatuses;
  serviceStackStatuses = ServiceStackStatuses;

  @Input()
  internalProtocol: string;

  @Input()
  path: string;

  @Input()
  port: string;

  @Input()
  dnsCheckStatus: DnsCheckStatuses;

  @Input()
  isSynced: boolean;

  @Input()
  serviceStackId: string;

  @Input()
  serviceStackStatus: ServiceStackStatuses;

  @Input()
  serviceStackName: string;

  @Input()
  type: 'stack' | 'other' | 'project' = 'stack';

  @Input()
  serviceStackTypeFullname: string;

  @Input()
  copyToClipboardTooltipTranslation: string;

  @Input()
  sslEnabled: boolean;

  @Input()
  activeSsl: boolean;

  @Input()
  routingType: 'http' | 'port';

  @Input()
  portTextAlign: 'left' | 'right' = 'left';

  @Input()
  pathTextAlign: 'left' | 'right' = 'left';

  @Input()
  set disableLink(v: string | boolean) {
    this._disableLink = coerceBooleanProperty(v);
  }
  get disableLink(): boolean {
    return this._disableLink;
  }

  @Input()
  set showDomainPlaceholder(v: string | boolean) {
    this._showDomainPlaceholder = coerceBooleanProperty(v);
  }
  get showDomainPlaceholder(): boolean {
    return this._showDomainPlaceholder;
  }

  safeHref: SafeUrl;
  safeHrefRaw: string;
  portRoutingLocation: { path: string; } = undefined;
  safeDomainPlaceholder = this._saniziter.bypassSecurityTrustHtml('{domain}');
  private _disableLink: boolean;
  private _showDomainPlaceholder: boolean;

  constructor(private _saniziter: DomSanitizer) { }

  ngOnChanges() {
    // TODO: temp
    this.safeHrefRaw = this.routingType === 'http'
      ? ((this.activeSsl || this.path?.includes('.zerops.io')) ? this.path.replace('http://', 'https://') : this.path)
      : ('http://' + this.path);
    this.safeHref = this._saniziter.bypassSecurityTrustUrl(this.safeHrefRaw);
  }
}
