import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { onWebsocketMessageDispatchUpdateEntities, MergeStrategy } from '@zerops/zef/entities';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { syncHttpRouting, syncHttpRoutingSuccess, syncHttpRoutingFail } from './http-routing-base.action';
import { HttpRoutingBaseApi } from './http-routing-base.api';
import { HttpRoutingEntity } from './http-routing-base.entity';

@Injectable()
export class HttpRoutingBaseEffect {

  private _onSync$ = createEffect(() => this._actions$.pipe(
    ofType(syncHttpRouting),
    switchMap((action) => this._api
      .sync$(action.data)
      .pipe(
        map((res) => syncHttpRoutingSuccess(res, action)),
        catchError((res) => of(syncHttpRoutingFail(res, action)))
      )
    )
  ));

  private _setupHttpRoutingUpdateSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._httpRoutingEntity.updateSubscribe(clientId),
  )));

  private _setupUpdateCacheDispatch$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(
      this._httpRoutingEntity,
      {
        zefEntityMergeStrategy: {
          domains: MergeStrategy.KeepNew,
          locations: MergeStrategy.KeepNew
        }
      }
    )
  ));

  constructor(
    private _api: HttpRoutingBaseApi,
    private _userEntity: UserEntity,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _actions$: Actions
  ) { }
}
