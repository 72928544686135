import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NgStringPipesModule } from 'ngx-pipes';
import { AppVersionNameComponent } from './app-version-name.component';
import { ZuiAppVersionReleaseInfoModule } from '@zerops/zui/app-version-release-info';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';

@NgModule({
  declarations: [ AppVersionNameComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    NgStringPipesModule,
    ZuiAppVersionReleaseInfoModule,
    ZuiProcessStepStateModule
  ],
  exports: [ AppVersionNameComponent ]
})
export class ZuiAppVersionNameModule { }
