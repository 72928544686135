import { zefActionPayload, zefActionSuccessPayload, zefActionFailPayload } from '@zerops/zef/core';
import { createAction, props, union } from '@ngrx/store';
import {
  StatisticsGroupBy,
  CurrentStatistic,
  StatisticsEntities
} from '@zerops/models/resource-statistics';
import { HistoryStatisticsGroupRequestPayload, HistoryStatisticsGroupUpdateSuccessPayload } from './resource-statistics-base.model';

export const loadCurrentStatistics = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-current-statistics',
  zefActionPayload<{ clientId: string; groupBy: StatisticsGroupBy; statisticsEntity: StatisticsEntities; }>()
);

export const loadCurrentStatisticsFail = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-current-statistics/fail',
  zefActionFailPayload
);

export const loadCurrentStatisticsSuccess = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-current-statistics/success',
  zefActionSuccessPayload<{ items: CurrentStatistic[]; key: any; }>()
);

export const loadCurrentStatisticsUpdate = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-current-statistics/update',
  (items: CurrentStatistic[], key: any) => ({ data: { items, key } })
);

export const loadResourceHistoryStatistics = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-history-statistics',
  zefActionPayload<HistoryStatisticsGroupRequestPayload>()
);

export const loadResourceHistoryStatisticsCancel = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-history-statistics/cancel',
  zefActionPayload
);

export const loadResourceHistoryStatisticsFail = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-history-statistics/fail',
  zefActionFailPayload
);

export const loadResourceHistoryStatisticsSuccess = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-history-statistics/success',
  zefActionSuccessPayload<HistoryStatisticsGroupUpdateSuccessPayload>()
);

export const loadResourceHistoryStatisticsUpdate = createAction(
  '[@zerops/zerops/core/resource-statistics-base] load-history-statistics/update',
  props<HistoryStatisticsGroupUpdateSuccessPayload>()
);

const all = union({
  loadCurrentStatistics,
  loadCurrentStatisticsFail,
  loadCurrentStatisticsSuccess,
  loadResourceHistoryStatistics,
  loadResourceHistoryStatisticsFail,
  loadResourceHistoryStatisticsSuccess,
  loadResourceHistoryStatisticsUpdate
});

export type StatisticsBaseActionUnion = typeof all;
