import {
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ProjectStatuses } from '@zerops/models/project';

@Component({
  selector: 'zui-ip-info',
  templateUrl: './ip-info.component.html',
  styleUrls: [ './ip-info.component.scss' ]
})
export class IpInfoComponent {

  @Input()
  ipV4: string;

  @Input()
  projectStatus: ProjectStatuses;

  @Input()
  ipV4PendingRequest = false;

  @Input()
  ipV4Prefix: string;

  @Input()
  ipV6Prefix: string;

  @Input()
  ipV6: string;

  @Input()
  requestIpv4Key: string;

  @Input()
  requestIpV4ButtonText: string;

  @Input()
  ipV4AddonInProcess = false;

  @Input()
  set showOnlyIpV4Button(v: string | boolean) {
    this._showOnlyIpV4Button = coerceBooleanProperty(v);
  }
  get showOnlyIpV4Button(): boolean {
    return this._showOnlyIpV4Button;
  }

  @Output()
  requestIpV4 = new EventEmitter<void>();

  projectStatuses = ProjectStatuses;
  private _showOnlyIpV4Button: boolean;

}
