/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './contextual-dialog.constant';
import { Contexts } from './contextual-dialog.container';

const en = {
  // project
  [Contexts.AddServiceStackClick]: {
    header: 'Would you like to add a new service to the project?',
    content: `A project can contain an <a data-zui-docs="overview/made-for-developers.html#each-developer-should-have-his-own-account-no-artificial-pricing-boosting">unlimited number of services</a>.
    Services are of different types and depending on their type they are either fully managed by Zerops,
    or partially managed by Zerops while giving you straightforward management abilities through the Zerops app.`
  },
  [Contexts.ProjectSettingsClick]: {
    header: 'Would you like to change settings of the project?',
    content: `It is possible to change the name of your <a data-zui-docs="overview/projects-and-services-structure.html#project">project</a>, its tags which are
    user mainly for filtering and better overview and its description.`
  },
  [Contexts.ProjectRoutingClick]: {
    header: 'Would you like check the routing overview of your project?',
    content: `You can easily set up routing rules — which domains and their locations should point to which service inside of your <a data-zui-docs="overview/projects-and-services-structure.html#project">project</a>.
    Let's encrypt (opens new window) SSL Certificate can be automatically installed for you. You can also open public ports on the IP addresses assigned to the project and point them to any service and its port.`
  },
  [Contexts.ProjectCostClick]: {
    header: 'Would you like check resources cost & add-ons?',
    content: `Each project you add activates the project core subscription for $5 per 30 days. This includes unique IPv6 address and shared IPv4 address,
    core services with built-in logging, an L7 balancer that takes care of SSL certification, and daily backups kept two days back. Further add-ons can be activated for each project,
    depending on your use case, whether it's a production project or any other environment. Open <a data-zui-docs="overview/pricing.html#pricing">pricing documentation</a> to see more.`,
    featureList: [
      'Unique IPv6 address',
      'Shared IPv4 address',
      'Core services with built-in logging',
      'L7 balancer that takes care of SSL certification, and daily backups',
      'In the beginning, you\'ll get $20 worth of promo credit'
    ]
  },
  [Contexts.ProjectCostLimitClick]: {
    header: 'Would you like set up a cost limit?',
    content: `Setting a daily cost limit will make sure that your hardware resources cost do not exceed a given amount. A reasonable limit might be 2-3 times your daily average to allow for peaks.`
  },
  [Contexts.ProjectDeleteClick]: {
    header: 'Fortunately you do not have permission to destroy this web',
    content: `Once you have your own Zerops account you can delete as many <a data-zui-docs="overview/projects-and-services-structure.html#project">projects</a> as you want.`
  },
  [Contexts.ProjectDetailClick]: {
    header: 'Would you like to go to project detail?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#project">Project</a> detail will give you more detailed overview of your project and all services inside it in terms of public routing, resources-cost & add-ons and more...`
  },
  [Contexts.ProjectExportClick]: {
    header: 'Would you like to export the project?',
    content: `Export whole project with all services and configurations in the .yaml format. The use it in zerops.yaml file or save it for later.`
  },
  // service stack
  [Contexts.ServiceStackDetailClick]: {
    header: 'Would you like to go to service detail and view containers and autoscaling options?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Service</a> detail will give you more detailed overview of containers on which service runs, active / failed / archived code versions, internal & public routings, builds & deploys, Environment variables, resources cost, gives access to runtime log and more... set your autoscaling preferences there.`
  },
  [Contexts.ServiceStackStartClick]: {
    header: 'Would you like to start the service?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Service</a> can be started which activates containers on which the service runs, reactivates public and internal routings and logging on the service. You can queue as many service operations as you want.`
  },
  [Contexts.ServiceStackStopClick]: {
    header: 'Would you like to stop the service?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Service</a> can be stopped which deactivates containers on which the service runs, it also deactivates  public and internal routings and logging on the service.`
  },
  [Contexts.ServiceStackRestartClick]: {
    header: 'Would you like to restart the service?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Service</a> can be restarted which restarts all containers on which the service runs, the restart is necesarry to apply new Environment variables.`
  },
  [Contexts.ServiceStackReloadClick]: {
    header: 'Would you like to reload the service?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Service</a> can be reloaded which does all the necesarry actions to apply new configurations to your service.`
  },
  [Contexts.ServiceStackDeleteClick]: {
    header: 'Fortunately you do not have permission to destroy the service, it is indispensable for this web',
    content: `Once you have your own Zerops account you can delete as many <a data-zui-docs="overview/projects-and-services-structure.html#service">services</a> as you want.`
  },
  [Contexts.ServiceStackUserDataClick]: {
    header: 'Would you like to go to environment variables page?',
    content: `<a data-zui-docs="overview/projects-and-services-structure.html#service">Services</a> define their own <a data-zui-docs="environment-variables/how-to-access.html">environment variables</a> that are then available inside a service. Each service has access to environment variables of other services inside the same project prefixed by their name.`,
    featureList: [
      'Available via environment variables inside runtime services',
      'Creating user defined variables'
    ]
  },
  [Contexts.ServiceStackLogClick]: {
    header: 'Would you like view the log of this notification?',
    content: `Build failed or succeeded? You can view the log of whole process step by step, if there is an error in your runtime or build container, you can easily discover it and fix it.`
  },
  [Contexts.ServiceStackDeployClick]: {
    header: 'Would you like to go to app version & pipeline settings page?',
    content: `Each package you <a data-zui-docs="build/how-zerops-build-works.html">build and / or deploy</a> to your runtime service is archived, allowing you to switch between versions on the fly. However, only one package can be active at a given point in time. A deploy item is created each time build pipeline is run. If the build fails, it will be visible on the list. Once you are done fixing your pipeline, you can remove these failed deploys. You can connect the service with a GitHub or GitLab repository to automatically trigger the pipeline on commit or a new tag / release.`
  },
  [Contexts.ServiceStackHowToAccessClick]: {
    header: 'Would you like to know how to access the service?',
    content: `Other services inside the project can access the <a data-zui-docs="services/databases.html#redis">database</a> by its hostname and port. You can also utilize the connectionString environment variable generated automatically by Zerops that ensures compatibility in HA mode.`
  },
  [Contexts.ServiceStackRoutingClick]: {
    header: 'Would you like to know more about service routing?',
    content: `Each service is automatically set up to be able to <a data-zui-docs="routing/routing-between-project-services.html">securely communicate</a> with other services inside the same project using their hostnames and ports.
    If your application needs to be publicly accessible, Zerops can easily set up access from your <a data-zui-docs="routing/using-your-domain.html">domains</a>, as well as direct access through a unique <a data-zui-docs="routing/unique-ipv4-ipv6-addresses.html">IPv4 or IPv6 address</a>, with the ability to setup <a data-zui-docs="routing/access-through-ip-and-firewall.html">firewall</a> rules. Zerops also generates a <a data-zui-docs="routing/zerops-subdomain.html">*.zerops.io subdomain</a> if you just need to test things out.`,
    featureList: [
      'Internal routing',
      'Public routing',
      'Firewall',
      'Zerops subdomain'
    ]
  },
  [Contexts.ServiceStackViewLogClick]: {
    header: 'Would you like to view the service runtime log?',
    content: `Each container your service is running on gets its own <a data-zui-docs="zerops-logs/runtime-logs.html">runtime log</a>. You can view each one inside the Runtime log tab of your service detail.
    Whenever an important event happens, such as an exit or a failure, you will get a notification with a button pointing to the particular container and to the relevant timestamp.`
  },
  [Contexts.ServiceStackConfigurationClick]: {
    header: 'Would you like to configure the service?',
    content: `Configuration page gives you ability to change nginx configuration and storage configuration in terms of the capacity and connected <a data-zui-docs="overview/projects-and-services-structure.html#service">services</a>.`
  },
  [Contexts.ServiceStackImportClick]: {
    header: 'Would you like to import the service?',
    content: `Import the service to the project by inserting service in .yaml format to dialog. It will add the service with identical configuration.`
  },
  [Contexts.ServiceStackExportClick]: {
    header: 'Would you like to export the service?',
    content: `Export the service in .yaml format then use it in zerops.yaml file on in the import dialog.`
  },
  [Contexts.ServiceStackFileBrowserClick]: {
    header: 'Would you like to go to file browser page?',
    content: `File browser can give you a better overview of what is actually on each container, view folders, files...`
  }
};

export type ContextualDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ContextualDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
