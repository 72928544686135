<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  horizontalAlign="center"
  verticalAlign="below"
  [hasArrow]="true"
  [hasBackdrop]="hasBackdrop">
  <mat-card class="__pop  z-manual-elevation  c-info-card">
    <zui-public-routing-content
      [serviceStackId]="state.serviceStackId"
      [serviceStackStatus]="serviceStackStatuses.Active"
      [ports]="state.ports"
      [httpRoutings]="state.httpRoutings"
      [portRoutings]="state.portRoutings"
      [publicIpV4]="state.publicIpV4"
      [publicIpV6]="state.publicIpV6"
      [copyButtonTooltip]="state.translations.copyPathToClipboard"
      [showAllRecords]="showAllRecords"
      [showMoreButtonMode]="'context'"
      (openRoutingSettingsClicked)="onOpenContextualDialog$.next(contexts.ServiceStackRoutingClick)">
    </zui-public-routing-content>
  </mat-card>
</sat-popover>
