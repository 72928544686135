import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import {
  billingInfo,
  billingInfoSuccess,
  ClientBaseActionUnion, updateBillingInfoSuccess
} from './client-base.action';
import { ClientBaseState } from './client-base.model';

const initialState = new ClientBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    billingInfo,
    (s): ClientBaseState => ({
      ...s,
      billingInfo: undefined
    })
  ),
  on(
    billingInfoSuccess,
    (s, { data }): ClientBaseState => ({
      ...s,
      billingInfo: data
    })
  ),
  on(
    updateBillingInfoSuccess,
    (s, { data }): ClientBaseState => ({
      ...s,
      billingInfo: {
        current: data?.current,
        future: data?.future
      }
    })
  ),
  on(zefResetState, () => initialState)
);

export function clientBaseReducer(
  state: ClientBaseState,
  action: ClientBaseActionUnion
) {
  return actionReducer(state, action);
}
