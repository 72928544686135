import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ObjectStoragesUsageEffect } from './object-storages-usage.effect';
import { FEATURE_NAME } from './object-storages-usage.constant';
import { objectStoragesUsageReducer } from './object-storages-usage.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ ObjectStoragesUsageEffect ]),
    StoreModule.forFeature(
      FEATURE_NAME,
      objectStoragesUsageReducer
    )
  ]
})
export class ObjectStoragesUsageModule { }
