import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Country } from '@zerops/models/settings';
import { HashMap } from '@zerops/zef/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { BillingInfoFormState } from './billing-info-form.form';

@Component({
  selector: 'z-billing-info-form',
  templateUrl: './billing-info-form.component.html',
  styleUrls: [ './billing-info-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingInfoFormComponent extends ZefFormBase<BillingInfoFormState> {

  @Input()
  autoscroll: boolean;

  @Input()
  set countryList(v) {
    this._countryList = v;
    this._countryEuMap = this._countryList.reduce((obj, itm) => {
      obj[itm.id] = itm.inEu;

      return obj;
    }, {});
  }
  get countryList() {
    return this._countryList;
  }

  additionalBillingInfoCtrl = new UntypedFormControl(false);
  _countryEuMap: HashMap<boolean>;
  private _countryList: Country[];

}
