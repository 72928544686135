import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { PortRouting } from '@zerops/models/port-routing';
import { Sort } from '@zerops/zef/sort';

@Injectable({ providedIn: 'root' })
export class PortRoutingEntity extends EntityService<PortRouting> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'desc'
  };

  list$(
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: PortRouting) => any)> = [ this.defaultSort.key ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ this.defaultSort.direction ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.PortRouting, store, collectionManager, websocketService);
  }
}
