import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopStateDirective } from './pop-state.directive';

@NgModule({
  declarations: [ PopStateDirective ],
  imports: [ CommonModule ],
  exports: [ PopStateDirective ]
})
export class PopStateModule { }
