<!-- http actions -->
<z-http-routing-actions-pop
  #httpRoutingActionsPopRef
  [id]="activeMoreId"
  [activeElRef]="activeHttpRoutingItemElRef"
  (popClosed)="activeMoreId = undefined"
  (editClicked)="editOpenClick.emit($event)">
</z-http-routing-actions-pop>

<ng-template #itemRef let-item="item">
  <mat-card
    *ngIf="item"
    class="__card"
    [class.is-inactive]="!!activeMoreId && activeMoreId !== item.id"
    [class.is-unsynced]="!item.isSynced && !!item.lastSync"
    [class.is-unsynced-new]="!item.isSynced && !item.lastSync"
    [class.is-unsynced-deletion]="item.deleteOnSync">

    <zui-more-button
      class="__more"
      [class.is-loading]="state.loadingsMap[item.id]"
      [popRef]="httpRoutingActionsPopRef.popoverRef"
      [isLoading]="state.loadingsMap[item.id]"
      (moreClick)="activeHttpRoutingItemElRef = httpRoutingItemRef.elementRef; activeMoreId = item.id;">
    </zui-more-button>

    <zui-http-routing-item
      #httpRoutingItemRef
      [data]="item"
      [otherLocations]="item._externalLocations"
      [stackLocations]="item._internalLocations"
      [translations]="state.httpRoutingItemTranslations">
    </zui-http-routing-item>

  </mat-card>
</ng-template>

<ng-container *ngFor="let item of state.categorizedItems.local; trackBy: trackByIndex;">
  <ng-template
    [ngTemplateOutlet]="itemRef"
    [ngTemplateOutletContext]="{ item: item }">
  </ng-template>
</ng-container>

<div *ngIf="externalVisible && state.categorizedItems.external?.length">

  <h3 class="__http-routing-category_title">Other Domain Accesses on the Project</h3>

  <div class="__other">
    <ng-container *ngFor="let item of state.categorizedItems.external; trackBy: trackByIndex;">
      <ng-template
        [ngTemplateOutlet]="itemRef"
        [ngTemplateOutletContext]="{ item: item }">
      </ng-template>
    </ng-container>
  </div>

</div>

<div *ngIf="state.categorizedItems.external?.length" class="u-text--center">
  <button
    type="button"
    mat-button
    (click)="externalVisible = !externalVisible"
    [class.has-unsynced]="state.categorizedItems.externalHasUnsynced"
    class="c-button  c-button--smaller  __external-trigger-button">
    <mat-icon>{{ externalVisible ? 'expand_less' : 'expand_more' }}</mat-icon> {{ externalVisible ? 'Hide' : ('Show ' + state.categorizedItems.external?.length) }} {{ state.categorizedItems.external?.length === 1 ? 'domains' : 'domains' }} not pointing to this service <mat-icon *ngIf="state.categorizedItems.externalHasUnsynced" class="__external-trigger-button_external-icon">sync</mat-icon>
  </button>
</div>
