import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserDataBaseApi {
  constructor(private _http: HttpClient) {}

  sync$(id: string) {
    return this._http.put<any>(
      `/api/service-stack/${id}/sync-user-data`,
      {}
    );
  }

  updateSecretEnvs$(serviceStackId: string, envFile: string) {
    return this._http.put<any>(
      `/api/user-data/env-file`,
      { serviceStackId, envFile }
    );
  }

}
