<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (afterClose)="onAfterPopClose$.next()"
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="proccesesPopRef.close()"
  [verticalAlign]="'start'"
  [horizontalAlign]="'center'">
  <mat-card class="__card  z-manual-elevation  c-soft-elevation">
    <zui-processes-card
      [mode]="processItemModes.Full"
      [maxHeight]="state?.scrollHeight"
      [runningProcesses]="state.processes?.running || []"
      [pendingProcesses]="state.processes?.pending || []"
      [processItemTranslations]="state.processItemTranslations"
      (pipelineDetailClicked)="onOpenPipelineDetailDialog$.next($event)"
      (cancelClicked)="onProcessCancel$.next($event)"
      (buildCancelClicked)="onBuildCancel$.next($event)"
      (internalLinkClicked)="proccesesPopRef.close()">
    </zui-processes-card>
  </mat-card>
</sat-popover>
