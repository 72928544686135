<zef-dialog
  [open]="state.open"
  [options]="{ restoreFocus: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__wrap">
    <zef-scrollbar maxHeight="80vh">
      <div class="__content">

        <zui-form-section
          class="__form-section"
          separator="none"
          [isZen]="state.isZen">

          <span *zuiFormSectionTitle>
            <strong>{{ state.project?.name }}</strong>
            daily cost limit
          </span>

          <span *zuiFormSectionDesc>
            <span class="__desc">
              Setting a daily cost limit will make sure
              that your hardware resources cost will not
              exceed given amount. A good limit might
              be <strong>2-3 times your daily average (${{ state.recommendedLimit }})</strong>.
              <ng-container *ngIf="state.project?.status !== projectStatuses.Stopped">We marked this for you in the limit selector with<span class="__desc-recommended-sign"></span>.</ng-container>
            </span>
          </span>

        </zui-form-section>

        <zui-separator
          class="__separator"
          [orientation]="'vertical'"
          [spacing]="'small'"
          [size]="'small'">
        </zui-separator>

        <!-- info mode -->
        <ng-container *ngIf="state.mode === projectCostLimitDialogModes.Info && state.projectStatus?.projectInfoJsonObject?.maxCreditLimit">
          <z-project-cost-limit-info
            [creditLimit]="state.projectStatus?.projectInfoJsonObject?.maxCreditLimit"
            [remainingCredit]="state.projectStatus?.remainingCredit"
            [activeCurrency]="state.activeCurrency"
            [lifetimeScore]="state.projectStatus?.lifetimeScore"
            [totalCredit]="state.totalCredit"
            [removeLoading]="state.limitRemoveLoading"
            (changeLimitClicked)="onChangeDialogMode$.next(projectCostLimitDialogModes.Edit)"
            (removeLimitClicked)="onRemoveLimit$.next()">
          </z-project-cost-limit-info>
        </ng-container>

        <!-- edit mode -->
        <ng-container *ngIf="state.mode === projectCostLimitDialogModes.Edit">
          <z-project-cost-limit-edit
            [updateLoading]="state.limitUpdateLoading"
            [formState]="state.formState"
            [projectStatus]="state.project?.status"
            [creditLimit]="state.projectStatus?.projectInfoJsonObject?.maxCreditLimit"
            [recommendedLimit]="state.recommendedLimit"
            [remainingCredit]="state.projectStatus?.remainingCredit"
            (backClicked)="onChangeDialogMode$.next(projectCostLimitDialogModes.Info)"
            (updateLimitClicked)="onUpdateLimit$.next()"
            (setTwiceAverageClicked)="onSetTwiceAverageLimit$.next()">
          </z-project-cost-limit-edit>
        </ng-container>

        <!-- loading -->
        <ng-container *ngIf="state.mode === projectCostLimitDialogModes.Info && !state.projectStatus?.projectInfoJsonObject?.maxCreditLimit">
          <div class="__loading-wrap">
            <zui-progress-spinner [isLoading]="true"></zui-progress-spinner>
          </div>
        </ng-container>

        <div class="__close-button-wrap">
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span>{{ state.generalTranslations?.close }}</span>
          </button>
        </div>

      </div>
    </zef-scrollbar>
  </div>
</zef-dialog>