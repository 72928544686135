<zef-form
  class="__form"
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field>
    <mat-form-field class="__top-up-form-field" color="accent">

      <input
        matInput
        type="string"
        autocomplete="off"
        [placeholder]="'Enter a promo code, if you have one..'"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.promoCode"
      />

    </mat-form-field>
  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
