import { createAction, union } from '@ngrx/store';
import { DeletionWarningDialog } from './deletion-warning-dialog.model';

export const deletionWarningDialogOpen = createAction(
  '[@zerops/zerops/feature/deletion-warning-dialog] open',
  (data: DeletionWarningDialog) => ({ data })
);

const all = union({ deletionWarningDialogOpen });

export type DeletionWarningDialogActionUnion = typeof all;
