<div
  class="__grid"
  [class.has-one-item]="!billingInfo.future || showCurrentOnly">

  <div class="__grid-item">

    <span class="__item" *ngIf="billingInfo.current?.companyName">{{ billingInfo.current?.companyName | middleEllipsis : 60 }} <span *ngIf="billingInfo.current?.invoiceAddressCountryId">({{ billingInfo.current?.invoiceAddressCountryId }})</span></span>

    <span
      class="__item"
      *ngIf="billingInfo.current?.invoiceAddressCity
        || billingInfo.current?.invoiceAddressStreet
        || billingInfo.current?.invoiceAddressPostcode">

      {{ billingInfo.current?.invoiceAddressCity }}

      <span *ngIf="billingInfo.current?.invoiceAddressStreet || billingInfo.current?.invoiceAddressPostcode">-</span>

      {{ billingInfo.current?.invoiceAddressStreet }}

      <span *ngIf="billingInfo.current?.invoiceAddressPostcode">,</span>

      {{ billingInfo.current?.invoiceAddressPostcode }}

    </span>

    <span
      class="__item" *ngIf="billingInfo.current?.companyNumber || billingInfo.current?.vatNumber">
      @if (!!billingInfo.current?.companyNumber) {
        CIN: {{ billingInfo.current?.companyNumber }}
      }

      @if (!!billingInfo.current?.vatNumber) {
        VAT: {{ billingInfo.current?.vatNumber }}
      }

    </span>

  </div>

  <div
    *ngIf="billingInfo?.future && ! showCurrentOnly"
    class="__grid-item">

    <h3 class="__title is-planned">

      <mat-icon
        [matTooltip]="'Planned change of billing info will automatically take effect on the first day of the following month. for manual change please contact our customer support.'"
        class="__planned-icon">
        schedule
      </mat-icon>

      Planned ({{ nextMonthStartDate | date: 'mediumDate' }})

    </h3>

    <span class="__item" *ngIf="billingInfo.future?.companyName">{{ billingInfo.future?.companyName | middleEllipsis : 60 }}</span>

    <span class="__item" *ngIf="billingInfo.future?.invoiceAddressCountryId">{{ billingInfo.future?.invoiceAddressCountryId }}</span>

    <span
      class="__item"
      *ngIf="billingInfo.future?.invoiceAddressCity
        || billingInfo.future?.invoiceAddressStreet
        || billingInfo.future?.invoiceAddressPostcode">

      {{ billingInfo.future?.invoiceAddressCity }}

      <span *ngIf="billingInfo.future?.invoiceAddressStreet || billingInfo.future?.invoiceAddressPostcode">-</span>

      {{ billingInfo.future?.invoiceAddressStreet }}

      <span *ngIf="billingInfo.future?.invoiceAddressPostcode">,</span>

      {{ billingInfo.future?.invoiceAddressPostcode }}

    </span>

    <span class="__item" *ngIf="billingInfo.future?.companyNumber">{{ billingInfo.future?.companyNumber }}</span>

    <span class="__item" *ngIf="billingInfo.future?.vatNumber">{{ billingInfo.future?.vatNumber }}</span>

  </div>

</div>

