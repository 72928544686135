import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './dns-check-status-pop.constant';

const en = {
  dnsChestStatuses: {
    PENDING: 'Waiting for routing to',
    CHECKING: 'Checking info for routing to',
    OK: 'Correctly routed to',
    FAILED: 'Failed to route to',
    IGNORED: 'Ignored for routing to'
  }
};

export type DnsCheckStatusPopTranslations = typeof en;

@NgModule()
export class DnsCheckStatusPopTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
