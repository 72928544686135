import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefFormModule } from '@zerops/zef/forms/modules';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { PortRoutingFormComponent } from './port-routing-form.component';

@NgModule({
  declarations: [ PortRoutingFormComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    ZefProgressErrorModule,
    ZefFormModule,
    ZuiMaterialFormStackModule,
    ZefFormsModule,
    ZuiLogoModule,
    ZuiDocsTextModule
  ],
  exports: [ PortRoutingFormComponent ]
})
export class PortRoutingFormModule {

}
