import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationState } from './notification.model';
import { FEATURE_NAME } from './notification.constant';

export const selectNotificationState = createFeatureSelector<NotificationState>(FEATURE_NAME);

export const selectActiveNotifications = createSelector(
  selectNotificationState,
  (state) => state && state.list
);
