import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  onWebsocketMessageDispatchUpdateEntities,
  onWebsocketMessageDispatchAddRemoveEntities
} from '@zerops/zef/entities';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { SubscriptionEntity } from './subscription.entity';

@Injectable()
export class SubscriptionBaseEffect {

  private _setupListStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._subscriptionEntity.listSubscribe(
      clientId,
      undefined,
      {
        search: [
          {
            name: 'status',
            operator: 'eq',
            value: 'ACTIVE'
          }
        ]
      }
    ))
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._subscriptionEntity)
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._subscriptionEntity.updateSubscribe(clientId)
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._subscriptionEntity)
  ));

  constructor(
    private _actions$: Actions,
    private _subscriptionEntity: SubscriptionEntity,
    private _userEntity: UserEntity
  ) {}
}
