import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppVersion } from '@zerops/models/app-version';
import { BuildProcessStates } from '../../build-state-steps.model';
import { getPipelineState } from '../../build-state-steps.utils';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-deploy-step',
  templateUrl: './deploy-step.component.html',
  styleUrls: [ './deploy-step.component.scss' ],
 changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeployStepComponent {

  buildProcessStates = BuildProcessStates;

  @Input()
  set appVersion(v: AppVersion) {
    this._appVersion = v;
    this._state = getPipelineState(this._appVersion)?.DEPLOY;
  }
  get appVersion(): AppVersion {
    return this._appVersion;
  }

  @Input()
  runtimeServiceName: string;

  @Input()
  runtimeServiceType: string;

  @Input()
  hideText = false;

  @Input()
  pipelineErrors: PipelineError[];

  _state: BuildProcessStates;
  private _appVersion: AppVersion;
}
