<div
  [class.is-dark-theme]="state.isDarkTheme"
  class="__wrap">
  <zef-dialog
    [open]="state.open"
    [autoFocus]="false"
    (closeTrigger)="onClose$.next()">
    <div
      [class.is-dark-theme]="state.isDarkTheme"
      class="__content">

      <zef-scrollbar maxHeight="60vh">
        <zui-form-section separator="none">
          <zui-user-data-add-form
            #formRef
            class="__form"
            (submit)="onAdd$.next()"
            [state]="state.formState">
          </zui-user-data-add-form>
        </zui-form-section>
      </zef-scrollbar>

      <div class="__buttons">
        <div class="__buttons-grid">

          <div>
            <zef-progress-error full [key]="addKey">
              <button
                class="c-button--full"
                color="accent"
                mat-raised-button
                (click)="formRef.triggerSubmit()"
                type="button">
                Save new secret variable
              </button>
            </zef-progress-error>
          </div>

          <div>
            <button
              (click)="onClose$.next()"
              class="c-button--full"
              type="button"
              mat-button>
              <span transloco="general.close"></span>
            </button>
          </div>

        </div>
      </div>

    </div>
  </zef-dialog>
</div>
