import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zui-uploading-icon',
  templateUrl: './uploading-icon.component.html',
  styleUrls: [ './uploading-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadingIconComponent {

}
