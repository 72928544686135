import {
  Component,
  ChangeDetectionStrategy,
  AfterContentInit,
  ElementRef,
  OnDestroy, Input
} from '@angular/core';
import { DocsPopService } from '../docs-pop/docs-pop.service';

const DATA_ATTR = 'data-zui-docs';

@Component({
  selector: 'zui-docs-text',
  templateUrl: './docs-text.component.html',
  styleUrls: [ './docs-text.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZuiDocsTextComponent implements AfterContentInit, OnDestroy {

  @Input()
  initDelay = 0;

  private _els: NodeList;

  constructor(
    private _elementRef: ElementRef,
    private _docsPopService: DocsPopService
  ) {
    this._attachDomListeners();
  }

  ngAfterContentInit() {
    this._attachDomListeners();
    setTimeout(() => this._attachDomListeners(), this.initDelay);
  }

  ngOnDestroy() {
    this._removeDomListeners();
  }

  refreshListeners() {
    this._removeDomListeners();
    this._attachDomListeners();
  }

  private _onClick(e: Event, el: HTMLElement) {

    e.preventDefault();

    const entity = el.getAttribute(DATA_ATTR);

    this._docsPopService.setEntity(entity);

    const ref = this._docsPopService.getRef();

    ref.open();
  }

  private _attachDomListeners() {
    if (!this._els?.length) {
      this._els = this._elementRef.nativeElement.querySelectorAll('[data-zui-docs]');

      this._els?.forEach((node: any) => {

        const textContent = node.textContent.split(' ');

        const lastItem = `<span>${textContent.pop()}</span>`;

        node.innerHTML = `${textContent.join(' ')} ${lastItem}`;

        node.addEventListener('click', (e: Event) => this._onClick(e, node));

      });
    }
  }

  private _removeDomListeners() {
    this._els?.forEach((node: any) => node.removeEventListener('click', (e) => this._onClick(e, node)));
  }

}
