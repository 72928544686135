import { Component, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';

@Component({
  templateUrl: './separator.component.html',
  styleUrls: [ './separator.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZeSeparatorComponent {

  innerWidth: number;

  @Input()
  orientation: 'horizontal' | 'vertical' = 'vertical';

  @Input()
  size: 'mini' | 'small' | 'medium' | 'large' = 'medium';

  @Input()
  spacing: 'none' | 'mini' | 'small' | 'medium' | 'large' | 'huge' | 'disabled' = 'medium';

  @Input()
  position: 'center' | 'start' | 'end' = 'center';

  @HostListener('window:resize', [ '$event' ])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor() {
    this.innerWidth = window.innerWidth;
  }

}
