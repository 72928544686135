import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  AppVersion,
  AppVersionParsedPublicGitSource,
  AppVersionStatuses,
  getAppVersionFailedStatus,
  parsePublicGitUrl
} from '@zerops/models/app-version';

@Component({
  selector: 'zui-app-version-release-info',
  templateUrl: './app-version-release-info.component.html',
  styleUrls: ['./app-version-release-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionReleaseInfoComponent {

  parsedPublicGitSource: AppVersionParsedPublicGitSource;
  isFailed = false;
  appVersionStatuses = AppVersionStatuses;

  @Input()
  set appVersion(v) {
    if (v) {
      this._appVersion = v;

      this.isFailed = getAppVersionFailedStatus(v.status);

      if (v.publicGitSource) {
        this.parsedPublicGitSource = parsePublicGitUrl(v.publicGitSource.gitUrl);
      }
    }
  }
  get appVersion() {
    return this._appVersion;
  }

  private _appVersion: AppVersion;

}
