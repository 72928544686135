import { Component, Input } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefError } from '@zerops/zef/errors';

@Component({
  selector: 'z-pop-error',
  templateUrl: './pop-error.container.html',
  styleUrls: [ './pop-error.container.scss' ]
})
export class PopErrorContainer {
  @Input()
  data: ZefError[];

  @Input()
  instance: SatPopover;

  trackBy(index: number) {
    return index;
  }
}
