import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { TopUpBillingInfoFormComponent } from './billing-info-form.component';
import { FormSectionModule } from '@zerops/zui/form-section';

@NgModule({
  declarations: [ TopUpBillingInfoFormComponent ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule
  ],
  exports: [ TopUpBillingInfoFormComponent ]
})
export class TopUpBillingInfoFormModule {

}
