import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { TopUpAmountFormState } from './top-up-amount-form.form';

@Component({
  selector: 'z-top-up-amount-form',
  templateUrl: './top-up-amount-form.component.html',
  styleUrls: [ './top-up-amount-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUpAmountFormComponent extends ZefFormBase<TopUpAmountFormState> {

}
