<button
  class="__button c-button--smaller"
  [class.is-red]="!!subscription && subscription.recurringEnabled"
  [class.is-green]="(!!subscription && !subscription.recurringEnabled) || !!addon"
  [matTooltipDisabled]="(!!subscription && !subscription.recurringEnabled) || !!addon"
  [matTooltip]="'Renews on ' + (subscription?.validTill | date: 'shortDate')"
  type="button"
  (click)="buttonClick.emit({
    addon,
    subscription
  })"
  mat-stroked-button>

  <ng-container *ngIf="!!addon">
    Activate addon (${{ addon.unitPrice }})
  </ng-container>

  <ng-container *ngIf="!!subscription && subscription.recurringEnabled">
    Deactivate autorenew
  </ng-container>

  <ng-container *ngIf="!!subscription && !subscription.recurringEnabled">
    Activate autorenew ($0)
  </ng-container>

  <span
    *ngIf="!!addon"
    class="__valid-date">
    from {{ addon.validFrom | date: 'shortDate' }} to {{ addon.validTill | date: 'shortDate' }}
  </span>

  <span
    *ngIf="!!subscription"
    class="__valid-date">
    Active from {{ subscription.validFrom | date: 'shortDate' }} to {{ subscription.validTill | date: 'shortDate' }}
  </span>

</button>
