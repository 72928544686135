<div class="__item">

  <div
    class="__content  c-process-notification-shell-content"
    [class.is-green]="color === 'green'"
    [class.is-red]="color === 'red'"
    [class.is-blue]="color === 'blue'"
    [class.is-orange]="color === 'orange'"
    [class.is-grey]="color === 'grey'"
    [class.is-base]="color === 'base'">
    <ng-content></ng-content>
  </div>

  <div class="__bottom  c-process-notification-shell-bottom">

    <div class="__half-grid">

      <div class="__half-grid_item">
        <div class="__label">

          <span class="__label_name">
            {{ prefixText }}
          </span>

          <span class="__label_text">

            <zef-avatar
              *ngIf="!isSystem"
              class="__avatar"
              [size]="18"
              [name]="fullName"
              [email]="email"
              [image]="image"
              [externalImage]="externalImage"
              [borderWidth]="1"
              [backgroundColor]="'#ccc'">
            </zef-avatar>

            <ng-container *ngIf="!isSystem">
              {{ createdByTypePrefix }}<strong class="__name">{{ firstName }}</strong>&nbsp;<span class="__at">at</span>&nbsp;<strong class="__date">{{ created | date: 'short' }}</strong>
            </ng-container>

            <ng-container *ngIf="isSystem">
              <strong class="__name  is-system">ZEROPS</strong>&nbsp;<span class="__at">at</span>&nbsp;<strong class="__date">{{ created | date: 'short' }}</strong>
            </ng-container>

          </span>

        </div>
      </div>

    </div>
  </div>

</div>
