<!-- more pop -->
<zui-user-data-more-pop
  #morePopRef
  [activeElRef]="activeElRef"
  [editAnchor]="editPopRef.popRef"
  [editKey]="editUserDataKey"
  (delete)="_deleteUserData()"
  (edit)="_setForm()">
</zui-user-data-more-pop>

<!-- add pop -->
<z-user-data-add-pop
  #addPopRef
  [popState]="addUserDataKey"
  [formGroup]="_form"
  [key]="addUserDataKey"
  [saveVariableTranslation]="popButtonsText"
  [disableAddButton]="duplicateKeyWarning"
  (add)="_addUserData($event); addPopRef.popRef.close()"
  (closeTrigger)="_resetForm()">
</z-user-data-add-pop>

<!-- edit pop -->
<z-user-data-edit-pop
  #editPopRef
  [popState]="editUserDataKey"
  [formGroup]="_form"
  [key]="editUserDataKey"
  [saveVariableTranslation]="popButtonsText"
  (edit)="_updateUserData($event); editPopRef.popRef.close()"
  (closeTrigger)="_resetForm()">
</z-user-data-edit-pop>

<div class="__buttons-wrap">

  <button
    mat-flat-button
    [zefNgrxDialogOpen]="addUserDataKey"
    [satPopoverAnchor]="addPopRef.popRef"
    class="[ c-button  c-button--smaller ]"
    [class.has-no-margin-bottom]="!value?.length"
    type="button">
    <mat-icon class="__button-icon">add</mat-icon> <span>{{ buttonText }}</span>
  </button>

  <button
    mat-flat-button
    (click)="toggleEnvFormatDialogOpenState()"
    class="__env-button [ c-button  c-button--smaller ]"
    [class.has-no-margin-bottom]="!value?.length"
    type="button">
    <mat-icon class="__button-icon is-env">how_to_vote</mat-icon> <span>Paste .env format</span>
  </button>

</div>

<div class="__wrap" *ngIf="value?.length">
  <div *ngFor="let item of value; let index = index; trackBy: trackBy; let last = last;">
    <z-user-data-item
      #userDataItemRef
      class="__item"
      [class.is-last]="last"
      [moreAnchor]="morePopRef.popRef"
      (moreClicked)="activeElRef = userDataItemRef.elementRef; activeUserDataIndex = index"
      [data]="item"
      [translations]="{ copyKeyTooltip: copyKeyTooltipText }">
    </z-user-data-item>
  </div>
</div>

<!-- user data env format dialog -->
<z-user-data-env-format-dialog
  [open]="envFormatDialogOpen"
  (add)="_appendEnvFormatItems($event)"
  (dialogClose)="toggleEnvFormatDialogOpenState()">
</z-user-data-env-format-dialog>
