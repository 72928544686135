<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  horizontalAlign="center"
  verticalAlign="start"
  hasBackdrop>
  <mat-card class="__pop  z-manual-elevation  c-soft-elevation">

    <span class="__desc">{{ title }}</span>

    <z-client-user-select
      [data]="clientUsers"
      (selected)="onSelectClientUser$.next($event); popRef.close();">
    </z-client-user-select>

  </mat-card>
</sat-popover>
