import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sync, syncSuccess, syncFail, updateSecretEnvs, updateSecretEnvsSuccess, updateSecretEnvsFail } from './user-data-base.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { UserDataBaseApi } from './user-data-base.api';
import { of } from 'rxjs';
// explicit import, circular dep
import { UserEntity } from '../user-base/user-base.entity';
import { UserDataEntity } from './user-data-base.entity';
import { onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef/entities';

@Injectable()
export class UserDataBaseEffect {

  private _onSync$ = createEffect(() => this._actions$.pipe(
    ofType(sync),
    switchMap((action) => this._api
      .sync$(action.data)
      .pipe(
        map((res) => syncSuccess(res, action)),
        catchError((res) => of(syncFail(res, action)))
      )
    )
  ));

  private _onUpdateSecretEnvs$ = createEffect(() => this._actions$.pipe(
    ofType(updateSecretEnvs),
    switchMap((action) => this._api
      .updateSecretEnvs$(action.data.id, action.data.content)
      .pipe(
        map((res) => updateSecretEnvsSuccess(res, action)),
        catchError((res) => of(updateSecretEnvsFail(res, action)))
      )
    )
  ));

  private _setupUserDataUpdateSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._userDataEntity.updateSubscribe(clientId),
  )));

  private _setupUpdateCacheDispatch$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._userDataEntity)
  ));

  constructor(
    private _api: UserDataBaseApi,
    private _userEntity: UserEntity,
    private _userDataEntity: UserDataEntity,
    private _actions$: Actions
  ) { }
}
