import { PeriodCost, PeriodCostRaw, PeriodCosts, TransactionDebitGroupItem } from './transaction-debit.model';
import orderBy from 'lodash-es/orderBy';

export function getTransactionGroupRangeKey(
  key: string
) {
  return key;
}

export function transformMetricGroup(
  items: TransactionDebitGroupItem[],
  groupKey: string
) {

  const tr = items.reduce((obj, itm) => {

    if (obj[itm.stackId] === undefined) {
      obj[itm.stackId] = {
        [groupKey]: []
      };
    }

    const from = new Date(itm.from);

    obj[itm.stackId][groupKey].push({
      x: from,
      cpu: itm.cpu,
      ram: itm.ram,
      disc: itm.disc,
      total: itm.sumTotalPrice,
      serviceName: itm.stackName,
      range: {
        from: from,
        to: new Date(itm.till)
      },
      nameMap: {
        cpu: 'CPU',
        ram: 'RAM',
        disc: 'DISK'
      },
      // TODO: ceph pricing
      // ceph_active: itm.ceph_active,
      // ceph_inactive: itm.ceph_inactive,
      /*
        IMPORTANT: Because of the different used labels for hardware resources in GUI, it's necessary
        to remember to update also that definition in the <zui-service-stack-spend-graph-pop-content>
        dumb component, if anything is changed with the keys on the server-side.
      */
      _keys: [ 'cpu', 'ram', 'disc'/*, 'ceph_active', 'ceph_inactive' */ ]
    });

    return obj;

  }, {});

  return tr;
}

export function transformServiceGroup(
  items: TransactionDebitGroupItem[],
  groupKey: string
) {

  let num = 0;
  let lastServiceId = undefined;
  let lastProjectId = undefined;
  let changedService = 0;
  let totalService = 0;
  let changedProject = 0;

  const tr = orderBy(
    items,
    [ 'projectId', 'stackId', 'from' ],
    [ 'asc', 'asc', 'asc' ]
  ).reduce((obj, itm, index) => {

    if (obj[itm.projectId] === undefined) {
      obj[itm.projectId] = {
        [groupKey]: []
      };
    }

    if (lastServiceId !== undefined && lastServiceId !== itm.stackId) {
      changedService = changedService + 1;
      totalService = totalService + 1;
    }

    if (lastProjectId !== undefined && lastProjectId !== itm.projectId) {
      changedService = 0;
      lastServiceId = undefined;
      changedProject = changedProject + 1;
    }

    if (changedService === 0) {

      const from = new Date(itm.from);

      const d = {
        x: from,
        total: itm.sumTotalPrice,
        range: {
          from: from,
          to: new Date(itm.till)
        },
        nameMap: {},
        _keys: []
      };

      if (itm.projectId && itm.stackId) {
        if (itm.stackId) {
          d[itm.stackId] = itm.sumTotalPrice;
          d.nameMap[itm.stackId] = itm.stackName;
          d._keys.push(itm.stackId);
        }
      }

      obj[itm.projectId][groupKey].push(d);

      if (changedProject === 0) {
        num = num + 1;
      }

    }

    if (changedService > 0) {

      const realIndex = index - (num * (totalService));

      if (obj[itm.projectId][groupKey] && obj[itm.projectId][groupKey][realIndex]) {
        obj[itm.projectId][groupKey][realIndex][itm.stackId] = itm.sumTotalPrice;
        obj[itm.projectId][groupKey][realIndex].total = obj[itm.projectId][groupKey][realIndex].total + itm.sumTotalPrice;
        obj[itm.projectId][groupKey][realIndex]._keys.push(itm.stackId);
        obj[itm.projectId][groupKey][realIndex].nameMap[itm.stackId] = itm.stackName;
      }

    }

    lastProjectId = itm.projectId;
    lastServiceId = itm.stackId;

    return obj;

  }, {});

  return tr;
}

export function transformProjectGroup(items: TransactionDebitGroupItem[]) {

  let lastProjectId = undefined;
  let changedProject = 0;
  let num = 0;

  const tr = orderBy(
    items,
    [ 'projectId', 'from' ],
    [ 'asc', 'asc' ]
  ).reduce((obj, itm, index) => {

    const from = new Date(itm.from);

    if (lastProjectId !== undefined && lastProjectId !== itm.projectId) {
      changedProject = changedProject + 1;
    }

    if (changedProject === 0) {

      const d = {
        x: from,
        total: itm.sumTotalPrice,
        range: {
          from: from,
          to: new Date(itm.till)
        },
        nameMap: {},
        _keys: []
      };

      if (itm.projectId) {
        d[itm.projectId] = itm.sumTotalPrice;
        d.nameMap[itm.projectId] = itm.projectName;
        d._keys.push(itm.projectId);
      }

      obj.data.push(d);

      num = num + 1;

    }

    if (changedProject > 0) {

      const realIndex = index - (num * (changedProject));

      if (obj.data[realIndex]) {
        obj.data[realIndex][itm.projectId] = itm.sumTotalPrice;
        obj.data[realIndex].total = obj.data[realIndex].total + itm.sumTotalPrice;
        obj.data[realIndex]._keys.push(itm.projectId);
        obj.data[realIndex].nameMap[itm.projectId] = itm.projectName;
      }

    }

    lastProjectId = itm.projectId;

    return obj;

  }, {
    data: []
  });

  return tr;
}

export function mergePeriodCost(
  client: PeriodCostRaw,
  project: PeriodCostRaw[],
  stack: PeriodCostRaw[]
): PeriodCosts {
  return {
    client: client?.periodCost,
    projects: getPeriodCostmap(project),
    services: getPeriodCostmap(stack)
  };
}

function getPeriodCostmap(data: Array<{
  id: string;
  periodCost: PeriodCost;
}>) {
  return data.reduce((obj, itm) => {
    obj[itm.id] = itm.periodCost;
    return obj;
  }, {});
}
