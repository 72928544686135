<div
  class="__wrap mat-elevation-z24"
  [class.is-active]="state?.isActive && !forceHide">
  <div class="__grid">

    <div>
      <h2 class="__title">
        <mat-icon class="c-color--warn __icon">warning</mat-icon> New version of Zerops GUI ({{ state?.version }}) was released, please reload your browser as soon as possible.
      </h2>
    </div>

    <div>
      <button
        type="button"
        mat-button
        (click)="forceHide = true">
        Ok, close.
      </button>
    </div>

  </div>
</div>
