import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  signal,
  ChangeDetectorRef
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { zefLogout } from '@zerops/zef/auth';
import { storeUserData, UserEntity } from '@zerops/zerops/core/user-base';
import { ClientUser } from '@zerops/models/client-user';
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base';
import { NotificationsPopService, notificationsPopSetSettings } from '@zerops/zerops/feature/notifications-pop';
import { ProcessesPopService, processesPopSetSettings } from '@zerops/zerops/feature/processes-pop';
import { AppVersionStatuses } from '@zerops/models/app-version';
import {
  mapTo,
  delayWhen,
  distinctUntilChanged,
  pairwise,
  map,
  delay
} from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { NotificationEntity } from '@zerops/zerops/core/notification-base';
import { SatPopover } from '@zerops/zef/popover';
import { ProcessEntity } from '@zerops/zerops/core/process-base';
import { ProcessStatuses } from '@zerops/models/process';
import { UserStatuses } from '@zerops/models/user';
import { AppBarTranslations } from './app-bar.translations';
import { FEATURE_NAME } from './app-bar.constant';
import { ColorScheme, ThemeService } from '@zerops/zef/theme';
import { ImportExportDialogModes, importExportDialogOpen } from '../import-export-dialog';
import { Intercom } from '@zerops/zef/intercom';

@Component({
  selector: 'z-app-bar',
  templateUrl: './app-bar.container.html',
  styleUrls: [ './app-bar.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBarContainer extends ZefReactiveComponent {

  // # Event Streams
  onLogout$ = new Subject<void>();
  onOpenNotifications$ = new Subject<void>();
  onOpenProcesses$ = new Subject<void>();
  onSelectClientUser$ = new Subject<ClientUser>();
  onImportProject$ = new Subject<void>();

  // # Data
  // -- sync
  notificationPopRef: SatPopover;
  processesPopRef: SatPopover;
  userStatuses = UserStatuses;

  // -- async
  intercomState = signal(false);
  appBarTranslations$ = this.translate$<AppBarTranslations>(FEATURE_NAME);
  clientUser$ = this._userEntity
    .activeClientUser$
    .pipe(
      distinctUntilChanged(),
      pairwise(),
      delayWhen(([ pre, post ]) => pre && !post ? timer(400) : timer(0)),
      map(([ _, post ]) => post),
    );
  isContabo$ = this._userEntity.isContabo$;
  activeUser$ = this._userEntity.activeUser$;
  activeUserClientUsers$ = this._userEntity
    .activeUserClientUsers$
    .pipe(
      distinctUntilChanged(),
      pairwise(),
      delayWhen(([ pre, post ]) => pre && !post ? timer(400) : timer(0)),
      map(([ _, post ]) => post)
    );
  appVersionUploading$ = this._appVersionEntity
    .list$()
    .pipe(
      map((d) => d?.filter((appVersion) => appVersion.status === AppVersionStatuses.Uploading).length)
    );
  unreadNotificationsCount$ = this._notificationEntity
    .unreadNotificationMap$()
    .pipe(
      map((d) => d.client),
      distinctUntilChanged()
    );
  processesCount$ = this._processEntity
    .list$(undefined, [ 'created' ], [ 'asc' ])
    .pipe(
      map((items) => !items ? [] : items),
      map((items) => items.reduce((res, itm) => {

        if (itm.status === ProcessStatuses.RUNNING) {
          res.running = res.running + 1;
        }

        if (itm.status === ProcessStatuses.PENDING) {
          res.queued = res.queued + 1;
        }

        return res;

      }, {
        running: 0,
        queued: 0
      }))
    );

  // -- angular
  @Input()
  active: boolean;

  @Input()
  menuState: boolean;

  @Input()
  menuAnchor: any;

  @Input()
  logoTheme: 'white' | 'black' = 'black';

  @Output()
  menuClicked = new EventEmitter<void>();

  @ViewChild('menuPopRef')
  menuPopRef: SatPopover;

  // # State resolver
  state = this.$connect({
    appBarTranslations: this.appBarTranslations$,
    clientUser: this.clientUser$,
    activeUserClientUsers: this.activeUserClientUsers$,
    appVersionUploading: this.appVersionUploading$,
    unreadNotificationsCount: this.unreadNotificationsCount$,
    processesCount: this.processesCount$,
    isContabo: this.isContabo$,
    activeUser: this.activeUser$,
    isLightMode: this._theme.isDarkMode$.pipe(map((d) => !d)),
    activeTheme: this._theme.activeMode$,
    isDarkTheme: this._theme.isDarkMode$,
    isZen: this._theme.isZen$,
    isCompact: this._theme.isCompact$
  });

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(mapTo(zefLogout()));
  private _selectClientUserAction$ = this.onSelectClientUser$.pipe(
    map(({ userId, id }) => storeUserData(userId, id, true)),
    delay(400)
  );
  private _openNotificationsAction$ = this.onOpenNotifications$.pipe(
    map(() => notificationsPopSetSettings({ level: 'client' }))
  );
  private _openProcessesAction$ = this.onOpenProcesses$.pipe(
    map(() => processesPopSetSettings({ level: 'client' }))
  );
  private _importExportDialogOpenAction$ = this.onImportProject$.pipe(
    map(() => importExportDialogOpen({ mode: ImportExportDialogModes.Import }))
  );

  constructor(
    private _userEntity: UserEntity,
    private _notificationEntity: NotificationEntity,
    private _processEntity: ProcessEntity,
    private _appVersionEntity: AppVersionEntity,
    private _notificationPopService: NotificationsPopService,
    private _processesPopService: ProcessesPopService,
    private _theme: ThemeService,
    private _intercom: Intercom,
    private _cdRef: ChangeDetectorRef
  ) {
    super();

    this.processesPopRef = this._processesPopService.getRef();
    this.notificationPopRef = this._notificationPopService.getRef();

    this._intercom.onShow(() => {
      this.intercomState.set(true);
      this._cdRef.detectChanges();
    });
    this._intercom.onHide(() => {
      this._intercom.update({ hide_default_launcher: true });
      this.intercomState.set(false);
      this._cdRef.detectChanges();
    });

    // # Dispatcher
    this.$dispatchActions([
      this._logoutAction$,
      this._selectClientUserAction$,
      this._openNotificationsAction$,
      this._openProcessesAction$,
      this._importExportDialogOpenAction$
    ]);

  }

  setTheme(theme: ColorScheme) {
    this._theme.saveTheme(theme);
  }

  toggleZen() {
    this._theme.toggleZenMode();
  }

  toggleCompact() {
    this._theme.toggleCompactMode();

    setTimeout(() => {
      this.menuPopRef.realign();
    });
  }

  showIntercom() {
    this._intercom.update({ hide_default_launcher: false });
    this._intercom.show();
  }

  hideIntercom() {
    this._intercom.hide();
  }

}
