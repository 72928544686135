import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState } from 'ngrx-forms';
import { FEATURE_NAME } from './user-data-add-form.constant';

export interface UserDataAddFormValue {
  key: string;
  content: string;
}

export type UserDataAddFormState = FormState<UserDataAddFormValue>;

@Injectable({ providedIn: 'root' })
export class UserDataAddForm extends ZefForm<UserDataAddFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        key: '',
        content: ''
      },
      {}
    );
  }
}
