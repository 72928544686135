<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <h3 class="__title">{{ state.translations.title }}</h3>

    <p
      class="__desc"
      [innerHTML]="state.translations.desc">
    </p>

    <zef-form-field appearance="slide-toggle">
      <div class="__toggle">
        <mat-slide-toggle
          [formControl]="shutdownControl">
          {{ state.translations.title }}
        </mat-slide-toggle>
      </div>
    </zef-form-field>

    <div class="__buttons-grid">

      <div>

        <button
          class="c-button--full"
          color="accent"
          type="button"
          (click)="onDeploy$.next()"
          mat-raised-button>
          {{ state.translations.deploy }}
        </button>

        <p class="__button-desc">{{ state.translations.deployDesc }}</p>

      </div>

      <div>

        <button
          class="c-button--full"
          type="button"
          (click)="onClose$.next()"
          mat-button>
          <span>{{ state.translations.close }}</span>
        </button>

        <p class="__button-desc">{{ state.translations.closeDesc }}</p>

      </div>

    </div>

  </div>
</zef-dialog>
