import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NotificationBaseEffect } from './notification-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ NotificationBaseEffect ])
  ]
})
export class NotificationBaseModule {

}
