import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { StatusIconBaseComponent } from './status-icon-base.component';

@NgModule({
  declarations: [ StatusIconBaseComponent ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [ StatusIconBaseComponent ]
})
export class StatusIconBaseModule { }
