import {
  Component,
  ChangeDetectionStrategy,
  AfterContentInit,
  ElementRef,
  OnDestroy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { Router } from '@angular/router';

const DATA_ATTR = 'data-zui-router-link';

@Component({
  selector: 'zui-internal-link-text',
  templateUrl: './internal-link-text.component.html',
  styleUrls: [ './internal-link-text.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZuiInternalLinkTextComponent implements AfterContentInit, OnDestroy {

  private _els: NodeList;

  @Input()
  disableLink = false;

  @Output()
  linkClicked = new EventEmitter<void>();

  constructor(
    private _elementRef: ElementRef,
    private _router: Router
  ) { }

  ngAfterContentInit() {
    setTimeout(() => this._attachDomListeners());
  }

  ngOnDestroy() {
    this._removeDomListeners();
  }

  private _onClick(path: string) {
    if (path) {
      this.linkClicked.emit();
      if (!this.disableLink) {
        this._router.navigate([ path ]);
      }
    }
  }

  refreshListeners() {
    this._removeDomListeners();
    this._attachDomListeners();
  }

  private _attachDomListeners() {
    this._els = this._elementRef.nativeElement.querySelectorAll(`[${DATA_ATTR}]`);
    this._els?.forEach((node) => node.addEventListener(
      'click',
      () => this._onClick((node as HTMLElement).getAttribute(DATA_ATTR))
    ));
  }

  private _removeDomListeners() {
    this._els?.forEach((node) => node.removeEventListener(
      'click',
      () => (node as HTMLElement).getAttribute(DATA_ATTR)
    ));
  }

}
