import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ServiceStackTypeCategories } from '@zerops/models/service-stack';
import { ProcessStatuses, Process } from '@zerops/models/process';
import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { ProcessItemTranslations } from './process-item.translations';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-process-item',
  templateUrl: './process-item.component.html',
  styleUrls: [ './process-item.component.scss' ]
})
export class ProcessItemComponent {

  processStatuses = ProcessStatuses;
  appVersionStatuses = AppVersionStatuses;

  statusColorClassMap = {
    [ProcessStatuses.PENDING]: 'orange',
    [ProcessStatuses.RUNNING]: 'blue'
  };
  private _data: Process;

  @Input()
  set data(v) {
    this._data = {
      ...v,
      serviceStacks: v.serviceStacks.filter(((d) => d.serviceStackTypeInfo.serviceStackTypeCategory !== ServiceStackTypeCategories.Build))
    };
  }
  get data() {
    return this._data;
  }

  @Input()
  pipelineErrors: PipelineError[];

  @Input()
  translations: ProcessItemTranslations;

  @Input()
  cancelLoading = false;

  @Output()
  internalLinkClicked = new EventEmitter<void>();

  @Input()
  hideCancel = false;

  @Output()
  cancelClicked = new EventEmitter<string>();

  @Output()
  pipelineDetailClicked = new EventEmitter<{ appVersion: AppVersion; pipelineErrors: PipelineError[]; }>();

  @Output()
  buildCancelClicked = new EventEmitter<string>();

  actionsMap = {
    'instanceGroup.create': { isSystem: true, action: 'create', projectPrefix: 'in' },
    'instanceGroup.delete': { isSystem: true, action: 'delete', projectPrefix: 'from' },
    'instance.create': { isSystem: true, action: 'create', projectPrefix: 'to' },
    'instance.delete': { isSystem: true, action: 'delete', projectPrefix: 'from' },
    'instance.syncPublicPortRouting': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'instance.syncPublicHttpRouting': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'instance.syncPublicHttpRoutingAndCreateL7HttpBalancerStack': { isSystem: true, action: 'create', projectPrefix: 'in' },
    'instance.createL7HttpBalancerStack': { isSystem: true, action: 'create', projectPrefix: 'in' },
    'instance.deleteL7HttpBalancerStack': { isSystem: true, action: 'delete', projectPrefix: 'from' },
    'stack.delete': { isSystem: false, action: 'delete', projectPrefix: 'from' },
    'stack.create': { isSystem: false, action: 'create', projectPrefix: 'in' },
    'stack.start': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.stop': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.restart': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.reload': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.deploy': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.updateUserData': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.disableSubdomainAccess': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.enableSubdomainAccess': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.updatePorts': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.upgrade': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.sharedStorageConnect': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.sharedStorageDisconnect': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.nginxConfig': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.build': { isSystem: false, action: 'create', projectPrefix: 'in' },
    'stack.createBuildStack': { isSystem: true, action: 'create', projectPrefix: 'in' },
    'stack.deleteBuildStack': { isSystem: true, action: 'delete', projectPrefix: 'in' },
    'publicIpRequest.accept': { isSystem: false, action: 'standard', projectPrefix: 'in' },
    'stack.delete.disconnectSharedStorageConnections': { isSystem: false, action: 'standard', projectPrefix: 'in' }
  };
}
