import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'zui-linked-basic-info-header',
  templateUrl: './linked-basic-info-header.component.html',
  styleUrls: [ './linked-basic-info-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedBasicInfoHeaderComponent {

  @Input()
  link: any[];

  @Input()
  linkText: string;

  @Input()
  linkIcon: string;

  @Input()
  linkIconPosition: 'start' | 'end';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Output()
  linkClicked = new EventEmitter<void>();
}
