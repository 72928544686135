import {
  PeriodCostRaw,
  PeriodCosts,
  TransactionDebitGroupItem,
  TransactionGroupBy,
  TransactionGroupRange,
  TransactionTimeGroupBy
} from '@zerops/models/transaction-debit';
import { HashMap } from '@zerops/zef/core';

export class TransactionDebitBaseState {
  data: HashMap<any>;
  trackedItems: any[];
  periodCosts: PeriodCosts;

  constructor() {
    this.data = {};
    this.trackedItems = [];
    this.periodCosts = {
      client: undefined,
      projects: {},
      services: {}
    };
  }

}

export interface TransactionDebitGroupRequestPayload {
  groupRange: TransactionGroupRange;
  groupBy: TransactionGroupBy;
  projectId?: string;
  serviceId?: string;
  isPeriodUpdate?: boolean;
}

export interface TransactionDebitGroupUpdateSuccessPayload {
  items: TransactionDebitGroupItem[];
  limit: number;
  timeGroupBy: TransactionTimeGroupBy;
  groupBy: TransactionGroupBy;
  projectId?: string;
  serviceId?: string;
  key?: string;
  from?: string | Date;
  till?: string | Date;
}

export interface TransactionDebitPeriodCostSuccessPayload {
  client: PeriodCostRaw;
  project: PeriodCostRaw[];
  stack: PeriodCostRaw[];
}
