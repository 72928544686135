import { extractRepoData } from '@zerops/zef/core';
import { AppVersionParsedPublicGitSource, AppVersionStatuses } from './app-version.model';

export function parsePublicGitUrl(d: string): AppVersionParsedPublicGitSource {

  const data = extractRepoData(d);
  return {
    organization: data.owner,
    source: data.type || 'OTHER',
    repository: data.name
  };
}

export function getAppVersionFailedStatus(status: AppVersionStatuses) {
  return [
    AppVersionStatuses.BuildFailed,
    AppVersionStatuses.DeployFailed,
    AppVersionStatuses.PreparingRuntimeFailed
  ].includes(status);
}
