<button
  #dynamicPopRef="dynamicPopAnchor"
  type="button"
  class="__button"
  [zuiDynamicPopAnchor]="triggerRef"
  (click)="dynamicPopRef.open(); opened.emit(dynamicPopRef)"
  mat-icon-button>

  <mat-icon
    *ngIf="unreadCount"
    [matBadge]="unreadCount"
    matBadgeSize="small"
    matBadgePosition="below">
    notifications_none
  </mat-icon>

  <mat-icon *ngIf="unreadCount === 0">notifications_none</mat-icon>

</button>
