import { Injectable } from '@angular/core';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ClientUser, ClientUserStatuses } from '@zerops/models/client-user';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Sort } from '@zerops/zef/sort';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientUserEntity extends EntityService<ClientUser> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'asc'
  };

  defaultSort$ = of(this.defaultSort);

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.ClientUser, store, collectionManager, websocketService);
  }

  list$(
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: ClientUser) => any)> = [
      (d) => d.status === ClientUserStatuses.waitingAuthorization,
      (d) => d.status === ClientUserStatuses.beingDeleted,
      this.defaultSort.key
    ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [
      'asc',
      'asc',
      this.defaultSort.direction
    ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

  all$() {
    console.warn(`all$() on clientUser should never be used,
      since it contains client users from across the user clientUsers`);

    return of([]);
  }
}
