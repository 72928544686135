import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './http-routing-list.constant';

const en = {
  updateSuccess: 'Http routing was updated successfully.',
  saveDomain: 'Save Domain'
};

export type HttpRoutingListTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class HttpRoutingListTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
