import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { MoreButtonComponent } from './more-button.component';

@NgModule({
  declarations: [ MoreButtonComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DynamicPopAnchorModule,
    ProgressSpinnerModule
  ],
  exports: [ MoreButtonComponent ]
})
export class ZuiMoreButtonModule {
}
