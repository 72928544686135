import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { StatusIconBaseModule } from '@zerops/zui/status-icon-base';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { RoutingLocationItemComponent } from './routing-location-item.component';

@NgModule({
  declarations: [ RoutingLocationItemComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatTooltipModule,
    ZefCopyToClipboardModule,
    ZefPipesModule,
    StatusIconBaseModule
  ],
  exports: [ RoutingLocationItemComponent ]
})
export class RoutingLocationItemModule {

}
