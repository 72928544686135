import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDataAddFormComponent } from './user-data-add-form.component';
import { UserDataAddFormTranslationsModule } from './user-data-add-form.translations';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefFormModule } from '@zerops/zef/forms/modules';

@NgModule({
  declarations: [ UserDataAddFormComponent ],
  imports: [
    CommonModule,
    UserDataAddFormTranslationsModule,
    UserDataFieldsModule,
    ZefFormModule
  ],
  exports: [ UserDataAddFormComponent ]
})
export class UserDataAddFormModule {

}
