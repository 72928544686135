import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './project-env-edit-dialog.constant';

const en = {
  titleActionButton: 'Update custom project variable',
  updateSuccess: 'Project custom environment variable was updated successfully.'
};

export type ProjectEnvEditDialogTranslations = typeof en;

@NgModule()
export class ProjectEnvEditDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
