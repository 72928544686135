<div class="__grid">

  <div class="__grid-item">
    <zef-avatar
      class="__avatar  c-list-item__avatar"
      [size]="avatarSize"
      [name]="name"
      [image]="avatar"
      [backgroundColor]="'#000'">
    </zef-avatar>
  </div>

  <div class="__grid-item">
    <span
      class="__text  c-client-list-item-name"
      [style.max-width.px]="maxNameWidth">
      {{ name | middleEllipsis : middleEllipsisMaxLength }}
    </span>

    <div
      *ngIf="showId"
      class="__copy">

      <mat-icon class="__icon">tag</mat-icon>

      <zef-copy-to-clipboard
        [tooltip]="copyButtonTooltip"
        [copyData]="clientId">
        {{ clientId }}
      </zef-copy-to-clipboard>

    </div>

  </div>

</div>
