import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './service-stack-version-field.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {};

export type ServiceStackVersionFieldTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ServiceStackVersionFieldTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
