import { createAction, union } from '@ngrx/store';
import { Process } from '@zerops/models/process';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';

export const syncHttpRouting = createAction(
  '[@zerops/zerops/core/http-routing-base] sync',
  zefActionPayload<string, { global: boolean }>()
);

export const syncHttpRoutingFail = createAction(
  '[@zerops/zerops/core/http-routing-base] sync/fail',
  zefActionFailPayload
);

export const syncHttpRoutingSuccess = createAction(
  '[@zerops/zerops/core/http-routing-base] sync/success',
  zefActionSuccessPayload<Process>()
);

const all = union({
  syncHttpRouting,
  syncHttpRoutingFail,
  syncHttpRoutingSuccess
});

export type HttpRoutingBaseActionUnion = typeof all;
