import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { HttpRoutingFormState } from '@zerops/zui/http-routing-form';
import { HttpPortsWithServiceStackInfo } from '@zerops/models/http-routing';
import { FormControlState, Boxed, FormGroupState } from 'ngrx-forms';
import { HttpRoutingFieldsTranslations } from './http-routing-fields.translations';

export enum HttpRoutingFieldsModes {
  Normal = 'normal',
  Fallback = 'fallback'
}

export interface HttpRoutingFormLocationsValue {
  path: string;
  portData: Boxed<{ port: number; serviceStackId: string; }>;
  serviceStackId: string;
}

@Component({
  selector: 'zui-http-routing-fields',
  templateUrl: './http-routing-fields.component.html',
  styleUrls: [ './http-routing-fields.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HttpRoutingFieldsComponent extends ZefFormBase<HttpRoutingFormState> {

  @Input()
  mode: HttpRoutingFieldsModes = HttpRoutingFieldsModes.Normal;

  @Input()
  domainsControl: FormControlState<Boxed<string[]>>;

  @Input()
  domainsBlacklist: string[];

  @Input()
  sslControl: FormControlState<boolean>;

  @Input()
  locationsControl: FormGroupState<HttpRoutingFormLocationsValue>[];

  @Input()
  portsWithServiceStackInfo: HttpPortsWithServiceStackInfo[][];

  @Input()
  translations: HttpRoutingFieldsTranslations;

  @Output()
  removeLocation = new EventEmitter<number>();

  @Output()
  domainSelected = new EventEmitter<string>();

  @Output()
  domainBlacklisted = new EventEmitter<string>();

  HttpRoutingFieldsModes = HttpRoutingFieldsModes;

  trackBy(index: number) {
    return index;
  }

  compareFn(c1: { port: number; serviceStackId: string; }, c2: { port: number; serviceStackId: string; }): boolean {
    return c1 && c2 && c1.port === c2.port && c1.serviceStackId === c2.serviceStackId;
  }

}
