import { Injectable } from '@angular/core';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { selectServiceStackTypesVersionsMap } from '@zerops/zerops/core/settings-base';
import { ServiceStack } from '@zerops/models/service-stack';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Sort } from '@zerops/zef/sort';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ServiceStackEntity extends EntityService<ServiceStack> {

  defaultSort: Sort = {
    key: 'created',
    direction: 'asc'
  };

  defaultSort$ = of(this.defaultSort);

  serviceStackTypesVersionsMap$ = this.store.pipe(select(selectServiceStackTypesVersionsMap));

  autoscalingParamsByEntityId$ = (
    selectorOrId: MemoizedSelector<any, string> | string | number,
    disctingFn = (a: any, b: any) => a && b && (a as any).id !== (b as any).id
  ) => {
    return this.entityById$(selectorOrId, disctingFn).pipe(
      filter((d) => !!d),
      map((d) => ({
        horizontalAutoscaling: d.customAutoscaling?.horizontalAutoscaling,
        verticalAutoscaling: d.customAutoscaling?.verticalAutoscaling,
        mode: d.mode
      }))
    );
  }

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.ServiceStack, store, collectionManager, websocketService);
  }
}
