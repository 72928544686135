import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { BuildStateStepsComponent } from './build-state-steps.component';
import {
  ZuiInitBuildStepModule,
  ZuiInitPrepareStepModule,
  ZuiRunBuildStepModule,
  ZuiRunPrepareStepModule
} from './modules';
import { ZuiDeployStepModule } from './modules/deploy-step';
import { ZuiErrorsPrinterModule } from '@zerops/zui/errors-printer';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ BuildStateStepsComponent ],
  imports: [
    CommonModule,
    ZuiProcessStepStateModule,
    ZuiDocsTextModule,
    ZuiInitBuildStepModule,
    ZuiRunBuildStepModule,
    ZuiInitPrepareStepModule,
    ZuiRunPrepareStepModule,
    ZuiDeployStepModule,
    ZuiErrorsPrinterModule,
    ZefScrollbarComponent
  ],
  exports: [ BuildStateStepsComponent ]
})
export class ZuiBuildStateStepsModule {

}
