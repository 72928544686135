<zef-form
  class="__form"
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field>
    <mat-form-field class="__top-up-form-field" color="accent">

      <mat-icon matPrefix class="__prefix">attach_money</mat-icon>

      <input
        matInput
        type="number"
        autocomplete="off"
        [placeholder]="'Top up my credit by (min. $10)..'"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.amount"
      />

    </mat-form-field>
  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
