import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { NotificationState } from './notification.model';
import { addNotification, removeNotification } from './notification.action';

const initialState = new NotificationState();

export const actionReducer = createReducer(
  initialState,
  on(
    addNotification,
    (s, { id }): NotificationState => ({
      ...s,
      list: [ id, ...s.list ]
    })
  ),
  on(
    removeNotification,
    (s, { id }): NotificationState => ({
      ...s,
      list: s.list.filter((itm) => itm !== id)
    })
  ),
  on(zefResetState, () => initialState)
);

export function notificationReducer(
  state = initialState,
  action: any
) {
  return actionReducer(state, action);
}
