import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { ZuiAutofocusModule } from '@zerops/zui/autofocus';
import { HttpRoutingFieldsComponent } from './http-routing-fields.component';
import { HttpRoutingFieldsTranslationsModule } from './http-routing-fields.translations';

@NgModule({
  declarations: [ HttpRoutingFieldsComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MatIconModule,
    MatButtonModule,
    ZuiAutofocusModule,
    ZuiMaterialFormStackModule,
    HttpRoutingFieldsTranslationsModule
  ],
  exports: [ HttpRoutingFieldsComponent ]
})
export class HttpRoutingFieldsModule {

}
