import { Component, ChangeDetectionStrategy } from '@angular/core';
import { StatusIconBase } from '@zerops/zui/status-icon-base';

// TODO: move to models
enum ContainerStatuses {
  Creating = 'CREATING',
  Active = 'ACTIVE',
  Deleting = 'DELETING',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  ActionFailed = 'ACTION_FAILED',
  Stopping = 'STOPPING',
  Stopped = 'STOPPED',
  Starting = 'STARTING',
  Restarting = 'RESTARTING',
  Reloading = 'RELOADING',
  Moving = 'MOVING',
  Upgrading = 'UPGRADING'
}

@Component({
  selector: 'zui-container-status-icon',
  templateUrl: './container-status-icon.component.html',
  styleUrls: [ './container-status-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerStatusIconComponent extends StatusIconBase<ContainerStatuses> {

  pulsingStatusesMap = {
    [ContainerStatuses.Creating]: true,
    [ContainerStatuses.Deleting]: true,
    [ContainerStatuses.Moving]: true,
    [ContainerStatuses.Reloading]: true,
    [ContainerStatuses.Restarting]: true,
    [ContainerStatuses.Starting]: true,
    [ContainerStatuses.Stopping]: true,
    [ContainerStatuses.Upgrading]: true
 };

}
