export enum KnowledgeBaseEntities {
  Project = 'project',
  ZCli = 'z-cli',
  Service = 'service',
  EnvironmentVariables = 'environment-variables',
  Routing = 'routing',
  Storage = 'storage',
  Nginx = 'nginx',
  Ha = 'ha',
  RestApi = 'rest-api',
  GithubConnection = 'github-connection'
}
