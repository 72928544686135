<div
  [style.width.px]="logoWidth"
  [@globalLoadingAnimation]="state.active">

  <div
    class="__logo-wrap"
    *ngIf="!state.active">
    <zui-logo
      [style.width.px]="logoWidth"
      class="__logo">
    </zui-logo>
  </div>

  <mat-spinner
    *ngIf="state.active"
    class="__spinner"
    [diameter]="logoWidth">
  </mat-spinner>

</div>
