import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { ZuiDurationModule } from '@zerops/zui/duration';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { ProcessNotificationDurationComponent } from './process-notification-duration.component';

@NgModule({
  declarations: [ ProcessNotificationDurationComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    ZefAvatarModule,
    ZuiDurationModule,
    ZuiProcessStepStateModule
  ],
  exports: [ ProcessNotificationDurationComponent ]
})
export class ZuiProcessNotificationDurationModule {

}
