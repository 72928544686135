<div
  data-overlayscrollbars-initialize
  overlayScrollbars
  #osRef
  class="c-zef-scroll-area __area"
  [class.is-dark]="isDark()"
  [class.is-hover]="isHover()"
  (mouseenter)="isHover.set(true)"
  (mouseleave)="isHover.set(false)"
  [style.height]="height"
  [style.maxHeight]="maxHeight"
  [options]="options$ | async"
  [events]="events"
  [defer]="false">
  <div
    #contentRef
    class="c-zef-scroll-content __content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template [ngIf]="showScrollButtonsV">

  <button
    mat-mini-fab
    *ngIf="showTopSmartArrowIconV && isScrollableV"
    (click)="scrollToTop()"
    class="__scroll-button __scroll-button-top c-scroll-scroll-button"
    type="button">
    <mat-icon class="__scroll-button-icon c-scroll-scroll-button-icon">arrow_upward</mat-icon>
  </button>

  <button
    mat-mini-fab
    *ngIf="showBottomSmartArrowIconV && isScrollableV"
    (click)="scrollToBottom()"
    class="__scroll-button __scroll-button-bottom c-scroll-scroll-button"
    type="button">
    <mat-icon class="__scroll-button-icon c-scroll-scroll-button-icon">arrow_downward</mat-icon>
  </button>

</ng-template>
