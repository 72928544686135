import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsBaseApi {
  constructor(private _http: HttpClient) { }

  settings$() {
    // TODO: interface
    return this._http.get<any>(
      `/api/settings`
    );
  }

  countryList$() {
    return this._http.get<any>(
      `/api/settings/country-list`
    )
  }
}
