import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { RoutingLocationItemModule } from '@zerops/zui/routing-location-item';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { TranslocoModule } from '@ngneat/transloco';
import { PublicRoutingContentComponent } from './public-routing-content.component';

@NgModule({
  declarations: [ PublicRoutingContentComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslocoModule,
    MatButtonModule,
    RoutingLocationItemModule,
    DynamicPopAnchorModule
  ],
  exports: [ PublicRoutingContentComponent ]
})
export class ZuiPublicRoutingContentModule { }
