<div class="__wrap">

  <zef-scrollbar
    *ngIf="enableScroll"
    [height]="state.scrollHeight">

    <div class="__header" *ngIf="!hideHeader">
      <div class="__half-grid">

        <div>
          <h4 class="__title">
            Notifications
          </h4>
        </div>

        <div class="u-text--right">
          <zef-progress-error
            [loading]="state.markAllAsReadLoading"
            [disabled]="!state.hasUnreadNotifications">
            <button
              class="__mark-all-read-button  c-button--smaller"
              type="button"
              (click)="onMarkAllAsRead$.next()"
              mat-flat-button>
              <mat-icon [class.is-disabled]="!state.hasUnreadNotifications" class="__read-icon">fiber_manual_record</mat-icon> {{ state.notificationsCardTranslations?.markAllAsRead }}
            </button>
          </zef-progress-error>
        </div>

      </div>
    </div>

    <div *ngIf="!notificationsLoading">
      <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
    </div>

  </zef-scrollbar>

  <div *ngIf="!enableScroll && !notificationsLoading">
    <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
  </div>

  <ng-template #templateRef>

    <div class="c-notifications-card-wrap" *ngIf="!preventGrouping">

      <div
        class="__group"
        [class.is-last]="last"
        *ngFor="let group of groupedNotifications; let last = last; trackBy: trackByDay;">

        <div class="__group-header">

          <div>
            <h5 class="__group-header-title">
              {{
                group.day === 'recent'
                  ? 'Recent'
                  : group.day | date
              }}
            </h5>
          </div>

        </div>

        <div
          *ngFor="let item of group.items; let lastItem = last; trackBy: trackById;"
          class="__item  __group_item"
          [class.is-last]="lastItem">

          <zui-notification-item
            class="__notification"
            [class.is-last]="lastItem"
            showMarkAsRead
            (markAsRead)="onMarkAsRead$.next(item.id)"
            [markAsReadLoading]="!!markAsReadProgressesMap[item.id]"
            [translations]="state.notificationItemTranslations"
            [data]="item"
            [recent]="group.day === 'recent'"
            [appVersionItemTranslations]="state.appVersionItemTranslations"
            (logClicked)="onOpenLogDialog$.next($event)"
            (pipelineDetailClicked)="onOpenPipelineDetailDialog$.next($event)"
            (internalLinkClicked)="internalLinkClicked.emit()">
          </zui-notification-item>

          <zui-separator
            *ngIf="!lastItem"
            class="__separator-inner"
            [position]="'start'"
            [size]="'small'"
            [spacing]="'small'">
          </zui-separator>

        </div>

      </div>

    </div>

    <div *ngIf="preventGrouping">
      <div
        *ngFor="let item of notifications; let lastItem = last; trackBy: trackById;"
        class="__item  __group_item"
        [class.is-last]="lastItem">

        <zui-notification-item
          class="__notification"
          [class.has-no-after]="preventGrouping"
          [class.is-last]="lastItem"
          showMarkAsRead
          (markAsRead)="onMarkAsRead$.next(item.id)"
          [markAsReadLoading]="!!markAsReadProgressesMap[item.id]"
          [translations]="state.notificationItemTranslations"
          [data]="item"
          [appVersionItemTranslations]="state.appVersionItemTranslations"
          (logClicked)="onOpenLogDialog$.next($event)"
          (internalLinkClicked)="internalLinkClicked.emit()">
        </zui-notification-item>

        <zui-separator
          *ngIf="!lastItem"
          class="__separator-inner"
          [position]="'start'"
          [size]="'small'"
          [spacing]="'small'">
        </zui-separator>

      </div>
    </div>

    <zui-progress-spinner
      *ngIf="(!!limit && !!totalHits) && limit < totalHits"
      class="__more-progress"
      [diameter]="18"
      [isLoading]="showMoreLoading"
      hideContent>
      <button
        class="__button"
        type="button"
        (click)="showMore.emit()"
        mat-button>
        <ng-container *zefMessageFormat="let mf">
          <mat-icon class="__icon">keyboard_arrow_down</mat-icon>
          <span
            [innerHTML]="mf(state.notificationsCardTranslations.showMore, {
              count: ((totalHits - limit) > showMoreStep) ? showMoreStep : (totalHits - limit)
            })">
          </span>
        </ng-container>
      </button>
    </zui-progress-spinner>

  </ng-template>

  <div *ngIf="notificationsLoading">
    <mat-spinner
      class="__spinner"
      [diameter]="30">
    </mat-spinner>
  </div>

</div>