import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { Project } from '@zerops/models/project';
import { HttpRoutingWithLocations } from '@zerops/models/http-routing';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { GeneralTranslations } from '@zerops/zerops/app';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { DnsCheckStatusPopTranslations } from './dns-check-status-pop.translations';
import { FEATURE_NAME } from './dns-check-status-pop.constant';

@Component({
  selector: 'z-dns-check-status-pop',
  templateUrl: './dns-check-status-pop.container.html',
  styleUrls: [ './dns-check-status-pop.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DnsCheckStatusPopContainer extends ZefReactiveComponent {

  @ObservableInput()
  @Input('httpRoutingId')
  httpRoutingId$!: Observable<string>;

  @ObservableInput()
  @Input('httpRoutingDomainName')
  httpRoutingDomainName$!: Observable<string>;

  @ObservableInput()
  @Input('httpRoutings')
  httpRoutings$!: Observable<HttpRoutingWithLocations[]>;

  @Input()
  project: Project;

  @Output()
  popClosed = new EventEmitter<void>();

  @Output()
  contentMouseLeave = new EventEmitter<void>();

  @Output()
  contentMouseEnter = new EventEmitter<void>();

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  generalTranslations$ = this.translate$<GeneralTranslations>('general');

  translations$ = this.translate$<DnsCheckStatusPopTranslations>(FEATURE_NAME);

  domain$ = combineLatest([
    this.httpRoutings$,
    this.httpRoutingId$,
    this.httpRoutingDomainName$
  ]).pipe(
    filter(([ routings, id, name ]) => !!routings && !!id && !!name),
    map(([ routings, id, name ]) => routings
      .find((r) => r.id === id).domains
      .find((d) => d.domainName === name)
    )
  );

  // # State resolver
  state = this.$connect({
    generalTranslations: this.generalTranslations$,
    translations: this.translations$,
    domain: this.domain$
  });

}
