import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingBaseState } from './billing-base.model';
import { FEATURE_NAME } from './billing-base.constant';

export const selectBillingBaseState = createFeatureSelector<BillingBaseState>(FEATURE_NAME);

export const selectPaymentClientSecret = createSelector(
  selectBillingBaseState,
  (state) => state.activePaymentRequest?.clientSecret
);

export const selectPaymentSources = createSelector(
  selectBillingBaseState,
  (state) => state.paymentSources
);

export const selectAvailableProjectAddons = createSelector(
  selectBillingBaseState,
  (state) => state.availableProjectAddons
);

export const selectAvailableClientAddons = createSelector(
  selectBillingBaseState,
  (state) => state.availableClientAddons
);
