<zui-process-notification-shell
  [isSystem]="actionsMap[data?.actionName]?.isSystem || data?.createdByUser.type === 'SYSTEM'"
  [fullName]="data?.createdByUser?.fullName"
  [email]="data?.createdByUser?.email"
  [image]="data?.createdByUser?.avatar?.smallAvatarUrl"
  [created]="data?.created"
  [createdByType]="data?.createdByUser.type"
  [firstName]="data?.createdByUser?.firstName"
  [externalImage]="data?.createdByUser?.avatar?.externalAvatarUrl"
  [prefixText]="'Created by'"
  [color]="statusColorClassMap[data?.status]">

  <ng-container *zefMessageFormat="let mf">

    <!-- build & deploy pipeline steps -->
    <ng-container *ngIf="data?.appVersion">

      <zui-pipeline-desc
        class="__build-desc"
        [serviceName]="data?.serviceStacks[0].name"
        [serviceType]="data?.serviceStacks[0].serviceStackTypeInfo?.serviceStackTypeName"
        [serviceTypeId]="data?.serviceStacks[0]?.serviceStackTypeId"
        [projectName]="data?.project.name"
        [serviceId]="data?.serviceStacks[0].id"
        [projectId]="data?.projectId"
        [appVersion]="data?.appVersion"
        [actionName]="data?.actionName"
        (linkClicked)="internalLinkClicked.emit()">
      </zui-pipeline-desc>

      <div class="__process-time">

        <zui-process-notification-duration
          class="__duration"
          [type]="data?.status === processStatuses.PENDING ? 'QUEUED' : 'TIMER'"
          [start]="data?.started">
        </zui-process-notification-duration>

        <button
          *ngIf="data?.status === processStatuses.RUNNING && [
            appVersionStatuses.Building,
            appVersionStatuses.WaitingToBuild,
            appVersionStatuses.Uploading
          ].includes(data?.appVersion?.status)"
          (click)="buildCancelClicked.emit(data.appVersion?.id)"
          mat-flat-button
          class="[ c-button  c-button--smaller ]  __cancel-button  __cancel-build-button"
          type="button"
          [disabled]="!!data.cancelRequested">

          <ng-container *ngIf="!data.cancelRequested">
            <mat-icon fontSet="material-icons-outlined" class="__cancel-build-button_icon">cancel</mat-icon>&nbsp;<span class="__cancel-build-button_text">Cancel build</span>
          </ng-container>

          <div class="__cancel-build-button_cancel-request" *ngIf="!!data.cancelRequested">
            Canceling <zui-three-dots></zui-three-dots>
          </div>

        </button>

        @if (!hideCancel) {
          <div class="__process-cancel-wrap" *ngIf="data?.status === processStatuses.PENDING">
            <ng-template [ngTemplateOutlet]="cancelButton"></ng-template>
          </div>
        }

      </div>

      <ng-container *ngIf="data?.status === processStatuses.RUNNING && data.actionName !== 'stack.deploy.backup'">

        <zui-separator
          class="__separator"
          [position]="'start'"
          [size]="'mini'"
          [spacing]="'mini'">
        </zui-separator>

        <zui-build-state-steps
          class="__build-steps"
          [serviceName]="data?.serviceStacks[0]?.name"
          [serviceType]="data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName"
          [projectId]="data?.projectId"
          [appVersion]="data?.appVersion"
          [pipelineErrors]="pipelineErrors">
        </zui-build-state-steps>

        <ng-container *ngIf="data?.appVersion?.source !== 'GUI' && (!!data?.appVersion?.build || !!data?.appVersion?.prepareCustomRuntime)">

          <zui-separator
            class="__separator"
            [position]="'start'"
            [size]="'mini'"
            [spacing]="'mini'">
          </zui-separator>

          <button
            (click)="pipelineDetailClicked.emit({ appVersion: data?.appVersion, pipelineErrors })"
            mat-flat-button
            class="[ c-button  c-button--smaller ]  __pipeline-button"
            type="button">
            Open pipeline detail <mat-icon>aspect_ratio</mat-icon>
          </button>

        </ng-container>

      </ng-container>

    </ng-container>

    <!-- other -->
    <div  *ngIf="!data?.appVersion">

      <zui-process-notification-desc (linkClicked)="internalLinkClicked.emit()">

        <span
          *ngIf="data?.status === processStatuses.RUNNING"
          [innerHTML]="(translations && translations.running[(data?.actionName | normalizeActionName)]
            ? mf(translations.running[(data?.actionName | normalizeActionName)], {
                serviceType: data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName,
                serviceName: data?.serviceStacks[0]?.name,
                serviceId: data?.serviceStacks[0]?.id,
                projectId: data?.projectId,
                projectName: data?.project.name
              })
            : data?.actionName
          ) | safeHtml">
        </span>

        <span
          *ngIf="data?.status === processStatuses.PENDING"
          [innerHTML]="(translations && translations.pending[(data?.actionName | normalizeActionName)]
            ? mf(translations.pending[(data?.actionName | normalizeActionName)], {
              serviceType: data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName,
              serviceName: data?.serviceStacks[0]?.name,
              serviceId: data?.serviceStacks[0]?.id,
              projectId: data?.projectId,
              projectName: data?.project.name
            })
            : data?.actionName
          ) | safeHtml">
        </span>

      </zui-process-notification-desc>

      <div class="__process-footer">

        <div class="__process-time">
          <zui-process-notification-duration
            [type]="data?.status === processStatuses.PENDING ? 'QUEUED' : 'TIMER'"
            [start]="data?.started">
          </zui-process-notification-duration>
        </div>

        @if (!hideCancel) {
          <div class="__process-cancel-wrap" *ngIf="data?.status === processStatuses.PENDING">
            <ng-template [ngTemplateOutlet]="cancelButton"></ng-template>
          </div>
        }

      </div>
    </div>

  </ng-container>

</zui-process-notification-shell>

<ng-template #cancelButton>
  <button
    (click)="cancelClicked.emit(data?.id)"
    class="__cancel-button  c-button c-button--smaller"
    type="button"
    mat-button>
    Cancel process
  </button>
</ng-template>
