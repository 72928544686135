<div
  *ngIf="state.debuggerStatus === debuggerStatuses.Opened"
  [@inOutAnimation]
  class="__wrap"
  [class.is-shrinked]="shrinked">

  <button
    class="__arrow"
    (click)="toggleShrink()"
    mat-icon-button>
    <mat-icon>{{ shrinked ? 'arrow_circle_up' : 'arrow_circle_down' }}</mat-icon>
  </button>

  <h3 class="__title">Websocket debugging in progress <zui-three-dots class="__title-dots"></zui-three-dots></h3>

  <h4 class="__subtitle">Remove <span class="__subtitle-localstorage">FORCE_WEBSOCKET_DEBUGGER</span> from localstorage to stop debugging</h4>

  <div class="__content">

    <div class="__info">

      <h4 class="__info-title">Active connection credentials</h4>

      <div class="__info-item"><strong>Token:</strong> {{ state.token }}</div>

      <div class="__info-item is-last"><strong>ReceiverId:</strong> {{ state.receiverId }}</div>

      <button
        class="__info-button c-button--full c-button--smaller"
        (click)="toggleHistoricalConnections()"
        type="button"
        mat-stroked-button>
        <mat-icon>{{ displayHistoricalConnections ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        {{ displayHistoricalConnections ? 'Hide' : 'Show' }} historical SocketSuccess message credentials
      </button>

      <div
       *ngIf="displayHistoricalConnections"
        class="__info-success-connections">
        <div
          *ngFor="let item of state.successConnections; let last = last; trackBy: trackByIndex"
          class="__info-success-connections-item"
          [class.is-last]="last">

          <div class="__info-item"><strong>date:</strong> {{ item.date | date: 'medium' }}</div>

          <div class="__info-item"><strong>chainId:</strong> {{ item.chainId }}</div>

          <div class="__info-item"><strong>sessionId:</strong> {{ item.sessionId }}</div>

          <div class="__info-item is-last"><strong>requestId:</strong> {{ item.requestId }}</div>

        </div>
      </div>

    </div>

    <div  class="__messages">

      <ng-container *ngIf="state.messages?.length; else emptyRef">

        <h4 class="__messages-last">Last message arrived at {{ state.lastMessageDate | date: 'medium' }}</h4>

        <div class="__messages-count">{{ state.messages?.length }}</div>

        <div class="__messages-wrap">
          <div
            *ngFor="let item of state.messages; let last = last; trackBy: trackByIndex"
            class="__messages-item"
            [class.is-last]="last">

            <div class="__info-item"><strong>date:</strong> {{ item.date | date: 'medium' }}</div>

            <div class="__info-item"><strong>chainId:</strong> {{ item.chainId }}</div>

            <div class="__info-item"><strong>sessionId:</strong> {{ item.sessionId }}</div>

            <div class="__info-item"><strong>subscriptionName:</strong> {{ item.subscriptionName }}</div>

            <div class="__info-item is-last"><strong>requestId:</strong> {{ item.requestId }}</div>

          </div>
        </div>

      </ng-container>

      <ng-template #emptyRef>
        <h4 class="__messages-empty">No websocket message has arrived yet...</h4>
      </ng-template>

    </div>

  </div>

</div>
