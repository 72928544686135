import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { UserCardComponent } from './user-card.component';
import { UserCardTranslationsModule } from './user-card.translations';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ UserCardComponent ],
  imports: [
    CommonModule,
    ZefAvatarModule,
    UserCardTranslationsModule,
    ZefPipesModule
  ],
  exports: [ UserCardComponent ]
})
export class UserCardModule {

}
