import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { Process } from '@zerops/models/process';

export const processCancel = createAction(
  '[@zerops/zerops/process-base] process-cancel',
  zefActionPayload<{ id: string }>()
);

export const processCancelFail = createAction(
  '[@zerops/zerops/process-base] process-cancel/fail',
  zefActionFailPayload
);

export const processCancelSuccess = createAction(
  '[@zerops/zerops/process-base] process-cancel/success',
  zefActionSuccessPayload<Process>()
);

export const processFinishNotify = createAction(
  '[@zerops/zerops/process-base] process-finish-notify',
  (data: Partial<Process>[]) => ({ data })
);

export const buildCancel = createAction(
  '[@zerops/zerops/process-base] build-cancel',
  zefActionPayload<{ id: string }>()
);

export const buildCancelFail = createAction(
  '[@zerops/zerops/process-base] build-cancel/fail',
  zefActionFailPayload
);

export const buildCancelSuccess = createAction(
  '[@zerops/zerops/process-base] build-cancel/success',
  zefActionSuccessPayload<Process>()
);

const all = union({
  processCancel,
  processCancelFail,
  processCancelSuccess
});

export type ProcessBaseActionUnion = typeof all;
