import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransactionDebitBaseState } from './transaction-debit-base.model';
import { FEATURE_NAME } from './transaction-debit-base.constant';
import {
  getTransactionGroupRangeKey,
  TransactionGroupBy,
  TransactionGroupRange
} from '@zerops/models/transaction-debit';

export const selectTransactionDebitBaseState = createFeatureSelector<TransactionDebitBaseState>(FEATURE_NAME);

export const selectTransactionDebitBaseGroupedData = createSelector(
  selectTransactionDebitBaseState,
  (s) => s.data
);

export const selectTransactionDebitGroup = (
  groupBy: TransactionGroupBy,
  range: TransactionGroupRange
) => createSelector(
  selectTransactionDebitBaseGroupedData,
  (d) => {
    if (!d || !d[groupBy]) { return undefined; }
    return d[groupBy][getTransactionGroupRangeKey(range.key)];
  }
);

export const selectProjectTransactionDebitGroupMap = createSelector(
  selectTransactionDebitBaseGroupedData,
  (d) => {
    if (!d || !d[TransactionGroupBy.Service]) { return undefined; }
    return d[TransactionGroupBy.Service];
  }
);

export const selectServiceTransactionDebitGroupInnerMap = createSelector(
  selectTransactionDebitBaseGroupedData,
  (d) => {
    if (!d || !d[TransactionGroupBy.Metric]) { return undefined; }
    return d[TransactionGroupBy.Metric];
  }
);

export const selectClientTransactionDebitGroupMap = (
  groupRange: TransactionGroupRange
) => createSelector(
  selectTransactionDebitBaseGroupedData,
  (d) => {
    if (!d || !d[TransactionGroupBy.Project]) { return undefined; }
    return d[TransactionGroupBy.Project][getTransactionGroupRangeKey(groupRange.key)];
  }
);

export const selectPeriodCosts = createSelector(
  selectTransactionDebitBaseState,
  (s) => s.periodCosts
);

export const selectTrackedItems = createSelector(
  selectTransactionDebitBaseState,
  (s) => s.trackedItems
);

export const selectClientPeriodCosts = createSelector(
  selectPeriodCosts,
  (s) => s.client
);

export const selectProjectPeriodCostsMap = createSelector(
  selectPeriodCosts,
  (s) => s.projects
);

export const selectServicePeriodCostsMap = createSelector(
  selectPeriodCosts,
  (s) => s.services
);
