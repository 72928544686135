import { LogData, LogInfo } from '@zerops/models/project';

export interface TrlogUrlParams {
  from?: string;
  till?: string;
  limit?: string | number;
  projectId?: string;
  serviceStackId?: string;
  containerId?: string;
  facility?: any;
  content?: string;
  desc?: '1' | '0';
  minimumSeverity?: string;
  tags?: string;
}

export interface TrlogParams {
  from?: string;
  till?: string;
  limit?: string | number;
  projectId?: string;
  serviceStackId?: string;
  containerId?: string;
  source?: 'access-logs' | 'logs';
  content?: string;
  desc?: '1' | '0';
  minimumSeverity?: string;
  period?: string;
  mode: 'tail' | 'absPeriod' | 'relPeriod' | 'range' | 'limit' | 'append' | 'prepend';
  tags?: string;
}

export interface TrLogOptions {
  live: boolean;
  liveCancellable: boolean;
  liveFollow: boolean;
}

export interface TrlogState {
  data: {
    items: LogData[];
    hasMore: boolean;
  };
  info: {
    items: LogInfo[];
  };
  options: TrLogOptions;
  params: TrlogParams;
  loading: boolean;
  loadingInfo: boolean;
  loadMoreLoading: boolean;
}

export interface GetLogPayload extends TrlogParams {
  key: string;
  projectId: string;
  live?: boolean;
}

export interface OpenLogStreamPayload extends TrlogParams {
  key: string;
  projectId: string;
}

export interface LogRes {
  items: LogData[];
  nextPage: boolean;
}

export interface LogInfoRes {
  items: LogInfo[];
}

export enum Facilities {
  Sixteen = 16,
  Seventeen = 17
}

export enum Severities {
  All = 'ALL',
  Unknown = 'UNKNOWN',
  Emergency = 'Emergency',
  Alert = 'Alert',
  Critical = 'Critical',
  Error = 'Error',
  Warning = 'Warning',
  Notice = 'Notice',
  Informational = 'Informational',
  Debug = 'Debug'
}

export const SEVERITIES = [
  {
    name: 'Emergency',
    value: '0'
  },
  {
    name: 'Alert',
    value: '1'
  },
  {
    name: 'Critical',
    value: '2'
  },
  {
    name: 'Error',
    value: '3'
  },
  {
    name: 'Warning',
    value: '4'
  },
  {
    name: 'Notice',
    value: '5'
  },
  {
    name: 'Informational',
    value: '6'
  },
  {
    name: 'Debug',
    value: '7'
  }
];

export const SEVERITIES_MAP = SEVERITIES.reduce((obj, itm) => {
  obj[itm.value] = itm;
  return obj;
}, {})

export const LOG_OPTIONS_KEYS: (keyof TrlogParams)[] = [
  'serviceStackId',
  'from',
  'till',
  'limit',
  'containerId',
  'desc',
  'minimumSeverity',
  'tags'
];
