import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { Notification } from '@zerops/models/notification';

export const markAsRead = createAction(
  '[@zerops/zerops/notifications-base] mark-as-read',
  zefActionPayload<{ id: string; shouldMarkAsRead: boolean; }>()
);

export const markAsReadFail = createAction(
  '[@zerops/zerops/notifications-base] mark-as-read/fail',
  zefActionFailPayload
);

export const markAsReadSuccess = createAction(
  '[@zerops/zerops/notifications-base] mark-as-read/success',
  zefActionSuccessPayload<Partial<Notification>>()
);

export const markAllAsRead = createAction(
  '[@zerops/zerops/notifications-base] mark-all-as-read',
  zefActionPayload<{ serviceStackId?: string; projectId?: string; }>()
);

export const markAllAsReadFail = createAction(
  '[@zerops/zerops/notifications-base] mark-all-as-read/fail',
  zefActionFailPayload
);

export const markAllAsReadSuccess = createAction(
  '[@zerops/zerops/notifications-base] mark-all-as-read/success',
  zefActionSuccessPayload<any>()
);


const all = union({
  markAsRead,
  markAsReadFail,
  markAsReadSuccess,
  markAllAsRead,
  markAllAsReadFail,
  markAllAsReadSuccess
});

export type NotificationBaseActionUnion = typeof all;
