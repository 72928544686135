import { Component, Input } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { ZefSnackInput } from '@zerops/zef/snack';

@Component({
  selector: 'zui-snack-warning',
  templateUrl: './snack-warning.container.html',
  styleUrls: [ './snack-warning.container.scss' ]
})
export class SnackWarningContainer {
  @Input()
  data: ZefSnackInput;

  @Input()
  instance: MatSnackBarRef<any>;
}
