<zef-dialog
  [open]="open"
  (closeTrigger)="onClose$.next()"
  [autoFocus]="false">
  <div class="__content">

    <zef-form-field>
      <mat-form-field color="accent" [floatLabel]="'always'">

        <mat-label>{{ state.translations.label }}</mat-label>

        <textarea
          class="__textarea"
          matInput
          matAutosize
          spellcheck="false"
          type="text"
          rows="15"
          [placeholder]="state.translations.placeholder"
          [formControl]="envControl"></textarea>

          <mat-hint [innerHTML]="state.translations.desc"></mat-hint>

      </mat-form-field>
    </zef-form-field>

    <div class="__controls">

      <button
        class="c-button--full"
        color="accent"
        type="button"
        [disabled]="!envControl.value"
        (click)="add.emit(envControl.value); dialogClose.emit(); resetTextarea();"
        mat-raised-button>
        {{ state.translations.add }}
      </button>

      <button
        class="c-button--full"
        type="button"
        (click)="dialogClose.emit(); resetTextarea();"
        mat-button>
        <span>{{ state.translations.close }}</span>
      </button>

    </div>

  </div>
</zef-dialog>
