import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'z-disk-capacity-field',
  templateUrl: './disk-capacity-field.component.html',
  styleUrls: [ './disk-capacity-field.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DiskCapacityFieldComponent),
      multi: true
    }
  ]
})
export class DiskCapacityFieldComponent implements ControlValueAccessor {
  @Input()
  label: string;

  @Input()
  step = 1;

  @Input()
  unit = 'GB';

  @Input()
  minValue = 1;

  @Input()
  maxValue = 100;

  @Input()
  labelTranslation: string;

  set value(v) {
    this._value = v;

    this._propagateChange(this.value);
  }

  get value() {
    return this._value;
  }

  private _value: number;

  writeValue(val: number) {
    this.value = val;
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(_: any) {
    return;
  }

  private _propagateChange = (_: any) => {
    return;
  };
}
