import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { BillingInfoFormModule } from '@zerops/zerops/common/billing-info-form';
import { BillingInfoEditDialogContainer } from './billing-info-edit-dialog.container';
import { BillingInfoEditDialogEffect } from './billing-info-edit-dialog.effect';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ BillingInfoEditDialogContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ BillingInfoEditDialogEffect ]),
    ZefDialogModule,
    FormSectionModule,
    ZefProgressErrorModule,
    BillingInfoFormModule,
    ZefPipesModule,
    ZefScrollbarComponent
  ],
  exports: [ BillingInfoEditDialogContainer ]
})
export class BillingInfoEditDialogModule {

}
