import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiAddonCardModule } from '@zerops/zui/addon-card';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { AddonActivationDialogContainer } from './addon-activation-dialog.container';
import { AddonActivationDialogTranslationsModule } from './addon-activation-dialog.translations';

@NgModule({
  declarations: [ AddonActivationDialogContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ZefDialogModule,
    AddonActivationDialogTranslationsModule,
    ZuiAddonCardModule,
    ZefProgressErrorModule
  ],
  exports: [ AddonActivationDialogContainer ]
})
export class AddonActivationDialogModule {

}
