import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminalFeature } from './terminal.feature';
import { NgTerminalModule } from 'ng-terminal';
import { API_URL, FORCE_SECURED_ENDPOINT } from './terminal.constant';
import { TerminalModuleConfig } from './terminal.model';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ActionListItemModule } from '@zerops/zui/action-list-item';

@NgModule({})
export class TerminalRootModule {}

@NgModule({
  declarations: [ TerminalFeature ],
  imports: [
    CommonModule,
    NgTerminalModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    ActionListItemModule
  ],
  exports: [ TerminalFeature ]
})
export class TerminalModule {
  static forRoot(config: TerminalModuleConfig): ModuleWithProviders<TerminalRootModule> {
    return {
      ngModule: TerminalRootModule,
      providers: [
        {
          provide: API_URL,
          useValue: config.apiUrl
        },
        {
          provide: FORCE_SECURED_ENDPOINT,
          useValue: config.forceSecuredEndpoint
        }
      ]
    };
  }
}
