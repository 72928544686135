import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { map, filter } from 'rxjs/operators';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { Notification } from '@zerops/models/notification';
import { UNREAD_NOTIFICATION_LIST_KEY } from './notification-base.constant';

@Injectable({ providedIn: 'root' })
export class NotificationEntity extends EntityService<Notification> {
  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.Notification, store, collectionManager, websocketService);
  }

  // TODO: from api
  projectRecentNotificationsMap$(tag?: string | { name: string; id: string; }) {
    return this.list$(tag, [ 'created' ], [ 'desc' ]).pipe(
      filter((d) => !!d),
      map((notifications) => notifications.reduce((obj, itm) => {

        if (!obj[itm.project.id]) {
          obj[itm.project.id] = [];
        }

        if (obj[itm.project.id].length < 3) {
          obj[itm.project.id].push(itm);
        }

        return obj;

      }, {})
    ));
  }

  // TODO: from api
  serviceStackRecentNotificationsMap$(tag?: string | { name: string; id: string; }) {
    return this.list$(tag, [ 'created' ], [ 'desc' ]).pipe(
      filter((d) => !!d),
      map((notifications) => notifications.reduce((obj, itm) => {

        itm.serviceStacks.forEach((stack) => {
          if (!obj[stack.id]) {
            obj[stack.id] = [];
          }

          if (obj[stack.id].length < 3) {
            obj[stack.id].push(itm);
          }
        });

        return obj;
      }, {})
    ));
  }

  list$(
    tag?: string | { name: string; id: string; },
    // TODO: interface for order selector / dir
    orderSelector: Array<string | ((i: Notification) => any)> = [ 'created' ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ 'desc' ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

  unreadNotificationMap$() {
    return super.list$(UNREAD_NOTIFICATION_LIST_KEY).pipe(
      map((d) => {

        const maps = d?.reduce((obj, itm) => {
          if (itm.project) {
            if (!obj.projectMap[itm.project.id]) {
              obj.projectMap[itm.project.id] = 0;
            }
            obj.projectMap[itm.project.id] = obj.projectMap[itm.project.id] + 1;
          }

          if (itm.serviceStacks?.length) {
            const ssId = itm.serviceStacks[0].id;

            if (!obj.serviceMap[ssId]) {
              obj.serviceMap[ssId] = 0;
            }

            obj.serviceMap[ssId] = obj.serviceMap[ssId] + 1;
          }

          return obj;

        }, {
          projectMap: {},
          serviceMap: {}
        });

        return {
          client: d?.length || 0,
          ...(maps || {})
        };

      })
    );
  }


}
