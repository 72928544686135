import { HashMap } from '@zerops/zef/core';
import { UserData } from '@zerops/models/user-data';

export const getUserDataKeyContentMap = (
  userData: UserData[],
): HashMap<string> => {

  return userData.reduce((obj, itm) => {
    obj[itm.key] = itm.content;
    return obj;
  }, {});

};
