import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PortRoutingBaseEffect } from './port-routing-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ PortRoutingBaseEffect ])
  ]
})
export class PortRoutingBaseModule {

}
