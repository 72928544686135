import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  ɵbypassSanitizationTrustResourceUrl as bypassSanitizationTrustResourceUrl,
  Input
} from '@angular/core';
import { DocsPopService } from './docs-pop.service';
import { map, filter } from 'rxjs/operators';
import { DialogComponent } from '@zerops/zef/dialog';

@Component({
  selector: 'zui-docs-pop',
  templateUrl: './docs-pop.component.html',
  styleUrls: [ './docs-pop.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZuiDocsPopComponent implements AfterViewInit {

  @ViewChild(DialogComponent)
  dialogRef: DialogComponent;

  @Input()
  documentationPath: string;

  _dialogOpen = false;
  _dialogOptions = {
    hasBackdrop: true
  };

  docsPath$ = this._docsPopService
    .getEntity$()
    .pipe(
      filter((d) => !!d),
      map((ent) => this._getPath(ent))
    );

  constructor(private _docsPopService: DocsPopService) { }

  ngAfterViewInit() {
    this._docsPopService.saveRef(this);
  }

  open() {
    this._dialogOpen = true;
  }

  close() {
    this._dialogOpen = false;
  }

  closeTrigger(): void {
    this._dialogOpen = false;
  }

  private _getPath(ent: string) {
    const fullPath = `${this.documentationPath}${ent}`;

    return bypassSanitizationTrustResourceUrl(fullPath);
  }

}
