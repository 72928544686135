import { Component } from '@angular/core';
import { PortRoutingForm } from '@zerops/zui/port-routing-form';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { PortRoutingEntity, modifyFirewallIpRanges } from '@zerops/zerops/core/port-routing-base';
import { ProjectEntity, getProjectPorts } from '@zerops/zerops/core/project-base';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { formValueOnValid } from '@zerops/zef/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, switchMap, filter, withLatestFrom, delay } from 'rxjs/operators';
import { FEATURE_NAME } from './port-routing-edit-dialog.constant';
import { PortRoutingEditDialogOpenMeta } from './port-routing-edit-dialog.model';
import { PublicIpTypes } from '@zerops/models/port-routing';

@Component({
  selector: 'z-port-routing-edit-dialog',
  templateUrl: './port-routing-edit-dialog.container.html',
  styleUrls: [ './port-routing-edit-dialog.container.scss' ]
})
export class PortRoutingEditDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onEdit$ = new Subject<void>();
  onRequestIpV4$ = new Subject<any>();
  onAddEmptyFirewallIpRange$ = new Subject<void>();
  onRemoveFirewallIpRange$ = new Subject<number>();
  onClose$ = new Subject<void>();

  // # Forms
  formState$ = this._portRoutingForm.state$;

  // # Data
  // -- sync
  editKey = this._portRoutingEntity.updateOne.type;
  dialogKey = FEATURE_NAME;
  publicIpTypes = PublicIpTypes;

  // -- async
  dialogState$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data.state));
  metaData$ = this.dialogState$.pipe(
    map((data) => data.meta as PortRoutingEditDialogOpenMeta),
    filter((d) => !!d)
  );
  portRouting$ = this.metaData$.pipe(
    switchMap(({ id }) => this._portRoutingEntity.entityById$(id)),
    filter((d) => !!d),
  );
  activePublicPort$ = this.portRouting$.pipe(
    map((d) => d.publicPort)
  );
  serviceStackId$ = this.portRouting$.pipe(
    map((d) => d?.serviceStackId),
    filter((d) => !!d)
  );
  projectId$ = this.portRouting$.pipe(
    map((d) => d?.projectId),
    filter((d) => !!d)
  );
  serviceStack$ = this.serviceStackId$.pipe(
    switchMap((id) => this._serviceStackEntity.entityById$(id))
  );
  project$ = this.projectId$.pipe(
    switchMap((projectId) => this._projectEntity
      .entityByIdWithServiceStacks$(projectId)
      .pipe(filter((d) => !!d))
    )
  );
  projectPorts$ = this.open$.pipe(
    filter((d) => !!d),
    switchMap(() => this.serviceStackId$.pipe(
      withLatestFrom(this.project$),
      map(([ serviceStackId, project ]) => getProjectPorts(project, serviceStackId, false, true)
    ))
  ));
  ip$ = this.portRouting$.pipe(
    withLatestFrom(this.project$),
    map(([ d, project ]) => d.publicIpType === this.publicIpTypes.IpV4
    ? project.publicIpV4
    : project.publicIpV6
  ));

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    project: this.project$,
    projectPorts: this.projectPorts$,
    ip: this.ip$,
    activePublicPort: this.activePublicPort$
  });

  // # Action Streams
  private _editAction$ = this.onEdit$.pipe(
    delay(0),
    formValueOnValid(this._portRoutingForm),
    withLatestFrom(this.serviceStackId$),
    map(([ d, stackId ]) => this._portRoutingEntity.updateOne(
      d.id,
      {
        publicIpType: d.publicIpType,
        serviceStackId: stackId,
        publicPort: d.publicPort,
        internalPort: d.internalPortData.value.port,
        internalProtocol: d.internalPortData.value.protocol,
        firewallPolicy: d.firewallPolicy,
        // firewallAllowMyIp: d.firewallPolicy === FirewallPolicy.Whitelist ? d.firewallAllowMyIp : false,
        firewallAllowMyIp: false,
        firewallIpRanges: d.firewall
          ? modifyFirewallIpRanges(d.firewallIpRanges, d.publicIpType)
          : []
      }
    ))
  );

  private _addEmptyFirewallIpRangeAction$ = this.onAddEmptyFirewallIpRange$.pipe(
    map(() => this._portRoutingForm.addArrayControl(
      'firewallIpRanges',
      {
        ip: '',
        range: undefined
      }
    ))
  );

  private _removeFirewallIpRangeAction$ = this.onRemoveFirewallIpRange$.pipe(
    map((index) => this._portRoutingForm.removeArrayControl('firewallIpRanges', index))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: this.dialogKey }))
  );

  constructor(
    private _store: Store<AppState>,
    private _serviceStackEntity: ServiceStackEntity,
    private _projectEntity: ProjectEntity,
    private _portRoutingEntity: PortRoutingEntity,
    private _portRoutingForm: PortRoutingForm
  ) {
    super();

    this.$dispatchActions(
      [
        this._editAction$,
        this._addEmptyFirewallIpRangeAction$,
        this._removeFirewallIpRangeAction$,
        this._closeAction$
      ]
    );
  }

}
