import { ServiceStackBaseState } from './service-stack-base.model';
import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import {
  deleteBackupSuccess,
  getBackupsSuccess,
  getContainerFileSuccess,
  ServiceStackBaseActionUnion
} from './service-stack-base.action';

const initialState = new ServiceStackBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    getContainerFileSuccess,
    (s, { data, originalAction }): ServiceStackBaseState => {
      const serviceStackFiles = s.containerFiles?.[originalAction?.data?.serviceStackId]
        ? s.containerFiles[originalAction.data.serviceStackId]
        : {};
      return ({
        ...s,
        containerFiles: {
          ...s.containerFiles,
          [originalAction?.data?.serviceStackId]: {
            ...serviceStackFiles,
            [originalAction?.data?.filePath]: data
          }
        }
      })
    }
  ),
  on(getBackupsSuccess, (s, { data, originalAction }) => {
    return {
      ...s,
      backups: {
        ...s.backups,
        [originalAction.data.id]: data
      }
    };
  }),
  on(deleteBackupSuccess, (s, { data }) => {
    return {
      ...s,
      backups: {
        ...s.backups,
        [data.serviceId]: s.backups[data.serviceId]?.filter((itm) => itm.name !== data.id)
      }
    };
  }),
  on(zefResetState, () => initialState)
);

export function serviceStackBaseReducer(
  state: ServiceStackBaseState,
  action: ServiceStackBaseActionUnion
) {
  return actionReducer(state, action);
}
