import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { BillingInfoComponent } from './billing-info.component';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ BillingInfoComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    ZuiMaterialStackModule,
    ZefPipesModule
  ],
  exports: [ BillingInfoComponent ]
})
export class ZuiBillingInfoModule { }
