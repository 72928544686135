import { AuthPopupWindow } from '@zerops/zef/core';
import { ZefGitlabAuthStateData } from './gitlab.model';

export const loginWithGitlab = (url: any, options?: any, id?: any) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const popup = AuthPopupWindow.open(url, options, id);
    popup.then(resolve, reject);
  });
};

export const parseGitlabAuthState = (data: string) => {
  try {
    return JSON.parse(window.atob(data)) as ZefGitlabAuthStateData;
  } catch (e) { return undefined; }
};
