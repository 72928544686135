import { Component, Input, Output, EventEmitter } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { markAsRead, markAllAsRead, NotificationEntity } from '@zerops/zerops/core/notification-base';
import { Notification } from '@zerops/models/notification';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { selectZefProgressMapByType, selectZefProgressByType } from '@zerops/zef/progress';
import { LOG_DIALOG_FEATURE_NAME } from '@zerops/zerops/feature/log-dialog';
import { pipelineDetailDialogOpen } from '@zerops/zerops/feature/pipeline-detail-dialog';
// explicit import, circular dep
import {
  NotificationItemTranslations,
  NOTIFICATION_ITEM_FEATURE_NAME
} from '@zerops/zui/notification-item';
import { AppVersionItemTranslations, APP_VERSION_ITEM_FEATURE_NAME } from '@zerops/zui/app-version-item';
import { AppVersion } from '@zerops/models/app-version';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ObservableInput } from 'observable-input';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  format,
  parseISO,
  startOfDay,
  isBefore,
  subMinutes
} from 'date-fns/esm';
import { NotificationsCardTranslations } from './notifications-card.translations';
import { FEATURE_NAME } from './notifications-card.constant';
import { PipelineError } from '@zerops/models/error-backend';

// TODO: unify
interface GroupedNotification {
  day: string;
  items: Notification[];
}

@Component({
  selector: 'z-notifications-card',
  templateUrl: './notifications-card.container.html',
  styleUrls: [ './notifications-card.container.scss' ]
})
export class NotificationsCardContainer extends ZefReactiveComponent {

  // # Event Streams
  onMarkAsRead$ = new Subject<string>();
  onMarkAllAsRead$ = new Subject<void>();
  onOpenLogDialog$ = new Subject<{
    serviceStackId: string;
    projectId: string;
    containerId: string;
    from: string;
    to: string;
  }>();
  onOpenPipelineDetailDialog$ = new Subject<{ appVersion: AppVersion; pipelineErrors: PipelineError[]; }>();

  // # Data
  // -- angular
  @Input()
  totalHits: number;

  @Input()
  limit: number;

  @Input()
  showMoreStep = 10;

  @Input()
  enableScroll = false;

  @Input()
  dateFormat = 'shortTime';

  @Input()
  level: 'client' | 'project' | 'service' = 'client';

  @Input()
  id: string;

  @Input()
  set showMoreLoading(v: string | boolean) {
    this._showMoreLoading = coerceBooleanProperty(v);
  }
  get showMoreLoading(): boolean {
    return this._showMoreLoading;
  }

  @Input()
  notificationsLoading = false;

  @Input()
  set preventGrouping(v: string | boolean) {
    this._preventGrouping = coerceBooleanProperty(v);
  }
  get preventGrouping(): boolean {
    return this._preventGrouping;
  }

  @Input()
  set notifications(v) {
    this._notifications = v;
    if (!this.preventGrouping) {
      this.groupedNotifications = this._groupNotifications(this.notifications);
    }
  }
  get notifications() {
    return this._notifications;
  }

  @Input()
  set hideHeader(v: string | boolean) {
    this._hideHeader = coerceBooleanProperty(v);
  }
  get hideHeader(): boolean {
    return this._hideHeader;
  }

  @ObservableInput()
  @Input('unreadSettings')
  unreadSettings$!: Observable<{
    level: 'client' | 'project' | 'service';
    id?: string;
  }>;

  @Output()
  showMore = new EventEmitter<void>();

  @Output()
  internalLinkClicked = new EventEmitter<void>();

  // -- sync
  markAsReadProgressesMap = {};
  groupedNotifications: GroupedNotification[];

  // -- async
  notificationsCardTranslations$ = this.translate$<NotificationsCardTranslations>(FEATURE_NAME);
  notificationItemTranslations$ = this.translate$<NotificationItemTranslations>(NOTIFICATION_ITEM_FEATURE_NAME);
  markAsReadProgressesMap$ = this._store.pipe(
    select(selectZefProgressMapByType(markAsRead.type))
  );
  markAllAsReadLoading$ = this._store.pipe(
    select(selectZefProgressByType(markAllAsRead.type)),
    map((r) => !!r)
  );
  scrollHeight$ = this.unreadSettings$.pipe(
    map((d) => d?.level === 'client' ? 'calc(100vh - 70px)' : '65vh')
  );
  hasUnreadNotifications$ = this.unreadSettings$.pipe(
    filter((d) => !!d),
    switchMap((settings) => this._notificationEntity.unreadNotificationMap$().pipe(
      map((data) => {

        if (settings.level === 'client') {
          return data.client > 0;
        }

        if (settings.level === 'project' && !!data.projectMap && !!data.projectMap[settings.id]) {
          return data.projectMap[settings.id] > 0;
        }

        if (settings.level === 'service' && !!data.serviceMap && !!data.serviceMap[settings.id]) {
          return data.serviceMap[settings.id] > 0;
        }

      }),
      distinctUntilChanged()
    ))
  );
  appVersionItemTranslations$ = this.translate$<AppVersionItemTranslations>(APP_VERSION_ITEM_FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    scrollHeight: this.scrollHeight$,
    markAsReadProgressesMap: this.markAsReadProgressesMap$,
    markAllAsReadLoading: this.markAllAsReadLoading$,
    hasUnreadNotifications: this.hasUnreadNotifications$,
    notificationsCardTranslations: this.notificationsCardTranslations$,
    notificationItemTranslations: this.notificationItemTranslations$,
    appVersionItemTranslations: this.appVersionItemTranslations$
  });

  // # Action Streams
  private _markAsReadAction$ = this.onMarkAsRead$.pipe(
    map((id) => markAsRead({ id }))
  );
  private _markAllAsReadAction$ = this.onMarkAllAsRead$.pipe(
    map(() => markAllAsRead({
      serviceStackId: this.level === 'service' ? this.id : undefined,
      projectId: this.level === 'project' ? this.id : undefined
    }))
  );
  private _openLogDialogAction$ = this.onOpenLogDialog$.pipe(
    map((meta) => zefDialogOpen({
      key: LOG_DIALOG_FEATURE_NAME,
      meta
    }))
  );
  private _openPipelineDetailDialog$ = this.onOpenPipelineDetailDialog$.pipe(
    map((data) => pipelineDetailDialogOpen(data))
  );

  private _notifications: Notification[];
  private _hideHeader: boolean;
  private _preventGrouping: boolean;
  private _showMoreLoading = false;

  constructor(
    private _store: Store<AppState>,
    private _notificationEntity: NotificationEntity
  ) {
    super();

    this.$dispatchActions([
      this._markAsReadAction$,
      this._markAllAsReadAction$,
      this._openLogDialogAction$,
      this._openPipelineDetailDialog$
    ]);

    this.markAsReadProgressesMap$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((d) => this.markAsReadProgressesMap = d);
  }

  trackByDay(_: number, item: any) {
    return item?.day;
  }

  trackById(_: number, item: any) {
    return item?.id;
  }

  // TODO: unify
  private _groupNotifications(notifications: Notification[]) {
    return notifications.reduce((obj, itm) => {
      const parsedDay = parseISO(itm.created);
      const isRecent = !isBefore(parsedDay, subMinutes(new Date(), 30));
      const day = !isRecent
        ? format(parsedDay, 'y-M-d')
        : 'recent';

      if (obj.dayMap[day] === undefined) {
        obj.dayMap[day] = obj.data.length;

        obj.data.push({
          day: isRecent
            ? 'recent'
            : startOfDay(parsedDay).toISOString(),
          items: [],
        });
      }

      obj.data[obj.dayMap[day]].items.push(itm);

      return obj;
    }, {
      dayMap: {},
      data: [] as GroupedNotification[]
    }).data;
  }

}
