import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Log, Project } from '@zerops/models/project';
import { Process } from '@zerops/models/process';
import { VpnSetting, VpnSettings } from '@zerops/zerops/core/project-base';
import { LogInfoRes, LogRes } from '@zerops/zerops/feature/trlog';
import { ProjectCustomEnvPayload, RemoteLoggingDataPayload, VpnPublicKeyPayload } from './project-base.model';

@Injectable({ providedIn: 'root' })
export class ProjectBaseApi {
  constructor(private _http: HttpClient) { }

  loadTags$(clientId: string) {
    return this._http.post<{ items: string[]; }>('/api/project/tag/list', { clientId });
  }

  logUrl$(id: string) {
    return this._http.get<Log>(`/api/project/${id}/log`);
  }

  logData$(url: string) {
    return this._http.get<LogRes>(url);
  }

  logInfo$(url: string) {
    return this._http.get<LogInfoRes>(url);
  }

  requestIpv4$(projectId: string) {
    return this._http.put<Process>(
      `/api/project/${projectId}/request-ipv4`,
      {}
    );
  }

  startProject$(id: string) {
    return this._http.put<Project>(
      `/api/project/${id}/start`,
      {}
    );
  }

  updateRemoteLogging$(id: string, data: RemoteLoggingDataPayload) {
    return this._http.put<Process>(
      `/api/project/${id}/remote-logging`,
      data
    );
  }

  addVpnPublicKey$(id: string, data: VpnPublicKeyPayload) {
    return this._http.post<VpnSetting>(
      `/api/project/${id}/vpn`,
      data
    );
  }

  deleteVpnPublicKey$(id: string, data: VpnPublicKeyPayload) {
    const options = {
      body: {
        publicKey: data.publicKey
      }
    };
    return this._http.delete<{success: true}>(
      `/api/project/${id}/vpn`,
      options
    );
  }

  listVpnPublicKeys$(id: string) {
    return this._http.get<VpnSettings>(
      `/api/project/${id}/vpn/list`
    );
  }

  projectExport$(id: string) {
    return this._http.get<{ yaml: string; }>(
      `/api/project/${id}/export`
    );
  }

  projectImport$(yaml: string, clientId: string) {
    return this._http.post<any>(
      '/api/project/import',
      {
        yaml,
        clientId
      }
    );
  }

  /**
   * Create a new unique project custom environment variable.
   */
  addProjectCustomEnv$(projectId: string, data: ProjectCustomEnvPayload) {
    return this._http.post<Process>(
      `/api/project-env`,
      {
        projectId,
        ...data
      }
    );
  }

  /**
   * Update all project custom environment variables through a bulk operation.
   * It is used a stringified list of all variables as a content.
   */
  updateProjectCustomEnvs$(projectId: string, content: string) {
    return this._http.put<Process>(
      `/api/project-env/env-file`,
      {
        projectId,
        ...{ envFile: content }
      }
    );
  }

  /**
   * Update individual project custom environment variable.
   */
  updateProjectCustomEnv$(id: string, data: ProjectCustomEnvPayload) {
    return this._http.put<Process>(
      `/api/project-env/${id}`,
      data
    );
  }

  /**
   * Delete individual project custom environment variable.
   */
  deleteProjectCustomEnv$(id: string) {
    return this._http.delete<Process>(
      `/api/project-env/${id}`
    );
  }

}
