import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { ProcessItemModule } from '@zerops/zui/process-item';
import { NotificationItemModule } from '@zerops/zui/notification-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { NotificationEffect } from './notification.effect';
import { NotificationFeature } from './notification.feature';
import { NotificationTranslationsModule } from './notification.translations';
import { notificationReducer } from './notification.reducer';
import { FEATURE_NAME } from './notification.constant';

@NgModule({
  declarations: [ NotificationFeature ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ NotificationEffect ]),
    StoreModule.forFeature(
      FEATURE_NAME,
      notificationReducer
    ),
    MaterialStackModule,
    ProcessItemModule,
    NotificationItemModule,
    NotificationTranslationsModule,
    ZuiSeparatorComponent
  ],
  exports: [ NotificationFeature ]
})
export class NotificationModule { }
