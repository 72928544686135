import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { delay, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { loadCountryList } from '@zerops/zerops/core/settings-base/settings-base.action';
import { AppState } from '@zerops/zerops/app';
import { billingInfo, selectBillingInfo } from '@zerops/zerops/core/client-base';
import { confirmPaymentSuccess, paymentSources } from '@zerops/zerops/core/billing-base';
import { TopUpAmountForm } from './modules';
import { FEATURE_NAME } from './top-up-dialog.constant';
import { openTopUpDialog } from './top-up-dialog.action';
import { TopUpBillingInfoForm } from './modules/billing-info-form';
import { TopUpPromoCodeForm } from './modules/top-up-promo-code-form';

@Injectable()
export class TopUpDialogEffect {

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    // dialog close animation
    delay(100),
    switchMap(() => [
      this._topUpAmountForm.reset(),
      this._topUpAmountForm.setDefaultValues(),
      this._topUpPromoCodeForm.reset(),
      this._topUpPromoCodeForm.setDefaultValues(),
      this._billingInfoForm.reset(),
      this._billingInfoForm.setDefaultValues()
    ])
  ));

  private _onTopUpOpenDialogOpenDialog$ = createEffect(() => this._actions$.pipe(
    ofType(openTopUpDialog),
    map(() => zefDialogOpen({ key: FEATURE_NAME }))
  ));

  private _onOpenDialogLoadData$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      loadCountryList(),
      billingInfo(),
      paymentSources()
    ])
  ));

  private _onConfirmPaymentCloseDialog$ = createEffect(() => this._actions$.pipe(
    ofType(confirmPaymentSuccess),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onTopUpOpenDialogFillForm$ = createEffect(() => this._actions$.pipe(
    ofType(openTopUpDialog),
    withLatestFrom(this._store.pipe(select(selectBillingInfo))),
    filter(([ _, data ]) => !!data?.current),
    map(([ _, { current } ]) => this._billingInfoForm.setValue({
      ...current,
      vatPayer: !!current.vatNumber
    }))
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _topUpAmountForm: TopUpAmountForm,
    private _topUpPromoCodeForm: TopUpPromoCodeForm,
    private _billingInfoForm: TopUpBillingInfoForm
  ) { }
}
