import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { ImportExportDialogContainer } from './import-export-dialog.container';
import { ImportExportDialogEffect } from './import-export-dialog.effect';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ ImportExportDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    MatInputModule,
    EffectsModule.forFeature([ ImportExportDialogEffect ]),
    ZefDialogModule,
    TranslocoModule,
    ZefFormsModule,
    ZuiDocsTextModule,
    ZefProgressErrorModule,
    CodeFieldModule,
    ZuiSeparatorComponent,
    SatPopoverModule,
    MatListModule,
    ActionListItemModule,
    ZefScrollbarComponent
  ],
  exports: [ ImportExportDialogContainer ]
})
export class ImportExportDialogModule {

}
