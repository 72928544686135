@if ((status || prefixRef) && !iconStatus) {
  <div class="__top-wrap">

    @if (status) {
      <div>
        @if (mode === modes.Project) {
          <zui-project-status-icon
            [size]="statusIconSize[size]"
            [status]="status"
            [statusText]="generalTranslations?.projectStatus[status]"
          />
        }

        @if (mode === modes.ServiceStack) {
          <zui-service-stack-status-icon
            [size]="statusIconSize[size]"
            [status]="status"
            [statusText]="generalTranslations?.stackStatus[status]"
          />
        }
      </div>
    }

    @if (prefixRef) {
      <div class="__prefix">
        <ng-template [ngTemplateOutlet]="prefixRef"></ng-template>
      </div>
    }
  </div>
}

<h3
  class="__name  c-basic-info-header-name"
  [ngClass]="{
    'is-service-stack': mode === modes.ServiceStack,
    'is-project': mode === modes.Project,
    'is-small': size === 'small',
    'is-medium': size === 'medium',
    'is-large': size === 'large'
  }">
  <span class="__name_content  c-basic-info-header-name-content">

    <div class="__grid">

      @if (iconStatus) {
        <div class="__grid-item  __grid-item--status">

          @if (mode === modes.Project) {
            <zui-project-status-icon
              [size]="customIconSize || statusIconSize[size]"
              [status]="status"
              [tooltip]="generalTranslations?.projectStatus[status]"
            />
          }

          @if (mode === modes.ServiceStack) {
            <zui-service-stack-status-icon
              [size]="customIconSize || statusIconSize[size]"
              [status]="status"
              [tooltip]="generalTranslations?.stackStatus[status]"
            />
          }
        </div>
      }

      <div class="__grid-item">
        <zef-fuse-highlight
          class="__name-highlight"
          [matTooltip]="name"
          [matTooltipShowDelay]="250"
          [matTooltipDisabled]="name?.length <= nameEllipsisSize"
          [data]="{ name: !!name ? ( name | middleEllipsis : nameEllipsisSize) : undefined }"
          [key]="'name'"
          [matches]="searchMatches"
        />
      </div>

      <div class="__grid-item">
        <ng-content />
      </div>

    </div>

  </span>
</h3>
