<div
  class="__step  c-build-state-steps-step"
  [class.is-waiting]="_state === buildProcessStates.Waiting">

  <span class="__step_status  c-build-state-steps-step-status">
    <zui-process-step-state [state]="_state"></zui-process-step-state>
  </span>

  <div *ngIf="!hideText" class="__step_text  c-build-state-steps-step-text" [ngSwitch]="_state">

    <ng-template [ngSwitchCase]="buildProcessStates.Waiting">
      Create app version&nbsp;<strong>{{ appVersion?.name }}</strong> and upgrade {{ runtimeServiceType }} service <strong>{{ runtimeServiceName }}</strong>
    </ng-template>

    <ng-template [ngSwitchCase]="buildProcessStates.Running">
      Creating app version&nbsp;<strong>{{ appVersion?.name }}</strong> and upgrading {{ runtimeServiceType }} service <strong>{{ runtimeServiceName }}</strong>
    </ng-template>

    <ng-template [ngSwitchCase]="buildProcessStates.Finished">
      Created app version&nbsp;<strong>{{ appVersion?.name }}</strong> and upgraded {{ runtimeServiceType }} service <strong>{{ runtimeServiceName }}</strong>
    </ng-template>

    <ng-template [ngSwitchCase]="buildProcessStates.Failed">

      <strong>Failed</strong> while creating app version&nbsp;<strong>{{ appVersion?.name }}</strong> or upgrading <strong>{{ runtimeServiceName }}</strong> ({{ runtimeServiceType }}), please contact support if problem persists.

      <!-- Start: New solution for displaying BE errors. -->
      <div
        *ngIf="!!pipelineErrors?.[0]?.error"
        class="__error-card">
        <zef-scrollbar maxHeight="200px">
          <zui-errors-printer
            [pipelineErrors]="pipelineErrors">
          </zui-errors-printer>
        </zef-scrollbar>
      </div>
      <!-- End: New solution for displaying BE errors. -->

    </ng-template>

    <ng-template [ngSwitchCase]="buildProcessStates.Cancelled">
      Cancelled app version {{ appVersion?.name }} creation and deploy to <strong>{{ runtimeServiceName }}</strong> ({{ runtimeServiceType }})
    </ng-template>

  </div>

</div>
