import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { RoutingLocationItemModule } from '@zerops/zui/routing-location-item';
import { ZuiSeparatorComponent } from '@zerops/zui//separator';
import { PortRoutingItemComponent } from './port-routing-item.component';
import { PortRoutingItemTranslationsModule } from './port-routing-item.translations';

@NgModule({
  declarations: [ PortRoutingItemComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    RouterModule,
    PortRoutingItemTranslationsModule,
    SatPopoverModule,
    ZuiSeparatorComponent,
    RoutingLocationItemModule
  ],
  exports: [ PortRoutingItemComponent ]
})
export class PortRoutingItemModule {

}
