import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { BodyScrollService } from '../scroll';

export type ColorScheme = 'light' | 'dark' | 'system';

const THEME_KEY = 'zef-preferred-theme';
const DARK_MODE_CLASS = 'zef-dark-theme';
const ZEN_MODE_KEY = 'zef-zen-mode';
const COMPACT_MODE_KEY = 'zef-compact-mode';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  #bodyScrollService = inject(BodyScrollService);
  private _isDarkMode$: BehaviorSubject<boolean>;
  private _activeMode$: BehaviorSubject<ColorScheme>;
  private _isZen$: BehaviorSubject<boolean>;
  private _isCompact$: BehaviorSubject<boolean>;
  isDarkMode$: Observable<boolean>;
  isZen$: Observable<boolean>;
  activeMode$: Observable<ColorScheme>;
  isCompact$: Observable<boolean>;

  constructor() {
    this._isDarkMode$ = new BehaviorSubject<boolean>(undefined);
    this._activeMode$ = new BehaviorSubject<ColorScheme>(undefined);
    this._isZen$ = new BehaviorSubject<boolean>(false);

    this._listenForSystemPreferenceChanges();

    this.#bodyScrollService.viewportSize$.subscribe((v) => {
      if (v.width < 1480) {
        this.setCompactMode(true, false);
      } else {
        if (!this.getSavedCompactMode()) {
          this.setCompactMode(false, false);
        }
      }
    });

    const savedTheme = this.getSavedTheme();
    const savedZenMode = this.getSavedZenMode();

    if (savedTheme) {
      this._activeMode$.next(savedTheme);
      this._isDarkMode$.next(this._shouldApplyDarkMode());
    } else {
      this._activeMode$.next('light');
      this._isDarkMode$.next(false);
    }

    if (savedZenMode !== null) {
      this._isZen$.next(savedZenMode);
    } else {
      this._isZen$.next(false);
    }

    this.applyTheme();

    const savedCompactMode = this.getSavedCompactMode();
    this._isCompact$ = new BehaviorSubject<boolean>(savedCompactMode !== null ? savedCompactMode : false);
    this.isCompact$ = this._isCompact$.asObservable().pipe(distinctUntilChanged());

    this.isDarkMode$ = this._isDarkMode$.asObservable().pipe(distinctUntilChanged());
    this.activeMode$ = this._activeMode$.asObservable().pipe(distinctUntilChanged());
    this.isZen$ = this._isZen$.asObservable().pipe(distinctUntilChanged());
  }

  saveTheme(theme: ColorScheme) {
    localStorage.setItem(THEME_KEY, theme);
    this.applyTheme();
  }

  getTheme() {
    const savedTheme = this.getSavedTheme();
    return savedTheme || 'light';
  }

  getSavedTheme() {
    return localStorage.getItem(THEME_KEY) as ColorScheme | null;
  }

  applyTheme() {
    const isDarkMode = this._shouldApplyDarkMode();
    this._updateBodyClass(isDarkMode);
    this._isDarkMode$.next(isDarkMode);
    this._activeMode$.next(this.getTheme());
  }

  toggleZenMode() {
    const currentZenMode = this._isZen$.getValue();
    const newZenMode = !currentZenMode;
    this._isZen$.next(newZenMode);
    localStorage.setItem(ZEN_MODE_KEY, JSON.stringify(newZenMode));
  }

  applyZenMode() {
    const savedZenMode = this.getSavedZenMode();
    this._isZen$.next(savedZenMode);
  }

  getSavedZenMode() {
    const savedZenMode = localStorage.getItem(ZEN_MODE_KEY);
    return savedZenMode ? JSON.parse(savedZenMode) : false;
  }

  setCompactMode(m: boolean, save = false) {
    this._isCompact$.next(m);
    if (save) {
      localStorage.setItem(COMPACT_MODE_KEY, JSON.stringify(m));
    }
  }

  toggleCompactMode() {
    const currentCompactMode = this._isCompact$.getValue();
    const newCompactMode = !currentCompactMode;
    this._isCompact$.next(newCompactMode);
    localStorage.setItem(COMPACT_MODE_KEY, JSON.stringify(newCompactMode));
  }

  getSavedCompactMode() {
    const savedCompactMode = localStorage.getItem(COMPACT_MODE_KEY);
    return savedCompactMode ? JSON.parse(savedCompactMode) : false;
  }

  private _getSystemColorScheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  private _updateBodyClass(isDarkMode: boolean) {
    if (isDarkMode) {
      document.body.classList.add(DARK_MODE_CLASS);
      document.documentElement.classList.add(DARK_MODE_CLASS);
    } else {
      document.body.classList.remove(DARK_MODE_CLASS);
      document.documentElement.classList.remove(DARK_MODE_CLASS);
    }
  }

  private _listenForSystemPreferenceChanges() {
    const prefersColorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    prefersColorSchemeQuery.addEventListener('change', () => {
      if (this.getTheme() === 'system') {
        this.applyTheme();
      }
    });
  }

  private _shouldApplyDarkMode() {
    const theme = this.getTheme();
    return theme === 'dark' || (theme === 'system' && this._getSystemColorScheme() === 'dark');
  }

}
