import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { ZefFuseModule } from '@zerops/zef/fuse';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { UserDataItemTranslationsModule } from './user-data-item.translations';
import { UserDataItemComponent } from './user-data-item.component';

@NgModule({
  declarations: [ UserDataItemComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    ZefCopyToClipboardModule,
    UserDataItemTranslationsModule,
    ZefFuseModule,
    ZuiMoreButtonModule
  ],
  exports: [ UserDataItemComponent ]
})
export class UserDataItemModule {

}
