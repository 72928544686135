import { NgModule } from '@angular/core';
import { LegacyNativeDateModule as NativeDateModule } from '@angular/material/legacy-core';
import { DatetimeAdapter } from './datetime-adapter';
import { MAT_DATETIME_FORMATS } from './datetime-formats';
import { MAT_DATE_FNS_DATETIME_FORMATS } from './date-fns-datetime-formats';
import { DateFnsDatetimeAdapter } from './date-fns-datetime-adapter';

@NgModule({
  imports: [ NativeDateModule ],
  providers: [
    {
      provide: DatetimeAdapter,
      useClass: DateFnsDatetimeAdapter
    }
  ]
})
export class NativeDateFnsDatetimeModule { }


@NgModule({
  imports: [ NativeDateFnsDatetimeModule ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: MAT_DATE_FNS_DATETIME_FORMATS
    }
  ],
})
export class MatDateFnsDatetimeModule { }
