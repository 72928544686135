import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { BillingInfoFormComponent } from './billing-info-form.component';

@NgModule({
  declarations: [ BillingInfoFormComponent ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ZefFormsModule,
    MaterialFormStackModule
  ],
  exports: [ BillingInfoFormComponent ]
})
export class BillingInfoFormModule {

}
