import { NgModule, ModuleWithProviders } from '@angular/core';
import { ZEF_GITLAB_API_URL, ZEF_GITLAB_CALLBACK_URL, ZEF_GITLAB_FEATURE_NAME } from './gitlab.constant';
import { ZefGitlabConfig } from './gitlab.model';
import { EffectsModule } from '@ngrx/effects';
import { ZefGitlabEffect } from './gitlab.effect';
import { StoreModule } from '@ngrx/store';
import { gitlabReducer } from './gitlab.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      ZefGitlabEffect
    ]),
    StoreModule.forFeature(
      ZEF_GITLAB_FEATURE_NAME,
      gitlabReducer
    )
  ]
})
export class ZefGitlabModule {
  static forRoot(config?: ZefGitlabConfig): ModuleWithProviders<ZefGitlabModule> {
    return {
      ngModule: ZefGitlabModule,
      providers: [
        {
          provide: ZEF_GITLAB_CALLBACK_URL,
          useValue: config?.callbackUrl
        },
        {
          provide: ZEF_GITLAB_API_URL,
          useValue: config?.apiUrl
        }
      ]
    };
  }

}
