import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { TopUpPromoCodeFormState } from './top-up-promo-code-form.form';

@Component({
  selector: 'z-top-up-promo-code-form',
  templateUrl: './top-up-promo-code-form.component.html',
  styleUrls: [ './top-up-promo-code-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUpPromoCodeFormComponent extends ZefFormBase<TopUpPromoCodeFormState> {

}
