import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObjectStorageUsage } from './object-storages-usage.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectStoragesUsageApi {
  constructor(private _http: HttpClient) { }

  usage$(id: string) {
    return this._http.get<ObjectStorageUsage>(
      `/api/service-stack/${id}/object-storage-size`
    );
  }

}
