import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './user-data-edit-form.constant';

const en = {};

export type UserDataEditFormTranslations = typeof en;

@NgModule()
export class UserDataEditFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
