<div
  class="c-status-icon-base__wrap"
  [ngStyle]="{
    'width.px': size,
    'height.px': size
  }">

  <div
    class="c-status-icon-base__status"
    [class.is-pulsing]="pulsing"
    [ngClass]="'has-status--' + status"
    [matTooltipDisabled]="!tooltip"
    [matTooltip]="tooltip"
    [ngStyle]="{
      'width.px': size,
      'height.px': size
    }">
  </div>

  <span
    *ngIf="pulsing || rippling"
    class="c-status-icon-base-ripple"
    [ngStyle]="{
      'width.px': size,
      'height.px': size
    }"
    [ngClass]="'has-status--' + status">
  </span>

</div>

<span class="c-status-icon-base-text" *ngIf="statusText">{{ statusText }}</span>

