import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { onWebsocketMessageDispatchUpdateEntities, MergeStrategy } from '@zerops/zef/entities';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PortRoutingEntity } from './port-routing-base.entity';
import { PortRoutingBaseApi } from './port-routing-base.api';
import {
  syncPortRoutingSuccess,
  syncPortRoutingFail,
  syncPortRouting,
} from './port-routing-base.action';

@Injectable()
export class PortRoutingBaseEffect {

  private _setupPortRoutingUpdateSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._portRoutingEntity.updateSubscribe(clientId),
  )));

  private _setupUpdateCacheDispatch$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(
      this._portRoutingEntity,
      {
        zefEntityMergeStrategy: {
          firewallIpRanges: MergeStrategy.KeepNew
        }
      }
    )
  ));

  private _onSync$ = createEffect(() => this._actions$.pipe(
    ofType(syncPortRouting),
    switchMap((action) => this._api
      .sync$(action.data)
      .pipe(
        map((res) => syncPortRoutingSuccess(res, action)),
        catchError((res) => of(syncPortRoutingFail(res, action)))
      )
    )
  ));

  constructor(
    private _userEntity: UserEntity,
    private _portRoutingEntity: PortRoutingEntity,
    private _actions$: Actions,
    private _api: PortRoutingBaseApi
  ) { }
}
