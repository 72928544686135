import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Process } from '@zerops/models/process';

@Injectable({ providedIn: 'root' })
export class ProcessBaseApi {
  constructor(private _http: HttpClient) { }

  cancel$(id: string) {
    return this._http.put<Process>(
      `/api/process/${id}/cancel`,
      {}
    );
  }

  buildCancel$(id: string) {
    return this._http.put<Process>(
      `/api/app-version/${id}/cancel-build`,
      {}
    );
  }
}
