import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { PortRoutingFormModule } from '@zerops/zui/port-routing-form';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { PortRoutingEditDialogContainer } from './port-routing-edit-dialog.container';
import { PortRoutingEditDialogEffect } from './port-routing-edit-dialog.effect';
import { PortRoutingEditDialogTranslationsModule } from './port-routing-edit-dialog.translations';

@NgModule({
  declarations: [ PortRoutingEditDialogContainer ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ PortRoutingEditDialogEffect ]),
    MaterialStackModule,
    TranslocoModule,
    FormSectionModule,
    PortRoutingFormModule,
    ZefProgressErrorModule,
    PopStateModule,
    ZefDialogModule,
    PortRoutingEditDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ PortRoutingEditDialogContainer ]
})
export class PortRoutingEditDialogModule {

}
