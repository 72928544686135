import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { SatPopover } from '@zerops/zef/popover';
import { NotificationEntity } from '@zerops/zerops/core/notification-base';
import { ListAdditionalInfo, showMoreEntities } from '@zerops/zef/entities';
import { AppState } from '@zerops/zerops/app';
import { selectZefProgressByTypeAndId } from '@zerops/zef/progress';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotificationsPopTranslations } from './notifications-pop.translations';
import { FEATURE_NAME } from './notifications-pop.constant';
import { NotificationsPopService } from './notifications-pop.service';
import { notificationsPopSettingsReset } from './notifications-pop.action';
import { selectNotificationPopState } from './notifications-pop.selector';

@Component({
  selector: 'z-notifications-pop',
  templateUrl: './notifications-pop.container.html',
  styleUrls: [ './notifications-pop.container.scss' ]
})
export class NotificationsPopContainer extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onShowMore$ = new Subject<void>();
  onAfterPopClose$ = new Subject<void>();

  // # Data
  // -- sync
  notificationsListAdditionalInfo: ListAdditionalInfo;
  showMoreStep = 50;

  // -- angular
  @ViewChild(SatPopover, { static: true })
  notificationsPopRef: SatPopover;

  // -- async
  settings$ = this._store.pipe(
    select(selectNotificationPopState)
  );
  notifications$ = this._notificationEntity.list$(FEATURE_NAME).pipe(
    map((items) => !items ? [] : items)
  );
  notificationsLoading$ = this._store.pipe(
    select(selectZefProgressByTypeAndId(this._notificationEntity.listSubscribe.type, FEATURE_NAME)),
    map((d) => !!d && !d.id)
  );
  unreadSettings$ = this._store.pipe(
    select(selectNotificationPopState),
    filter((d) => !!d?.level)
  );
  notificationsListAdditionalInfo$ = this._notificationEntity.listAdditionalInfo$(FEATURE_NAME);
  translations$ = this.translate$<NotificationsPopTranslations>(FEATURE_NAME);
  showMoreNotificationsLoading$ = this._store.pipe(
    select(selectZefProgressByTypeAndId(this._notificationEntity.listSubscribe.type, FEATURE_NAME)),
    map((d) => !!d && !!d.id)
  );

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    settings: this.settings$,
    notifications: this.notifications$,
    unreadSettings: this.unreadSettings$,
    notificationsListAdditionalInfo: this.notificationsListAdditionalInfo$,
    notificationsLoading: this.notificationsLoading$,
    showMoreNotificationsLoading: this.showMoreNotificationsLoading$
  });

  // # Action Streams
  private _showMoreEntitiesAction$ = this.onShowMore$.pipe(
    map(() => showMoreEntities(FEATURE_NAME))
  );
  private _afterPopCloseAction$ = this.onAfterPopClose$.pipe(
    map(() => notificationsPopSettingsReset())
  );

  constructor(
    private _store: Store<AppState>,
    private _notificationEntity: NotificationEntity,
    private _notificationsPopService: NotificationsPopService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._showMoreEntitiesAction$,
      this._afterPopCloseAction$
    ]);

  }

  ngAfterViewInit() {
    this._notificationsPopService.saveRef(this.notificationsPopRef);
  }

}
