import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatRFC3339 } from 'date-fns/esm';
import { TransactionDebitGroupItem, TransactionGroupBy, TransactionTimeGroupBy } from '@zerops/models/transaction-debit';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { getTransactionGroupRangeSubscriptionName } from './transaction-debit-base.utils';

@Injectable({ providedIn: 'root' })
export class TransactionDebitBaseApi {
  constructor(
    private _http: HttpClient,
    private _wsService: ZefWebsocketService
  ) { }

  groupSearch$(
    clientId: string,
    groupBy: TransactionGroupBy,
    timeGroupBy: TransactionTimeGroupBy,
    limit: number,
    projectId?: string,
    serviceId?: string,
    range?: {
      from: Date;
      to: Date;
    },
    key?: string
  ) {
    return this._http.post<{ items: TransactionDebitGroupItem[]; }>(
      '/api/transaction-debit/group-by-search',
      {
        search: [
          {
            name: 'clientId',
            operator: 'eq',
            value: clientId
          },
          !!projectId
            ? {
              name: 'projectId',
              operator: 'eq',
              value: projectId
            }
            : undefined,
          !!serviceId
            ? {
              name: 'stackId',
              operator: 'eq',
              value: serviceId
            }
            : undefined
        ].filter((itm) => !!itm),
        receiverId: this._wsService.getReceiverId(),
        subscriptionName: getTransactionGroupRangeSubscriptionName(
          groupBy,
          timeGroupBy,
          projectId,
          serviceId,
          key
        ),
        groupBy,
        timeGroupBy,
        timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        limit: !!range.from && !!range.to && !limit ? undefined : limit,
        from: !!limit ? undefined : formatRFC3339(range?.from),
        till: !!limit ? undefined : formatRFC3339(range?.to)
      }
    );
  }

  periodCost$(clientId: string) {
    return this._http.post<any>(
      '/api/transaction-debit/cost-search',
      {
        search: [
          {
            name: 'clientId',
            operator: 'eq',
            value: clientId
          }
        ]
      }
    );
  }

}
