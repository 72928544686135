import { Component, ChangeDetectionStrategy, ContentChildren, QueryList, ChangeDetectorRef, Input } from '@angular/core';
import { toggleContentAnimation } from './toggle-content.animations';
import { ToggleContentItemDirective } from './toggle-content-item.directive';

@Component({
  selector: 'zui-toggle-content',
  templateUrl: './toggle-content.component.html',
  styleUrls: [ './toggle-content.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ toggleContentAnimation() ]
})
export class ToggleContentComponent {
  @Input()
  activeIndex = 0;

  @Input()
  position: 'start' |'end' = 'start';

  @ContentChildren(ToggleContentItemDirective)
  itemsRefs: QueryList<ToggleContentItemDirective>;

  constructor(private _cdRef: ChangeDetectorRef) { }

  toggle() {
    this.activeIndex = this.activeIndex ? 0 : 1;
    this._cdRef.markForCheck();
  }

  open() {
    this.activeIndex = 1;
    this._cdRef.markForCheck();
  }

  close() {
    this.activeIndex = 0;
    this._cdRef.markForCheck();
  }

}
