export enum TrLogPeriods {
  Last60Minutes = 'last60Minutes',
  Last15Minutes = 'last15Minutes',
  Last7Hours = 'last7Hours',
  Last24Hours = 'last24Hours',
  Last7Days = 'last7Days',
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek'
}
