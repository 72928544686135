import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState, validate } from 'ngrx-forms';
import { greaterThan } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './project-cost-limit-dialog.constant';

export interface ProjectCostLimitFormValue {
  limit: number;
}

export type ProjectCostLimitFormState = FormState<ProjectCostLimitFormValue>;

@Injectable({ providedIn: 'root' })
export class ProjectCostLimitForm extends ZefForm<ProjectCostLimitFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        limit: 1
      },
      {
        limit: validate(greaterThan(0))
      }
    );
  }
}
