export const NUXT_STATIC_IMPORT_YAML = `project:
  name: recipe-nuxt
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: static
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-nuxt-static`;
