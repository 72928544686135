import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefFormFieldModule, ZefFormModule } from '@zerops/zef/forms/modules';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ProjectCostLimitEditComponent } from './project-cost-limit-edit.component';

@NgModule({
  declarations: [ ProjectCostLimitEditComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSliderModule,
    MatButtonModule,
    MatTooltipModule,
    ZefProgressErrorModule,
    ZefFormModule,
    ZefFormFieldModule,
    ZefFormsModule
  ],
  exports: [ ProjectCostLimitEditComponent ]
})
export class ProjectCostLimitEditModule { }
