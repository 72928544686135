import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { EffectsModule } from '@ngrx/effects';
import { CopyCodeDialogContainer } from './copy-code-dialog.container';
import { CopyCodeDialogEffect } from './copy-code-dialog.effect';

@NgModule({
  declarations: [ CopyCodeDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    EffectsModule.forFeature([ CopyCodeDialogEffect ]),
    ZefDialogModule,
    CodeFieldModule
  ],
  exports: [ CopyCodeDialogContainer ]
})
export class CopyCodeDialogModule {

}
