import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { NewServiceDialogContainer } from './new-service-dialog.container';
import { NewServiceDialogEffect } from './new-service-dialog.effect';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ReadyToDeployModule } from '@zerops/zerops/feature/ready-to-deploy';

@NgModule({
  declarations: [ NewServiceDialogContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ NewServiceDialogEffect ]),
    ZefDialogModule,
    ZuiSeparatorComponent,
    ReadyToDeployModule,
    ZefScrollbarComponent
  ],
  exports: [ NewServiceDialogContainer ]
})
export class NewServiceDialogModule {

}
