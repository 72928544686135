import { Subscription } from '@zerops/models/subscription';
import { Addon, MetricIds } from '@zerops/models/billing';
import { Project } from '@zerops/models/project';
import { GroupedAddon } from '@zerops/models/billing';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';

export const groupAddons = (
  subscriptions: Subscription[],
  availableAddons: Addon[],
  projects: Project[],
  projectId?: string,
  onlyGroupsWithActiveAddon = false
) => {
  const data = [ ...subscriptions || [], ...availableAddons || [] ];
  const uniqueMetricIds = Object.keys(groupBy(data, (itm) => itm.metricId === MetricIds.ProjectCore
  ? itm.metricId + '_' + itm.metricVariant
  : itm.metricId
));
  const arr = [];
  const cmpr = (d: Addon | Subscription, key: string) => d.metricId === MetricIds.ProjectCore
    ? (d.metricId + '_' + d.metricVariant) === key
    : d.metricId === key;

  uniqueMetricIds.forEach((key) => {
    if (!arr[key]) {
      arr.push({
        metricId: key,
        subscriptions: orderBy(subscriptions?.filter((d) => cmpr(d, key)).map((dd) => {
          const project = projects?.find((p) => p.id === dd.projectId);
          return {
            ...dd,
            projectName: project?.name
          };
        }), 'projectName'),
        availableAddons: orderBy(availableAddons?.filter((d) => cmpr(d, key)).map((dd) => {
          const project = projects?.find((p) => p.id === dd.projectId);
          return {
            ...dd,
            projectName: project?.name
          };
        }), 'projectName')
      });
    }
  });

  if (projectId) {
    return orderBy(arr.map((group) => ({
      ...group,
      availableAddons: group.availableAddons?.filter((addon: any) => addon.projectId === projectId),
      subscriptions: group.subscriptions?.filter((subscription: any) => subscription.projectId === projectId)
    })), 'metricId') as GroupedAddon[];
  }

  return orderBy(
    onlyGroupsWithActiveAddon
      ? arr.filter((d) => d.subscriptions?.length).map((d) => ({ ...d, availableAddons: [] }))
      : arr, 'metricId'
  ) as GroupedAddon[];
}

export const filterCoreProjects = (data: GroupedAddon[]) => {
  const coreMetrics = [
    MetricIds.ProjectCoreLegacy,
    MetricIds.ProjectCoreLight,
    MetricIds.ProjectCoreSerious
  ];

  const coreItems = data.filter((item) => coreMetrics.includes(item.metricId as MetricIds));
  const activeCore = coreItems.find((item) => item.subscriptions.length > 0);
  const nonCoreItems = data.filter((item) => !coreMetrics.includes(item.metricId as MetricIds));

  return activeCore
    ? [ activeCore, ...nonCoreItems ]
    : nonCoreItems;
}
