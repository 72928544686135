import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import {
  PublicIpTypes,
  FirewallPolicies,
  IPV4_BASE_RANGE,
  IPV6_BASE_RANGE,
  PortsWithServiceStackInfo
} from '@zerops/models/port-routing';
import { PortRoutingFormState } from './port-routing-form.form';

enum Modes {
  Add = 'add',
  Edit = 'edit'
}

@Component({
  selector: 'zui-port-routing-form',
  templateUrl: './port-routing-form.component.html',
  styleUrls: [ './port-routing-form.component.scss' ]
})
export class PortRoutingFormComponent extends ZefFormBase<PortRoutingFormState> {

  // # Data
  // -- angular

  @Input()
  portsWithServiceStackInfo: PortsWithServiceStackInfo[][];

  @Input()
  mode: Modes = Modes.Add;

  @Output()
  removeFirewallIpRange = new EventEmitter<number>();

  // -- sync
  publicIpTypes = PublicIpTypes;
  firewallPolicies = FirewallPolicies;
  modes = Modes;
  ipV4BaseRange = IPV4_BASE_RANGE;
  ipV6BaseRange = IPV6_BASE_RANGE;

  trackBy(index: number) {
    return index;
  }

  compareFn(c1: { port: number; serviceStackId: string; }, c2: { port: number; serviceStackId: string; }): boolean {
    return c1 && c2 && c1.port === c2.port && c1.serviceStackId === c2.serviceStackId;
  }

}
