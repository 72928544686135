import { Component, ChangeDetectionStrategy, ViewEncapsulation, HostBinding, Input } from '@angular/core';
import { StatusIconBase } from './status-icon-base.base';

@Component({
  selector: 'zui-status-icon-base',
  templateUrl: './status-icon-base.component.html',
  styleUrls: [ './status-icon-base.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StatusIconBaseComponent extends StatusIconBase {

  @Input()
  pulsing = false;

  @Input()
  rippling = false;

  @HostBinding('class.has-text')
  get classHasText() {
    return !!this.statusText;
  }
}
