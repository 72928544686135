import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { zefLoginSuccess } from '@zerops/zef/auth';
import { ZefSnackService } from '@zerops/zef/snack';
import { of } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  filter,
  tap,
  switchMap
} from 'rxjs/operators';
import {
  loadUser,
  loadUserSuccess,
  loadUserFail,
  setUserId,
  storeUserData,
  loadStoredUserData,
  loadStoredUserDataSuccess,
  clearUserData,
  setUserAvatar,
  setUserAvatarFail,
  setUserAvatarSuccess,
  removeUserAvatar,
  removeUserAvatarSuccess,
  removeUserAvatarFail,
  storeUserDataSuccess
} from './user-base.action';
import { UserEntity } from './user-base.entity';
import { UserBaseApi } from './user-base.api';
import { UserDataStorageService } from './user-base.utils';
import { FEATURE_NAME } from './user-base.constant';

@Injectable()
export class UserBaseEffect {
  private _onLoadUserSuccess$ = this._actions$.pipe(ofType(loadUserSuccess));

  private _onLoadUser$ = createEffect(() => this._actions$.pipe(
    ofType(loadUser),
    mergeMap((action) => this._api
      .load$()
      .pipe(
        map((response) => loadUserSuccess(response, action, action.meta)),
        catchError((err) => of(loadUserFail(err, action)))
      )
    )
  ));

  private _onLoadUserSuccessAddToCache$ = createEffect(() => this._onLoadUserSuccess$.pipe(
    map(({ data }) => this._userEntity.addToCache([ data ]))
  ));

  private _onLoadUserSuccessStorage$ = createEffect(() => this._onLoadUserSuccess$.pipe(
    filter(({ meta }) => meta === zefLoginSuccess.type),
    map(({ data }) => setUserId(data.id))
  ));

  private _onStoreUserData$ = createEffect(() => this._actions$.pipe(
    ofType(storeUserData),
    tap(({ clientUserId, userId }) => this._userDataStorage.setData({
      clientUserId,
      userId
    })),
    map(({ clientUserId, userId, refresh }) => storeUserDataSuccess(userId, clientUserId, refresh))
  ));

  private _onLoadStoredUserData$ = createEffect(() => this._actions$.pipe(
    ofType(loadStoredUserData),
    map(() => this._userDataStorage.getData()),
    filter((d) => !!d),
    map(({ clientUserId, userId }) => loadStoredUserDataSuccess(userId, clientUserId))
  ));

  private _onClearUserData$ = createEffect(() => this._actions$.pipe(
    ofType(clearUserData),
    tap(() => this._userDataStorage.removeData())
  ), { dispatch: false });

  private _onSetAvatar$ = createEffect(() => this._actions$.pipe(
    ofType(setUserAvatar),
    switchMap((action) => this._api
      .setAvatar$(action.data)
      .pipe(
        map((res) => setUserAvatarSuccess(res, action)),
        catchError((err) => of(setUserAvatarFail(err, action)))
      )
    )
  ));

  private _onSetAvatarSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(setUserAvatarSuccess),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.setAvatarSuccess` }))
  ), { dispatch: false });

  private _onRemoveAvatar$ = createEffect(() => this._actions$.pipe(
    ofType(removeUserAvatar),
    switchMap((action) => this._api
      .removeAvatar$()
      .pipe(
        map((res) => removeUserAvatarSuccess(res, action)),
        catchError((err) => of(removeUserAvatarFail(err, action)))
      )
    )
  ));

  private _onRemoveAvatarSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(removeUserAvatarSuccess),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.removeAvatarSuccess` }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions<any>,
    private _api: UserBaseApi,
    private _userEntity: UserEntity,
    private _userDataStorage: UserDataStorageService,
    private _snack: ZefSnackService
  ) { }
}
