import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SatPopover } from '@zerops/zef/popover';

@Component({
  selector: 'z-user-data-edit-pop',
  templateUrl: './user-data-edit-pop.component.html',
  styleUrls: [ './user-data-edit-pop.component.scss' ]
})
export class UserDataEditPopComponent {

  @Input()
  key: string;

  @Input()
  saveVariableTranslation: string;

  @Input()
  popState: string;

  @Input()
  formGroup: UntypedFormGroup;

  @Output()
  setActiveId = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<{ key: string; content: string; }>();

  @Output()
  closeTrigger = new EventEmitter<void>();

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  @ViewChild('ngFormRef')
  ngFormRef: FormGroupDirective;

}
