<zef-dialog
  [open]="state.open"
  [options]="{ disableClose: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">

  <div
    class="__wrap"
    [class.is-expanded]="state.expanded">

    <zef-scrollbar class="__scroll" maxHeight="calc(100vh - 325px)" showScrollButtonsV>
      <ng-container *zefMessageFormat="let mf">

        <!-- http routing -->
        <div
          class="__item"
          [class.is-expanded]="state.expanded"
          *ngIf="state.serviceStack?.hasUnsyncedPublicHttpRoutingRecord || state.otherStacksUnsyncedHttpRoutings?.length">

          <span class="__title" [class.is-expanded]="state.expanded" [innerHTML]="state.translations?.httpRoutingTitle"></span>

          <!-- expanded version -->
          <ng-container *ngIf="state.expanded">
            <ng-container *ngIf="state.unsyncedHttpRoutings?.length">

              <span class="__title is-subtitle">{{ state.translations?.currentService }}</span>

              <zui-http-routing-item
                #httpRoutingItemRef
                *ngFor="let item of state.unsyncedHttpRoutings; let last = last; let index = index; trackBy: trackByIndex"
                class="__expanded-item"
                [class.is-last]="last"
                hideOutOfSync
                [data]="item"
                [stackLocations]="item._stackLocations"
                [showOtherLocations]="true"
                [otherLocations]="item._otherLocations"
                [translations]="state.httpRoutingItemTranslations">
              </zui-http-routing-item>

            </ng-container>

            <ng-container *ngIf="state.otherStacksUnsyncedHttpRoutings?.length">

              <span
                class="__title is-subtitle"
                [class.has-margin]="state.unsyncedHttpRoutings?.length">
                {{ state.translations?.otherServices }}
              </span>

              <zui-http-routing-item
                #httpOtherRoutingItemRef
                *ngFor="let item of state.otherStacksUnsyncedHttpRoutings; let last = last; let index = index; trackBy: trackByIndex;"
                class="__expanded-item"
                [class.is-last]="last"
                hideOutOfSync
                [data]="item"
                [stackLocations]="item._stackLocations"
                [showOtherLocations]="true"
                [otherLocations]="item._otherLocations"
                [translations]="state.httpRoutingItemTranslations">
              </zui-http-routing-item>

            </ng-container>
          </ng-container>

          <div>

            <zef-progress-error
              [key]="syncHttpRoutingKey"
              [loading]="state.httpRoutingSyncProcessRunning"
              class="__button"
              [class.is-expanded]="state.expanded">
              <button
                [class]="!state.expanded ? 'c-button--smaller' : undefined"
                (click)="onSyncHttpRouting$.next()"
                color="accent"
                mat-raised-button
                type="submit"
                [disabled]="state.serviceStack?.status !== serviceStackStatuses.New
                  && state.serviceStack?.status !== serviceStackStatuses.Active
                  && state.serviceStack?.status !== serviceStackStatuses.Stopped">
                  <ng-container *ngIf="state.allUnsyncedHttpRoutings?.length; else countlessChangesTextRef">
                    {{
                      mf(state.translations?.publishHttpChanges, {
                        num: state.allUnsyncedHttpRoutings?.length
                      })
                    }}
                    {{
                      mf(state.translations?.publishOnServices, {
                        num: state.serviceStacksContainingUnsyncedHttpRoutingsCount
                      })
                    }}
                  </ng-container>
              </button>
            </zef-progress-error>

            <button
              class="c-button--routing"
              [class.c-button--smaller]="!state.expanded"
              *ngIf="!state.expanded"
              [routerLink]="[ '/', 'service-stack', state.serviceStack?.id, 'routing' ]"
              (click)="onClose$.next()"
              type="button"
              mat-stroked-button>

              {{ state.translations?.viewDetails }}

              <mat-icon>navigate_next</mat-icon>

            </button>

          </div>

        </div>

        <zui-separator
          *ngIf="state.expanded && (state.serviceStack?.hasUnsyncedPublicPortRecord
            || state.otherStacksUnsyncedPortRoutings?.ipv4?.length
            || state.otherStacksUnsyncedPortRoutings?.ipv6?.length)
              && (state.serviceStack?.hasUnsyncedPublicHttpRoutingRecord || state.otherStacksUnsyncedHttpRoutings?.length)"
          [spacing]="'mini'"
          class="__sep">
        </zui-separator>

        <!-- port routing -->
        <div
          class="__item"
          [class.is-expanded]="state.expanded"
          *ngIf="state.serviceStack?.hasUnsyncedPublicPortRecord
            || state.otherStacksUnsyncedPortRoutings?.ipv4?.length
            || state.otherStacksUnsyncedPortRoutings?.ipv6?.length">

            <span class="__title" [class.is-expanded]="state.expanded" [innerHTML]="state.translations?.portRoutingTitle"></span>

            <!-- expanded version -->
            <ng-container *ngIf="state.expanded">
              <ng-container *ngIf="state.unsyncedPortRoutings?.ipv4?.length || state.unsyncedPortRoutings?.ipv6?.length">

                <span class="__title is-subtitle">{{ state.translations?.currentService }}</span>

                <zui-port-routing-item
                  *ngFor="let item of state.unsyncedPortRoutings?.ipv4; let last = last; trackBy: trackByIndex;"
                  class="__expanded-item"
                  [class.is-last]="last"
                  [data]="item"
                  [ip]="state.project?.publicIpV4"
                  [type]="publicIpTypes.IpV4"
                  [translations]="state.portRoutingItemTranslations"
                  disableEdit>
                </zui-port-routing-item>

                <zui-port-routing-item
                  *ngFor="let item of state.unsyncedPortRoutings?.ipv6; let last = last; trackBy: trackByIndex;"
                  class="__expanded-item"
                  [class.is-last]="last"
                  [data]="item"
                  [ip]="state.project?.publicIpV6"
                  [type]="publicIpTypes.IpV6"
                  [translations]="state.portRoutingItemTranslations"
                  disableEdit>
                </zui-port-routing-item>

              </ng-container>

              <ng-container *ngIf="state.otherStacksUnsyncedPortRoutings?.ipv4?.length || state.otherStacksUnsyncedPortRoutings?.ipv6?.length">

                <span
                  class="__title is-subtitle"
                  [class.has-margin]="state.unsyncedPortRoutings?.ipv4?.length || state.unsyncedPortRoutings?.ipv6?.length">
                  {{ state.translations?.otherServices }}
                </span>

                <zui-port-routing-item
                  *ngFor="let item of state.otherStacksUnsyncedPortRoutings?.ipv4; let last = last; trackBy: trackByIndex;"
                  class="__expanded-item"
                  [class.is-last]="last"
                  [data]="item"
                  [ip]="state.project?.publicIpV4"
                  [type]="publicIpTypes.IpV4"
                  [translations]="state.portRoutingItemTranslations"
                  disableEdit>
                </zui-port-routing-item>

                <zui-port-routing-item
                  *ngFor="let item of state.otherStacksUnsyncedPortRoutings?.ipv6; let last = last; trackBy: trackByIndex;"
                  class="__expanded-item"
                  [class.is-last]="last"
                  [data]="item"
                  [ip]="state.project?.publicIpV6"
                  [type]="publicIpTypes.IpV6"
                  [translations]="state.portRoutingItemTranslations"
                  disableEdit>
                </zui-port-routing-item>

              </ng-container>
            </ng-container>

            <div>

              <zef-progress-error
                [key]="syncPortRoutingKey"
                [loading]="state.portRoutingSyncProcessRunning"
                class="__button"
                [class.is-expanded]="state.expanded">
                <button
                  [class]="!state.expanded ? 'c-button--smaller' : undefined"
                  (click)="onSyncPortRouting$.next()"
                  color="accent"
                  mat-raised-button
                  type="submit"
                  [disabled]="state.serviceStack?.status !== serviceStackStatuses.New
                    && state.serviceStack?.status !== serviceStackStatuses.Active
                    && state.serviceStack?.status !== serviceStackStatuses.Stopped">
                    <ng-container *ngIf="state.allUnsyncedPortRoutings?.length; else countlessChangesTextRef">
                      {{
                        mf(state.translations?.publishPortChanges, {
                          num: state.allUnsyncedPortRoutings?.length
                        })
                      }}
                      {{
                        mf(state.translations?.publishOnServices, {
                          num: state.serviceStacksContainingUnsyncedPortRoutingsCount
                        })
                      }}
                    </ng-container>
                </button>
              </zef-progress-error>

              <button
                class="c-button--routing"
                [class.c-button--smaller]="!state.expanded"
                *ngIf="!state.expanded"
                [routerLink]="[ '/', 'service-stack', state.serviceStack.id, 'routing' ]"
                (click)="onClose$.next()"
                type="button"
                mat-stroked-button>

                {{ state.translations?.viewDetails }}

                <mat-icon>navigate_next</mat-icon>

              </button>

            </div>

        </div>

        <zui-separator
          *ngIf="state.expanded && state.serviceStack?.hasUnsyncedUserDataRecord
            && ((state.serviceStack?.hasUnsyncedPublicHttpRoutingRecord || state.otherStacksUnsyncedHttpRoutings?.length)
              || (state.serviceStack?.hasUnsyncedPublicPortRecord
                || state.otherStacksUnsyncedPortRoutings?.ipv4?.length
                || state.otherStacksUnsyncedPortRoutings?.ipv6?.length))"
          [spacing]="'mini'"
          class="__sep">
        </zui-separator>

        <!-- user data -->
        <div
          class="__item"
          [class.is-expanded]="state.expanded"
          *ngIf="state.serviceStack?.hasUnsyncedUserDataRecord">

          <span class="__title" [class.is-expanded]="state.expanded" [innerHTML]="state.translations?.userDataTitle"></span>

          <!-- expanded version -->
          <ng-container *ngIf="state.expanded">

            <ng-container *ngIf="state.unsyncedUserData?.length">

              <span class="__title is-subtitle">{{ state.translations?.currentService }}</span>

              <z-user-data-item
                *ngFor="let item of state.unsyncedUserData; let last = last; trackBy: trackByIndex;"
                class="__expanded-item"
                [data]="item"
                [serviceStackStatus]="state.serviceStack?.status"
                [translations]="state.userDataItemTranslations">
              </z-user-data-item>

            </ng-container>

            <ng-container *ngIf="state.otherStacksUnsyncedUserData?.length">

              <span
                class="__title is-subtitle"
                [class.has-margin]="state.unsyncedUserData?.length">
                {{ state.translations?.otherServices }}
              </span>

              <z-user-data-item
                *ngFor="let item of state.otherStacksUnsyncedUserData ; let last = last; trackBy: trackByIndex;"
                class="__expanded-item"
                [data]="item"
                [serviceStackStatus]="state.serviceStack?.status"
                [translations]="state.userDataItemTranslations">
              </z-user-data-item>

            </ng-container>

          </ng-container>

          <div>

            <zef-progress-error
              [key]="syncUserDataKey"
              [loading]="state.userDataSyncProcessRunning"
              class="__button"
              [class.is-expanded]="state.expanded">
              <button
                [class]="!state.expanded ? 'c-button--smaller' : undefined"
                (click)="onSyncUserData$.next()"
                color="accent"
                mat-raised-button
                type="submit"
                [disabled]="state.serviceStack?.status !== serviceStackStatuses.New
                  && state.serviceStack?.status !== serviceStackStatuses.Active
                  && state.serviceStack?.status !== serviceStackStatuses.Stopped">
                  {{
                    mf(state.translations?.synchronizeUserData, {
                      num: state.unsyncedUserData?.length + state.otherStacksUnsyncedUserData?.length
                    })
                  }}
              </button>
            </zef-progress-error>

            <button
              class="c-button--routing"
              [class.c-button--smaller]="!state.expanded"
              *ngIf="!state.expanded"
              [routerLink]="[ '/', 'service-stack', state.serviceStack?.id, 'user-data' ]"
              (click)="onClose$.next()"
              type="button"
              mat-stroked-button>

              {{ state.translations?.viewDetails }}

              <mat-icon>navigate_next</mat-icon>

            </button>

          </div>

        </div>

      </ng-container>
    </zef-scrollbar>

  </div>

  <div class="__actions">
    <button
      mat-button
      type="button"
      (click)="onClose$.next()">
      Close
    </button>
  </div>

</zef-dialog>

<ng-template #countlessChangesTextRef>
  {{ state.translations?.applyChangesCountless }}
</ng-template>