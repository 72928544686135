import { createAction, union } from '@ngrx/store';

export const portRoutingEditDialogOpen = createAction(
  '[@zerops/zerops/feature/port-routing-edit-dialog] open',
  (id: string) => ({ id })
);

const all = union({ portRoutingEditDialogOpen });

export type PortRoutingEditDialogActionUnion = typeof all;
