import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticsGroupBy } from '@zerops/models/resource-statistics';
import { ResourceStatisticsBaseState } from './resource-statistics-base.model';
import { FEATURE_NAME } from './resource-statistics-base.constant';

export const selectResourceStatisticsBaseState = createFeatureSelector<ResourceStatisticsBaseState>(FEATURE_NAME);

export const selectCurrentServiceStackStatisticsBaseServiceStackMap = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.currentServiceStackMap
);

export const selectCurrentContainerResourceStatisticsBaseServiceStackMap = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.currentContainerMap
);

export const selectCurrentCostMap = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.currentCostMap
);

export const selectCurrentProjectCostMap = createSelector(
  selectCurrentCostMap,
  (s) => s.projects
);

export const selectCurrentServicesCostMap = createSelector(
  selectCurrentCostMap,
  (s) => s.services
);

export const selectCurrentServicesResourcesCostMap = createSelector(
  selectCurrentCostMap,
  (s) => s.serviceResources
);

export const selectCurrentClientCost = createSelector(
  selectCurrentCostMap,
  (s) => s.client
);

export const selectResourceHistoryData = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.historyData
);

export const selectServiceResourceHistoryStatisticsMap = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.historyData[StatisticsGroupBy.Service]
);

export const selectContainerResourceHistoryStatisticsMap = createSelector(
  selectResourceStatisticsBaseState,
  (s) => s.historyData[StatisticsGroupBy.Container]
);
