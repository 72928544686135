import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import {
  ServiceStack,
  ServiceStackAutoscaling,
  ServiceStackBackup,
  ServiceStackModes,
  ServiceStackPort,
  ServiceStackUpdateData
} from '@zerops/models/service-stack';
import { Process } from '@zerops/models/process';
import { createAction, union } from '@ngrx/store';
import { ServiceStackOperationTypes } from './service-stack-base.constant';

// -- add
export const addServiceStack = createAction(
  '[@zerops/zerops/service-stack-base] add-service',
  // meta: { quickadd: true } in case of quick add
  zefActionPayload<any>()
);

export const addServiceStackFail = createAction(
  '[@zerops/zerops/service-stack-base] add-service/fail',
  zefActionFailPayload
);

export const addServiceStackSuccess = createAction(
  '[@zerops/zerops/service-stack-base] add-service/success',
  zefActionSuccessPayload<ServiceStack & { process: Process; }>()
);

// -- enable subdomain access
export const enableSubdomainAccess = createAction(
  '[@zerops/zerops/service-stack-base] enable-subdomain-access',
  zefActionPayload<{ id: string; }>()
);

export const enableSubdomainAccessFail = createAction(
  '[@zerops/zerops/service-stack-base] enable-subdomain-access/fail',
  zefActionFailPayload
);

export const enableSubdomainAccessSuccess = createAction(
  '[@zerops/zerops/service-stack-base] enable-subdomain-access/success',
  zefActionSuccessPayload<Process>()
);

// -- disable subdomain access
export const disableSubdomainAccess = createAction(
  '[@zerops/zerops/service-stack-base] disable-subdomain-access',
  zefActionPayload<{ id: string; }>()
);

export const disableSubdomainAccessFail = createAction(
  '[@zerops/zerops/service-stack-base] disable-subdomain-access/fail',
  zefActionFailPayload
);

export const disableSubdomainAccessSuccess = createAction(
  '[@zerops/zerops/service-stack-base] disable-subdomain-access/success',
  zefActionSuccessPayload<Process>()
);

// -- get a container file, like the Nginx config, through the file browser api
export const getContainerFile = createAction(
  '[@zerops/zerops/service-stack-base] get-container-file',
  zefActionPayload<{ serviceStackId: string; url: string; containerId: string; filePath: string }>()
);

export const getContainerFileFail = createAction(
  '[@zerops/zerops/service-stack-base] get-container-file/fail',
  zefActionFailPayload
);

export const getContainerFileSuccess = createAction(
  '[@zerops/zerops/service-stack-base] get-container-file/success',
  zefActionSuccessPayload<string>()
);

// -- start
export const serviceStackStart = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-start',
  zefActionPayload<{
    id: string;
    type: ServiceStackOperationTypes;
  }>()
);

export const serviceStackStartFail = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-start/fail',
  zefActionFailPayload
);

export const serviceStackStartSuccess = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-start/success',
  zefActionSuccessPayload<Process>()
);

// -- stop
export const serviceStackStop = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-stop',
  zefActionPayload<{
    id: string;
    type: ServiceStackOperationTypes;
  }>()
);

export const serviceStackStopFail = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-stop/fail',
  zefActionFailPayload
);

export const serviceStackStopSuccess = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-stop/success',
  zefActionSuccessPayload<Process>()
);

// -- restart
export const serviceStackRestart = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-restart',
  zefActionPayload<{
    id: string;
    type: ServiceStackOperationTypes;
  }>()
);

export const serviceStackRestartFail = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-restart/fail',
  zefActionFailPayload
);

export const serviceStackRestartSuccess = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-restart/success',
  zefActionSuccessPayload<Process>()
);

// -- reload
export const serviceStackReload = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-reload',
  zefActionPayload<{
    id: string;
    type: ServiceStackOperationTypes;
  }>()
);

export const serviceStackReloadFail = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-reload/fail',
  zefActionFailPayload
);

export const serviceStackReloadSuccess = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-reload/success',
  zefActionSuccessPayload<Process>()
);

//  -- modify internal ports
export const serviceStackModifyInternalPorts = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-modify-internal-ports',
  zefActionPayload<{ serviceStackId: string; ports: ServiceStackPort[]; id?: string }>()
);

export const serviceStackModifyInternalPortsFail = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-modify-internal-ports/fail',
  zefActionFailPayload
);

export const serviceStackModifyInternalPortsSuccess = createAction(
  '[@zerops/zerops/service-stack-base] service-stack-modify-internal-ports/success',
  zefActionSuccessPayload<Process>()
);

// -- connect / disconnect shared storage
export const connectDisconnectSharedStorage = createAction(
  '[@zerops/zerops/service-stack-base] connect-disconnect-shared-storage',
  zefActionPayload<{ stackId: string; sharedStorageId: string; type: 'connect' | 'disconnect' }>()
);

export const connectDisconnectSharedStorageFail = createAction(
  '[@zerops/zerops/service-stack-base] connect-disconnect-shared-storage/fail',
  zefActionFailPayload
);

export const connectDisconnectSharedStorageSuccess = createAction(
  '[@zerops/zerops/service-stack-base] connect-disconnect-shared-storage/success',
  zefActionSuccessPayload<Process>()
);

// -- external repository integration
export const integrateExternalRepository = createAction(
  '[@zerops/zerops/service-stack-base] integrate-external-repository',
  zefActionPayload<{
    id: string;
    gitLabIntegration?: any;
    gitHubIntegration?: any;
  }>()
);

export const integrateExternalRepositoryFail = createAction(
  '[@zerops/zerops/service-stack-base] integrate-external-repository/fail',
  zefActionFailPayload
);

export const integrateExternalRepositorySuccess = createAction(
  '[@zerops/zerops/service-stack-base] integrate-external-repository/success',
  zefActionSuccessPayload()
);

export const removeExternalRepositoryIntegration = createAction(
  '[@zerops/zerops/service-stack-base] remove-external-repository-integration',
  zefActionPayload<{
    id: string
  }>()
);

// -- remove external repository integration
export const removeExternalRepositoryIntegrationFail = createAction(
  '[@zerops/zerops/service-stack-base] remove-external-repository-integration/fail',
  zefActionFailPayload
);

export const removeExternalRepositoryIntegrationSuccess = createAction(
  '[@zerops/zerops/service-stack-base] remove-external-repository-integration/success',
  zefActionSuccessPayload()
);

//  -- update object storage settings
export const updateObjectStorageSettings = createAction(
  '[@zerops/zerops/service-stack-base] update-object-storage-settings',
  zefActionPayload<{ id: string; diskGBytes: number; policy?: string; rawPolicy?: string; }>()
);

export const updateObjectStorageSettingsFail = createAction(
  '[@zerops/zerops/service-stack-base] update-object-storage-settings/fail',
  zefActionFailPayload
);

export const updateObjectStorageSettingsSuccess = createAction(
  '[@zerops/zerops/service-stack-base] update-object-storage-settings/success',
  zefActionSuccessPayload<Process>()
);

// -- stack update
export const updateServiceStack = createAction(
  '[@zerops/zerops/service-stack-base] update-service-stack',
  zefActionPayload<{ id: string; typeVersion: string; data: ServiceStackUpdateData }>()
);

export const updateServiceStackFail = createAction(
  '[@zerops/zerops/service-stack-base] update-service-stack/fail',
  zefActionFailPayload
);

export const updateServiceStackSuccess = createAction(
  '[@zerops/zerops/service-stack-base] update-service-stack/success',
  zefActionSuccessPayload<Process>()
);

export const serviceStackExport = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-export',
  zefActionPayload<string>()
);

export const serviceStackExportFail = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-export/fail',
  zefActionFailPayload
);

export const serviceStackExportSuccess = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-export/success',
  zefActionSuccessPayload<string>()
);

export const serviceStackImport = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-import',
  zefActionPayload<{ yaml: string; projectId: string; }>()
);

export const serviceStackImportFail = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-import/fail',
  zefActionFailPayload
);

export const serviceStackImportSuccess = createAction(
  '[@zerops/zerops/core/service-stack-base] service-stack-import/success',
  zefActionSuccessPayload()
);

// -- adjust autoscaling
export const adjustServiceStackAutoscaling = createAction(
  '[@zerops/zerops/service-stack-base] adjust-service-stack-autoscaling',
  zefActionPayload<{ serviceStackId: string; mode: ServiceStackModes; customAutoscaling: ServiceStackAutoscaling }>()
);

export const adjustServiceStackAutoscalingFail = createAction(
  '[@zerops/zerops/service-stack-base] adjust-service-stack-autoscaling/fail',
  zefActionFailPayload
);

export const adjustServiceStackAutoscalingSuccess = createAction(
  '[@zerops/zerops/service-stack-base] adjust-service-stack-autoscaling/success',
  zefActionSuccessPayload<{ process: Process }>()
);

// -- clear build cache
export const deleteBuildCache = createAction(
  '[@zerops/zerops/service-stack-base] delete-build-cache',
  zefActionPayload<{ id: string; }>()
);

export const deleteBuildCacheFail = createAction(
  '[@zerops/zerops/service-stack-base] delete-build-cache/fail',
  zefActionFailPayload
);

export const deleteBuildCacheSuccess = createAction(
  '[@zerops/zerops/service-stack-base] delete-build-cache/success',
  zefActionSuccessPayload()
);

// -- clear runtime prepare cache
export const deleteRuntimePrepareCache = createAction(
  '[@zerops/zerops/service-stack-base] delete-runtime-prepare-cache',
  zefActionPayload<{ id: string; }>()
);

export const deleteRuntimePrepareCacheFail = createAction(
  '[@zerops/zerops/service-stack-base] delete-runtime-prepare-cache/fail',
  zefActionFailPayload
);

export const deleteRuntimePrepareCacheSuccess = createAction(
  '[@zerops/zerops/service-stack-base] delete-runtime-prepare-cache/success',
  zefActionSuccessPayload()
);

// -- get list of backups
export const getBackups = createAction(
  '[@zerops/zerops/service-stack-base] get-backups',
  zefActionPayload<{ id: string; }>()
);

export const getBackupsFail = createAction(
  '[@zerops/zerops/service-stack-base] get-backups/fail',
  zefActionFailPayload
);

export const getBackupsSuccess = createAction(
  '[@zerops/zerops/service-stack-base] get-backups/success',
  zefActionSuccessPayload<ServiceStackBackup[]>()
);

// -- create backup
export const createBackup = createAction(
  '[@zerops/zerops/service-stack-base] create-backup',
  zefActionPayload<{ id: string; }>()
);

export const createBackupFail = createAction(
  '[@zerops/zerops/service-stack-base] create-backup/fail',
  zefActionFailPayload
);

export const createBackupSuccess = createAction(
  '[@zerops/zerops/service-stack-base] create-backup/success',
  zefActionSuccessPayload()
);

// -- download backup
export const backupDownload = createAction(
  '[@zerops/zerops/service-stack-base] backup-download',
  zefActionPayload<{
    id: string;
    serviceId: string;
    filename: string;
  }>()
);

export const backupDownloadFail = createAction(
  '[@zerops/zerops/service-stack-base] backup-download/fail',
  zefActionFailPayload
);

export const backupDownloadSuccess = createAction(
  '[@zerops/zerops/service-stack-base] backup-download/success',
  zefActionSuccessPayload()
);

// -- set backup frequency
export const setBackupFrequency = createAction(
  '[@zerops/zerops/service-stack-base] set-backup-frequency',
  zefActionPayload<{
    id?: string;
    frequency: string;
    serviceId: string;
  }>()
);

export const setBackupFrequencyFail = createAction(
  '[@zerops/zerops/service-stack-base] set-backup-frequency/fail',
  zefActionFailPayload
);

export const setBackupFrequencySuccess = createAction(
  '[@zerops/zerops/service-stack-base] set-backup-frequency/success',
  zefActionSuccessPayload()
);

// -- delete backup
export const deleteBackup = createAction(
  '[@zerops/zerops/service-stack-base] delete-backup',
  zefActionPayload<{
    id: string;
    serviceId: string;
  }>()
);

export const deleteBackupFail = createAction(
  '[@zerops/zerops/service-stack-base] delete-backup/fail',
  zefActionFailPayload
);

export const deleteBackupSuccess = createAction(
  '[@zerops/zerops/service-stack-base] delete-backup/success',
  zefActionSuccessPayload<{ serviceId: string; id: string; }>()
);

const all = union({
  addServiceStack,
  addServiceStackFail,
  addServiceStackSuccess,
  enableSubdomainAccess,
  enableSubdomainAccessFail,
  enableSubdomainAccessSuccess,
  disableSubdomainAccess,
  disableSubdomainAccessFail,
  disableSubdomainAccessSuccess,
  serviceStackStart,
  serviceStackStartFail,
  serviceStackStartSuccess,
  serviceStackStop,
  serviceStackStopFail,
  serviceStackStopSuccess,
  serviceStackRestart,
  serviceStackRestartFail,
  serviceStackRestartSuccess,
  serviceStackReload,
  serviceStackReloadFail,
  serviceStackReloadSuccess,
  getContainerFile,
  getContainerFileFail,
  getContainerFileSuccess,
  serviceStackModifyInternalPorts,
  serviceStackModifyInternalPortsSuccess,
  serviceStackModifyInternalPortsFail,
  connectDisconnectSharedStorage,
  connectDisconnectSharedStorageFail,
  connectDisconnectSharedStorageSuccess,
  integrateExternalRepository,
  integrateExternalRepositoryFail,
  integrateExternalRepositorySuccess,
  removeExternalRepositoryIntegration,
  removeExternalRepositoryIntegrationFail,
  removeExternalRepositoryIntegrationSuccess,
  serviceStackExport,
  serviceStackExportSuccess,
  serviceStackExportFail,
  serviceStackImport,
  serviceStackImportSuccess,
  serviceStackImportFail,
  deleteBuildCache,
  deleteBuildCacheSuccess,
  deleteBuildCacheFail,
  deleteRuntimePrepareCache,
  deleteRuntimePrepareCacheSuccess,
  deleteRuntimePrepareCacheFail,
  getBackups,
  getBackupsFail,
  getBackupsSuccess,
  createBackup,
  createBackupFail,
  createBackupSuccess,
  backupDownload,
  backupDownloadFail,
  backupDownloadSuccess,
  setBackupFrequency,
  setBackupFrequencyFail,
  setBackupFrequencySuccess,
  deleteBackup,
  deleteBackupFail,
  deleteBackupSuccess
});
export type ServiceStackBaseActionUnion = typeof all;
