export type Levels = 'client' | 'project' | 'service';

export class ProcessesPopState {
  level: Levels;
  id?: string;
}

export interface ProcessesPopOpenPayload {
  level: Levels;
  id?: string;
}
