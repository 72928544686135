import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ProjectStatus } from '@zerops/models/project';
// import { GeneralTranslations } from '@zerops/zerops/app';
import { HashMap } from '@zerops/zef/core';


@Component({
  selector: 'zui-project-basic-info',
  templateUrl: './project-basic-info.component.html',
  styleUrls: [ './project-basic-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectBasicInfoComponent {

  @Input()
  status: ProjectStatus;

  @Input()
  name: string;

  @Input()
  description: string;

  @Input()
  tags: string[];

  @Input()
  ipV4: string;

  @Input()
  ipV6: string;

  @Input()
  searchMatches: any[];

  @Input()
  generalTranslations: any; // GeneralTranslations;

  @Input()
  routerParams: HashMap<any>;

  @Input()
  activeTagFilter: string;

  @Input()
  nameSize: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  set disableLinks(v: string | boolean) {
    this._disableLinks = coerceBooleanProperty(v);
  }
  get disableLinks(): boolean {
    return this._disableLinks;
  }

  private _disableLinks = false;

  trackByIndex(index: number) {
    return index;
  }

}
