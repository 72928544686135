import { NgModule } from '@angular/core';
import { CommonStorageServiceStackFormComponent } from './common-storage-service-stack-form.component';

@NgModule({
    declarations: [CommonStorageServiceStackFormComponent],
    exports: [CommonStorageServiceStackFormComponent]
})
export class CommonStorageServiceStackFormModule {

}
