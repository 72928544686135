import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ConfirmPopModule } from '@zerops/zui/confirm-pop';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { TranslocoModule } from '@ngneat/transloco';
import { PaymentSourcesComponent } from './payment-sources.component';

@NgModule({
  declarations: [ PaymentSourcesComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    ConfirmPopModule,
    TranslocoModule,
    DynamicPopAnchorModule
  ],
  exports: [ PaymentSourcesComponent ]
})
export class ZuiPaymentSourcesModule { }
