import { createAction, union } from '@ngrx/store';
import {
  ClientAccountInfoUpdateDto,
  ClientBillingInfoResponse,
  ClientBillingInfoUpdateDto
} from '@zerops/models/client';
import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';


export const billingInfo = createAction(
  '[@zerops/zerops/core/client-base] billing-info',
  zefActionPayload()
);

export const billingInfoFail = createAction(
  '[@zerops/zerops/core/client-base] billing-info/fail',
  zefActionFailPayload
);

export const billingInfoSuccess = createAction(
  '[@zerops/zerops/core/client-base] billing-info/success',
  zefActionSuccessPayload<ClientBillingInfoResponse>()
);

export const updateBillingInfo = createAction(
  '[@zerops/zerops/core/client-base] update-billing-info',
  zefActionPayload<ClientBillingInfoUpdateDto>()
);

export const updateBillingInfoFail = createAction(
  '[@zerops/zerops/core/client-base] update-billing-info/fail',
  zefActionFailPayload
);

export const updateBillingInfoSuccess = createAction(
  '[@zerops/zerops/core/client-base] update-billing-info/success',
  zefActionSuccessPayload<ClientBillingInfoResponse>()
);

export const updateAccountInfo = createAction(
  '[@zerops/zerops/core/client-base] update-account-info',
  zefActionPayload<ClientAccountInfoUpdateDto>()
);

export const updateAccountInfoFail = createAction(
  '[@zerops/zerops/core/client-base] update-account-info/fail',
  zefActionFailPayload
);

export const updateAccountInfoSuccess = createAction(
  '[@zerops/zerops/core/client-base] update-account-info/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

export const setClientAvatar = createAction(
  '[@zerops/zerops/client-base] set-avatar',
  (file: File) => zefActionPayload<File>(file)
);

export const setClientAvatarFail = createAction(
  '[@zerops/zerops/client-base] set-avatar/fail',
  zefActionFailPayload
);

export const setClientAvatarSuccess = createAction(
  '[@zerops/zerops/client-base] set-avatar/success',
  zefActionSuccessPayload()
);

export const removeClientAvatar = createAction(
  '[@zerops/zerops/client-base] remove-avatar',
  zefActionPayload()
);

export const removeClientAvatarFail = createAction(
  '[@zerops/zerops/client-base] remove-avatar/fail',
  zefActionFailPayload
);

export const removeClientAvatarSuccess = createAction(
  '[@zerops/zerops/client-base] remove-avatar/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

const all = union({
  billingInfo,
  billingInfoFail,
  billingInfoSuccess,
  updateBillingInfo,
  updateBillingInfoFail,
  updateBillingInfoSuccess,
  updateAccountInfo,
  updateAccountInfoFail,
  updateAccountInfoSuccess
});

export type ClientBaseActionUnion = typeof all;
