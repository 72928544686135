import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ClientListItemModule } from '@zerops/zui/client-list-item';
import { ClientUserSelectContainer } from './client-user-select.container';

@NgModule({
  declarations: [ ClientUserSelectContainer ],
  imports: [
    CommonModule,
    MatListModule,
    ClientListItemModule
  ],
  exports: [ ClientUserSelectContainer ]
})
export class ClientUserSelectModule {

}
