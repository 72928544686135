import { Component } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { syncHttpRouting } from '@zerops/zerops/core/http-routing-base';
import { syncPortRouting, getPortRoutingByIpType } from '@zerops/zerops/core/port-routing-base';
import { PortRouting, PublicIpTypes } from '@zerops/models/port-routing';
import { syncUserData } from '@zerops/zerops/core/user-data-base';
import { HttpRoutingWithLocations } from '@zerops/models/http-routing';
import { PortRoutingItemTranslations, PORT_ROUTING_ITEM_FEATURE_NAME } from '@zerops/zui/port-routing-item';
import { ProjectEntity } from '@zerops/zerops/core/project-base';
import { ProcessEntity } from '@zerops/zerops/core/process-base';
import { HttpRoutingItemTranslations, HTTP_ROUTING_ITEM_FEATURE_NAME } from '@zerops/zui/http-routing-item';
import { ProcessStatuses, ProcessActions } from '@zerops/models/process';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { UserDataItemTranslations, USER_DATA_ITEM_FEATURE_NAME } from '@zerops/zerops/common/user-data-item';
import { select, Store } from '@ngrx/store';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { selectEntities } from '@zerops/zef/entities';
import { Subject } from 'rxjs';
import { filter, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './sync-dialog.constant';
import { SyncDialogTranslations } from './sync-dialog.translations';
import { UserData } from '@zerops/models/user-data';
import uniqBy from 'lodash-es/uniqBy';

@Component({
  selector: 'z-sync-dialog',
  templateUrl: './sync-dialog.container.html',
  styleUrls: [ './sync-dialog.container.scss' ]
})
export class SyncDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();
  onSyncHttpRouting$ = new Subject<void>();
  onSyncPortRouting$ = new Subject<void>();
  onSyncUserData$ = new Subject<void>();

  // # Data
  // -- angular

  // -- sync
  serviceStackStatuses = ServiceStackStatuses;
  publicIpTypes = PublicIpTypes;
  syncHttpRoutingKey = syncHttpRouting.type;
  syncPortRoutingKey = syncPortRouting.type;
  syncUserDataKey = syncUserData.type;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  serviceStackId$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta?.serviceStackId as string)
  );
  httpRoutings$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta?.httpRoutings as HttpRoutingWithLocations[])
  );
  portRoutings$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta?.portRoutings as PortRouting[])
  );
  userData$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta?.userData as UserData[])
  );
  expanded$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta?.expanded as boolean)
  );
  serviceStack$ = this.serviceStackId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  project$ = this.serviceStack$.pipe(
    switchMap((stack) => this._projectEntity
      .entityById$(stack.projectId)
      .pipe(filter((d) => !!d))
    )
  );
  allUnsyncedHttpRoutings$ = this.httpRoutings$.pipe(
    filter((d) => !!d),
    map((data) => data.filter((itm) => !itm.isSynced))
  );
  serviceStacksContainingUnsyncedHttpRoutingsCount$ = this.allUnsyncedHttpRoutings$.pipe(
    map((d) => uniqBy(d, 'serviceStackId')?.length)
  );
  unsyncedHttpRoutings$ = this.allUnsyncedHttpRoutings$.pipe(
    map((d) => d.filter((itm) => !!itm._stackLocations.length))
  );
  otherStacksUnsyncedHttpRoutings$ = this.allUnsyncedHttpRoutings$.pipe(map((data) => data.filter((itm) => !itm._stackLocations.length)));
  allUnsyncedPortRoutings$ = this.portRoutings$.pipe(
    filter((d) => !!d),
    // TODO FIXME TEMP HACK remove once #18418 done
    withLatestFrom(this._store.pipe(
      select(selectEntities),
      map((ent) => ent[ApiEntityKeys.ServiceStack]),
    )),
    map(([ d, entMap ]) => d.filter((itm) => !itm.isSynced).reduce((arr, itm) => {
      arr.push({
        ...itm,
        serviceStackName: entMap[itm.serviceStackId]?.name
      });
      return arr;
    }, [] as PortRouting[]))
  );
  serviceStacksContainingUnsyncedPortRoutingsCount$ = this.allUnsyncedPortRoutings$.pipe(
    map((d) => uniqBy(d, 'serviceStackId')?.length)
  );
  unsyncedPortRoutings$ = this.allUnsyncedPortRoutings$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ d, id ]) => getPortRoutingByIpType(d.filter((itm) => itm.serviceStackId === id)))
  );
  otherStacksUnsyncedPortRoutings$ = this.allUnsyncedPortRoutings$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ data , stackId ]) => getPortRoutingByIpType(data.filter((itm) => itm.serviceStackId !== stackId)))
  );
  allUnsyncedUserData$ = this.userData$.pipe(
    filter((d) => !!d),
    map((data) => data.filter((itm) => !itm.isSynced))
  );
  unsyncedUserData$ = this.allUnsyncedUserData$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ d, id ]) => d.filter((itm) => itm.serviceStackId === id))
  );
  otherStacksUnsyncedUserData$ = this.allUnsyncedUserData$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ d, id ]) => d.filter((itm) => itm.serviceStackId !== id))
  );
  translations$ = this.translate$<SyncDialogTranslations>(FEATURE_NAME);
  portRoutingItemTranslations$ = this.translate$<PortRoutingItemTranslations>(PORT_ROUTING_ITEM_FEATURE_NAME);
  httpRoutingItemTranslations$ = this.translate$<HttpRoutingItemTranslations>(HTTP_ROUTING_ITEM_FEATURE_NAME);
  userDataItemTranslations$ = this.translate$<UserDataItemTranslations>(USER_DATA_ITEM_FEATURE_NAME);
  runningProcesses$ = this._processEntity.list$().pipe(
    withLatestFrom(this.project$),
    filter(([ d, { id } ]) => d && !!id),
    map(([ d, { id } ]) => d.filter((p) => p.projectId === id && p.status === ProcessStatuses.RUNNING))
  );
  userDataSyncProcessRunning$ = this.runningProcesses$.pipe(
    map((d) => !!d.filter((p) => p.actionName === ProcessActions.stackUpdateUserData).length)
  );
  httpRoutingSyncProcessRunning$ = this.runningProcesses$.pipe(
    map((d) => !!d.filter((p) => p.actionName === ProcessActions.syncHttpRouting).length)
  );
  portRoutingSyncProcessRunning$ = this.runningProcesses$.pipe(
    map((d) => !!d.filter((p) => p.actionName === ProcessActions.syncPortRouting).length)
  );

  // # Action Streams
  private _dialogCloseAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  private _syncHttpRoutingAction$ = this.onSyncHttpRouting$.pipe(
    withLatestFrom(this.serviceStack$),
    map(([ _, { projectId } ]) => syncHttpRouting({ data: projectId,  meta: { global: true } }))
  );
  private _syncPortRoutingAction$ = this.onSyncPortRouting$.pipe(
    withLatestFrom(this.serviceStack$),
    map(([ _, { projectId } ]) => syncPortRouting({ data: projectId,  meta: { global: true } }))
  );
  private _syncUserDataAction$ = this.onSyncUserData$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ _, id ]) => syncUserData({ data: id,  meta: { global: true } }))
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    expanded: this.expanded$,
    serviceStack: this.serviceStack$,
    project: this.project$,
    allUnsyncedHttpRoutings: this.allUnsyncedHttpRoutings$,
    serviceStacksContainingUnsyncedHttpRoutingsCount: this.serviceStacksContainingUnsyncedHttpRoutingsCount$,
    unsyncedHttpRoutings: this.unsyncedHttpRoutings$,
    otherStacksUnsyncedHttpRoutings: this.otherStacksUnsyncedHttpRoutings$,
    allUnsyncedPortRoutings: this.allUnsyncedPortRoutings$,
    serviceStacksContainingUnsyncedPortRoutingsCount: this.serviceStacksContainingUnsyncedPortRoutingsCount$,
    unsyncedPortRoutings: this.unsyncedPortRoutings$,
    otherStacksUnsyncedPortRoutings: this.otherStacksUnsyncedPortRoutings$,
    unsyncedUserData: this.unsyncedUserData$,
    otherStacksUnsyncedUserData: this.otherStacksUnsyncedUserData$,
    translations: this.translations$,
    portRoutingItemTranslations: this.portRoutingItemTranslations$,
    httpRoutingItemTranslations: this.httpRoutingItemTranslations$,
    userDataItemTranslations: this.userDataItemTranslations$,
    userDataSyncProcessRunning: this.userDataSyncProcessRunning$,
    httpRoutingSyncProcessRunning: this.httpRoutingSyncProcessRunning$,
    portRoutingSyncProcessRunning: this.portRoutingSyncProcessRunning$
  });

  constructor(
    private _store: Store<AppState>,
    private _processEntity: ProcessEntity,
    private _serviceStackEntity: ServiceStackEntity,
    private _projectEntity: ProjectEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._dialogCloseAction$,
      this._syncHttpRoutingAction$,
      this._syncPortRoutingAction$,
      this._syncUserDataAction$
    ]);

  }

}
