import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZcliExamplesComponent } from './zcli-examples.component';
import { ZuiDocsTextModule } from '../docs-text';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';

@NgModule({
  declarations: [ ZcliExamplesComponent ],
  imports: [
    CommonModule,
    ZuiDocsTextModule,
    ZefCopyToClipboardModule
  ],
  exports: [ ZcliExamplesComponent ]
})
export class ZuiZcliExamplesModule {

}
