import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './user-data-item.constant';

const en = {
  copyKeyTooltip: 'Click to copy key to clipboard',
  copyContentTooltip: 'Click to copy content to clipboard',
  restrictedVariable: 'Restricted variable, can not be deleted or edited.',
  notSynchronized: 'Not synchronized, commit changes and restart the service'
};

export type UserDataItemTranslations = typeof en;

@NgModule()
export class UserDataItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
