import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { parseISO } from 'date-fns/esm';
import {
  ZefScheduler,
  calculateDurationInSeconds,
  formatDurationFromMinutes
} from '@zerops/zef/core';

@Component({
  selector: 'zui-duration',
  templateUrl: './duration.component.html',
  styleUrls: [ './duration.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurationComponent extends ZefScheduler implements OnDestroy, AfterViewInit {

  @Input()
  useShorthand = false;

  @Input()
  set start(v) {
    if (!!v) {
      this._startIso = parseISO(v);

      this.formatted = this._setFormattedDuration();
      this._start = v;

      this._startTimer();
    }
  }
  get start() {
    return this._start;
  }

  @Input()
  set end(v) {
    this._end = v;

    if (!!v && !!this.start) {
      this.formatted = this._setFormattedDuration(parseISO(v));
      this._stopTimer();
    }
  }
  get end() {
    return this._end;
  }

  formatted: string;
  private _interval: number;
  private _startIso: Date;
  private _start: string;
  private _end: string;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this._startTimer();
  }

  ngOnDestroy() {
    this._stopTimer();
  }

  private _stopTimer() {
    clearInterval(this._interval);
  }

  private _startTimer() {
    if (this.start && !this.end && this._interval === undefined) {
      this._interval = setInterval(() => {
        this.formatted = this._setFormattedDuration();
        this.renderScheduler.schedule();
      }, 1000) as any;
    }
  }

  private _setFormattedDuration(end?: Date) {
    const durationSeconds = calculateDurationInSeconds(this._startIso, end);
    return formatDurationFromMinutes(durationSeconds / 60, this.useShorthand);
  }

}
