export class PortRouting {
  id: string;
  clientId: string;
  projectId: string;
  serviceStackId: string;
  serviceStackName: string;
  publicIpType: PublicIpTypes;
  publicPort: number;
  internalPort: number;
  internalProtocol: string;
  created: string;
  lastUpdate: string;
  isSynced: boolean;
  lastSync: string;
  deleteOnSync: boolean;
  firewallPolicy: FirewallPolicies;
  firewallAllowMyIp: boolean;
  firewallIpRanges: string[];
}

export class PortsWithServiceStackInfo {
  serviceStackName: string;
  serviceStackId: string;
  port: number;
}


export class FirewallIpRange {
  ip: string;
  range: number;
}

export enum PublicIpTypes {
  IpV4 = 'IP_V4',
  IpV6 = 'IP_V6'
}

export enum FirewallPolicies {
  Whitelist = 'WHITELIST',
  Blacklist = 'BLACKLIST'
}

export const IPV4_BASE_RANGE = '32';
export const IPV6_BASE_RANGE = '128';
