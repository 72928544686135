import {
  Component,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ZefFormElementDirective } from '@zerops/zef/forms';
import {
  ObjectStorageServiceStackFormTranslations
} from './object-storage-service-stack-form.translations';
import { ObjecStorageAcls } from '@zerops/models/service-stack';

@Component({
  selector: 'z-object-storage-service-stack-form',
  templateUrl: './object-storage-service-stack-form.component.html',
  styleUrls: [ './object-storage-service-stack-form.component.scss' ]
})
export class ObjectStorageServiceStackFormComponent {
  @Input()
  state: any;

  @Input()
  isZen: boolean;

  @Input()
  translations: ObjectStorageServiceStackFormTranslations;

  @ViewChildren(ZefFormElementDirective)
  formElementsRefs: QueryList<ZefFormElementDirective>;

  acls = ObjecStorageAcls;
  aclItems = [
    {
      id: ObjecStorageAcls.PublicRead,
      name: 'Public read'
    },
    {
      id: ObjecStorageAcls.PublicObjectsRead,
      name: 'Public objects read'
    },
    {
      id: ObjecStorageAcls.PublicReadWrite,
      name: 'Public read write'
    },
    {
      id: ObjecStorageAcls.PublicWrite,
      name: 'Public write'
    },
    {
      id: ObjecStorageAcls.Private,
      name: 'Private'
    },
    {
      id: ObjecStorageAcls.Custom,
      name: 'Custom policy'
    }
  ];

  aclItemsVisible = this.aclItems.filter((itm) => itm.id !== ObjecStorageAcls.Custom);

  aclMap = this.aclItems.reduce((obj, itm) => {
    obj[itm.id] = itm.name;
    return obj;
  }, {});

}
