<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxHeight: '80vh', maxWidth: '660px', width: '80vw' }"
  (closeTrigger)="onClose$.next()">
  @if (state.open) {
    <div class="__content">

      <h3 class="__title">Zerops.yml environment variables as .env</h3>

      <zui-code-field
        class="__code-field"
        [editorOptions]="{
          language: 'ini'
        }"
        [formControl]="formControl">
      </zui-code-field>

      <div class="__buttons">
        <button
          class="__copy-button  c-button--full"
          mat-button
          color="accent"
          type="button"
          (click)="copy()">
          Copy to clipboard
        </button>
      </div>

      <button
        (click)="onClose$.next()"
        class="__close-button c-button--full"
        type="button"
        mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>

    </div>
  }
</zef-dialog>
