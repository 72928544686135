import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { FormControlState } from 'ngrx-forms';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'zui-user-data-fields',
  templateUrl: './user-data-fields.component.html',
  styleUrls: [ './user-data-fields.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataFieldsComponent extends ZefFormBase<any> {
  @Input()
  keyControlState: FormControlState<string>;

  @Input()
  contentControlState: FormControlState<string>;

  @Input()
  keyControl: UntypedFormControl;

  @Input()
  contentControl: UntypedFormControl;
}
