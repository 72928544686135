import { Injectable } from '@angular/core';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { onWebsocketMessageDispatchAddRemoveEntities } from '@zerops/zef/entities';
import { PortRoutingEntity } from '@zerops/zerops/core/port-routing-base';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './port-routing-list.constant';
import { initializePortRoutingList } from './port-routing-list.action';

@Injectable()
export class PortRoutingListEffect {

  private _initializedProjectId$ = this._actions$.pipe(
    ofType(initializePortRoutingList),
    map(({ projectId }) => projectId)
  );

  // port routing list / update
  private _setupPortRoutingListStream$ = createEffect(() => this._initializedProjectId$.pipe(
    withLatestFrom(this._userEntity.activeClientId$),
    map(([ projectId, clientId ]) => this._portRoutingEntity.listSubscribe(
      clientId,
      {
        name: FEATURE_NAME,
        id: projectId
      },
      {
        search: [
          {
            name: 'projectId',
            operator: 'eq',
            value: projectId
          },

          // FIXME TODO filter by servicestackname (https://redmine.vshosting.cz/issues/18418)
          {
            name: 'publicPort',
            operator: 'ne',
            value: 80
          },
          {
            name: 'publicPort',
            operator: 'ne',
            value: 443
          }
        ]
      }
    ))
  ));

  private _onPortRoutingListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._portRoutingEntity,
      FEATURE_NAME,
      undefined,
      this._initializedProjectId$
    )
  ));

  constructor(
    private _actions$: Actions,
    private _userEntity: UserEntity,
    private _portRoutingEntity: PortRoutingEntity
  ) { }

}
