import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationBaseApi {
  constructor(private _http: HttpClient) { }

  markAsRead(id: string) {
    return this._http.put<Notification>(`/api/user-notification/${id}/acknowledge`, {});
  }

  markAllAsRead(serviceStackId?: string, projectId?: string) {
    return this._http.put<any>(`/api/user-notification/acknowledge-all`, {
      serviceStackId: serviceStackId ? serviceStackId : undefined,
      projectId: projectId ? projectId : undefined,
    });
  }
}
