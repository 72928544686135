import { Routes } from '@angular/router';
import { zefPermissionGuardActivation, createRule } from '@zerops/zef/permission';
import { zefNgrxRouterGuardActivation, zefNgrxRouterGuardDeactivation } from '@zerops/zef/ngrx-router';
import { Roles } from './app.permissions';
import { RouteKeys } from './app.constant';

const authorizedRole = (redirect = '/login') => ({
  zefPermissions: createRule(
    { only: [ Roles.Authorized ] },
    [ redirect ]
  )
});

const unauthorizedRole = (redirect = '/dashboard') => ({
  zefPermissions: createRule(
    { only: [ Roles.Unauthorized ] },
    [ redirect ]
  )
});

const ununathorizedIincompleteRole = {
  zefPermissions: createRule(
    { only: [ Roles.Unauthorized, Roles.Incomplete ] },
    [ '/dashboard' ]
  )
};

export const appRoutes: Routes = [
  // login
  {
    path: 'login',
    loadChildren: () => import('../pages/+login/login.module').then(m => m.LoginModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: {
      ...ununathorizedIincompleteRole,
      key: RouteKeys.Login
    }
  },

  // registration
  {
    path: 'registration',
    loadChildren: () => import('../pages/+registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [ zefPermissionGuardActivation ],
    data: {
      ...unauthorizedRole(),
      key: RouteKeys.Registration
    }
  },

  // invitation
  {
    path: 'invitation',
    loadChildren: () => import('../pages/+invitation/invitation.module').then(m => m.InvitationModule),
    data: {
      key: RouteKeys.Invitation
    }
  },

  // whitepaper
  {
    path: 'whitepaper',
    loadChildren: () => import('../pages/+whitepaper/whitepaper.module').then(m => m.WhitepaperPageModule),
    data: {
      key: RouteKeys.Whitepaper
    }
  },

  // recipe
  {
    path: 'recipe',
    loadChildren: () => import('../pages/+recipe/recipe.module').then(m => m.RecipePageModule),
    data: {
      key: RouteKeys.Recipe
    }
  },

  // github auth
  {
    path: 'github-auth',
    loadChildren: () => import('../pages/+github-auth/github-auth.module').then(m => m.GithubAuthModule),
    canActivate: [ zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      key: RouteKeys.GithubAuth
    }
  },

  // gitlab auth
  {
    path: 'gitlab-auth',
    loadChildren: () => import('../pages/+gitlab-auth/gitlab-auth.module').then(m => m.GitlabAuthModule),
    canActivate: [ zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      key: RouteKeys.GithubAuth
    }
  },

  // forgotten password
  {
    path: 'forgotten-password',
    loadChildren: () => import('../pages/+forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule),
    data: {
      key: RouteKeys.ForgottenPassword
    }
  },

  // password recovery
  {
    path: 'password-recovery',
    loadChildren: () => import('../pages/+password-recovery/password-recovery.module').then(m => m.PasswordRecoveryModule),
    data: {
      key: RouteKeys.PasswordRecovery
    }
  },

  // dashboard
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/+dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ zefPermissionGuardActivation, zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.Dashboard
    }
  },

  // project
  {
    path: 'project',
    loadChildren: () => import('../pages/+project-detail/project-detail.module').then(m => m.ProjectDetailModule),
    canActivate: [ zefPermissionGuardActivation, zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.ProjectDetail
    }
  },

  // service stack
  {
    path: 'service-stack',
    loadChildren: () => import('../pages/+service-stack-detail/service-stack-detail.module').then(m => m.ServiceStackDetailModule),
    canActivate: [ zefPermissionGuardActivation, zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.ServiceStackDetail
    }
  },

  // settings
  {
    path: 'settings',
    loadChildren: () => import('../pages/+settings/settings.module').then(m => m.SettingsModule),
    canActivate: [ zefPermissionGuardActivation, zefNgrxRouterGuardActivation ],
    canDeactivate: [ zefNgrxRouterGuardDeactivation ],
    data: {
      ...authorizedRole(),
      key: RouteKeys.Settings
    }
  },

  // login to Zerops
  {
    path: 'authorize',
    loadChildren: () => import('../pages/+login-to-zerops/login-to-zerops.module').then(m => m.LoginToZeropsModule),
    data: {
      key: RouteKeys.LoginToZerops
    }
  },

  // default route handler
  {
    path: '**',
    redirectTo: '/dashboard/projects'
  }
];
