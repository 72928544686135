import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ZefError } from '@zerops/zef/errors';
import { zefLogout } from '@zerops/zef/auth';
import { loadUser } from '@zerops/zerops/core/user-base';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'z-dialog-error',
  templateUrl: './dialog-error.container.html',
  styleUrls: [ './dialog-error.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogErrorContainer extends ZefReactiveComponent {
  // # Event Streams
  onLogout$ = new Subject<void>();

  // # Data
  // -- sync
  userLoadKey = loadUser.type;

  // -- angular
  @Input()
  data: ZefError[];

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(
    map(() => zefLogout())
  );

  constructor() {
    super();

    this.$dispatchActions([ this._logoutAction$ ]);
  }
}
