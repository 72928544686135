import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ProjectStatusBaseEffect } from './project-status-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ProjectStatusBaseEffect ])
  ]
})
export class ProjectStatusBaseModule {

}
