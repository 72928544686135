import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SatPopoverModule } from '@zerops/zef/popover';
import { RoutingLocationItemModule } from '@zerops/zui/routing-location-item';
import { ZuiPublicRoutingContentModule } from '@zerops/zui/public-routing-content';
import { TranslocoModule } from '@ngneat/transloco';
import { WebPublicRoutingPopContainer } from './web-public-routing-pop.container';
import { WebPublicRoutingPopTranslationsModule } from './web-public-routing-pop.translations';

@NgModule({
  declarations: [ WebPublicRoutingPopContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    MatButtonModule,
    MatCardModule,
    SatPopoverModule,
    RoutingLocationItemModule,
    ZuiPublicRoutingContentModule,
    WebPublicRoutingPopTranslationsModule
  ],
  exports: [ WebPublicRoutingPopContainer ]
})
export class WebPublicRoutingPopModule { }
