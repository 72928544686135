import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './project-cost-limit-dialog.constant';
import { ProjectCostLimitDialogContainer } from './project-cost-limit-dialog.container';
import { ProjectCostLimitDialogEffect } from './project-cost-limit-dialog.effect';
import { projectCostLimitDialogReducer } from './project-cost-limit-dialog.reducer';
import { ProjectCostLimitEditModule, ProjectCostLimitInfoModule } from './modules';

@NgModule({
  declarations: [ ProjectCostLimitDialogContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    EffectsModule.forFeature([ ProjectCostLimitDialogEffect ]),
    StoreModule.forFeature(
      FEATURE_NAME,
      projectCostLimitDialogReducer
    ),
    ZefProgressErrorModule,
    ZefDialogModule,
    FormSectionModule,
    ZuiSeparatorComponent,
    ProjectCostLimitInfoModule,
    ProjectCostLimitEditModule,
    ProgressSpinnerModule,
    ZefScrollbarComponent
  ],
  exports: [ ProjectCostLimitDialogContainer ]
})
export class ProjectCostLimitDialogModule { }
