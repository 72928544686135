import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef
} from '@angular/core';
import { ProjectStatus } from '@zerops/models/project';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
// import { GeneralTranslations } from '@zerops/zerops/app';

enum Modes {
  Project = 'project',
  ServiceStack = 'serviceStack'
}

@Component({
  selector: 'zui-basic-info-header',
  templateUrl: './basic-info-header.component.html',
  styleUrls: [ './basic-info-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicInfoHeaderPaneComponent {

  modes = Modes;

  @Input()
  mode: Modes = Modes.ServiceStack;

  @Input()
  name: string;

  @Input()
  nameEllipsisSize = 25;

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  status: ServiceStackStatuses | ProjectStatus;

  @Input()
  iconStatus = false;

  @Input()
  customIconSize: number;

  @Input()
  prefixRef: TemplateRef<any>;

  @Input()
  generalTranslations: any; // GeneralTranslations;

  @Input()
  searchMatches: any[];

  statusIconSize = {
    small: 8,
    medium: 10,
    large: 10
  };

}
