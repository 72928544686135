import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { DiskCapacityFieldComponent } from './disk-capacity-field.component';

@NgModule({
  declarations: [ DiskCapacityFieldComponent ],
  imports: [
    CommonModule,
    MatSliderModule
  ],
  exports: [ DiskCapacityFieldComponent ]
})
export class DiskCapacityFieldModule {

}
