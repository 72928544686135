import { createAction } from '@ngrx/store';

export const zefStateSwitcherSetState = createAction(
  '[@zerops/zef/state-switcher] set-state',
  (
    key: string,
    state: string,
  ) => ({ key, state })
);

export type ZefStateSwitcherActionUnion = typeof zefStateSwitcherSetState;
