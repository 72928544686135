export const DOTNET_IMPORT_YAML = `project:
  name: recipe-dotnet
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: dotnet@6
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-dotnet

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
