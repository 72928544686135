import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState } from 'ngrx-forms';
import { FEATURE_NAME } from './project-env-edit-form.constant';

export interface ProjectEnvEditFormValue {
  key: string;
  content: string;
  id: string;
}

export type ProjectEnvEditFormState = FormState<ProjectEnvEditFormValue>;

@Injectable({ providedIn: 'root' })
export class ProjectEnvEditForm extends ZefForm<ProjectEnvEditFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        key: '',
        content: '',
        id: ''
      },
      {}
    );
  }
}
