import { Component } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { ProjectEnvEditFormState } from './project-env-edit-form.form';

@Component({
  selector: 'zui-project-env-edit-form',
  templateUrl: './project-env-edit-form.component.html',
  styleUrls: [ './project-env-edit-form.component.scss' ],
})
export class ProjectEnvEditFormComponent extends ZefFormBase<ProjectEnvEditFormState> {
}
