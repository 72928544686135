import { createAction, union } from '@ngrx/store';
import { ServiceStack } from '@zerops/models/service-stack';

export const newServiceDialogOpen = createAction(
  '[@zerops/zerops/feature/new-service-dialog] open',
  (serviceStack: ServiceStack) => (serviceStack)
);

const all = union({ newServiceDialogOpen });

export type NewServiceDialogActionUnion = typeof all;
