import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './state-switcher.constant';
import { stateSwitcherReducer } from './state-switcher.reducer';
import { StateSwitcherContainer } from './state-switcher.container';
import { ZefStateSwitcherStateModule } from './modules';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, stateSwitcherReducer)
  ]
})
export class ZefStateSwitcherRootModule { }

@NgModule({
  declarations: [ StateSwitcherContainer ],
  imports: [
    CommonModule,
    ZefStateSwitcherStateModule
  ],
  exports: [
    StateSwitcherContainer,
    ZefStateSwitcherStateModule
  ]
})
export class ZefStateSwitcherModule {
  static forRoot(): ModuleWithProviders<ZefStateSwitcherRootModule> {
    return {
      ngModule: ZefStateSwitcherRootModule
    };
  }
}
