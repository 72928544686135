import { Component } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState, GeneralTranslations } from '@zerops/zerops/app';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { selectCountries } from '@zerops/zerops/core/settings-base';
import { BillingInfoForm } from '@zerops/zerops/common/billing-info-form';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './billing-info-edit-dialog.constant';
import { updateBillingInfo } from '@zerops/zerops/core/client-base';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { ThemeService } from '@zerops/zef/theme';

@Component({
  selector: 'z-billing-info-edit-dialog',
  templateUrl: './billing-info-edit-dialog.container.html',
  styleUrls: [ './billing-info-edit-dialog.container.scss' ]
})
export class BillingInfoEditDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();
  onEdit$ = new Subject<void>();

  // # Forms
  formState$ = this._billingInfoForm.state$;

  // # Data
  // -- sync
  key = FEATURE_NAME;
  billingInfoUpdateKey = updateBillingInfo.type;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  isFirstAddition$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.meta?.firstAddition)
  );
  generalTranslations$ = this.translate$<GeneralTranslations>('general');
  countryList$ = this._store.pipe(select(selectCountries));
  clientUser$ = this._userEntity.activeClientUser$;

  // -- angular

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );
  private _editAction$ = this.onEdit$.pipe(
    withLatestFrom(this.formState$.pipe(map((s) => s.value)),),
    map(([ _, formValues ]) => updateBillingInfo({ data: formValues, meta: FEATURE_NAME }))
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    generalTranslations: this.generalTranslations$,
    countryList: this.countryList$,
    formState: this.formState$,
    clientUser: this.clientUser$,
    isFirstAddition: this.isFirstAddition$,
    isDarkTheme: this._theme.isDarkMode$
  });

  constructor(
    private _store: Store<AppState>,
    private _billingInfoForm: BillingInfoForm,
    private _userEntity: UserEntity,
    private _theme: ThemeService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._closeAction$,
      this._editAction$
    ]);
  }

}
