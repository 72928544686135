import { createAction, union } from '@ngrx/store';
import { Process } from '@zerops/models/process';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';

export const syncPortRouting = createAction(
  '[@zerops/zerops/core/port-routing-base] sync',
  zefActionPayload<string, { global: boolean }>()
);

export const syncPortRoutingFail = createAction(
  '[@zerops/zerops/core/port-routing-base] sync/fail',
  zefActionFailPayload
);

export const syncPortRoutingSuccess = createAction(
  '[@zerops/zerops/core/port-routing-base] sync/success',
  zefActionSuccessPayload<Process>()
);

const all = union({
  syncPortRouting,
  syncPortRoutingFail,
  syncPortRoutingSuccess
});

export type PortRoutingBaseActionUnion = typeof all;
