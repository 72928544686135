import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslocoModule } from '@ngneat/transloco';
import { SnackSuccessContainer } from './snack-success.container';

@NgModule({
  declarations: [ SnackSuccessContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule
  ],
  exports: [ SnackSuccessContainer ]
})
export class SnackSuccessModule {

}
