import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefFuseModule } from '@zerops/zef/fuse';
import { ZefAutocompleteTagModule } from '@zerops/zef/forms/modules';
import { GitlabRepoFormContainer } from './gitlab-repo-form.container';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ GitlabRepoFormContainer ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    ZefProgressErrorModule,
    ZefFormsModule,
    ZefAutocompleteTagModule,
    MatCardModule,
    SatPopoverModule,
    ZefFuseModule,
    ZuiSeparatorComponent,
    ZefPipesModule,
    ZefScrollbarComponent
  ],
  exports: [ GitlabRepoFormContainer ]
})
export class GitlabRepoFormModule {

}
