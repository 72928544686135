import { NgModule } from '@angular/core';
import { ToggleContentComponent } from './toggle-content.component';
import { ToggleContentItemDirective } from './toggle-content-item.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ToggleContentComponent,
    ToggleContentItemDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToggleContentComponent,
    ToggleContentItemDirective
  ]
})
export class ToggleContentModule {

}
