import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { AppVersion } from '@zerops/models/app-version';
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base';
import { of, Subject, timer } from 'rxjs';
import { delayWhen, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FEATURE_NAME } from './pipeline-detail-dialog.constant';
import { PipelineDetailModes } from '@zerops/zerops/feature/pipeline-detail';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'z-pipeline-detail-dialog',
  templateUrl: './pipeline-detail-dialog.container.html',
  styleUrls: [ './pipeline-detail-dialog.container.scss' ]
})
export class PipelineDetailDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  defaultMode: PipelineDetailModes = 'PIPELINE';

  // -- async
  dialog$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialog$.pipe(
    map((data) => data.state),
    tap((d) => d ? this.defaultMode = 'PIPELINE' : undefined)
  );
  data$ = this.dialog$.pipe(
    map((data) => data.meta as { appVersion: AppVersion; pipelineErrors: PipelineError[] })
  );
  appVersion$ = this.data$.pipe(
    // let dialog close without data disappearing
    delayWhen((d) => !!d?.appVersion ? timer(0) : timer(400)),
    switchMap((d) => (d?.appVersion
      ? this._appVersionEntity
        .entityById$(d.appVersion.id)
        .pipe(
          // take live app version if it exists
          map((appVersion) => appVersion ? appVersion : d.appVersion),
          takeUntil(this.open$.pipe(filter((d) => !d)))
        )
      : of(d?.appVersion)
    ))
  );
  pipelineErrors$ = this.data$.pipe(
    delayWhen((d) => !!d?.pipelineErrors ? timer(0) : timer(400)),
    map((d) => d?.pipelineErrors)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    appVersion: this.appVersion$,
    pipelineErrors: this.pipelineErrors$
  });

  // # Action Stream
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _appVersionEntity: AppVersionEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._closeAction$
    ]);
  }

}
