import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import {
  RxVirtualFor,
  RxVirtualScrollViewportComponent,
  DynamicSizeVirtualScrollStrategy,
  RxVirtualScrollElementDirective
} from '@rx-angular/template/experimental/virtual-scrolling';
import { TrlogFeature } from './trlog.feature';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ButtonProgressModule } from '@zerops/zef/progress';
import { ZuiThreeDotsModule } from '@zerops/zui/three-dots';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatetimepickerModule } from '@zerops/zef/datetimepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ZefFormFieldModule } from '@zerops/zef/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ZefFormModule } from '@zerops/zef/forms/modules';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ TrlogFeature ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    SatPopoverModule,
    ZefFormModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    ZuiSeparatorComponent,
    RxVirtualFor,
    ActionListItemModule,
    RxVirtualScrollElementDirective,
    RxVirtualScrollViewportComponent,
    DynamicSizeVirtualScrollStrategy,
    ButtonProgressModule,
    ZuiThreeDotsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    ZefFormFieldModule,
    ZefScrollbarComponent
  ],
  exports: [ TrlogFeature ]
})
export class TrlogModule {

}
