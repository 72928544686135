import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { ZuiThreeDotsModule } from '@zerops/zui/three-dots';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { GithubRepoFormModule } from '@zerops/zerops/feature/github-repo-form';
import { GitlabRepoFormModule } from '@zerops/zerops/feature/gitlab-repo-form';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { RemoteRepositoryConnectorFeature } from './remote-repository-connector.feature';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ RemoteRepositoryConnectorFeature ],
  imports: [
    CommonModule,
    MaterialStackModule,
    GithubRepoFormModule,
    GitlabRepoFormModule,
    ZefProgressErrorModule,
    ZuiSeparatorComponent,
    ZuiMoreButtonModule,
    SatPopoverModule,
    MatCardModule,
    MatListModule,
    ZefPipesModule,
    ActionListItemModule,
    ZuiThreeDotsModule
  ],
  exports: [ RemoteRepositoryConnectorFeature ]
})
export class RemoteRepositoryConnectorModule {

}
