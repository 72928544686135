import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiDocsPopComponent } from './docs-pop.component';

@NgModule({
  declarations: [ ZuiDocsPopComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    ZefDialogModule
  ],
  exports: [ ZuiDocsPopComponent ]
})
export class ZuiDocsPopModule {

}
