import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HashMap } from '@zerops/zef/core';
import { ServiceStackTypeVersion } from '@zerops/models/service-stack';
// explicit import because of circular dependency
import {
  FORBIDDEN_STACK_IDS,
  NOT_IMPORTANT_STACK_IDS,
  FORBIDDEN_STACK_CATEGORIES,
  BUILD_STACK_CATEGORY,
  SERVICE_STACK_NAME_TEXT_MAP
} from '@zerops/zerops/core/service-stack-base/service-stack-base.constant';
import { Country, Currency, ServiceStackTypeVersionItem } from '@zerops/models/settings';
import { SettingsBaseState } from './settings-base.model';
import { FEATURE_NAME } from './settings-base.constant';

export const selectSettingsBaseState = createFeatureSelector<SettingsBaseState>(FEATURE_NAME);

export const selectLanguages = createSelector(
  selectSettingsBaseState,
  (state) => state.languageList
);

export const selectCountries = createSelector(
  selectSettingsBaseState,
  (state) => state.countryList
);

export const selectCountryMap = createSelector(
  selectCountries,
  (countryList) => countryList?.reduce((obj, itm) => {
    obj[itm.id] = itm;
    return obj;
  }, {} as HashMap<Country>)
);

export const selectCurrencies = createSelector(
  selectSettingsBaseState,
  (state) => state.currencyList
);

export const selectServiceStackTypes = createSelector(
  selectSettingsBaseState,
  (state) => state.serviceStackList.filter((d) => !FORBIDDEN_STACK_IDS.includes(d.id)
    && !FORBIDDEN_STACK_CATEGORIES.includes(d.category)
  ).map((d) => ({ ...d, name: SERVICE_STACK_NAME_TEXT_MAP[d.id] ? SERVICE_STACK_NAME_TEXT_MAP[d.id] : d.name }))
);

export const selectImportantServiceStackTypes = createSelector(
  selectSettingsBaseState,
  (state) => state.serviceStackList.filter((d) => !FORBIDDEN_STACK_IDS.includes(d.id)
    && !FORBIDDEN_STACK_CATEGORIES.includes(d.category)
    && !NOT_IMPORTANT_STACK_IDS.includes(d.id as any)
  )
);

export const selectBuildServiceStackTypes = createSelector(
  selectSettingsBaseState,
  (state) => state.serviceStackList.filter((d) => d.category === BUILD_STACK_CATEGORY)
);

export const selectServiceStackTypesVersionsMap = createSelector(
  selectSettingsBaseState,
  (state) => {

    const dataMap = state.serviceStackList.reduce((obj, itm) => {
      obj.map[itm.id] = itm;

      obj.data = [
        ...obj.data,
        ...itm.serviceStackTypeVersionList
      ];
      return obj;
    }, {
      map: {},
      data: [] as ServiceStackTypeVersion[]
    });

    return dataMap.data.reduce((obj, serviceStackTypeVersion) => {
      const serviceStackType = dataMap.map[serviceStackTypeVersion.serviceStackTypeId];
      const serviceStackTypeName = serviceStackType.name;
      const serviceStackTypeVersionName = serviceStackTypeVersion.versionNumber;

      obj[serviceStackTypeVersion.id] = {
        name: `${SERVICE_STACK_NAME_TEXT_MAP[serviceStackType?.id]
          ? SERVICE_STACK_NAME_TEXT_MAP[serviceStackType?.id]
          : serviceStackTypeName} ${serviceStackTypeVersionName}`,
        serviceStackTypeName,
        serviceStackTypeVersionName,
        serviceStackType,
        serviceStackTypeVersion
      };

      return obj;
    }, {}) as HashMap<ServiceStackTypeVersionItem>;

  }
);

export const selectCurrencyMap = createSelector(
  selectCurrencies,
  (currencies) => currencies.reduce((obj, curr) => {
    obj[curr.id] = curr;
    return obj;
  }, {}) as HashMap<Currency>
);

export const selectCurrencyById = (id: string) => createSelector(
  selectCurrencyMap,
  (currencyMap) => currencyMap[id]
);

export const selectActiveCurrency = createSelector(
  selectSettingsBaseState,
  (state) => state.activeCurrency
);
