export const QWIK_NODEJS_IMPORT_YAML = `project:
  name: recipe-qwik
  tags:
    - zerops-recipe

services:
  - hostname: ssrapp
    type: nodejs@20
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-qwik-nodejs`;
