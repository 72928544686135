import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { Country, Currency, Language, Enum } from '@zerops/models/settings';
import { ServiceStackType } from '@zerops/models/service-stack';

export const loadSettings = createAction(
  '[@zerops/zerops/core/settings-base] load-settings',
  zefActionPayload()
);

export const loadSettingsFail = createAction(
  '[@zerops/zerops/core/settings-base] load-settings/fail',
  zefActionFailPayload
);

export const loadSettingsSuccess = createAction(
  '[@zerops/zerops/core/settings-base] load-settings/success',
  zefActionSuccessPayload<{
    countryList: Country[],
    currencyList: Currency[],
    languageList: Language[],
    enumList: Enum[],
    serviceStackList: ServiceStackType[]
  }>()
);

export const loadCountryList = createAction(
  '[@zerops/zerops/core/settings-base] load-country-list',
  zefActionPayload()
);

export const loadCountryListFail = createAction(
  '[@zerops/zerops/core/settings-base] load-country-list/fail',
  zefActionFailPayload
);

export const loadCountryListSuccess = createAction(
  '[@zerops/zerops/core/settings-base] load-country-list/success',
  zefActionSuccessPayload<{
    list: Country[]
  }>()
);

const all = union({
  loadSettings,
  loadSettingsFail,
  loadSettingsSuccess,
  loadCountryList,
  loadCountryListFail,
  loadCountryListSuccess
});

export type SettingsBaseActionUnion = typeof all;
