<zef-dialog
  [open]="state.open"
  [options]="{ restoreFocus: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar maxHeight="80vh">
      <zui-form-section
        class="__form-section"
        separator="none">

        <span *zuiFormSectionTitle>
          Update public access <br/> through {{ state.ip }}<strong>:{{ state.activePublicPort }}</strong>
        </span>

        <div *zuiFormSectionDesc>
          All changes will have to be confirmed afterwards to apply.
        </div>

        <zui-port-routing-form
          class="__form"
          #formRef
          (submit)="onEdit$.next()"
          (removeFirewallIpRange)="onRemoveFirewallIpRange$.next($event)"
          [state]="state.formState"
          [portsWithServiceStackInfo]="state.projectPorts"
          mode="edit">
          @if (state.formState?.controls.firewall.value) {
            <button
              type="button"
              (click)="onAddEmptyFirewallIpRange$.next()"
              mat-button
              class="__new-button  c-button--full">
              {{ state.formState?.controls.firewallIpRanges.value.length ? 'Add another IP address' : 'Add an IP address' }}
            </button>
          }
        </zui-port-routing-form>

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="editKey">
            <button
              [disabled]="!state.formState?.isValid"
              class="c-button--full"
              color="accent"
              mat-raised-button
              [disabled]="state.formState?.controls.publicPort?.value === 80 || state.formState?.controls.publicPort?.value === 443"
              (click)="formRef.triggerSubmit()"
              type="button">
              Update public port access
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>