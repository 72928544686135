import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { StatusIconBase } from '@zerops/zui/status-icon-base';

@Component({
  selector: 'zui-service-stack-status-icon',
  templateUrl: './service-stack-status-icon.component.html',
  styleUrls: [ './service-stack-status-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceStackStatusIconComponent extends StatusIconBase<ServiceStackStatuses> {

  pulsingStatusesMap = {
     [ServiceStackStatuses.Creating]: true,
     [ServiceStackStatuses.Deleting]: true,
     [ServiceStackStatuses.Reloading]: true,
     [ServiceStackStatuses.Restarting]: true,
     [ServiceStackStatuses.Upgrading]: true,
     [ServiceStackStatuses.Stopping]: true,
     [ServiceStackStatuses.Starting]: true,
     [ServiceStackStatuses.Repairing]: true,
     [ServiceStackStatuses.MovingContainer]: true,
     [ServiceStackStatuses.NewBuildPending]: true,
     [ServiceStackStatuses.NewBuildRunning]: true,
     [ServiceStackStatuses.ActiveBuildPending]: true,
     [ServiceStackStatuses.ActiveBuildRunning]: true
    };

  @Input()
  amount: number;

  @Input()
  important: boolean;

  @Input()
  set uploading(v: string | boolean) {
    this._uploading = coerceBooleanProperty(v);
  }
  get uploading(): boolean {
    return this._uploading;
  }

  @HostBinding('class.is-important')
  get isImportantClass() {
    return this.important;
  }

  private _uploading = false;
}
