<div class="__wrap">

  <div
    class="__editor c-code-field-editor"
    #editorContainerRef>
  </div>

  @if (loading()) {
    <mat-spinner
      class="__spinner"
      [diameter]="25">
    </mat-spinner>
  }

</div>
