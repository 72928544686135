import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ReactiveFormsModule } from '@angular/forms';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { ZefFormFieldModule } from '@zerops/zef/forms/modules';
import { DeployShutdownDialogContainer } from './deploy-shutdown-dialog.container';
import { DeployShutdownDialogTranslationsModule } from './deploy-shutdown-dialog.translations';

@NgModule({
  declarations: [ DeployShutdownDialogContainer ],
  imports: [
    CommonModule,
    MaterialFormStackModule,
    ZefFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    ZefDialogModule,
    DeployShutdownDialogTranslationsModule
  ],
  exports: [ DeployShutdownDialogContainer ]
})
export class DeployShutdownDialogModule {

}
