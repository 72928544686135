export class ZefGitlabState {
  repositories: ZefGitlabRepository[];
  authorized: boolean;
  branchMap: Record<string, Array<ZefGitlabBranch>>;
}

export enum ZefGitlabAuthUrlType {
  Registration = 'REGISTRATION',
  Login = 'LOGIN',
  Repository = 'REPOSITORY'
}

export interface ZefGitlabConfig {
  callbackUrl?: string;
  apiUrl?: string;
}

export interface ZefGitlabRepository {
  fullName: string;
  name: string;
  owner: string;
  ownerUrl: string;
  private: boolean;
}

export interface ZefGitlabBranch {
  name: string;
  isDefault: boolean;
}

export interface ZefGitlabAuthUrlResponse {
  gitlabUrl: string;
}

export interface ZefGitlabApiAuthorizePayload {
  state: string;
  code: string;
}

export interface ZefGitlabAuthStateData {
  action: ZefGitlabAuthUrlType;
  key: string;
  nonHaRecipe?: string;
  haRecipe?: string;
}
