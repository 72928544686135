import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { of } from 'rxjs';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { NotificationEntity } from './notification-base.entity';
import {
  markAsRead,
  markAsReadFail,
  markAllAsRead,
  markAllAsReadSuccess,
  markAllAsReadFail,
  markAsReadSuccess
} from './notification-base.action';
import { NotificationBaseApi } from './notification-base.api';
import { UNREAD_NOTIFICATION_LIST_KEY } from './notification-base.constant';

@Injectable()
export class NotificationBaseEffect {

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    withLatestFrom(this._userEntity.activeUser$),
    filter(([ clientId, activeUser ]) => !!clientId && !!activeUser),
    map(([ clientId, { id } ]) => this._notificationEntity.updateSubscribe(clientId, {
      search: [{
        name: 'userId',
        operator: 'eq',
        value: id
      }]
    })
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._notificationEntity)
  ));

  private _setupUnreadListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    withLatestFrom(this._userEntity.activeUser$),
    filter(([ clientId, activeUser ]) => !!clientId && !!activeUser),
    map(([ clientId, activeUser ]) => this._notificationEntity.listSubscribe(
      clientId,
      UNREAD_NOTIFICATION_LIST_KEY,
      {
        search: [
          {
            name: 'acknowledged',
            operator: 'not'
          },
          {
            name: 'userId',
            operator: 'eq',
            value: activeUser.id
          }
        ]
      }
    ))
  ));

  private _setupUnreadListAddRemove$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._notificationEntity,
      UNREAD_NOTIFICATION_LIST_KEY
    )
  ));

  private _onMarkAsRead$ = createEffect(() => this._actions$.pipe(
    ofType(markAsRead),
    mergeMap((action) => this._baseApi
      .markAsRead(action.data.id)
      .pipe(
        map(() => markAsReadSuccess({
          id: action.data.id,
          acknowledged: true
        }, action)),
        catchError((err) => of(markAsReadFail(err, action)))
      )
    )
  ));

  private _onMarkAsReadSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(markAsReadSuccess),
    map(({ data }) => this._notificationEntity.updateCache([ data ]))
  ));

  private _onMarkAllAsRead$ = createEffect(() => this._actions$.pipe(
    ofType(markAllAsRead),
    mergeMap((action) => this._baseApi
      .markAllAsRead(action.data?.serviceStackId, action.data?.projectId)
      .pipe(
        map((res) => markAllAsReadSuccess(res, action)),
        catchError((err) => of(markAllAsReadFail(err, action)))
      )
    )
  ));

  constructor(
    private _actions$: Actions,
    private _notificationEntity: NotificationEntity,
    private _userEntity: UserEntity,
    private _baseApi: NotificationBaseApi
  ) { }
}
