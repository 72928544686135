import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { PublicIpTypes, FirewallPolicies } from '@zerops/models/port-routing';
import { FormState, validate, Boxed, box } from 'ngrx-forms';
import { greaterThan, lessThan, required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './port-routing-form.constant';

export interface PortRoutingFormValue {
  id: string;
  publicIpType: PublicIpTypes.IpV4 | PublicIpTypes.IpV6;
  publicPort: number;
  internalPortData: Boxed<{
    port: number;
    protocol: string;
    serviceStackId: string;
  }>;
  firewallPolicy: FirewallPolicies;
  firewallIpRanges: Array<{
    ip: string;
    range: number;
  }>;
  // firewallAllowMyIp: boolean;
  firewall: boolean;
}

export type PortRoutingFormState = FormState<PortRoutingFormValue>;

@Injectable({ providedIn: 'root' })
export class PortRoutingForm extends ZefForm<PortRoutingFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        id: undefined,
        publicIpType: PublicIpTypes.IpV6,
        internalPortData: box({
          port: undefined,
          protocol: undefined,
          serviceStackId: undefined
        }),
        publicPort: undefined,
        firewallPolicy: FirewallPolicies.Blacklist,
        firewallIpRanges: [
          {
            ip: '',
            range: undefined
          }
        ],
        // firewallAllowMyIp: false,
        firewall: false
      },
      {
        // TODO: type
        publicPort: validate(greaterThan(0), lessThan(65536), required) as any
      }
    );
  }
}
