import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ClientStatusBaseEffect } from './client-status-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ClientStatusBaseEffect ])
  ]
})
export class ClientStatusBaseModule {

}
