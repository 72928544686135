<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'start'"
  [horizontalAlign]="'end'"
  hasBackdrop
  (backdropClicked)="popRef.close(); setActiveId.emit();">
  <mat-card class="__card  z-manual-elevation  c-soft-elevation">
    <mat-action-list class="__more-actions">

      <button
        mat-list-item
        #editDynamicAnchor="dynamicPopAnchor"
        [zuiDynamicPopAnchor]="editAnchor"
        (click)="edit.emit(); popRef.close(); (editAnchor ? editDynamicAnchor.open(false, activeElRef) : undefined)"
        [zefNgrxDialogOpen]="editAnchor ? editKey : undefined"
        type="button"
        [disabled]="loadingsMap[activeUserDataDelayed?.id]
          ? !!loadingsMap[activeUserDataDelayed?.id][editKey]
          : false">
        <zui-progress-spinner
          hideContent
          [isLoading]="loadingsMap[activeUserDataDelayed?.id]
            ? !!loadingsMap[activeUserDataDelayed?.id][editKey]
            : false">
            <zui-action-list-item
              [icon]="'edit'"
              [label]="'general.edit' | transloco">
            </zui-action-list-item>
        </zui-progress-spinner>
      </button>

      <div
        *ngIf="!activeUserDataDelayed?.deleteOnSync"
        [matTooltip]="editableVariableTranslation"
        [matTooltipClass]="'c-zerops-tooltip'"
        [matTooltipDisabled]="activeUserDataDelayed?.type !== userDataType.Editable">
        <button
          [disabled]="activeUserDataDelayed?.type === userDataType.Editable"
          mat-list-item
          (click)="delete.emit(); popRef.close(); setActiveId.emit();"
          type="button"
          [disabled]="loadingsMap[activeUserDataDelayed?.id]
            ? !!loadingsMap[activeUserDataDelayed?.id][deleteKey]
            : false">
          <zui-progress-spinner
            hideContent
            [isLoading]="loadingsMap[activeUserDataDelayed?.id]
              ? !!loadingsMap[activeUserDataDelayed?.id][deleteKey]
              : false">
            <zui-action-list-item
              [icon]="activeUserDataDelayed?.type === userDataType.Editable
                || (loadingsMap[activeUserDataDelayed?.id]
                  && !!loadingsMap[activeUserDataDelayed?.id][deleteKey])
                ? 'lock'
                : 'delete_outline'"
              [label]="'general.delete' | transloco">
            </zui-action-list-item>
          </zui-progress-spinner>
        </button>
      </div>

      <div
        *ngIf="activeUserDataDelayed?.deleteOnSync"
        [matTooltip]="editableVariableTranslation"
        [matTooltipDisabled]="activeUserDataDelayed?.type !== userDataType.Editable">
        <button
          [disabled]="activeUserDataDelayed?.type === userDataType.Editable"
          mat-list-item
          (click)="restore.emit(); popRef.close(); setActiveId.emit()"
          type="button"
          [disabled]="loadingsMap[activeUserDataDelayed?.id]
            ? !!loadingsMap[activeUserDataDelayed?.id][restoreKey]
            : false">
          <zui-progress-spinner
            hideContent
            [isLoading]="loadingsMap[activeUserDataDelayed?.id]
              ? !!loadingsMap[activeUserDataDelayed?.id][restoreKey]
              : false">
            <zui-action-list-item
              [icon]="activeUserDataDelayed?.type === userDataType.Editable
                || (loadingsMap[activeUserDataDelayed?.id]
                  && !!loadingsMap[activeUserDataDelayed?.id][restoreKey])
                ? 'lock'
                : 'restore'"
              [label]="'general.restore' | transloco">
            </zui-action-list-item>
          </zui-progress-spinner>
        </button>
      </div>

    </mat-action-list>
  </mat-card>
</sat-popover>
