import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'zui-process-notification-desc',
  templateUrl: './process-notification-desc.component.html',
  styleUrls: [ './process-notification-desc.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessNotificationDescComponent {

  @Input()
  disableLink = false;

  @Output()
  linkClicked = new EventEmitter<void>();

}
