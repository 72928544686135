import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { GlobalLoadingContainer } from './global-loading.container';

@NgModule({
  declarations: [ GlobalLoadingContainer ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ZuiLogoModule
  ],
  exports: [ GlobalLoadingContainer ]
})
export class GlobalLoadingModule {

}
