import { on, createReducer } from '@ngrx/store';
import { mergePeriodCost } from '@zerops/models/transaction-debit';
import { zefResetState } from '@zerops/zef/core';
import {
  periodCostSuccess,
  trackTransactionDebitGroupItem,
  TransactionDebitBaseActionUnion,
  transactionDebitGroupPrefillData,
  transactionDebitGroupSuccess,
  transactionDebitGroupUpdate
} from './transaction-debit-base.action';
import {
  TransactionDebitBaseState,
  TransactionDebitGroupUpdateSuccessPayload
} from './transaction-debit-base.model';
import {
  getTransactionGroupRangeSubscriptionName,
  transformDebitGroupTransformByMetric
} from './transaction-debit-base.utils';

const initialState = new TransactionDebitBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    transactionDebitGroupPrefillData,
    (s, data): TransactionDebitBaseState => mergeDebitGroup(s, data)),
  on(
    transactionDebitGroupSuccess,
    (s, { data }): TransactionDebitBaseState => mergeDebitGroup(s, data)
  ),
  on(
    transactionDebitGroupUpdate,
    (s, props): TransactionDebitBaseState => mergeDebitGroup(s, props)
  ),
  on(
    trackTransactionDebitGroupItem,
    (s, props): TransactionDebitBaseState => ({
      ...s,
      trackedItems: mergeTrackedItems(s.trackedItems, props.data),
    })
  ),
  on(
    periodCostSuccess,
    (s, { data }): TransactionDebitBaseState => ({
      ...s,
      periodCosts: mergePeriodCost(
        data.client,
        data.project,
        data.stack
      )
    })
  ),
  on(zefResetState, () => initialState)
);

export function transactionDebitBaseReducer(
  state: TransactionDebitBaseState,
  action: TransactionDebitBaseActionUnion
) {
  return actionReducer(state, action);
}

function mergeDebitGroup(s: TransactionDebitBaseState, data: TransactionDebitGroupUpdateSuccessPayload) {
  return {
    ...s,
    data: transformDebitGroupTransformByMetric(data, s.data)
  };
}

function mergeTrackedItems(items: any[], newItem: any) {
  const newItemData = newItem.data;
  const newItemSN = getTransactionGroupRangeSubscriptionName(
    newItemData.groupBy,
    newItemData.groupRange.timeGroupBy,
    newItemData.projectId,
    newItemData.serviceId,
    newItemData.groupRange.key
  );
  const index = items.find((itm) => {
    const itmData = itm.data;

    const itmSN = getTransactionGroupRangeSubscriptionName(
      itmData.groupBy,
      itmData.groupRange.timeGroupBy,
      itmData.projectId,
      itmData.serviceId,
      itmData.groupRange.key
    );

    return itmSN === newItemSN;
  });

  if (index) {
    return items;
  }

  return [
    ...items,
    newItem
  ];
}
