import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { BuildProcessStates, BuildProcessStepState } from './build-state-steps.model';
import { getPipelineState } from './build-state-steps.utils';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-build-state-steps',
  templateUrl: './build-state-steps.component.html',
  styleUrls: [ './build-state-steps.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildStateStepsComponent {

  @Input()
  set appVersion(v) {
    this._appVersion = v;

    if (this._appVersion) {
      this.buildProcessStepsState = getPipelineState(this._appVersion);
    }
  }

  get appVersion() {
    return this._appVersion;
  }

  @Input()
  projectId: string;

  @Input()
  serviceName: string;

  @Input()
  serviceType: string;

  @Input()
  set hideText(v: string | boolean) {
    this._hideText = coerceBooleanProperty(v);
  }
  get hideText(): boolean {
    return this._hideText;
  }

  @Input()
  pipelineErrors: PipelineError[];

  buildProcessStepsState: BuildProcessStepState = {
    INIT_BUILD_CONTAINER: BuildProcessStates.Waiting,
    RUN_BUILD_COMMANDS: BuildProcessStates.Waiting,
    INIT_PREPARE_CONTAINER: BuildProcessStates.Waiting,
    RUN_PREPARE_COMMANDS: BuildProcessStates.Waiting,
    DEPLOY: BuildProcessStates.Waiting
  };

  buildProcessStates = BuildProcessStates;
  appVersionStatuses = AppVersionStatuses;

  private _appVersion: AppVersion;
  private _hideText = false;
}
