import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DnsCheckStatusIconComponent } from './dns-check-status-icon.component';
import { StatusIconBaseModule } from '../status-icon-base';

@NgModule({
  declarations: [ DnsCheckStatusIconComponent ],
  imports: [ CommonModule, MatTooltipModule, StatusIconBaseModule ],
  exports: [ DnsCheckStatusIconComponent ]
})
export class DnsCheckStatusIconModule { }
