import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { LinkedBasicInfoHeaderModule } from '@zerops/zui/linked-basic-info-header';
import { ProjectBasicInfoModule } from '@zerops/zui/project-basic-info';
import { ZuiAddonButtonModule } from '@zerops/zui/addon-button';
import { AddonCardComponent } from './addon-card.component';

@NgModule({
  declarations: [ AddonCardComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    ZuiSeparatorComponent,
    LinkedBasicInfoHeaderModule,
    ProjectBasicInfoModule,
    ZuiAddonButtonModule
  ],
  exports: [ AddonCardComponent ]
})
export class ZuiAddonCardModule {

}
