import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ZuiTagModule } from '@zerops/zui/tag';
import { BasicInfoHeaderModule } from '@zerops/zui/basic-info-header';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { ProjectBasicInfoComponent } from './project-basic-info.component';

@NgModule({
  declarations: [ ProjectBasicInfoComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    ZuiTagModule,
    ZefCopyToClipboardModule,
    BasicInfoHeaderModule,
    ZefPipesModule,
    ZefProgressErrorModule
  ],
  exports: [ ProjectBasicInfoComponent ]
})
export class ProjectBasicInfoModule { }
