<sat-popover
  #notificationsPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (afterClose)="onAfterPopClose$.next()"
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="notificationsPopRef.close()"
  [verticalAlign]="'start'"
  [horizontalAlign]="'center'">
  <mat-card
    class="__card __notification-card  c-z-notifications-pop-card  z-manual-elevation  c-soft-elevation"
    [class.has-margin-right]="state.settings?.level === 'client'">
    <z-notifications-card
      class="__notifications"
      [notifications]="state.notifications"
      [totalHits]="state.notificationsListAdditionalInfo?.totalHits"
      [limit]="state.notificationsListAdditionalInfo?.limit"
      [showMoreStep]="showMoreStep"
      [unreadSettings]="state.unreadSettings"
      [notificationsLoading]="state.notificationsLoading"
      [showMoreLoading]="state.showMoreNotificationsLoading"
      [enableScroll]="true"
      [level]="state.settings?.level"
      [id]="state.settings?.id"
      (showMore)="onShowMore$.next()"
      (internalLinkClicked)="notificationsPopRef.close()">
    </z-notifications-card>
  </mat-card>
</sat-popover>
