import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { ZefGitlabState } from './gitlab.model';
import { GitlabActionUnion, zefGitlabRepositoriesSuccess, zefGitlabBranchSuccess } from './gitlab.action';

export const initialState = new ZefGitlabState();

const actionReducer = createReducer(
  initialState,
  on(zefGitlabRepositoriesSuccess, (s: ZefGitlabState, d): ZefGitlabState => ({
    ...s,
    repositories: d.data.repositories,
    authorized: true
  })),
  on(zefGitlabBranchSuccess, (s: ZefGitlabState, d): ZefGitlabState => ({
    ...s,
    branchMap: {
      ...s.branchMap,
      [d.data.repositoryName]: d.data.branches
    }
  })),
  on(zefResetState, (): ZefGitlabState => initialState)
);

export function gitlabReducer(
  state = initialState,
  action: GitlabActionUnion
) {
  return actionReducer(state, action);
}
