export const GHOST_IMPORT_YAML = `project:
  name: ghost-app
  tags:
    - zerops-recipe
services:
  - hostname: db
    type: mariadb@10.6
    mode: NON_HA
    priority: 1

  - hostname: storage
    type: object-storage
    objectStorageSize: 2
    objectStoragePolicy: public-objects-read
    priority: 1

  - hostname: ghost
    type: nodejs@18
    verticalAutoscaling:
      minRam: 1
    maxContainers: 1
    buildFromGit: https://github.com/zeropsio/recipe-ghost@main
    enableSubdomainAccess: true

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit@main
    enableSubdomainAccess: true`;
