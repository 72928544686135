import {
  IPV4_BASE_RANGE,
  FirewallIpRange,
  IPV6_BASE_RANGE,
  PublicIpTypes,
  PortRouting
} from '@zerops/models/port-routing';
import { CategoritedPortRoutings } from './port-routing-base.model';
import partition from 'lodash-es/partition';

const ipV4Range = '/' + IPV4_BASE_RANGE;
const ipV6Range = '/' + IPV6_BASE_RANGE;

export const modifyFirewallIpRanges = (
  ipRanges: FirewallIpRange[],
  publicIpType: PublicIpTypes
) => ipRanges.length === 1 && !ipRanges[0].ip ? [] : ipRanges.map((item) => {
  return !item.range ? item.ip + (publicIpType === PublicIpTypes.IpV4 ? ipV4Range : ipV6Range) : item.ip + '/' + item.range;
});

export const getPortRoutingByIpType = (
  items: PortRouting[],
) => {
  const data = partition(items, (itm) => itm.publicIpType === PublicIpTypes.IpV6);
  return {
    ipv4: data[1] || [],
    ipv6: data[0] || []
  };
};

export const getCategorizedPortRoutings = (
  items: PortRouting[],
  serviceStackId?: string
): CategoritedPortRoutings => {

  const baseState: CategoritedPortRoutings = {
    local: [],
    external: [],
    localHasUnsynced: undefined,
    externalHasUnsynced: undefined
  };

  const res =  items.reduce((obj, itm) => {

    if (itm.serviceStackId === serviceStackId || !serviceStackId) {
      obj.local.push(itm);
    } else {
      obj.external.push(itm);
    }

    return obj;
  }, baseState);

  res.localHasUnsynced = res.local.some((itm) => !itm.isSynced);
  res.externalHasUnsynced = res.external.some((itm) => !itm.isSynced);

  return res;
};
