import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatPopoverModule } from '@zerops/zef/popover';
import { StoreModule } from '@ngrx/store';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { ZuiProcessesCardModule } from '@zerops/zui/processes-card';
import { ProcessesPopContainer } from './processes-pop.container';
import { ProcessesPopTranslationsModule } from './processes-pop.translations';
import { FEATURE_NAME } from './processes-pop.constant';
import { processesPopReducer } from './processes-pop.reducer';

@NgModule({
  declarations: [ ProcessesPopContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    StoreModule.forFeature(FEATURE_NAME, processesPopReducer),
    SatPopoverModule,
    ZuiProcessesCardModule,
    ProcessesPopTranslationsModule
  ],
  exports: [ ProcessesPopContainer ]
})
export class ProcessesPopModule { }
