import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SettingsBaseEffect } from './settings-base.effect';
import { settingsBaseReducer } from './settings-base.reducer';
import { FEATURE_NAME } from './settings-base.constant';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, settingsBaseReducer),
    EffectsModule.forFeature([ SettingsBaseEffect ])
  ]
})
export class SettingsBaseModule {

}
