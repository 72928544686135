<div
  class="__grid"
  [class.__grid--start]="value?.length >= 3">
  <div
    *ngFor="let item of value; let index = index; trackBy: trackBy;"
    class="u-text--center">
    <div class="__item" #itemRef>

      <sat-popover
        #popoverRef
        hasBackdrop
        [autoFocus]="false"
        [restoreFocus]="false"
        [verticalAlign]="'start'"
        [horizontalAlign]="'end'"
        (backdropClicked)="popoverRef.close()">
        <mat-card class="__more-pop-card  z-manual-elevation  c-soft-elevation">
          <mat-action-list>

            <button
              class=""
              mat-list-item
              #editButtonRef="dynamicPopAnchor"
              [zuiDynamicPopAnchor]="formPopRef"
              (click)="_updatePort(index); editButtonRef.open(true, itemRef); popoverRef.close()"
              type="button">
              <zui-action-list-item
                hideContent
                [icon]="'edit'"
                [label]="'Edit'"
                [isLoading]="progressesMap[item.port + '_update']">
              </zui-action-list-item>
            </button>

            <button
              mat-list-item
              class="__remove"
              [class.is-disabled]="allowFullRemove === false"
              (click)="_deletePort(index); popoverRef.close()"
              [disabled]="allowFullRemove === false"
              type="button">
              <zui-action-list-item
                hideContent
                [icon]="'delete_outline'"
                [label]="'Remove'"
                [isLoading]="progressesMap[item.port + '_delete']">
              </zui-action-list-item>
            </button>

          </mat-action-list>
        </mat-card>
      </sat-popover>

      <span class="__item_scheme">{{ item.scheme }}</span>

      <h5 class="__item_port">
        {{ item?.protocol }}://:{{ item?.port }}
      </h5>

      <zui-more-button
        *ngIf="editable"
        class="__item_more-button"
        #moreButtonRef="dynamicPopAnchor"
        [zuiDynamicPopAnchor]="popoverRef"
        [isLoading]="progressesMap[item.port + '_delete'] || progressesMap[item.port + '_update']"
        (moreClick)="moreButtonRef.open()">
      </zui-more-button>

    </div>
  </div>
</div>

<div *ngIf="value && !!value.length && editable" class="__controls">
  <zef-progress-error [loading]="progressesMap['add']">
    <button
      class="__new-port-button c-button c-button--smaller c-button--full"
      (click)="_formMode = 'add'; addButtonRef.open()"
      #addButtonRef="dynamicPopAnchor"
      [zuiDynamicPopAnchor]="formPopRef"
      mat-flat-button
      type="button">
      <mat-icon>add</mat-icon>
      Open a new internal port
    </button>
  </zef-progress-error>
</div>

<ng-container *ngIf="(!value || value.length === 0)">

  @if (editable) {
    <ng-template [ngTemplateOutlet]="formRef"></ng-template>

    <button
      *ngIf="editable"
      class="__add-port-button  [ c-button  c-button--smaller  c-button--full ]"
      mat-raised-button
      color="accent"
      type="button"
      (click)="_addPort()">
      Add port
    </button>
  } @else {
    <zui-tip-card class="__tip">
      Internal ports can be defined inside <span>`run`</span> section of your zerops.yml, eg.
      <pre>run:
  ports:
    - port: 3000
      httpSupport: true</pre>
    </zui-tip-card>
  }


</ng-container>

<sat-popover
  #formPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (afterOpen)="_onPopOpen()"
  [backdropClass]="'cdk-overlay-dark-backdrop'"
  (afterClose)="_resetToInitial()"
  [lockAlignment]="true"
  [verticalAlign]="'start'"
  [horizontalAlign]="'center'">
  <mat-card class="__form-pop-card  z-manual-elevation  c-soft-elevation">

    <ng-template [ngTemplateOutlet]="formRef"></ng-template>

    <!-- update -->
    <button
      type="button"
      color="accent"
      class="c-button c-button--full"
      mat-raised-button
      (click)="_saveUpdatePort(); formPopRef.close()"
      *ngIf="_formMode === 'edit'">
      Update port
    </button>

    <!-- add -->
    <button
      class="c-button c-button--full __add-button"
      type="button"
      color="accent"
      mat-raised-button
      (click)="_addPort(); formPopRef.close()"
      *ngIf="_formMode === 'add'">
      Add port
    </button>

    <div class="__close-button-wrap">
      <button
        mat-button
        (click)="formPopRef.close()"
        class="c-button  c-button--full  c-button--smaller"
        type="button">
        Close
      </button>
    </div>

  </mat-card>
</sat-popover>

<ng-template #formRef>
  <div class="zef-form  __first-port-add-form-wrap">
    <form
      #ngFormRef="ngForm"
      [formGroup]="_form">

      <zef-form-field
        class="__protocol"
        [appearance]="'button-toggle'">

        <zef-form-label>Protocol</zef-form-label>

        <mat-button-toggle-group
          [formControl]="_form.get('protocol')"
          (change)="_handleProtocolChange()">

          <mat-button-toggle [value]="'tcp'">tcp://</mat-button-toggle>

          <mat-button-toggle [value]="'udp'">udp://</mat-button-toggle>

        </mat-button-toggle-group>

      </zef-form-field>

      <zef-form-field
        class="__http-support"
        appearance="checkbox">
        <mat-checkbox
          [formControl]="_httpSupportControl"
          (change)="_handleHttpSupportChange()">
          HTTP protocol support
        </mat-checkbox>
      </zef-form-field>

      <zef-form-field class="__field">
        <mat-form-field color="accent">

          <mat-label>Port</mat-label>

          <input
            type="number"
            matInput
            #portInputRef
            [placeholder]="'Anything from 10 to 65435'"
            [formControl]="_form.get('port')"
          />

        </mat-form-field>
      </zef-form-field>

    </form>
  </div>
</ng-template>
