import { createAction, union } from '@ngrx/store';
import { HttpRouting } from '@zerops/models/http-routing';

export const httpRoutingEditDialogOpen = createAction(
  '[@zerops/zerops/feature/http-routing-edit-dialog] open',
  (
    httpRouting: Partial<HttpRouting>,
    projectId: string,
    serviceStackId?: string
  ) => ({ httpRouting, projectId, serviceStackId })
);

const all = union({ httpRoutingEditDialogOpen });

export type HttpRoutingEditDialogActionUnion = typeof all;
