import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  onWebsocketMessageDispatchUpdateEntities,
  onWebsocketMessageDispatchAddRemoveEntities
} from '@zerops/zef/entities';
import { UserEntity } from '../user-base';
import { ServiceEntity } from './service.entity';

@Injectable()
export class ServiceBaseEffect {

  private _setupListStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._serviceEntity.listSubscribe(clientId))
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._serviceEntity)
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._serviceEntity.updateSubscribe(clientId)
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._serviceEntity)
  ));

  constructor(
    private _actions$: Actions,
    private _serviceEntity: ServiceEntity,
    private _userEntity: UserEntity
  ) {}
}
