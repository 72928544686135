import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { ContextualDialogTranslationsModule } from './contextual-dialog.translations';
import { ContextualDialogContainer } from './contextual-dialog.container';

@NgModule({
  declarations: [ ContextualDialogContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    ContextualDialogTranslationsModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZefDialogModule,
    ZuiLogoModule
  ],
  exports: [ ContextualDialogContainer ]
})
export class ZuiContextualDialogModule {

}
