import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ProcessStepStateComponent } from './process-step-state.component';

@NgModule({
  declarations: [ ProcessStepStateComponent ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [ ProcessStepStateComponent ]
})
export class ZuiProcessStepStateModule {

}
