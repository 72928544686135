import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Subscription } from '@zerops/models/subscription';

@Injectable({ providedIn: 'root' })
export class SubscriptionEntity extends EntityService<Subscription> {
  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketSubscription: ZefWebsocketService
  ) {
    super(ApiEntityKeys.Subscription, store, collectionManager, websocketSubscription);
  }
}
