import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PaymentSource } from '@zerops/models/billing';

@Component({
  selector: 'zui-payment-sources',
  templateUrl: './payment-sources.component.html',
  styleUrls: ['./payment-sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSourcesComponent {

  @Input()
  paymentSources: PaymentSource[];

  @Input()
  set hideRemoveButton(v: string | boolean) {
    this._hideRemoveButton = coerceBooleanProperty(v);
  }
  get hideRemoveButton(): boolean {
    return this._hideRemoveButton;
  }

  @Output()
  removePaymentSource = new EventEmitter<string>();

  removingId = undefined;
  private _hideRemoveButton = false;

  trackByIndex(index: number) {
    return index;
  }

}
