import { UserData, UserDataKeys } from './user-data.model';

export const extractObjectStorageQuota = (
  userData: UserData[],
) => {
  return +userData.find((d) => d.key === UserDataKeys.QuotaGBytes)?.content;
};

export const extractObjectStorageBucketName = (
  userData: UserData[],
) => {
  return userData.find((d) => d.key === UserDataKeys.BucketName)?.content;
};
