import keys from 'lodash-es/keys';
import { Project, ProjectWithServiceStacks } from '@zerops/models/project';
import flatten from 'lodash-es/flatten';
import orderBy from 'lodash-es/orderBy';

export const projectTagsGroups = (projects: Project[]) => {
  return projects
    .reduce((obj, itm) => {
      itm.tagList.forEach((tag) => {
        if (obj.map[tag] === undefined) {
          obj.data.push({
            label: tag,
            statuses: {
              [itm.status]: {
                amount: 1,
                label: itm.status
              }
            }
          });

          obj.map[tag] = obj.data.length - 1;
        } else {
          if (!obj.data[obj.map[tag]].statuses[itm.status]) {
            obj.data[obj.map[tag]].statuses[itm.status] = {
              amount: 1,
              label: itm.status
            };
          } else {
            obj.data[obj.map[tag]].statuses[itm.status].amount = obj.data[obj.map[tag]].statuses[itm.status].amount + 1;
          }
        }
      });
      return obj;
    }, {
      map: {},
      data: []
    })
    .data
    .map((itm) => ({
      ...itm,
      statuses: keys(itm.statuses).reduce(((arr, key) => {
        arr.push(itm.statuses[key]);
        return arr;
      }), [])
    }) as {
      label: string;
      statuses: Array<{
        amount: number;
        label: string;
      }>
    });
};


export const getProjectPorts = (
  project: ProjectWithServiceStacks,
  serviceStackId?: string,
  httpOnly = false,
  serviceStackOnly = false
) => {
  const data = project?.serviceStacks
    // remove internal services and services without
    // http ports
    .filter((s) => !s.isSystem)
    // map to a flat array of ports
    .map((stack) => flatten(stack.ports)
      // leave only port with http / https scheme
      .filter((itm) => !!(httpOnly === false || [ 'http', 'https' ].includes(itm.scheme)))
      .filter(() => serviceStackOnly ? stack.id === serviceStackId : true)
      .map((port) => ({
        serviceStackName: stack.name,
        serviceStackId: stack.id,
        protocol: port.protocol,
        port: port.port
      })
    ))
    // remove any empty groups
    .filter((itm) => itm?.length);

    return orderBy(
      data,
      [ (p) => p[0].serviceStackId === serviceStackId ? 1 : 0, 'serviceStackName' ],
      [ 'desc', 'asc' ]
    );

};

export const getFirstNextEmptyPort = (
  basePort: number,
  existingPorts: number[]
) => {

  if (!existingPorts.includes(basePort)){
    return basePort;
  }

  let nextPort: number;
  let i = 1;

  while (!nextPort) {
    if (!existingPorts.includes(basePort + i)) {
      nextPort = basePort + i;
      break;
    } else {
      i++;
    }
  }

  return nextPort;
};
