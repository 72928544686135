import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { HttpRoutingFormModule } from '@zerops/zui/http-routing-form';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { HttpRoutingEditDialogContainer } from './http-routing-edit-dialog.container';
import { HttpRoutingEditDialogEffect } from './http-routing-edit-dialog.effect';
import { HttpRoutingEditDialogTranslationsModule } from './http-routing-edit-dialog.translations';

@NgModule({
  declarations: [ HttpRoutingEditDialogContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ HttpRoutingEditDialogEffect ]),
    TranslocoModule,
    FormSectionModule,
    ZefDialogModule,
    HttpRoutingFormModule,
    ZefProgressErrorModule,
    PopStateModule,
    HttpRoutingEditDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ HttpRoutingEditDialogContainer ]
})
export class HttpRoutingEditDialogModule {

}
