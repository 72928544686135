import { ZefUploadItem } from '@zerops/zef/upload';
import { Avatar } from '@zerops/models/user';

export type AppVersionSource = 'GITHUB' | 'GITLAB' | 'CLI' | 'GUI' | 'GIT';

export class AppVersionLight {
  id: string;
  created: string;
  lastUpdate: string;
  status: AppVersionStatuses;
  name: string;
}

export class AppVersion extends AppVersionLight {
  clientId: string;
  serviceStackId: string;
  build: AppVersionBuild;
  projectId: string;
  source: AppVersionSource;
  sequence: number;
  name: string;
  activationDate: string;
  gitlabIntegration: AppVersionGitlabIntegration;
  githubIntegration: AppVersionGithubIntegration;
  publicGitSource: AppVersionPublicGitSource;
  prepareCustomRuntime?: AppVersionPreparingRuntime;
  createdByUser: {
    id: string;
    email: string;
    fullName: string;
    firstName: string;
    avatar: Avatar;
    type: 'GITHUB' | 'GITLAB' | 'CLI';
  };
  configContent: string;
  zeropsYamlJson: string;
}

export class AppVersionPreparingRuntime {
  startDate: string;
  endDate: string;
  serviceStackId: string;
  serviceStackName: string;
}

export class AppVersionBuild {
  startDate: string;
  endDate: string;
  serviceStackId: string;
  pipelineStart: string;
  pipelineFinish: string;
  pipelineFailed: string;
  containerCreationStart: string;
  serviceStackTypeVersionId: string;
  serviceStackName: string;
}

export class AppVersionFileSelectedPayload {
  serviceStackId: string;
  files: ZefUploadItem[];
  _iid: string;
}

export class AppVersionGithubIntegration {
  branchName: string;
  commit: string;
  eventType: 'TAG' | 'BRANCH';
  pusher: string;
  tagName: string;
  repositoryFullName: string;
  tagRegex: string;
}

export class AppVersionGitlabIntegration {
  branchName: string;
  commit: string;
  eventType: 'TAG' | 'BRANCH';
  pusher: string;
  tagName: string;
  repositoryFullName: string;
  tagRegex: string;
}

export class AppVersionPublicGitSource {
  branchName: string;
  gitUrl: string;
}

export class AppVersionParsedPublicGitSource {
  organization: string;
  repository: string;
  source: 'GITHUB' | 'GITLAB' | 'OTHER';
}

export enum AppVersionStatuses {
  Active = 'ACTIVE',
  Uploading = 'UPLOADING',
  WaitingToUpload = 'WAITING_TO_UPLOAD',
  WaitingToDeploy = 'WAITING_TO_DEPLOY',
  Deploying = 'DEPLOYING',
  PreparingRuntime = 'PREPARING_RUNTIME',
  // TODO: match with API
  PreparingRuntimeFailed = 'PREPARING_RUNTIME_FAILED',
  BuildFailed = 'BUILD_FAILED',
  DeployFailed = 'DEPLOY_FAILED',
  WaitingToBuild = 'WAITING_TO_BUILD',
  Backup = 'BACKUP',
  Building = 'BUILDING',
  Cancelled = 'CANCELLED',
  BuildValidationFailed = 'BUILD_VALIDATION_FAILED'
}

export enum ZeropsYamlErrors {
  ZeropsBuildFileNotFound = 'zeropsBuildFileNotFound',
  YamlValidationInvalidYaml = 'yamlValidationInvalidYaml',
  ZeropsYamlSetupNotFound = 'zeropsYamlSetupNotFound',
  ZeropsYamlServiceNotFound = 'zeropsYamlServiceNotFound',
  ZeropsYamlInvalidYaml = 'zeropsYamlInvalidYaml',
  ZeropsYamlTooManyExtends = 'zeropsYamlTooManyExtends',
  ZeropsYamlInvalidParameter = 'zeropsYamlInvalidParameter',
  ZeropsYamlMissingParameter = 'zeropsYamlMissingParameter',
  ZeropsYamlDocumentRootRequired = 'zeropsYamlDocumentRootRequired',
  ZeropsYamlStartCommandRequired = 'zeropsYamlStartCommandRequired',
  ZeropsYamlNoProbeSpecified = 'zeropsYamlNoProbeSpecified',
  ZeropsYamlTooManyProbes = 'zeropsYamlTooManyProbes',
  ZeropsBuildFileTooLarge = 'zeropsBuildFileTooLarge',
  ZeropsYamlRunOsRequired = 'zeropsYamlRunOsRequired',
  ZeropsYamlRunBaseRequired = 'zeropsYamlRunBaseRequired',
  ZeropsYamlRunOsBaseInvalid = 'zeropsYamlRunOsBaseInvalid',
  InternalServerError = 'internalServerError'
}
