export const RUST_IMPORT_YAML = `project:
  name: recipe-rust
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: rust@1
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-rust

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
