import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedStorageConnectionFieldComponent } from './shared-storage-connection-field.component';

@NgModule({
  declarations: [ SharedStorageConnectionFieldComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatSlideToggleModule
  ],
  exports: [ SharedStorageConnectionFieldComponent ]
})
export class SharedStorageConnectionFieldModule {

}
