import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefProgressMap } from '@zerops/zef/progress';
import { UserDataTypes, UserData } from '@zerops/models/user-data';


@Component({
  selector: 'zui-user-data-more-pop',
  templateUrl: './user-data-more-pop.component.html',
  styleUrls: [ './user-data-more-pop.component.scss' ]
})
export class UserDataMorePopComponent {

  userDataType = UserDataTypes;

  @Input()
  editKey: string;

  @Input()
  deleteKey: string;

  @Input()
  restoreKey: string;

  @Input()
  restrictedVariableTranslation: string;

  @Input()
  editableVariableTranslation: string;

  @Input()
  loadingsMap: ZefProgressMap = {};

  @Input()
  activeUserDataDelayed: UserData;

  @Input()
  activeElRef: ElementRef<any>;

  @Output()
  setActiveId = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<void>();

  @Output()
  restore = new EventEmitter<void>();

  @Input()
  editAnchor: SatPopover;

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

}
