<zef-dialog
  [autoFocus]="false"
  [options]="_dialogOptions"
  [open]="_dialogOpen"
  (closeTrigger)="closeTrigger()">

  <div class="__wrap">
    <ng-container *ngIf="(docsPath$ | async) as docsPath">
      <iframe class="__iframe" [src]="docsPath" frameborder="0"></iframe>
    </ng-container>
  </div>

  <div class="__buttons">

    <div>
      <a
        [href]="docsPath$ | async"
        target="_blank"
        mat-button
        target="_blank"
        (click)="close()"
        class="c-button  c-button--full">
        <mat-icon>open_in_new</mat-icon> Open in a New tab
      </a>
    </div>

    <div>
      <button
        type="button"
        class="c-button  c-button--full"
        mat-button
        (click)="close()">
        Close
      </button>
    </div>

  </div>

</zef-dialog>
