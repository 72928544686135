import { createAction, union } from '@ngrx/store';
import { PublicIpTypes } from '@zerops/models/port-routing';
import { PortRoutingAddDialogOpenMeta } from './port-routing-add-dialog.model';

export const portRoutingAddDialogOpen = createAction(
  '[@zerops/zerops/feature/port-routing-add-dialog] open',
  (
    ipType: PublicIpTypes,
    projectId: string,
    serviceStackId?: string
  ) => ({
    ipType,
    projectId,
    serviceStackId
  } as PortRoutingAddDialogOpenMeta)
);

const all = union({ portRoutingAddDialogOpen });

export type PortRoutingAddDialogActionUnion = typeof all;
