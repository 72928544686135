import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AddonButtonComponent } from './addon-button.component';

@NgModule({
  declarations: [ AddonButtonComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [ AddonButtonComponent ]
})
export class ZuiAddonButtonModule {

}
