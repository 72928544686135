import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  onWebsocketMessageDispatchUpdateEntities,
  successOf,
  onWebsocketMessageDispatchAddRemoveEntities
} from '@zerops/zef/entities';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { DEPLOY_SHUTDOWN_DIALOG_FEATURE_NAME } from '@zerops/zerops/feature/deploy-shutdown-dialog';
import { ZefSnackService } from '@zerops/zef/snack';
import { of } from 'rxjs';
import {
  map,
  filter,
  withLatestFrom,
  switchMap,
  catchError,
  concatMap,
  take, tap
} from 'rxjs/operators';
import { AppVersionEntity } from './app-version-base.entity';
import {
  filesSelected,
  filesUploadProgress,
  filesUploadFail,
  filesUploadSuccess,
  AppVersionBaseActionUnion,
  deploy,
  deploySuccess,
  deployFail,
  download
} from './app-version-base.action';
import { AppVersionBaseApi } from './app-version-base.api';
import { FEATURE_NAME } from './app-version-base.constant';

@Injectable()
export class AppVersionBaseEffect {

  private _filesSelected$ = this._actions$.pipe(ofType(filesSelected));

  // app version user list / update
  private _setupAppVersionListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._appVersionEntity.listSubscribe(
      clientId
    ))
  ));

  private _onClientUserListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._appVersionEntity
    )
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._appVersionEntity.updateSubscribe(clientId)
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._appVersionEntity)
  ));

  private _onFilesSelectedCheckAppVersion$ = createEffect(() => this._filesSelected$.pipe(
    switchMap(({ serviceStackId }) => this._serviceStackEntity.entityById$(serviceStackId).pipe(
      filter((d) => !!d),
      map((serviceStack) => ({ serviceStackId, activeAppVersion: serviceStack.activeAppVersion })),
      take(1)
    )),
    map(({ serviceStackId, activeAppVersion }) => {
      if (!!activeAppVersion) {
        return zefDialogOpen({ key: DEPLOY_SHUTDOWN_DIALOG_FEATURE_NAME, meta: serviceStackId });
      } else {
        return this._appVersionEntity.addOne({ serviceStackId });
      }
    })
  ));

  private _onAddSuccess$ = createEffect(() => this._actions$.pipe(
    successOf<any>(this._appVersionEntity.addOne),
    withLatestFrom(this._filesSelected$),
    concatMap(([ action, { files }]) => this._api
      .upload$(action.meta.rawResult.uploadUrl, files[0].meta)
      .pipe(
        map((e) => {
          if (e.type === HttpEventType.Sent) {
            return filesUploadProgress({ progress: 0 });
          }

          if (e.type === HttpEventType.UploadProgress) {
            return filesUploadProgress({ progress: Math.round((100 * e.loaded) / e.total) });
          }

          if (e.type === HttpEventType.Response || e.type === HttpEventType.ResponseHeader) {
            if (e.status === 200 || e.status === 201) {
              return filesUploadSuccess(
                {
                  serviceStackId: action.meta.rawResult.id
                },
                {
                  temporaryShutdown: action.originalAction.meta.shutdownControl
                }
              );
            } else {
              return filesUploadFail();
            }
          }

          return filesUploadFail();
        }),
        catchError(() => of(filesUploadFail()))
      )
    )
  ));

  private _onUploadSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(filesUploadSuccess),
    map(({ serviceStackId, meta: { temporaryShutdown } }) => deploy({ id: serviceStackId, temporaryShutdown }))
  ));

  private _onDeploy$ = createEffect(() => this._actions$.pipe(
    ofType(deploy),
    switchMap((action) => this._api
      .deploy$(action.data.id, action.data.temporaryShutdown)
      .pipe(
        map(() => deploySuccess()),
        catchError((err) => of(deployFail(err, action)))
      )
    )
  ));

  private _onAppVersionDeleteSuccessNotification$ = createEffect(() => this._actions$.pipe(
    successOf<{ success: boolean; }>(this._appVersionEntity.deleteOne),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.deleted` }))
  ), { dispatch: false });

  private _onDownload$ = createEffect(() => this._actions$.pipe(
    ofType(download),
    switchMap(({ id }) => this._api
      .download$(id)
      .pipe(
        tap(({ url }) => window.location.replace(this._getUrl(url)))
      )
    )
  ), { dispatch: false });

  constructor(
    private _actions$: Actions<AppVersionBaseActionUnion | any>,
    private _appVersionEntity: AppVersionEntity,
    private _serviceStackEntity: ServiceStackEntity,
    private _userEntity: UserEntity,
    private _api: AppVersionBaseApi,
    private _snack: ZefSnackService
  ) { }

  private _getUrl(url: string) {
    return 'https://' + url.split('GET')[1].trim();
  }

}
