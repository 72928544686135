import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { HttpRoutingFormModule } from '@zerops/zui/http-routing-form';
import { HttpRoutingItemModule } from '@zerops/zui/http-routing-item';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZuiIpInfoCardModule } from '@zerops/zui/ip-info-card';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { HttpRoutingAddDialogContainer } from './http-routing-add-dialog.container';
import { HttpRoutingAddDialogEffect } from './http-routing-add-dialog.effect';
import { HttpRoutingAddDialogTranslationsModule } from './http-routing-add-dialog.translations';

@NgModule({
  declarations: [ HttpRoutingAddDialogContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    ZefDialogModule,
    EffectsModule.forFeature([ HttpRoutingAddDialogEffect ]),
    FormSectionModule,
    HttpRoutingFormModule,
    HttpRoutingItemModule,
    ZefProgressErrorModule,
    PopStateModule,
    TranslocoModule,
    HttpRoutingAddDialogTranslationsModule,
    ZuiIpInfoCardModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent
  ],
  exports: [ HttpRoutingAddDialogContainer ]
})
export class HttpRoutingAddDialogModule {

}
