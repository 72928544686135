import { createAction, union } from '@ngrx/store';

export const openTopUpDialog = createAction(
  '[@zerops/zerops/feature/top-up-dialog] open'
);

const all = union({
  openTopUpDialog
});

export type TopUpDialogActionUnion = typeof all;
