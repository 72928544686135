import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { PublicIpTypes, FirewallPolicies, PortRouting } from '@zerops/models/port-routing';
import { PortRoutingItemTranslations } from './port-routing-item.translations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'zui-port-routing-item',
  templateUrl: './port-routing-item.component.html',
  styleUrls: [ './port-routing-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortRoutingItemComponent {

  publicIpTypes = PublicIpTypes;
  firewallPolicies = FirewallPolicies;

  @Input()
  data: PortRouting;

  @Input()
  ip: string;

  @Input()
  type: PublicIpTypes;

  @Input()
  location: 'stack' | 'other' = 'stack';

  @Output()
  editOpen = new EventEmitter<void>();

  @Input()
  set hideOutOfSync(v: string | boolean) {
    this._hideOutOfSync = coerceBooleanProperty(v);
  }
  get hideOutOfSync(): boolean {
    return this._hideOutOfSync;
  }

  @Input()
  set disableEdit(v: string | boolean) {
    this._disableEdit = coerceBooleanProperty(v);
  }
  get disableEdit(): boolean {
    return this._disableEdit;
  }

  @Input()
  translations: PortRoutingItemTranslations;

  private _hideOutOfSync = false;
  private _disableEdit = false;

  constructor(public elementRef: ElementRef) { }

  trackBy(index: number) {
    return index;
  }
}
