import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { filter, map, switchMap } from 'rxjs/operators';
import { importExportDialogOpen } from './import-export-dialog.action';
import { DEFAULT_PROJECT_YAML, DEFAULT_SERVICE_YAML, FEATURE_NAME } from './import-export-dialog.constant';
import { DialogEntities } from './import-export-dialog.container';
import { ImportExportDialogForm } from './import-export-dialog.form';
import { ImportExportDialogModes } from './import-export-dialog.model';

@Injectable()
export class ImportExportDialogEffect {

  private _onImportExportDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(importExportDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  private _onExportDialogOpenSetForm$ = createEffect(() => this._actions$.pipe(
    ofType(importExportDialogOpen),
    filter((d) => !!d.yaml),
    map((d) => this._importExportDialogForm.setValue({ yaml: d.yaml }))
  ));

  private _onImportDialogOpenSetForm$ = createEffect(() => this._actions$.pipe(
    ofType(importExportDialogOpen),
    filter((d) => !d.yaml && d.mode === ImportExportDialogModes.Import),
    map((d) => this._importExportDialogForm.setValue({
      yaml: d.entity === DialogEntities.Project
        ? DEFAULT_PROJECT_YAML
        : DEFAULT_SERVICE_YAML
    }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((d) => d.key === FEATURE_NAME),
    switchMap(() => [
      this._importExportDialogForm.reset(),
      this._importExportDialogForm.setDefaultValues()
    ])
  ));

  constructor(
    private _actions$: Actions,
    private _importExportDialogForm: ImportExportDialogForm
  ) { }
}
