export const ECHO_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-echo
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-echo
    enableSubdomainAccess: true

  - hostname: db
    priority: 10
    type: postgresql@16
    mode: NON_HA

  - hostname: storage
    type: object-storage
    priority: 10
    objectStoragePolicy: public-read
    objectStorageSize: 5

  - hostname: redis
    type: keydb@6
    priority: 10
    mode: NON_HA

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit
    enableSubdomainAccess: true

  - hostname: adminer
    type: php-apache@8.1
    buildFromGit: https://github.com/zeropsio/recipe-adminer
    enableSubdomainAccess: true`;
