export const ANALOG_NODEJS_IMPORT_YAML = `project:
  name: recipe-analog
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: nodejs@20
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-astro-nodejs`;
