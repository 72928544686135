import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ContainerEntity } from './container-base.entity';
import { onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef/entities';

@Injectable()
export class ContainerBaseEffect {
  // # Deps
  #actions$ = inject(Actions);
  #containerEntity = inject(ContainerEntity);

  // # Effects
  onUpdateStreamMessage$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#containerEntity)
  ));

}
