import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { ProjectCostLimitDialogState } from './project-cost-limit-dialog.model';
import { ProjectCostLimitDialogActionUnion, setMode } from './project-cost-limit-dialog.action';

export const initialState = new ProjectCostLimitDialogState();

const actionReducer = createReducer(
  initialState,
  on(setMode, (s: ProjectCostLimitDialogState, d): ProjectCostLimitDialogState => ({
    ...s,
    mode: d.mode
  })),
  on(zefResetState, (): ProjectCostLimitDialogState => initialState)
);

export function projectCostLimitDialogReducer(
  state = initialState,
  action: ProjectCostLimitDialogActionUnion
) {
  return actionReducer(state, action);
}
