import {
  Component,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppVersionItemTranslations, APP_VERSION_ITEM_FEATURE_NAME } from '@zerops/zui/app-version-item';
import { Contexts, CONTEXTUAL_DIALOG_FEATURE_NAME } from '@zerops/zui/contextual-dialog';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WebServiceStackActiveAppVersionPopService } from './web-service-stack-active-app-version-pop.service';

@Component({
  selector: 'zui-web-service-stack-active-app-version-pop',
  templateUrl: './web-service-stack-active-app-version-pop.container.html',
  styleUrls: [ './web-service-stack-active-app-version-pop.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebServiceStackActiveAppversionPopContainer extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onOpenContextualDialog$ = new Subject<Contexts>();

  // # Data
  // -- angular
  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  // -- sync
  contexts = Contexts;

  // -- async
  data$ = this._webServiceStackActiveAppVersionService.getData$().pipe(
    filter((d) => !!d)
  );
  serviceStack$ = this.data$.pipe(
    map((d) => d?.serviceStack)
  );
  activeAppVersion$ = this.data$.pipe(
    map((d) => d?.appVersion)
  );
  appVersionItemTranslations$ = this.translate$<AppVersionItemTranslations>(APP_VERSION_ITEM_FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    serviceStack: this.serviceStack$,
    activeAppVersion: this.activeAppVersion$,
    appVersionItemTranslations: this.appVersionItemTranslations$
  });

  // # Action Streams
  private _openContextualDialogAction$ = this.onOpenContextualDialog$.pipe(
    map((meta) => zefDialogOpen({
      key: CONTEXTUAL_DIALOG_FEATURE_NAME,
      meta
    }))
  );

  constructor(
    private _webServiceStackActiveAppVersionService: WebServiceStackActiveAppVersionPopService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._openContextualDialogAction$ ]);
  }

  ngAfterViewInit() {
    this._webServiceStackActiveAppVersionService.saveRef(this.popRef);
  }

}
