import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { ProcessNotificationShellComponent } from './process-notification-shell.component';

@NgModule({
  declarations: [ ProcessNotificationShellComponent ],
  imports: [
    CommonModule,
    ZefAvatarModule
  ],
  exports: [ ProcessNotificationShellComponent ]
})
export class ZuiProcessNotificationShellModule {

}
