import { NgModule } from '@angular/core';
import { ThreeDotsComponent } from './three-dots.component';

@NgModule({
  declarations: [ ThreeDotsComponent ],
  exports: [ ThreeDotsComponent ]
})
export class ZuiThreeDotsModule {

}
