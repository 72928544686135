import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceStackVersionFieldComponent } from './service-stack-version-field.component';
import { ServiceStackVersionFieldTranslationsModule } from './service-stack-version-field.translations';

@NgModule({
  declarations: [ ServiceStackVersionFieldComponent ],
  imports: [
    CommonModule,
    ServiceStackVersionFieldTranslationsModule
  ],
  exports: [ ServiceStackVersionFieldComponent ]
})
export class ServiceStackVersionFieldModule {

}
