import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './port-routing-item.constant';

const en = {
  statuses: {
    SYNCED: 'Synchronized',
    UNSYNCED: 'Not synchronized',
    BEING_DELETED: 'Being deleted & Not synchronized'
  },
  copyPathToClipboard: 'Click to copy path to clipboard'
};

export type PortRoutingItemTranslations = typeof en;

@NgModule()
export class PortRoutingItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
