import { NgModule } from '@angular/core';
import { ZuiRecipeTagModule } from '@zerops/zui/elements/recipe-tag';
import { ZeDiscordButtonModule } from './discord-button';
import { ZeSeparatorModule } from './separator';

@NgModule({
  imports: [
    ZuiRecipeTagModule,
    ZeDiscordButtonModule,
    ZeSeparatorModule
  ]
})
export class ZeElementsModule {

}
