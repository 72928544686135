<sat-popover
  [zuiPopState]="popState"
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  [backdropClass]="'cdk-overlay-dark-backdrop'"
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="popRef.close()"
  [verticalAlign]="'start'"
  [horizontalAlign]="'center'"
  (closed)="closeTrigger.emit()">
  <mat-card class="__card  z-manual-elevation  c-soft-elevation">
    <zui-form-section separator="none">
      <form
        #ngFormRef="ngForm"
        [formGroup]="formGroup"
        (submit)="add.emit(formGroup.value)">

        <zui-user-data-fields
          [keyControl]="formGroup.get('key')"
          [contentControl]="formGroup.get('content')"
        />

        <div class="__buttons">
          <div class="__buttons-grid">

            <div>
              <zef-progress-error full [key]="key">
                <button
                  class="__button  c-button--full"
                  color="accent"
                  mat-raised-button
                  type="submit"
                  [disabled]="disableAddButton">
                  {{ saveVariableTranslation }}
                </button>
              </zef-progress-error>
            </div>

            <div>
              <button
                (click)="popRef.close()"
                class="c-button--full"
                type="button"
                mat-button>
                <span transloco="general.close"></span>
              </button>
            </div>

          </div>
        </div>

      </form>
    </zui-form-section>
  </mat-card>
</sat-popover>
