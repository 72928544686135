import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { PipelineDetailModule } from '@zerops/zerops/feature/pipeline-detail';
import { PipelineDetailDialogContainer } from './pipeline-detail-dialog.container';
import { PipelineDetailDialogEffect } from './pipeline-detail-dialog.effect';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ PipelineDetailDialogContainer ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ PipelineDetailDialogEffect ]),
    ZefDialogModule,
    PipelineDetailModule,
    MatButtonModule,
    MatIconModule,
    ZefScrollbarComponent
  ],
  exports: [ PipelineDetailDialogContainer ]
})
export class PipelineDetailDialogModule {

}
