<zef-form-field>
  <mat-form-field color="accent" [floatLabel]="'always'">

    <mat-label>Key</mat-label>

    <input
      *ngIf="!!keyControlState && !keyControl"
      matInput
      type="text"
      autocomplete="off"
      [placeholder]="'Has to be unique and match [a-zA-Z0-9_]*.'"
      [ngrxEnableFocusTracking]="true"
      [ngrxFormControlState]="keyControlState"
    />

    <input
      *ngIf="!keyControlState && !!keyControl"
      matInput
      type="text"
      autocomplete="off"
      [placeholder]="'Has to be unique and match [a-zA-Z0-9_]*.'"
      [formControl]="keyControl"
    />

  </mat-form-field>
</zef-form-field>

<zef-form-field>
  <mat-form-field color="accent" [floatLabel]="'always'">

    <mat-label>Content</mat-label>

    <textarea
      *ngIf="!!contentControlState && !contentControl"
      matInput
      matAutosize
      type="text"
      rows="6"
      [placeholder]="'Anything that is ASCII and is not the EOL symbol.'"
      autocomplete="off"
      [ngrxEnableFocusTracking]="true"
      [ngrxFormControlState]="contentControlState"></textarea>

    <textarea
      *ngIf="!contentControlState && !!contentControl"
      [formControl]="contentControl"
      matInput
      matAutosize
      type="text"
      rows="6"
      [placeholder]="'Anything that is ASCII and is not the EOL symbol.'"
      autocomplete="off"></textarea>

    <mat-hint>You can reference another variable using <code>$&#123;key&#125;</code> or even a variable from a different service using <code>$&#123;hostname_key&#125;</code>, and even if it doesn't exist yet. Go wild.</mat-hint>

  </mat-form-field>
</zef-form-field>