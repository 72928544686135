<mat-card
  class="__info"
  [ngClass]="{
    'is-orange': color === 'orange',
    'is-green': color === 'green',
    'is-base': color === 'base'
  }">

  <p class="__desc">
    <zui-docs-text>
      Make sure to point DNS records <strong>A</strong> and / or <strong>AAAA</strong> to the unique IP&nbsp;addresses assigned to the project <strong>{{ projectName }}</strong>.
      <span *ngIf="!ipV4"> Alternatively you can point the&nbsp;<strong>A</strong>&nbsp;record to the <a data-zui-docs="routing/using-shared-ipv4.html">shared IPv4&nbsp;address</a>
        &nbsp;
        <zef-copy-to-clipboard
          [copyData]="sharedIpV4"
          [tooltip]="'Copy shared IPv4 address to clipboard'">
          <strong>{{ sharedIpV4 }}</strong>
        </zef-copy-to-clipboard>
        &nbsp;along with the&nbsp;<strong>AAAA</strong>&nbsp;record pointing the unique IPv6 address (required for shared IPv4 to work).
      </span>
    </zui-docs-text>
  </p>

  <zui-ip-info
    class="__dns"
    [ipV6]="ipV6"
    [ipV4]="ipV4"
    [ipV4PendingRequest]="ipV4PendingRequest"
    [requestIpV4ButtonText]="requestIpV4ButtonText"
    [ipV4Prefix]="ipV4Prefix"
    [ipV6Prefix]="ipV6Prefix"
    [requestIpv4Key]="requestIpv4Key"
    [showOnlyIpV4Button]="showOnlyIpV4Button"
    [projectStatus]="projectStatus"
    [ipV4AddonInProcess]="ipV4AddonInProcess"
    (requestIpV4)="requestIpV4.emit()">
  </zui-ip-info>

</mat-card>
