import { createAction, props, union } from '@ngrx/store';

export const requestRecipeAdd = createAction(
  '[@zerops/zui/recipes] request-recipe-add',
  props<{ id: number; yaml: string; }>()
);

export const requestShowYaml = createAction(
  '[@zerops/zui/recipes] request-show-yaml',
  props<{ yaml: string }>()
);

const all = union({
  requestRecipeAdd,
  requestShowYaml
});

export type RecipesActionUnion = typeof all;
