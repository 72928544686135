import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CodeFieldComponent } from './code-field.component';

@NgModule({
  declarations: [ CodeFieldComponent ],
  imports: [
    MatProgressSpinnerModule
  ],
  exports: [ CodeFieldComponent ]
})
export class CodeFieldModule {

}
