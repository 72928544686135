import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZeSeparatorComponent } from './separator.component';

@NgModule({
  declarations: [ ZeSeparatorComponent ],
  imports: [
    CommonModule,
    ZuiSeparatorComponent
  ],
  exports: [ ZeSeparatorComponent ]
})
export class ZeSeparatorModule {

  constructor(injector: Injector) {
    const customElement = createCustomElement(ZeSeparatorComponent, { injector });
    customElements.define('ze-separator', customElement);
  }

}
