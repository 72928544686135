import { createAction, props, union } from '@ngrx/store';
import { ProjectCostLimitDialogModes } from './project-cost-limit-dialog.model';

export const setMode = createAction(
  '[@zerops/zerops/feature/project-cost-limit-dialog] set-mode',
  props<{ mode: ProjectCostLimitDialogModes; }>()
);

const all = union({ setMode });
export type ProjectCostLimitDialogActionUnion = typeof all;
