import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyChipComponent } from './key-chip.component';

@NgModule({
  declarations: [ KeyChipComponent ],
  imports: [ CommonModule ],
  exports: [ KeyChipComponent ]
})
export class KeyChipModule { }
