import { Directive, Inject, OnInit, Input } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { Store, select } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { Observable } from 'rxjs';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import {
  switchMap,
  takeUntil,
  map,
  distinctUntilChanged,
  withLatestFrom,
  filter,
  delay
} from 'rxjs/operators';
import { BaseClass, isOnDestroy } from '@zerops/zef/core';

@Directive({
  selector: '[zuiPopState]',
  exportAs: 'popState'
})
export class PopStateDirective extends BaseClass implements OnInit {

  @ObservableInput()
  @Input('zuiPopState')
  zuiPopState$!: Observable<string>;

  state$ = this.zuiPopState$.pipe(
    switchMap((s) => this._store.pipe(select(selectZefDialogState(s)))),
    map((s) => s && s.state),
    distinctUntilChanged()
  );

  constructor(
    @Inject(SatPopover)
    private _pop: SatPopover,
    private _store: Store<any>
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this._pop.afterClose
      .pipe(
        withLatestFrom(this.zuiPopState$),
        map(([ _, key ]) => zefDialogClose({ key })),
        filter(() => !!this._pop.open),
        takeUntil(this.lifecycle$.pipe(isOnDestroy())),
      )
      .subscribe(this._store);

    this.state$
      .pipe(
        // needed in case dynamic anchor binding called after dialog open
        delay(0),
        takeUntil(this.lifecycle$.pipe(isOnDestroy())),
      )
      .subscribe((s) => s
        ? this._pop.open()
        : this._pop.open
          ? this._pop.close()
          : undefined
      );
  }
}
