import { Injectable } from '@angular/core';
import MessageFormat, { MessageFunction } from '@messageformat/core';

@Injectable({
  providedIn: 'root',
})
export class MessageFormatService {
  #messageFormat = new MessageFormat('en');
  #cache = new Map<string, MessageFunction<'string'>>();

  format(
    pattern: string,
    params: Record<string, any>,
    locale?: string
  ): string {
    locale = locale || 'en';
    const cacheKey = `${locale}:${pattern}`;
    if (!this.#cache.has(cacheKey)) {
      const messageFunction = this.#messageFormat.compile(pattern);
      this.#cache.set(cacheKey, messageFunction);
    }
    const messageFunction = this.#cache.get(cacheKey);
    return messageFunction(params);
  }
}
