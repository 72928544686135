import { UserDataBaseState } from './user-data-base.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME } from './user-data-base.constant';

export const selectUserDataBaseState = createFeatureSelector<UserDataBaseState>(FEATURE_NAME);

export const selectUserDataBaseActiveId = createSelector(
  selectUserDataBaseState,
  (state) => state.activeId
);
