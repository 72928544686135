<zef-form
  [autoScroll]="autoscroll"
  [state]="state"
  (submit)="submit.emit()">

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>Name / Company name <strong>(required)</strong></mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.companyName"
      />

    </mat-form-field>
  </zef-form-field>

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>Country <strong>(required)</strong></mat-label>

      <mat-select
        class="__country"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.invoiceAddressCountryId">

        <mat-option
          [value]="item.id"
          *ngFor="let item of countryList">
          {{ item.name }}
        </mat-option>
      </mat-select>

    </mat-form-field>
  </zef-form-field>

  <ng-container *ngIf="state?.controls.invoiceAddressCountryId.value && _countryEuMap[state?.controls.invoiceAddressCountryId.value]">

    <zef-form-field appearance="checkbox">
      <mat-checkbox
        [ngrxFormControlState]="state?.controls.vatPayer"
        [ngrxEnableFocusTracking]="true">
        I'm a VAT payer
      </mat-checkbox>
    </zef-form-field>

    <zef-form-field *ngIf="!!state?.controls.vatPayer.value">
      <mat-form-field color="accent">

        <mat-label>VAT number</mat-label>

        <input
          matInput
          type="text"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state?.controls.vatNumber"
        />

      </mat-form-field>
    </zef-form-field>

  </ng-container>

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>Company identification number (CIN)</mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.companyNumber"
      />

    </mat-form-field>
  </zef-form-field>

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>City</mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.invoiceAddressCity"
      />

    </mat-form-field>
  </zef-form-field>

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>Street</mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.invoiceAddressStreet"
      />

    </mat-form-field>
  </zef-form-field>

  <zef-form-field>
    <mat-form-field color="accent">

      <mat-label>Post code</mat-label>

      <input
        matInput
        type="text"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.invoiceAddressPostcode"
      />

    </mat-form-field>
  </zef-form-field>

  <ng-content></ng-content>

</zef-form>
