import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { MenuPanelContainer } from './menu-panel.container';
import { MenuPanelTranslationsModule } from './menu-panel.translations';

@NgModule({
  declarations: [ MenuPanelContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    ActionListItemModule,
    MenuPanelTranslationsModule
  ],
  exports: [ MenuPanelContainer ]
})
export class MenuPanelModule {

}
