import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[zuiAutofocus]'
})
export class ZuiAutofocusDirective implements OnInit {

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    if (this._elementRef.nativeElement) {
      setTimeout(() => this._elementRef.nativeElement?.focus(), 10);
    }
  }

}
