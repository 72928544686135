import { Injectable } from '@angular/core';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { Store } from '@ngrx/store';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
// explicit import because of circular dependency
import { ClientUserEntity } from '@zerops/zerops/core/client-user-base/client-user.entity';
import { User } from '@zerops/models/user';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import {
  selectUserBaseActiveUserId,
  selectUserBaseActiveClientUserId
} from './user-base.selector';

@Injectable({ providedIn: 'root' })
export class UserEntity extends EntityService<User> {
  activeUser$ = this.entityById$(selectUserBaseActiveUserId);
  activeClientUser$ = this._clientUser.entityById$(selectUserBaseActiveClientUserId);
  activeClientIdRaw$ = this._clientUser
    .entityById$(selectUserBaseActiveClientUserId)
    .pipe(
      map((d) => d?.clientId)
    );
  activeClientId$ = this.activeClientIdRaw$
    .pipe(
      distinctUntilChanged(),
      filter((d) => !!d)
    );
  isContabo$ = this.activeClientId$
    .pipe(
      map((id) => id.includes('contabo'))
    );
  activeUserClientUsers$ = this.activeUser$.pipe(
    map((user) => user ? user.clientUserList : undefined)
  );

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService,
    private _clientUser: ClientUserEntity
  ) {
    super(ApiEntityKeys.User, store, collectionManager, websocketService);
  }
}
