import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslocoModule } from '@ngneat/transloco';
import { SnackInfoContainer } from './snack-info.container';

@NgModule({
  declarations: [ SnackInfoContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule
  ],
  exports: [ SnackInfoContainer ]
})
export class SnackInfoModule {

}
