<mat-card
  @inOutAnimation
  class="__card  z-manual-elevation"
  [class.is-orange]="state?.notifications?.[0]?.type === 'WARNING'"
  [class.is-red]="state?.notifications?.[0]?.type === 'ERROR'">

  <div class="__content" *ngIf="state?.notifications?.length">

    <div class="__close-wrap">
      <button
        mat-icon-button
        type="button"
        (click)="onRemove$.next(state?.notifications[0].id)"
        class="__close-button">

        <mat-icon class="__close-button_icon">close</mat-icon>

        <div
          *ngIf="state.notifications?.length > 1"
          class="__count">
          <div class="__count-content">
            <span class="__count-number">{{ state.notifications?.length }}</span>
          </div>
        </div>

      </button>
    </div>

    <zui-notification-item
      class="__item"
      hideUnread
      [color]="'transparent'"
      [translations]="state?.notificationItemTranslations"
      [data]="state?.notifications[0]"
      [appVersionItemTranslations]="state.appVersionItemTranslations"
      (pipelineDetailClicked)="onOpenPipelineDetailDialog$.next($event)"
      (logClicked)="onOpenLogDialog$.next($event)">
    </zui-notification-item>


  </div>
</mat-card>
