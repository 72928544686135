import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './nginx-service-stack-form.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  hostnameTitle: `Hostname`,
  hostnameDesc: `Select a short, URL-friendly name, like 'app', 'cache','qui' etc. Other services will be able to access this service using this hostname and the internal port, i.e., gui:3000. You can also access the services in the same way if using zcli VPN.`,
  hostnameLabel: 'Hostname',
  hostnamePlaceholder: 'Use anything URL-friendly …',
  saveVariable: 'Save the variable',
  addVariable: 'Add new variable',
  copyKeyTooltip: 'Click to copy the key name to clipboard',
  resetConfiguration: 'Set default configuration',
  envVariablesTitle: 'Secret variables',
  envVariablesDesc: `Add environment variables with sensitive data, such as password, tokens, salts, certificates etc. These will be securely saved inside Zerops and added to your runtime service upon start. You can reference other secret variables, or other types of Zerops environment variables (generated, from zerops.yml file) even those that do not exist yet. You can also all other variables from other services inside the project.`,
  repositoryDesc: `Connect service to a <a data-zui-docs="github/github-integration.html">Github</a> or
  <a data-zui-docs="gitlab/gitlab-integration.html">GitLab</a> repository and setup automatic build trigger
  on a new code tag or branch push or deploy your code manually through
  <a data-zui-docs="cli/available-commands.html#deploy-project-name-service-name-space-separated-files-or-directories">Zerops CLI</a>.
  View available <a data-zui-docs="backup-restore/deploy-versioning.html">versions & backups</a>.`,
  scalingDesc: `Zerops will scale the Static app service <strong>horizontally</strong> by adding and removing containers and <strong>vertically</strong> by
  increasing and decreasing each container's resources, depending on the current load and Static app scaling scaling best practices.
  Zerops will scale within ranges that you can fine tune to meet the exact needs of the environment for which you plan to run the service.
  Find all the specifics in the <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">Zerops Static app scaling documentation.</a>`,
  nginxConfigDesc: `If you use your own custom config, please make extra sure that your
  <strong>root, error_log, access_log</strong> parameters have correct values. Read full
  <a data-zui-docs="services/static-server/nginx.html">Nginx documentation</a>.`,
  nginxServiceTypes: {
    nodejs: 'Node.js',
    golang: 'Golang',
    php_apache: 'PHP'
  }
};

export type NginxServiceStackFormTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class NginxServiceStackFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
