import { NgModule } from '@angular/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { ZefAutocompleteTagComponent, ZefAutocompleteTagTitleDirective } from './autocomplete-tag-field.component';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ZefFormFieldModule } from '../form-field';

@NgModule({
  declarations: [
    ZefAutocompleteTagComponent,
    ZefAutocompleteTagTitleDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ZefFormFieldModule
  ],
  exports: [
    ZefAutocompleteTagComponent,
    ZefAutocompleteTagTitleDirective
  ]
})
export class ZefAutocompleteTagModule {

}
