import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { HttpRoutingActionsPopContainer } from './http-routing-actions-pop.container';
import { ZefDialogModule } from '@zerops/zef/dialog';

@NgModule({
  declarations: [ HttpRoutingActionsPopContainer ],
  imports: [
    CommonModule,
    MatListModule,
    MaterialStackModule,
    SatPopoverModule,
    ActionListItemModule,
    ZefDialogModule
  ],
  exports: [ HttpRoutingActionsPopContainer ]
})
export class HttpRoutingActionsPopModule {

}
