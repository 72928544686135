import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { NgStringPipesModule } from 'ngx-pipes';
import { PipelineDescComponent } from './pipeline-desc.component';
import { RouterModule } from '@angular/router';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ PipelineDescComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    NgStringPipesModule,
    ZuiDocsTextModule,
    ZefPipesModule
  ],
  exports: [ PipelineDescComponent ]
})
export class ZuiPipelineDescModule {

}
