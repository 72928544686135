export class DialogState {
  keys: Record<string, {
    state: boolean;
    meta?: any;
  }>;

  constructor() {
    this.keys = {};
  }
}

export interface DialogPayload {
  key: string;
  meta?: any;
}
