import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum WebsocketDebuggerStatuses {
  Opened = 'opened',
  Closed = 'closed'
}

export interface Connection {
  chainId: string;
  sessionId: string;
  requestId: string;
  date: Date;
}

@Injectable()
export class WebsocketDebuggerService {

  private _messages: any[] = [];
  private _successConnections: Connection[] = [];

  debuggerStatus$ = new Subject<WebsocketDebuggerStatuses>();
  successConnections$ = new Subject<Connection[]>();
  messages$ = new Subject<any[]>();
  lastMessageDate$ = new Subject<Date>();

  startDebugging(chainId: string, sessionId: string, requestId: string) {
    this.debuggerStatus$.next(WebsocketDebuggerStatuses.Opened);
    this._successConnections.push({
      chainId,
      sessionId,
      requestId,
      date: new Date()
    });
    this.successConnections$.next(this._successConnections);
  }

  addMessage(message: any) {
    if (message.type !== 'pong') {
      this.lastMessageDate$.next(new Date());

      this._messages.push({
        ...message,
        date: new Date()
      });
      this.messages$.next(this._messages);
    }
  }
}
