import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate
} from '@angular/animations';

export function menuPanelAnimation() {
  return trigger('menuPanelAnimation', [
    transition('* <=> *', [
      query(':enter', [
        style({
          opacity: 0,
          transform: 'translate3d(-4px, 0px, 0px)',
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)'
        }),
        group([
          animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            transform: 'translate3d(0px, 0px, 0px)',
            opacity: 1
          })),
          animate('150ms 100ms', style({
            boxShadow: '10px 0px 50px rgba(0, 0, 0, 0.2)'
          }))
        ])
      ], { optional: true }),
      query(':leave', [
        animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
          transform: 'translate3d(-4px, 0px, 0px)',
          opacity: 0
        }))
      ], { optional: true })
    ])
  ]);
}

export function routeAnimation() {
  return trigger('routeAnimation', [
    transition('* <=> *', [
      animateChild(),
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        }),
        animateChild()
      ], { optional: true }),
      group([
        query(':enter', [
          style({
            opacity: 0,
            transform: 'translate3d(0px, -2px, 0px)'
          }),
          animate('150ms 200ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
          }))
        ], { optional: true }),
        query(':leave', [
          style({
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px)'
          }),
          animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity: 0,
            transform: 'translate3d(0px, 2px, 0px)'
          }))
        ], { optional: true })
      ])
    ])
  ]);
}
