import { Component, OnDestroy } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { UserDataAddForm } from '@zerops/zui/user-data-add-form';
import { AppState } from '@zerops/zerops/app';
import { formValueOnValid } from '@zerops/zef/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './user-data-add-dialog.constant';
import { ThemeService } from '@zerops/zef/theme';

@Component({
  selector: 'z-user-data-add-dialog',
  templateUrl: './user-data-add-dialog.container.html',
  styleUrls: [ './user-data-add-dialog.container.scss' ]
})
export class UserDataAddDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onAdd$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Forms
  formState$ = this._userDataAddForm.state$;

  // # Data
  // -- sync
  addKey = this._userDataEntity.addOne.type;
  dialogKey = FEATURE_NAME;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  serviceStackId$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta as string)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    isDarkTheme: this._theme.isDarkMode$
  });

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    formValueOnValid(this._userDataAddForm),
    withLatestFrom(this.serviceStackId$),
    map(([ state, id ]) => this._userDataEntity.addOne({
      ...state,
      serviceStackId: id
    }))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _userDataAddForm: UserDataAddForm,
    private _userDataEntity: UserDataEntity,
    private _theme: ThemeService
  ) {
    super();

    this.$dispatchActions([
      this._addAction$,
      this._closeAction$
    ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
