import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { Currency } from '@zerops/models/settings';

@Component({
  selector: 'zui-price-desc',
  templateUrl: './price-desc.component.html',
  styleUrls: [ './price-desc.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDescComponent {

  @Input()
  currency: Currency;

  @Input()
  amount: number;

  @Input()
  decimalCount: number;

  @Input()
  desc: string;

  @Input()
  descTpl: ElementRef;

}
