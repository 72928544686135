import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './service-stack-type-field.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  serviceGroup: {
    managed: 'Managed services',
    static: 'Static apps and static content',
    bare: 'Containers and virtual machines',
    runtime: 'Runtime services',
    storage: 'Storage services',
  },
  serviceCategory: {
    database: 'Database',
    messaging: 'Message broker',
    phpWebserver: 'Web server with PHP',
    webserver: 'Web server',
    static: 'Static apps',
    runtimeEnvironment: 'Runtime environment',
    programmingLanguage: 'Programming language',
    searchEngine: 'Search engine',
    storage: 'Storage',
    linuxContainer: 'Linux container'
  }
};

export type ServiceStackTypeFieldTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ServiceStackTypeFieldTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
