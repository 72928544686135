import { Component, Input } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { ZefError } from '@zerops/zef/errors';

@Component({
  selector: 'zui-snack-error',
  templateUrl: './snack-error.container.html',
  styleUrls: [ './snack-error.container.scss' ]
})
export class SnackErrorContainer {
  @Input()
  data: ZefError;

  @Input()
  instance: MatSnackBarRef<any>;
}
