import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationComponent } from './duration.component';

@NgModule({
  declarations: [ DurationComponent ],
  imports: [
    CommonModule
  ],
  exports: [ DurationComponent ]
})
export class ZuiDurationModule {

}
