import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Currency } from '@zerops/models/settings';

@Component({
  selector: 'zui-price',
  templateUrl: './price.component.html',
  styleUrls: [ './price.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceComponent {

  @Input()
  amount: number;

  @Input()
  currency: Currency;

  @Input()
  prefix: string;

  @Input()
  decimalCount = 4;

  @Input()
  minDecimalCount = 2;

  @Input()
  set shrinkDecimals(v: string | boolean) {
    this._shrinkDecimals = coerceBooleanProperty(v);
  }
  get shrinkDecimals(): boolean {
    return this._shrinkDecimals;
  }

  private _shrinkDecimals: boolean;

}
