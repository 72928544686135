import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import { UserBaseState } from './user-base.model';
import {
  UserBaseActionUnion,
  setUserId,
  clearUserData,
  storeUserData,
  loadStoredUserDataSuccess,
  loadStoredUserData
} from './user-base.action';

const initialState = new UserBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    setUserId,
    (s, { id }): UserBaseState => ({
      ...s,
      activeUserId: id
    })
  ),
  on(
    clearUserData,
    (s): UserBaseState => ({
      ...s,
      activeUserId: undefined,
      activeClientUserId: undefined
    })
  ),
  on(
    loadStoredUserData,
    (s): UserBaseState => ({
      ...s
    })
  ),
  on(
    storeUserData,
    loadStoredUserDataSuccess,
    (s, { userId, clientUserId }): UserBaseState => ({
      ...s,
      activeUserId: userId,
      activeClientUserId: clientUserId
    })
  ),
  on(zefResetState, () => initialState)
);

export function userBaseReducer(
  state: UserBaseState,
  action: UserBaseActionUnion
) {
  return actionReducer(state, action);
}
