export const SVELTE_STATIC_IMPORT_YAML = `project:
  name: recipe-svelte
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: static
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-svelte-static`;
