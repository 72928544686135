import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@zerops/models/user';

@Injectable({ providedIn: 'root' })
export class UserBaseApi {
  constructor(private _http: HttpClient) { }

  load$() {
    return this._http.get<User>(`/api/user/info`);
  }

  setAvatar$(file: File) {

    const formData = new FormData();
    formData.append('file', file);

    return this._http.put<{ success: boolean; }>(
      '/api/avatar/',
      formData
    );

  }

  removeAvatar$() {
    return this._http.delete<{ success: boolean; }>(
      '/api/avatar/'
    );
  }
}
