<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  (closeTrigger)="onCloseDialog$.next()">
  <div class="__content">

    <ng-container *ngIf="!!state.addon || !!state.subscription">
      <zui-addon-card
        class="__addon-card"
        [metricId]="!!state.addon ? state.addon.metricId : state.subscription.metricId"
        [addonData]="state.addon"
        [subscriptionData]="state.subscription"
        [generalTranslations]="state.generalTranslations"
        [activeCurrency]="state.activeCurrency"
        [addonPrice]="pricelistMap[!!state.addon ? state.addon.metricId : state.subscription.metricId]"
        (linkClicked)="onCloseDialog$.next()">
      </zui-addon-card>
    </ng-container>

    <span
      *ngIf="!!state.addon && (state.activeClientStatus?.credit < pricelistMap[state.addon.metricId]
        && state.activeClientStatus?.promoCredit < pricelistMap[state.addon.metricId])"
      class="__credit-warning">

      {{ state.translations.creditWarning }}

      <button
        *ngIf="!state.isContabo"
        class="__topup-button  c-button--smaller"
        type="button"
        (click)="onOpenTopUpDialog$.next(); onCloseDialog$.next();"
        mat-flat-button>

        <mat-icon>add_circle_outline</mat-icon>

        Top up credit

      </button>

    </span>

    <div class="__buttons-grid">

      <div class="__grid-item">
        <zef-progress-error [key]="loadingKeys">
          <button
            class="__button c-button--full"
            [class.is-disabled]="!!state.addon && (state.activeClientStatus?.credit < pricelistMap[state.addon.metricId]
              && state.activeClientStatus?.promoCredit < pricelistMap[state.addon.metricId])"
            [disabled]="!!state.addon && (state.activeClientStatus?.credit < pricelistMap[state.addon.metricId]
              && state.activeClientStatus?.promoCredit < pricelistMap[state.addon.metricId])"
            [class.is-deactivated]="!!state.addon || (!!state.subscription && !state.subscription.recurringEnabled)"
            [class.is-activated]="!!state.subscription && state.subscription.recurringEnabled"
            type="button"
            (click)="!!state.addon
              ? this.onActivate$.next()
              : this.onModifyAutorenew$.next();"
            mat-stroked-button>

            <span>

              <ng-container *ngIf="!!state.addon">
                {{ state.translations?.activateAddon }}
              </ng-container>

              <ng-container *ngIf="!!state.subscription && state.subscription.recurringEnabled">
                {{ state.translations?.deactivateAutorenew }}
              </ng-container>

              <ng-container *ngIf="!!state.subscription && !state.subscription.recurringEnabled">
                {{ state.translations?.activateAutorenew }}
              </ng-container>

              <ng-container *ngIf="!!state.addon">
                (${{ state.addon.unitPrice }})
              </ng-container>

            </span>

          </button>
        </zef-progress-error>
      </div>

      <div class="__grid-item">
        <button
          class="c-button--full"
          type="button"
          (click)="onCloseDialog$.next()"
          mat-button>
          <span>{{ state.translations.close }}</span>
        </button>
      </div>

    </div>

  </div>
</zef-dialog>
