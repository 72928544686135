import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';

@Component({
  selector: 'zui-processes-pop-trigger',
  templateUrl: './processes-pop-trigger.component.html',
  styleUrls: [ './processes-pop-trigger.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessesPopTriggerComponent {

  @Input()
  runningCount = 0;

  @Input()
  pendingCount = 0;

  @Input()
  set simple(v: string | boolean) {
    this._simple = coerceBooleanProperty(v);
  }
  get simple(): boolean {
    return this._simple;
  }

  @Input()
  triggerRef: SatPopover;

  @Output()
  opened = new EventEmitter<void>();

  private _simple: boolean;

}
