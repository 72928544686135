import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { map } from 'rxjs/operators';
import { envFormatDialogOpen } from './env-format-dialog.action';
import { FEATURE_NAME } from './env-format-dialog.constant';

@Injectable()
export class EnvFormatDialogEffect {

  private _onPortRoutingAddDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(envFormatDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  constructor(
    private _actions$: Actions
  ) { }
}
