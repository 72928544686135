import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { validate, FormState, box, Boxed } from 'ngrx-forms';
import { maxLength, pattern, required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './shared-storage-service-stack-form.constant';

export interface SharedStorageServiceStackFormValue {
  name: string;
  storageStackIds: Boxed<string[]>;
  mode: 'HA' | 'NON_HA';
  customAutoscaling?: {
    horizontalAutoscaling?: {
      minContainerCount: number;
      maxContainerCount: number;
    };
    verticalAutoscaling?: {
      cpuMode: 'SHARED' | 'DEDICATED';
      startCpuCoreCount?: number;
      minFreeResource?: {
        cpuCoreCount?: number;
        cpuCorePercent?: number;
        memoryGBytes?: number;
        memoryPercent?: number;
      };
      maxResource: {
        cpuCoreCount: number;
        diskGBytes: number;
        memoryGBytes: number;
      };
      minResource: {
        cpuCoreCount: number;
        diskGBytes: number;
        memoryGBytes: number;
      };
    };
  };
}

export type SharedStorageServiceStackFormState = FormState<SharedStorageServiceStackFormValue>;

@Injectable({ providedIn: 'root' })
export class SharedStorageServiceStackForm extends ZefForm<SharedStorageServiceStackFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        name: '',
        storageStackIds: box([]),
        mode: 'HA',
        customAutoscaling: {}
      },
      {
        name: validate(required, maxLength(25), pattern(/^[a-z0-9]*$/))
      }
    );
  }
}
