import { createReducer, on } from '@ngrx/store';
import { NotificationsPopState } from './notifications-pop.model';
import {
  NotificationsPopActionUnion,
  notificationsPopSetSettings,
  notificationsPopSettingsReset
} from './notifications-pop.action';

const initialState = new NotificationsPopState();

export const actionReducer = createReducer(
  initialState,
  on(
    notificationsPopSetSettings,
    (s, { level, id }): NotificationsPopState => ({
      ...s,
      level,
      id
    })
  ),
  on(
    notificationsPopSettingsReset,
    () => initialState
  )
);

export function notificationsPopReducer(
  state = initialState,
  action: NotificationsPopActionUnion
) {
  return actionReducer(state, action);
}
