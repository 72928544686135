import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { RecipeComponent } from './recipe.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ZefPipesModule } from '@zerops/zef/pipes';

@NgModule({
  declarations: [ RecipeComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    MatListModule,
    ZuiSeparatorComponent,
    ZefProgressErrorModule,
    ZuiMoreButtonModule,
    SatPopoverModule,
    ActionListItemModule,
    ZefPipesModule
  ],
  exports: [ RecipeComponent ]
})
export class ZuiRecipeModule {

}
