import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { GithubRepoFormModule } from '@zerops/zerops/feature/github-repo-form';
import { GitlabRepoFormModule } from '@zerops/zerops/feature/gitlab-repo-form';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import {
  PhpNginxServiceStackFormComponent
} from './php-nginx-service-stack-form.component';
import {
  PhpNginxServiceStackFormTranslationsModule
} from './php-nginx-service-stack-form.translations';
import { HostnameFieldModule } from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';

@NgModule({
  declarations: [ PhpNginxServiceStackFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MatButtonModule,
    MatIconModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    ZuiAutoscalingFormFieldModule,
    CodeFieldModule,
    PhpNginxServiceStackFormTranslationsModule,
    UserDataFormFieldModule,
    GithubRepoFormModule,
    GitlabRepoFormModule,
    ZefPipesModule,
    ZuiDocsTextModule,
    ZuiSeparatorComponent
  ],
  exports: [ PhpNginxServiceStackFormComponent ]
})
export class PhpNginxServiceStackFormModule {

}
