<div class="fx:inline-grid  fx:align-items-center  fx:grid-auto-flow-column  fx:col-gap-2">
  <div>
    <zui-container-status-icon
      [size]="10"
      [status]="status()"
      [statusText]="statusText()">
    </zui-container-status-icon>
  </div>

  @if (type() && !hideBadge()) {
    <div>
      <zui-separator
        [spacing]="'none'"
        [size]="'mini'"
        [orientation]="'horizontal'"
      />
    </div>

    <div>
      <span
        [class.is-container]="type() === 'container'"
        [class.is-build]="type() === 'build'"
        [class.is-balancer]="type() === 'balancer'"
        class="__type-badge">
        @if (type() === 'container') {
          Container
        }
        @if (type() === 'build') {
          Build container
        }
        @if (type() === 'balancer') {
          Balancer
        }
      </span>
    </div>
  }
</div>

<strong class="__hostname" [class.has-tag]="type() && !hideBadge()">
  <zef-copy-to-clipboard [mode]="'basic'" [copyData]="hostname()">
    {{ hostname() | middleEllipsis: middleEllipsisLength() }}
  </zef-copy-to-clipboard>
</strong>

@if (!!containerId() || !!versionName() || !!created()) {
  <div class="__created-id">

    @if (!!containerId()) {
      #<strong>{{ containerId() }}</strong>
      <br />
    }

    @if (!!versionName()) {
      Active version: <strong>{{ versionName() }}</strong>
      <br />
    }

    @if (!!created()) {
      Created: <strong>{{ created() | date: 'medium' }}</strong>
    }

  </div>
}
