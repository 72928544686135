import { HistoryStatistic } from './statistics.model';

export function getHistoryStatisticsGroupRangeKey(key: string) {
  return key;
}

export function transformResourceHistoryServiceGroup(
  data: HistoryStatistic[],
  groupKey: string,
) {
  return data.reduce((obj, itm) => {
    if (obj[itm.serviceStackId] === undefined) {
      obj[itm.serviceStackId] = {
        [groupKey]: []
      }
    }

    obj[itm.serviceStackId][groupKey].push({
      ...itm,
      vCpuUsed: itm.vCpuUsed || 0,
      vCpuLimit: itm.vCpuLimit || 0
    });

    return obj;
  }, {});
}


export function transformResourceHistoryContainerGroup(
  data: HistoryStatistic[],
  groupKey: string
) {
  return data.reduce((obj, itm) => {

    if (obj[itm.containerId] === undefined) {
      obj[itm.containerId] = {
        [groupKey]: []
      }
    }

    obj[itm.containerId][groupKey].push(itm);

    return obj;
  }, {});
}
