import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { PortRoutingFormModule } from '@zerops/zui/port-routing-form';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { PopStateModule } from '@zerops/zui/pop-state';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { EffectsModule } from '@ngrx/effects';
import { PortRoutingAddDialogContainer } from './port-routing-add-dialog.container';
import { PortRoutingAddDialogEffect } from './port-routing-add-dialog.effect';
import { PortRoutingAddDialogTranslationsModule } from './port-routing-add-dialog.translations';

@NgModule({
  declarations: [ PortRoutingAddDialogContainer ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ PortRoutingAddDialogEffect ]),
    TranslocoModule,
    FormSectionModule,
    PortRoutingFormModule,
    ZefProgressErrorModule,
    PopStateModule,
    ZefDialogModule,
    PortRoutingAddDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ PortRoutingAddDialogContainer ]
})
export class PortRoutingAddDialogModule {

}
