import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectZefProgresses } from '@zerops/zef/progress';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { map, distinctUntilChanged, throttleTime, delay } from 'rxjs/operators';
import { AppState } from '@zerops/zerops/app';
import { globalLoadingAnimation } from './global-loading.animations';

@Component({
  selector: 'z-global-loading',
  templateUrl: './global-loading.container.html',
  styleUrls: [ './global-loading.container.scss' ],
  animations: [ globalLoadingAnimation() ]
})
export class GlobalLoadingContainer extends ZefReactiveComponent {

  // # Data
  // -- sync
  animationRunning = false;

  // -- angular
  @Input()
  logoWidth = 18;

  // -- async
  active$ = this._store.pipe(
    select(selectZefProgresses),
    throttleTime(300, undefined, { trailing: true }),
    map((d) => !!(d && d.length)),
    distinctUntilChanged(),
    delay(1)
  );

  // # State resolver
  state = this.$connect({
    active: this.active$
  });

  constructor(
    private _store: Store<AppState>
  ) {
    super();
  }
}
