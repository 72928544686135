export const MEDUSA_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-medusa
  corePackage: LIGHT
  envVariables:
    NEXT_STORE_URL: https://nextstore-\${zeropsSubdomainHost}-8000.prg1.zerops.app
    ANALOG_STORE_URL: https://analogstore-\${zeropsSubdomainHost}-3000.prg1.zerops.app
    MEDUSA_INSTANCE_URL: https://medusa-\${zeropsSubdomainHost}-9000.prg1.zerops.app
    IMGPROXY_INSTANCE_URL: https://imgproxy-\${zeropsSubdomainHost}.prg1.zerops.app
  tags:
    - zerops-recipe

services:
  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 100

  # # # # # # # # # # # # # # # # # # #
  # currently disabled as meilisearch #
  # is not supported in medusa v2 yet #
  # # # # # # # # # # # # # # # # # # #
  #
  # - hostname: search
  #   type: meilisearch@1.10
  #   mode: NON_HA
  #   priority: 100

  - hostname: redis
    type: valkey@7.2
    mode: NON_HA
    priority: 100

  - hostname: storage
    type: object-storage
    objectStorageSize: 2
    objectStoragePolicy: public-read
    priority: 100

  - hostname: imgproxy
    type: go@1.22
    buildFromGit: https://github.com/zeropsio/recipe-imgproxy
    enableSubdomainAccess: true
    envSecrets:
      IMGPROXY_KEY: <@generateRandomString(<32>) | toHex>
      IMGPROXY_SALT: <@generateRandomString(<32>) | toHex>
      IMGPROXY_ALLOWED_SOURCES: \${storage_apiUrl}/\${storage_bucketName}/
    priority: 90

  - hostname: medusa
    type: nodejs@22
    buildFromGit: https://github.com/zeropsio/recipe-medusa
    enableSubdomainAccess: true
    envSecrets:
      ADMIN_CORS: \${zeropsSubdomain}
      COOKIE_SECRET: <@generateRandomString(<32>)>
      JWT_SECRET: <@generateRandomString(<32>)>
      SUPERADMIN_EMAIL: admin@example.com
      SUPERADMIN_PASSWORD: <@generateRandomString(<16>)>
    verticalAutoscaling:
      minRam: 0.5
    priority: 90

  - hostname: nextstore
    type: nodejs@22
    buildFromGit: https://github.com/zeropsio/recipe-medusa-nextstore
    enableSubdomainAccess: true
    envSecrets:
      REVALIDATE_SECRET: <@generateRandomString(<16>)>
    verticalAutoscaling:
      minRam: 0.5

  - hostname: analogstore
    type: nodejs@22
    buildFromGit: https://github.com/zeropsio/recipe-medusa-analogstore
    enableSubdomainAccess: true
    verticalAutoscaling:
      minRam: 0.5`;
