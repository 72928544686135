import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'zui-errors-printer',
  templateUrl: './errors-printer.component.html',
  styleUrls: [ './errors-printer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorsPrinterComponent {

  @Input()
  pipelineErrors: PipelineError[];

  trackByCode(_: number, error: PipelineError) {
    return error.code;
  }

}
