<div
  class="__step  c-build-state-steps-step"
  [class.is-waiting]="_state === buildProcessStates.Waiting">

  <span class="__step_status  c-build-state-steps-step-status">
    <zui-process-step-state [state]="_state"></zui-process-step-state>
  </span>

  <ng-container *ngIf="!hideText">
    <div class="__step_text  c-build-state-steps-step-text" [ngSwitch]="_state">
      <ng-template [ngSwitchCase]="buildProcessStates.Waiting">
        Run build commands from&nbsp;zerops.yml
      </ng-template>
      <ng-template [ngSwitchCase]="buildProcessStates.Running">
        Running build commands from&nbsp;zerops.yml
      </ng-template>
      <ng-template [ngSwitchCase]="buildProcessStates.Finished">
        Build commands from zerops.yml ran&nbsp;successfully
      </ng-template>
      <ng-template [ngSwitchCase]="buildProcessStates.Failed">

        Build commands from zerops.yml failed

        <!-- Start: New solution for displaying BE errors. -->
        <div
          *ngIf="!!pipelineErrors?.[0]?.error"
          class="__error-card">
          <zef-scrollbar maxHeight="200px">
            <zui-errors-printer
              [pipelineErrors]="pipelineErrors">
            </zui-errors-printer>
          </zef-scrollbar>
        </div>
        <!-- End: New solution for displaying BE errors. -->

      </ng-template>
      <ng-template [ngSwitchCase]="buildProcessStates.Cancelled">
        Couldn't start build container, build step cancelled
      </ng-template>
    </div>
  </ng-container>

</div>
