import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './shared-storage-service-stack-form.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  nameTitle: `Shared storage name`,
  nameDesc: `Select on which name will your service <strong>communicate with other services</strong> within the project.
    You will be able to setup public access routing once the service stack is running</strong>.`,
  nameLabel: 'Name',
  namePlaceholder: 'Name your shared storage …',
  connectionsTitle: 'Mount disk to Services',
  connectionsDesc: `<strong>Connect</strong> the <a data-zui-docs="services/storage.html#shared-storage">shared storage</a>
  with your services within the project to enable data access from their local file system.`,
  haDesc: `High availability mode allows for the <a data-zui-docs="ha/zerops-enterprise-grade-reliability.html">
  highest possible reliability</a> and the most optimized performance.
  Each service with high availability enabled will run on at least two
  (<a data-zui-docs="services/runtimes.html">runtimes</a>) hardware-separated containers.`,
};

export type SharedStorageServiceStackFormTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class SharedStorageServiceStackFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
