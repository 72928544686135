export const JAVA_IMPORT_YAML = `project:
  name: recipe-java
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: java@17
    buildFromGit: https://github.com/zeropsio/recipe-java
    enableSubdomainAccess: true

  - hostname: db
    type: postgresql@16
    mode: NON_HA`;
