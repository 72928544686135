import { Input, Directive } from '@angular/core';

@Directive()
export class StatusIconBase<T = string> {
  @Input()
  status: T;

  @Input()
  statusText: T;

  @Input()
  size = 10;

  @Input()
  tooltip: string;
}
