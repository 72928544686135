import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { UserData } from '@zerops/models/user-data';
import { ZefSnackService } from '@zerops/zef/snack';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { FEATURE_NAME } from './env-format-dialog.constant';

@Component({
  selector: 'z-env-format-dialog',
  templateUrl: './env-format-dialog.container.html',
  styleUrls: [ './env-format-dialog.container.scss' ]
})
export class EnvFormatDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  formControl = new UntypedFormControl(undefined);
  envFormat = '';

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  userData$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta?.userData as UserData[]),
    filter((d) => !!d?.length)
  );

  // # State resolver
  state = this.$connect({
    open: this.open$
  });

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _clipboardService: Clipboard,
    private _snack: ZefSnackService
  ) {
    super();

    this.userData$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe((d) => {
      this.envFormat = '';
      d.forEach((item) => {
        this.envFormat +=  `${item.key}="${item.content}"\n`;
      });

      this.formControl.disable();
      this.formControl.setValue(this.envFormat);
    })

    this.$dispatchActions([ this._closeAction$ ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  copy() {
    this._clipboardService.copy(this.envFormat);
    this._snack.info$({ translation: 'general.copySuccess' });
  }

}
