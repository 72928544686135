import { Project } from '@zerops/models/project';
import { ServiceStack } from '@zerops/models/service-stack';
import { Avatar } from '@zerops/models/user';
import { AppVersionSource, AppVersionStatuses } from '../app-version';

export interface ProcessTypeMap {
  running: Process[];
  pending: Process[];
}

export enum ProcessStatuses {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  CANCELING = 'CANCELING'
}

export enum ProcessActions {
  stackCreate = 'stack.create',
  stackStart = 'stack.start',
  stackStop = 'stack.stop',
  stackRestart = 'stack.restart',
  stackReload = 'stack.reload',
  stackDelete = 'stack.delete',
  stackUpdateUserData = 'stack.updateUserData',
  stackDeploy = 'stack.deploy',
  stackUpgrade = 'stack.upgrade',
  stackUpdate = 'stack.update',
  stackBuild = 'stack.build',
  stackNginxConfig = 'stack.nginxConfig',
  stackEnableSubdomainAccess = 'stack.enableSubdomainAccess',
  syncHttpRouting = 'instance.syncPublicHttpRouting',
  syncPortRouting = 'instance.syncPublicPortRouting',
  instanceCreate = 'instance.create',
  instanceDelete = 'instance.delete',
  ipV4AddonActivation = 'publicIpRequest.request',
  configureRemoteLogging = 'instance.configureRemoteLogging'
}

export class Process {
  id: string;
  clientId: string;
  project: Partial<Project>;
  serviceStacks: ServiceStack[];
  serviceStackId: string;
  projectId: string;
  status: ProcessStatuses;
  appVersion?: {
    id: string;
    source: AppVersionSource;
    name: string;
    created: string;
    build?: {
      endDate: string;
      serviceStackId: string;
      serviceStackName: string;
      serviceStackTypeVersionId: string;
      startDate: string;
    };
    githubIntegration?: {
      branchName: string;
      commit: string;
      eventType: 'TAG' | 'BRANCH';
      pusher: string;
      repositoryFullName: string;
      tagName: string;
      tagRegex: string;
    };
    gitlabIntegration?: {
      branchName: string;
      commit: string;
      eventType: 'TAG' | 'BRANCH';
      pusher: string;
      repositoryFullName: string;
      tagName: string;
      tagRegex: string;
    };
    status: AppVersionStatuses;
  };
  createdByUser: {
    id: string;
    email: string;
    fullName: string;
    firstName: string;
    avatar: Avatar;
    type: 'GITHUB' | 'GITLAB' | 'CLI';
  };
  actionName: string;
  blockedByProcessId?: string;
  created: string;
  started: string;
  finished: string;
  lastUpdate: string;
}
