import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { syncHttpRoutingSuccess } from '@zerops/zerops/core/http-routing-base';
import { syncPortRoutingSuccess } from '@zerops/zerops/core/port-routing-base';
import { syncUserDataSuccess } from '@zerops/zerops/core/user-data-base';
import { zefDialogClose } from '@zerops/zef/dialog';
import { filter, map } from 'rxjs/operators';
import { FEATURE_NAME } from './sync-dialog.constant';

@Injectable()
export class SyncDialogEffect {

  private _onSync$ = this._actions$.pipe(
    ofType(
      syncHttpRoutingSuccess,
      syncPortRoutingSuccess,
      syncUserDataSuccess
    )
  );

  private _onSyncCloseDialog$ = createEffect(() => this._onSync$.pipe(
    filter(({ originalAction: { meta } }) => meta?.global),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  constructor(private _actions$: Actions) { }

}
