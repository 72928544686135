import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ProjectStatuses } from '@zerops/models/project';

@Component({
  selector: 'zui-ip-info-card',
  templateUrl: './ip-info-card.component.html',
  styleUrls: ['./ip-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpInfoCardComponent {

  @Input()
  ipV4: string;

  @Input()
  sharedIpV4: string;

  @Input()
  ipV4PendingRequest = false;

  @Input()
  ipV4AddonInProcess = false;

  @Input()
  ipV4Prefix: string;

  @Input()
  ipV6Prefix: string;

  @Input()
  ipV6: string;

  @Input()
  requestIpv4Key: string;

  @Input()
  requestIpV4ButtonText: string;

  @Input()
  projectName: string;

  @Input()
  projectStatus: ProjectStatuses;

  @Input()
  color: 'orange' | 'green' | 'base' = 'base';

  @Input()
  set showOnlyIpV4Button(v: string | boolean) {
    this._showOnlyIpV4Button = coerceBooleanProperty(v);
  }
  get showOnlyIpV4Button(): boolean {
    return this._showOnlyIpV4Button;
  }

  @Output()
  requestIpV4 = new EventEmitter<void>();

  private _showOnlyIpV4Button: boolean;

}
