<zef-dialog
  [open]="state.dialogState"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar class="__scroll" maxHeight="80vh">
      <div class="__content-inner">

        @if (!state.isLoadingEssentialData) {

            <!-- has payment source -->
          @if (state.paymentSources?.length) {
            <zui-form-section separator="none">

              <span *zuiFormSectionTitle>
                How much Zerops credit do you need?
              </span>

              <z-top-up-amount-form
                #topUpFormRef
                [state]="state.topUpAmountFormState">
              </z-top-up-amount-form>

            </zui-form-section>
          }

          <!-- no billing info -->
          @if (state.resolvedPaymentState === 'MISSING_BILLING_INFO') {
            <z-top-up-billing-info-form
              [autoscroll]="state.amountFormValid"
              [countryList]="state.countryList"
              [amount]="state.topUpAmountFormState.value.amount"
              [state]="state.billingInfoFormState">
              @if (!!state?.billingInfoFormState.controls.invoiceAddressCountryId.value || !!state.paymentSources?.length) {
                <z-top-up-amount-form
                  #topUpFormRef
                  [state]="state.topUpAmountFormState">
                </z-top-up-amount-form>
              }
            </z-top-up-billing-info-form>
        }

          <!-- has billing info but missing payment source  -->
          @if (state.resolvedPaymentState === 'MISSING_PAYMENT_SOURCE') {
            <zui-form-section separator="none">

              <span *zuiFormSectionTitle>
                How much Zerops credit do you need?
              </span>

              <z-top-up-amount-form
                #topUpFormRef
                [state]="state.topUpAmountFormState"
              />

            </zui-form-section>
          }

          <!-- overview + stripe + promo -->
          @if (!!state?.billingInfoFormState.controls.invoiceAddressCountryId.value || state.resolvedPaymentState === 'READY') {

            <!-- promo code  -->
            @if (state.showPromoCodePrompt === true) {
              <zui-form-section [separator]="'top'">

                <span *zuiFormSectionTitle>
                  Promo Code
                </span>

                <z-top-up-promo-code-form [state]="state.topUpPromoCodeFormState" />

              </zui-form-section>
            }

            <zui-form-section [separator]="'top'">

              <span *zuiFormSectionTitle>
                Overview & Payment
              </span>

              <span *zuiFormSectionDesc>
                We are using Stripe to securely handle the transaction, <br/>no sensitive data is saved or goes through our side.
              </span>

              <div class="__overview">

                <div class="__overview-item  fx:grid  fx:grid-cols-2">
                  <div class="__overview-item_key">Zerops credit</div>
                  <div class="fx:text-right">{{ state.topUpAmountFormState.controls.amount.value || 0 }}</div>
                </div>

                <div class="__overview-item  fx:grid  fx:grid-cols-2">
                  <div class="__overview-item_key">VAT</div>
                  <div class="fx:text-right">{{ state.vatRate }}%</div>
                </div>

                <div class="__overview-item  is-total  fx:grid  fx:grid-cols-2">
                  <div><strong>Total</strong></div>
                  <div class="fx:text-right"><strong>${{ (state.topUpAmountFormState.controls.amount.value || 0) * (((state.vatRate || 0) / 100) + 1) }}</strong></div>
                </div>

                @if (state.resolvedPaymentState === 'MISSING_PAYMENT_SOURCE' || (state.resolvedPaymentState === 'READY' && !!state.billingInfo?.current?.invoiceAddressCountryId)) {
                  <div class="__overview-item  __overview-item--billed  fx:grid">

                    <div class="__overview-item_key">Billed to</div>

                    <div>
                      <zui-billing-info
                        class="__billing-info"
                        [billingInfo]="state.billingInfo"
                        showCurrentOnly>
                      </zui-billing-info>
                    </div>

                  </div>
                }

              </div>

              <div class="__stripe-card-wrap">

                @if (state.resolvedPaymentState === 'MISSING_BILLING_INFO' || state.resolvedPaymentState === 'MISSING_PAYMENT_SOURCE') {
                  <div class="__stripe-card">

                    <span class="__badge c-payment-sources-badge">
                      <span class="__badge-content">

                        Payment processed by

                        <mat-icon
                          class="__stripe-icon"
                          svgIcon="stripe">
                        </mat-icon>

                      </span>
                    </span>

                    <ngx-stripe-card
                      [options]="cardOptions"
                      (change)="stripeCardChange($event)"
                      [elementsOptions]="elementsOptions">
                    </ngx-stripe-card>

                  </div>
                }

                @if (state.resolvedPaymentState === 'READY') {
                  <div class="__credit-card-section">
                    <zui-payment-sources
                      *ngIf="state.paymentSources?.length"
                      class="__payment-sources"
                      (removePaymentSource)="onRemovePaymentSource$.next($event)"
                      [paymentSources]="state.paymentSources">
                    </zui-payment-sources>
                  </div>
                }

              </div>

            </zui-form-section>

          }

        } @else {
          <div class="__loading-state">
            <mat-spinner
              class="__spinner"
              [diameter]="30">
            </mat-spinner>
          </div>
        }

      </div>
    </zef-scrollbar>

    <!-- buttons -->
    @if (!state.isLoadingEssentialData) {
      <div class="__buttons">
        <div class="__buttons-grid">

        <div>
          <zef-progress-error
            full
            [disabled]="!state.topUpAmountFormState?.isValid || (!state.stripeCardValid && !state.paymentSources?.length)"
            [key]="[ confirmPaymentKey, paymentRequestKey, updateBillingInfoKey ]">
            <button
              mat-raised-button
              color="accent"
              class="c-button--full  __top-up-button"
              (click)="onTopUp$.next(); this.markFormsAsSubmitted()"
              type="button">
              Top up credits
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            mat-button
            class="c-button--full"
            (click)="onClose$.next()"
            type="button">
            Close
          </button>
        </div>

        </div>
      </div>
    }

  </div>
</zef-dialog>
