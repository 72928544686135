import { themeConfig, themeDarkConfig, tokenConf } from './nginx-upport.config';
// import suggestions from './suggestions';
// import { directives } from './directives';

export const registerNginx = (monaco: any) => {

  monaco.languages.register({ id: 'nginx' });
  monaco.languages.setMonarchTokensProvider('nginx', tokenConf);
  monaco.editor.defineTheme('nginx-theme', themeConfig);
  monaco.editor.defineTheme('nginx-theme-dark', themeDarkConfig);

  // monaco.languages.registerCompletionItemProvider('nginx', {
  //   provideCompletionItems: (model: any, position: any) => {
  //     const word = model.getWordUntilPosition(position);
  //     const range = {
  //       startLineNumber: position.lineNumber,
  //       endLineNumber: position.lineNumber,
  //       startColumn: word.startColumn,
  //       endColumn: word.endColumn,
  //     };
  //     return { suggestions: suggestions(range) };
  //   },
  // });

  // monaco.languages.registerHoverProvider('nginx', {
  //   provideHover: (model: editor.ITextModel, position: Position, _: CancellationToken) => {
  //     const word = model.getWordAtPosition(position);
  //     if (!word) return;
  //     const data = directives.find((item) => item.n === word.word || item.n === `$${word.word}`);
  //     if (!data) return;
  //     const range = {
  //       startLineNumber: position.lineNumber,
  //       endLineNumber: position.lineNumber,
  //       startColumn: word.startColumn,
  //       endColumn: word.endColumn,
  //     };
  //     const contents = [{ value: `**\`${data.n}\`** | ${data.m} | ${data.c || ''}` }];
  //     if (data.s) {
  //       contents.push({ value: `**syntax:** ${data.s || ''}` });
  //     }
  //     if (data.v) {
  //       contents.push({ value: `**default:** ${data.v || ''}` });
  //     }
  //     if (data.d) {
  //       contents.push({ value: `${data.d}` });
  //     }
  //     return {
  //       contents: [...contents],
  //       range: range,
  //     };
  //   },
  // });
}
