<div
  class="__wrap  c-toggle-content-wrap"
  [ngClass]="{
    'has-main-active': activeIndex === 0,
    'has-alt-active': activeIndex === 1
  }">
  <div
    class="__item  c-toggle-content-item"
    [ngClass]="{
      '__item--main  c-toggle-content-item--main': index === 0,
      '__item--alt  c-toggle-content-item--alt': index === 1
    }"
    [ngStyle]="{
      left: index === 1 && position === 'start' ? 0 : undefined,
      right: index === 1 && position === 'end' ? 0 : undefined
    }"
    *ngFor="let item of itemsRefs; let index = index;">
    <ng-template [ngTemplateOutlet]="item.templateRef"></ng-template>
  </div>
</div>
