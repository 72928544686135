<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxWidth: '1300px', width: '100%' }"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <ng-container [ngSwitch]="state.serviceStack?.serviceStackTypeInfo?.serviceStackTypeCategory">
      <zef-scrollbar class="__scroll" showScrollButtonsV height="90vh">
        <div class="__wrap">
          <z-ready-to-deploy
            *ngIf="state.open"
            [serviceStackId]="state.serviceStackId">
            <div class="__close-button-wrap">
              <button
                class="__close-button c-button"
                (click)="onClose$.next()"
                type="button"
                mat-flat-button>
                <mat-icon>snooze</mat-icon> Close for now, finish later from service detail?
              </button>
            </div>
          </z-ready-to-deploy>
        </div>
      </zef-scrollbar>
    </ng-container>

    <button
      class="__dialog-close-button"
      type="button"
      (click)="onClose$.next()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>