import { Injectable, inject } from '@angular/core';
import { delay, map, mergeMap, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ZefSnackService } from '@zerops/zef/snack';
import { onZefDialogClose, onZefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { updateProjectCustomEnvsSuccess } from '@zerops/zerops/core/project-base/project-base.action';
import { FEATURE_NAME } from './project-envs-edit-dialog.constant';
import { ProjectEnvsEditDialogForm } from './project-envs-edit-dialog.form';
import { ProjectEnv } from '@zerops/models/project';

@Injectable()
export class ProjectEnvsEditDialogEffect {

  /**
   * Dependency injections.
   */
  #actions$ = inject(Actions);
  #snack = inject(ZefSnackService);
  #projectEnvsEditDialogForm = inject(ProjectEnvsEditDialogForm);

  /**
   * Event streams properties.
   */
  #updateSuccess$ = this.#actions$.pipe(ofType(updateProjectCustomEnvsSuccess));

  /**
   * Effect hooks.
   */
  onDialogOpenSetValue$ = createEffect(() => this.#actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    map(({ meta: { projectEnvs } }) => this.#projectEnvsEditDialogForm.setValue({
      content: projectEnvs?.reduce((str: string, itm: ProjectEnv) => {
        str = str + `${itm.key}=${itm.content}\n`;
        return str;
      }, '')
    }))
  ));

  onUpdateSuccessCloseDialog$ = createEffect(() => this.#updateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDialogCloseResetForm$ = createEffect(() => this.#actions$.pipe(
    onZefDialogClose(FEATURE_NAME),
    delay(200),
    mergeMap(() => [
      this.#projectEnvsEditDialogForm.reset(),
      this.#projectEnvsEditDialogForm.setDefaultValues()
    ])
  ));

  onUpdateSuccessNotification$ = createEffect(() => this.#updateSuccess$.pipe(
    switchMap(() => this.#snack.success$({ translation: `${FEATURE_NAME}.snackSuccessMessage` }))
  ), { dispatch: false });

}
