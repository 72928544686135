import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AppVersionStatuses,
  AppVersionGithubIntegration,
  AppVersionBuild,
  AppVersionSource,
  AppVersionGitlabIntegration,
  AppVersion,
  AppVersionPublicGitSource
} from '@zerops/models/app-version';
import { differenceInMinutes } from 'date-fns/esm';
import { AppVersionItemTranslations } from './app-version-item.translations';

export enum AppVersionItemModes {
  Normal = 'normal',
  Shrinked = 'shrinked',
  Log = 'log',
  Condensed = 'condensed'
}

@Component({
  selector: 'zui-app-version-item',
  templateUrl: './app-version-item.component.html',
  styleUrls: ['./app-version-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionItemComponent {

  @Input()
  data: AppVersion;

  @Input()
  status: AppVersionStatuses;

  @Input()
  id: string;

  @Input()
  name: string;

  @Input()
  projectId: string;

  @Input()
  serviceStackName: string;

  @Input()
  source: AppVersionSource;

  @Input()
  set buildData(v) {
    this._buildData = v;

    this.buildTook = (v?.endDate && v?.startDate)
      ? differenceInMinutes(new Date(v.endDate), new Date(v.startDate))
      : undefined;
  }
  get buildData() {
    return this._buildData;
  }

  @Input()
  githubIntegration: AppVersionGithubIntegration;

  @Input()
  gitlabIntegration: AppVersionGitlabIntegration;

  @Input()
  publicGitSource: AppVersionPublicGitSource;

  @Input()
  created: string;

  @Input()
  activated: string;

  @Input()
  lastUpdate: string;

  @Input()
  username: string;

  @Input()
  translations: AppVersionItemTranslations;

  @Input()
  activateLoading = false;

  @Input()
  deleteLoading = false;

  @Input()
  mode = AppVersionItemModes.Normal;

  @Input()
  set hideTimestamp(v: string | boolean) {
    this._hideTimestamp = coerceBooleanProperty(v);
  }
  get hideTimestamp(): boolean {
    return this._hideTimestamp;
  }

  @Input()
  set showOnlyBuildInfo(v: string | boolean) {
    this._showOnlyBuildInfo = coerceBooleanProperty(v);
  }
  get showOnlyBuildInfo(): boolean {
    return this._showOnlyBuildInfo;
  }

  @Output()
  activateVersion = new EventEmitter<string>();

  @Output()
  deleteVersion = new EventEmitter<string>();

  appVersionStatuses = AppVersionStatuses;
  buildTook: number;
  modes = AppVersionItemModes;
  private _hideTimestamp = false;
  private _showOnlyBuildInfo = false;
  private _buildData: AppVersionBuild;

}
