import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { UserDataFieldsComponent } from './user-data-fields.component';

@NgModule({
  declarations: [ UserDataFieldsComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    ReactiveFormsModule,
    ZuiMaterialFormStackModule
  ],
  exports: [ UserDataFieldsComponent ]
})
export class UserDataFieldsModule {

}
