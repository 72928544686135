import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ProjectEnvEditFormModule } from '@zerops/zui/project-env-edit-form';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { ProjectEnvEditDialogContainer } from './project-env-edit-dialog.container';
import { ProjectEnvEditDialogEffect } from './project-env-edit-dialog.effect';
import { ProjectEnvEditDialogTranslationsModule } from './project-env-edit-dialog.translations';

@NgModule({
  declarations: [ ProjectEnvEditDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    ProjectEnvEditFormModule,
    EffectsModule.forFeature([ ProjectEnvEditDialogEffect ]),
    FormSectionModule,
    ReactiveFormsModule,
    ZefProgressErrorModule,
    TranslocoModule,
    UserDataFieldsModule,
    ZefDialogModule,
    ProjectEnvEditDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ ProjectEnvEditDialogContainer ]
})
export class ProjectEnvEditDialogModule {}
