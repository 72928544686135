import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProjectStatuses } from '@zerops/models/project';
import { StatusIconBase } from '@zerops/zui/status-icon-base';

@Component({
  selector: 'zui-project-status-icon',
  templateUrl: './project-status-icon.component.html',
  styleUrls: [ './project-status-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectStatusIconComponent extends StatusIconBase<ProjectStatuses> {

  pulsingStatusesMap = {
    [ProjectStatuses.Creating]: true,
    [ProjectStatuses.Deleting]: true,
    [ProjectStatuses.Stopping]: true,
    [ProjectStatuses.Starting]: true
  };

  @Input()
  amount: number;
}
