import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectEnvEditFormComponent } from './project-env-edit-form.component';
import { ProjectEnvEditFormTranslationsModule } from './project-env-edit-form.translations';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefFormModule } from '@zerops/zef/forms/modules';

@NgModule({
  declarations: [ ProjectEnvEditFormComponent ],
  imports: [
    CommonModule,
    ProjectEnvEditFormTranslationsModule,
    UserDataFieldsModule,
    ZefFormModule
  ],
  exports: [ ProjectEnvEditFormComponent ]
})
export class ProjectEnvEditFormModule {

}
