<zef-form
  [state]="state"
  (submit)="submit.emit(state)">

  <zui-user-data-fields
    [keyControlState]="state?.controls.key"
    [contentControlState]="state?.controls.content">
  </zui-user-data-fields>

  <ng-content></ng-content>

</zef-form>
