import { on, createReducer } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import {
  StatisticsIds,
  ZEROPS_HOURLY_RESOURCE_PRICES, StatisticsGroupBy
} from '@zerops/models/resource-statistics';
import {
  loadCurrentStatisticsSuccess,
  StatisticsBaseActionUnion,
  loadCurrentStatisticsUpdate,
  loadResourceHistoryStatisticsSuccess, loadResourceHistoryStatisticsUpdate
} from './resource-statistics-base.action';
import {
  HistoryStatisticsGroupUpdateSuccessPayload,
  ResourceStatisticsBaseState
} from './resource-statistics-base.model';
import { historyResourceStatisticsMerge } from './resource-statistics-base.utils';

const initialState = new ResourceStatisticsBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    loadCurrentStatisticsSuccess,
    loadCurrentStatisticsUpdate,
    (s, { data }): ResourceStatisticsBaseState => ({
      ...s,
      currentContainerMap: data.key === StatisticsGroupBy.Container
        ? data.items.reduce((obj, itm) => {
            obj[itm[StatisticsIds.Container]] = itm;
            return obj;
          }, {})
        : { ...s.currentContainerMap },
      currentServiceStackMap: data.key === StatisticsGroupBy.Service
        ? data.items.reduce((obj, itm) => {
            obj[itm[StatisticsIds.ServiceStack]] = itm;
            return obj;
          }, {})
        : { ...s.currentServiceStackMap },
      currentCostMap: data.key === StatisticsGroupBy.Service
        ? data.items.reduce((obj, itm) => {
            const cpuCost = (itm.cpu.limit > 0
              ? itm.cpu.limit  * ZEROPS_HOURLY_RESOURCE_PRICES.cpu
              : itm.vCpu.limit  * ZEROPS_HOURLY_RESOURCE_PRICES.vcpu);

            const cost = ((itm.diskGBytes.limit * ZEROPS_HOURLY_RESOURCE_PRICES.disc)
            + cpuCost
            + (itm.ramGBytes.limit * ZEROPS_HOURLY_RESOURCE_PRICES.ram));

            obj.client = obj.client + cost;

            if (obj.projects[itm.projectId] === undefined) {
              obj.projects[itm.projectId] = 0;
            }

            if (obj.services[itm.serviceStackId] === undefined) {
              obj.services[itm.serviceStackId] = 0;
            }

            obj.projects[itm.projectId] = obj.projects[itm.projectId] + cost;
            obj.services[itm.serviceStackId] = obj.services[itm.serviceStackId] + cost;

            obj.serviceResources[itm.serviceStackId] = {
              disc: (itm.diskGBytes.limit * ZEROPS_HOURLY_RESOURCE_PRICES.disc),
              cpu: cpuCost,
              ram: (itm.ramGBytes.limit * ZEROPS_HOURLY_RESOURCE_PRICES.ram)
            };

            return obj;
          }, {
            client: 0,
            projects: {},
            services: {},
            serviceResources: {}
          })
        : { ...s.currentCostMap }
      })
  ),
  on(
    loadResourceHistoryStatisticsSuccess,
    (s, { data }): ResourceStatisticsBaseState => mergeHistoryStatisticsGroup(s, data)
  ),
  on(
    loadResourceHistoryStatisticsUpdate,
    (s, props): ResourceStatisticsBaseState => mergeHistoryStatisticsGroup(s, props)
  ),
  on(zefResetState, () => initialState)
);

export function resourceStatisticsBaseReducer(
  state: ResourceStatisticsBaseState,
  action: StatisticsBaseActionUnion
) {
  return actionReducer(state, action);
}

function mergeHistoryStatisticsGroup(
  s: ResourceStatisticsBaseState,
  data: HistoryStatisticsGroupUpdateSuccessPayload
) {
  return {
    ...s,
    historyData: historyResourceStatisticsMerge(data, s.historyData)
  };
}
