import { Injectable } from '@angular/core'

@Injectable()
export class IntercomConfig {
  /**
   * Custom property to manage Intercom updates when the Angular routing
   * happens. It is not the native one from the Intercom API.
   */
  updateOnRouterChange?: boolean;

  /**
   * Native Intercom API properties follow.
   */

  /**
   * Intercom application id.
   */
  app_id: string;
  /**
   * Customize left or right position of messenger.
   */
  alignment?: 'left' | 'right';
  /**
   * Customize horizontal padding.
   */
  horizontal_padding?: number;

  /**
   * Customize vertical padding.
   */
  vertical_padding?: number;

  /**
   * Arbitrarily localize your Intercom messenger.
   */
  language_override?: string;

  /**
   * Affect the default behavior of the Intercom launcher.
   */
  hide_default_launcher?: boolean;
}
