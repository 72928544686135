import { NgModule } from '@angular/core';
import { FuseHighlightComponent } from './fuse-highlight.component';

@NgModule({
  declarations: [ FuseHighlightComponent ],
  exports: [ FuseHighlightComponent ]
})
export class ZefFuseModule {

}
