import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef/entities';
import { map } from 'rxjs/operators';
import { ClientStatusEntity } from './client-status-base.entity';

@Injectable()
export class ClientStatusBaseEffect {

  // client status list / update
  private _setupClientStatusListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientStatusEntity.listSubscribe(
      clientId
    ))
  ));

  private _setupClientStatusUpdateStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientStatusEntity.updateSubscribe(clientId)
  )));

  private _onClientStatusUpdateChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._clientStatusEntity)
  ));

  constructor(
    private _userEntity: UserEntity,
    private _clientStatusEntity: ClientStatusEntity,
    private _actions$: Actions
  ) { }
}
