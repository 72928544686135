<div
  *ngIf="!hideOutOfSync && !data.isSynced"
  class="__out-of-sync">
  <div class="__out-of-sync_update" *ngIf="!data.isSynced && !data.deleteOnSync && !!data.lastSync">
    <span>Publish changes to propagate updates</span> <mat-icon class="__out-of-sync_icon">sync</mat-icon>
  </div>
  <div class="__out-of-sync_deletion" *ngIf="!data.isSynced && data.deleteOnSync && !!data.lastSync">
    <span>Publish changes to confirm deletion</span> <mat-icon class="__out-of-sync_icon">delete_outline</mat-icon>
  </div>
  <div class="__out-of-sync_new" *ngIf="!data.isSynced && !data.lastSync">
    <span>Publish changes to the open public Port</span> <mat-icon class="__out-of-sync_icon">add</mat-icon>
  </div>
</div>

<!-- is ipv6 -->
<div *ngIf="type === publicIpTypes.IpV6" class="__item">
  <div class="__item_public-port">
    <span class="__item_public-port-ip"><span class="__port-bracket">[</span>{{ ip }}<span class="__port-bracket">]</span></span>
    <strong class="__item_public-port-port">:{{ data.publicPort }}</strong>
  </div>
  <div class="__item_separator">
    <mat-icon class="__item_arrow">arrow_right_alt</mat-icon>
  </div>
  <div class="__item_internal-port">

    <ng-container *ngIf="!!data.serviceStackName && !!data.serviceStackId && location === 'other'; else stackNameNoLinkRef">
      {{ data.internalProtocol }}://<a class="c-internal-link" [routerLink]="[ '/service-stack', data.serviceStackId, 'routing' ]">{{ data.serviceStackName }}</a>
    </ng-container>:{{ data.internalPort }}
  </div>
</div>

<!-- is ipv4 -->
<div *ngIf="type === publicIpTypes.IpV4" class="__item">
  <div class="__item_public-port">
    <span class="__item_public-port-ip">{{ ip }}</span>
    <strong class="__item_public-port-port">:{{ data.publicPort }}</strong>
  </div>
  <div class="__item_separator">
    <mat-icon class="__item_arrow">arrow_right_alt</mat-icon>
  </div>
  <div class="__item_internal-port">

    <ng-container *ngIf="!!data.serviceStackName && !!data.serviceStackId && location === 'other'; else stackNameNoLinkRef">
      {{ data.internalProtocol }}://<a class="c-internal-link" [routerLink]="[ '/service-stack', data.serviceStackId, 'routing' ]">{{ data.serviceStackName }}</a>
    </ng-container>:{{ data.internalPort }}

  </div>
</div>

<ng-template #stackNameNoLinkRef>{{ data.internalProtocol }}://{{ data.serviceStackName }}</ng-template>

<ng-container *ngIf="!data?.firewallIpRanges?.length">
  <div class="__firewall-empty-state">
    <h4 class="__firewall-title  __firewall-title--public">
      Access Open to <mat-icon class="__firewal-title_icon">public</mat-icon> Public<ng-container *ngIf="!disableEdit">, <button (click)="editOpen.emit()" type="button" class="c-button-reset  __enabled-firewall-button">Enable Firewall</button>?</ng-container>
    </h4>
  </div>
</ng-container>

<ng-container *ngIf="data?.firewallIpRanges?.length">

  <div class="__firewall-separator">
    <zui-separator
      [position]="'start'"
      [size]="'small'"
      [spacing]="'mini'">
    </zui-separator>
  </div>

  <div
    class="__firewall"
    [class.is-blacklist]="data.firewallPolicy === firewallPolicies.Blacklist"
    [class.is-whitelist]="data.firewallPolicy === firewallPolicies.Whitelist">

    <h4 class="__firewall-title  __firewall-title--protected">
      <mat-icon class="__firewal-title_icon">security</mat-icon> Firewall <strong>Enabled</strong>
    </h4>

    <span class="__firewall-desc">

      <ng-container *ngIf="data.firewallPolicy === firewallPolicies.Blacklist">
        Blacklisted IP addresses
      </ng-container>

      <ng-container *ngIf="data.firewallPolicy === firewallPolicies.Whitelist">
        Whitelisted IP addresses
      </ng-container>

    </span>

    <div class="__firewall_item" *ngFor="let item of data?.firewallIpRanges">
      <mat-icon class="__firewall_item-icon">{{ data.firewallPolicy === firewallPolicies.Whitelist ? 'lock_open' : 'lock' }}</mat-icon> {{ item }}
    </div>

  </div>

</ng-container>
