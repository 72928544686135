<div class="__wrap">

  <div class="__title"><strong>Current daily cost limit</strong> <br/> {{ ((creditLimit - remainingCredit) / creditLimit * 100) | number: '.1-2' }}% spent today, restores in <zui-duration [start]="nowDateString" [end]="endOfDayDateString"></zui-duration></div>

  <zui-price-desc
    class="__spend"
    [amount]="creditLimit"
    [decimalCount]="1"
    [currency]="activeCurrency">
  </zui-price-desc>

</div>

<div
  *ngIf="!!creditLimit"
  class="__controls-grid has-two-items">

  <div>
    <button
      class="__change-limit-button c-button c-button--smaller c-button--full"
      mat-button
      type="button"
      color="accent"
      (click)="changeLimitClicked.emit()">
      Change limit
    </button>
  </div>

  <div>
    <zef-progress-error
      full
      [loading]="removeLoading">
      <button
        class="__remove-limit-button c-button c-button--smaller c-button--full"
        mat-button
        type="button"
        (click)="removeLimitClicked.emit()">
        Remove limit
      </button>
    </zef-progress-error>
  </div>

</div>

<div
  *ngIf="(!!remainingCredit
    && lifetimeScore !== null
    && lifetimeScore > 0
    && lifetimeScore <= 8
    && lifetimeScore < hoursTillEndOfDay
    && totalCredit > remainingCredit)
      || (!!remainingCredit
        && lifetimeScore !== null
        && lifetimeScore > 0
        && lifetimeScore <= 2
        && lifetimeScore < hoursTillEndOfDay
        && totalCredit > remainingCredit)"
  class="__text"
  [ngClass]="{
    'is-warning': !!remainingCredit
      && lifetimeScore !== null
      && lifetimeScore > 0
      && lifetimeScore <= 8
      && lifetimeScore < hoursTillEndOfDay
      && totalCredit > remainingCredit,
    'is-error': !!remainingCredit
      && lifetimeScore !== null
      && lifetimeScore > 0
      && lifetimeScore <= 2
      && lifetimeScore < hoursTillEndOfDay
      && totalCredit > remainingCredit
  }">

  <ng-container *ngIf="!!remainingCredit
    && lifetimeScore !== null
    && lifetimeScore > 0
    && lifetimeScore <= 8
    && lifetimeScore < hoursTillEndOfDay
    && totalCredit > remainingCredit">

    <mat-icon
      *ngIf="lifetimeScore <= 8 && lifetimeScore > 2"
      class="__text-icon">
      warning_amber
    </mat-icon>

    <mat-icon
      *ngIf="lifetimeScore <= 2"
      class="__text-icon">
      error_outline
    </mat-icon>

    Daily cost limit

   <strong>${{ creditLimit }}</strong>

    will be exceeded in

    <zui-duration [start]="lifeTimeScoreDates?.startDate" [end]="lifeTimeScoreDates?.endDate"></zui-duration>

    by given the current total hourly consumption of the project. Increase the limit to prevent from stopping the project.

  </ng-container>

  <ng-container *ngIf="remainingCredit <= 0 && totalCredit > remainingCredit">

    <mat-icon class="__text-icon">error_outline</mat-icon>

    Daily cost limit

    <strong>${{ creditLimit }}</strong>

    was exceeded. Increase the limit and project manually.

  </ng-container>

</div>