import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiAppVersionItemModule } from '@zerops/zui/app-version-item';
import { ZuiProcessNotificationDurationModule } from '@zerops/zui/process-notification-duration';
import { ZuiBuildStateStepsModule } from '@zerops/zui/build-state-steps';
import { ZuiPipelineDescModule } from '@zerops/zui/pipeline-desc';
import { ZuiProcessNotificationShellModule } from '@zerops/zui/process-notification-shell';
import { ZuiProcessNotificationDescModule } from '@zerops/zui/process-notification-desc';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { NotificationItemComponent } from './notification-item.component';
import { NotificationItemTranslationsModule } from './notification-item.translations';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';
import { ZuiErrorsPrinterModule } from '../errors-printer';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ NotificationItemComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    ZuiMaterialStackModule,
    NotificationItemTranslationsModule,
    ZefPipesModule,
    ZuiSeparatorComponent,
    ZuiAppVersionItemModule,
    ZuiProcessNotificationShellModule,
    ZuiProcessNotificationDurationModule,
    ZuiBuildStateStepsModule,
    ZuiPipelineDescModule,
    ZuiProcessNotificationDescModule,
    ProgressSpinnerModule,
    ZuiDocsTextModule,
    ZefMessageFormatDirective,
    ZuiErrorsPrinterModule,
    ZefScrollbarComponent
  ],
  exports: [ NotificationItemComponent ]
})
export class NotificationItemModule {

}
