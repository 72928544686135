import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME } from './service-stack-base.constant';
import { ServiceStackBaseState } from './service-stack-base.model';

export const selectServiceStackBaseState = createFeatureSelector<ServiceStackBaseState>(FEATURE_NAME);

export const selectContainerFileByIdAndPath = (id: string, path: string) => createSelector(
  selectServiceStackBaseState,
  (state) => state && state.containerFiles && state.containerFiles[id] && state.containerFiles[id][path]
);

export const selectBackupsById = (id: string) => createSelector(
  selectServiceStackBaseState,
  (state) => state.backups[id]
);

