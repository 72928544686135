import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'zui-client-list-item',
  templateUrl: './client-list-item.component.html',
  styleUrls: [ './client-list-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientListItemComponent {
  @Input()
  web: string;

  @Input()
  name: string;

  @Input()
  maxNameWidth = 280;

  @Input()
  avatar: string;

  @Input()
  avatarSize = 30;

  @Input()
  clientId: string;

  @Input()
  copyButtonTooltip: string;

  @Input()
  showId: boolean;

  @Input()
  middleEllipsisMaxLength = 30;
}
