import { NgModule } from '@angular/core';
import { ZefFormElementDirective } from './form-element.directive';

@NgModule({
  declarations: [ ZefFormElementDirective ],
  exports: [ ZefFormElementDirective ]
})
export class ZefFormElementModule {

}
