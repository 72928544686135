import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { RunBuildStepComponent } from './run-build-step.component';
import { ZuiErrorsPrinterModule } from '@zerops/zui/errors-printer';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ RunBuildStepComponent ],
  imports: [
    CommonModule,
    ZuiProcessStepStateModule,
    ZuiDocsTextModule,
    ZuiErrorsPrinterModule,
    ZefScrollbarComponent
  ],
  exports: [ RunBuildStepComponent ]
})
export class ZuiRunBuildStepModule {

}
