<sat-popover
  #popoverRef
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'start'"
  [horizontalAlign]="'end'"
  hasBackdrop
  (closed)="popClosed.emit()"
  (backdropClicked)="popoverRef.close()">
  <mat-card class="__more-card  z-manual-elevation  c-soft-elevation">
    <mat-action-list>

      <button
        mat-list-item
        (click)="editClicked.emit(state.httpRouting); popoverRef.close();"
        type="button"
        [disabled]="state.deleteOnSync">
        <zui-action-list-item
          [icon]="'edit'"
          [label]="state.generalTranslations.edit">
        </zui-action-list-item>
      </button>

      <button
        *ngIf="!state.deleteOnSync"
        mat-list-item
        type="button"
        (click)="onHttpRoutingDelete$.next()">
        <zui-action-list-item
          hideContent
          [isLoading]="state.isDeleteLoading"
          [icon]="'delete_outline'"
          [label]="state.generalTranslations.delete">
        </zui-action-list-item>
      </button>

      <button
        *ngIf="state.deleteOnSync"
        mat-list-item
        type="button"
        (click)="onHttpRoutingRestore$.next()">
        <zui-action-list-item
          hideContent
          [isLoading]="state.isRestoreLoading"
          [icon]="'restore'"
          [label]="state.generalTranslations.restore">
        </zui-action-list-item>
      </button>

    </mat-action-list>
  </mat-card>
</sat-popover>
