import { Inject, Injectable, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeAdapter } from './datetime-adapter';
import {
  addMonths,
  setYear,
  setMonth,
  setDate,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  getYear,
  getMonth,
  getDate,
  startOfMonth,
  isSameMonth,
  isSameYear,
  parse as dateFnsParse,
} from 'date-fns/esm';

function extendObject(dest: any, ...sources: any[]): any {
  if (dest == null) {
    throw TypeError('Cannot convert undefined or null to object');
  }

  for (const source of sources) {
    if (source != null) {
      for (const key in source) {
        // eslint-disable-next-line no-prototype-builtins
        if (source.hasOwnProperty(key)) {
          dest[key] = source[key];
        }
      }
    }
  }

  return dest;
}

const SUPPORTS_INTL_API = typeof Intl !== 'undefined';

/** The default month names to use if Intl API is not available. */
const DEFAULT_MONTH_NAMES = {
  'long': [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ],
  'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  'narrow': ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
};

/** The default date names to use if Intl API is not available. */
const DEFAULT_DATE_NAMES = range(31, i => String(i + 1));

/** The default day of the week names to use if Intl API is not available. */
const DEFAULT_DAY_OF_WEEK_NAMES = {
  'long': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  'short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  'narrow': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
};

const DEFAULT_HOUR_NAMES = range(24, (i) => String(i));
const DEFAULT_MINUTE_NAMES = range(60, (i) => String(i));

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class DateFnsDatetimeAdapter extends DatetimeAdapter<Date> {

  useUtcForDisplay = true;

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    _delegate: DateAdapter<Date>
  ) {
    super(_delegate);
    this.setLocale(matDateLocale);
  }

  // Adapted methods using 'date-fns'
  clone(date: Date): Date {
    return new Date(date.getTime());
  }

  getYear(date: Date): number {
    return getYear(date);
  }

  getMonth(date: Date): number {
    return getMonth(date);
  }

  getDate(date: Date): number {
    return getDate(date);
  }

  getHour(date: Date): number {
    return getHours(date);
  }

  getMinute(date: Date): number {
    return getMinutes(date);
  }

  isInNextMonth(startDate: Date, endDate: Date): boolean {
    const nextMonth = addMonths(startOfMonth(startDate), 1);
    return isSameMonth(nextMonth, endDate) && isSameYear(nextMonth, endDate);
  }

  createDatetime(year: number, month: number, date: number, hour: number, minute: number): Date {
    let result = new Date();
    result = setYear(result, year);
    result = setMonth(result, month);
    result = setDate(result, date);
    result = setHours(result, hour);
    result = setMinutes(result, minute);

    if (getMonth(result) !== month || getDate(result) !== date) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  getFirstDateOfMonth(date: Date): Date {
    return startOfMonth(date);
  }

  getHourNames(): string[] {
    return DEFAULT_HOUR_NAMES;
  }

  getMinuteNames(): string[] {
    return DEFAULT_MINUTE_NAMES;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // if (SUPPORTS_INTL_API) {
    //   const dtf = new Intl.DateTimeFormat(this.locale, {month: style});
    //   return range(12, i => this._stripDirectionalityCharacters(dtf.format(new Date(2017, i, 1))));
    // }
    return DEFAULT_MONTH_NAMES[style];
  }

  getDateNames(): string[] {
    // if (SUPPORTS_INTL_API) {
    //   const dtf = new Intl.DateTimeFormat(this.locale, {day: 'numeric'});
    //   return range(31, i => this._stripDirectionalityCharacters(
    //       dtf.format(new Date(2017, 0, i + 1))));
    // }
    return DEFAULT_DATE_NAMES;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    // if (SUPPORTS_INTL_API) {
    //   const dtf = new Intl.DateTimeFormat(this.locale, {weekday: style});
    //   return range(7, i => this._stripDirectionalityCharacters(
    //       dtf.format(new Date(2017, 0, i + 1))));
    // }
    return DEFAULT_DAY_OF_WEEK_NAMES[style];
  }

  getYearName(date: Date): string {
    if (SUPPORTS_INTL_API) {
      const dtf = new Intl.DateTimeFormat(this.locale, {year: 'numeric'});
      return this._stripDirectionalityCharacters(dtf.format(date));
    }
    return String(this.getYear(date));
  }

  addCalendarYears(date: Date, years: number): Date {
    return this.addCalendarMonths(date, years * 12);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  addCalendarMonths(date: Date, months: number): Date {
    let newDate = this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date) + months,
      this.getDate(date),
      this.getHour(date),
      this.getMinute(date)
    );

    // It's possible to wind up in the wrong month if the original month has more days than the new
    // month. In this case we want to go to the last day of the desired month.
    // Note: the additional + 12 % 12 ensures we end up with a positive number, since JS % doesn't
    // guarantee this.
    if (
      this.getMonth(newDate) !==
      (((this.getMonth(date) + months) % 12) + 12) % 12
    ) {
      newDate = this._createDateWithOverflow(
        this.getYear(newDate),
        this.getMonth(newDate),
        0,
        this.getHour(date),
        this.getMinute(date)
      );
    }

    return newDate;
  }

  addCalendarDays(date: Date, days: number): Date {
    return this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date),
      this.getDate(date) + days,
      this.getHour(date),
      this.getMinute(date)
    );
  }

  addCalendarHours(date: Date, hours: number): Date {
    return this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date),
      this.getDate(date),
      this.getHour(date) + hours,
      this.getMinute(date)
    );
  }

  addCalendarMinutes(date: Date, minutes: number): Date {
    return this._createDateWithOverflow(
      this.getYear(date),
      this.getMonth(date),
      this.getDate(date),
      this.getHour(date),
      this.getMinute(date) + minutes
    );
  }

  parse(value: any, parseFormat?: string): Date | null {
    if (value instanceof Date) {
      return value;
    }

    const parsedDate = dateFnsParse(
      value.replace(/\. /g, '.'),
      parseFormat,
      new Date()
    );

    const r = value && this._delegate.isValid(parsedDate) ? parsedDate : null;

    return r;
  }

  format(date: Date, displayFormat: object): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }
    if (SUPPORTS_INTL_API) {
      if (this.useUtcForDisplay) {
        date = new Date(Date.UTC(
            date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        displayFormat = extendObject({}, displayFormat, {timeZone: 'utc'});
      }
      const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);

      return this._stripDirectionalityCharacters(dtf.format(date));
    }

    return this._stripDirectionalityCharacters(date.toDateString());
  }


  formatEn(date: Date, displayFormat: object): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }
    if (SUPPORTS_INTL_API) {
      if (this.useUtcForDisplay) {
        date = new Date(Date.UTC(
            date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        displayFormat = extendObject({}, displayFormat, {timeZone: 'utc'});
      }
      const dtf = new Intl.DateTimeFormat('en', displayFormat);

      return this._stripDirectionalityCharacters(dtf.format(date));
    }

    return this._stripDirectionalityCharacters(date.toDateString());
  }

  toIso8601(date: Date): string {
    return (
      super.toIso8601(date) +
      'T' +
      [
        this._2digit(date.getUTCHours()),
        this._2digit(date.getUTCMinutes()),
      ].join(':')
    );
  }

  private getDateInNextMonth(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1,
      date.getHours(),
      date.getMinutes()
    );
  }

  /**
   * Pads a number to make it two digits.
   * @param n The number to pad.
   * @returns The padded number.
   */
  private _2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  /** Creates a date but allows the month and date to overflow. */
  private _createDateWithOverflow(
    year: number,
    month: number,
    date: number,
    hours: number,
    minutes: number
  ) {
    const result = new Date(year, month, date, hours, minutes);

    // We need to correct for the fact that JS native Date treats years in range [0, 99] as
    // abbreviations for 19xx.
    if (year >= 0 && year < 100) {
      result.setFullYear(this.getYear(result) - 1900);
    }
    return result;
  }


  /**
   * Strip out unicode LTR and RTL characters. Edge and IE insert these into formatted dates while
   * other browsers do not. We remove them to make output consistent and because they interfere with
   * date parsing.
   * @param str The string to strip direction characters from.
   * @returns The stripped string.
   */
  private _stripDirectionalityCharacters(str: string) {
    return str.replace(/[\u200e\u200f]/g, '');
  }
}
