import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogClose, onZefDialogOpen } from '@zerops/zef/dialog';
import { UserData } from '@zerops/models/user-data';
import { successOf } from '@zerops/zef/entities';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';
import { UserDataAddForm } from '@zerops/zui/user-data-add-form';
import { ZefSnackService } from '@zerops/zef/snack';
import { map, filter, switchMap, delay } from 'rxjs/operators';
import { FEATURE_NAME } from './user-data-add-dialog.constant';

@Injectable()
export class UserDataAddDialogEffect {

  private _onDialogOpenFocusInput$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    delay(100),
    map(() => this._userDataAddForm.focus('key'))
  ));

  private _onAddSuccess$ = this._actions$.pipe(
    successOf<UserData>(this._userDataEntity.addOne)
  );

  private _onAddSuccessDialogClose$ = createEffect(() => this._onAddSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    delay(500),
    switchMap(() => [
      this._userDataAddForm.reset(),
      this._userDataAddForm.setDefaultValues()
    ])
  ));

  // private _onAddSuccessNotification$ = createEffect(() => this._onAddSuccess$.pipe(
  //   switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.addSuccess` }))
  // ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _userDataEntity: UserDataEntity,
    private _userDataAddForm: UserDataAddForm,
    private _snack: ZefSnackService,
  ) { }
}
