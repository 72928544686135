<div @ws-reconnect>
  <div
    [@wrap]="state"
    class="__wrap">

    <div
      *ngIf="state === states.reconnecting"
      @reconnecting
      class="__reconnecting">

      <div class="__text">

        <span class="__title">{{ translations.reconnecting }}</span>

        <br />

        <span class="__note">{{ translations.outdated }}</span>

      </div>

      <mat-spinner
        class="__spinner"
        [diameter]="25">
      </mat-spinner>

    </div>

    <div
      *ngIf="state === states.reconnected"
      @reconnected
      class="__success">
      {{ translations.connected }}
    </div>

  </div>
</div>
