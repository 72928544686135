import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { deletionWarningDialogOpen } from './deletion-warning-dialog.action';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { FEATURE_NAME } from './deletion-warning-dialog.constant';
import { map } from 'rxjs/operators';

@Injectable()
export class DeletionWarningDialogEffect {

  private _onDeletionWarningDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(deletionWarningDialogOpen),
    map(({ data }) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: {
        entity: data.entity,
        routerLink: data.routerLink,
        buttonText: data.buttonText
      }
    }))
  ));

  constructor(private _actions$: Actions) { }
}
