import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { UserDataMorePopComponent } from './user-data-more-pop.component';

@NgModule({
  declarations: [ UserDataMorePopComponent ],
  imports: [
    CommonModule,
    MatListModule,
    MatTooltipModule,
    ZuiMaterialStackModule,
    SatPopoverModule,
    ProgressSpinnerModule,
    TranslocoModule,
    ActionListItemModule,
    DynamicPopAnchorModule,
    ZefDialogModule
  ],
  exports: [ UserDataMorePopComponent ]
})
export class UserDataMorePopModule {

}
