import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './app-bar.constant';

const en = {
  clickOr: 'click or',
  toSearch: 'to search',
  accountPasswordSettings: 'Account & password settings',
  accountSettings: 'Account settings',
  tokenManagement: 'Access token management',
  company: 'Organization settings',
  billing: ' Invoices & billing settings',
  logOut: 'Log out'
};

export type AppBarTranslations = typeof en;

@NgModule()
export class AppBarTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
