<zef-dialog
  [open]="state.open"
  [options]="{ maxWidth: '1200px', width: '100%', disableClose: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__wrap">

    <div class="__controls">
      <button
        mat-icon-button
        (click)="onClose$.next()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="__content">

      <span *ngIf="!!state.project && !!state.serviceStack">
        {{ state.serviceStack?.serviceStackTypeInfo?.serviceStackTypeName }} service
        <a (click)="onClose$.next()" class="c-internal-link" [routerLink]="[ '/project', state.project?.id ]">{{ state.project?.name }}</a>/<a (click)="onClose$.next()" class="c-internal-link" [routerLink]="[ '/service-stack', state.serviceStack?.id ]"><strong>{{ state.serviceStack?.name }}</strong></a>
        failed at {{ state.data?.from | date: 'medium' }} on container <a (click)="onClose$.next()" class="c-internal-link" [routerLink]="[ '/service-stack', state.serviceStack?.id, 'log', { container: state.containerId } ]">#{{ state.containerId }}</a>
      </span>

      <span *ngIf="!state.project || !state.serviceStack">{{ !state.project ? 'Project' : 'Service' }} was deleted</span>

    </div>
<!--
    <z-zlog
      class="__log"
      [class.project-is-deleted]="!state.project"
      [key]="featureName"
      [initialScrollDown]="true"
      [serviceStack]="state.serviceStack"
      [scrollHeight]="'calc(100vh - 300px)'"
      (loadPrevData)="onRequestPreviousData$.next()"
      showLoadPrevButton>
    </z-zlog> -->

  </div>
</zef-dialog>
