import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@zerops/zerops/app';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { HashMap, ZefReactiveComponent } from '@zerops/zef/core';
import { Subject } from 'rxjs';
import { map, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { FEATURE_NAME } from './deletion-warning-dialog.constant';
import { DeletionWarningDialogTranslations } from './deletion-warning-dialog.translations';

@Component({
  selector: 'z-deletion-warning-dialog',
  templateUrl: './deletion-warning-dialog.container.html',
  styleUrls: [ './deletion-warning-dialog.container.scss' ]
})
export class DeletionWarningDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;

  // -- async
  data$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    filter(({ meta }) => !!meta)
  );
  translations$ = this.data$.pipe(
    withLatestFrom(this.translate$<HashMap<string>>(`${FEATURE_NAME}.entities`)),
    switchMap(([ { meta }, entities ]) => this.translate$<DeletionWarningDialogTranslations>(
      FEATURE_NAME,
      {
        desc: { entity: entities[meta.entity] }
      }
    ))
  );
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    open: this.open$,
    data: this.data$
  });

  // # Action Streams
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._closeAction$ ]);
  }

}
