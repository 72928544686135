import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@zerops/zerops/env';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { of, timer } from 'rxjs';
import { switchMap, map, share, catchError, filter } from 'rxjs/operators';

@Component({
  selector: 'z-version-warning',
  templateUrl: './version-warning.container.html',
  styleUrls: [ './version-warning.container.scss' ]
})
export class VersionWarningContainer extends ZefReactiveComponent {

  forceHide = false;

  version$ = timer(0, 60 * 60 * 10).pipe(
    switchMap(() => this._http.get(`${environment.versionInfoPath}?t=${Date.now()}`).pipe(
      catchError(() => of(undefined))
    )),
    filter((d) => !!d),
    map((d: any) => d.version as string),
    share()
  );

  isActive$ = this.version$.pipe(
    map((res) => res !== environment.appVersion)
  );

  state = this.$connect({
    version: this.version$,
    isActive: this.isActive$
  });

  constructor(
    private _http: HttpClient
  ) {
    super();
  }
}
