import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  templateUrl: './discord-button.component.html',
  styleUrls: [ './discord-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZeDiscordButtonComponent {
  @Input()
  text: string;
}
