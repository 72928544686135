import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiBillingInfoModule } from '@zerops/zui/billing-info';
import { ZuiPaymentSourcesModule } from '@zerops/zui/payment-sources';
import { NgxStripeModule } from 'ngx-stripe';
import { TopUpDialogContainer } from './top-up-dialog.container';
import { TopUpDialogEffect } from './top-up-dialog.effect';
import { TopUpAmountFormModule } from './modules';
import { TopUpBillingInfoFormModule } from './modules/billing-info-form';
import { TopUpPromoCodeFormModule } from './modules/top-up-promo-code-form';

@NgModule({
  declarations: [ TopUpDialogContainer ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ TopUpDialogEffect ]),
    MatButtonModule,
    MatIconModule,
    NgxStripeModule,
    ZefDialogModule,
    TopUpAmountFormModule,
    TopUpPromoCodeFormModule,
    TopUpBillingInfoFormModule,
    ZefProgressErrorModule,
    FormSectionModule,
    ZuiBillingInfoModule,
    ZuiPaymentSourcesModule,
    MatProgressSpinnerModule,
    ZefScrollbarComponent
  ],
  exports: [ TopUpDialogContainer ]
})
export class TopUpDialogModule {

}
