<zui-tip-card class="__tip">
  Zerops Object Storage, powered by MinIO, operates on an independent infrastructure as a dedicated service. When you add an Object Storage service, we automatically create a new user and bucket, which are immediately ready for your use.
</zui-tip-card>

<zui-form-section
  [isZen]="isZen"
  [separator]="'none'">

  <span *zuiFormSectionTitle>
    {{ translations.nameTitle }}
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>
      <span [innerHTML]="translations?.nameDesc | safeHtml"></span>
    </zui-docs-text>
  </div>

  <zef-form-field>
    <z-hostname-field
      [label]="translations.nameLabel"
      [placeholder]="translations.namePlaceholder"
      [control]="state?.controls.name"
    />
  </zef-form-field>

</zui-form-section>

<zui-separator
  [orientation]="'vertical'"
  [size]="'medium'"
  [spacing]="'large'"
  [position]="'center'"
/>

<zui-form-section
  [isZen]="isZen"
  [separator]="'none'">

  <span *zuiFormSectionTitle>
    {{ translations.policyTitle }}
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>
      <span [innerHTML]="translations?.policyDesc | safeHtml"></span>
    </zui-docs-text>
  </div>

  <zef-form-field last>
    <mat-form-field class="__select-field" color="accent">

      <mat-label>Policy (ACL)</mat-label>

      <mat-select
        [panelClass]="'c-mat-select-long-panel'"
        [ngrxEnableFocusTracking]="true"
        [ngrxFormControlState]="state?.controls.policy">

        <mat-select-trigger>
          {{ aclMap?.[state?.controls.policy?.value] || '—' }}
        </mat-select-trigger>

        <mat-option [value]="acls.Custom">
          <strong>Custom policy</strong> <mat-icon class="__custom-policy-icon">tune</mat-icon>
        </mat-option>

        @for (item of aclItemsVisible; track 'id') {
          <mat-option [value]="item.id">
            {{ item.name }}
          </mat-option>
        }
      </mat-select>

    </mat-form-field>
  </zef-form-field>

  @if (state?.controls.policy?.value === acls.Custom) {
    <zef-form-field last>
      @defer {
        <zui-code-field
          class="__code-field"
          [editorOptions]="{ language: 'json' }"
          [ngrxFormControlState]="state?.controls.rawPolicy"
          [ngrxEnableFocusTracking]="true">
        </zui-code-field>
      }

    </zef-form-field>
  }

</zui-form-section>

<zui-separator
  [orientation]="'vertical'"
  [size]="'medium'"
  [spacing]="'large'"
  [position]="'center'"
/>

<zui-form-section
  [isZen]="isZen"
  [separator]="'none'">

  <span *zuiFormSectionTitle>
    {{ translations.discTitle }}
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>
      <span [innerHTML]="translations?.discDesc | safeHtml"></span>
    </zui-docs-text>
  </div>

  <zef-form-field last>
    <mat-card class="__card">
      <z-disk-capacity-field
        [ngrxFormControlState]="state?.controls.diskGBytes"
        [ngrxEnableFocusTracking]="true">
      </z-disk-capacity-field>
    </mat-card>
  </zef-form-field>

</zui-form-section>
