import { createAction, union } from '@ngrx/store';
import { UserData } from '@zerops/models/user-data';

export const envFormatDialogOpen = createAction(
  '[@zerops/zerops/feature/env-format-dialog] open',
  (userData: UserData[]) => ({ userData } as { userData: UserData[]; })
);

const all = union({ envFormatDialogOpen });

export type EnvFormatDialogActionUnion = typeof all;
