import { Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Component({
  selector: 'z-hostname-field',
  templateUrl: './hostname-field.component.html',
  styleUrls: [ './hostname-field.component.scss' ]
})
export class HostnameFieldComponent {

  errorMessages = {
    hintRequired: `Value has to be entered.`,
    hintMaxLength: `Value is too long (max. 25 characters).`,
    hintPattern: `Value contains invalid characters (only 0-9 or a-z).`,
  };

  @Input()
  control: FormControlState<string>;

  @Input()
  placeholder: string;

  @Input()
  label: string;

}
