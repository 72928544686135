import { trigger, transition, style, animate } from '@angular/animations';

// export function notificationAnimations() {
//   return [
//     trigger(
//       'inOutAnimation',
//       [
//         transition(
//           ':enter',
//           [
//             style({
//               opacity: 0,
//               transform: 'translate3d(-12px, 0, 0)',
//             }),
//             animate(
//               '150ms ease-in',
//               style({
//                 transform: 'translate3d(0, 0, 0)',
//                 opacity: 1
//               })
//             )
//           ]
//         ),
//         transition(
//           ':leave',
//           [
//             style({
//               height: '*',
//               opacity: 1,
//               overflow: 'hidden'
//             }),
//             sequence([
//               animate(
//                 '100ms ease-out',
//                 style({
//                   opacity: 0,
//                   transform: 'translate3d(0, 6px, 0)'
//                 })
//               ),
//               animate(
//                 '250ms ease-out',
//                 style({
//                   height: 0,
//                   margin: 0
//                 })
//               )
//             ])
//           ]
//         )
//       ]
//     )
//   ];
// }

export function notificationAnimations() {
  return trigger('inOutAnimation', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translate3d(0, -12px, 0)',
      }),
      animate(150, style({
        transform: 'translate3d(0, 0, 0)',
        opacity: 1
      }))
    ]),
    transition(':leave', [
      style({
        height: '*',
        opacity: 1,
        // overflow: 'hidden'
      }),
      animate(200, style({
        opacity: 0,
        transform: 'translate3d(0, 4px, 0)'
      }))
    ])
  ]);
}
