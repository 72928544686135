import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  AppVersion,
  AppVersionGithubIntegration,
  AppVersionGitlabIntegration,
  AppVersionPublicGitSource,
  AppVersionSource,
  AppVersionStatuses,
  getAppVersionFailedStatus
} from '@zerops/models/app-version';

@Component({
  selector: 'zui-app-version-name',
  templateUrl: './app-version-name.component.html',
  styleUrls: ['./app-version-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionNameComponent {

  appVersionStatuses = AppVersionStatuses;
  isFailed = false;

  @Input()
  id: string;

  @Input()
  set status(value: AppVersionStatuses) {
    this._status = value;
    this.isFailed = getAppVersionFailedStatus(value)
  }
  get status() {
    return this._status;
  }

  @Input()
  name: string;

  @Input()
  source: AppVersionSource;

  @Input()
  appVersion: AppVersion;

  @Input()
  activationDate: string;

  @Input()
  lastUpdate: string;

  @Input()
  githubIntegration: AppVersionGithubIntegration;

  @Input()
  gitlabIntegration: AppVersionGitlabIntegration;

  @Input()
  publicGitSource: AppVersionPublicGitSource;

  @Input()
  set noLink(v: string | boolean) {
    this._noLink = coerceBooleanProperty(v);
  }
  get noLink(): boolean {
    return this._noLink;
  }

  @Input()
  set noLinkIcon(v: string | boolean) {
    this._noLinkIcon = coerceBooleanProperty(v);
  }
  get noLinkIcon(): boolean {
    return this._noLinkIcon;
  }

  @Input()
  set noProcessStepState(v: string | boolean) {
    this._noProcessStepState = coerceBooleanProperty(v);
  }
  get noProcessStepState(): boolean {
    return this._noProcessStepState;
  }

  private _status: AppVersionStatuses;
  private _noLink: boolean;
  private _noLinkIcon: boolean;
  private _noProcessStepState: boolean;

}
