import { NgModule } from '@angular/core';
import { ZefPermissionService } from '@zerops/zef/permission';
import { zefSelectAuthState, ZefAuthState } from '@zerops/zef/auth';
import { selectUserBaseActiveClientUserId, UserEntity } from '@zerops/zerops/core/user-base';
import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AppState } from './app.model';

export enum Roles {
  Authorized = 'authorized',
  Incomplete = 'incomplete',
  Unauthorized = 'unauthorized',
  NotContabo = 'not-contabo'
}

@NgModule({})
export class AppPermissionsModule {
  constructor(
    private _store: Store<AppState>,
    private _permissions: ZefPermissionService,
    private _userEntity: UserEntity
  ) {

    // # Permissions Setup
    // base roles
    this._permissions.define(
      Roles.Authorized,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)),
        this._store.pipe(select(selectUserBaseActiveClientUserId))
      ]).pipe(
        filter(([ s ]) => s !== ZefAuthState.Checking),
        map(([ state, activeClienUserId ]) => state === ZefAuthState.Authorized && !!activeClienUserId),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.Incomplete,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)).pipe(filter((s) => s !== ZefAuthState.Checking)),
        this._store.pipe(select(selectUserBaseActiveClientUserId))
      ]).pipe(
        map(([ state, activeClientUserId ]) => !!(state === ZefAuthState.Authorized && !activeClientUserId)),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.Unauthorized,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)).pipe(
          filter((s) => s !== ZefAuthState.Checking)
        ),
        this._store.pipe(select(selectUserBaseActiveClientUserId))
      ]).pipe(
        map(([ state, activeClientUserId ]) => !!(state === ZefAuthState.Invalid && !activeClientUserId)),
        distinctUntilChanged()
      )
    );

    this._permissions.define(
      Roles.NotContabo,
      () => combineLatest([
        this._store.pipe(select(zefSelectAuthState)).pipe(filter((s) => s !== ZefAuthState.Checking)),
        this._store.pipe(select(selectUserBaseActiveClientUserId)),
        this._userEntity.isContabo$
      ]).pipe(
        map(([ state, activeClienUserId, isContabo ]) => state === ZefAuthState.Authorized && !!activeClienUserId && !isContabo),
        distinctUntilChanged()
      )
    );

  }
}
