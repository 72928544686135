import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { LogData } from '@zerops/models/project';
import { HashMap, ZefReactiveComponent } from '@zerops/zef/core';
import { RxVirtualScrollViewportComponent } from '@rx-angular/template/experimental/virtual-scrolling';
import { Container } from '@zerops/zerops/core/container-base';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { SEVERITIES, SEVERITIES_MAP, TrLogOptions, TrlogParams } from './trlog.model';
import { TrlogComponentStore } from './trlog.store';
import { periodSettings } from './trlog.utils';
import { TrLogPeriods } from './trlog.constant';
import { FormControl, FormGroup } from '@angular/forms';
import { SatPopover, SatPopoverAnchor } from '@zerops/zef/popover';
import { VirtualScrollRefs } from '@zerops/zef/scrollbar';
import formatISO from 'date-fns/formatISO';
import startOfMinute from 'date-fns/startOfMinute';
import parseISO from 'date-fns/parseISO';
import { ServiceStack, ServiceStackTypes } from '@zerops/models/service-stack';

const deepCompare = (x: any, y: any) => isEqual(x, y);

@Component({
  selector: 'z-trlog',
  templateUrl: './trlog.feature.html',
  styleUrls: [ './trlog.feature.scss' ],
  providers: [ TrlogComponentStore ]
})
export class TrlogFeature extends ZefReactiveComponent implements AfterViewInit {
  // # Data
  // -- sync
  severities = SEVERITIES;
  severitiesMap = SEVERITIES_MAP;
  rangeControl = new FormGroup({
    from: new FormControl(undefined),
    till: new FormControl(undefined)
  });
  customRangeMode = false;

  // -- async
  scrollIndexChange$ = new Subject<number>();
  options$ = new BehaviorSubject<any>(undefined);
  params$ = new BehaviorSubject<TrlogParams>(undefined);
  itemsRendered$ = new Subject<any[]>();
  items$ = this._trLogStore.select((s) => s.data.items);
  logTags$ = this._trLogStore.select((s) => s.info.items?.[0]?.tags).pipe(
    filter((d) => !!d),
    map((d) => sortBy(d, [
      (str) => !str.startsWith('zerops@zerops'),
      (str) => !str.startsWith('zerops@'),
      (str) => !str.includes('zerops@'),
      (str) => str.toLowerCase()
    ]))
  );
  tagParamsArr$ = this.params$.pipe(
    map((d) => d.tags?.split(','))
  );
  tagsParamsInfo$ = this.tagParamsArr$.pipe(
    map((tags) => {
      if (tags?.length > 2) {
        return `${tags.slice(0, 2).join(', ')} +${tags?.length - 2}`;
      } else {
        return tags?.join(', ');
      }
    })
  );
  tagParamsActiveMap$ = this.tagParamsArr$.pipe(
    map((tags) => tags?.reduce((obj, itm) => {
      obj[itm] = true;
      return obj;
    }, {} as Record<string, boolean>) || {})
  );
  showLoadMore$ = this._trLogStore.select((s) => !!s.data?.hasMore);
  itemsLength$ = this.items$.pipe(map((d) => d?.length || 0), distinctUntilChanged());
  delayedShow$ = this.itemsLength$.pipe(
    delay(50),
    map((d) => !!d)
  );
  stateOptions$ = this._trLogStore.select((s) => s?.options);
  isLive$ = this.stateOptions$.pipe(map((d) => d?.live));
  isFollowing$ = this.stateOptions$.pipe(map((d) => d?.liveFollow));
  isLiveCancellable$ = this.stateOptions$.pipe(map((d) => d?.liveCancellable));
  isLoading$ = this._trLogStore.select((d) => d?.loading);
  isLoadMoreLoading$ = this._trLogStore.select((d) => d?.loadMoreLoading);
  viewportWidth: number;
  containerMap: HashMap<Container> = {};
  periods = {
    absolute: [
      {
        key: TrLogPeriods.Today,
        name: 'Today',
        value: periodSettings.today()
      },
      {
        key: TrLogPeriods.Yesterday,
        name: 'Yesterday',
        value: periodSettings.yesterday()
      },
      {
        key: TrLogPeriods.ThisWeek,
        name: 'This Week',
        value: periodSettings.thisWeek()
      },
      {
        key: TrLogPeriods.LastWeek,
        name: 'Last Week',
        value: periodSettings.lastWeek()
      }
    ],
    relative: [
      {
        key: TrLogPeriods.Last15Minutes,
        name: 'Last 15 Minutes',
        value: periodSettings.last15Minutes()
      },
      {
        key: TrLogPeriods.Last60Minutes,
        name: 'Last 60 Minutes',
        value: periodSettings.last60Minutes()
      },
      {
        key: TrLogPeriods.Last7Hours,
        name: 'Last 7 Hours',
        value: periodSettings.last7Hours()
      },
      {
        key: TrLogPeriods.Last24Hours,
        name: 'Last 24 Hours',
        value: periodSettings.last24Hours()
      },
      {
        key: TrLogPeriods.Last7Days,
        name: 'Last 7 Days',
        value: periodSettings.last7Days()
      }
    ]
  };
  periodsMap = {
    relative: this.periods.relative.reduce((obj, itm) => {
      obj[itm.key] = itm;
      return obj;
    }, {}),
    absolute: this.periods.absolute.reduce((obj, itm) => {
      obj[itm.key] = itm;
      return obj;
    }, {})
  };
  serviceStackTypes = ServiceStackTypes;

  private _allowedRanges = [ 1000, 5000, 20000 ];
  private _rowSize = 22;
  private _itemPadding = 32;
  private _letterWidth = 7.2;
  private _containers: Container[];
  private _params: TrlogParams;
  private _rangeKeys = [ 'from', 'till' ];

  @Input()
  showFilters = false;

  @Input()
  serviceStack: ServiceStack;

  @Input()
  set options(v: TrLogOptions) {
    this.options$.next(v);
  }

  @Input()
  set params(v: TrlogParams) {
    const parsedLimit = parseInt(v.limit as string, 10);
    const p = {
      ...v,
      limit: this._allowedRanges.includes(parsedLimit) ? parsedLimit : 1000
    };
    this.params$.next(p);
    this._params = p;

    if (v.mode === 'range') {
      this._setRangeValues();
      this.customRangeMode = true;
    }
  }

  get params() {
    return this._params;
  }

  @Input()
  set containers(v) {
    this._containers = v;
    this.containerMap = v?.reduce((obj: HashMap<Container>, itm) => {
      obj[itm.id] = itm;
      return obj;
    }, {});
  }
  get containers() {
    return this._containers;
  }

  @Input()
  scrollHeight = '500px';

  @Input()
  virtualScrollKey = 'logRuntimeRef';

  @Output()
  filterChange = new EventEmitter<any>();

  @ViewChild(RxVirtualScrollViewportComponent)
  scrollViewportRef: RxVirtualScrollViewportComponent;

  @ViewChild('viewportRef')
  viewportRef: ElementRef<HTMLElement>;

  @ViewChild('timePopRef')
  timePopRef: SatPopover;

  @ViewChild('timeRangePopAnchorRef')
  timeRangePopAnchorRef: SatPopoverAnchor;

  state = this.$connect({
    showLoadMore: this.showLoadMore$,
    options: this.options$,
    tagParamsArr: this.tagParamsArr$,
    params: this.params$,
    tagsParamsInfo: this.tagsParamsInfo$,
    logTags: this.logTags$,
    tagParamsActiveMap: this.tagParamsActiveMap$,
    isLive: this.isLive$,
    isFollowing: this.isFollowing$,
    itemsLength: this.itemsLength$,
    isLoading: this.isLoading$,
    isLiveCancellable: this.isLiveCancellable$,
    isLoadMoreLoading: this.isLoadMoreLoading$,
    delayedShow: this.delayedShow$
  });

  dynamicSize = (item: LogData) => {
    // 10 = spacing between date and text
    // 24 = severity
    const totalWidthRequired = ((item.timestamp + item.content).length * this._letterWidth) + 10 + 24;
    const rows = Math.ceil(totalWidthRequired / this.viewportWidth);

    return Math.max(rows, 1) * this._rowSize;
  };

  constructor(
    private _trLogStore: TrlogComponentStore,
    private _viewportRuler: ViewportRuler,
    private _virtualScrollRefs: VirtualScrollRefs
  ) {
    super();

    // sets options
    combineLatest([
      this.params$.pipe(debounceTime(50), distinctUntilChanged(deepCompare)),
      this.options$.pipe(distinctUntilChanged(deepCompare))
    ])
      .pipe(
        tap(([ params, options ]) => {
          this._trLogStore.setParamsAndOptions({
            params,
            options: (options || {})
          });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    // handles scroll down cases
    this.itemsRendered$.pipe(
      filter((d) => !!d),
      take(1),
      switchMap(() => this.stateOptions$.pipe(
        switchMap(({ live, liveFollow }) => this.itemsLength$.pipe(
          distinctUntilChanged(),
          filter((d) => !!d),
          tap((data) => {
            if (!data || !this.scrollViewportRef) { return; }

            if (live && liveFollow) {
              this.scrollViewportRef.scrollToIndex(data - 1);
            }

            if (!live && this._trLogStore.lastPosition) {
              this.scrollViewportRef.scrollToIndex(this._trLogStore.lastPosition - 5);
            }

            if (!live && !this._trLogStore.lastPosition) {
              this.scrollViewportRef.scrollToIndex(data - 1);
            }
          })
        )),
        takeUntil(this.onDestroy$)
      ))
    ).subscribe();

  }

  ngAfterViewInit() {

    this._viewportRuler
      .change(200)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this._calculateViewportWidth();
      });

    // removes live when scrolling up
    const createWheelEvent$ = (scrollEl: HTMLElement) => {
      return fromEvent(scrollEl, 'wheel').pipe(
        debounceTime(10),
        filter((e: WheelEvent) => e.deltaY < 0),
        map((e: WheelEvent) => ({ type: 'WHEEL_SCROLL_UP', e }))
      );
    };

    const createArrowUpEvent$ = (scrollEl: HTMLElement) => {
      scrollEl.setAttribute('tabindex', '0');
      return fromEvent<KeyboardEvent>(scrollEl, 'keydown').pipe(
        filter((e) => e.key === 'ArrowUp'),
        map((e) => ({ type: 'ARROW_UP', e }))
      );
    };

    setTimeout(() => {
      this._calculateViewportWidth();
    }, 0);

    setTimeout(() => {
      const scrollEl = this.scrollViewportRef.getScrollElement();
      const wheelEvent$ = createWheelEvent$(scrollEl);
      const arrowUpEvent$ = createArrowUpEvent$(scrollEl);

      merge(wheelEvent$, arrowUpEvent$).pipe(
        withLatestFrom(this.stateOptions$),
        filter(([ _, { live } ]) => live),
        tap(([ _, { liveCancellable }]) => {
          if (liveCancellable) {
            this._trLogStore.updateOptions({
              live: false
            });
          } else {
            this._trLogStore.updateOptions({
              liveFollow: false
            });
          }
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();
    }, 100);

    setTimeout(() => {
      this._initScrollBar();
    }, 50);

  }

  setLive(state = true) {
    this._trLogStore.setLiveState(state);
    this._trLogStore.updateOptions({
      liveFollow: state
    });
  }

  setFollow(liveFollow = true) {
    this._trLogStore.updateOptions({ liveFollow });
    this._scrollToBottom();
  }

  toTop() {
    this._trLogStore.updateOptions({
      liveFollow: false
    });
    this._scrollToTop();
  }

  loadMore() {
    this._trLogStore.loadMore('prepend');
  }

  trackById(_: number, item: any) {
    return item?.id;
  }

  onTimePopRefOpen(mode: string) {
    if (mode === 'range') {
      setTimeout(() => {
        this.timeRangePopAnchorRef.popover.open();
        this.customRangeMode = true;
      });
    }
  }

  onTimeRangePopOpen() {
    this.timeRangePopAnchorRef.popover.open();
    this.customRangeMode = true;

    this._setRangeValues();
    this._rangeKeys.forEach((n) => {
      const ctrl = this.rangeControl.get(n);
      ctrl?.markAsPristine();
      ctrl?.markAsUntouched();
    })
  }

  applyCustomRange() {
    if (this.rangeControl.valid) {
      this.filterChange.emit({
        mode: 'range',
        from: formatISO(startOfMinute(this.rangeControl.value?.from)),
        till: formatISO(startOfMinute(this.rangeControl.value?.till)),
        limit: null,
        period: null
      });
      this.timePopRef.close();
      this.timeRangePopAnchorRef.popover.close();
    } else {
      this._rangeKeys.forEach((n) => {
        const ctrl = this.rangeControl.get(n);
        ctrl?.markAsDirty();
        ctrl?.markAllAsTouched();
      });
    }
  }

  toggleTag(tag: string, activeTags: string[]) {
    // without the current tag
    if (activeTags?.includes(tag)) {
      const newTags = activeTags.filter((itm) => itm !== tag).join(',');

      // no tags after removing -> return undefined to clear the param
      if (!newTags.length) { return undefined; }
      return newTags;
    }

    // add tags
    return [
      ...(activeTags || []),
      tag
    ].join(',')

  }

  clipboardCopy(event: ClipboardEvent) {
    const selectedContent = window.getSelection().toString();

    /**
     * There is the only one pattern for timestamps detection:
     * - 2024-10-18T17:17:01.991Z (it contains the second's fraction)
     */
    const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g

    /**
     * One of the possible logic was to trim the content of the first line, if there was
     * not recognized the timestamp pattern, but finally it is kept as it was selected.
     */

    /*
    const firstMatchIndex = selectedContent.search(regex);
    let modifiedContent = selectedContent;
    if (firstMatchIndex !== -1) {
      const trimmedContent = selectedContent.slice(firstMatchIndex);
      modifiedContent = trimmedContent.replace(regex, match => `\n${match} `);
    }
    */

    const modifiedContent = selectedContent.replace(regex, match => `\n${match} `);
    event.clipboardData.setData("text/plain", modifiedContent);
    event.preventDefault();
  }

  private _calculateViewportWidth() {
    this.viewportWidth = this.viewportRef.nativeElement.clientWidth - this._itemPadding;
  }

  private _scrollToBottom() {
    this.scrollViewportRef.scrollToIndex(1000000000000000);
  }

  private _scrollToTop() {
    this.scrollViewportRef.scrollTo(0);
  }

  private _setRangeValues() {
    if (this._params?.mode === 'range') {
      this.rangeControl.setValue({
        from: parseISO(this._params?.from),
        till: parseISO(this._params?.till)
      });
    }
  }

  private _initScrollBar() {
    if (this.scrollViewportRef) {
      this._virtualScrollRefs.setVirtualScrollRef(this.virtualScrollKey, this.scrollViewportRef);
    }
  }

}
