import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDataEditFormComponent } from './user-data-edit-form.component';
import { UserDataEditFormTranslationsModule } from './user-data-edit-form.translations';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefFormModule } from '@zerops/zef/forms/modules';

@NgModule({
  declarations: [ UserDataEditFormComponent ],
  imports: [
    CommonModule,
    UserDataEditFormTranslationsModule,
    UserDataFieldsModule,
    ZefFormModule
  ],
  exports: [ UserDataEditFormComponent ]
})
export class UserDataEditFormModule {

}
