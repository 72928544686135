<div class="__grid">

  <div class="__grid-item  __layout_item--key">

    <mat-icon
      *ngIf="!!data.id
        && serviceStackStatus !== serviceStackStatuses.New
        && serviceStackStatus !== serviceStackStatuses.Creating
        && serviceStackStatus !== serviceStackStatuses.ReadyToDeploy
        && !data?.isSynced"
      class="__status-icon"
      [matTooltip]="translations.notSynchronized">
      lens
    </mat-icon>

    <zef-copy-to-clipboard
      class="__key-wrap"
      [copyData]="data.key"
      [tooltip]="translations.copyKeyTooltip">
      <div
        class="__key"
        [class.is-deleted]="data?.deleteOnSync">
        <zef-fuse-highlight
          [data]="data"
          [key]="'key'"
          [matches]="matches">
        </zef-fuse-highlight>
      </div>
    </zef-copy-to-clipboard>

  </div>

  <div class="__grid-item">
    <div class="__content" [class.is-deleted]="data?.deleteOnSync">

      <zef-fuse-highlight
        *ngIf="!formattedContent?.length || (!!formattedContent?.length && !formattedContent[1])"
        [data]="data"
        [key]="'content'"
        [matches]="matches">
      </zef-fuse-highlight>

      <ng-container *ngIf="!!formattedContent?.length && !!formattedContent[1] && !!formattedContent[1].type">
        <span
          class="__content_item"
          [class.is-expanded]="!!item.type"
          *ngFor="let item of formattedContent">{{ item.type ? item.raw : item }}</span>
      </ng-container>

    </div>
  </div>

</div>

<div
  class="__actions"
  [class.is-restricted]="data?.type === userDataTypes.ReadOnly">

  <ng-container *ngIf="!data.id || data?.type === userDataTypes.Env || data?.type === userDataTypes.Editable">
    <zui-more-button
      class="__more-button"
      [popRef]="moreAnchor"
      [isLoading]="moreActionLoading"
      (moreClick)="moreClicked.emit()">
    </zui-more-button>
  </ng-container>

  <mat-icon
    class="__locked-icon"
    *ngIf="data?.type === userDataTypes.ReadOnly"
    [matTooltip]="translations.restrictedVariable">
    lock
  </mat-icon>

</div>
