<zui-progress-spinner
  hideContent
  [isLoading]="isLoading">
  <button
    mat-icon-button
    #ref="dynamicPopAnchor"
    type="button"
    class="c-more-button-button"
    (click)="moreClick.emit(); ref.open();"
    [zuiDynamicPopAnchor]="popRef">
    <mat-icon>more_vert</mat-icon>
  </button>
</zui-progress-spinner>
