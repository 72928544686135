  import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { ProcessStatuses } from '@zerops/models/process';
import { ZefSnackService } from '@zerops/zef/snack';
import { ZefEntityAction } from '@zerops/zef/entities/entity-manager.model';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { of, timer } from 'rxjs';
import { map, switchMap, catchError, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { ProcessEntity } from './process-base.entity';
import {
  processCancel,
  processCancelSuccess,
  processCancelFail,
  processFinishNotify,
  buildCancel,
  buildCancelSuccess,
  buildCancelFail
} from './process-base.action';
import { ProcessBaseApi } from './process-base.api';
import { FEATURE_NAME } from './process-base.constant';

@Injectable()
export class ProcessBaseEffect {

  // process list / update
  private _setupProcessListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    switchMap((clientId) => timer(0, 30000).pipe(
      map((count) => this._processEntity.listSubscribe(
        clientId,
        undefined,
        {
          search: [
            {
              name: 'status',
              operator: 'in',
              value: [ ProcessStatuses.RUNNING, ProcessStatuses.PENDING ]
            },
            {
              name: 'actionName',
              operator: 'ne',
              value: 'instanceGroup.create'
            }
          ]
        },
        /**
         * The flag forcedRefresh differentiates between the first subscription
         * to get a list of processes and a repeated forced refresh to prevent a page
         * from flickering between the content showing and a loading indicator.
         */
        { forcedRefresh: count > 0 }
      )),
      takeUntil(this._userEntity.activeClientIdRaw$.pipe(filter((d) => !d)))
    ))
  ));

  private _onProcessListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._processEntity)
  ));

  private _setupProcessUpdateStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._processEntity.updateSubscribe(
      clientId,
      {
        search: [
          {
            name: 'status',
            operator: 'in',
            value: [ ProcessStatuses.RUNNING, ProcessStatuses.PENDING, ProcessStatuses.FINISHED ]
          },
          {
            name: 'actionName',
            operator: 'ne',
            value: 'instanceGroup.create'
          }
        ]
      }
    )
  )));

  private _onProcessUpdateChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._processEntity)
  ));

  private _onProcessCancel$ = createEffect(() => this._actions$.pipe(
    ofType(processCancel),
    switchMap((action) => this._api
      .cancel$(action.data.id)
      .pipe(
        map((res) => processCancelSuccess(res, action)),
        catchError((err) => of(processCancelFail(err, action)))
      )
    )
  ));

  private _onProcessCancelSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(processCancelSuccess),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.cancelled` }))
  ), { dispatch: false });

  private _onFinishNotify$ = createEffect(() => this._actions$.pipe(
    ofType(this._processEntity.updateCache),
    map(({ data }) => data && data.length && data.filter((item) => item.status === ProcessStatuses.FINISHED)),
    filter((d) => !!d),
    map((d) => processFinishNotify(d))
  ));

  private _onBuildCancel$ = createEffect(() => this._actions$.pipe(
    ofType(buildCancel),
    switchMap((action) => this._api
      .buildCancel$(action.data.id)
      .pipe(
        map((res) => buildCancelSuccess(res, action)),
        catchError((err) => of(buildCancelFail(err, action)))
      )
    )
  ));

  private _onActionContainingProcessSaveProcess$ = createEffect(() => this._actions$.pipe(
    filter((action: ZefEntityAction) => {
      const { data } = action;
      if (!data) return false;
      return this._hasRequiredFields(data) || (data.process && this._hasRequiredFields(data.process));
    }),
    mergeMap((action) => [
      this._processEntity.addToCache([ action.data.process ? action.data.process : action.data ]),
      this._processEntity.addIdsToCache([ action.data.process ? action.data.process.id : action.data.id ], { tag: undefined, handleGlobally: true }, 1)
    ])
  ));

  constructor(
    private _actions$: Actions,
    private _processEntity: ProcessEntity,
    private _userEntity: UserEntity,
    private _api: ProcessBaseApi,
    private _snack: ZefSnackService
  ) { }

  private _checkPropertyExists(entries: any, property: string) {
    return Object.prototype.hasOwnProperty.call(entries, property);
  }

  private _hasRequiredFields(data: any) {
    return this._checkPropertyExists(data, 'id')
      && this._checkPropertyExists(data, 'actionName')
      && this._checkPropertyExists(data, 'status');
  }

}
