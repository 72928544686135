import { Injectable } from '@angular/core';
import { zefWebsocketMessage } from '@zerops/zef/websocket/websocket.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import has from 'lodash-es/has';
import { WebsocketDebuggerService } from './websocket-debugger.service';

@Injectable()
export class WebsocketDebuggerEffect {

  private _onWsOpened$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketMessage),
    filter((action: any) => action.message
      && action.message.type === 'SocketSuccess'
      && localStorage
      && has(localStorage, 'FORCE_WEBSOCKET_DEBUGGER')
    ),
    tap((action: any) => this._websocketDebuggerService.startDebugging(
      action.message.chainId,
      action.message.sessionId,
      action.message.requestId
    ))
  ), { dispatch: false });

  private _onWsMessage$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketMessage),
    filter((action: any) => action.message && action.message.type !== 'SocketSuccess'),
    tap((action: any) => this._websocketDebuggerService.addMessage(action.message))
  ), { dispatch: false });


  constructor(
    private _actions$: Actions,
    private _websocketDebuggerService: WebsocketDebuggerService
  ) { }
}
