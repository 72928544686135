import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import {
  HttpRoutingEntity,
  getCategorizedHttpRoutings
} from '@zerops/zerops/core/http-routing-base';
import { selectZefProgressMapByType } from '@zerops/zef/progress';
import {
  HttpRoutingItemTranslations,
  HTTP_ROUTING_ITEM_FEATURE_NAME
} from '@zerops/zui/http-routing-item';
import { HttpRouting } from '@zerops/models/http-routing';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { HttpRoutingListTranslations } from './http-routing-list.translations';
import { FEATURE_NAME } from './http-routing-list.constant';

@Component({
  selector: 'z-http-routing-list',
  templateUrl: './http-routing-list.container.html',
  styleUrls: [ './http-routing-list.container.scss' ]
})
export class HttpRoutingListContainer extends ZefReactiveComponent {

  // # Event Streams
  @Output()
  editOpenClick = new EventEmitter<HttpRouting>();

  // # Data
  // -- angular
  @ObservableInput()
  @Input('items')
  items$!: Observable<HttpRouting[]>;

  @ObservableInput()
  @Input('serviceStackId')
  serviceStackId$!: Observable<string>;

  // -- sync
  activeMoreId: string = undefined;
  activeHttpRoutingItemElRef: ElementRef<any>;
  externalVisible = true;

  // -- async
  translations$ = this.translate$<HttpRoutingListTranslations>(FEATURE_NAME);
  httpRoutingItemTranslations$ = this.translate$<HttpRoutingItemTranslations>(HTTP_ROUTING_ITEM_FEATURE_NAME);
  // split into local / external for both items and their locations
  categorizedItems$ = this.items$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ items, serviceStackId ]) => getCategorizedHttpRoutings(items, serviceStackId))
  );
  loadingsMap$ = this._store.pipe(
    select(selectZefProgressMapByType([
      this._httpRoutingEntity.deleteOne.type,
      this._httpRoutingEntity.restoreOne.type
    ]))
  );

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    httpRoutingItemTranslations: this.httpRoutingItemTranslations$,
    items: this.items$,
    categorizedItems: this.categorizedItems$,
    loadingsMap: this.loadingsMap$
  });

  constructor(
    private _store: Store<AppState>,
    private _httpRoutingEntity: HttpRoutingEntity
  ) {
    super();
  }

}
