import { Injectable } from '@angular/core';
import { zefDialogClose, onZefDialogOpen, zefDialogOpen } from '@zerops/zef/dialog';
import { PortRoutingForm } from '@zerops/zui/port-routing-form';
import { successOf } from '@zerops/zef/entities';
import { PortRouting } from '@zerops/models/port-routing';
import { PortRoutingEntity } from '@zerops/zerops/core/port-routing-base';
import { ZefSnackService } from '@zerops/zef/snack';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, filter, withLatestFrom, take } from 'rxjs/operators';
import { FEATURE_NAME } from './port-routing-add-dialog.constant';
import { portRoutingAddDialogOpen } from './port-routing-add-dialog.action';
import { box } from 'ngrx-forms';
import { ProjectEntity, getFirstNextEmptyPort } from '@zerops/zerops/core/project-base';
import orderBy from 'lodash-es/orderBy';
import { PORT_ROUTING_LIST_FEATURE_NAME } from '../port-routing-list';

@Injectable()
export class PortRoutingAddDialogEffect {

  private _onPortRoutingAddDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(portRoutingAddDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  private _onDialogOpenSetDefaultInternalPort$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    switchMap(({ meta: { ipType, projectId, serviceStackId } }) => this._projectEntity
      .entityByIdWithServiceStacks$(projectId)
      .pipe(
        filter((d) => !!(!!d && !!d.serviceStacks?.length)),
        take(1),
        withLatestFrom(
          this._portRoutingForm.value$,
          // TODO: switchMap instead maybe?
          this._portRoutingEntity.list$({
            name: PORT_ROUTING_LIST_FEATURE_NAME,
            id: projectId
          }),
        ),
        map(([ project , formValues, existingPortRoutings ]) => {

          const serviceStacks = orderBy(
            project.serviceStacks,
            [ (itm) => itm.id === serviceStackId ],
            [ 'desc' ]
          );

          let publicPort: number;

          const allExistingPorts = existingPortRoutings
            .filter((d) => d.publicIpType === ipType)
            .map((d) => d.publicPort);
          const activeServiceStack = serviceStacks[0];
          const activeServiceStackPorts = activeServiceStack.ports;

          if (serviceStackId) {
            publicPort = getFirstNextEmptyPort(
              activeServiceStackPorts.length === 1
                ? activeServiceStackPorts[0].port
                : formValues.publicPort,
                allExistingPorts
            );
          }

          return this._portRoutingForm.setValue({
            ...formValues,
            publicIpType: ipType,
            publicPort,
            internalPortData: box({
              port: activeServiceStackPorts[0].port,
              protocol: activeServiceStackPorts[0].protocol,
              serviceStackId: activeServiceStack.id
            })
          });
        })
      )
    ),
  ));

  private _onAddSuccess$ = this._actions$.pipe(
    successOf<PortRouting>(this._portRoutingEntity.addOne)
  );

  private _onAddSuccessDialogClose$ = createEffect(() => this._onAddSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._portRoutingForm.reset(),
      this._portRoutingForm.setDefaultValues()
    ])
  ));

  private _onAddSuccessNotification$ = createEffect(() => this._onAddSuccess$.pipe(
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.addSuccess` }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _projectEntity: ProjectEntity,
    private _portRoutingEntity: PortRoutingEntity,
    private _portRoutingForm: PortRoutingForm,
    private _snack: ZefSnackService
  ) { }
}
