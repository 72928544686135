import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogClose, onZefDialogOpen } from '@zerops/zef/dialog';
import { UserData } from '@zerops/models/user-data';
import { successOf } from '@zerops/zef/entities';
import { UserDataEntity, setActiveId } from '@zerops/zerops/core/user-data-base';
import { UserDataEditForm } from '@zerops/zui/user-data-edit-form';
// import { ZefSnackService } from '@zerops/zef/snack';
import { map, filter, switchMap, delay } from 'rxjs/operators';
import { FEATURE_NAME } from './user-data-edit-dialog.constant';

@Injectable()
export class UserDataEditDialogEffect {

  private _onUserDataUpdateDialogOpen$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(this._userDataEntity.updateOne.type),
    map(({ meta: { key, content, type } }) => this._userDataEditForm.setValue({ key, content, type }))
  ));

  private _onDialogOpenFocusInput$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    delay(100),
    map(() => this._userDataEditForm.focus('key'))
  ));

  private _onUserDataUpdateSuccess$ = this._actions$.pipe(
    successOf<UserData>(this._userDataEntity.updateOne)
  );

  private _onUpdateSuccessDialogClose$ = createEffect(() => this._onUserDataUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    delay(500),
    switchMap(() => [
      setActiveId(undefined),
      this._userDataEditForm.reset(),
      this._userDataEditForm.setDefaultValues()
    ])
  ));

  private _onDialogCloseReseActiveId$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    map(() => setActiveId(undefined))
  ));

  // private _onUpdateSuccessNotification$ = createEffect(() => this._onUserDataUpdateSuccess$.pipe(
  //   switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.updateSuccess` }))
  // ), { dispatch: false });

  private _onUpdateSuccessResetActiveId$ = createEffect(() => this._onUserDataUpdateSuccess$.pipe(
    map(() => setActiveId(undefined))
  ));

  constructor(
    private _actions$: Actions,
    private _userDataEntity: UserDataEntity,
    private _userDataEditForm: UserDataEditForm
    // private _snack: ZefSnackService,
  ) { }
}
