import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { AppversionStatusIconModule } from '@zerops/zui/app-version-status-icon';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { ConfirmPopModule } from '@zerops/zui/confirm-pop';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiAppVersionNameModule } from '@zerops/zui/app-version-name';
import { ZuiAppVersionStatusModule } from '@zerops/zui/app-version-status';
import { ZuiAppVersionReleaseInfoModule } from '@zerops/zui/app-version-release-info';
import { TranslocoModule } from '@ngneat/transloco';
import { NgStringPipesModule } from 'ngx-pipes';
import { AppVersionItemComponent } from './app-version-item.component';
import { AppVersionItemTranslationsModule } from './app-version-item.translations';
import { TimeagoModule, TimeagoFormatter, Unit, Suffix } from 'ngx-timeago';

export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

const defaultFormattter = function(then: number): {value: number, unit: Unit, suffix: Suffix} {
  const now = Date.now();
  const seconds = Math.round(Math.abs(now - then) / 1000);
  const suffix: Suffix = then < now ? 'ago' : 'from now';

  const [value, unit]: [number, Unit] =
    seconds < MINUTE
      ? [Math.round(seconds), 'second']
      : seconds < HOUR
        ? [Math.round(seconds / MINUTE), 'minute']
        : seconds < DAY
          ? [Math.round(seconds / HOUR), 'hour']
          : seconds < WEEK
            ? [Math.round(seconds / DAY), 'day']
            : seconds < MONTH
              ? [Math.round(seconds / WEEK), 'week']
              : seconds < YEAR
                ? [Math.round(seconds / MONTH), 'month']
                : [Math.round(seconds / YEAR), 'year'];

  return {value, unit, suffix};
};

@Injectable()
export class TimeagoDefaultFormatter extends TimeagoFormatter {
  format(then: number): string {
    const {value, unit} = defaultFormattter(then);
    return this.parse(value, unit);
  }

  private parse(value: number, unit: Unit): string {
    if (value !== 1) {
      unit += 's';
    }
    return value + ' ' + unit ;
  }
}

@NgModule({
  declarations: [ AppVersionItemComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AppversionStatusIconModule,
    AppVersionItemTranslationsModule,
    ZefProgressErrorModule,
    ZuiSeparatorComponent,
    ProgressSpinnerModule,
    TimeagoModule.forChild({
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoDefaultFormatter
      }
    }),
    NgStringPipesModule,
    ZefPipesModule,
    ConfirmPopModule,
    TranslocoModule,
    DynamicPopAnchorModule,
    ZefCopyToClipboardModule,
    ZuiAppVersionNameModule,
    ZuiAppVersionStatusModule,
    ZuiAppVersionReleaseInfoModule
  ],
  exports: [ AppVersionItemComponent ]
})
export class ZuiAppVersionItemModule { }
