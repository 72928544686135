import { Component } from '@angular/core';
import {
  ProjectEntity,
  getProjectPorts
} from '@zerops/zerops/core/project-base';
import { PortRoutingForm } from '@zerops/zui/port-routing-form';
import { PublicIpTypes, FirewallPolicies } from '@zerops/models/port-routing';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { modifyFirewallIpRanges } from '@zerops/zerops/core/port-routing-base/port-routing-base.utils';
import { PortRoutingEntity } from '@zerops/zerops/core/port-routing-base';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { AppState } from '@zerops/zerops/app';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  withLatestFrom,
  map,
  switchMap,
  filter,
  distinctUntilChanged,
  delay
} from 'rxjs/operators';
import { FEATURE_NAME } from './port-routing-add-dialog.constant';
import { PortRoutingAddDialogOpenMeta } from './port-routing-add-dialog.model';
import { formValueOnValid } from '@zerops/zef/forms';

@Component({
  selector: 'z-port-routing-add-dialog',
  templateUrl: './port-routing-add-dialog.container.html',
  styleUrls: [ './port-routing-add-dialog.container.scss' ]
})
export class PortRoutingAddDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onAdd$ = new Subject<void>();
  onAddEmptyFirewallIpRange$ = new Subject<void>();
  onRemoveFirewallIpRange$ = new Subject<number>();
  onClose$ = new Subject<void>();

  // # Forms
  formState$ = this._portRoutingForm.state$;
  formValue$ = this._portRoutingForm.value$;

  // # Data
  // -- sync
  publicIpTypes = PublicIpTypes;
  addKey = this._portRoutingEntity.addOne.type;
  dialogKey = FEATURE_NAME;

  // -- async
  dialogState$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data.state));
  metaData$ = this.dialogState$.pipe(
    map((data) => data.meta as PortRoutingAddDialogOpenMeta),
    filter((d) => !!d)
  );
  ipType$ = this.metaData$.pipe(
    map(({ ipType }) => ipType),
    distinctUntilChanged()
  );
  projectId$ = this.metaData$.pipe(
    map(({ projectId }) => projectId),
    distinctUntilChanged()
  );
  serviceStackId$ = this.metaData$.pipe(
    map(({ serviceStackId }) => serviceStackId),
    distinctUntilChanged()
  );
  project$ = this.projectId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this._projectEntity.entityByIdWithServiceStacks$(id)),
    filter((id) => !!id)
  );
  ip$ = this.ipType$.pipe(
    withLatestFrom(this.project$),
    map(([ ipType, project ]) => ipType === this.publicIpTypes.IpV4
      ? project.publicIpV4
      : project.publicIpV6
    )
  );
  projectPorts$ = this.open$.pipe(
    filter((d) => !!d),
    switchMap(() => this.serviceStackId$.pipe(
      withLatestFrom(this.project$),
      map(([ serviceStackId, project ]) => getProjectPorts(project, serviceStackId, false, true)
    ))
  ));

  // # State resolver
  state = this.$connect({
    open: this.open$,
    project: this.project$,
    formState: this.formState$,
    projectPorts: this.projectPorts$,
    ip: this.ip$
  });

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    delay(0),
    formValueOnValid(this._portRoutingForm),
    map(({
      firewall,
      publicIpType,
      publicPort,
      internalPortData,
      firewallPolicy,
      firewallIpRanges
    }) => this._portRoutingEntity.addOne({
      publicIpType,
      serviceStackId: internalPortData.value.serviceStackId,
      publicPort,
      internalPort: internalPortData.value.port,
      internalProtocol: internalPortData.value.protocol,
      firewallPolicy: firewall ? firewallPolicy : FirewallPolicies.Blacklist,
      // firewallAllowMyIp: firewall && firewallPolicy === FirewallPolicy.Whitelist ? firewallAllowMyIp : false,
      firewallAllowMyIp: false,
      firewallIpRanges: firewall
        ? modifyFirewallIpRanges(firewallIpRanges, publicIpType)
        : []
    }))
  );


  private _addEmptyFirewallIpRangeAction$ = this.onAddEmptyFirewallIpRange$.pipe(
    map(() => this._portRoutingForm.addArrayControl(
      'firewallIpRanges',
      {
        ip: '',
        range: undefined
      }
    ))
  );

  private _removeFirewallIpRangeAction$ = this.onRemoveFirewallIpRange$.pipe(
    map((index) => this._portRoutingForm.removeArrayControl('firewallIpRanges', index))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: this.dialogKey }))
  );

  constructor(
    private _store: Store<AppState>,
    private _projectEntity: ProjectEntity,
    private _portRoutingEntity: PortRoutingEntity,
    private _portRoutingForm: PortRoutingForm
  ) {
    super();

    this.$dispatchActions(
      [
        this._addAction$,
        this._addEmptyFirewallIpRangeAction$,
        this._removeFirewallIpRangeAction$,
        this._closeAction$
      ]
    );
  }

}
