import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPopAnchorDirective } from './dynamic-pop-anchor.directive';

@NgModule({
  declarations: [ DynamicPopAnchorDirective ],
  imports: [ CommonModule ],
  exports: [ DynamicPopAnchorDirective ]
})
export class DynamicPopAnchorModule { }
