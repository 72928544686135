import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Currency } from '@zerops/models/settings';
import { endOfDay, startOfDay, addMinutes, differenceInHours, endOfToday } from 'date-fns/esm';

@Component({
  selector: 'z-project-cost-limit-info',
  templateUrl: './project-cost-limit-info.component.html',
  styleUrls: ['./project-cost-limit-info.component.scss']
})
export class ProjectCostLimitInfoComponent {

  nowDate = new Date();
  nowDateString = this.nowDate.toISOString();
  startOfDayDateString = startOfDay(this.nowDate).toISOString();
  endOfDayDateString = endOfDay(this.nowDate).toISOString();
  hoursTillEndOfDay = differenceInHours(endOfToday(), new Date());

  @Input()
  creditLimit: number;

  @Input()
  remainingCredit: number;

  @Input()
  activeCurrency: Currency;

  @Input()
  set lifetimeScore(v) {
    if (v) {
      this._lifetimeScore = v;
      this.lifeTimeScoreDates = {
        startDate: new Date(0).toISOString(),
        endDate: addMinutes(new Date(0), (v * 60)).toISOString()
      };
    }
  }
  get lifetimeScore() {
    return this._lifetimeScore;
  }

  @Input()
  totalCredit: number;

  @Input()
  removeLoading = false;

  @Output()
  changeLimitClicked = new EventEmitter<void>();

  @Output()
  removeLimitClicked = new EventEmitter<void>();

  private _lifetimeScore: number;
  lifeTimeScoreDates: { startDate: string; endDate: string; };

}
