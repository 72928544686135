import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zui-three-dots',
  templateUrl: './three-dots.component.html',
  styleUrls: [ './three-dots.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreeDotsComponent {

}
