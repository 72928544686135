
export function mergeTooltips(slider: any, threshold: number, separator: string) {

  const isRtl = slider.options.direction === 'rtl';
  const isVertical = slider.options.orientation === 'vertical';
  const tooltips = slider.getTooltips();
  const origins = slider.getOrigins();

  // Move tooltips into the origin element. The default stylesheet handles this.
  tooltips.forEach(function (tooltip: any, index: any) {
      if (tooltip) {
          origins[index].appendChild(tooltip);
      }
  });

  slider.on('update', function (values: any, handle: any, unencoded: any, tap: any, positions: any) {

      const pools = [[]];
      const poolPositions = [[]];
      const poolValues = [[]];
      let atPool = 0;

      // Assign the first tooltip to the first pool, if the tooltip is configured
      if (tooltips[0]) {
          pools[0][0] = 0;
          poolPositions[0][0] = positions[0];
          poolValues[0][0] = values[0];
      }

      for (let i = 1; i < positions.length; i++) {
          if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
              atPool++;
              pools[atPool] = [];
              poolValues[atPool] = [];
              poolPositions[atPool] = [];
          }

          if (tooltips[i]) {
              pools[atPool].push(i);
              poolValues[atPool].push(values[i]);
              poolPositions[atPool].push(positions[i]);
          }
      }

      pools.forEach(function (pool, poolIndex) {
          const handlesInPool = pool.length;

          for (let j = 0; j < handlesInPool; j++) {
              const handleNumber = pool[j];

              if (j === handlesInPool - 1) {
                  let offset = 0;

                  poolPositions[poolIndex].forEach(function (value) {
                      offset += 1000 - value;
                  });

                  const direction = isVertical ? 'bottom' : 'right';
                  const last = isRtl ? 0 : handlesInPool - 1;
                  const lastOffset = 1000 - poolPositions[poolIndex][last];
                  offset = (offset / handlesInPool) - lastOffset;

                  // Center this tooltip over the affected handles
                  tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator);
                  tooltips[handleNumber].style.display = 'block';
                  tooltips[handleNumber].style[direction] = offset + '%';
              } else {
                  // Hide this tooltip
                  tooltips[handleNumber].style.display = 'none';
              }
          }
      });
  });
}
