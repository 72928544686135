import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiEntityKeys } from '@zerops/zerops/app';
import { map, filter } from 'rxjs/operators';
import { getSubscriptionNameForFeature } from '@zerops/zef/entities';
import { markAsRead, UNREAD_NOTIFICATION_LIST_KEY } from '@zerops/zerops/core/notification-base';
import { onWebsocketSubscriptionName } from '@zerops/zef/websocket';
import { addNotification, removeNotification } from './notification.action';

@Injectable()
export class NotificationEffect {

  private _listSubscription$ = createEffect(() => this._actions$.pipe(
    onWebsocketSubscriptionName(getSubscriptionNameForFeature(
      ApiEntityKeys.Notification,
      'list',
      UNREAD_NOTIFICATION_LIST_KEY
    )),
    filter((message) => message.data.add && message.data.add.length),
    map((message) => addNotification(message.data.add[0]))
  ));

  private _onNotificationRemove$ = createEffect(() => this._actions$.pipe(
    ofType(removeNotification),
    filter((action) => !!action?.shouldMarkAsRead),
    map(({ id }) => markAsRead({ id }))
  ));

  constructor(private _actions$: Actions) { }

}
