import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ZefFormBase, ZefFormsModule } from '@zerops/zef/forms';
import { ProjectEnvsEditFormState } from './project-envs-edit-form.model';
import { CodeFieldModule } from '@zerops/zui/code-field';

@Component({
  selector: 'z-project-envs-edit-form',
  templateUrl: './project-envs-edit-form.component.html',
  styleUrls: [ './project-envs-edit-form.component.scss' ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ZefFormsModule,
    CodeFieldModule
  ]
})
export class ProjectEnvsEditFormComponent extends ZefFormBase<ProjectEnvsEditFormState> {}
