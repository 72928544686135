<span
  *ngIf="!!prefix"
  class="__prefix  c-price-prefix"
  [innerHTML]="prefix">
</span><!--

--><span
  *ngIf="currency?.displaySymbolBefore"
  class="__symbol is-before  [ c-price-symbol  c-price-symbol--before ]"><!--
  -->{{ amount < 0 ? '-' : '' }}{{ currency?.symbol }}<!--
--></span><!--

--><span class="__amount  c-price-amount"><!--
-->{{ (amount < 0 ? -amount : amount) | noDecimals | currency: currency?.id : '' : '1.0-0' }}<!--
--></span><!--

--><span [class.is-zero]="!!(amount | decimals)" class="__amount-decimals  c-price-amount-decimals" [class.is-shrinked]="shrinkDecimals"><!--
-->.{{ amount | decimals: decimalCount: minDecimalCount }}<!--
--></span><!--

--><span
  *ngIf="!currency?.displaySymbolBefore"
  class="__symbol is-after  [ c-price-symbol  c-price-symbol--after ]"><!--
-->{{ currency?.symbol }}<!--
--></span><!--

--><span class="__suffix  c-price-suffix"><!--
--><ng-content></ng-content><!--
--></span>
