import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ToggleContentModule } from '@zerops/zui/toggle-content';
import { LinkedBasicInfoHeaderComponent } from './linked-basic-info-header.component';

@NgModule({
  declarations: [ LinkedBasicInfoHeaderComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    ToggleContentModule
  ],
  exports: [ LinkedBasicInfoHeaderComponent ]
})
export class LinkedBasicInfoHeaderModule {

}
