import { createAction, union } from '@ngrx/store';
import { AppVersionFileSelectedPayload } from '@zerops/models/app-version';
import { zefActionFailPayload, zefActionPayload } from '@zerops/zef/core';

export const filesSelected = createAction(
  '[@zerops/zerops/core/app-version-base] files-selected',
  (data: AppVersionFileSelectedPayload) => ({
    serviceStackId: data.serviceStackId,
    files: data.files,
    _iid: data._iid
  })
);

export const filesUploadProgress = createAction(
  '[@zerops/zerops/core/app-version-base] files-upload/progress',
  (data: { progress: number; }) => ({ progress: data.progress })
);

export const filesUploadSuccess = createAction(
  '[@zerops/zerops/core/app-version-base] files-upload/success',
  (data: { serviceStackId: string; }, meta?: { temporaryShutdown: any; }) => ({
    serviceStackId: data.serviceStackId,
    meta: { temporaryShutdown: meta.temporaryShutdown }
  })
);

export const filesUploadFail = createAction('[@zerops/zerops/core/app-version-base] files-upload/fail');

export const deploy = createAction(
  '[@zerops/zerops/core/app-version-base] deploy',
  zefActionPayload<{ id: string; temporaryShutdown: boolean; }>()
);

export const deployFail = createAction(
  '[@zerops/zerops/core/app-version-base] deploy/fail',
  zefActionFailPayload
);

export const deploySuccess = createAction(
  '[@zerops/zerops/core/app-version-base] deploy/success'
);

export const download = createAction(
  '[@zerops/zerops/core/app-version-base] download',
  (id: string) => ({ id })
);

const all = union({
  filesSelected,
  filesUploadProgress,
  filesUploadSuccess,
  filesUploadFail,
  deploy,
  deployFail,
  deploySuccess
});

export type AppVersionBaseActionUnion = typeof all;
