import {
  Component,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { SatPopover } from '@zerops/zef/popover';
import { AppState, GeneralTranslations } from '@zerops/zerops/app';
import { selectZefProgressMapByType, toProgressMapKey } from '@zerops/zef/progress';
import { PortRoutingEntity } from '@zerops/zerops/core/port-routing-base';
import { PortRouting } from '@zerops/models/port-routing';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  map,
  tap,
  withLatestFrom,
  switchMap,
  filter,
  share
} from 'rxjs/operators';
import { ObservableInput } from 'observable-input';

@Component({
  selector: 'z-port-routing-actions-pop',
  templateUrl: './port-routing-actions-pop.container.html',
  styleUrls: [ './port-routing-actions-pop.container.scss' ]
})
export class PortRoutingActionsPopContainer extends ZefReactiveComponent implements OnDestroy {
  // # Event Streams
  onPortRoutingDelete$ = new Subject<void>();
  onPortRoutingRestore$ = new Subject<void>();

  // # Data
  // -- sync
  portRoutingDeleteType = this._portRoutingEntity.deleteOne.type;
  portRoutingRestoreType = this._portRoutingEntity.restoreOne.type;

  // -- angular
  @ObservableInput()
  @Input('id')
  id$!: Observable<string>;

  @Input()
  activeElRef: ElementRef<any>;

  @Output()
  popClosed = new EventEmitter<void>();

  @Output()
  editClicked = new EventEmitter<PortRouting>();

  @ViewChild('popoverRef', { static: true })
  popoverRef: SatPopover;

  // -- sync

  // -- async
  portRouting$ = this.id$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._portRoutingEntity.entityById$(id)),
    filter((d) => !!d)
  );
  deleteOnSync$ = this.portRouting$.pipe(
    map((d) => d.deleteOnSync)
  );
  loadingsMap$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(
      select(selectZefProgressMapByType([
        this.portRoutingDeleteType,
        this.portRoutingRestoreType
      ])),
      map((m) => m && m[id] ? m[id] : {}),
    )),
    share()
  );
  isDeleteLoading$ = this.loadingsMap$.pipe(toProgressMapKey(this.portRoutingDeleteType));
  isRestoreLoading$ = this.loadingsMap$.pipe(toProgressMapKey(this.portRoutingRestoreType));
  generalTranslations$ = this.translate$<GeneralTranslations>('general');

  // # State resolver
  state = this.$connect({
    generalTranslations: this.generalTranslations$,
    portRouting: this.portRouting$,
    deleteOnSync: this.deleteOnSync$,
    isDeleteLoading: this.isDeleteLoading$,
    isRestoreLoading: this.isRestoreLoading$
  });

  // # Action Streams
  private _portRoutingDeleteAction$ = this.onPortRoutingDelete$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => this._portRoutingEntity.deleteOne(
      id,
      undefined,
      { type: 'pop' }
    )),
    tap(() => this.popoverRef.isOpen() ? this.popoverRef.close() : undefined)
  );
  private _portRoutingRestoreAction$ = this.onPortRoutingRestore$.pipe(
    withLatestFrom(this.id$),
    map(([ _, id ]) => this._portRoutingEntity.restoreOne(
      id,
      undefined,
      { type: 'pop' }
    )),
    tap(() => this.popoverRef.isOpen() ? this.popoverRef.close() : undefined)
  );

  constructor(
    private _store: Store<AppState>,
    private _portRoutingEntity: PortRoutingEntity
  ) {
    super();

    this.$dispatchActions(
      [
        this._portRoutingDeleteAction$,
        this._portRoutingRestoreAction$
      ]
    );
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
