import { RECIPES } from './data';
import { Recipe, RecipeCategory } from './recipes.model';

export const getRecipeByCategory = (r: Recipe[]) => r.reduce((obj, itm) => {
  if (obj[itm.category] === undefined) { obj[itm.category] = []; }
  obj[itm.category].push(itm);
  return obj;
}, {} as Record<RecipeCategory, Recipe[]>);

export const getRecipeMapById = (r: Recipe[]) => r.reduce((obj, itm) => {
  obj[itm.id] = itm;
  return obj;
}, {} as Record<string, Recipe>);

export const getRecipeById = (id: string) => RECIPES.find((itm) => {
  return itm.id === id;
});
