import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ZeDiscordButtonComponent } from './discord-button.component';

@NgModule({
  declarations: [ ZeDiscordButtonComponent ],
  imports: [ CommonModule ],
  exports: [ ZeDiscordButtonComponent ]
})
export class ZeDiscordButtonModule {

  constructor(injector: Injector) {
    const customElement = createCustomElement(ZeDiscordButtonComponent, { injector });
    customElements.define('ze-discord-button', customElement);
  }

}
