import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef
} from '@angular/core';

import { ZefScheduler } from '@zerops/zef/core';

@Component({
  templateUrl: './recipe-tag.component.html',
  styleUrls: [ './recipe-tag.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZeRecipeTagComponent extends ZefScheduler {

  @Input()
  slug: string;

  data = {};
  activeTags = {};

  constructor(
    private _host: ElementRef
  ) {
    super();

    this._host.nativeElement.setData = this.setData.bind(this);
  }

  setData(data: any = {}, activeTags: any = {}) {
    this.data = data;
    this.activeTags = activeTags;
    this.renderScheduler.schedule();
  }

}
