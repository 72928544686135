import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectBaseState } from './project-base.model';
import { FEATURE_NAME } from './project-base.constant';

export const selectProjectBaseState = createFeatureSelector<ProjectBaseState>(FEATURE_NAME);

export const selectProjectTags = createSelector(
  selectProjectBaseState,
  (state) => state.tags
);

/**
 * Selects all registered WireGuard VPN public keys for an actual project.
 */
export const selectProjectVpnSettingsMap = (id: string) => createSelector(
  selectProjectBaseState,
  (state) => state.vpnSettingsMap[id]?.peers
);
