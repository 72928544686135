import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefWebsocketToken, selectZefWebsocketReceiverId } from '@zerops/zef/websocket';
import { WebsocketDebuggerService, WebsocketDebuggerStatuses } from './websocket-debugger.service';

@Component({
  selector: 'z-websocket-debugger',
  templateUrl: './websocket-debugger.component.html',
  styleUrls: [ './websocket-debugger.component.scss' ],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
            style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
            style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class WebsocketDebuggerComponent extends ZefReactiveComponent {

  // # Data
  // -- sync
  debuggerStatuses = WebsocketDebuggerStatuses;
  shrinked = false;
  displayHistoricalConnections = false;

  // -- async
  debuggerStatus$ = this._websocketDebuggerService.debuggerStatus$;
  successConnections$ = this._websocketDebuggerService.successConnections$;
  messages$ = this._websocketDebuggerService.messages$;
  lastMessageDate$ = this._websocketDebuggerService.lastMessageDate$;
  token$ = this._store.pipe(select(selectZefWebsocketToken));
  receiverId$ = this._store.pipe(select(selectZefWebsocketReceiverId));


  // # State resolver
  state = this.$connect({
    debuggerStatus: this.debuggerStatus$,
    token: this.token$,
    receiverId: this.receiverId$,
    successConnections: this.successConnections$,
    messages: this.messages$,
    lastMessageDate: this.lastMessageDate$
  });

  constructor(
    private _store: Store<any>,
    private _websocketDebuggerService: WebsocketDebuggerService
  ) {
    super();
  }

  toggleShrink() {
    this.shrinked = !this.shrinked;
  }

  toggleHistoricalConnections() {
    this.displayHistoricalConnections = !this.displayHistoricalConnections;
  }
}
