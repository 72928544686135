import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './common-standard-service-stack-form.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  hostnameTitle: `Hostname`,
  hostnameDesc: `Select a short, URL-friendly name, like 'app', 'cache','qui' etc. Other services will be able to access this service using this hostname and the internal port, i.e., gui:3000. You can also access the services in the same way if using zcli VPN.`,
  hostnameLabel: 'Hostname',
  haDesc: `High availability mode allows for the <a data-zui-docs="ha/zerops-enterprise-grade-reliability.html">
  highest possible reliability</a> and the most optimized performance.
  Each service with high availability enabled will run on at least two
  (<a data-zui-docs="services/runtimes.html">runtimes</a>) hardware-separated containers.`,
  hostnamePlaceholder: 'Use anything URL-friendly …'
};

export type CommonStandardServiceStackFormTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class CommonStandardServiceStackFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
