import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { StatisticsGroupBy, StatisticsIds, CurrentStatistic, StatisticsTimeGroupBy } from '@zerops/models/resource-statistics';
import { formatRFC3339 } from 'date-fns/esm';
import { getCurrentResourceStatisticsSubscriptionName, getHistoryResourceStatisticsSubscriptionName } from './resource-statistics-base.utils';

@Injectable({ providedIn: 'root' })
export class ResourceStatisticsBaseApi {

  groupSearchCurrentByClient$(
    id: string,
    groupBy: StatisticsGroupBy = StatisticsGroupBy.ServiceStack
  ) {
    return this.groupSearchCurrent$({
      name: StatisticsIds.Client,
      id
    }, groupBy);
  }

  groupSearchCurrentByServiceStack$(
    id: string,
    groupBy: StatisticsGroupBy = StatisticsGroupBy.ServiceStack
  ) {
    return this.groupSearchCurrent$({
      name: StatisticsIds.ServiceStack,
      id
    }, groupBy);
  }

  groupSearchCurrentByService$(
    id: string,
    groupBy: StatisticsGroupBy = StatisticsGroupBy.ServiceStack
  ) {
    return this.groupSearchCurrent$({
      name: StatisticsIds.Service,
      id
    }, groupBy);
  }

  groupSearchCurrentByProject$(
    id: string,
    groupBy: StatisticsGroupBy = StatisticsGroupBy.ServiceStack
  ) {
    return this.groupSearchCurrent$({
      name: StatisticsIds.Project,
      id
    }, groupBy);
  }

  groupSearchCurrent$(
    { id, name }: { name: string; id: string },
    groupBy: StatisticsGroupBy
  ) {
    return this._http.post<{ items: CurrentStatistic[]; }>(
      '/api/current-stats/group-by-search',
      {
        search: [
          {
            name,
            operator: 'eq',
            value: id
          },
          {
            name: 'billingEnabled',
            operator: 'is',
            value: true
          }
        ],
        receiverId: this._wsService.getReceiverId(),
        subscriptionName: getCurrentResourceStatisticsSubscriptionName(
          groupBy,
          id
        ),
        groupBy
      }
    );
  }

  groupSearchHistory$(
    clientId: string,
    groupBy: StatisticsGroupBy,
    timeGroupBy: StatisticsTimeGroupBy,
    limit: number,
    projectId?: string,
    serviceId?: string,
    containerId?: string,
    range?: {
      from: Date;
      to: Date;
    },
    key?: string
  ) {
    return this._http.post<{ items: any[]; }>(
      '/api/stats-history/group-by-search',
      {
        search: [
          {
            name: 'clientId',
            operator: 'eq',
            value: clientId
          },
          {
            name: 'billingEnabled',
            operator: 'is',
            value: true
          },
          !!projectId
            ? {
              name: 'projectId',
              operator: 'eq',
              value: projectId
            }
            : undefined,
          !!serviceId
            ? {
              name: 'serviceStackId',
              operator: 'eq',
              value: serviceId
            }
            : undefined,
          !!containerId
            ? {
              name: 'containerId',
              operator: 'eq',
              value: containerId
            }
            : undefined
        ].filter((itm) => !!itm),
        receiverId: this._wsService.getReceiverId(),
        subscriptionName: getHistoryResourceStatisticsSubscriptionName(
          groupBy,
          limit,
          timeGroupBy,
          projectId,
          serviceId,
          containerId,
          key
        ),
        groupBy,
        timeGroupBy,
        limit,
        timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        from: !!limit ? undefined : formatRFC3339(range?.from),
        till: !!limit ? undefined : formatRFC3339(range?.to)
      }
    );
  }

  constructor(
    private _http: HttpClient,
    private _wsService: ZefWebsocketService
  ) { }

}
