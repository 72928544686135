import {
  Component,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Contexts, CONTEXTUAL_DIALOG_FEATURE_NAME } from '@zerops/zui/contextual-dialog';
import { zefDialogOpen } from '@zerops/zef/dialog';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FEATURE_NAME } from './web-public-routing-pop.constant';
import { WebPublicRoutingPopTranslations } from './web-public-routing-pop.translations';
import { WebPublicRoutingPopService } from './web-public-routing-pop.service';

@Component({
  selector: 'zui-web-public-routing-pop',
  templateUrl: './web-public-routing-pop.container.html',
  styleUrls: [ './web-public-routing-pop.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebPublicRoutingPopContainer extends ZefReactiveComponent implements AfterViewInit {

  // # Event Streams
  onOpenContextualDialog$ = new Subject<Contexts>();

  // # Data
  // -- angular
  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  // -- sync
  showAllRecords = false;
  hasBackdrop = false;
  contexts = Contexts;
  serviceStackStatuses = ServiceStackStatuses;

  // -- async
  translations$ = this.translate$<WebPublicRoutingPopTranslations>(FEATURE_NAME);
  data$ = this._webPublicRoutingPopService.getData$().pipe(
    filter((d) => !!d)
  );
  serviceStackId$ = this.data$.pipe(
    map((d) => d?.serviceStackId)
  );
  httpRoutings$ = this.data$.pipe(
    map((d) => d?.httpRoutings)
  );
  portRoutings$ = this.data$.pipe(
    map((d) => d?.portRoutings)
  );
  ports$ = this.data$.pipe(
    map((d) => d?.ports)
  );
  publicIpV4$ = this.data$.pipe(
    map((d) => d?.publicIpV4)
  );
  publicIpV6$ = this.data$.pipe(
    map((d) => d?.publicIpV6)
  );

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    serviceStackId: this.serviceStackId$,
    httpRoutings: this.httpRoutings$,
    portRoutings: this.portRoutings$,
    ports: this.ports$,
    publicIpV4: this.publicIpV4$,
    publicIpV6: this.publicIpV6$
  });

  // # Action Streams
  private _openContextualDialogAction$ = this.onOpenContextualDialog$.pipe(
    map((meta) => zefDialogOpen({
      key: CONTEXTUAL_DIALOG_FEATURE_NAME,
      meta
    }))
  );

  constructor(
    private _webPublicRoutingPopService: WebPublicRoutingPopService
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._openContextualDialogAction$ ]);
  }

  ngAfterViewInit() {
    this._webPublicRoutingPopService.saveRef(this.popRef);
  }

}
