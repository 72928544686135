import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ContainerBaseEffect } from './container-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ContainerBaseEffect ])
  ]
})
export class ContainerBaseModule {

}
