export const REACT_NODEJS_IMPORT_YAML = `project:
  name: recipe-react
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: nodejs@20
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-react-nodejs`;
