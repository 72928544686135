export const QWIK_STATIC_IMPORT_YAML = `project:
  name: recipe-qwik
  tags:
    - zerops-recipe

services:
  - hostname: staticapp
    type: static
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-qwik-static`;
