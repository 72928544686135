import { createAction, props, union } from '@ngrx/store';
import { NotificationsPopOpenPayload } from './notifications-pop.model';

export const notificationsPopSetSettings = createAction(
  '[@zerops/zerops/feature/notifications-pop] set-settings',
  props<NotificationsPopOpenPayload>()
);

export const notificationsPopSettingsReset = createAction(
  '[@zerops/zerops/feature/notifications-pop] reset-settings'
);

const all = union({
  notificationsPopSetSettings,
  notificationsPopSettingsReset
});

export type NotificationsPopActionUnion = typeof all;
