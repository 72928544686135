import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { FormState, validate } from 'ngrx-forms';
import { greaterThanOrEqualTo, number, required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './top-up-amount-form.constant';

export interface TopUpAmountFormValue {
  amount: number;
}

export type TopUpAmountFormState = FormState<TopUpAmountFormValue>;

@Injectable({ providedIn: 'root' })
export class TopUpAmountForm extends ZefForm<TopUpAmountFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        amount: null
      },
      {
        amount: validate(required, number, greaterThanOrEqualTo(10))
      }
    );
  }
}
