import { ErrorHandler, Injectable, Inject, InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '@zerops/zerops/env';
import has from 'lodash-es/has';

const rollbarConfig = {
  accessToken: 'd270c426ea76465781ad6bb512eb7ef0', // '09b65626b4064fba980a2179977a2a88'
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.envName,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.appVersion,
        guess_uncaught_frames: true
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService)
    private _rollbar: Rollbar
  ) { }

  handleError(err:any) : void {
    this._rollbar.error(err.originalError || err);

    if (!environment.production) {
      throw err.originalError || err;
    } else if (localStorage && has(localStorage, 'FORCE_ERROR_LOG')) {
      console.error(err.originalError || err);
    }
  }
}

export const rollbarErrorProvider = [
  {
    provide: ErrorHandler,
    useClass: RollbarErrorHandler
  },
  {
    provide: RollbarService,
    useFactory: rollbarFactory
  }
];
