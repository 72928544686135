import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './project-envs-edit-dialog.constant';

const en = {
  titleDialog: 'Project custom environment variables multi-editation',
  titleActionButton: 'Update custom environment variables',
  snackSuccessMessage: 'Project custom variables updated.'
};

export type ProjectEnvsEditDialogTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class ProjectEnvsEditDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
