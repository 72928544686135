import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './app-version-base.constant';

const en = {
  deleted: 'Deployed package was removed successfully.'
};

export type AppVersionBaseTranslations = typeof en;

@NgModule()
export class AppVersionBaseTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
