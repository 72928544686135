import { Injectable } from '@angular/core';

import { Sort } from '@zerops/zef/sort';
import { ViewModes } from '@zerops/zui/view-mode-switcher';
import {
  PersistentUserSettings,
  ViewModeSettingNames,
  SortModeSettingNames
} from './persistent-user-settings.model';
import { FEATURE_NAME } from './persistent-user-settings.constant';

@Injectable({ providedIn: 'root' })
export class PersistencySettingsApi {

  #persistentUserSettings: PersistentUserSettings = {
    viewMode: {
      [ViewModeSettingNames.DASHBOARD_PROJECTS_VIEW_MODE]: null,
      [ViewModeSettingNames.DASHBOARD_USERS_VIEW_MODE]: null,
      [ViewModeSettingNames.PROJECT_STACKS_VIEW_MODE]: null
    },
    sortMode: {
      [SortModeSettingNames.DASHBOARD_PROJECTS_SORT_MODE]: null,
      [SortModeSettingNames.DASHBOARD_USERS_SORT_MODE]: null,
      [SortModeSettingNames.PROJECT_STACKS_SORT_MODE]: null
    }
  };

  #updateLocalStorage(): void {
    localStorage.setItem(FEATURE_NAME, JSON.stringify(this.#persistentUserSettings));
  }

  #readLocalStorage(): void {
    const value = localStorage.getItem(FEATURE_NAME);
    if (value) {
      try {
        const userSettings = JSON.parse(value) as PersistentUserSettings;
        this.#persistentUserSettings = {
          ...this.#persistentUserSettings,
          ...userSettings
        }
      } catch (e) {
        return console.error('... not able to parse the saved user settings from a local storage.');
      }
    }
  }

  constructor() {
    this.#readLocalStorage();
  }

  getViewModePersistency(viewModeSettingName: ViewModeSettingNames): ViewModes | null {
    const value = this.#persistentUserSettings.viewMode[viewModeSettingName];
    return value;
  }

  putViewModePersistency(viewModeSettingName: ViewModeSettingNames, value: ViewModes): void {
    this.#persistentUserSettings.viewMode[viewModeSettingName] = value;
    this.#updateLocalStorage();
  }

  getSortModePersistency(sortModeSettingName: SortModeSettingNames): Sort | null {
    const value = this.#persistentUserSettings.sortMode[sortModeSettingName];
    return value;
  }

  putSortModePersistency(sortModeSettingName: SortModeSettingNames, value: Sort): void {
    this.#persistentUserSettings.sortMode[sortModeSettingName] = value;
    this.#updateLocalStorage();
  }

}
