import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './port-routing-edit-dialog.constant';

const en = {
  updateSuccess: 'Port routing was updated successfully.'
};

export type PortRoutingEditDialogTranslations = typeof en;

@NgModule()
export class PortRoutingEditDialogTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
