import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserDataBaseEffect } from './user-data-base.effect';
import { FEATURE_NAME } from './user-data-base.constant';
import { userDataBaseReducer } from './user-data-base.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, userDataBaseReducer),
    EffectsModule.forFeature([ UserDataBaseEffect ])
  ]
})
export class UserDataBaseModule {

}
