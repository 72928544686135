import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { VersionWarningContainer } from './version-warning.container';

@NgModule({
  declarations: [ VersionWarningContainer ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ VersionWarningContainer ]
})
export class VersionWarningModule {

}
