import {
  Component,
  ChangeDetectionStrategy,
  Input, Output, EventEmitter, OnChanges
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  HttpRoutingWithLocations,
  HttpRoutingLocation,
  SslStatuses,
  DnsCheckStatuses } from '@zerops/models/http-routing';
import { PortRouting, PublicIpTypes } from '@zerops/models/port-routing';
import {
  RABBIT_MQ_SERVICE_STACK_TYPE_NAME,
  RABBIT_MQ_WS1_PORT,
  RABBIT_MQ_WS2_PORT,
  ServiceStackPort,
  ServiceStackStatuses
} from '@zerops/models/service-stack';
import { SatPopover } from '@zerops/zef/popover';
import flatten from 'lodash-es/flatten';

export interface HttpRoutingPrivateProperties {
  _sslEnabled: boolean;
  _activeSsl: boolean;
  _isSynced: boolean;
  _dnsCheckStatus: DnsCheckStatuses;
}

export enum ShowMoreButtonModes {
  Redirect = 'redirect',
  Pop = 'pop',
  Context = 'context'
}

@Component({
  selector: 'zui-public-routing-content',
  templateUrl: './public-routing-content.component.html',
  styleUrls: [ './public-routing-content.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicRoutingContentComponent implements OnChanges {

  @Input()
  serviceStackId: string;

  @Input()
  serviceStackName: string;

  @Input()
  serviceStackStatus: ServiceStackStatuses;

  @Input()
  itemsNumber = 5;

  @Input()
  ports: ServiceStackPort[];

  @Input()
  type: 'stack' | 'other' = 'stack';

  @Input()
  publicIpV4: string;

  @Input()
  publicIpV6: string;

  @Input()
  set httpRoutings(v: HttpRoutingWithLocations[]) {
    if (v) {
      // data preparation for routing location item component
      this.httpRoutingLocations = flatten(v.map((routing) => routing.domains.reduce((arr, domain) => {
        routing._stackLocations.forEach((itm) => {
          const protocol = this.ports?.find((p) => p.port === itm.port)?.protocol;

          const path = itm.serviceStackInfo?.serviceStackTypeName === RABBIT_MQ_SERVICE_STACK_TYPE_NAME
            && (domain.domainName.includes(RABBIT_MQ_WS1_PORT.toString()) || domain.domainName.includes(RABBIT_MQ_WS2_PORT.toString()) || itm.port === RABBIT_MQ_WS1_PORT || itm.port === RABBIT_MQ_WS2_PORT)
              ? ('wss://' + domain.domainName + (itm.path !== '/' ? itm.path : '') + (domain.domainName.includes('app.zerops.io') ? '/ws' : ''))
              : ((routing.sslEnabled ? 'https://' : 'http://') + domain.domainName + (itm.path !== '/' ? itm.path : ''))

          arr.push({
            ...itm,
            path,
            protocol,
            _sslEnabled: routing.sslEnabled,
            _activeSsl: domain.sslStatus === SslStatuses.Active,
            _isSynced: routing.isSynced,
            _dnsCheckStatus: domain.dnsCheckStatus
          });

        });
        return arr;
      }, [])));
      this.httpRoutingLocationsSliced = this.httpRoutingLocations.slice(0, this.itemsNumber);
    }
  }

  @Input()
  portRoutings: PortRouting[];

  @Input()
  copyButtonTooltip: string;

  @Input()
  showMoreButtonMode: ShowMoreButtonModes = ShowMoreButtonModes.Redirect;

  @Input()
  publicRoutingPopRef: SatPopover;

  @Input()
  set showAllRecords(v: string | boolean) {
    this._showAllRecords = coerceBooleanProperty(v);
  }
  get showAllRecords(): boolean {
    return this._showAllRecords;
  }

  @Output()
  openRoutingSettingsClicked = new EventEmitter<void>();

  showMoreButtonModes = ShowMoreButtonModes;
  httpRoutingLocations: (HttpRoutingLocation & HttpRoutingPrivateProperties)[];
  httpRoutingLocationsSliced: (HttpRoutingLocation & HttpRoutingPrivateProperties)[];
  portRoutingsSliced: PortRouting[];
  portRoutingsRaw: PortRouting[];
  private _showAllRecords = false;

  trackByIndex(index: number) {
    return index;
  }

  ngOnChanges() {
    if ((!!this.publicIpV6 || !!this.publicIpV4) && this.portRoutings?.length) {

      this.portRoutingsRaw = this.portRoutings.map((itm) => ({
        ...itm,
        path: (itm.publicIpType === PublicIpTypes.IpV6 ? '[' : '')
        + (itm.publicIpType === PublicIpTypes.IpV4 ? this.publicIpV4 : this.publicIpV6)
        + (itm.publicIpType === PublicIpTypes.IpV6 ? ']:' : ':') + itm.publicPort
      }));

      this.portRoutingsSliced = this.portRoutingsRaw.slice(0, this.itemsNumber);

    }
  }

}
