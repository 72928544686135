import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Notification } from '@zerops/models/notification';
import { NotificationEntity } from '@zerops/zerops/core/notification-base';
import { AppVersionItemTranslations, APP_VERSION_ITEM_FEATURE_NAME } from '@zerops/zui/app-version-item';
import { zefDialogOpen } from '@zerops/zef/dialog';
import {
  NotificationItemTranslations,
  NOTIFICATION_ITEM_FEATURE_NAME
} from '@zerops/zui/notification-item';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { pipelineDetailDialogOpen } from '@zerops/zerops/feature/pipeline-detail-dialog';
import { ServiceStackTypeCategories } from '@zerops/models/service-stack';
import { AppVersion } from '@zerops/models/app-version';
import { selectActiveNotifications } from './notification.selector';
import { removeNotification } from './notification.action';
import { notificationAnimations } from './notification.animations';
import { LOG_DIALOG_FEATURE_NAME } from '../log-dialog';
import { PipelineError } from '@zerops/models/error-backend';

@Component({
  selector: 'z-notification',
  templateUrl: './notification.feature.html',
  styleUrls: [ './notification.feature.scss' ],
  animations: [ notificationAnimations() ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationFeature extends ZefReactiveComponent {

  // # Event Streams
  onRemove$ = new Subject<string>();
  onOpenLogDialog$ = new Subject<{
    serviceStackId: string;
    projectId: string;
    containerId: string;
    from: string;
    to: string;
  }>();
  onOpenPipelineDetailDialog$ = new Subject<{ appVersion: AppVersion; pipelineErrors: PipelineError[]; }>();

  // # Data
  // -- sync
  typeColorMap = {
    WARNING: 'orange'
  };

  // -- async
  notifications$ = this._notificationEntity
    .listByIds$(selectActiveNotifications)
    .pipe(
      map((data) => data.filter((itm) => !itm.acknowledged
      && !(
        itm.actionName === 'stack.create'
        // no need to show unread notification for
        // user stacks, it is handled by new-service-dialog
        && itm.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeCategory === ServiceStackTypeCategories.User)
      ))
    );

  notificationItemTranslations$ = this.translate$<NotificationItemTranslations>(NOTIFICATION_ITEM_FEATURE_NAME);

  appVersionItemTranslations$ = this.translate$<AppVersionItemTranslations>(APP_VERSION_ITEM_FEATURE_NAME);

  // # State resolver
  state = this.$connect({
    notifications: this.notifications$,
    notificationItemTranslations: this.notificationItemTranslations$,
    appVersionItemTranslations: this.appVersionItemTranslations$
  });

  // # Action Streams
  private _removeAction$ = this.onRemove$.pipe(
    map((id) => removeNotification(id, true))
  );

  private _openLogDialogAction$ = this.onOpenLogDialog$.pipe(
    map((meta) => zefDialogOpen({
      key: LOG_DIALOG_FEATURE_NAME,
      meta
    }))
  );

  private _openPipelineDetailDialog$ = this.onOpenPipelineDetailDialog$.pipe(
    map((data) => pipelineDetailDialogOpen(data))
  );

  constructor(
    private _notificationEntity: NotificationEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._removeAction$,
      this._openLogDialogAction$,
      this._openPipelineDetailDialog$
    ]);

  }

  trackById(_: number, item: Notification) {
    return item.id;
  }

}
