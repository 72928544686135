import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { ActionListItemComponent } from './action-list-item.component';

@NgModule({
  declarations: [ ActionListItemComponent ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    MatTooltipModule,
    ProgressSpinnerModule
  ],
  exports: [ ActionListItemComponent ]
})
export class ActionListItemModule {

}
