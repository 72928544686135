import {
  Component,
  ViewChild,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { storeUserData } from '@zerops/zerops/core/user-base';
import { ClientUser } from '@zerops/models/client-user';

@Component({
  selector: 'z-client-user-select-pop',
  templateUrl: './client-user-select-pop.container.html',
  styleUrls: [ './client-user-select-pop.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientUserSelectPopContainer extends ZefReactiveComponent {

  // # Event Streams
  onSelectClientUser$ = new Subject<ClientUser>();

  // # Data
  // -- angular
  @Input()
  clientUsers: ClientUser[];

  @Input()
  title: string;

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;

  // # Action Streams
  private _storeUserDataAction$ = this.onSelectClientUser$.pipe(
    map(({ userId, id }) => storeUserData(userId, id, true))
  );

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._storeUserDataAction$ ]);
  }

}
