import { HttpRouting, HttpRoutingWithLocations, Domain, HttpRoutingLocation } from '@zerops/models/http-routing';
import uniq from 'lodash-es/uniq';
import partition from 'lodash-es/partition';
import { box } from 'ngrx-forms';
import { CategorizedHttpRoutings } from './http-routing-base.model';

export const httpRoutingLocationsByStackId = (
  httpRoutings: HttpRouting[],
  serviceStackId: string,
  filterFallbackRule = false
): HttpRoutingWithLocations[] => {
  return httpRoutings
  .filter((itm) => filterFallbackRule ? itm.domains[0].domainName !== '*' : true)
  .map((itm) => ({
    ...itm,
    /**
     * Select locations that are part of this stack and locations defined on other stacks
     * for easier template consumption.
     */
    _stackLocations: itm.locations.filter((loc) => loc.serviceStackId === serviceStackId),
    _otherLocations: itm.locations.filter((loc) => loc.serviceStackId !== serviceStackId)
  }));
};

export const extractUniqDomainNamesFromDomains = (domains: Domain[] = []) => {
  return uniq(domains.map((itm) => itm.domainName));
};

export const transformLocationToFormLocations = (locations: HttpRoutingLocation[] = []) => {
  return locations.map(({port, path, serviceStackId}) => ({path, portData: box({port, serviceStackId})}));
};

export const getCategorizedHttpRoutings = (
  items: HttpRouting[],
  serviceStackId?: string
): CategorizedHttpRoutings => {

  const baseState = {
    local: [],
    external: [],
    localHasUnsynced: undefined,
    externalHasUnsynced: undefined
  };

  if (!items) { return baseState; }

  const res = items.reduce((obj, itm) => {
    if (!serviceStackId || itm.locations.some((it) => it.serviceStackId === serviceStackId)) {
      const [ _internalLocations, _externalLocations ] = partition(
        itm.locations,
        (l) => l.serviceStackId === serviceStackId
      );
      obj.local.push({
        ...itm,
        _externalLocations,
        _internalLocations,
      });
    } else {
      obj.external.push({
        ...itm,
        _externalLocations: itm.locations
      });
    }
    return obj;
  }, baseState);

  res.localHasUnsynced = res.local.some((itm: HttpRouting) => !itm.isSynced);
  res.externalHasUnsynced = res.external.some((itm: HttpRouting) => !itm.isSynced);

  return res;
};
