import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { FormSectionModule } from '@zerops/zui/form-section';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { ZefFormFieldModule } from '@zerops/zef/forms/modules';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { EffectsModule } from '@ngrx/effects';
import { EnvFormatDialogContainer } from './env-format-dialog.container';
import { EnvFormatDialogEffect } from './env-format-dialog.effect';

@NgModule({
  declarations: [ EnvFormatDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    EffectsModule.forFeature([ EnvFormatDialogEffect ]),
    ZefDialogModule,
    FormSectionModule,
    ReactiveFormsModule,
    MaterialFormStackModule,
    ZefFormFieldModule,
    CodeFieldModule
  ],
  exports: [ EnvFormatDialogContainer ]
})
export class EnvFormatDialogModule {

}
