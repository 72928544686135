import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyRippleModule as MatRippleModule } from '@angular/material/legacy-core';
import { MatBadgeModule } from '@angular/material/badge';
import { ZefFadeAnimationModule } from '@zerops/zef/animations';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { GlobalLoadingModule } from '@zerops/zerops/feature/global-loading';
import { ZuiProcessesPopTriggerModule } from '@zerops/zui/processes-pop-trigger';
import { ZuiNotificationsPopTriggerModule } from '@zerops/zui/notification-pop-trigger';
import { ZuiLogoModule } from '@zerops/zui/logo';
import { ZuiLogoFullModule } from '@zerops/zui/logo-full';
import { KeyChipModule } from '@zerops/zui/key-chip';
import { ProcessItemModule } from '@zerops/zui/process-item';
import { ZuiThemeSelectorModule } from '@zerops/zui/theme-selector';
import { ToggleContentModule } from '@zerops/zui/toggle-content';
import { ZuiThreeDotsModule } from '@zerops/zui/three-dots';
import { UploadingIconModule } from '@zerops/zui/uploading-icon';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { UserInfoAccountsModule } from './modules/user-info-accounts';
import { AppBarContainer } from './app-bar.container';
import { AppBarTranslationsModule } from './app-bar.translations';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MenuPanelModule } from '../menu-panel';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ClientUserSelectPopModule } from '@zerops/zerops/feature/client-user-select-pop';

@NgModule({
  declarations: [ AppBarContainer ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatBadgeModule,
    RouterModule,
    MaterialStackModule,
    ZefFadeAnimationModule,
    ZefAvatarModule,
    SatPopoverModule,
    ZuiLogoModule,
    ZuiLogoFullModule,
    KeyChipModule,
    ProcessItemModule,
    ToggleContentModule,
    GlobalLoadingModule,
    ZuiThreeDotsModule,
    UserInfoAccountsModule,
    ZuiProcessesPopTriggerModule,
    ZuiNotificationsPopTriggerModule,
    AppBarTranslationsModule,
    UploadingIconModule,
    ZuiThemeSelectorModule,
    MatTooltipModule,
    MenuPanelModule,
    ZefPipesModule,
    MatListModule,
    ActionListItemModule,
    ZuiSeparatorComponent,
    ClientUserSelectPopModule,
    ZefScrollbarComponent
  ],
  exports: [ AppBarContainer ]
})
export class AppBarModule {

}
