<!-- GITHUB -->
<ng-container *ngIf="appVersion?.source === 'GITHUB'">
  Created automatically on

  <ng-container *ngIf="appVersion?.githubIntegration.eventType !== 'BRANCH'">
    a new <strong>tag</strong> / <strong>release</strong>
  </ng-container>

  <ng-container *ngIf="appVersion?.githubIntegration.eventType === 'BRANCH'">
    commit to brach <strong>{{ appVersion?.githubIntegration.branchName }}</strong>
  </ng-container>

  <br/>

  on

  <mat-icon class="__icon" svgIcon="github"></mat-icon>
  <a
    target="_blank"
    [href]="'https://github.com/' + appVersion?.githubIntegration.repositoryFullName">
    {{ appVersion?.githubIntegration.repositoryFullName }}
  </a>

  by

  <zef-avatar
    *ngIf="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    class="__avatar"
    [size]="16"
    [image]="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    [borderWidth]="1"
    [backgroundColor]="'#ccc'">
  </zef-avatar>

  <a
    target="_blank"
    [href]="'https://github.com/' + appVersion?.githubIntegration.pusher">
    {{ appVersion?.githubIntegration.pusher }}
  </a>

</ng-container>

<!-- GITLAB -->
<ng-container *ngIf="appVersion?.source === 'GITLAB'">
  Created automatically on

  <ng-container *ngIf="appVersion?.gitlabIntegration.eventType !== 'BRANCH'">
    a new <strong>tag</strong> / <strong>release</strong>
  </ng-container>

  <ng-container *ngIf="appVersion?.gitlabIntegration.eventType === 'BRANCH'">
    commit to brach <strong>{{ appVersion?.gitlabIntegration.branchName }}</strong>
  </ng-container>

  <br/>

  on

  <mat-icon class="__icon" svgIcon="gitlab"></mat-icon>
  <a
    target="_blank"
    [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration.repositoryFullName">
    {{ appVersion?.gitlabIntegration.repositoryFullName }}
  </a>

  by

  <zef-avatar
    *ngIf="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    class="__avatar"
    [size]="16"
    [image]="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    [borderWidth]="1"
    [backgroundColor]="'#ccc'">
  </zef-avatar>

  <a
    target="_blank"
    [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration.pusher">
    {{ appVersion?.gitlabIntegration.pusher }}
  </a>

</ng-container>

<!-- CLI -->
<ng-container *ngIf="appVersion?.source === 'CLI'">
  Created by Zerops CLI `push` command triggered by

  <zef-avatar
    *ngIf="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    class="__avatar"
    [size]="16"
    [image]="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    [borderWidth]="1"
    [backgroundColor]="'#ccc'">
  </zef-avatar>

  <strong>{{ appVersion?.createdByUser?.firstName }}</strong>

</ng-container>

<!-- GUI -->
<ng-container *ngIf="appVersion?.source === 'GUI'">
  Created directly through Zerops app triggered by

  <zef-avatar
    *ngIf="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    class="__avatar"
    [size]="16"
    [image]="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    [borderWidth]="1"
    [backgroundColor]="'#ccc'">
  </zef-avatar>

  <strong>{{ appVersion?.createdByUser?.firstName }}</strong>

</ng-container>

<!-- PUBLIC GIT SOURCE -->
<ng-container *ngIf="parsedPublicGitSource">
  Created from public repository

  <mat-icon *ngIf="parsedPublicGitSource.source === 'GITHUB'" class="__icon" svgIcon="github">

  </mat-icon> <mat-icon *ngIf="parsedPublicGitSource.source === 'GITLAB'" class="__icon" svgIcon="gitlab"></mat-icon>

  <a
    target="_blank"
    [href]="appVersion?.publicGitSource?.gitUrl">
    {{ parsedPublicGitSource.repository }}
  </a>

  as part of project import triggered by

  <zef-avatar
    *ngIf="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    class="__avatar"
    [size]="16"
    [image]="appVersion?.createdByUser?.avatar?.externalAvatarUrl"
    [borderWidth]="1"
    [backgroundColor]="'#ccc'">
  </zef-avatar>

  <strong>{{ appVersion?.createdByUser?.firstName }}</strong>

</ng-container>
