import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'zui-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: [ './progress-spinner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerComponent {
  @Input()
  isLoading: boolean;

  @Input()
  diameter = 18;

  @Input()
  set hideContent(v: string | boolean) {
    this._hideContent = coerceBooleanProperty(v);
  }
  get hideContent(): boolean {
    return this._hideContent;
  }

  private _hideContent = false;
}
