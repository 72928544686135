import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientBillingInfoResponse } from '@zerops/models/client';
import { addMonths, startOfMonth } from 'date-fns/esm';

@Component({
  selector: 'zui-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingInfoComponent {

  @Input()
  billingInfo: ClientBillingInfoResponse;

  @Input()
  set showCurrentOnly(v: string | boolean) {
    this._showCurrentOnly = coerceBooleanProperty(v);
  }
  get showCurrentOnly(): boolean {
    return this._showCurrentOnly;
  }

  nextMonthStartDate: Date;
  private _showCurrentOnly: boolean;

  constructor() {
    this.nextMonthStartDate = startOfMonth(addMonths(new Date(), 1));
  }

}
