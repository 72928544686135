import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';

export const sync = createAction(
  '[@zerops/zerops/core/user-data-base] sync',
  zefActionPayload<string, { global: boolean }>()
);

export const syncFail = createAction(
  '[@zerops/zerops/core/user-data-base] sync/fail',
  zefActionFailPayload
);

export const syncSuccess = createAction(
  '[@zerops/zerops/core/user-data-base] sync/success',
  // TODO: interface
  zefActionSuccessPayload()
);

export const updateSecretEnvs = createAction(
  '[@zerops/zerops/core/user-data-base] update-secret-envs',
  zefActionPayload<{ id: string; content: string; }>()
);

export const updateSecretEnvsFail = createAction(
  '[@zerops/zerops/core/user-data-base] update-secret-envs/fail',
  zefActionFailPayload
);

export const updateSecretEnvsSuccess = createAction(
  '[@zerops/zerops/core/user-data-base] update-secret-envs/success',
  zefActionSuccessPayload()
);

export const setActiveId = createAction(
  '[@zerops/zerops/core/user-data-base] set-active-id',
  (id: string) => ({ id })
);

const all = union({
  sync,
  syncFail,
  syncSuccess,
  setActiveId,
  updateSecretEnvs,
  updateSecretEnvsFail,
  updateSecretEnvsSuccess
});

export type UserDataBaseActionUnion = typeof all;
