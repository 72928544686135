import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AddonExtendedByProjectInfo, SubscriptionExtendedByProjectInfo } from '@zerops/models/billing';

@Component({
  selector: 'zui-addon-button',
  templateUrl: './addon-button.component.html',
  styleUrls: [ './addon-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonButtonComponent {

  @Input()
  addon: AddonExtendedByProjectInfo;

  @Input()
  subscription: SubscriptionExtendedByProjectInfo;

  @Output()
  buttonClick = new EventEmitter<{
    addon: AddonExtendedByProjectInfo;
    subscription: SubscriptionExtendedByProjectInfo;
  }>();

}
