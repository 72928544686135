import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { RunPrepareStepComponent } from './run-prepare-step.component';
import { ZuiErrorsPrinterModule } from '@zerops/zui/errors-printer';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';

@NgModule({
  declarations: [ RunPrepareStepComponent ],
  imports: [
    CommonModule,
    ZuiProcessStepStateModule,
    ZuiDocsTextModule,
    ZuiErrorsPrinterModule,
    ZefScrollbarComponent
  ],
  exports: [ RunPrepareStepComponent ]
})
export class ZuiRunPrepareStepModule {

}
