<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxHeight: '80vh', maxWidth: '760px', width: '80vw' }"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <h3 class="__title">{{ state.title }}</h3>

    <zui-code-field
      class="__code-field"
      [value]="state.data"
      [editorModel]="{
        language: 'yaml',
        fileName: 'zerops-yml'
      }">
    </zui-code-field>

    <div class="__buttons">
      <button
        class="__copy-button  c-button--full"
        mat-button
        color="accent"
        type="button"
        (click)="copy(state.data)">
        Copy to clipboard
      </button>
    </div>

    <button
      (click)="onClose$.next()"
      class="__close-button c-button--full"
      type="button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>
