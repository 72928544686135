import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { UserEntity } from '@zerops/zerops/core/user-base';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { map } from 'rxjs/operators';
import { ProjectStatusEntity } from './project-status-base.entity';

@Injectable()
export class ProjectStatusBaseEffect {

  // project status list / update
  private _setupProjectStatusListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._projectStatusEntity.listSubscribe(
      clientId
    ))
  ));

  private _onProjectStatusListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._projectStatusEntity
    )
  ));

  private _setupClientStatusUpdateStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._projectStatusEntity.updateSubscribe(clientId)
  )));

  private _onProjectStatusUpdateChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._projectStatusEntity)
  ));

  constructor(
    private _userEntity: UserEntity,
    private _projectStatusEntity: ProjectStatusEntity,
    private _actions$: Actions
  ) { }
}
