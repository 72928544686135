<mat-action-list class="__user-menu-actions">
  <button
    *ngFor="let item of data"
    mat-list-item
    type="button"
    class="__client-user-select-button"
    (click)="selected.emit(item)">
    <zui-client-list-item
      [web]="item.client.webUrl"
      [avatar]="item.client.avatar?.smallAvatarUrl"
      [name]="item.client.accountName"
      [maxNameWidth]="140">
    </zui-client-list-item>
  </button>
</mat-action-list>
