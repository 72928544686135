<zef-form-field>
  <mat-form-field color="accent" [floatLabel]="'always'">

    <mat-label>{{ label }}</mat-label>

    <mat-chip-list #chipListRef>

      <mat-chip
        *ngFor="let tag of selectedTags"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(tag)">
        {{ tag }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>

      <input
        #searchInputRef
        [placeholder]="tagSearchPlaceholder"
        class="__tag-input"
        matInput
        autocomplete="off"
        [formControl]="searchFormControl"
        [matChipInputFor]="chipListRef"
        [matAutocomplete]="autocompleteRef"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />

    </mat-chip-list>

    <mat-autocomplete
      #autocompleteRef="matAutocomplete"
      (optionSelected)="_selected($event)">
      <mat-option
        *ngFor="let tag of !disableAutocomplete
          ? (filteredTags$ | async)
          : []"
        [value]="tag">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="hint || hintRef">

      <div *ngIf="hint" [innerHTML]="hint"></div>

      <div class="__hint">
        <ng-template *ngIf="hintRef" [ngTemplateOutlet]="hintRef.templateRef"></ng-template>
      </div>

    </mat-hint>

  </mat-form-field>
</zef-form-field>
