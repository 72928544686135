import { NgModule } from '@angular/core';
import { ZefEntitiesModule, CollectionManagerService } from '@zerops/zef/entities';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefProgressModule } from '@zerops/zef/progress';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { ZefWebsocketModule } from '@zerops/zef/websocket';
import { ZefAuthModule, ZefAuthRefreshTokenModule } from '@zerops/zef/auth';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZefGithubModule } from '@zerops/zef/github';
import { ZefGitlabModule } from '@zerops/zef/gitlab';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZefFadeAnimationModule } from '@zerops/zef/animations';
import { ZefPopErrorModule, ZefSnackErrorModule } from '@zerops/zef/errors';
import { environment } from '@zerops/zerops/env';
import { ZefRecaptchaModule } from '@zerops/zef/recaptcha';
import { ZefNgrxRouterModule } from '@zerops/zef/ngrx-router';
import { ZefStateSwitcherModule } from '@zerops/zef/state-switcher';
import { META_CONFIG, ZefMetaModule } from '@zerops/zef/meta';
import { HttpStatusCodes } from '@zerops/zef/core';
import { ApiEntityKeys } from '@zerops/zerops/app';

@NgModule({
  imports: [
    ZefEntitiesModule.forRoot({
      config: {
        apiPrefix: '/api'
      }
    }),
    ZefRecaptchaModule.forRoot(),
    // !!! keep order of AuthRefresh, Auth and Error imports
    // needed for the correct interceptor chain
    ZefAuthRefreshTokenModule,
    ZefErrorsModule.forRoot({
      // we don't want to show 401 error, as it always
      // triggers refresh, which either successes and
      // "nothing" visible happens for the user or fails
      // in which case single 403 error is added and handled
      // by a dialog error component
      blacklistedErrors: [ HttpStatusCodes.UNAUTHORIZED ],
      uniqueErrors: [ HttpStatusCodes.FORBIDDEN ]
    }),
    ZefProgressModule.forRoot(),
    ZefAuthModule.forRoot({
      loginEndpoint: '/api/auth/login',
      logoutEndpoint: '/api/auth/logout',
      refreshEndpoint: '/api/auth/refresh'
    }),
    ZefGithubModule.forRoot({
      callbackUrl: environment.githubAuthCallbackUrl
    }),
    ZefGitlabModule.forRoot({
      callbackUrl: environment.gitlabAuthCallbackUrl
    }),
    ZefDialogModule.forRoot(),
    ZefFormsModule.forRoot(),
    ZefNgrxRouterModule.forRoot(),
    ZefStateSwitcherModule.forRoot(),
    ZefWebsocketModule.forRoot({
      loginUrl: environment.websocketLoginUrl,
      host: environment.websocketHost,
      forceSecuredEndpoint: environment.forceSecuredWebsocket
    }),
    ZefFadeAnimationModule,
    ZefPopErrorModule,
    ZefSnackErrorModule,
    ZefPipesModule,
    ZefMetaModule.forRoot(),
  ],
  providers: [
    {
      provide: META_CONFIG,
      useValue: {
        baseTitle: environment.isZeropsContabo === 'false' ? 'zerops.io' : 'Zerops',
        separator: '~'
      }
    },
  ]
})
export class ZefLibModule {
  constructor(private _collectionManagerService: CollectionManagerService) {
    this._collectionManagerService.create({
      [ApiEntityKeys.User]: {
        definitions: {
          clientUserList: [ ApiEntityKeys.ClientUser ]
        }
      },
      [ApiEntityKeys.ClientUser]: {
        definitions: {
          client: ApiEntityKeys.Client
        }
      },
      [ApiEntityKeys.ServiceStack]: {},
      [ApiEntityKeys.ClientStatus]: {
        config: {
          idAttribute: 'clientId'
        }
      },
      [ApiEntityKeys.HttpRouting]: {},
      [ApiEntityKeys.PortRouting]: {},
      [ApiEntityKeys.Notification]: {},
      [ApiEntityKeys.Process]: {},
      [ApiEntityKeys.Project]: {},
      [ApiEntityKeys.Client]: {},
      [ApiEntityKeys.UserData]: {},
      [ApiEntityKeys.UserToken]: {},
      [ApiEntityKeys.AppVersion]: {},
      [ApiEntityKeys.Container]: {},
      [ApiEntityKeys.Service]: {},
      [ApiEntityKeys.Payment]: {},
      [ApiEntityKeys.Subscription]: {},
      [ApiEntityKeys.TransactionDebit]: {},
      [ApiEntityKeys.TransactionCredit]: {},
      [ApiEntityKeys.ProjectStatus]: {},
      [ApiEntityKeys.StackEvent]: {}
    });
  }
}
