import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ClientUserBaseEffect } from './client-user-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ClientUserBaseEffect ])
  ]
})
export class ClientUserBaseModule {

}
