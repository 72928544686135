import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { HttpRoutingBaseEffect } from './http-routing-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ HttpRoutingBaseEffect ])
  ]
})
export class HttpRoutingBaseModule {

}
