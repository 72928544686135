import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './process-base.constant';

const en = {
  cancelled: 'Process was cancelled successfully.'
};

export type ProcessBaseTranslations = typeof en;

@NgModule()
export class ProcessBaseTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
