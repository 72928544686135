import { Injectable } from '@angular/core';
import { zefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { ProjectStatusEntity } from '@zerops/zerops/core/project-status-base';
import { firstAvailable } from '@zerops/zef/core';
import { getProjectStatus } from '@zerops/models/project-status';
import { successOf } from '@zerops/zef/entities';
import { Project } from '@zerops/models/project';
import { ProjectEntity } from '@zerops/zerops/core/project-base';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, filter } from 'rxjs/operators';
import { FEATURE_NAME } from './project-cost-limit-dialog.constant';
import { ProjectCostLimitForm } from './project-cost-limit.form';
import { setMode } from './project-cost-limit-dialog.action';
import { ProjectCostLimitDialogModes } from './project-cost-limit-dialog.model';

@Injectable()
export class ProjectCostLimitDialogEffect {

  private _onDialogOpen$ = this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME)
  );

  private _onDialogOpenSetForm$ = createEffect(() => this._onDialogOpen$.pipe(
    filter(({ meta }) => meta?.projectId),
    switchMap(({ meta }) => this._projectStatusEntity.list$().pipe(
      firstAvailable(),
      map((d) => getProjectStatus(d, meta?.projectId))
    )),
    filter((d) => !!d.projectInfoJsonObject?.maxCreditLimit),
    map((d) => this._projectCostLimitForm.setValue({ limit: d.projectInfoJsonObject?.maxCreditLimit }))
  ));

  private _onDialogOpenSetMode$ = createEffect(() => this._onDialogOpen$.pipe(
    filter(({ meta }) => meta?.mode),
    map(({ meta }) => setMode({ mode: meta.mode }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._projectCostLimitForm.reset(),
      this._projectCostLimitForm.setDefaultValues(),
      setMode({ mode: ProjectCostLimitDialogModes.Info })
    ])
  ));

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf<Project>(this._projectEntity.updateOne)
  );

  private _onRemoveLimitSuccessCloseDialog$ = createEffect(() => this._onUpdateSuccess$.pipe(
    filter((action) => action.originalAction?.meta?.tag === 'limit_remove'),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onUpdateSuccessCloseDialog$ = createEffect(() => this._onUpdateSuccess$.pipe(
    filter((action) => action.originalAction?.meta?.tag === 'limit'),
    map(() => setMode({ mode: ProjectCostLimitDialogModes.Info }))
  ));

  constructor(
    private _actions$: Actions,
    private _projectStatusEntity: ProjectStatusEntity,
    private _projectEntity: ProjectEntity,
    private _projectCostLimitForm: ProjectCostLimitForm
  ) { }
}
