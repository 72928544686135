import { ProjectBaseState } from './project-base.model';
import { createReducer, on } from '@ngrx/store';
import { zefResetState } from '@zerops/zef/core';
import {
  loadProjectTagsSuccess,
  listVpnPublicKeysSuccess,
  ProjectBaseActionUnion
} from './project-base.action';

const initialState = new ProjectBaseState();

const actionReducer = createReducer(
  initialState,
  on(
    loadProjectTagsSuccess,
    (s, { data }): ProjectBaseState => ({
      ...s,
      tags: data
    })
  ),
  on(
    listVpnPublicKeysSuccess,
    (s, { data }): ProjectBaseState => ({
      ...s,
      vpnSettingsMap: {
        ...s.vpnSettingsMap,
        [data.id]: data.response
      }
    })
  ),
  on(zefResetState, () => initialState)
);

export function projectBaseReducer(
  state: ProjectBaseState,
  action: ProjectBaseActionUnion
) {
  return actionReducer(state, action);
}
