export class HttpRoutingLocation {
  path: string;
  port: number;
  serviceStackId: string;
  serviceStackInfo?: {
    serviceStackName: string;
    serviceStackTypeName: string;
    serviceStackTypeVersionName: string;
  };
}

export class Domain {
  domainName: string;
  dnsCheckStatus: DnsCheckStatuses;
  sslStatus: SslStatuses;
}

export enum SslStatuses {
  Inactive = 'INACTIVE',
  BeingInstalled = 'BEING_INSTALLED',
  WaitingForDns = 'WAITING_FOR_DNS',
  Active = 'ACTIVE',
  InstallationFailed = 'INSTALLATION_FAILED'
}

export enum DnsCheckStatuses {
  Pending = 'PENDING',
  Checking = 'CHECKING',
  Ok = 'OK',
  Failed = 'FAILED',
  Ignored = 'IGNORED'
}

export class HttpRouting {
  clientId: string;
  created: string;
  lastUpdate: string;
  id: string;
  deleteOnSync: boolean;
  isSynced: boolean;
  isEditable: boolean;
  lastSync: string;
  locations: HttpRoutingLocation[];
  projectId: string;
  sslEnabled: boolean;
  domains: Domain[];
}

export class HttpRoutingPost {
  locations: HttpRoutingLocation[];
  sslEnabled: boolean;
  domains: string[];
  projectId?: string;
}

export class HttpRoutingPut {
  locations: HttpRoutingLocation[];
  sslEnabled: boolean;
  domains: string[];
  projectId?: string;
}

export class HttpRoutingWithLocations extends HttpRouting {
  _stackLocations: HttpRoutingLocation[];
  _otherLocations: HttpRoutingLocation[];
}

export class HttpPortsWithServiceStackInfo {
  serviceStackName: string;
  serviceStackId: string;
  port: number;
}
