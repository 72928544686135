export const VUE_IMPORT_YAML = `project:
  name: recipe-vue
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: static@1.0
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-vue`;
