import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ClientAccountInfoUpdateDto,
  ClientBillingInfoResponse,
  ClientBillingInfoUpdateDto
} from '@zerops/models/client';

@Injectable({ providedIn: 'root' })
export class ClientBaseApi {

  constructor(private _http: HttpClient) { }

  billingInfo$(id: string) {
    return this._http.get<ClientBillingInfoResponse>(
      `/api/client/${id}/billing-info`
    );
  }

  updateBillingInfo$(id: string, data: ClientBillingInfoUpdateDto) {
    return this._http.put<ClientBillingInfoResponse>(
      `/api/client/${id}/billing-info`,
      data
    );
  }

  updateAccountInfo$(id: string, data: ClientAccountInfoUpdateDto) {
    return this._http.put<{ success: boolean; }>(
      `/api/client/${id}/account-info`,
      data
    );
  }

  setAvatar$(id: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this._http.put<{ success: boolean; }>(
      `/api/client/${id}/avatar`,
      formData
    );

  }

  removeAvatar$(id: string) {
    return this._http.delete<{ success: boolean; }>(
      `/api/client/${id}/avatar`
    );
  }

}
