import { Directive, inject } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { Observable, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { HashMap } from './models';
import { ZefReactiveComponentBase } from './reactive-component-base';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ZefReactiveComponent extends ZefReactiveComponentBase {

  private __store = inject(Store);
  private __translationsService = inject(ZefTranslationsService);
  private _contexts = inject(ChildrenOutletContexts);

  constructor() {
    super();
  }

  translate$<T>(key: string, params?: HashMap<any>) {
    return this.__translationsService.selectTranslate$<T>(key, params);
  }

  $dispatchActions(actions: Observable<Action>[]) {
    merge(...actions)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.__store);
  }

  /*
  Uncaught Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError:
  Expression has changed after it was checked.
  Previous value for '@innerRouteAnimation': 'undefined'.
  Current value: 'project-detailadd-service-stack'.
  */

  /*
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['key'];
  }
  */

  prepareRoute(_outlet: RouterOutlet) {
    const key = this._contexts.getContext('primary')?.route?.snapshot?.data?.['key'];
    return key;
  }

}
