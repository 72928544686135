import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZefGitlabState } from './gitlab.model';
import { ZEF_GITLAB_FEATURE_NAME } from './gitlab.constant';

export const slice = createFeatureSelector<ZefGitlabState>(ZEF_GITLAB_FEATURE_NAME);

export const selectZefGitlabRepositories = createSelector(slice, (s) => s.repositories);

export const selectZefGitlabAuthState = createSelector(slice, (s) => !!s.authorized);

export const selectZefGitlabBranchMap = createSelector(slice, (s) => s.branchMap);

