import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { CommonStandardServiceStackFormComponent } from './common-standard-service-stack-form.component';
import { CommonStandardServiceStackFormTranslationsModule } from './common-standard-service-stack-form.translations';
import { HostnameFieldModule } from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';

@NgModule({
  declarations: [ CommonStandardServiceStackFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    CommonStandardServiceStackFormTranslationsModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiAutoscalingFormFieldModule,
    ZuiSeparatorComponent
  ],
  exports: [ CommonStandardServiceStackFormComponent ]
})
export class CommonStandardServiceStackFormModule {

}
