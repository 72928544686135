import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { UserDataAddFormModule } from '@zerops/zui/user-data-add-form';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { UserDataFieldsModule } from '@zerops/zui/user-data-fields';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { ProjectEnvAddDialogContainer } from './project-env-add-dialog.container';
import { ProjectEnvAddDialogEffect } from './project-env-add-dialog.effect';
import { ProjectEnvAddDialogTranslationsModule } from './project-env-add-dialog.translations';

@NgModule({
  declarations: [ ProjectEnvAddDialogContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    UserDataAddFormModule,
    EffectsModule.forFeature([ ProjectEnvAddDialogEffect ]),
    FormSectionModule,
    ReactiveFormsModule,
    ZefProgressErrorModule,
    TranslocoModule,
    UserDataFieldsModule,
    ZefDialogModule,
    ProjectEnvAddDialogTranslationsModule,
    ZefScrollbarComponent
  ],
  exports: [ ProjectEnvAddDialogContainer ]
})
export class ProjectEnvAddDialogModule {

}
