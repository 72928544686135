import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { WsReconnectComponent } from './ws-reconnect.component';
import { WsReconnectTranslationsModule } from './ws-reconnect.translations';

@NgModule({
  declarations: [ WsReconnectComponent ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    WsReconnectTranslationsModule
  ],
  exports: [ WsReconnectComponent ]
})
export class WsReconnectModule {
}
