export const DJANGO_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-django
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: python@3.12
    buildFromGit: https://github.com/zeropsio/recipe-django
    enableSubdomainAccess: true
    envSecrets:
      SECRET_KEY: <@generateRandomBytes(<32>) | toString>
      # Default superuser password can be found in the environment variables section of the service in GUI.
      DJANGO_SUPERUSER_PASSWORD: <@generateRandomString(<12>)>

  - hostname: db
    priority: 10
    type: postgresql@16
    mode: NON_HA

  - hostname: storage
    type: object-storage
    priority: 10
    objectStoragePolicy: public-read
    objectStorageSize: 5

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit
    enableSubdomainAccess: true

  - hostname: adminer
    type: php-apache@8.1
    buildFromGit: https://github.com/zeropsio/recipe-adminer
    enableSubdomainAccess: true`;
