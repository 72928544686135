import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NgrxRouterService } from './ngrx-router.service';

export const zefNgrxRouterGuardActivation = (route: ActivatedRouteSnapshot): boolean => {
  const _ngrxRouterService = inject(NgrxRouterService);

  if (route.data.key && route.params.id) {
    _ngrxRouterService.setId(route.data.key, route.params.id);
  }
  return true;
}

export const zefNgrxRouterGuardDeactivation = (_: any, route: ActivatedRouteSnapshot): boolean => {
  const _ngrxRouterService = inject(NgrxRouterService);

  if (route.data.key && route.params.id) {
    _ngrxRouterService.resetId(route.data.key);
  }

  return true;
}
