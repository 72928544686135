export enum PortScheme {
  tcp = 'tcp',
  udp = 'udp',
  http = 'http',
  https = 'https',
  redis = 'redis',
  rabbitmq = 'rabbitmq',
  mysql = 'mysql',
  postgresql = 'postgresql'
}
