import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ProcessBaseEffect } from './process-base.effect';
import { ProcessBaseTranslationsModule } from './process-base.translations';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ProcessBaseEffect ]),
    ProcessBaseTranslationsModule
  ]
})
export class ProcessBaseModule {

}
