import { Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[zuiToggleContentItem]'
})
export class ToggleContentItemDirective {
  constructor(public templateRef: TemplateRef<ToggleContentItemDirective>) {

  }
}
