import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './web-public-routing-pop.constant';

const en = {
  copyPathToClipboard: 'Click to copy path to clipboard'
};

export type WebPublicRoutingPopTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class WebPublicRoutingPopTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
