import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { RemoteRepositoryConnectorModule } from '@zerops/zerops/feature/remote-repository-connector';
import { PipelineDetailModule } from '@zerops/zerops/feature/pipeline-detail';
import { ReadyToDeployFeature } from './ready-to-deploy.feature';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ProcessItemModule } from '@zerops/zui/process-item';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiZcliExamplesModule } from '@zerops/zui/zcli-examples';
import { ZuiDurationModule } from '@zerops/zui/duration';
import { CodeFieldModule } from "../../../../../../libs/zui/src/code-field/code-field.module";
import { ZuiProcessesCardModule } from '@zerops/zui/processes-card';
import { ZuiWrapModule } from '@zerops/zui/wrap';

@NgModule({
  declarations: [ ReadyToDeployFeature ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialStackModule,
    ZuiSeparatorComponent,
    ZefPipesModule,
    ProcessItemModule,
    ZuiDocsTextModule,
    ZuiZcliExamplesModule,
    RemoteRepositoryConnectorModule,
    PipelineDetailModule,
    ZuiDurationModule,
    CodeFieldModule,
    ZuiProcessesCardModule,
    ZuiWrapModule
],
  exports: [ ReadyToDeployFeature ]
})
export class ReadyToDeployModule {

}
