import { Component, OnDestroy, inject } from '@angular/core';
import { omit } from 'lodash';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ProjectEnvEditForm } from '@zerops/zui/project-env-edit-form';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { formValueOnValid } from '@zerops/zef/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FEATURE_NAME } from './project-env-edit-dialog.constant';
import { ProjectEnvEditDialogTranslations } from './project-env-edit-dialog.translations';
import { updateProjectCustomEnv } from '@zerops/zerops/core/project-base/project-base.action';

@Component({
  selector: 'z-project-env-edit-dialog',
  templateUrl: './project-env-edit-dialog.container.html',
  styleUrls: [ './project-env-edit-dialog.container.scss' ]
})
export class ProjectEnvEditDialogContainer extends ZefReactiveComponent implements OnDestroy {

  /**
   * Dependency injections.
   */
  #store = inject(Store);
  #projectEnvEditForm = inject(ProjectEnvEditForm);

  /**
   * Event streams properties.
   */
  onUpdate$ = new Subject<void>();
  onClose$ = new Subject<void>();

  /**
   * Forms properties.
   */
  formState$ = this.#projectEnvEditForm.state$;

  /**
   * Sync properties.
   */
  updateKey = updateProjectCustomEnv.type;
  dialogKey = FEATURE_NAME;

  /**
   * Async properties.
   */
  dialogState$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME))
  );
  projectEnvEditDialogItemTranslations$ = this.translate$<ProjectEnvEditDialogTranslations>(FEATURE_NAME);

  /**
   * State resolver property.
   */
  state = this.$connect({
    open: this.dialogState$.pipe(map((d) => !!d.state)),
    formState: this.formState$,
    projectEnvEditDialogItemTranslations: this.projectEnvEditDialogItemTranslations$
  });

  /**
   * Action streams methods.
   */
  private _updateAction$ = this.onUpdate$.pipe(
    formValueOnValid(this.#projectEnvEditForm),
    map((value) => {
      const { id } = value;
      return updateProjectCustomEnv({
        id,
        payload: omit(value, 'id')
      });
    })
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this._updateAction$,
      this._closeAction$
    ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
