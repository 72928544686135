import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { UserEntity } from '@zerops/zerops/core/user-base';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { map } from 'rxjs/operators';
import { ClientUserEntity } from './client-user.entity';

@Injectable()
export class ClientUserBaseEffect {

  // client user list / update
  private _setupClientUserListStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientUserEntity.listSubscribe(
      clientId
    ))
  ));

  private _onClientUserListChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._clientUserEntity
    )
  ));

  private _setupClientUserUpdateStream$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientUserEntity.updateSubscribe(clientId)
  )));

  private _onClientUserUpdateChangeStream$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._clientUserEntity)
  ));

  constructor(
    private _userEntity: UserEntity,
    private _clientUserEntity: ClientUserEntity,
    private _actions$: Actions
  ) { }
}
