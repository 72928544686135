import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormControl,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { removeAtIndex, HashMap } from '@zerops/zef/core';
import { ServiceStackPort } from '@zerops/models/service-stack';
import { ZefProgress } from '@zerops/zef/progress';
import uniq from 'lodash-es/uniq';
import { PortScheme } from './ports-form-field.model';

export enum FormFieldModes {
  Write = 'write',
  Emit = 'emit'
}

@Component({
  selector: 'zui-ports-form-field',
  templateUrl: './ports-form-field.component.html',
  styleUrls: ['./ports-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortsFormFieldComponent),
      multi: true
    }
  ]
})
export class PortsFormFieldComponent implements ControlValueAccessor {

  @Input()
  set value(v) {
    this._value = v;

    setTimeout(() => {
      this._propagateChange(this.value);
    });
  }

  get value() {
    return this._value;
  }

  @Input()
  progressesMap: HashMap<ZefProgress> = {};

  @Input()
  editable = true;

  @ViewChild('ngFormRef')
  ngFormRef: FormGroupDirective;

  @ViewChild('portInputRef')
  portInputRef: ElementRef;

  @Input()
  allowFullRemove = true;

  // In case of form based on real data we do not want to change form values right after any action execution. We use FormFieldModes.Emit.
  @Input()
  mode: FormFieldModes = FormFieldModes.Write;

  @Output()
  modifyInternalPorts = new EventEmitter<{ ports: ServiceStackPort[]; portKey?: string }>();

  _httpSupportControl = new UntypedFormControl(true);
  _form = new UntypedFormGroup({
    port: new UntypedFormControl(undefined, [ Validators.required ]),
    protocol: new UntypedFormControl(PortScheme.tcp),
    scheme: new UntypedFormControl(PortScheme.http),
    description: new UntypedFormControl(undefined),
    serviceId: new UntypedFormControl(undefined)
  });
  _formMode: 'edit' | 'add' = undefined;
  _updateIndex: number = undefined;
  _schemes: PortScheme[] = [
    PortScheme.http,
    PortScheme.https,
    PortScheme.mysql,
    PortScheme.postgresql,
    PortScheme.rabbitmq,
    PortScheme.redis,
    PortScheme.tcp,
    PortScheme.udp
  ];
  private _value: any[];

  writeValue(v: any[]) {
    if (v || (!v && this._value)) {
      this.value = v;
    }
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(_: any) {
    return;
  }

  _addPort() {
    if (this._form.valid) {
      const newValues = uniq([
        ...(this.value || []),
        this._form.value
      ]);
      if (this.mode === FormFieldModes.Write) {
        this.writeValue(newValues);
      } else {
        this.modifyInternalPorts.emit({ ports: newValues, portKey: 'add' });
      }
    }
  }

  _deletePort(index: number) {
    const newValues = removeAtIndex(this.value, index);
    if (this.mode === FormFieldModes.Write) {
      this.value = newValues;
    } else {
      this.modifyInternalPorts.emit({ ports: newValues, portKey: `${this.value[index].port}_delete` });
    }
  }

  _updatePort(index: number) {
    this._formMode = 'edit';
    this._updateIndex = index;

    // in case of missing description & serviceId from stack add
    if (!this.value[index]?.description && !this.value[index]?.serviceId) {
      this._form.setValue({
        ...this.value[index],
        description: '',
        serviceId: ''
      });
    } else {
      this._form.setValue(this.value[index]);
    }

    const protocolValue = this._form.get('protocol').value;
    if (protocolValue === 'udp') {
      this._setUpdValues();
    } else {
      this._httpSupportControl.enable();
      if (this._form.get('scheme').value === PortScheme.http) {
        this._httpSupportControl.setValue(true);
      } else {
        this._httpSupportControl.setValue(false);
      }
    }

  }

  _saveUpdatePort() {
    if (this._form.valid) {
      const newValues = this.value.map((itm, i) => {
        if (i === this._updateIndex) {
          return this._form.value;
        } else {
          return itm;
        }
      });
      if (this.mode === FormFieldModes.Write) {
        this.value = newValues;
      } else {
        this.modifyInternalPorts.emit({ ports: newValues, portKey: `${this.value[this._updateIndex].port}_update` });
      }
    }
  }

  _resetInternalForm() {
    this.ngFormRef.resetForm();
    this._form.reset({
      protocol: PortScheme.tcp,
      scheme: PortScheme.http
    });
    this._httpSupportControl.enable();
    this._httpSupportControl.reset(true);
  }

  _resetToInitial() {
    this._resetInternalForm();
    this._updateIndex = undefined;
    this._formMode = undefined;
  }

  // TODO: make optional
  _onPopOpen() {
    this.portInputRef?.nativeElement?.focus();
  }

  _handleProtocolChange() {
    const protocolValue = this._form.get('protocol').value;
    if (protocolValue === 'udp') {
      this._setUpdValues();
    } else {
      this._httpSupportControl.enable();
      this._form.get('scheme').setValue(PortScheme.tcp);
    }
  }

  _handleHttpSupportChange() {
    const protocolValue = this._form.get('protocol').value;
    if (protocolValue === 'tcp') {
      if (this._httpSupportControl.value) {
        this._form.get('scheme').setValue(PortScheme.http);
      } else {
        this._form.get('scheme').setValue(PortScheme.tcp);
      }
    }
  }

  trackBy(index: number) {
    return index;
  }

  private _propagateChange = (_: any) => {
    return;
  };

  private _setUpdValues() {
    this._httpSupportControl.disable();
    this._httpSupportControl.setValue(false);
    this._form.get('scheme').setValue(PortScheme.udp);
  }
}
