import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiMaterialStackModule } from '@zerops/zui/material-stack';
import { TranslocoModule } from '@ngneat/transloco';
import { SnackErrorContainer } from './snack-error.container';

@NgModule({
  declarations: [ SnackErrorContainer ],
  imports: [
    CommonModule,
    ZuiMaterialStackModule,
    TranslocoModule
  ],
  exports: [ SnackErrorContainer ]
})
export class SnackErrorModule {

}
