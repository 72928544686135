import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ProjectBaseEffect } from './project-base.effect';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './project-base.constant';
import { projectBaseReducer } from './project-base.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_NAME, projectBaseReducer),
    EffectsModule.forFeature([ ProjectBaseEffect ])
  ]
})
export class ProjectBaseModule {

}
