import { HashMap } from '@zerops/zef/core';
import { MetricIds } from '../billing';

export type TransactionSource = 'PROMO' | 'COMMON';
export type TransactionStatus = 'CHARGED';
export type TransactionUnitId = 'GB' | 'PCS';

export class TransactionDebit {
  cancelledDate?: string;
  chargedDate?: string;
  clientId: string;
  createdDate: string;
  currencyId: 'USD';
  description: string;
  id: string;
  lastUpdate: string;
  metricId: MetricIds;
  projectId: string;
  quantity: number;
  reservedDate?: string;
  source: TransactionSource;
  stackId: string;
  status: TransactionStatus;
  subscriptionId?: string;
  totalPrice: number;
  transactionObject: {
    cancelReason?: string;
    periodFrom: string;
    periodTill: string;
  }
  unitId: TransactionUnitId;
  unitPrice: number;
  refundedBy: string;
  refundedTransaction: {
    createdDate: string;
    amount: number;
  };
}

export interface TransactionDebitGroupItem {
  ceph_active: number;
  ceph_inactive: number;
  cpu: number;
  disc: number;
  from: string;
  projectId: string;
  projectName?: string;
  ram: number;
  stackId?: string;
  stackName?: string;
  sumTotalPrice: number;
  till: string;
}

export enum TransactionGroupBy {
  Project = 'projectId',
  Service = 'stackId',
  Metric = 'metricId'
}

export enum TransactionTimeGroupBy {
  Hours = '1h',
  Days = '1d',
  Weeks = '1w',
  Months = '1M'
}

export interface TransactionGroupRange {
  key: string;
  label: string;
  hasCurrent: boolean;
  averageScale?: number;
  timeGroupBy: TransactionTimeGroupBy;
  limit?: number;
  range?: {
    from: Date;
    to: Date;
  };
  getRange: () => {
    from: Date;
    to: Date;
  };
}


export interface PeriodCosts {
  client: PeriodCost;
  projects: HashMap<PeriodCost>;
  services: HashMap<PeriodCost>;
}

export interface PeriodCost {
  last24hours: number;
  last7days: number;
  last30days: number;
  last12months: number;
  today: number;
  yesterday: number;
  lastMonth: number;
  thisMonth: number;
  lastYear: number;
  thisYear: number;
  averageLast30Days: number;
  averageLast30DaysPrecision: number;
}

export interface PeriodCostRaw {
  id: string;
  periodCost: PeriodCost;
}
