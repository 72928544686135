import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { HashMap } from '@zerops/zef/core';
import { of, forkJoin, Observable, interval, merge } from 'rxjs';
import {
  catchError,
  map,
  distinctUntilChanged,
  switchMap,
  filter,
  take
} from 'rxjs/operators';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import {
  ServiceStackTypeCategories,
  ServiceStack,
  ServiceStackStatuses
} from '@zerops/models/service-stack';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import { getStorageInfoSuccess, getStorageInfoFail } from './object-storages-usage.action';
import { ObjectStoragesUsageApi } from './object-storages-usage.api';

@Injectable()
export class ObjectStoragesUsageEffect {

  private _INTERVAL = 60000;

  // select only service stacks
  // of object storage type
  // map to their ids
  private _objectStorageServiceStacksIds$ = this._serviceStackEntity
    .list$()
    .pipe(
      filter((d) => !!d),
      map((stacks) => stacks
        .filter(this._filterObjectStorageServiceStacks)
        .map(({ id }) => id)
      )
    );

  private _onGetObjectStorageUsage$ = createEffect(() => merge(
    // runs initially and then only when
    // object storage service stack is
    // added or removed
    this._objectStorageServiceStacksIds$.pipe(distinctUntilChanged((prev, curr) => isEqual(sortBy(prev), sortBy(curr)))),
    // runs every n seconds to get updates
    interval(this._INTERVAL).pipe(switchMap(() => this._objectStorageServiceStacksIds$.pipe(take(1))))
  ).pipe(
    // waits for all inner api calls are resolved
    // and return success actions with map of
    // <service stack id>: storage usage
    switchMap((ids) => forkJoin(this._getApiCallDictionary(ids)).pipe(map((d) => getStorageInfoSuccess(d)),
    // if any of the inner api calls fails, return
    // error action
    catchError((err) => of(getStorageInfoFail(err)))
  ))));

  constructor(
    private _serviceStackEntity: ServiceStackEntity,
    private _api: ObjectStoragesUsageApi
  ) { }

  // checks if service stack is of type object storage and is active
  private _filterObjectStorageServiceStacks({ serviceStackTypeInfo, status }: ServiceStack) {
    return serviceStackTypeInfo?.serviceStackTypeCategory === ServiceStackTypeCategories.ObjectStorage
      && status === ServiceStackStatuses.Active;
  }

  // returns a map with key being object storage
  // service stack id and value api call that
  // gets its's usage as a number
  private _getApiCallDictionary(ids: string[]): HashMap<Observable<{ used: number; rawPolicy: string; }>> {
    return ids.reduce((obj, id) => {
      obj[id] = this._api.usage$(id).pipe(map((d) => ({ used: d.diskGBytesUsed, rawPolicy: d.rawPolicy })));
      return obj;
    }, {});
  }

}
