<div
  class="__step  c-build-state-steps-step"
  [class.is-waiting]="_state === buildProcessStates.Waiting">

  <span class="__step_status  c-build-state-steps-step-status">
    <zui-process-step-state [state]="_state"></zui-process-step-state>
  </span>

  <div *ngIf="!hideText" class="__step_text  c-build-state-steps-step-text" [ngSwitch]="_state">
    <ng-template [ngSwitchCase]="buildProcessStates.Waiting">
      Initialize build container
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Running">
      Initializing build container
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Finished">
      Initialized build container
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Cancelled">
      Cancelled while creating build container, please try again
    </ng-template>
    <ng-template [ngSwitchCase]="buildProcessStates.Failed">
      Failed while creating build container, please try again
    </ng-template>
  </div>

</div>
