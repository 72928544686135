import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { AppState } from '@zerops/zerops/app';
import { ServiceStack } from '@zerops/models/service-stack';
import { FEATURE_NAME } from './new-service-dialog.constant';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';

@Component({
  selector: 'z-new-service-dialog',
  templateUrl: './new-service-dialog.container.html',
  styleUrls: [ './new-service-dialog.container.scss' ]
})
export class NewServiceDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  serviceStackRaw$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((d) => d.meta as ServiceStack)
  );
  serviceStackId$ = this.serviceStackRaw$.pipe(
    filter((d) => !!d),
    map((s) => s?.id),
    distinctUntilChanged()
  );
  serviceStack$ = this.serviceStackId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d),
    takeUntil(this.open$.pipe(filter((d) => !d)))
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    serviceStackId: this.serviceStackId$,
    serviceStack: this.serviceStack$
  });

  // # Action Stream
  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _serviceStackEntity: ServiceStackEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._closeAction$
    ]);
  }

}
