@if (!isZen) {
  <zui-separator
    *ngIf="separator === 'top' || separator === 'both'"
    [spacing]="separatorSpacing"
    [size]="separatorSize">
  </zui-separator>
}

<h3
  class="__title  c-form-section-title"
  *ngIf="titleRef"
  [class.is-zen]="isZen"
  [class.has-no-margin]="separator !== 'none' && !descRef">
  <ng-template [ngTemplateOutlet]="titleRef.templateRef"></ng-template>
</h3>

<div class="__desc  c-form-section-desc" *ngIf="descRef && !isZen" [class.has-no-margin]="separator !== 'none'">
  <ng-template [ngTemplateOutlet]="descRef.templateRef"></ng-template>
</div>

<div class="__docs-button-wrap" *ngIf="!!docButtonText && !!docButtonLink">
  <a class="__docs-button  c-button--smaller" mat-button target="__blank" [href]="docButtonLink">
    <mat-icon>layers</mat-icon> {{ docButtonText }}
  </a>
</div>

<ng-content></ng-content>

@if (!isZen) {
  <zui-separator
    *ngIf="separator === 'bottom' || separator === 'both'"
    [spacing]="separatorSpacing"
    [size]="separatorSize">
  </zui-separator>
}
