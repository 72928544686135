import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { HttpPortsWithServiceStackInfo } from '@zerops/models/http-routing';
import { HttpRoutingFormState } from './http-routing-form.form';
import { HttpRoutingFieldsModes, HttpRoutingFieldsTranslations } from '@zerops/zui/http-routing-fields';

@Component({
  selector: 'zui-http-routing-form',
  templateUrl: './http-routing-form.component.html',
  styleUrls: [ './http-routing-form.component.scss' ],
})
export class HttpRoutingFormComponent extends ZefFormBase<HttpRoutingFormState> {

  @Input()
  mode: HttpRoutingFieldsModes = HttpRoutingFieldsModes.Normal;

  @Input()
  portsWithServiceStackInfo: HttpPortsWithServiceStackInfo[][];

  @Input()
  domainsBlacklist: string[];

  @Input()
  httpRoutingFieldsTranslations: HttpRoutingFieldsTranslations;

  @Output()
  domainSelected = new EventEmitter<string>();

  @Output()
  removeLocation = new EventEmitter<number>();

  @Output()
  domainBlacklisted = new EventEmitter<string>();

}
