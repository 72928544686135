import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FEATURE_NAME } from './project-envs-edit-dialog.constant';
import { ProjectEnvsEditFormValue } from './components';

@Injectable({ providedIn: 'root' })
export class ProjectEnvsEditDialogForm extends ZefForm<ProjectEnvsEditFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      { content: '' },
      {}
    );
  }
}
