import { createAction, union } from '@ngrx/store';
import { ImportExportDialogModes } from './import-export-dialog.model';

export const importExportDialogOpen = createAction(
  '[@zerops/zerops/feature/import-export-dialog] open',
  (data: { entity?: 'project' | 'service-stack';  mode?: ImportExportDialogModes; yaml?: string; projectId?: string; }) => (
    {
      entity: data.entity ? data.entity : 'project',
      mode: data.mode,
      yaml: data.yaml,
      projectId: data.projectId
    }
  )
);

const all = union({ importExportDialogOpen });

export type ImportExportDialogActionUnion = typeof all;
