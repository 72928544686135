<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'start'"
  [horizontalAlign]="'end'"
  hasBackdrop
  (backdropClicked)="popRef.close()">
  <mat-card class="__card __notification-card z-manual-elevation c-soft-elevation">
    <div class="__wrap">
      <zef-scrollbar height="70vh">

        <div class="__header">
          <div class="__half-grid">

            <div>
              <h4 class="__title">
                Notifications
              </h4>
            </div>

          </div>
        </div>

        <div
          class="__group"
          [class.is-last]="last"
          *ngFor="let group of state.groupedNotifications; let last = last; trackBy: trackByDay;">

          <div class="__group-header">

            <div>
              <h5 class="__group-header-title">
                {{
                  group.day === 'recent'
                  ? 'Recent'
                  : group.day | date
                }}
              </h5>
            </div>

          </div>

          <div
            *ngFor="let item of group.items; let lastItem = last; trackBy: trackById;"
            class="__item  __group_item"
            [class.is-last]="lastItem">

            <zui-notification-item
              class="__notification"
              [class.is-last]="lastItem"
              [translations]="state.notificationItemTranslations"
              [data]="item"
              [recent]="group.day === 'recent'"
              [appVersionItemTranslations]="state.appVersionItemTranslations"
              [disableLink]="true">
            </zui-notification-item>

            <zui-separator
              *ngIf="!lastItem"
              class="__separator-inner"
              [position]="'start'"
              [size]="'small'"
              [spacing]="'small'">
            </zui-separator>

          </div>

        </div>

      </zef-scrollbar>
    </div>
  </mat-card>
</sat-popover>