import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientBaseState } from './client-base.model';
import { FEATURE_NAME } from './client-base.constant';

export const selectClientBaseState = createFeatureSelector<ClientBaseState>(FEATURE_NAME);

export const selectBillingInfo = createSelector(
  selectClientBaseState,
  (state) => state.billingInfo
);
