import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { GithubRepoFormModule } from '@zerops/zerops/feature/github-repo-form';
import { GitlabRepoFormModule } from '@zerops/zerops/feature/gitlab-repo-form';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import {
  PhpApacheServiceStackFormComponent
} from './php-apache-service-stack-form.component';
import {
  PhpApacheServiceStackFormTranslationsModule
} from './php-apache-service-stack-form.translations';
import { HostnameFieldModule } from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { CodeFieldModule } from '@zerops/zui/code-field';

@NgModule({
  declarations: [ PhpApacheServiceStackFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    ZuiAutoscalingFormFieldModule,
    PhpApacheServiceStackFormTranslationsModule,
    UserDataFormFieldModule,
    GithubRepoFormModule,
    GitlabRepoFormModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiSeparatorComponent,
    CodeFieldModule
  ],
  exports: [ PhpApacheServiceStackFormComponent ]
})
export class PhpApacheServiceStackFormModule {

}
