import { HashMap } from '@zerops/zef/core';
import {
  subHours,
  subDays,
  startOfHour,
  subMonths,
  subMinutes,
  startOfDay,
  startOfMonth,
  addHours,
  addDays,
  addMonths
} from 'date-fns/esm';
import {
  StatisticsGroupRange,
  StatisticsTimeGroupBy
} from './statistics.model';

const now = startOfHour(new Date());

export const ZEROPS_HOURLY_RESOURCE_PRICES = {
  cpu: 0.00833333333333334,
  vcpu: 0.0008333333333333,
  ram: 0.004166666666667,
  disc: 0.000138889
};

export const STATISTICS_GROUP_RANGE: HashMap<StatisticsGroupRange> = {
  last1h: {
    key: 'last1hour',
    label: 'Last 1 Hour',
    timeGroupBy: StatisticsTimeGroupBy.Minutes,
    limit: 60,
    range: {
      from: subMinutes(new Date(), 60),
      to: new Date()
    }
  },
  last24h: {
    key: 'last24hours',
    label: 'Last 24 Hours',
    timeGroupBy: StatisticsTimeGroupBy.Hours,
    limit: 24,
    range: {
      from: subHours(now, 24),
      to: startOfHour(addHours(now, 1))
    }
  },
  last7d: {
    key: 'last7days',
    timeGroupBy: StatisticsTimeGroupBy.Days,
    label: 'Last 7 Days',
    limit: 7,
    range: {
      from: startOfDay(subDays(now, 6)),
      to: startOfDay(addDays(now, 1))
    }
  },
  last30d: {
    key: 'last30days',
    timeGroupBy: StatisticsTimeGroupBy.Days,
    label: 'Last 30 Days',
    limit: 30,
    range: {
      from: startOfDay(subDays(now, 30)),
      to: startOfDay(addDays(now, 1))
    }
  },
  last12m: {
    key: 'last12month',
    timeGroupBy: StatisticsTimeGroupBy.Months,
    label: 'Last 12 months',
    limit: 12,
    range: {
      from: startOfMonth(startOfDay(subMonths(now, 11))),
      to: startOfMonth(startOfDay(addMonths(now, 1)))
    }
  },
  thisMonth: {
    key: 'thisMonth',
    timeGroupBy: StatisticsTimeGroupBy.Days,
    label: 'This month',
    range: {
      from: startOfMonth(startOfDay(now)),
      to: startOfMonth(addMonths(startOfDay(now), 1))
    }
  },
  lastMonth: {
    key: 'lastMonth',
    timeGroupBy: StatisticsTimeGroupBy.Days,
    label: 'Last month',
    range: {
      from: startOfMonth(startOfDay(subMonths(now, 1))),
      to: startOfDay(startOfMonth(now))
    }
  },
  today: {
    key: 'today',
    timeGroupBy: StatisticsTimeGroupBy.Hours,
    label: 'Today',
    range: {
      from: startOfDay(now),
      to: startOfDay(addDays(now, 1))
    }
  },
  yesterday: {
    key: 'yesterday',
    timeGroupBy: StatisticsTimeGroupBy.Hours,
    label: 'Yesterday',
    range: {
      from: startOfDay(subDays(now, 1)),
      to: startOfDay(now)
    }
  },
};


