import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientBaseEffect } from './client-base.effect';
import { FEATURE_NAME } from './client-base.constant';
import { clientBaseReducer } from './client-base.reducer';
import { ClientBaseTranslationsModule } from './client-base.translations';

@NgModule({
  imports: [
    EffectsModule.forFeature([ ClientBaseEffect ]),
    StoreModule.forFeature(FEATURE_NAME, clientBaseReducer),
    ClientBaseTranslationsModule
  ]
})
export class ClientBaseModule {

}
