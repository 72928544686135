import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogClose, onZefDialogOpen } from '@zerops/zef/dialog';
import { UserDataAddForm } from '@zerops/zui/user-data-add-form';
import { ZefSnackService } from '@zerops/zef/snack';
import { map, filter, switchMap, delay } from 'rxjs/operators';
import { FEATURE_NAME } from './project-env-add-dialog.constant';
import { addProjectCustomEnvSuccess } from '@zerops/zerops/core/project-base/project-base.action';

@Injectable()
export class ProjectEnvAddDialogEffect {

  onDialogOpenFocusInput$ = createEffect(() => this._actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    delay(100),
    map(() => this._userDataAddForm.focus('key'))
  ));

  #onAddSuccess$ = this._actions$.pipe(
    ofType(addProjectCustomEnvSuccess)
  );

  onAddSuccessDialogClose$ = createEffect(() => this.#onAddSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    delay(500),
    switchMap(() => [
      this._userDataAddForm.reset(),
      this._userDataAddForm.setDefaultValues()
    ])
  ));

  onAddSuccessNotification$ = createEffect(() => this.#onAddSuccess$.pipe(
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.addSuccess` }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _userDataAddForm: UserDataAddForm,
    private _snack: ZefSnackService
  ) {}
}
