import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { ClientUserSelectModule } from '@zerops/zerops/feature/client-user-select';
import { ClientListItemModule } from '@zerops/zui/client-list-item';
import { UserCardModule } from '@zerops/zui/user-card';
import { ActionListItemModule } from '@zerops/zui/action-list-item';
import { UserInfoAccountsComponent } from './user-info-accounts.component';

@NgModule({
  declarations: [ UserInfoAccountsComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MaterialStackModule,
    ClientListItemModule,
    ClientUserSelectModule,
    UserCardModule,
    ActionListItemModule
  ],
  exports: [ UserInfoAccountsComponent ]
})
export class UserInfoAccountsModule {

}
