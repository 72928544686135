import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { ZuiProcessStepStateModule } from '@zerops/zui/process-step-state';
import { InitBuildStepComponent } from './init-build-step.component';

@NgModule({
  declarations: [ InitBuildStepComponent ],
  imports: [
    CommonModule,
    ZuiProcessStepStateModule,
    ZuiDocsTextModule
  ],
  exports: [ InitBuildStepComponent ]
})
export class ZuiInitBuildStepModule {

}
