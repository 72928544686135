import { createAction } from '@ngrx/store';

export const addNotification = createAction(
  '[@zerops/zerops/feature/notification] add notification',
  (id: string) => ({ id })
);

export const removeNotification = createAction(
  '[@zerops/zerops/feature/notification] remove notification',
  (id: string, shouldMarkAsRead = false) => ({ id, shouldMarkAsRead })
);
