import { Component, Input } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { FEATURE_NAME } from './contextual-dialog.constant';
import { ContextualDialogTranslations } from './contextual-dialog.translations';
import { select, Store } from '@ngrx/store';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum Contexts {
  // project
  AddServiceStackClick = 'addServiceStack',
  ProjectDetailClick = 'projectDetailClick',
  ProjectSettingsClick = 'projectSettingsClick',
  ProjectRoutingClick = 'projectRoutingClick',
  ProjectCostClick = 'projectCostClick',
  ProjectCostLimitClick = 'projectCostLimitClick',
  ProjectDeleteClick = 'projectDeleteClick',
  ProjectExportClick = 'projectExportClick',
  // service stack
  ServiceStackDetailClick = 'serviceStackDetailClick',
  ServiceStackReloadClick = 'serviceStackReloadClick',
  ServiceStackStartClick = 'serviceStackStartClick',
  ServiceStackStopClick = 'serviceStackStopClick',
  ServiceStackRestartClick = 'serviceStackRestartClick',
  ServiceStackDeleteClick = 'serviceStackDeleteClick',
  ServiceStackConfigurationClick = 'serviceStackConfigurationClick',
  ServiceStackViewLogClick = 'serviceStackViewLogClick',
  ServiceStackRoutingClick = 'serviceStackRoutingClick',
  ServiceStackHowToAccessClick = 'serviceStackHowToAccessClick',
  ServiceStackDeployClick = 'serviceStackDeployClick',
  ServiceStackUserDataClick = 'serviceStackUserDataClick',
  ServiceStackLogClick = 'serviceStackLogClick',
  ServiceStackImportClick = 'serviceStackImportClick',
  ServiceStackExportClick = 'serviceStackExportClick',
  ServiceStackFileBrowserClick = 'serviceStackFileBrowserClick'
}

interface AppState {}

@Component({
  selector: 'zui-contextual-dialog',
  templateUrl: './contextual-dialog.container.html',
  styleUrls: [ './contextual-dialog.container.scss' ]
})
export class ContextualDialogContainer extends ZefReactiveComponent {

  @Input()
  documentationPath: string;

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  contexts = Contexts;

  // -- angular

  // -- async
  translations$ = this.translate$<ContextualDialogTranslations>(FEATURE_NAME);

  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );

  context$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.meta)
  );

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    open: this.open$,
    context: this.context$
  });

  // # Action Streams
  private _closeDialogAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([ this._closeDialogAction$ ]);
  }
}
