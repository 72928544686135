<zef-dialog
  [open]="state.open"
  [options]="{ restoreFocus: false }"
  [autoFocus]="false"
  (closeTrigger)="onCloseDialog$.next()">
  <div class="__content">

    <zef-scrollbar maxHeight="80vh">
      <zui-form-section
        class="__form-section"
        separator="none">

        <span *zuiFormSectionTitle>
          Add new public access through domains
        </span>

        <div *zuiFormSectionDesc>
          Add one or a group of domains and set up routes — which public path will points to which service and its port.
        </div>

        <zui-ip-info-card
          class="__ip-info-card"
          [class.is-first]="state.metaData?.isFirst"
          [color]="state.hasDnsFailedHttpRouting
            ? 'orange'
            : state.allProjectHttpRoutingsWithoutSubdomains?.length && !state.hasDnsFailedHttpRouting
              ? 'green'
              : 'base'"
          [projectName]="state.project?.name"
          [ipV6]="state.project?.publicIpV6"
          [ipV4]="state.project?.publicIpV4"
          [sharedIpV4]="sharedIpV4"
          [ipV4PendingRequest]="state.project?.publicIpV4PendingRequest"
          [requestIpV4ButtonText]="'Activate for $' + state.ipv4Addon?.unitPrice + ' / 30 days'"
          [ipV4Prefix]="'A record (Unique IPv4 address)'"
          [ipV6Prefix]="'AAAA record (IPv6 address)'"
          [requestIpv4Key]="requestIpv4Key"
          [projectStatus]="state.project?.status"
          [ipV4AddonInProcess]="state.hasIpV4AddonActivationProcess"
          (requestIpV4)="onOpenAddonActivationDialog$.next(); onCloseDialog$.next();"
        />

        <zui-separator [size]="'small'" [spacing]="'small'"></zui-separator>

        <zui-http-routing-form
          #formRef
          class="__form"
          [class.has-no-top-padding]="state.metaData?.isFirst"
          (removeLocation)="onRemoveLocation$.next($event)"
          (submit)="onAdd$.next()"
          (domainBlacklisted)="onDomainSelected$.next($event)"
          [domainsBlacklist]="state.domainBlacklist"
          [portsWithServiceStackInfo]="state.projectHttpPorts"
          [state]="state.formState"
          [httpRoutingFieldsTranslations]="{
            hostLabel: state.httpRoutingFieldsTranslations.hostLabel,
            hostPlaceholder: state.httpRoutingFieldsTranslations.hostPlaceholder,
            ssl: state.httpRoutingFieldsTranslations.ssl
          }">
          <button
            type="button"
            (click)="onAddEmptyLocation$.next()"
            mat-button
            class="__new-location-button  c-button--full">
            Add another route
          </button>
        </zui-http-routing-form>

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="addKey">
            <button
              class="c-button--full"
              color="accent"
              mat-raised-button
              type="button"
              (click)="formRef.triggerSubmit()">
              Add domain access
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onCloseDialog$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>

<!-- existing dialog -->
<zef-dialog
  [autoFocus]="false"
  [open]="state.editingExistingDialogOpen">
  <div *ngIf="state.editingExistingDialogData as editingExistingDialogData" class="__content">

    <zef-scrollbar maxHeight="80vh">
      <zui-form-section
        class="__form-section"
        separator="none">

        <span *zuiFormSectionTitle>
          <strong>{{ state.editingExistingDialogData.domains[0].domainName }}</strong>
          {{ state.translations.hasAlreadyDefined }}
        </span>

        <div *zuiFormSectionDesc>
          {{ state.translations.editDesc }}
        </div>

        <div class="__edit-existing-item">
          <zui-http-routing-item
            hideOutOfSync
            [data]="editingExistingDialogData"
            [stackLocations]="editingExistingDialogData._internalLocations"
            [otherLocations]="editingExistingDialogData._externalLocations"
            [translations]="state.httpRoutingItemTranslations"
          />
        </div>

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>

          <button
            class="c-button--full"
            color="accent"
            type="button"
            (click)="onOpenEditDialog$.next()"
            mat-raised-button>
            {{ state.translations.editAndAppendData }}
          </button>

          <p
            class="__edition-selector-button-desc"
            [innerHTML]="state.translations.addNonconflictRoutes">
          </p>

        </div>

        <div>

          <button
            class="c-button--full"
            type="button"
            (click)="onCloseExistingDialog$.next()"
            mat-button>
            Close
          </button>

          <p class="__edition-selector-button-desc" [innerHTML]="state.translations.willRemove"></p>

        </div>

      </div>
    </div>

  </div>
</zef-dialog>