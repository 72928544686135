import { Component, Input } from '@angular/core';
import { WsReconnectTranslations } from './ws-reconnect.translations';
import {
  wsReconnectAnimation,
  reconnectingAnimation,
  reconnectedAnimation,
  wrapAnimation
} from './ws-reconnect.animations';

enum States {
  reconnecting = '1',
  reconnected = '0'
}

@Component({
  selector: 'z-ws-reconnect',
  templateUrl: './ws-reconnect.component.html',
  styleUrls: [ './ws-reconnect.component.scss' ],
  animations: [
    wsReconnectAnimation(),
    reconnectingAnimation(),
    reconnectedAnimation(),
    wrapAnimation()
  ]
})
export class WsReconnectComponent {
  states = States;

  @Input()
  state: States = States.reconnecting;

  @Input()
  translations: WsReconnectTranslations;
}
