export class StatisticItem {
  limit: number;
  used: number;
}

export class CurrentStatistic {
  clientId: string;
  projectId: string;
  serviceStackId: string;
  serviceId?: string;
  containerId?: string;
  cpu: StatisticItem;
  vCpu: StatisticItem;
  diskGBytes: StatisticItem;
  // ramDiskGBytes: StatisticItem;
  ramGBytes: StatisticItem;
}

export class HistoryStatistic {
  from: string;
  till: string;
  projectId: string;
  serviceStackId: string;
  serviceId: string;
  containerId: string | null;
  containerCount: number;
  cpuLimit: number;
  cpuUsed: number;
  vCpuLimit: number;
  vCpuUsed: number;
  ramLimit: number;
  ramUsed: number;
  diskLimit: number;
  diskUsed: number;
}

export enum StatisticsGroupBy {
  ServiceStack = 'serviceStackId',
  Service = 'serviceId',
  Container = 'containerId'
}

export enum StatisticsIds {
  Client = 'clientId',
  Project = 'projectId',
  ServiceStack = 'serviceStackId',
  Service = 'serviceId',
  Container = 'containerId'
}

export enum StatisticsEntities {
  ServiceStack = 'serviceStack',
  Container = 'container'
}

export enum StatisticsTimeGroupBy {
  Minutes = '1m',
  Hours = '1h',
  Days = '1d',
  Weeks = '1w',
  Months = '1M'
}

export interface StatisticsGroupRange {
  key: string;
  label: string;
  timeGroupBy: StatisticsTimeGroupBy;
  limit?: number;
  range?: {
    from: Date;
    to: Date;
  };
}

