import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { zefDialogClose } from '@zerops/zef/dialog';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FEATURE_NAME } from './user-data-env-format-dialog.constant';
import { UserDataEnvFormatDialogTranslations } from './user-data-env-format-dialog.translations';

@Component({
  selector: 'z-user-data-env-format-dialog',
  templateUrl: './user-data-env-format-dialog.container.html',
  styleUrls: [ './user-data-env-format-dialog.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataEnvFormatDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  envControl = new UntypedFormControl(undefined);

  // -- async
  translations$ = this.translate$<UserDataEnvFormatDialogTranslations>(FEATURE_NAME);

  // -- Angular
  @Input()
  open = false;

  @Output()
  add = new EventEmitter<string>();

  @Output()
  dialogClose = new EventEmitter<void>();

  // # State resolver
  state = this.$connect({ translations: this.translations$ });

  // # Action Streams
  private _closeDialogAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor() {
    super();

  // # Dispatcher
  this.$dispatchActions([ this._closeDialogAction$ ]);
  }

  resetTextarea() {
    this.envControl.reset();
  }

}
