<zef-dialog
  [open]="state.open"
  [paneClass]="'c-dialog-no-padding'"
  [options]="{ disableClose: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__dialog-content">

    <div class="__no-logo-wrap">

      <h1 class="__dialog-title">Yes, you absolutely <br> can do that..</h1>

      <p class="__dialog-statement">Just on your own project</p>

      <div class="__recipes-south-wrap  __u-fade-reveal">
        <mat-icon class="__recipes-south-icon">south</mat-icon>
      </div>

      <a
        class="__registration-button  c-button--larger"
        mat-button
        href="https://app.zerops.io/registration"
        target="_blank">
        Sign up with $20 free credit
      </a>

      <div class="__dialog-close-button">
        <button
          (click)="onClose$.next()"
          class="__close-button"
          type="button"
          mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>

    </div>

    <div class="__logo">
      <zui-logo class="__backgroud-logo"></zui-logo>
    </div>

  </div>
  <!-- <div class="__wrap">

    <div class="__header">
      {{ state.translations[state.context]?.header }}
    </div>

    <div class="__content">

      <zui-docs-text #docsTextRef>
        <span [innerHTML]="state.translations[state.context]?.content | safeHtml"></span>
      </zui-docs-text>

      <div *ngIf="state.translations[state.context]?.featureList">
        <ul class="__list">
          <li
            *ngFor="let item of state.translations[state.context]?.featureList"
            class="__list-item">
            {{ item }}
          </li>
        </ul>

      </div>

    </div>

    <div class="__buttons-grid">

      <div class="__buttons-grid-item">
        <a
          href="#"
          mat-button
          class="__register-button"
          href="https://app.zerops.io/registration"
          target="_blank">
          <zui-logo class="__register-logo"></zui-logo>
          Register with <strong class="__register-credit">$20 free credit</strong>
        </a>
      </div>

      <div class="__buttons-grid-item">
        <button
          mat-button
          type="button"
          (click)="onClose$.next()"
          class="c-button--full">
          Close
        </button>
      </div>

    </div>

  </div> -->
</zef-dialog>
