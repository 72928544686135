import { Component } from '@angular/core';
import { ZefFormBase } from '@zerops/zef/forms';
import { UserDataEditFormState } from './user-data-edit-form.form';

@Component({
  selector: 'zui-user-data-edit-form',
  templateUrl: './user-data-edit-form.component.html',
  styleUrls: [ './user-data-edit-form.component.scss' ],
})
export class UserDataEditFormComponent extends ZefFormBase<UserDataEditFormState> {
}
