import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RxVirtualScrollViewport } from '@rx-angular/template/experimental/virtual-scrolling';

@Injectable({ providedIn: 'root' })
export class VirtualScrollRefs {

  #virtualScrollsMap = new Map<string, RxVirtualScrollViewport>();
  #virtualScrollRefEmitted = new BehaviorSubject<string | null>(null);
  virtualScrollRefEmitted$ = this.#virtualScrollRefEmitted.asObservable();

  setVirtualScrollRef(key: string, ref: RxVirtualScrollViewport): void {
    if (!this.#virtualScrollsMap.has(key)) {
      this.#virtualScrollsMap.set(key, ref);
    }
    this.#virtualScrollRefEmitted.next(key);
  }

  getVirtualScrollRef(key: string): RxVirtualScrollViewport | undefined {
    return this.#virtualScrollsMap.get(key);
  }

  deleteVirtualScrollRef(key: string): void {
    if (this.#virtualScrollsMap.has(key)) {
      this.#virtualScrollsMap.delete(key);
    }
  }

}
