import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { StatusIconBaseModule } from '@zerops/zui/status-icon-base';
import { ContainerStatusIconComponent } from './container-status-icon.component';

@NgModule({
  declarations: [ ContainerStatusIconComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    StatusIconBaseModule
  ],
  exports: [ ContainerStatusIconComponent ]
})
export class ContainerStatusIconModule { }
