import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';
import { DynamicPopAnchorDirective } from '@zerops/zui/dynamic-pop-anchor';

@Component({
  selector: 'zui-notification-pop-trigger',
  templateUrl: './notification-pop-trigger.component.html',
  styleUrls: [ './notification-pop-trigger.component.scss' ]
})
export class NotificationPopTriggerComponent  {

  @Input()
  unreadCount = 0;

  @Input()
  triggerRef: SatPopover

  @Output()
  opened = new EventEmitter<DynamicPopAnchorDirective>();

}
