export const PYTHON_IMPORT_YAML = `project:
  name: recipe-python
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: python@3.12
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-python

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
