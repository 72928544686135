import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ServiceStack } from '@zerops/models/service-stack';
import { HashMap } from '@zerops/zef/core';

enum Modes {
  Add = 'add',
  Stack = 'stack'
}

@Component({
  selector: 'zui-shared-storage-connection-field',
  templateUrl: './shared-storage-connection-field.component.html',
  styleUrls: [ './shared-storage-connection-field.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedStorageConnectionFieldComponent),
      multi: true
    }
  ]
})
export class SharedStorageConnectionFieldComponent implements ControlValueAccessor {

  modes = Modes;
  hostname = window.location.hostname;

  @Input()
  items: ServiceStack[];

  @Input()
  projectId: string;

  // TODO: find mode system solution
  // active item border none / border after clicking bug fix. selectedIds is set many times during connection / disconnetion
  @Input()
  mode: Modes = Modes.Add;

  @Output()
  connectionToggle = new EventEmitter<{ stackId: string, type: 'connect' | 'disconnect' }>();

  _idMap: HashMap<any> = {};

  @Input()
  set selectedIds(v: string[]) {
    this._selectedIds = v;
    if (v) {
      this._idMap = this._selectedIds.reduce((m, id) => {
        m[id] = true;
        return m;
      }, {});
    }

    setTimeout(() => {
      this._propagateChange(this._selectedIds);
    });
  }

  get selectedIds() {
    return this._selectedIds;
  }

  private _selectedIds: string[] = [];

  writeValue(v: string[]) {
    if (v !== undefined) {
      this.selectedIds = v;
    }
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(_: any) {
    return;
  }

  private _propagateChange = (_: any) => {
    return;
  };

  _toggleSelected(id: string) {
    if (this.selectedIds.includes(id)) {
      if (this.mode === Modes.Add) {
        this.selectedIds = this.selectedIds.filter((v) => v !== id);
      }
      this.connectionToggle.emit({ stackId: id, type: 'disconnect' });
    } else {
      if (this.mode === Modes.Add) {
        this.selectedIds = [ ...this.selectedIds, id ];
      }
      this.connectionToggle.emit({ stackId: id, type: 'connect' });
    }
  }

  trackBy(index: number) {
    return index;
  }
}
