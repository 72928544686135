import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { SatPopoverModule } from '@zerops/zef/popover';
import { PortRoutingItemModule } from '@zerops/zui/port-routing-item';
import { HttpRoutingItemModule } from '@zerops/zui/http-routing-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { UserDataItemModule } from '@zerops/zerops/common/user-data-item';
import { EffectsModule } from '@ngrx/effects';
import { TranslocoModule } from '@ngneat/transloco';
import { SyncDialogContainer } from './sync-dialog.container';
import { DnsCheckStatusPopModule } from '../dns-check-status-pop';
import { SyncDialogTranslationsModule } from './sync-dialog.translations';
import { SyncDialogEffect } from './sync-dialog.effect';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';

@NgModule({
  declarations: [ SyncDialogContainer ],
  imports: [
    CommonModule,
    RouterModule,
    EffectsModule.forFeature([ SyncDialogEffect ]),
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    SatPopoverModule,
    ZefProgressErrorModule,
    SyncDialogTranslationsModule,
    PortRoutingItemModule,
    HttpRoutingItemModule,
    ZuiSeparatorComponent,
    DnsCheckStatusPopModule,
    TranslocoModule,
    ZefDialogModule,
    UserDataItemModule,
    ZefMessageFormatDirective,
    ZefScrollbarComponent
  ],
  exports: [ SyncDialogContainer ]
})
export class SyncDialogModule { }
