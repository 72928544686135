export const NUXT_NODEJS_IMPORT_YAML = `project:
  name: recipe-nuxt
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: nodejs@20
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-nuxt-nodejs`;
