import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SatPopoverModule } from '@zerops/zef/popover';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { NotificationsCardModule } from '@zerops/zerops/feature/notifications-card';
import { NotificationsPopContainer } from './notifications-pop.container';
import { FEATURE_NAME } from './notifications-pop.constant';
import { notificationsPopReducer } from './notifications-pop.reducer';
import { NotificationsPopTranslationsModule } from './notifications-pop.translations';
import { NotificationsPopEffect } from './notifications-pop.effect';

@NgModule({
  declarations: [ NotificationsPopContainer ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ NotificationsPopEffect ]),
    StoreModule.forFeature(FEATURE_NAME, notificationsPopReducer),
    MatBadgeModule,
    SatPopoverModule,
    MaterialStackModule,
    NotificationsCardModule,
    NotificationsPopTranslationsModule
  ],
  exports: [ NotificationsPopContainer ]
})
export class NotificationsPopModule { }
