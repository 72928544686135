<mat-form-field class="__single-field" color="accent" [floatLabel]="'always'">

  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    type="text"
    autocomplete="off"
    class="c-hostname-field-input"
    [placeholder]="placeholder"
    [ngrxFormControlState]="control"
    [ngrxEnableFocusTracking]="true"
  />

  <mat-hint *ngIf="control?.errors.maxLength || control?.errors.pattern" class="__hint-text">
    {{
      control.errors.maxLength
      ? errorMessages.hintMaxLength
      : errorMessages.hintPattern
    }}
  </mat-hint>

</mat-form-field>
