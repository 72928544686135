<zui-basic-info-header
  *ngIf="name"
  class="__header"
  [name]="name"
  [searchMatches]="searchMatches"
  [status]="status"
  [size]="nameSize"
  [generalTranslations]="generalTranslations"
  mode="project">
</zui-basic-info-header>

<!-- TODO: move to tooltip -->
<!-- <p class="__desc" *ngIf="description">
  {{ description }}
</p> -->

<div class="__tags" *ngIf="tags?.length">

  <ng-container *ngIf="!disableLinks; else linkDisabledRef">
    <zui-tag
      *ngFor="let tag of tags; trackBy: trackByIndex;"
      [routerLink]="!disableLinks
        ? (activeTagFilter !== tag
          ? [ '/', 'dashboard', 'projects', routerParams | routerParams: 'tag' : tag ]
          : [ '/', 'dashboard', 'projects', routerParams | routerParams: 'tag' ])
        : undefined"
      class="__tags-tag"
      [class.is-active]="activeTagFilter === tag">
      {{ tag }}
    </zui-tag>
  </ng-container>

  <ng-template #linkDisabledRef>
    <zui-tag
      *ngFor="let tag of tags; trackBy: trackByIndex;"
      class="__tags-tag"
      (click)="activeTagFilter = tag"
      [class.is-active]="activeTagFilter === tag">
      {{ tag }}
    </zui-tag>
  </ng-template>

</div>
