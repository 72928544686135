import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { Payment } from '@zerops/models/billing';

@Injectable({ providedIn: 'root' })
export class PaymentEntity extends EntityService<Payment> {

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService
  ) {
    super(ApiEntityKeys.Payment, store, collectionManager, websocketService);
  }

  list$(
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: Payment) => any)> = [ 'created' ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ 'desc' ]
  ) {
    return super.list$(tag, orderSelector, orderDir);
  }

}
