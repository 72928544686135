import { NgModule } from '@angular/core';
import { CommonUserServiceStackFormComponent } from './common-user-service-stack-form.component';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';
import { ZuiAutoscalingFormFieldModule } from '@zerops/zui/autoscaling-form-field';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { PortsFormFieldModule } from '@zerops/zui/ports-form-field';
import { CommonUserServiceStackFormTranslationsModule } from './common-user-service-stack-form.translations';
import { CommonModule } from '@angular/common';
import { HostnameFieldModule } from '../../fields/hostname-field';
import { FormSectionModule } from '@zerops/zui/form-section';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { ZefFormsModule } from '@zerops/zef/forms';

@NgModule({
  declarations: [ CommonUserServiceStackFormComponent ],
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    HostnameFieldModule,
    CommonModule,
    CommonUserServiceStackFormTranslationsModule,
    PortsFormFieldModule,
    UserDataFormFieldModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiAutoscalingFormFieldModule,
    ZefMessageFormatDirective,
    ZuiSeparatorComponent,
    CodeFieldModule
  ],
  exports: [ CommonUserServiceStackFormComponent ]
})
export class CommonUserServiceStackFormModule {

}
