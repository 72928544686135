<ng-template #draftGithubReleaseTpl>
  <a
    class="[ c-button  c-button--small-link  c-button--full ]  __active-action_action"
    mat-stroked-button
    [href]="'https://github.com/' + state.serviceStack?.githubIntegration?.repositoryFullName + '/releases/new'"
    target="_blank">
    Draft a new release on GitHub <mat-icon class="__active-action_action-icon">open_in_new</mat-icon>
  </a>
</ng-template>

<ng-template #draftGitlabReleaseTpl>
  <a
    class="[ c-button  c-button--small-link  c-button--full ]  __active-action_action"
    mat-stroked-button
    [href]="'https://gitlab.com/' + state.serviceStack?.gitlabIntegration?.repositoryFullName + '-/tags/new'"
    target="_blank">
    Create a new tag on GitLab <mat-icon class="__active-action_action-icon">open_in_new</mat-icon>
  </a>
</ng-template>

<sat-popover
  #popRef
  [autoFocus]="false"
  [restoreFocus]="false"
  [verticalAlign]="'center'"
  [horizontalAlign]="'before'"
  scrollStrategy="close"
  hasBackdrop
  (backdropClicked)="popRef.close()">
  <mat-card class="__active-more-card  z-manual-elevation  c-soft-elevation">
    <mat-action-list>
      <a
        mat-list-item
        (click)="onRemoveExternalRepositoryIntegration$.next(); popRef.close();"
        class="__link">
        <zui-action-list-item
          warn
          [icon]="'link_off'"
          [label]="'Remove connection?'">
        </zui-action-list-item>
      </a>
    </mat-action-list>
  </mat-card>
</sat-popover>


<!-- non active github -->
<div
  class="__setup-wrap  is-first"
  *ngIf="!state.serviceStack?.githubIntegration?.isActive && !state.serviceStack?.gitlabIntegration?.isActive && !state.gitlabFormState.value.repositoryFullName">
  <z-github-repo-form
    [formState]="state.githubFormState"
    [triggerBuildLabel]="'Trigger once after the activation?'">
    <div class="__connect-button-wrap">
      <zef-progress-error
        full
        [key]="integrateExternalRepositoryKey">
        <button
          (click)="onIntegrateExternalRepository$.next('github')"
          mat-flat-button
          class="[ c-button  c-button--larger ]  [ __activate-button  __activate-button--github ]"
          type="button">
          Activate pipeline trigger
        </button>
      </zef-progress-error>
    </div>
  </z-github-repo-form>
</div>

<!-- non active gitlab -->
<div
  class="__setup-wrap  is-last"
  *ngIf="!state.serviceStack?.gitlabIntegration?.isActive && !state.serviceStack?.githubIntegration?.isActive && !state.githubFormState.value.repositoryFullName">
  <z-gitlab-repo-form
    class="__gitlab-form"
    [formState]="state.gitlabFormState"
    [triggerBuildLabel]="'Trigger once after the activation?'">
    <div class="__connect-button-wrap">
      <zef-progress-error
        full
        [key]="integrateExternalRepositoryKey">
        <button
          (click)="onIntegrateExternalRepository$.next('gitlab')"
          mat-flat-button
          class="[ c-button  c-button--larger ]  [ __activate-button  __activate-button--gitlab ]"
          type="button">
          Activate pipeline Trigger
        </button>
      </zef-progress-error>
    </div>
  </z-gitlab-repo-form>
</div>

<!-- active github -->
<mat-card
  class="__active-wrap"
  *ngIf="!!state.serviceStack?.githubIntegration?.isActive">

  <h2 class="__active-status">

    <mat-icon class="__active-status_icon" svgIcon="github"></mat-icon>
    <mat-icon class="__active-status_status">check_circle</mat-icon>

    GitHub connected

    <zui-more-button
      class="__active-status_more"
      [popRef]="popRef"
      [isLoading]="state.removeExternalLoadingState">
    </zui-more-button>

  </h2>

  <div
    *ngIf="!state.serviceStack?.activeAppVersion && !state.hasBuildProcess"
    class="__ready-to-deploy-info">

    <h4 class="__ready-to-deploy-info_title">Waiting for the first pipeline run <zui-three-dots></zui-three-dots></h4>

    <p>

      Make sure your repository includes, or commit <strong>zerops.yml</strong> and then

      <!-- release -->
      <ng-container *ngIf="state.serviceStack?.githubIntegration?.eventType === 'TAG'">
        create a new release on GitHub
      </ng-container>

      <!-- push to branch -->
      <ng-container *ngIf="state.serviceStack?.githubIntegration?.eventType === 'BRANCH'">
        push to branch <strong>{{ state.serviceStack?.githubIntegration?.repositoryFullName }}&#64;{{ state.serviceStack?.githubIntegration?.branchName }}</strong>.
      </ng-container>

      to start the first build, prepare, deploy and run pipeline and start your application

    </p>

    <div
      class="__active-action  __active-action--no-shift  __active-action--bottom-margin"
      *ngIf="
        state.serviceStack?.githubIntegration?.eventType === 'TAG'
        && state.serviceStack?.status === serviceStackStates.ReadyToDeploy
      ">
      <ng-template [ngTemplateOutlet]="draftGithubReleaseTpl"></ng-template>
    </div>

  </div>

  <div class="__active-content">

    <span class="__active-label">Repository</span>

    <h3 class="__active-title  __active-title--repo">
      <a
        class="__active-title_link"
        [href]="'https://github.com/' + state.serviceStack?.githubIntegration?.repositoryFullName" target="_blank">
        <span class="__active-title_text">https://github.com/{{ state.serviceStack?.githubIntegration?.repositoryFullName | middleEllipsis: 30 }}</span>
      </a>
    </h3>

    <span class="__active-label">Trigger on</span>

    <h3 class="[ __active-title __active-title--type ]  is-last">

      <!-- release -->
      <ng-container *ngIf="state.serviceStack?.githubIntegration?.eventType === 'TAG'">

        <span class="__active-title_text">New tag / release</span>

        <ng-template [ngIf]="state.serviceStack?.githubIntegration?.tagRegex">
          <span class="__active-title_text">&nbsp;matching expression</span><br>
          <span class="__expression-slash">/</span>
          <span class="__expression-content">{{ state.serviceStack?.githubIntegration?.tagRegex }}</span>
          <span class="__expression-slash">/</span>
        </ng-template>

      </ng-container>

      <!-- push to branch -->
      <ng-container *ngIf="state.serviceStack?.githubIntegration?.eventType === 'BRANCH'">
        <span class="__active-title_text">Push to branch <strong>{{ state.serviceStack?.githubIntegration?.branchName }}</strong></span>
      </ng-container>

      <div
        class="__active-action"
        *ngIf="
          state.serviceStack?.githubIntegration?.eventType === 'TAG'
          && state.serviceStack?.status !== serviceStackStates.ReadyToDeploy
        ">
        <ng-template [ngTemplateOutlet]="draftGithubReleaseTpl"></ng-template>
      </div>

    </h3>

  </div>

</mat-card>

<!-- active gitlab  -->
<mat-card
  class="__active-wrap"
  *ngIf="!!state.serviceStack?.gitlabIntegration?.isActive">
  <h2 class="__active-status">

    <mat-icon class="__active-status_icon" svgIcon="gitlab"></mat-icon>
    <mat-icon class="__active-status_status">check_circle</mat-icon>

    GitLab connected

    <zui-more-button
      class="__active-status_more"
      [popRef]="popRef"
      [isLoading]="state.removeExternalLoadingState">
    </zui-more-button>

  </h2>

  <div
    *ngIf="state.serviceStack?.status === serviceStackStates.ReadyToDeploy"
    class="__ready-to-deploy-info">

    <h4 class="__ready-to-deploy-info_title">Waiting for the first pipeline run <zui-three-dots></zui-three-dots></h4>

    <p>

      Make sure your repository includes, or commit <strong>zerops.yml</strong> and then

      <!-- release -->
      <ng-container *ngIf="state.serviceStack?.gitlabIntegration?.eventType === 'TAG'">
        create a new release on GitLab
      </ng-container>

      <!-- push to branch -->
      <ng-container *ngIf="state.serviceStack?.gitlabIntegration?.eventType === 'BRANCH'">
        push to branch <strong>{{ state.serviceStack?.gitlabIntegration?.repositoryFullName }}&#64;{{ state.serviceStack?.gitlabIntegration?.branchName }}</strong>
      </ng-container>

      to start the first build, prepare, deploy and run pipeline and start your application

    </p>

    <div
      class="__active-action  __active-action--no-shift  __active-action--bottom-margin"
      *ngIf="
        state.serviceStack?.gitlabIntegration?.eventType === 'TAG'
        && state.serviceStack?.status === serviceStackStates.ReadyToDeploy
      ">
      <ng-template [ngTemplateOutlet]="draftGitlabReleaseTpl"></ng-template>
    </div>

  </div>

  <div class="__active-content">

    <span class="__active-label">Repository</span>

    <h3 class="__active-title  __active-title--repo">
      <a
        class="__active-title_link"
        [href]="'https://gitlab.com/' + state.serviceStack?.gitlabIntegration?.repositoryFullName" target="_blank">
        <span class="__active-title_text">https://gitlab.com/{{ state.serviceStack?.gitlabIntegration?.repositoryFullName | middleEllipsis: 20 }}</span>
      </a>
    </h3>

    <span class="__active-label">Trigger on</span>

    <h3 class="[ __active-title __active-title--type ]  is-last">

      <!-- release -->
      <ng-container *ngIf="state.serviceStack?.gitlabIntegration?.eventType === 'TAG'">

        <span class="__active-title_text">New Tag / Release</span>

        <ng-template [ngIf]="state.serviceStack?.gitlabIntegration?.tagRegex">
          <span class="__active-title_text">matching expression</span>
          <span class="__expression-slash">/</span>
          <span class="__expression-content">{{ state.serviceStack?.gitlabIntegration?.tagRegex }}</span>
          <span class="__expression-slash">/</span>
        </ng-template>

      </ng-container>

      <!-- push to branch -->
      <ng-container *ngIf="state.serviceStack?.gitlabIntegration?.eventType === 'BRANCH'">
        <span class="__active-title_text">Push to branch <strong>{{ state.serviceStack?.gitlabIntegration?.branchName }}</strong></span>
      </ng-container>

      <div
        class="__active-action"
        *ngIf="
          state.serviceStack?.gitlabIntegration?.eventType === 'TAG'
          && state.serviceStack?.status !== serviceStackStates.ReadyToDeploy
        ">
        <ng-template [ngTemplateOutlet]="draftGitlabReleaseTpl"></ng-template>
      </div>

    </h3>

  </div>

</mat-card>
