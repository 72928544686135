import { Injectable } from '@angular/core';
import { onWebsocketMessageDispatchAddRemoveEntities, onShowMore } from '@zerops/zef/entities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { map, withLatestFrom, filter } from 'rxjs/operators';
import { NotificationEntity } from '@zerops/zerops/core/notification-base';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { AppState } from '@zerops/zerops/app';
import { FEATURE_NAME } from './notifications-pop.constant';
import { notificationsPopSetSettings, notificationsPopSettingsReset } from './notifications-pop.action';
import { NotificationsPopOpenPayload } from './notifications-pop.model';
import { selectNotificationPopState } from './notifications-pop.selector';

@Injectable()
export class NotificationsPopEffect {

  private _defaultNotificationsLimit = 50;

  private _setupListStream$ = createEffect(() => merge(
    this._actions$.pipe(
      ofType(notificationsPopSetSettings),
      map(() => ({ limit: this._defaultNotificationsLimit, showMoreLoading: false }))
    ),
    this._actions$.pipe(
      onShowMore(
        this._notificationEntity,
        FEATURE_NAME,
        this._defaultNotificationsLimit
      )
    )
  ).pipe(
    withLatestFrom(
      this._userEntity.activeClientUser$,
      this._store.pipe(select(selectNotificationPopState))
    ),
    filter(([ _, activeUser ]) => !!activeUser),
    map(([
      { limit, showMoreLoading },
      { clientId, userId },
      settings
    ]) => this._notificationEntity.listSubscribe(
      clientId,
      FEATURE_NAME,
      {
        id: showMoreLoading ? FEATURE_NAME : undefined,
        search: this._getSearchSetting(settings, userId),
        sort: [
          {
            name: 'created',
            direction: 'desc'
          }
        ],
        limit
      }
    ))
  ));

  private _setupListAddRemove$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._notificationEntity,
      FEATURE_NAME
    )
  ));

  private _onSetingsResetRemoveIdsFromChache$ = createEffect(() => this._actions$.pipe(
    ofType(notificationsPopSettingsReset),
    map(() => this._notificationEntity.listReset(FEATURE_NAME))
  ));

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _notificationEntity: NotificationEntity,
    private _userEntity: UserEntity
  ) { }

  private _getSearchSetting(
    setting: NotificationsPopOpenPayload,
    userId: string
  ) {

    const baseUserIdSearch = {
      name: 'userId',
      operator: 'eq',
      value: userId
    };

    switch (setting.level) {
      case 'client':
        return [ baseUserIdSearch ];

      case 'project':
        return [
          baseUserIdSearch,
          {
            name: 'project.id',
            operator: 'eq',
            value: setting.id
          }
        ];

      case 'service':
        return [
          baseUserIdSearch,
          {
            name: 'serviceStacks.id',
            operator: 'eq',
            value: setting.id
          }
        ];

      default:
        console.warn('Notifications pop missing `level` attribute.');
        return [];

    }

  }
}
