import { Component, Input } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { ZefSnackInput } from '@zerops/zef/snack';

@Component({
  selector: 'zui-snack-success',
  templateUrl: './snack-success.container.html',
  styleUrls: [ './snack-success.container.scss' ]
})
export class SnackSuccessContainer {
  @Input()
  data: ZefSnackInput;

  @Input()
  instance: MatSnackBarRef<any>;
}
