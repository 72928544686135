import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZuiAutofocusDirective } from './autofocus.directive';
import { ZuiSeparatorComponent } from '../separator';

@NgModule({
  declarations: [ ZuiAutofocusDirective ],
  imports: [
    CommonModule,
    ZuiSeparatorComponent
  ],
  exports: [ ZuiAutofocusDirective ]
})
export class ZuiAutofocusModule {

}
