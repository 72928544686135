import { NgModule } from '@angular/core';
import { LogoFullComponent } from './logo-full.component';

@NgModule({
  declarations: [ LogoFullComponent ],
  exports: [ LogoFullComponent ]
})
export class ZuiLogoFullModule {

}
