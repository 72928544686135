import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EntityService,
  CollectionManagerService
} from '@zerops/zef/entities';
import { AppState, ApiEntityKeys } from '@zerops/zerops/app';
import { ZefWebsocketService } from '@zerops/zef/websocket';
import { ServiceEntity } from '@zerops/zerops/core/service-base';
import { Sort } from '@zerops/zef/sort';
import { combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Container, ContainerWithService } from './container-base.model';

@Injectable({ providedIn: 'root' })
export class ContainerEntity extends EntityService<Container> {

  defaultSort: Sort = {
    key: 'number',
    direction: 'asc'
  };

  listWithService$ = (
    tag?: string | { name: string; id: string; },
    orderSelector: Array<string | ((i: Container) => number)> = [ this.defaultSort.key ],
    orderDir: Array<boolean | 'asc' | 'desc'> = [ this.defaultSort.direction ]
  ) => combineLatest([
    this.list$(
      tag,
      orderSelector?.length && orderSelector[0] !== undefined
        ? orderSelector
        : [ this.defaultSort.key ],
      orderDir?.length && orderDir[0] !== undefined
        ? orderDir
        : [ this.defaultSort.direction ]
    ).pipe(map((v) => v || [])),
    this._serviceEntity
      .list$()
      .pipe(
        filter((d) => !!d),
        map((services) => services.reduce((obj, itm) => {
          obj[itm.id] = itm;
          return obj;
        }, {}))
      )
  ]).pipe(
    map(([ containers, serviceMap ]) => containers.map((d) => ({
      ...d,
      service: serviceMap[d.serviceId]
    }) as ContainerWithService))
  )

  constructor(
    public store: Store<AppState>,
    public collectionManager: CollectionManagerService,
    public websocketService: ZefWebsocketService,
    private _serviceEntity: ServiceEntity
  ) {
    super(ApiEntityKeys.Container, store, collectionManager, websocketService);
  }
}
