import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AppVersionBaseApi {
  constructor(private _http: HttpClient) { }

  upload$(url: string, file: File) {
    return this._http.put(
      url,
      file,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  deploy$(id: string, temporaryShutdown = true) {
    return this._http.put(
      `/api/app-version/${id}/deploy`,
      {
        temporaryShutdown
      }
    );
  }

  download$(id: string) {
    return this._http.get<{ url: string; }>(`/api/app-version/${id}/app-code`);
  }

}
