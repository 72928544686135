import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { NotificationItemModule } from '@zerops/zui/notification-item';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { NotificationsCardContainer } from './notifications-card.container';
import { NotificationsCardTranslationsModule } from './notifications-card.translations';
import { ZefMessageFormatDirective } from '@zerops/zef/message-format';

@NgModule({
  declarations: [ NotificationsCardContainer ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MaterialStackModule,
    NotificationsCardTranslationsModule,
    NotificationItemModule,
    ZefProgressErrorModule,
    ProgressSpinnerModule,
    ZuiSeparatorComponent,
    ZefMessageFormatDirective,
    ZefScrollbarComponent
  ],
  exports: [ NotificationsCardContainer ]
})
export class NotificationsCardModule {

}
