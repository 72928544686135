import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { User } from '@zerops/models/user';
import { createAction, union } from '@ngrx/store';

export const setUserId = createAction(
  '[@zerops/zerops/user-base] set-user-id',
  (id: string) => ({ id })
);

export const clearUserData = createAction(
  '[@zerops/zerops/user-base] clear-user-data'
);

export const loadUser = createAction(
  '[@zerops/zerops/user-base] load-user',
  (obj?: any) => zefActionPayload<any>(
    obj ? { ...obj } : undefined,
    { type: 'dialog' }
  )
);

export const loadUserFail = createAction(
  '[@zerops/zerops/user-base] load-user/fail',
  zefActionFailPayload
);

export const loadUserSuccess = createAction(
  '[@zerops/zerops/user-base] load-user/success',
  (data: any, originalAction: any, meta: any) => zefActionSuccessPayload<User>()(
    data,
    originalAction,
    meta
  )
);

export const storeUserData = createAction(
  '[@zerops/zerops/user-base] store-user-data',
  (
    userId: string,
    clientUserId?: string,
    refresh = false
  ) => ({ clientUserId, userId, refresh })
);

export const storeUserDataSuccess = createAction(
  '[@zerops/zerops/user-base] store-user-data/success',
  (
    userId: string,
    clientUserId: string,
    refresh = false
  ) => ({ clientUserId, userId, refresh })
);

export const loadStoredUserData = createAction(
  '[@zerops/zerops/user-base] load-stored-user-data',
);

export const loadStoredUserDataSuccess = createAction(
  '[@zerops/zerops/user-base] load-stored-user-data/success',
  (userId: string, clientUserId: string) => ({ clientUserId, userId })
);

export const setUserAvatar = createAction(
  '[@zerops/zerops/user-base] set-avatar',
  (file: File) => zefActionPayload<File>(file)
);

export const setUserAvatarFail = createAction(
  '[@zerops/zerops/user-base] set-avatar/fail',
  zefActionFailPayload
);

export const setUserAvatarSuccess = createAction(
  '[@zerops/zerops/user-base] set-avatar/success',
  zefActionSuccessPayload()
);

export const removeUserAvatar = createAction(
  '[@zerops/zerops/user-base] remove-avatar',
  zefActionPayload()
);

export const removeUserAvatarFail = createAction(
  '[@zerops/zerops/user-base] remove-avatar/fail',
  zefActionFailPayload
);

export const removeUserAvatarSuccess = createAction(
  '[@zerops/zerops/user-base] remove-avatar/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

const all = union({
  setUserId,
  clearUser: clearUserData,
  loadUser,
  loadUserFail,
  loadUserSuccess,
  storeUserData,
  loadStoredUserData,
  loadStoredUserDataSuccess
});
export type UserBaseActionUnion = typeof all;
