@if (!!triggerRef) {
  <button
    #dynamicPopRef="dynamicPopAnchor"
    [zuiDynamicPopAnchor]="triggerRef"
    (click)="dynamicPopRef.open(); opened.emit()"
    mat-button
    type="button"
    class="__button">
    <ng-template [ngTemplateOutlet]="contentRef" />
  </button>
} @else {
  <div class="__button">
    <ng-template [ngTemplateOutlet]="contentRef" />
  </div>
}

<ng-template #contentRef>

  <!-- both running and pending processes -->
  <ng-container *ngIf="runningCount && pendingCount">

    <div class="__icon-wrap">

      <div
        class="__icon-wrap_wrap  __icon-wrap_wrap--running">
        <span class="__icon-wrap_num">{{ runningCount }}</span>
      </div>

      <span class="__icon-wrap_ripple"></span>

    </div>

    <span class="__text" *ngIf="!simple">running and</span>

    <div class="__icon-wrap  __icon-wrap--second">

      <div
        class="__icon-wrap_wrap  __icon-wrap_wrap--queued">
        <span class="__icon-wrap_num">{{ pendingCount }}</span>
      </div>

    </div>

    <span class="__text" *ngIf="!simple">queued {{ pendingCount > 1 ? 'processes' : 'process' }}</span>

  </ng-container>

  <!-- only running -->
  <ng-container *ngIf="runningCount && !pendingCount">

    <div class="__icon-wrap">

      <div
        class="__icon-wrap_wrap  __icon-wrap_wrap--running">
        <span class="__icon-wrap_num">{{ runningCount }}</span>
      </div>

      <span class="__icon-wrap_ripple"></span>

    </div>

    <span class="__text" *ngIf="!simple">running {{ runningCount > 1 ? 'processes' : 'process' }}</span>

  </ng-container>

  <!-- only pending -->
  <ng-container *ngIf="pendingCount && !runningCount">
    <div class="__icon-wrap">

      <div
        class="__icon-wrap_wrap  __icon-wrap_wrap--queued">
        <span class="__icon-wrap_num">{{ pendingCount }}</span>
      </div>

    </div>

    <span class="__text" *ngIf="!simple">queued {{ pendingCount > 1 ? 'processes' : 'process' }}</span>

  </ng-container>

</ng-template>
