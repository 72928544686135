<zef-dialog
  [open]="state.open"
  [options]="{ restoreFocus: false }"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <zef-scrollbar maxHeight="80vh">
      <zui-form-section
        class="__form-section"
        separator="none"
        [isZen]="state.isZen">

        <span *zuiFormSectionTitle>
          Project Domain Access Modification
        </span>

        <div *zuiFormSectionDesc>
          Add one or a group of domains and set up routes — which public path will points to which service and its port.
        </div>

        <zui-http-routing-form
          #formRef
          class="__form"
          (submit)="onEdit$.next()"
          [portsWithServiceStackInfo]="state.projectHttpPorts"
          [state]="state.formState"
          [domainsBlacklist]="state.domainBlacklist"
          [httpRoutingFieldsTranslations]="state.httpRoutingFieldsTranslations"
          (domainBlacklisted)="onDomainBlacklisted$.next($event)"
          (removeLocation)="onRemoveLocation$.next($event)">
          <button
            type="button"
            (click)="onAddEmptyLocation$.next()"
            mat-button
            class="__new-location-button  c-button--full">
            Add another route
          </button>
        </zui-http-routing-form>

      </zui-form-section>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error full [key]="editKey">
            <button
              class="__button  c-button--full"
              color="accent"
              mat-raised-button
              type="button"
              (click)="formRef.triggerSubmit()">
              Update domain access
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>