<zef-form
  [state]="state"
  (submit)="submit.emit(state)">

  <zui-code-field
    class="__code-field"
    [editorOptions]="{
      language: 'ini',
      wordWrap: true
    }"
    [ngrxFormControlState]="state.controls.content"
    [ngrxEnableFocusTracking]="true">
  </zui-code-field>

  <ng-content></ng-content>

</zef-form>
