export const ASTRO_IMPORT_YAML = `project:
  name: recipe-astro
  tags:
    - zerops-recipe

services:
  - hostname: ssrapp
    type: nodejs@20
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-astro-nodejs

  - hostname: staticapp
    type: static
    zeropsSetup: app
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-astro-static`;
