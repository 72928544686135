export const DENO_IMPORT_YAML = `project:
  name: recipe-deno
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: deno@1
    buildFromGit: https://github.com/zeropsio/recipe-deno
    enableSubdomainAccess: true

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
