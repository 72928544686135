import { Language } from '@zerops/models/settings';
import { ClientUser } from '@zerops/models/client-user';

export enum UserStatuses {
  Active = 'ACTIVE',
  WaitingForAuthorization = 'WAITING_FOR_AUTHORIZATION'
}

export class User {
  id: string;
  email: string;
  avatar: Avatar;
  fullName: string;
  firstName: string;
  lastName: string;
  countryCallingCode: number;
  phoneNumber: number;
  language: Language;
  created: string;
  lastUpdate: string;
  status: string;
  clientUserList: ClientUser[];
  intercomHash?: string;
  // TODO: needed to make updateOne work
  // https://trello.com/c/LsB50A9r
  clientId?: string;
  languageId?: string;
  passwordIsSet: boolean;
}

export class Avatar {
  externalAvatarUrl: string;
  largeAvatarUrl: string;
  smallAvatarUrl: string;
}

export class UserCheck {
  fullName: string;
  id: string;
}
