import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SatPopover } from '@zerops/zef/popover';

@Component({
  selector: 'zui-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: [ './more-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreButtonComponent {

  @Input()
  popRef: SatPopover;

  @Input()
  set isLoading(v: string | boolean) {
    this._isLoading = coerceBooleanProperty(v);
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output()
  moreClick = new EventEmitter<void>();

  private _isLoading = false;

}
