<zui-form-section
  [isZen]="isZen"
  [separator]="'none'">

  <span *zuiFormSectionTitle>
    {{ translations.nameTitle }}
  </span>

  <div
    *zuiFormSectionDesc
    [innerHTML]="translations.nameDesc">
  </div>

  <zef-form-field last>
    <zef-form-field>
      <z-hostname-field
        [label]="translations.nameLabel"
        [placeholder]="translations.namePlaceholder"
        [control]="state?.controls.name">
      </z-hostname-field>
    </zef-form-field>
  </zef-form-field>

</zui-form-section>

<zui-separator
  [orientation]="'vertical'"
  [size]="'medium'"
  [spacing]="'large'"
  [position]="'center'"
/>

@if (userServiceStacks?.length) {
  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'">

    <span *zuiFormSectionTitle>
      {{ translations.connectionsTitle }}
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.connectionsDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field>
      <zui-shared-storage-connection-field
        [items]="userServiceStacks"
        [ngrxFormControlState]="state?.controls.storageStackIds"
        [ngrxEnableFocusTracking]="true">
      </zui-shared-storage-connection-field>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'large'"
    [position]="'center'"
  />

}

<zui-form-section
  [isZen]="isZen"
  [separator]="'none'"
  class="__scaling-section">

  <span *zuiFormSectionTitle>
    Automatic Scaling
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>
      The cluster runs always on three containers, as this is the most efficient configuration for the vast majority of cases.
      Zerops will scale the service only vertically by increasing and decreasing each container's resources,
      depending on the current load and the best practices. You can fine-tune all within the ranges to fit the exact need of
      the environment you plan to run the service. Find all the specifics in the
      <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">Zerops shared storage scaling documentation.</a>
    </zui-docs-text>
  </div>

  <zef-form-field>
    <zui-autoscaling-form-field
      [serviceStackType]="serviceStackType"
      [ngrxFormControlState]="state?.controls.customAutoscaling"
      [defaultHorizontalAutoscaling]="defaultHorizontalAutoscaling"
      [defaultVerticalAutoscaling]="defaultVerticalAutoscaling">
    </zui-autoscaling-form-field>
  </zef-form-field>

</zui-form-section>
