import { Injectable } from '@angular/core' ;


export interface UserDataContentVariableExpand {
  type: 'local' | 'external';
  raw: string;
  val: string | string[];
}

interface ShellName {
  name: string;
  w: number;
}

@Injectable({ providedIn: 'root' })
export class UserDataContentParser {
  parse(text: string): (UserDataContentVariableExpand | string)[] {
    let i = 0;
    let result = [];
    let buf = '';

    for (let j = 0; j < text.length; j++) {
      if (text[j] === '$' && j + 1 < text.length) {

        buf = buf + text.slice(i, j);

        const x = this._getShellName(text.slice(j + 1, text.length));

        // quick fix
        if (!x) { return [ text ]; }

        if ((x as ShellName).name === '' && (x as ShellName).w > 0) {
          // Encountered invalid syntax; eat the
          // characters.
        } else if ((x as ShellName).name === '') {
          // Valid syntax, but $ was not followed by a
          // name. Leave the dollar character untouched.
          buf = buf + text[j];
        } else {

          const _x = (x as ShellName);

          const _nameSplit = _x.name.split('_');
          const _isExternal = _nameSplit.length > 1;

          result = result.concat(buf, {
            type: _isExternal ? 'external' : 'local',
            raw: '${' + _x.name + '}',
            val: _isExternal ? [ ..._nameSplit ] : _x.name
          });

          buf = '';

        }

        j += (x as ShellName).w;
        i = j + 1;

      }
    }

    if (result.length === 0) {
      return [ text ];
    }

    return result.concat(text.slice(i, text.length));

  }

  private _isShellSpecialVar(c: string) {
    return [ '#', '$', '@', '!', '?', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' ].includes(c);
  }

  private _isAlphaNum(c: string | number) {
    return c === '_' || '0' <= c && c <= '9' || 'a' <= c && c <= 'z' || 'A' <= c && c <= 'Z';
  }

  private _getShellName(s: string): ShellName | string {

    if (s[0] === '{') {

      if (s.length > 2 && this._isShellSpecialVar(s[1]) && s[2] === '}') {
        return {
          name: s.slice(1,2),
          w: 3,
        };
      }

      // Scan to closing brace
      for (let i = 1; i < s.length; i++) {
        if (s[i] === '}') {

          if (i === 1) {
            return {
              name: '',
              w: 2
            }; // Bad syntax; eat "${}"
          }

          return {
            name: s.slice(1,i),
            w: i + 1
          };

        }
      }

      // Bad syntax; eat "${"
      return {
        name: '',
        w: 1
      };

    }

    if (this._isShellSpecialVar(s[0])) {
      return {
        name: s.slice(0,1),
        w: 1
      };
    }

    // Scan alphanumerics.
    for (let i = 0; i < s.length && this._isAlphaNum(s[i]); i++) {
      return {
        name: s.slice(0, i),
        w: i
      };
    }

  }

}
