<div class="__desc-wrap" *ngIf="mode === 'new'">

  @if (actionName !== 'stack.deploy.backup') {
    {{ !appVersion.build ? 'Deploy pipeline' : 'Pipeline' }} <ng-template [ngTemplateOutlet]="pipelineLinkRef"></ng-template>

    for service

    <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>

    <!-- github -->
    <ng-template [ngIf]="appVersion?.source === 'GITHUB' || parsedPublicGitSource?.source === 'GITHUB'">

      using source

      <!-- BRANCH eventType -->
      <ng-template [ngIf]="appVersion?.githubIntegration?.eventType === 'BRANCH'">
        from commit <a class="__build_commit  __external-link" target="_blank" [href]="'https://github.com/' + appVersion?.githubIntegration?.repositoryFullName + '/tree/' + appVersion?.githubIntegration?.commit">{{ appVersion?.githubIntegration?.commit | shorten: 8 }}</a> in
      </ng-template>

      <!-- TAG eventType -->
      <ng-template [ngIf]="appVersion?.githubIntegration?.eventType === 'TAG'">
        from release <a class="__build_release  __external-link" target="_blank" [href]="'https://github.com/' + appVersion?.githubIntegration?.repositoryFullName + '/tree/' + appVersion?.githubIntegration?.tagName">{{ appVersion?.githubIntegration?.tagName }}</a> of
      </ng-template>

      <ng-template [ngIf]="!!parsedPublicGitSource">
        from branch <a class="__build_repository-name  __external-link" target="_blank" [href]="appVersion?.publicGitSource?.gitUrl">{{ appVersion?.publicGitSource?.branchName }}</a> of
      </ng-template>

      repository&nbsp;<a class="__build_repository-name  __external-link" target="_blank" [href]="'https://github.com/' + ((appVersion?.githubIntegration?.repositoryFullName || parsedPublicGitSource?.organization) + (!!parsedPublicGitSource?.repository ?  ('/' + parsedPublicGitSource?.repository) : ''))"><mat-icon class="__build_github-icon" svgIcon="github"></mat-icon>&nbsp;{{ (appVersion?.githubIntegration?.repositoryFullName || (parsedPublicGitSource?.organization + '/' + parsedPublicGitSource?.repository))  | middleEllipsis: 26 }}</a>

    </ng-template>

    <!-- gitlab -->
    <ng-template [ngIf]="appVersion?.source === 'GITLAB' || parsedPublicGitSource?.source === 'GITLAB'">

      using source

      <!-- BRANCH eventType -->
      <ng-template [ngIf]="appVersion?.gitlabIntegration?.eventType === 'BRANCH'">
        from commit <a class="__build_commit  __external-link" target="_blank" [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration?.repositoryFullName + '/-/tree/' + appVersion?.gitlabIntegration?.commit">{{ appVersion?.gitlabIntegration?.commit | shorten: 8 }}</a> in
      </ng-template>

      <!-- TAG eventType -->
      <ng-template [ngIf]="appVersion?.gitlabIntegration?.eventType === 'TAG'">
        from release <a class="__build_release  __external-link" target="_blank" [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration?.repositoryFullName + '/-/tags/' + appVersion?.gitlabIntegration?.tagName">{{ appVersion?.gitlabIntegration?.tagName }}</a> of
      </ng-template>

      <ng-template [ngIf]="!!parsedPublicGitSource">
        from branch <a class="__build_repository-name  __external-link" target="_blank" [href]="appVersion?.publicGitSource?.gitUrl">{{ appVersion?.publicGitSource?.branchName }}</a> of
      </ng-template>

      repository&nbsp;<a class="__build_repository-name  __external-link" target="_blank" [href]="'https://gitlab.com/' + ((appVersion?.gitlabIntegration?.repositoryFullName || parsedPublicGitSource?.organization) + (!!parsedPublicGitSource?.repository ?  ('/' + parsedPublicGitSource?.repository) : ''))"><mat-icon class="__build_gitlab-icon" svgIcon="gitlab"></mat-icon>&nbsp;{{ (appVersion?.gitlabIntegration?.repositoryFullName || (parsedPublicGitSource?.organization + '/' + parsedPublicGitSource?.repository))  | middleEllipsis: 26 }}</a>

    </ng-template>

    <!-- other -->
    <ng-template [ngIf]="appVersion?.source === 'OTHER' && !!parsedPublicGitSource">

      using source

      <!-- Display repository information for OTHER source -->
      <ng-template [ngIf]="parsedPublicGitSource?.organization || parsedPublicGitSource?.repository">
        Repository:
        <span class="__build_repository-name">
          {{ (parsedPublicGitSource?.organization ? parsedPublicGitSource?.organization + '/' : '') + (parsedPublicGitSource?.repository ? parsedPublicGitSource?.repository : '') | middleEllipsis: 26 }}
        </span>
      </ng-template>

    </ng-template>

    <!-- CLI -->
    <ng-template [ngIf]="appVersion?.source === 'CLI'">
      using source pushed through <strong>Zerops CLI</strong><span *ngIf="!!appVersion?.name"> with version name <strong>{{ appVersion?.name }}</strong></span>
    </ng-template>

  } @else {
    {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} archived version<strong *ngIf="appVersion?.name">&nbsp;{{ appVersion?.name }}&nbsp;</strong>created by {{ !appVersion.build ? 'deploy pipeline' : 'pipeline' }} <span class="c-internal-link">{{ appVersion?.id | middleEllipsis: 12 }}</span> for service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
  }

</div>

<ng-container *ngIf="mode === 'old'">

  <!-- from github -->
  <ng-container *ngIf="appVersion?.source === 'GITHUB' || parsedPublicGitSource?.source === 'GITHUB'">

    <ng-container *ngIf="actionName === 'stack.deploy.backup'">
      {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} archived app version {{ appVersion?.name }} of&nbsp;{{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
    </ng-container>

    <zui-docs-text *ngIf="actionName !== 'stack.deploy.backup'">
      {{ statusName }} <a data-zui-docs="build/how-zerops-build-works.html"><ng-container *ngIf="appVersion?.status !== appVersionStatuses.Backup"> build, prepare, </ng-container>deploy and run</a> pipeline for&nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>, for&nbsp;<a class="__build_repository-name  __external-link" target="_blank" [href]="'https://github.com/' + (appVersion?.githubIntegration?.repositoryFullName || parsedPublicGitSource?.organization + '/' + parsedPublicGitSource?.repository)"><mat-icon class="__build_github-icon" svgIcon="github"></mat-icon>&nbsp;{{ appVersion?.githubIntegration?.repositoryFullName || parsedPublicGitSource?.repository }}</a>
    </zui-docs-text>

    <!-- BRANCH eventType -->
    <ng-container *ngIf="appVersion?.githubIntegration?.eventType === 'BRANCH'">
      commit&nbsp;<a class="__build_commit  __external-link" target="_blank" [href]="'https://github.com/' + appVersion?.githubIntegration?.repositoryFullName + '/tree/' + appVersion?.githubIntegration?.commit">{{ appVersion?.githubIntegration?.branchName }}&#64;{{ appVersion?.githubIntegration?.commit | shorten: 8 }}</a>
    </ng-container>

    <!-- TAG eventType -->
    <ng-container *ngIf="appVersion?.githubIntegration?.eventType === 'TAG'">
      release&nbsp;<a class="__build_release  __external-link" target="_blank" [href]="'https://github.com/' + appVersion?.githubIntegration?.repositoryFullName + '/tree/' + appVersion?.githubIntegration?.tagName">{{ appVersion?.githubIntegration?.tagName }}</a>
    </ng-container>

    <ng-container *ngIf="!!parsedPublicGitSource">
      branch <a class="__build_repository-name  __external-link" target="_blank" [href]="appVersion?.publicGitSource?.gitUrl">{{ appVersion?.publicGitSource?.branchName }}</a>
    </ng-container>

  </ng-container>

  <!-- from gitlab -->
  <ng-container *ngIf="appVersion?.source === 'GITLAB' || parsedPublicGitSource?.source === 'GITLAB'">

    <ng-container *ngIf="actionName === 'stack.deploy.backup'">
      {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} archived app version {{ appVersion?.name }} of&nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
    </ng-container>

    <zui-docs-text *ngIf="actionName !== 'stack.deploy.backup'">
      {{ statusName }} <a data-zui-docs="build/how-zerops-build-works.html"><ng-container *ngIf="appVersion?.status !== appVersionStatuses.Backup"> build & </ng-container>deploy</a> &nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> from  <a class="__build_repository-name  __external-link" target="_blank" [href]="'https://gitlab.com/' + (appVersion?.gitlabIntegration?.repositoryFullName || parsedPublicGitSource?.organization + '/' + parsedPublicGitSource?.repository)"><mat-icon class="__build_github-icon" svgIcon="gitlab"></mat-icon>&nbsp;{{ appVersion?.gitlabIntegration?.repositoryFullName || parsedPublicGitSource?.repository }}</a>
    </zui-docs-text>

    <!-- BRANCH eventType -->
    <ng-container *ngIf="appVersion?.gitlabIntegration?.eventType === 'BRANCH'">
      commit&nbsp;<a class="__build_commit  __external-link" target="_blank" [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration?.repositoryFullName + '/tree/' + appVersion?.gitlabIntegration?.commit">{{ appVersion?.gitlabIntegration?.branchName }}&#64;{{ appVersion?.gitlabIntegration?.commit | shorten: 8 }}</a>
    </ng-container>

    <!-- TAG eventType -->
    <ng-container *ngIf="appVersion?.gitlabIntegration?.eventType === 'TAG'">
      release&nbsp;<a class="__build_release  __external-link" target="_blank" [href]="'https://gitlab.com/' + appVersion?.gitlabIntegration?.repositoryFullName + '/tree/' + appVersion?.gitlabIntegration?.tagName">{{ appVersion?.gitlabIntegration?.tagName }}</a>
    </ng-container>

    <ng-container *ngIf="!!parsedPublicGitSource">
      branch <a class="__build_repository-name  __external-link" target="_blank" [href]="appVersion?.publicGitSource?.gitUrl">{{ appVersion?.publicGitSource?.branchName }}</a>
    </ng-container>

  </ng-container>

  <!-- from public GIT -->
  <ng-container *ngIf="appVersion?.source === 'GIT' && parsedPublicGitSource?.source === 'OTHER'">

    <ng-container *ngIf="actionName === 'stack.deploy.backup'">
      {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} archived app version {{ appVersion?.name }} of&nbsp;{{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
    </ng-container>

    <zui-docs-text *ngIf="actionName !== 'stack.deploy.backup'">
      {{ statusName }} <a data-zui-docs="build/how-zerops-build-works.html"><ng-container *ngIf="appVersion?.status !== appVersionStatuses.Backup"> build & </ng-container>deploy</a> pipeline for&nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> from branch <a class="__build_repository-name  __external-link" target="_blank" [href]="appVersion?.publicGitSource?.gitUrl">{{ appVersion?.publicGitSource?.branchName }}</a>
    </zui-docs-text>

  </ng-container>

  <!-- from CLI -->
  <ng-container *ngIf="appVersion?.source === 'CLI'">

    <ng-container *ngIf="actionName === 'stack.deploy.backup'">
      {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} <ng-container *ngIf="appVersion?.name">archived app version {{ appVersion?.name }}</ng-container><ng-container *ngIf="!appVersion?.name">archived unnamed version</ng-container> of {{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
    </ng-container>

    <ng-container *ngIf="actionName !== 'stack.deploy.backup'">

      <!-- has build -->
      <ng-container *ngIf="!!appVersion?.build?.serviceStackTypeVersionId">

        <!-- no name -->
        <zui-docs-text *ngIf="!appVersion?.name">
          {{ statusName }} <a data-zui-docs="build/how-zerops-build-works.html"><ng-container *ngIf="appVersion?.status !== appVersionStatuses.Backup"> build, prepare, </ng-container>deploy and run</a> pipeline for&nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> <span class="__unnamed">unnamed version</span>
        </zui-docs-text>

        <!-- has name -->
        <zui-docs-text *ngIf="appVersion?.name">
          {{ statusName }} <a data-zui-docs="build/how-zerops-build-works.html"><ng-container *ngIf="appVersion?.status !== appVersionStatuses.Backup"> build, prepare, </ng-container>deploy and run</a> pipeline for&nbsp;{{ serviceType }} service <br *ngIf="hasHardBreakAfterService" /> <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> <strong>{{ appVersion?.name }}</strong>
        </zui-docs-text>

      </ng-container>

      <!-- only deploy -->
      <ng-container *ngIf="!appVersion?.build?.serviceStackTypeVersionId">
        {{ statusName }} deploy for {{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> triggered using <strong>CLI</strong>
      </ng-container>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="appVersion?.source === 'GUI'">

    <ng-container *ngIf="actionName === 'stack.deploy.backup'">
      {{ appVersion?.status === appVersionStatuses.Deploying ? 'Activating' : appVersion?.status === appVersionStatuses.WaitingToDeploy ? 'Activate' : 'Activated' }} <ng-container *ngIf="appVersion?.name">archived app version {{ appVersion?.name }}</ng-container><ng-container *ngIf="!appVersion?.name">archived app version</ng-container> of {{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
    </ng-container>

    <!-- only deploy -->
    <ng-container *ngIf="!appVersion?.build?.serviceStackTypeVersionId && actionName !== 'stack.deploy.backup'">
      {{ statusName }} deploy for {{ serviceType }} service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template> triggered using <strong>GUI</strong>
    </ng-container>

  </ng-container>

</ng-container>

<ng-template #projectServiceLinkRef>

  <ng-template [ngIf]="!disableLink">
    <a (click)="linkClicked.emit()" class="c-internal-link" [routerLink]="[ '/project', projectId ]">{{ projectName }}</a>/<a (click)="linkClicked.emit()" class="c-internal-link" [routerLink]="[ '/service-stack', serviceId ]"><strong>{{ serviceName }}</strong></a>
  </ng-template>

  <ng-template [ngIf]="disableLink">
    <span (click)="linkClicked.emit()" class="c-internal-link">{{ projectName }}</span>/<span (click)="linkClicked.emit()" class="c-internal-link"><strong>{{ serviceName }}</strong></span>
  </ng-template>

</ng-template>

<ng-template #pipelineLinkRef>

  <ng-template [ngIf]="!disableLink && !noPipelineLinkDetail">
    <a (click)="linkClicked.emit()" class="c-internal-link" [routerLink]="[ '/service-stack', serviceId, 'deploy', appVersion?.id ]">{{ appVersion?.id | middleEllipsis: 12 }}</a>
  </ng-template>

  <ng-template [ngIf]="disableLink || noPipelineLinkDetail">
    <span (click)="linkClicked.emit()" class="c-internal-link">{{ appVersion?.id | middleEllipsis: 12 }}</span>
  </ng-template>

</ng-template>
