import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { FormState } from 'ngrx-forms';
import { FEATURE_NAME } from './import-export-dialog.constant';

export interface ImportExportDialogFormValue {
  yaml: string;
}

export type ImportExportDialogFormState = FormState<ImportExportDialogFormValue>;

@Injectable({ providedIn: 'root' })
export class ImportExportDialogForm extends ZefForm<ImportExportDialogFormValue> {
  constructor(
    public store: Store<any>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        yaml: ''
      },
      {}
    );
  }
}
