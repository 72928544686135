export class Service {
  id: string;
  clientId: string;
  projectId: string;
  serviceStackId: string;
  name: string;
  status: ServiceStatuses;
  stackAccessPoint: boolean;
  category: ServiceCategories;
  targetContainerCount: number;
  currentActiveContainerCount: number;
  currentHardwareResource: CurrentHardwareResource;
  created: string;
  lastUpdate: string;
}

export class CurrentHardwareResource {
  cpuCoreCount: number;
  diskGBytes: number;
  memoryMBytes: number;
  ramDiskMBytes: number;
}

export enum ServiceCategories {
  Empty = 'EMPTY',
  Master = 'MASTER',
  Logger = 'LOGGER',
  HttpL7Balancer = 'HTTP_L7_BALANCER',
  ApplicationBalancer = 'APPLICATION_BALANCER',
  Database = 'DATABASE',
  MessageBroker = 'MESSAGE_BROKER',
  UserApplication = 'USER_APPLICATION',
  SharedStorage = 'SHARED_STORAGE',
  System = 'SYSTEM'
}

export enum ServiceStatuses {
  Active = 'ACTIVE',
  Deleting = 'DELETING',
  Failed = 'FAILED',
  Repairing = 'REPAIRING',
  Creating = 'CREATING',
  ContainerFailed = 'CONTAINER_FAILED',
  MovingContainer = 'MOVING_CONTAINER',
  Stopping = 'STOPPING',
  Stopped = 'STOPPED',
  Starting = 'STARTING',
  Restarting = 'RESTARTING',
  Reloading = 'RELOADING',
  Upgrading = 'UPGRADING',
  UpgradeFailed = 'UPGRADE_FAILED',
  ActionFailed = 'ACTION_FAILED'
}
