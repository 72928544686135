import { HashMap } from '@zerops/zef/core';
import { CurrentStatistic, HistoryStatistic, StatisticsGroupBy, StatisticsGroupRange, StatisticsTimeGroupBy } from '@zerops/models/resource-statistics';

export class ResourceStatisticsBaseState {
  currentServiceStackMap: HashMap<CurrentStatistic>;
  currentContainerMap: HashMap<CurrentStatistic>;
  currentCostMap: any;
  historyData: HashMap<any>;

  constructor() {
    this.currentServiceStackMap = {};
    this.currentContainerMap = {};
    this.historyData = {};
    this.currentCostMap = {};
  }

}

export interface HistoryStatisticsGroupRequestPayload {
  groupRange: StatisticsGroupRange;
  groupBy: StatisticsGroupBy;
  projectId?: string;
  serviceId?: string;
  containerId?: string;
}

export interface HistoryStatisticsGroupUpdateSuccessPayload {
  items: HistoryStatistic[];
  limit: number;
  timeGroupBy: StatisticsTimeGroupBy;
  groupBy: StatisticsGroupBy;
  projectId?: string;
  serviceId?: string;
  key?: string;
  from?: string | Date;
  till?: string | Date;
}
