import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  HttpRoutingLocation,
  HttpRoutingWithLocations,
  SslStatuses,
  DnsCheckStatuses
} from '@zerops/models/http-routing';
import { SatPopover } from '@zerops/zef/popover';
import { HttpRoutingItemTranslations } from './http-routing-item.translations';
import { DynamicPopAnchorDirective } from '../dynamic-pop-anchor';

@Component({
  selector: 'zui-http-routing-item',
  templateUrl: './http-routing-item.component.html',
  styleUrls: [ './http-routing-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HttpRoutingItemComponent {

  sslStatuses = SslStatuses;
  dnsStatuses = DnsCheckStatuses;

  @Input()
  data: HttpRoutingWithLocations;

  @Input()
  stackLocations: HttpRoutingLocation[];

  @Input()
  otherLocations: HttpRoutingLocation[];

  @Input()
  showOtherLocations = false;

  @Input()
  translations: HttpRoutingItemTranslations;

  @Input()
  dnsCheckStatusPopRef: SatPopover;

  @Input()
  set hideOutOfSync(v: string | boolean) {
    this._hideOutOfSync = coerceBooleanProperty(v);
  }
  get hideOutOfSync(): boolean {
    return this._hideOutOfSync;
  }

  @Output()
  dnsCheckStatusPopEnter = new EventEmitter<{
    anchor: DynamicPopAnchorDirective,
    name: string
  }>();

  @Output()
  dnsCheckStatusPopLeave = new EventEmitter<void>();

  @ViewChildren('dnsCheckStatusAnchorRef')
  dnsCheckStatusAnchorRefs: QueryList<DynamicPopAnchorDirective>;

  private _hideOutOfSync = false;

  constructor(public elementRef: ElementRef) { }

  trackBy(_: number, item: HttpRoutingLocation) {
    return `${item.path}${item.port}`;
  }
}
