import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  catchError
} from 'rxjs/operators';
import {
  loadSettings,
  loadSettingsSuccess,
  loadSettingsFail, loadCountryList, loadCountryListSuccess, loadCountryListFail
} from './settings-base.action';
import { SettingsBaseApi } from './settings-base.api';

@Injectable()
export class SettingsBaseEffect {

  private _onLoadSettings$ = createEffect(() => this._actions$.pipe(
    ofType(loadSettings),
    switchMap((action) => this._api
      .settings$()
      .pipe(
        map((response) => loadSettingsSuccess(response, action)),
        catchError((err) => of(loadSettingsFail(err, action)))
      )
    )
  ));

  private _onLoadCountryList$ = createEffect(() => this._actions$.pipe(
    ofType(loadCountryList),
    switchMap((action) => this._api
      .countryList$()
      .pipe(
        map((response) => loadCountryListSuccess(response, action)),
        catchError((err) => of(loadCountryListFail(err, action)))
      )
    )
  ));

  constructor(
    private _actions$: Actions<any>,
    private _api: SettingsBaseApi
  ) { }
}
