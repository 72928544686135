export const GLEAM_IMPORT_YAML = `project:
  name: recipe-gleam
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: gleam@1.5
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-gleam

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
