@if (showOnlyIpV4Button) {
  <zef-progress-error
    class="__ip-button"
    [key]="requestIpv4Key"
    [loading]="ipV4AddonInProcess">
      <button
        mat-stroked-button
        type="button"
        color="accent"
        (click)="requestIpV4.emit()"
        [class.is-single]="showOnlyIpV4Button"
        [class.c-button--smaller]="!showOnlyIpV4Button"
        class="__ip-request-button  is-main">
        <ng-content />
      </button>
  </zef-progress-error>
}

@if (!showOnlyIpV4Button && (ipV4 || ipV6)) {
  <div
    class="__ip-wrap c-ip-info-ip-wrap"
    [class.has-one-item]="!!ipV6 && !ipV4">

    @if (ipV6) {
      <div class="__ip is-active c-ip-info-ip">

        @if (ipV6Prefix) {
          <span class="__ip-prefix">{{ ipV6Prefix }}</span>
        }

        <zef-copy-to-clipboard
          class="__ip-content"
          [copyData]="ipV6"
          [tooltip]="'Click to copy to clipboard'">
          <span class="__ip-address">{{ ipV6 }}</span>
        </zef-copy-to-clipboard>

      </div>
    }

    @if (!ipV4 && !ipV4PendingRequest && projectStatus === projectStatuses.Active) {
      <div class="__ip">

        <span class="__ip-prefix">{{ ipV4Prefix }}</span>

        <zef-progress-error
          class="__ip-button"
          [key]="requestIpv4Key"
          [loading]="ipV4AddonInProcess">
          <button
            mat-button
            type="button"
            (click)="requestIpV4.emit()"
            [class.is-single]="showOnlyIpV4Button"
            [class.c-button--smaller]="!showOnlyIpV4Button"
            class="__ip-request-button">
            @if (showOnlyIpV4Button) {
              <mat-icon>router</mat-icon>&nbsp;
            }<span>{{ requestIpV4ButtonText }}</span>

          </button>
        </zef-progress-error>

      </div>
    }

    @if (!ipV4 && ipV4PendingRequest) {
      <span class="__pending">IPv4 assigment request is pending</span>
    }

    @if (!showOnlyIpV4Button) {
      @if (ipV4) {
        <div class="__ip is-active c-ip-info-ip">

          @if (ipV4Prefix) {
            <span class="__ip-prefix">{{ ipV4Prefix }}</span>
          }

          <zef-copy-to-clipboard
            [copyData]="ipV4"
            class="__ip-content"
            [tooltip]="'Click to copy to clipboard'">
            <span class="__ip-address">
              {{ ipV4 }}
            </span>
          </zef-copy-to-clipboard>

        </div>
      }
    }
  </div>
}
