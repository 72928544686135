import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import set from 'lodash-es/set';

@Component({
  selector: 'zef-fuse-highlight',
  templateUrl: './fuse-highlight.component.html',
  styleUrls: [ './fuse-highlight.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseHighlightComponent implements OnChanges {
  @Input()
  highlightClass = 'zef-fuse-highlight';

  @Input()
  key: string;

  @Input()
  matches: any;

  @Input()
  data: any;

  text: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    if (this.matches && this.matches.length > 0) {
      // If there are matches, generate highlighted text
      const _item = { ...this.data };
      this.matches.forEach((match: any) => {
        set(_item, match.key, this._generateHighlightedText(match.value, match.indices));
      });
      this.text = this._sanitizeHTML(_item[this.key]);
    } else {
      // If there are no matches, display the text as is (escaped)
      this.text = this._sanitizeHTML(this._escapeHtml(this.data[this.key]));
    }
  }

  private _generateHighlightedText(inputText: string, regions: number[][]): string {
    let content = '';
    let nextUnhighlightedRegionStartingIndex = 0;

    regions.forEach((region) => {
      const lastRegionNextIndex = region[1] + 1;

      // Wrap highlighted text and escape HTML for non-highlighted parts
      content += [
        this._escapeHtml(inputText.substring(nextUnhighlightedRegionStartingIndex, region[0])),
        `<span class="__highlight  __highlight--active  ${this.highlightClass}">`,
        this._escapeHtml(inputText.substring(region[0], lastRegionNextIndex)),
        '</span>',
      ].join('');

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    // Append remaining text
    content += this._escapeHtml(inputText.substring(nextUnhighlightedRegionStartingIndex));

    return content;
  }

  private _sanitizeHTML(content: string): SafeHtml {
    // Bypass security trust only after escaping HTML entities
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  private _escapeHtml(text: string): string {
    if (!text) { return ''; }
    // Escape HTML entities to ensure no tags are rendered
    return text.replace(/&/g, "&amp;")
               .replace(/</g, "&lt;")
               .replace(/>/g, "&gt;")
               .replace(/"/g, "&quot;")
               .replace(/'/g, "&#39;");
  }
}
