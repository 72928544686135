import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ClientUserSelectModule } from '@zerops/zerops/feature/client-user-select';
import { ClientUserSelectPopContainer } from './client-user-select-pop.container';

@NgModule({
  declarations: [ ClientUserSelectPopContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    SatPopoverModule,
    ClientUserSelectModule
  ],
  exports: [ ClientUserSelectPopContainer ]
})
export class ClientUserSelectPopModule { }
