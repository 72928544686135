<a
  *ngIf="link; else elseRef"
  class="__item"
  [ngClass]="{
    'is-small': size === 'small',
    'is-medium': size === 'medium',
    'is-large': size === 'large',
    'is-icon-end': linkIconPosition === 'end',
    'is-icon-start': linkIconPosition === 'start'
  }"
  (mouseenter)="toggleContentRef.open()"
  (mouseleave)="toggleContentRef.close()"
  (click)="linkClicked.emit()"
  [routerLink]="link">
  <zui-toggle-content
    class="__toggle-content"
    #toggleContentRef
    [activeIndex]="0">

    <div
      *zuiToggleContentItem
      class="__toggle-content_item">
      <div class="__grid">

        <div
          [style.order]="linkIconPosition === 'end' ? 1 : 0">
          <div class="__link-icon-wrap">
            <mat-icon class="__link-icon  c-linked-basic-info-header-link-icon">{{ linkIcon }}</mat-icon>
          </div>
        </div>

        <div [style.order]="linkIconPosition === 'end' ? 0 : 1">
          <ng-template [ngTemplateOutlet]="contentRef"></ng-template>
        </div>

      </div>
    </div>

    <div
      *zuiToggleContentItem
      class="__toggle-content_item">
      <span class="__link-text  c-linked-basic-info-hearer-link-text">
        {{ linkText }}
      </span>
    </div>

  </zui-toggle-content>
</a>

<ng-template #elseRef>
  <span
    class="__item"
    [ngClass]="{
      'is-small': size === 'small',
      'is-medium': size === 'medium',
      'is-large': size === 'large',
      'is-icon-end': linkIconPosition === 'end',
      'is-icon-start': linkIconPosition === 'start'
    }"
    (mouseenter)="toggleContentNoLinkRef.open()"
    (mouseleave)="toggleContentNoLinkRef.close()"
    (click)="linkClicked.emit()">
    <zui-toggle-content
      class="__toggle-content"
      #toggleContentNoLinkRef
      [activeIndex]="0">

      <div
        *zuiToggleContentItem
        class="__toggle-content_item">
        <div class="__grid">

          <div
            [style.order]="linkIconPosition === 'end' ? 1 : 0">
            <div class="__link-icon-wrap">
              <mat-icon class="__link-icon  c-linked-basic-info-header-link-icon">{{ linkIcon }}</mat-icon>
            </div>
          </div>

          <div [style.order]="linkIconPosition === 'end' ? 0 : 1">
            <ng-template [ngTemplateOutlet]="contentRef"></ng-template>
          </div>

        </div>
      </div>

      <div
        *zuiToggleContentItem
        class="__toggle-content_item">
        <span class="__link-text  c-linked-basic-info-hearer-link-text">
          {{ linkText }}
        </span>
      </div>

    </zui-toggle-content>
  </span>
</ng-template>

<ng-template #contentRef>
  <ng-content></ng-content>
</ng-template>
