import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ZefFuseModule } from '@zerops/zef/fuse';
import { ServiceStackStatusIconModule } from '@zerops/zui/service-stack-status-icon';
import { ProjectStatusIconModule } from '@zerops/zui/project-status-icon';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { BasicInfoHeaderPaneComponent } from './basic-info-header.component';

@NgModule({
  declarations: [ BasicInfoHeaderPaneComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    ZefFuseModule,
    ServiceStackStatusIconModule,
    ProjectStatusIconModule,
    ZefPipesModule
  ],
  exports: [ BasicInfoHeaderPaneComponent ]
})
export class BasicInfoHeaderModule {

}
