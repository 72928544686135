import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZefAuthDefaultResponse } from '@zerops/zef/auth';
import {
  ZefGitlabAuthUrlType,
  ZefGitlabAuthUrlResponse,
  ZefGitlabApiAuthorizePayload,
  ZefGitlabRepository,
  ZefGitlabBranch
} from './gitlab.model';
import { ZEF_GITLAB_API_URL, ZEF_GITLAB_CALLBACK_URL } from './gitlab.constant';

@Injectable({ providedIn: 'root' })
export class ZefGitlabService {
  constructor(
    @Inject(ZEF_GITLAB_CALLBACK_URL)
    private _callbackUrl: string,
    @Inject(ZEF_GITLAB_API_URL)
    private _apiUrl: string,
    private _http: HttpClient
  ) { }

  getAuthUrl(action: ZefGitlabAuthUrlType, recipes?: { nonHaRecipeId: string; haRecipeId: string }) {
    return this._http.get<ZefGitlabAuthUrlResponse>(
      this._apiUrl ? (this._apiUrl + '/gitlab/auth-url') : '/api/gitlab/auth-url',
      {
        params: {
          action,
          nonHaRecipe: recipes?.nonHaRecipeId,
          haRecipe: recipes?.haRecipeId,
          redirectUrl: this._callbackUrl
        }
      }
    );
  }

  login(data: ZefGitlabApiAuthorizePayload) {
    return this._http.post<ZefAuthDefaultResponse>(
      '/api/gitlab/user-login',
      data
    );
  }

  signup(data: ZefGitlabApiAuthorizePayload) {
    return this._http.post<ZefAuthDefaultResponse>(
      '/api/gitlab/user-registration',
      data
    );
  }

  repositoryAccess(data: ZefGitlabApiAuthorizePayload) {
    return this._http.post<{ success: boolean; }>(
      '/api/gitlab/user-repository-access',
      data
    );
  }

  repositories() {
    return this._http.get<{ repositories: ZefGitlabRepository[]; }>(
      '/api/gitlab/repository'
    );
  }

  repositoryBranch(repositoryFullName: string) {
    return this._http.get<{ branches: ZefGitlabBranch[]; }>(
      '/api/gitlab/repository-branch',
      {
        params: {
          repositoryFullName
        }
      }
    );
  }

}
