import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { TopUpPromoCodeFormComponent } from './top-up-promo-code-form.component';

@NgModule({
  declarations: [ TopUpPromoCodeFormComponent ],
  imports: [
    CommonModule,
    ZefFormsModule,
    MaterialFormStackModule,
    MatIconModule
  ],
  exports: [ TopUpPromoCodeFormComponent ]
})
export class TopUpPromoCodeFormModule {

}
