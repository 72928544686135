import { createAction, union } from '@ngrx/store';

export const copyCodeDialogOpen = createAction(
  '[@zerops/zerops/feature/copy-code-dialog] open',
  (data: any, title: string) => ({ data, title })
);

const all = union({ copyCodeDialogOpen });

export type CopyCodeDialogActionUnion = typeof all;
