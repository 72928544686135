import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';
import { AppState } from '@zerops/zerops/app';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ProjectEntity } from '@zerops/zerops/core/project-base';
// import { getLog, selectZlogData } from '@zerops/zerops/feature/zlog';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { ServiceStack } from '@zerops/models/service-stack';
import { Store, select } from '@ngrx/store';
import { Subject, of, Observable } from 'rxjs';
// import { map, filter, switchMap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { map, filter, switchMap } from 'rxjs/operators';
import { FEATURE_NAME } from './log-dialog.constant';
import { LogDialogMeta } from './log-dialog.model';

@Component({
  selector: 'z-log-dialog',
  templateUrl: './log-dialog.container.html',
  styleUrls: [ './log-dialog.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onClose$ = new Subject<void>();
  onRequestPreviousData$ = new Subject<void>();

  // # Data
  // -- Angular

  // -- sync
  featureName = FEATURE_NAME;

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ state, meta }) => state && !!meta)
  );
  data$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    filter(({ meta }) => !!meta),
    map(({ meta }) => meta as LogDialogMeta)
  );
  projectId$ = this.data$.pipe(
    filter((d) => !!d.projectId),
    map((d) => d.projectId)
  );
  project$ = this.projectId$.pipe(
    switchMap((id) => this._projectEntity.entityByIdWithServiceStacks$(id)),
    filter((d) => !!d)
  );
  containerId$ = this.data$.pipe(
    filter((d) => !!d.containerId),
    map((d) => d.containerId)
  );
  serviceStack$: Observable<ServiceStack> = this.data$.pipe(
    switchMap(({ serviceStackId }) => serviceStackId ? this._serviceStackEntity.entityById$(serviceStackId) : of(undefined))
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    project: this.project$,
    containerId: this.containerId$,
    serviceStack: this.serviceStack$,
    data: this.data$
  });

  // # Action Streams
  private _dialogCloseAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  /*
  private _getLogAction$ = this.containerId$.pipe(
    distinctUntilChanged(),
    withLatestFrom(
      this.projectId$,
      this.data$
    ),
    filter(([ containerId, projectId, data ]) => !!containerId && !!projectId && !!data?.from),
    map(([ containerId, projectId, data ]) => getLog({
      key: this.featureName,
      projectId: projectId,
      limit: 100,
      containerId: containerId,
      from: data.from,
      minimumSeverity: 7
    }))
  );

  private _getPreviousDataLogAction$ = this.onRequestPreviousData$.pipe(
    withLatestFrom(
      this.projectId$,
      this.containerId$,
      this._store.pipe(select(selectZlogData))
    ),
    filter(([ _, projectId, containerId, data ]) => !!projectId && !!containerId && !!data && !!data[this.featureName]?.length),
    map(([ _, projectId, containerId, data ]) => getLog({
      key: this.featureName,
      projectId: projectId,
      limit: 100,
      containerId: containerId,
      till: data[this.featureName][0]?.id,
      desc: '1',
      persistData: true,
      minimumSeverity: 7
    }))
  );
  */

  constructor(
    private _store: Store<AppState>,
    private _projectEntity: ProjectEntity,
    private _serviceStackEntity: ServiceStackEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._dialogCloseAction$,
      // this._getLogAction$,
      // this._getPreviousDataLogAction$
    ]);
  }
}
