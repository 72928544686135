import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, GeneralTranslations } from '@zerops/zerops/app';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { requestIPv4 } from '@zerops/zerops/core/project-base';
import { modifyAddon, PRICELIST_MAP } from '@zerops/zerops/core/billing-base';
import { AddonExtendedByProjectInfo, MetricIds, SubscriptionExtendedByProjectInfo } from '@zerops/models/billing';
import { ClientStatusEntity } from '@zerops/zerops/core/client-status-base';
import { openTopUpDialog } from '@zerops/zerops/feature/top-up-dialog';
import { selectActiveCurrency } from '@zerops/zerops/core/settings-base';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { Subject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { FEATURE_NAME } from './addon-activation-dialog.constant';
import { AddonActivationDialogTranslations } from './addon-activation-dialog.translations';

@Component({
  selector: 'z-addon-activation-dialog',
  templateUrl: './addon-activation-dialog.container.html',
  styleUrls: [ './addon-activation-dialog.container.scss' ]
})
export class AddonActivationDialogContainer extends ZefReactiveComponent {

  // # Event Streams
  onActivate$ = new Subject<void>();
  onModifyAutorenew$ = new Subject<void>();
  onOpenTopUpDialog$ = new Subject<void>();
  onCloseDialog$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  pricelistMap = PRICELIST_MAP;
  loadingKeys = [ requestIPv4.type, modifyAddon.type ]

  // -- async
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  meta$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.meta),
    filter((d) => !!d)
  );
  addon$ = this.meta$.pipe(
    map((d) => d.addon as AddonExtendedByProjectInfo)
  );
  subscription$ = this.meta$.pipe(
    map((d) => d.subscription as SubscriptionExtendedByProjectInfo)
  );
  activeCurrency$ = this._store.pipe(select(selectActiveCurrency));
  translations$ = this.translate$<AddonActivationDialogTranslations>(FEATURE_NAME);
  generalTranslations$ = this.translate$<GeneralTranslations>('general');
  activeClientStatus$ = this._clientStatusEntity.activeClientStatus$;
  isContabo$ = this._userEntity.isContabo$;

  // # State resolver
  state = this.$connect({
    translations: this.translations$,
    open: this.open$,
    addon: this.addon$,
    subscription: this.subscription$,
    activeCurrency: this.activeCurrency$,
    generalTranslations: this.generalTranslations$,
    activeClientStatus: this.activeClientStatus$,
    isContabo: this.isContabo$
  });

  // # Action Streams
  private _activateAddonAction$ = this.onActivate$.pipe(
    withLatestFrom(this.addon$),
    map(([ _, { metricId, projectId } ]) => this._getAddonAction(metricId, 'activation', projectId))
  );
  private _deactivateAddonAction$ = this.onModifyAutorenew$.pipe(
    withLatestFrom(this.subscription$),
    map(([ _, { projectId, metricId, recurringEnabled, id } ]) => this._getAddonAction(
      metricId,
      'modification',
      projectId,
      id,
      !recurringEnabled
    ))
  );
  private _openTopUpDialogAction$ = this.onOpenTopUpDialog$.pipe(
    map(() => openTopUpDialog())
  );
  private _closeDialogAction$ = this.onCloseDialog$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _clientStatusEntity: ClientStatusEntity,
    private _userEntity: UserEntity
  ) {
    super();

    this.$dispatchActions([
      this._activateAddonAction$,
      this._deactivateAddonAction$,
      this._openTopUpDialogAction$,
      this._closeDialogAction$
    ]);
  }

  private _getAddonAction(
    metricId: MetricIds,
    mode: 'activation' | 'modification',
    projectId: string,
    addonId?: string,
    recurringEnabled?: boolean
  ) {

    const actionsMap = {
      activation: {
        [MetricIds.ProjectIpV4]: requestIPv4(projectId)
      },
      modification: modifyAddon({
        id: addonId,
        recurringEnabled,
        enabled: true
      })
    };

    return mode === 'activation' ? actionsMap.activation[metricId] : actionsMap.modification
  }

}
