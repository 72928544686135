import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { DynamicPopAnchorModule } from '@zerops/zui/dynamic-pop-anchor';
import { NotificationPopTriggerComponent } from './notification-pop-trigger.component';

@NgModule({
  declarations: [ NotificationPopTriggerComponent ],
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    DynamicPopAnchorModule
  ],
  exports: [ NotificationPopTriggerComponent ]
})
export class ZuiNotificationsPopTriggerModule { }
