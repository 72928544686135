<zui-price
  class="__price"
  [currency]="currency"
  [decimalCount]="decimalCount"
  [amount]="amount">
</zui-price>

<span *ngIf="desc" class="__desc u-desc c-price-desc-desc" [innerHTML]="desc"></span>

<ng-template *ngIf="descTpl" [ngTemplateOutlet]="descTpl"></ng-template>
