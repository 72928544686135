import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HttpRoutingItemModule } from '@zerops/zui/http-routing-item';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiMoreButtonModule } from '@zerops/zui/more-button';
import { EffectsModule } from '@ngrx/effects';
import { HttpRoutingListContainer } from './http-routing-list.container';
import { HttpRoutingActionsPopModule } from './modules/http-routing-actions-pop';
import { HttpRoutingListTranslationsModule } from './http-routing-list.translations';
import { HttpRoutingListEffect } from './http-routing-list.effect';

@NgModule({
  declarations: [ HttpRoutingListContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    EffectsModule.forFeature([ HttpRoutingListEffect ]),
    ZuiSeparatorComponent,
    HttpRoutingActionsPopModule,
    HttpRoutingItemModule,
    HttpRoutingListTranslationsModule,
    ZuiMoreButtonModule
  ],
  exports: [ HttpRoutingListContainer ]
})
export class HttpRoutingListModule {

}
