import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { SatPopoverModule } from '@zerops/zef/popover';
import { DnsCheckStatusIconModule } from '@zerops/zui/dns-check-status-icon';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { DnsCheckStatusPopContainer } from './dns-check-status-pop.container';
import { DnsCheckStatusPopTranslationsModule } from './dns-check-status-pop.translations';

@NgModule({
  declarations: [ DnsCheckStatusPopContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    SatPopoverModule,
    DnsCheckStatusPopTranslationsModule,
    DnsCheckStatusIconModule,
    ZefCopyToClipboardModule
  ],
  exports: [ DnsCheckStatusPopContainer ]
})
export class DnsCheckStatusPopModule { }
